import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";

import Moment from "moment";
import { translateStatesBicciFilter } from "../utils/objects/translateStates";
import { UserContext } from "../context/CurrentUserProvider";

function useGetOrdersDataByCompany(initDateInitial, endDateInitial) {
  const { userDataContext } = useContext(UserContext);

  const days = 1;
  const daysfinal = 1;

  const [initDate, setInitDate] = useState(
    initDateInitial || Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    endDateInitial || Moment(Date.now()).format("YYYY-MM-DD")
  );

  const [dataOrdersExpress, setDataOrdersExpress] = useState(null);
  const [loadingExpress, setLoadingExpress] = useState(false);
  const [dataOrdersExpressPendint, setDataOrdersExpressPendint] = useState([]);
  const [loadingExpressPending, setLoadingExpressPending] = useState(false);

  const [mergedOrdersBicci, setMergedOrdersBicci] = useState({});
  const [loadingContextBicci, setLoadingContextBicci] = useState(false);

  const [dataOrdersBicci, setDataOrdersBicci] = useState(null);
  const [loadingBicci, setLoadingBicci] = useState(false);
  const [dataOrdersBicciPending, setDataOrdersBicciPending] = useState(null);
  const [loadingBicciPending, setLoadingBicciPending] = useState(false);

  const [mergedOrdersExpress, setMergedOrdersExpress] = useState({});

  const [loadingContextExpress, setLoadingContextExpress] = useState(false);

  const [mergedOrders, setMergedOrders] = useState({});
  const [loadingContext, setLoadingContext] = useState(false);

  const companyId = useMemo(() => {
    if (userDataContext) {
      return userDataContext.companyID;
    }
    return "";
  }, [userDataContext]);

  const companyID_Int = useMemo(() => {
    if (userDataContext && userDataContext.companyData) {
      return userDataContext.companyData.integrationServicesBicciID;
    }
    return "";
  }, [userDataContext]);

  useEffect(() => {}, [userDataContext]);

  useEffect(() => {
    setLoadingContext(true);
    const loadAndMergeOrders = async () => {
      if (!initDate || !endDate) return;

      findOrderBicci(companyID_Int, initDate, endDate);
      findOrderBicciPendinst(companyID_Int);
      findOrderExpress(companyId, initDate, endDate);
      findOrderExpressPendint(companyId, initDate, endDate);
    };

    loadAndMergeOrders();
  }, [initDate, endDate, companyId, companyID_Int]);

  React.useEffect(() => {
    // console.log(loadingExpress, loadingExpressPending);
    // console.log(dataOrdersExpressPendint, dataOrdersExpress);
    if (!loadingExpress && !loadingExpressPending) {
      if (dataOrdersExpress && dataOrdersExpressPendint) {
        const arrayUnido = dataOrdersExpress.concat(dataOrdersExpressPendint);

        mergeExpressOrders(arrayUnido);
      }
    }
  }, [
    loadingExpress && loadingExpressPending,
    dataOrdersExpressPendint,
    dataOrdersExpress,
  ]);

  React.useEffect(() => {
    //console.log(dataOrdersBicci);
    if (
      !loadingBicciPending &&
      !loadingBicci &&
      dataOrdersBicciPending &&
      dataOrdersBicci
    ) {
      const arrayUnido2 = dataOrdersBicci.concat(dataOrdersBicciPending);

      mergeBicciOrders(arrayUnido2);
    }
  }, [loadingBicciPending, loadingBicci]);

  React.useEffect(() => {
    if (loadingContextBicci && loadingContextExpress) {
      if (mergedOrdersBicci && mergedOrdersExpress) {
        // console.log(mergedOrdersExpress);
        // console.log(mergedOrdersBicci);
        mergeBicciExpress(mergedOrdersExpress, mergedOrdersBicci);
      }
    }
  }, [
    loadingContextBicci,
    loadingContextExpress,
    mergedOrdersBicci,
    mergedOrdersExpress,
  ]);

  //Bicci

  const findOrderBicci = async (companyId, initDate, endDate) => {
    setLoadingBicci(true);
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + days);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + daysfinal);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef1 = collection(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const q = query(
            ordersDispachtRef1,
            where("companyID", "==", companyId),
            where("createdAt", ">=", rangInit),
            where("createdAt", "<=", rangFinal)
          );

          const querySnapshot1 = await getDocs(q);

          const ordersPropietary1 = querySnapshot1.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDataOrdersBicci(ordersPropietary1);
          setLoadingBicci(false);
        } catch (error) {
          // console.log("error 179");
          setLoadingBicci(false);
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef1 = collection(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const q = query(
            ordersDispachtRef1,
            where("companyID", "==", companyId),
            where("createdAt", ">=", rangInit),
            where("createdAt", "<=", rangFinal)
          );

          const querySnapshot1 = await getDocs(q);

          const ordersPropietary1 = querySnapshot1.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDataOrdersBicci(ordersPropietary1);
          setLoadingBicci(false);
        } catch (error) {
          // console.log("error 206");
          setLoadingBicci(false);
        }
      }
    } catch (error) {
      setLoadingBicci(false);
    }
  };

  const findOrderBicciPendinst = async (companyId) => {
    setLoadingBicciPending(true);

    try {
      callExceptions();

      async function callExceptions() {
        try {
          const ordersDispachtRef1 = collection(
            db,
            process.env.REACT_APP_COL_USERS_ORDERS
          );
          const q = query(
            ordersDispachtRef1,
            where("companyID", "==", companyId),
            where("status", ">", 0),
            where("status", "<", 8)
          );

          const querySnapshot1 = await getDocs(q);

          const ordersPropietary1 = querySnapshot1.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setDataOrdersBicciPending(ordersPropietary1);
          setLoadingBicciPending(false);
        } catch (error) {
          // console.log("error 244");
          setLoadingBicciPending(false);
        }
      }
    } catch (error) {
      setLoadingBicciPending(false);
    }
  };

  const mergeBicciOrders = (mergedArray) => {
    // console.log(mergedArray);
    setMergedOrdersBicci(mergedArray);
    setLoadingContextBicci(true);
  };

  //Express

  const findOrderExpress = async (companyId, initDate, endDate) => {
    setLoadingExpress(true);
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + days);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + daysfinal);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setDataOrdersExpress(ordersPropietary);
          setLoadingExpress(false);
        } catch (error) {
          // console.log("error 317");

          setLoadingExpress(false);
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setDataOrdersExpress(ordersPropietary);
          setLoadingExpress(false);
        } catch (error) {
          // console.log("error 345");
          // console.log(error);
          setLoadingExpress(false);
        }
      }
    } catch (error) {
      setLoadingExpress(false);
    }
  };

  const findOrderExpressPendint = async (companyId, initDate, endDate) => {
    setLoadingExpress(true);
    // console.log(initDate, endDate);
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + days);
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + daysfinal);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setDataOrdersExpressPendint(ordersPropietary);
          setLoadingExpressPending(false);
        } catch (error) {
          // console.log("error 317");

          setLoadingExpressPending(false);
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setDataOrdersExpress(ordersPropietary);
          setLoadingExpress(false);
        } catch (error) {
          // console.log("error 443");
          // console.log(error);
          setLoadingExpress(false);
        }
      }
    } catch (error) {
      setLoadingExpress(false);
    }
  };

  const mergeExpressOrders = (mergedArray) => {
    // console.log(mergedArray);
    setMergedOrdersExpress(mergedArray);
    setLoadingContextExpress(true);
  };

  //Merge and return

  const mergeBicciExpress = (Orders, OrdersBicci) => {
    if (Orders && OrdersBicci) {
      let mergedArray = Orders.map((item) => {
        let found = OrdersBicci.find(
          (innerItem) => innerItem.id === item.orderID
        );
        return {
          ...item,
          ...found,
        };
      });

      let groupedByStatus = mergedArray.reduce((accumulator, currentItem) => {
        const statusText =
          translateStatesBicciFilter[currentItem.status] || "nocofirmacion";

        if (!accumulator[statusText]) {
          accumulator[statusText] = [];
        }
        accumulator[statusText].push(currentItem);
        return accumulator;
      }, {});

      setMergedOrders(groupedByStatus);
      setLoadingContext(false);
    }
  };
  return {
    mergedOrders,
    loadingContext,
    initDate,
    endDate,
    setInitDate,
    setEndDate,
  };
}

export default useGetOrdersDataByCompany;
