import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider } from "semantic-ui-react";
import {
  fetcherOrdersUserdriver,
  fetcherUserdriver,
} from "../../../utils/FectcherData/FectcherUser";

const DriversDetails = (props) => {
  const { id } = useParams();
  const [user, setUserData] = useState(null);
  const [driverOperationData, setDriverOperationData] = useState(null);
  const [driverOperationDataAgroup, setDriverOperationDataAgroup] =
    useState(null);

  useEffect(() => {
    if (id) {
      buscarConductor(id);
    }
  }, [id]);

  useEffect(() => {
    if (driverOperationData) {
      const groupedOrders = groupOrdersByDate(driverOperationData);
      setDriverOperationDataAgroup(groupedOrders);
    }
  }, [driverOperationData]);

  const buscarConductor = async (id) => {
    let userData = await fetcherUserdriver(id);
    let Orders = await fetcherOrdersUserdriver(id);
    setDriverOperationData(Orders);
    setUserData(userData);
  };

  function timestampToDate(timestamp) {
    const date = new Date(timestamp.seconds * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function groupOrdersByDate(orders) {
    return orders.reduce((acc, order) => {
      const date = timestampToDate(order.createdAt);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(order);
      return acc;
    }, {});
  }

  function printOrdersCountByDate(groupedOrders) {
    const elements = [];
    for (const [date, orders] of Object.entries(groupedOrders)) {
      elements.push(
        <div
          key={date}
        >{`${date}        =>   Ordenes asignadas : ${orders.length} `}</div>
      );
    }
    return elements;
  }

  return user ? (
    <Grid sx={{ m: 1 }}>
      <Grid>
        <h2 className="h2-withe">Detalles de : {id}</h2>
      </Grid>
      <Grid container spacing={3} sx={{ m: 1 }}>
        <Grid xs={12} md={2}>
          {/* id="mi-cuentaContainer"> */}
          <Card sx={{ m: 1 }}>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={user.avatar}
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {user.name}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                >
                  {user.dni}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.bicciNumber}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textPrimary"
                  gutterBottom
                  variant="body"
                >
                  {user.phone}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {`${user.email}`}
                </Typography>
                <Typography
                  fontFamily="Lato, Arial, Helvetica, sans-serif"
                  color="textSecondary"
                  variant="body"
                >
                  {user.device}
                </Typography>
              </Box>
            </CardContent>
            <Divider />
            <CardActions>
              {/* <Button
        color="primary"
        fullWidth
        variant="text"
        
      >
        Actualizar foto
      </Button> */}
            </CardActions>
          </Card>
          <br></br>
        </Grid>

        <Grid xs={12} md={6}>
          <Card sx={{ m: 1 }}>
            {/* className="typeMiniforms"> */}
            <CardContent>
              <h3>Detalles</h3>
              <div>Totales : {driverOperationData.length}</div>
              <div>
                Entregadas :{" "}
                {driverOperationData.filter((item) => item.status === 8).length}
              </div>
              <div>
                No entregadas :{" "}
                {driverOperationData.filter((item) => item.status !== 8).length}
              </div>
            </CardContent>
            <Divider />
            <CardActions></CardActions>
          </Card>
          <Card sx={{ m: 1 }}>
            {/* className="typeMiniforms"> */}
            <CardContent>
              {driverOperationDataAgroup ? (
                <>{printOrdersCountByDate(driverOperationDataAgroup)}</>
              ) : null}
            </CardContent>
            <Divider />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default DriversDetails;
