import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Card,
} from "@mui/material";
import { Grid } from "semantic-ui-react";
import React, { useEffect, useMemo, useState } from "react";
import GuiasLateralBar from "./Views/GuiasLateralBar";
import GuiasToolsBars from "./Views/GuiasToolsBars";
import GuiasViewBody from "./Views/GuiasViewBody";
import * as XLSX from "xlsx";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import useFindOrderBicciexpress from "../../../utils/UtilsOrders/useFindOrderBicciexpress";
import useFindOrderBicciexpressPendingPayment from "../../../utils/UtilsOrders/useFindOrderBicciexpressPendingPayment";
import { useDataProcessing } from "../../../hooks/useDataProcessing";
import { useDispatch } from "react-redux";
import { updateOrdersBicciexpressPayments } from "../../../redux/accionesOrders";
import { useDataProcessingProforma } from "../../../hooks/useDataProcessingProforma";
import generarReporteProformas from "../../../utils/Reports/generarReporteProformas";
import { useDataProcessingStates } from "../../../hooks/useDataProcessingStates";
import { useDataProcessingStatesApelaciones } from "../../../hooks/useDataProcessingStatesApelaciones";
import { fetcherUserSuportTickets } from "../../../utils/FectcherData/FectcherData";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const GuiasFacturacion = (props) => {
  const dispacth = useDispatch();

  const companyId = useMemo(() => {
    if (props.usuarioStorage) {
      return props.usuarioStorage.company;
    }
    return "";
  }, [props.usuarioStorage]);

  const drawerWidth = 240;
  const [initDate, setInitDate] = useState(""); //Moment(Date.now()).format("YYYY-MM-DD"));

  const [endDate, setEndDate] = useState(""); //Moment(Date.now()).format("YYYY-MM-DD"));

  const [modulesView, setModulesWiew] = useState("all");

  const [viewOrders, setViewOrder] = useState([]);

  const [dataPayment, setDataPayment] = useState(null);

  const [dataApelaciones, setDataApelaciones] = useState(null);

  const [dataStates, setDataStates] = useState(null);

  const [dataApe, setDataApe] = useState(null);

  const [isActive, setIsActive] = useState(false);

  const [isActiveUpdateProf, setisActiveUpdateProf] = useState(false);

  const [isActiveUpdateState, setIsActiveUpdateState] = useState(false);

  const [isActiveUpdateApe, setisActiveUpdateApe] = useState(false);

  const [menssaje, setMessaje] = useState("");

  const progress = useDataProcessing(viewOrders, isActive);

  const progressBilling = useDataProcessingProforma(
    dataPayment,
    isActiveUpdateProf
  );

  const progressState = useDataProcessingStates(
    dataStates,
    isActiveUpdateState
  );

  const progressApe = useDataProcessingStatesApelaciones(
    dataApe,
    isActiveUpdateApe
  );

  const { dataOrders, loading } = useFindOrderBicciexpress(
    companyId,
    initDate,
    endDate
  );

  const { dataOrdersPending, loadingPendints } =
    useFindOrderBicciexpressPendingPayment(companyId);

  useEffect(() => {
    // // console.log("Datos de salida", dataOrders, loading);
  }, [initDate, endDate, loading]);

  useEffect(() => {
    //  // console.log(menssaje);
    if (companyId) {
      getData(companyId);
    }
  }, [companyId, menssaje]);

  const getData = async () => {
    const tickets = await fetcherUserSuportTickets(companyId);

    let filtertickets = tickets.filter((item) => item.type === "billingTicket");

    if (filtertickets) {
      setDataApelaciones(filtertickets);
    } else {
      setDataApelaciones([]);
    }
  };

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(createDate.value);
    setEndDate(FinalDate.value);
  };

  const onClikBuscarCambios = (data) => {
    //  // console.log(viewOrders);
    if (viewOrders.length > 0) {
      setMessaje("");
      setIsActive(true);
    } else {
      //  // console.log("No hay registros seleccionado");
      setMessaje("No hay registros seleccionado");
      setIsActive(false);
    }
  };

  const loadDataPayment = () => {
    if (dataPayment && dataPayment.length > 0) {
      setMessaje("");
      setisActiveUpdateProf(true);
    } else {
      //   // console.log("No hay archivo de carga");
      setMessaje("No hay archivo de carga");
      setisActiveUpdateProf(false);
    }
  };

  const loadDataState = () => {
    if (dataStates && dataStates.length > 0) {
      setMessaje("");
      setIsActiveUpdateState(true);
    } else {
      //  // console.log("No hay archivo de carga");
      setMessaje("No hay archivo de carga");
      setIsActiveUpdateState(false);
    }
  };

  const loadDataApe = () => {
    if (dataApe && dataApe.length > 0) {
      setMessaje("");
      setisActiveUpdateApe(true);
    } else {
      //    // console.log("No hay archivo de carga");
      setMessaje("No hay archivo de carga");
      setisActiveUpdateApe(false);
    }
  };

  const donwsLoadRegister = (data) => {
    // console.log(data);
  };

  const handleFileProforma = async (e) => {
    // console.log("Aqui Prof");

    if (e.target.files.length > 0) {
      setMessaje("seleccionado 1 Archivo");
    } else {
      setMessaje("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    // console.log(file);
    const data = await file.arrayBuffer();
    const woskbook = XLSX.read(data);

    const worksheet = woskbook.Sheets["DATA"];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    try {
      const paymentProforma = jsonData[0]["PaymentProforma"];
      const paymentValor = jsonData[0]["PaymentValor"];
      const paymentTipoServicio = jsonData[0]["PaymentType_service"];
      const bicciId = jsonData[0]["BICCIID"];
      // PaymentProforma // PaymentValor // PaymentType_service // BICCIID
      if (paymentProforma && paymentValor && paymentTipoServicio && bicciId) {
        setMessaje("un archivo seleccionado");
        setDataPayment(jsonData);
        // props.setDocumentData(jsonData);
        // props.setDataType("billing");
        // props.setRender(!props.remder);
      } else {
        paymentProforma
          ? setMessaje("Valido")
          : setMessaje("No se encontro nombre paymentProforma");
        paymentValor
          ? setMessaje("Valido")
          : setMessaje("No se encontro nombre paymentValor");
        paymentTipoServicio
          ? setMessaje("Valido")
          : setMessaje("No se encontro nombre ");
        bicciId
          ? setMessaje("Valido")
          : setMessaje("No se encontro nombre BICCIID");
      }
    } catch (error) {
      setMessaje("No se encuentra la hoja con nombre DATA");
    }
  };

  const handleFileStates = async (e) => {
    //  // console.log("Aqui handle File States");

    if (e.target.files.length > 0) {
      setMessaje("seleccionado 1 Archivo");
    } else {
      setMessaje("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook2 = XLSX.read(data);

    const worksheetState = woskbook2.Sheets["DATA_ESTADOS"];

    if (worksheetState) {
      const jsonDataSate = XLSX.utils.sheet_to_json(worksheetState);
      //    // console.log("Procesar Estados");

      try {
        const id = jsonDataSate[0]["ID"];
        const state = jsonDataSate[0]["ESTADO"];
        const baja = jsonDataSate[0]["BAJA"];
        const fecha = jsonDataSate[0]["FECHABAJA"];
        const comentario = jsonDataSate[0]["COMENTARIOBAJA"];
        const motivo = jsonDataSate[0]["MOTIVO"];
        if (id && state && baja && fecha) {
          setDataStates(jsonDataSate);
        } else {
          setMessaje(
            "Confirme el Nombre de las columnas  ID	ESTADO	BAJA	FECHABAJA	COMENTARIOBAJA	MOTIVO"
          );
        }
      } catch (error) {
        setMessaje("No se encuentra la hoja con nombre DATA_ESTADOS");
      }
    }
  };

  const handleFileApelacion = async (e) => {
    // console.log("Aqui handle File States");

    if (e.target.files.length > 0) {
      setMessaje("seleccionado 1 Archivo");
    } else {
      setMessaje("Ningún archivo seleccionado.");
    }

    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const woskbook3 = XLSX.read(data);

    const worksheetApe = woskbook3.Sheets["DATA_APELACIONES"];

    if (worksheetApe) {
      const jsonDataApe = XLSX.utils.sheet_to_json(worksheetApe);
      //  // console.log("Procesar Apelacion");
      //  // console.log(jsonDataApe);
      try {
        const id = jsonDataApe[0]["ID"];
        const State = jsonDataApe[0]["ESTADO"];
        const Size = jsonDataApe[0]["TALLA"];
        const alto = jsonDataApe[0]["ALTO"];
        const ancho = jsonDataApe[0]["ANCHO"];
        const largo = jsonDataApe[0]["LARGO"];
        const mt3 = jsonDataApe[0]["M3"];
        if (id && State && Size) {
          setDataApe(jsonDataApe);
        } else {
          setMessaje(
            "Confirme el Nombre de las columnas  ID // ESTADO // TALLA // ALTO // ANCHO // LARGO // M3"
          );
        }
      } catch (error) {
        setMessaje("No se encuentra la hoja con nombre DATA_APELACIONES");
      }
    }
  };

  const onSelectView = (e) => {
    setModulesWiew(e);
  };

  const exportartReportePagos = (data) => {
    generarReporteProformas(data);
  };

  return (
    <Grid celled="internally" id="expresscuentaContent">
      <Grid.Row>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={16}
          className="dash-container-colmun"
        >
          <Card className="typeMiniforms">
            <Box
              sx={{
                padding: "20px",
                width: "100%",
                minHeight: "100%",
                height: "100%",
                backgroundColor: "#f8f8f8",
                margin: "0px",
              }}
            >
              {/* Barra superior */}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#F8F8F8",
                }}
              >
                <Typography variant="h3" sx={{ m: 2 }}>
                  Facturación{" "}
                  {/* <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: "none" }) }}
                  >
                    <MenuIcon />
                  </IconButton> */}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    float: "right !important",
                    right: "5% !important",
                    marginRight: "5% !important",
                  }}
                >
                  {progress ? `${progress.toFixed(2)}%` : null}
                  {progressBilling ? `${progressBilling.toFixed(2)}%` : null}
                  {progressState ? `${progressState.toFixed(2)}%` : null}

                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => {
                      setIsActive(!isActive);
                      setMessaje("");
                    }}
                    edge="start"
                    sx={{ m: 2, ...(open && { display: "none" }) }}
                  >
                    {progress && isActive ? (
                      <>
                        <PauseCircleIcon /> Pausar
                      </>
                    ) : (
                      <>
                        <PlayCircleIcon /> Actualizar
                      </>
                    )}
                  </IconButton>
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    position: "absolute",
                    float: "right !important",
                    right: "5% !important",
                    marginRight: "5% !important",
                  }}
                >
                  {"_> "}
                  {menssaje}
                </Typography>
              </Box>
              <Box>
                <Grid
                  sx={{
                    height: "100%",
                    width: open ? `calc(100% - ${drawerWidth + 5}px)` : "100%",
                    border: "1px solid  grey",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      border: "2px solid #030303",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <GuiasToolsBars
                      onChageRangePiker={onChageRangePiker}
                      onSelectView={onSelectView}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      border: "2px solid green",
                      width: "100%",
                      height: "450px",
                    }}
                  >
                    <GuiasViewBody
                      modulesView={modulesView}
                      initDate={initDate}
                      endDate={endDate}
                      dataOrders={dataOrders}
                      loading={loading}
                      dataOrdersPending={dataOrdersPending}
                      loadingPendints={loadingPendints}
                      viewOrder={viewOrders}
                      setViewOrder={setViewOrder}
                      onClikBuscarCambios={onClikBuscarCambios}
                      exportartReportePagos={exportartReportePagos}
                      handleFileProforma={handleFileProforma}
                      handleFileStates={handleFileStates}
                      handleFileApelacion={handleFileApelacion}
                      loadDataPayment={loadDataPayment}
                      loadDataState={loadDataState}
                      loadDataApe={loadDataApe}
                      dataApelaciones={dataApelaciones}
                      donwsLoadRegister={donwsLoadRegister}
                    />
                  </Grid>
                </Grid>
                {/* 
                <Drawer
                  sx={{
                    width: drawerWidth,
                    marginTop: "72px !important",
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                      boxSizing: "border-box",
                    },
                  }}
                  variant="persistent"
                  anchor="right"
                  open={open}
                >
                  <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </DrawerHeader>
                  <Divider />
                  <List>
                    {["Inbox", "Starred", "Send email", "Drafts"].map(
                      (text, index) => (
                        <ListItem key={text} disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                          </ListItemButton>
                        </ListItem>
                      )
                    )}
                  </List>
                  <Divider />
                  <List>
                    {["All mail", "Trash", "Spam"].map((text, index) => (
                      <ListItem key={text} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                          </ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
                 */}
              </Box>
            </Box>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GuiasFacturacion;
