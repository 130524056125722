import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function useGetInfo() {
  const [totalDelivery, setTotalDelivery] = useState(36000);
  const [totalCal, setTotalCal] = useState(26000);

  useEffect(() => {
    callUptade();
  }, []);

  async function callUptade() {
    try {
      const ordersDispachtRef = collection(
        db,
        process.env.REACT_APP_COL_ORDERS
      );
      const querySnapshot = await getDocs(ordersDispachtRef);
      const totalDocuments = querySnapshot.size + 35000;
      setTotalDelivery(totalDocuments);
      let estimaet = totalDelivery * 0.0005;
      setTotalCal(estimaet + 26000);
    } catch (error) {}
  }
  return { totalDelivery, totalCal };
}

export default useGetInfo;
