import React, { useState, useContext, useMemo, useRef } from "react";
import useGlobalConfig from "../../../hooks/globalConfig";
import { useSelector } from "react-redux";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import Moment from "moment";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import { format } from "date-fns";
import { db } from "../../../firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { retiredPodArray } from "../../../utils/objects/retiredPodArray";
import useGetVehiclesNames from "../../../hooks/getVehiclesNames";

const FechtDataMaps = (stateUser, vertical) => {
  const { falabellaExcepcions } = useGlobalConfig();
  var listadeItemsNoMach = [];

  const companyId = useMemo(() => {
    if (stateUser && stateUser.companyCollaborator) {
      if (stateUser.companyCollaborator === "falabella") {
        return `${process.env.REACT_APP_BXTOKETN}`;
      }
      return stateUser.userCompanyID;
    }
    return "";
  }, [stateUser]);

  const { VehiclesDataByCompany } = useGetDataVehiclesByCompany(companyId);

  const { VehiclesNames } = useGetVehiclesNames();

  const [falabellaOrders, setFalabellaOrders] = useState([]);

  const [BicciOrders, setBicciOrders] = useState([]);

  const [dataMerge, setDataMerge] = useState([]);

  const [itemsDataOrdersForAccions, setItemsDataOrdersForAccions] =
    React.useState(false);

  const initDate = Moment(Date.now()).format("YYYY-MM-DD");

  const endDate = Moment(Date.now()).format("YYYY-MM-DD");

  const [render, setRender] = useState(true);

  const [realTimeisActive, setRealTimeisActive] = React.useState(true);

  const [containerHistory, setContainerHistory] = React.useState({});

  const [metricsOps, setMetricsOps] = React.useState([]);
  const [metricsOpsStores, setMetricsOpsStores] = useState([]);

  const [listIdstoSelct, setListIdstoSelct] = React.useState("");
  const [CheckedChangeOE, setCheckedChangeOE] = React.useState(false);

  const [ordersHistoryGetProgres, setOrdersHistoryGetProgres] =
    React.useState(0);
  const [typeRequets, setTypeRequets] = React.useState({
    typeRequetsCreate: false,
    typeRequetsUpdate: true,
  });

  const [resetCicle, setResetCicle] = React.useState({
    currentRequest: 0,
    maxRequest: 10,
    desactiveRealtime: 150,
  });

  const [bicciOrdersExcepData, setBicciOrdersExcepData] = useState([]);

  const [typeRquest, setTypeRquest] = React.useState(false);

  const isMounted = useRef(true);

  const toggleShowRefres = () => setRender(!render);

  const [cantListItems, setcantListItems] = React.useState({
    Creadas: 0,
    Ruta: 0,
    Incidencias: 0,
    Finalizadas: 0,
    Total: 0,
  });

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  React.useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispachtFalabella(
        initDate,
        endDate,
        typeRequets,
        companyId,
        vertical
      );
      findOrdesrDispachtBicci(
        initDate,
        endDate,
        typeRequets,
        companyId,
        vertical
      );
    }
  }, [companyId, initDate, endDate, vertical]);

  React.useEffect(() => {}, [itemsDataOrdersForAccions]);

  React.useEffect(() => {
    if (
      BicciOrders.length > 0 ||
      falabellaOrders.length > 0 ||
      bicciOrdersExcepData.length > 0
    ) {
      BiccidataMerge();
    }
  }, [BicciOrders, falabellaOrders, bicciOrdersExcepData]);

  React.useEffect(() => {
    isMounted.current = true;

    findOrdesrDispachtFalabella(
      initDate,
      endDate,
      typeRquest,
      companyId,
      vertical
    );
    findOrdesrDispachtBicci(
      initDate,
      endDate,
      typeRequets,
      companyId,
      vertical
    );
    return () => {
      isMounted.current = false;
    };
  }, [CheckedChangeOE, typeRequets, vertical]);

  React.useEffect(() => {
    if (dataMerge) {
      getResumenOps();
    }
  }, [dataMerge]);

  React.useEffect(() => {
    let interval;
    if (realTimeisActive === true) {
      //console.log(" React.useEffect 3 ");
      interval = setInterval(() => {
        resetCicle.currentRequest += 1;
        setCheckedChangeOE(false);
        findOrdesrDispachtFalabella(
          initDate,
          endDate,
          typeRquest,
          companyId,
          vertical
        );
        findOrdesrDispachtBicci(
          initDate,
          endDate,
          typeRequets,
          companyId,
          vertical
        );

        if (resetCicle.maxRequest === resetCicle.currentRequest) {
          setRender(!render);
        }
        if (resetCicle.desactiveRealtime === resetCicle.currentRequest) {
          setRealTimeisActive(!realTimeisActive);
          setRender(!render);
        }
      }, 60000);
    }
    return () => clearInterval(interval);
  }, [realTimeisActive]);

  React.useEffect(() => {
    if (listIdstoSelct) {
      selecListItames(listIdstoSelct);
    }
  }, [listIdstoSelct]);

  React.useEffect(() => {}, [render]);

  const findOrdesrDispachtFalabella = async (
    initDate,
    endDate,
    typeRquest,
    companyId
  ) => {
    //console.log("findOrdesrDispacht Falabella");

    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "updatedAt";
    }

    const allOrdersRef = collection(
      db,
      process.env.REACT_APP_COL_FALABELLA_ORDERS
    );
    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }
    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate());
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    function formatDate(fecha) {
      var res = new Date(fecha);
      res.setHours(23);
      res.setMinutes(59);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }

    var rangFinal = sumarDos(e);
    var rangInitial = formatDate(i);
    const dataOrder = {
      data: "",
      get dataEbiex() {
        return this.data;
      },
      set dataEbiex(data) {
        this.data = data;
      },
    };
    dataOrder.data = [];
    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    async function callExceptions() {
      try {
        const allOrdersRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(allOrdersRef, where("falabella_status", "!=", "99"));
        const querySnapshot = await getDocs(q);

        const dataOrdesHasExcepciones = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dataOrdesHasExcepciones.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );
        let filtrarSolucionados = dataOrdesHasExcepciones.filter(
          (item) =>
            (item.falabella_status !== "1029") &
            (item.falabella_status !== "1046") &
            (item.falabella_status !== "1023") &
            (item.falabella_status !== "1081") &
            (item.falabella_status !== "1007") &
            (item.falabella_status !== "99")
        );

        let dataOrdesfilterHasExcepciones = filtrarSolucionados.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );

        let clearRang = dataOrder.data;
        const arrayId = clearRang.map((obj) => obj.id);

        let clearDataOrdesfilterHasExcepciones =
          dataOrdesfilterHasExcepciones.filter(
            (item) => !arrayId.includes(item.id.toString())
          );

        if (clearDataOrdesfilterHasExcepciones.length > 0) {
          //console.log(clearDataOrdesfilterHasExcepciones);
          let arryExcepcion = [];
          for (let i = 0; i < clearDataOrdesfilterHasExcepciones.length; i++) {
            arryExcepcion.push("F" + clearDataOrdesfilterHasExcepciones[i].id);
          }
          // console.log(arryExcepcion);
          try {
            const ordersCollectionRef = collection(
              db,
              process.env.REACT_APP_COL_USERS_ORDERS
            );
            const querySnapshot = await getDocs(
              query(ordersCollectionRef, where("id", "in", arryExcepcion))
            );
            const orders = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            //console.log("orders");
            //console.log(orders);

            setBicciOrdersExcepData(orders); // En test
          } catch (error) {}
          if (CheckedChangeOE === false) {
            // console.log("Mostrar incidencias activas");
            const resultoFind = dataOrdesfilterHasExcepciones.concat(
              dataOrder.data
            );

            //setResumenTable(resultoFind);
            setFalabellaOrders(resultoFind);
          }

          if (CheckedChangeOE !== false) {
            //console.log("Ocultar incidencias activas");
            //setResumenTable(dataOrder.data);
            setFalabellaOrders(dataOrder.data);
          }
        } else {
          //setResumenTable(dataOrder.data);
          setFalabellaOrders(dataOrder.data);

          setDataMerge(dataOrder.data); // QA
        }
        //await BiccidataMerge();
      } catch (error) {}

      toggleShowRefres();
    }

    async function callNormal() {
      try {
        const q = query(
          allOrdersRef,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", endRang)
        );
        const querySnapshot = await getDocs(q);
        var dataOrdes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        //   //////console.log(dataOrdes)

        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );

        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {
        //console.log(error);
      }
    }

    async function callIgual() {
      try {
        const dataallOrdersfind5 = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const q = query(
          dataallOrdersfind5,
          where(temptypeRquestCall, ">=", rangInitial),
          where(temptypeRquestCall, "<=", rangFinal)
        );
        const querySnapshot5 = await getDocs(q);
        var dataOrdes = querySnapshot5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        ////console.log(dataOrdes)
        dataOrdes.sort((a, b) =>
          a.updatedAt.seconds < b.updatedAt.seconds ? 1 : -1
        );
        let dataOrdesfilter = dataOrdes.filter(
          (item) => !retiredPodArray.includes("F" + item.id.toString())
        );
        dataOrder.data = dataOrdesfilter;
        callExceptions();
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const findOrdesrDispachtBicci = async (
    initDate,
    endDate,
    typeRequets,
    companyId
  ) => {
    //console.log("findOrdesrDispacht Bicci");

    const i = new Date(initDate);
    const e = new Date(endDate);

    let temptypeRquestCall;
    if (typeRequets.typeRequetsCreate === true) {
      temptypeRquestCall = "createdAt";
    }
    if (typeRequets.typeRequetsUpdate === true) {
      temptypeRquestCall = "createdAt";
    }

    function sumarUno(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var endRang = sumarUno(e);

    function restaUno(fecha2) {
      fecha2.setDate(fecha2.getDate() - 5);
      return fecha2;
    }

    function sumarDos(fecha) {
      var res = new Date(fecha);
      res.setDate(res.getDate() + 1);
      return res;
    }

    var rangInit = restaUno(i);
    var rangFinal = sumarDos(e);

    if (initDate !== endDate) {
      callNormal();
    }
    if (initDate === endDate) {
      callIgual();
    }

    async function callNormal() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", i),
          where(temptypeRquestCall, "<=", endRang)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (bicciOrdersExcepData.length > 0) {
          let mergedate = bicciOrdersExcepData.concat(dataOrdesBicci);
          setBicciOrders(mergedate);
        } else {
          setBicciOrders(dataOrdesBicci);
        }
      } catch (error) {
        //console.log(error);
      }
    }

    async function callIgual() {
      try {
        const orderRef = collection(db, process.env.REACT_APP_COL_USERS_ORDERS);
        const q = query(
          orderRef,
          where(temptypeRquestCall, ">=", rangInit),
          where(temptypeRquestCall, "<=", rangFinal)
        );

        const querySnapshot = await getDocs(q);

        const dataOrdesBicci = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBicciOrders(dataOrdesBicci);
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const BiccidataMerge = async () => {
    var newdataMere = [];
    var DataOrders = BicciOrders;
    var DataOrdersExcep = bicciOrdersExcepData;

    async function initMerge(newdataMere) {
      const uniqueMap = new Map();

      async function removeDuplicatesById(array) {
        for (const item of array) {
          if (!uniqueMap.has(item.id)) {
            uniqueMap.set(item.id, item);
          }
        }

        return Array.from(uniqueMap.values());
      }

      var DataMerge = await removeDuplicatesById(falabellaOrders);

      for (let i = 0; i < DataMerge.length; i++) {
        for (let n = 0; n < newdataMere.length; n++) {
          let socf = "F" + DataMerge[i].id;

          if (socf === newdataMere[n].id) {
            // ////console.log(falabellaOrders[i])

            DataMerge[i].placeName = newdataMere[n].placeName;
            DataMerge[i].driverName = newdataMere[n].driverName;
            DataMerge[i].driverAvatar = newdataMere[n].driverAvatar;
            DataMerge[i].driverID = newdataMere[n].driverID;
            DataMerge[i].userNameReceptor = newdataMere[n].userNameReceptor;
            DataMerge[i].userDniReceptor = newdataMere[n].userDniReceptor;
            DataMerge[i].pics = newdataMere[n].pics;
            DataMerge[i].toAddressLocation = newdataMere[n].toAddressLocation;
            DataMerge[i].observationReceptor =
              newdataMere[n].observationReceptor;
            DataMerge[i].observationAdmin = newdataMere[n].observationAdmin;
            DataMerge[i].upDatedAtText = format(
              DataMerge[i].updatedAt.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
            DataMerge[i].dateCreateText = format(
              DataMerge[i].date.seconds * 1000,
              "dd/MM/yy hh:mm:ss"
            ).toString();
          }
        }
      }

      const filteredDataOrdersExcepVertical = DataMerge.filter((item) => {
        if (vertical === "all") {
          return true; // Incluye todos los tipos
        } else if (vertical === "HD") {
          return item.delivery_type === "HD" || item.delivery_type === "HDF";
        } else {
          return item.delivery_type === vertical; // Para "SD" o cualquier otro tipo específico
        }
      });
      //  console.log(filteredDataOrdersExcepVertical);
      setDataMerge(filteredDataOrdersExcepVertical);
      setResumenTable(filteredDataOrdersExcepVertical);
    }
    //console.log("newdataMere");
    //console.log(falabellaOrders);
    //console.log(BicciOrders);
    if (DataOrders.length > 0 || DataOrdersExcep.length > 0) {
      const dataOrdersIdSet = new Set(DataOrders.map((item) => item.id));
      const filteredDataOrdersExcep = DataOrdersExcep.filter(
        (item) => !dataOrdersIdSet.has(item.id)
      );

      newdataMere = DataOrders.concat(filteredDataOrdersExcep);
    }
    //console.log(newdataMere);
    await initMerge(newdataMere);
  };

  const getResumenOps = async () => {
    var listRiders = [];
    var comunasDelivery = [];
    var ListStores = [];
    try {
      if (dataMerge && dataMerge.length > 0) {
        for (let n = 0; n < dataMerge.length; n++) {
          if (!listRiders.includes(dataMerge[n].driverID)) {
            listRiders.push(dataMerge[n].driverID);
          }
          if (!comunasDelivery.includes(dataMerge[n].commune2)) {
            comunasDelivery.push(dataMerge[n].commune2);
          }
        }

        const localSet = new Set();

        dataMerge.forEach((order) => {
          localSet.add(order.local_provider);
        });

        const ListStores = Array.from(localSet);

        async function getOrdersByLocal(data, ListStores) {
          const orders = data.filter(
            (order) => order.falabella_status === "99" && order.status !== "99"
          );

          const listStoreOriging = {};
          orders.forEach((order) => {
            const local = order.local_provider;
            if (!listStoreOriging[local]) {
              listStoreOriging[local] = {
                delivered: 0,
                withIssues: 0,
              };
              if (ListStores.includes(local)) {
                listStoreOriging[local].localName = local;
              }
            }
            if (order.status === "99") {
              listStoreOriging[local].withIssues++;
            } else {
              listStoreOriging[local].delivered++;
            }
          });

          let arraylistRidersJsonString = Object.values(listStoreOriging);

          return arraylistRidersJsonString;
        }

        let listStoreOriging = await getOrdersByLocal(dataMerge, ListStores);

        var listRidersJsonString = {};

        var conuters = {};
        var conutersDelivery = {};
        var conutersIssures = {};

        var countersListOrders = {};

        var ordersContent = {};
        var listOrderContent = {};

        var countersIssuresListOrders = {};

        var conterComunes = {};
        var vehicleDetailsID = {};
        var vehicleDetailsType = {};

        const riderMap = {};
        ridersDataByCompany.forEach((rider) => {
          riderMap[rider.id] = rider.vehicleID;
        });

        const vehicleNameMap = {};
        VehiclesNames.forEach((vehicle) => {
          vehicleNameMap[vehicle.id] = vehicle.name;
        });

        const vehicleRidersMap = {};
        Object.keys(riderMap).forEach((driverID) => {
          const vehicleID = riderMap[driverID];

          const rider = ridersDataByCompany.find(
            (rider) => rider.id === driverID
          );
          if (rider && vehicleNameMap[vehicleID]) {
            vehicleRidersMap[rider.id] = vehicleNameMap[vehicleID];
          }
        });

        if (listRiders.length > 0 && VehiclesNames) {
          for (let n = 0; n < dataMerge.length; n++) {
            for (let m = 0; m < listRiders.length; m++) {
              if (dataMerge[n].driverID === listRiders[m]) {
                if (listRidersJsonString[dataMerge[n].driverID]) {
                  conuters[dataMerge[n].driverID] = {
                    value:
                      listRidersJsonString[dataMerge[n].driverID].asignados + 1,
                  };

                  if (
                    listRidersJsonString[dataMerge[n].driverID]
                      .countersListOrders
                  ) {
                    countersListOrdersContent =
                      listRidersJsonString[dataMerge[n].driverID]
                        .countersListOrders.data;
                    countersListOrdersContent.push(dataMerge[n].id);

                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };
                  } else {
                    countersListOrdersContent.push(dataMerge[n].id);
                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };
                  }

                  var countersListOrdersContent = [];

                  ordersContent = [];
                  ordersContent.push(dataMerge[n]);

                  listOrderContent[dataMerge.driverID] = ordersContent;

                  var countersListIssuresOrdersContent = [];
                  if (dataMerge[n].falabella_status === "99") {
                    conutersDelivery[dataMerge[n].driverID] = {
                      value:
                        listRidersJsonString[dataMerge[n].driverID].delivery +
                        1,
                    };
                  } else {
                    conutersIssures[dataMerge[n].driverID] = {
                      value:
                        listRidersJsonString[dataMerge[n].driverID].issures + 1,
                    };

                    if (
                      listRidersJsonString[dataMerge[n].driverID]
                        .countersIssuresListOrders
                    ) {
                      ////console.log('EXISTE countersIssuresListOrders')
                      countersListIssuresOrdersContent =
                        listRidersJsonString[dataMerge[n].driverID]
                          .countersIssuresListOrders.data;
                      countersListIssuresOrdersContent.push(dataMerge[n].id);
                      countersIssuresListOrders[dataMerge[n].driverID] = {
                        data: countersListIssuresOrdersContent,
                      };
                    } else {
                      countersListIssuresOrdersContent.push(dataMerge[n].id);
                      countersIssuresListOrders[dataMerge[n].driverID] = {
                        data: countersListIssuresOrdersContent,
                      };
                    }
                  }
                } else {
                  conuters[dataMerge[n].driverID] = { value: 1 };

                  if (dataMerge[n].falabella_status === "99") {
                    conutersDelivery[dataMerge[n].driverID] = { value: 1 };
                    conutersIssures[dataMerge[n].driverID] = { value: 0 };
                    countersListOrdersContent = [];
                    countersListOrdersContent.push(dataMerge[n].id);

                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };
                  } else {
                    conutersDelivery[dataMerge[n].driverID] = { value: 0 };
                    conutersIssures[dataMerge[n].driverID] = { value: 1 };

                    countersListOrdersContent = [];
                    countersListOrdersContent.push(dataMerge[n].id);
                    countersListOrders[dataMerge[n].driverID] = {
                      data: countersListOrdersContent,
                    };

                    countersListIssuresOrdersContent = [];
                    countersListIssuresOrdersContent.push(dataMerge[n].id);
                    countersIssuresListOrders[dataMerge[n].driverID] = {
                      data: countersListIssuresOrdersContent,
                    };
                  }
                }

                if (listRidersJsonString[dataMerge[n].driverID]) {
                  for (let x = 0; x < comunasDelivery.length; x++) {
                    if (comunasDelivery[x] === dataMerge[n].commune2) {
                      if (
                        listRidersJsonString[dataMerge[n].driverID].locality
                          .length > 0
                      ) {
                        let arrayComunes =
                          listRidersJsonString[dataMerge[n].driverID].locality;
                        let comunaContenidas = [];
                        for (let a = 0; a < arrayComunes.length; a++) {
                          let nombreComuna = Object.keys(arrayComunes[a]);
                          if (!comunaContenidas.includes(nombreComuna[0])) {
                            comunaContenidas.push(nombreComuna[0]);
                          }
                        }
                        if (!comunaContenidas.includes(dataMerge[n].commune2)) {
                          arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                        } else {
                          arrayComunes[
                            comunaContenidas.indexOf(dataMerge[n].commune2, 0)
                          ][dataMerge[n].commune2] =
                            arrayComunes[
                              comunaContenidas.indexOf(dataMerge[n].commune2, 0)
                            ][dataMerge[n].commune2] + 1;
                        }
                        conterComunes[dataMerge[n].driverID] = arrayComunes;
                      } else {
                        let arrayComunes = [];
                        arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                        conterComunes[dataMerge[n].driverID] = arrayComunes;
                      }
                    }
                  }
                } else {
                  for (let x = 0; x < comunasDelivery.length; x++) {
                    if (comunasDelivery[x] === dataMerge[n].commune2) {
                      let arrayComunes = [];
                      arrayComunes.push({ [dataMerge[n].commune2]: 1 });
                      conterComunes[dataMerge[n].driverID] = arrayComunes;
                    }
                  }
                }

                vehicleDetailsID[dataMerge[n].driverID] = {
                  value: vehicleRidersMap[dataMerge[n].driverID]
                    ? vehicleRidersMap[dataMerge[n].driverID]
                    : "No registrado",
                };
                vehicleDetailsType[dataMerge[n].driverID] = {
                  value: vehicleRidersMap[dataMerge[n].driverID]
                    ? vehicleRidersMap[dataMerge[n].driverID]
                    : "No registrado",
                };
                /*
                for (let q = 0; q < VehiclesDataByCompany.length; q++) {
                  if (
                    dataMerge[n].driverID ===
                    VehiclesDataByCompany[q].driverAssing
                  ) {
                    vehicleDetailsID[dataMerge[n].driverID] = {
                      value: VehiclesDataByCompany[q].id,
                    };
                    vehicleDetailsType[dataMerge[n].driverID] = {
                      value: VehiclesDataByCompany[q].vehiclesTypes,
                    };
                  } else {
                    if (!vehicleDetailsID[dataMerge[n].driverID]) {
                      vehicleDetailsID[dataMerge[n].driverID] = {
                        value: "No resgistrado",
                      };
                      vehicleDetailsType[dataMerge[n].driverID] = {
                        value: "No resgistrado",
                      };
                    }
                  }
                }
*/
                listRidersJsonString[dataMerge[n].driverID] = {
                  driverName: dataMerge[n].driverName,
                  driverID: dataMerge[n].driverID,
                  email: dataMerge[n].email,
                  driverAvatar: dataMerge[n].driverAvatar,
                  asignados: conuters[dataMerge[n].driverID].value,
                  //asignadosListId: conutersListId[dataMerge[n].driverID], // Nueva Variable
                  delivery: conutersDelivery[dataMerge[n].driverID].value,
                  issures: conutersIssures[dataMerge[n].driverID].value,
                  vehicleDetailsType:
                    vehicleDetailsType[dataMerge[n].driverID].value,
                  vehicleDetailsID:
                    vehicleDetailsID[dataMerge[n].driverID].value,
                  locality: conterComunes[dataMerge[n].driverID],
                  countersListOrders: countersListOrders[dataMerge[n].driverID],
                  countersIssuresListOrders:
                    countersIssuresListOrders[dataMerge[n].driverID],
                  listOrdersData: {
                    data: listOrderContent[dataMerge.driverID],
                  },
                };
              }
            }
          }
          let arraylistRidersJsonString = Object.values(listRidersJsonString);

          setMetricsOps(arraylistRidersJsonString);
          setMetricsOpsStores(listStoreOriging);
        }
      } else {
        setMetricsOps([]);
        setMetricsOpsStores([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setResumenTable = (dataOrdes) => {
    let Creadas = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1058" || item.falabella_status === "1059"
    );
    //cantListItems.Creadas = Creadas.length
    let Finalizadas = dataOrdes.filter(
      (item) => item.falabella_status === "99"
    );
    //cantListItems.Finalizadas = Finalizadas.length
    let Ruta = dataOrdes.filter(
      (item) =>
        item.falabella_status === "1060" || item.falabella_status === "1061"
    );
    //cantListItems.Ruta = Ruta.length
    let Incidencias = dataOrdes.filter((item) =>
      falabellaExcepcions.includes(item.falabella_status)
    );
    //  cantListItems.Incidencias = Incidencias.length
    //  cantListItems.Total = dataOrdes.length
    setcantListItems({
      Creadas: Creadas.length,
      Ruta: Ruta.length,
      Incidencias: Incidencias.length,
      Finalizadas: Finalizadas.length,
      Total: dataOrdes.length,
    });
  };

  const UpdateOrderForAcctions = (items) => {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = items;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === "F" + tempuserOrders[n].id) {
          //  console.log("ok");
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes("F" + listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }

    setDataMerge(tempuserOrders);
    setRender(!render);
  };

  async function selecListItames(listIdstoSelct) {
    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    listIdstoSelct = listIdstoSelct.trim();
    var listadeItems2 = listIdstoSelct.split(/[,\s]+/);

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems2.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems2[i] === "F" + tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems2.length; index++) {
      if (!ordersForAccions.includes(listadeItems2[index])) {
        listadeItemsNoMach.push(listadeItems2[index]);
      }
    }

    setDataMerge(tempuserOrders);
    setRender(!render);
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }
  }

  const handleClickSelectRiderCurrentList = (e, item) => {
    e.preventDefault();

    listadeItemsNoMach = [];
    let ordersForAccions = [];
    let dataOrdersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders.data;

    for (let i = 0; i < tempuserOrders.length; i++) {
      tempuserOrders[i].setectedItem = false;
    }
    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          tempuserOrders[n].setectedItem = true;
          ordersForAccions.push("F" + tempuserOrders[n].id);
          dataOrdersForAccions.push(tempuserOrders[n]);
        }
      }
    }

    for (let index = 0; index < listadeItems.length; index++) {
      if (!ordersForAccions.includes("F" + listadeItems[index])) {
        listadeItemsNoMach.push(listadeItems[index]);
      }
    }
    if (ordersForAccions.length > 0) {
      setItemsDataOrdersForAccions(true);
    } else {
      setItemsDataOrdersForAccions(false);
    }

    setDataMerge(tempuserOrders);
    setRender(!render);
  };

  const handleCopySelectRiderCurrentList = (e, item) => {
    e.preventDefault();
    listadeItemsNoMach = [];
    let copyOrdersForAccions = [];
    let ordersForAccions = [];
    var tempuserOrders = dataMerge;
    var listadeItems = item.countersListOrders.data;

    for (let i = 0; i < listadeItems.length; i++) {
      for (let n = 0; n < tempuserOrders.length; n++) {
        if (listadeItems[i] === tempuserOrders[n].id) {
          copyOrdersForAccions.push("F" + tempuserOrders[n].id);
        }
      }
    }

    let copyData = copyOrdersForAccions;
    navigator.clipboard.writeText(copyData);
    setRender(!render);
  };

  const handleCopySelectRiderCurrentListErrors = (e, item) => {
    e.preventDefault();
    navigator.clipboard.writeText(item);
  };

  const handleChangeOE = () => {
    setCheckedChangeOE(!CheckedChangeOE);
  };

  const handleChangetypeRequets = (e) => {
    const { name, checked } = e.currentTarget;
    if (name === "typeRequetsCreate" && checked) {
      setTypeRequets({ typeRequetsCreate: true, typeRequetsUpdate: false });
    } else {
      setTypeRequets({ typeRequetsCreate: false, typeRequetsUpdate: true });
    }
  };

  const findHistorials = async () => {
    let cantGet = falabellaOrders.length;

    let dataDocsContainer = {};

    for (let i = 0; i < cantGet; i++) {
      let porcentajeDeAvance = (i / cantGet) * 100;
      setOrdersHistoryGetProgres(porcentajeDeAvance);

      try {
        const clearID = falabellaOrders[i].id; //.replace(/F/g, "");
        const falabellaOrderRef = collection(
          db,
          process.env.REACT_APP_COL_FALABELLA_ORDERS
        );

        const statusRef = doc(falabellaOrderRef, clearID.toString());

        const statusDocs = collection(statusRef, "status");

        const dataallOrdersfind5 = await getDocs(statusDocs);
        const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        datadocuments.sort((a, b) =>
          a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
        );

        dataDocsContainer[falabellaOrders[i].id] = datadocuments;
      } catch (error) {}
    }
    setOrdersHistoryGetProgres(100);
    setContainerHistory(dataDocsContainer);
  };
  //console.log(dataMerge, metricsOps, ridersDataByCompany);
  return { dataMerge, metricsOps, ridersDataByCompany };
};

export default FechtDataMaps;
