import isEqual from "lodash/isEqual";
import { useState, useCallback, useEffect } from "react";
import { alpha } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import { useBoolean } from "../../hooks/use-boolean.js";
import Label from "../Label/Label.jsx";
import Iconify from "../Iconify/Iconify.jsx";
import Scrollbar from "../Scrollbar/Scrollbar.jsx";
import { ConfirmDialog } from "../CustomDialog/index.jsx";
import {
  UseTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../Table/index.jsx";
//
import UserDriverTableRow from "../../pages/Dashboard/Drivers/UserDriverTableRow.jsx";
import UserTableToolbar from "../../pages/Dashboard/Drivers/UserDriversTableToolbar.jsx";
import UserTableFiltersResult from "../../pages/Dashboard/Drivers/UserDriversTableFiltersResult.jsx";

// ----------------------------------------------------------------------

export default function UserListViewDrivers(props) {
  const defaultRowsPerPage = 25;
  const configTable = {
    defaultRowsPerPage: defaultRowsPerPage,
    defaultDense: false,
  };
  const table = UseTable(configTable);
  // Datos ficticios para las constantes
  useEffect(() => {}, []);

  const [userList, setUserList] = useState(props.ridersDataByCompany);

  const online = [true, false];

  const active = [true, false];

  const currentOrderList = [true, false];

  const vehicle = [
    "Eco Bicci",
    "Bicicleta",
    "Moto",
    "Auto",
    "Furgon",
    "Furgon-XL",
  ];

  const _service = []; //["HD", "SD", "EX"];

  const _orige = ["Tobalaba", "Domicos", "Egaña"];

  const roleOptions = []; //["admin", "driver", "client"];

  const USER_STATUS_OPTIONS = [
    { value: "activos", label: "Activos" },
    { value: "online", label: "Conectados" },
    { value: "bloqueado", label: "Boqueados" },
    { value: "ofline", label: "Off line" },
  ];

  const USER_OPTIONS_OTERS = [
    { value: "create", label: "Creados" },
    { value: "assing", label: "Asignados" },
    { value: "route", label: "Enruta" },
    { value: "delivery", label: "Entregados" },
    { value: "reject", label: "Devolución" },
  ];
  // ----------------------------------------------------------------------

  const STATUS_OPTIONS = [
    { value: "all", label: "All" },
    ...USER_STATUS_OPTIONS,
  ];

  const STATUS_USER_OPTIONS_OTERS = [
    { value: "allOrders", label: "All" },
    ...USER_OPTIONS_OTERS,
  ];

  const TABLE_HEAD = [
    { id: "userID", label: "Identidad", width: 180 },
    { id: "contacData", label: "datos contacto", width: 180 },
    { id: "sistemdetails", label: "sistem details", width: 220 },
    { id: "status", label: "status", width: 180 },
    { id: "acciones", label: "acciones", width: 100 },
    { id: "", width: 88 },
  ];

  const defaultFilters = {
    name: "",
    role: [],
    status: "all",
    origen: "",
    service: [],
    orderStatus: "allOrders",
    online: [],
    active: [],
    vehicle: [],
  };

  //const settings = useSettingsContext();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(userList);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter(
      (row) => !table.selected.includes(row.id)
    );
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  // const handleEditRow = useCallback(
  //   (id) => {
  //     router.push(paths.dashboard.user.edit(id));
  //   },
  //   [router]
  // );
  const handleEditRow = (id) => {};

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters("status", newValue);
    },
    [handleFilters]
  );

  const handleFilterOrdersStatus = useCallback(
    (event, newValue) => {
      handleFilters("orderStatus", newValue);
    },
    [handleFilters]
  );

  const handleFilterActive = useCallback(
    (event, newValue) => {
      handleFilters("active", newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  let showTabs = true;
  return (
    <>
      <Container maxWidth={"100%"} sx={{ maxWidth: "100%", width: "100%" }}>
        {/*         
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: "Dashboard", href: paths.dashboard.root },
            { name: "User", href: paths.dashboard.user.root },
            { name: "List" },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.user.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New User
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        /> 
        */}

        <Card sx={{ maxWidth: "100%", width: "100%" }}>
          {showTabs ? (
            <>
              <Tabs
                value={filters.active}
                //onChange={handleFilterActive}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab
                    key={tab.value}
                    iconPosition="end"
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label
                        variant={
                          ((tab.value === "all" ||
                            tab.value === filters.active) &&
                            "filled") ||
                          "soft"
                        }
                        color={
                          (tab.value === "activos" && "success") ||
                          (tab.value === "online" && "warning") ||
                          (tab.value === "bloqueado" && "error") ||
                          (tab.value === "ofline" && "error") ||
                          "default"
                        }
                      >
                        {tab.value === "all" && userList.length}
                        {tab.value === "activos" &&
                          userList.filter((user) => user.active === true)
                            .length}

                        {tab.value === "online" &&
                          userList.filter((user) => user.online === true)
                            .length}
                        {tab.value === "bloqueado" &&
                          userList.filter((user) => user.active === false)
                            .length}
                        {tab.value === "ofline" &&
                          userList.filter((user) => user.online === false)
                            .length}
                      </Label>
                    }
                  />
                ))}
              </Tabs>
              {/*}
              <Tabs
                value={filters.orderStatus}
                onChange={handleFilterOrdersStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_USER_OPTIONS_OTERS.map((tab) => (
                  <Tab
                    key={tab.value}
                    iconPosition="end"
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label
                        variant={
                          ((tab.value === "allOrders" ||
                            tab.value === filters.orderStatus) &&
                            "filled") ||
                          "soft"
                        }
                        color={
                          (tab.value === "create" && "warning") ||
                          (tab.value === "assing" && "success") ||
                          (tab.value === "route" && "success") ||
                          (tab.value === "delivery" && "success") ||
                          (tab.value === "pending" && "warning") ||
                          (tab.value === "reject" && "error") ||
                          "default"
                        }
                      >
                        {tab.value === "allOrders" && userList.length}
                        {tab.value === "create" &&
                          userList.filter(
                            (user) => user.orderStatus === "create"
                          ).length}

                        {tab.value === "assing" &&
                          userList.filter(
                            (user) => user.orderStatus === "assing"
                          ).length}
                        {tab.value === "route" &&
                          userList.filter(
                            (user) => user.orderStatus === "route"
                          ).length}
                        {tab.value === "delivery" &&
                          userList.filter(
                            (user) => user.orderStatus === "delivery"
                          ).length}
                        {tab.value === "pending" &&
                          userList.filter(
                            (user) => user.orderStatus === "pending"
                          ).length}
                        {tab.value === "reject" &&
                          userList.filter(
                            (user) => user.orderStatus === "reject"
                          ).length}
                      </Label>
                    }
                  />
                ))}
              </Tabs>
                  */}
            </>
          ) : null}

          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            roleOptions={roleOptions}
            serviceOptions={_service}
            onlineOptions={online}
            activeOptions={active}
            vehicleOptions={vehicle}
          />
          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}
          <div>
            <TableContainer sx={{ position: "unset", overflow: "unset" }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={tableData.length}
                items={table.selected}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table
                  size={table.dense ? "small" : "medium"}
                  sx={{ minWidth: 960 }}
                >
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    items={table.selected} // Seleccionado para acciones
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                    {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <UserDriverTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(
                        table.page,
                        table.rowsPerPage,
                        tableData.length
                      )}
                    />

                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </div>
          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{" "}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const {
    name,
    status,
    orderStatus,
    role,
    orige,
    service,
    online,
    active,
    vehicle,
  } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== "all") {
    inputData = inputData.filter((user) => user.status === status);
  }
  if (orderStatus !== "allOrders") {
    inputData = inputData.filter((user) => user.orderStatus === orderStatus);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }
  if (online.length) {
    inputData = inputData.filter((user) => online.includes(user.online));
  }

  if (vehicle.length) {
    inputData = inputData.filter((user) => vehicle.includes(user.vehicle));
  }

  if (active.length) {
    inputData = inputData.filter((user) => active.includes(user.active));
  }

  if (orige) {
    inputData = inputData.filter(
      (user) =>
        user.orige &&
        user.orige.toLowerCase().indexOf(orige.toLowerCase()) !== -1
    );
  }

  if (service.length) {
    inputData = inputData.filter((user) => service.includes(user.service));
  }

  return inputData;
}
