import React from "react";
import { useEffect } from "react";
import { Card, Grid, Typography, Box } from "@mui/material";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";

const DashIconsResumenDispachsMinimal = (props) => {
  useEffect(() => {}, [props.cant]);

  const COLORS = {
    Creadas: "dashIcon-blue",
    Ruta: "dashIcon-grey",
    Incidencias: "dashIcon-red",
    Finalizadas: "dashIcon-on-line",
    Total: "dashIcon-blue",
  };
  const COLORS2 = {
    Creadas: "#1E90FF", // Blue
    Ruta: "#808080", // Grey
    Incidencias: "#FF0000", // Red
    Finalizadas: "#32CD32", // Green
    Total: "#1E90FF", // Blue
  };
  const CANT = {
    Creadas: props.cant.Creadas,
    Ruta: props.cant.Ruta,
    Incidencias: props.cant.Incidencias,
    Finalizadas: props.cant.Finalizadas,
    Total: props.cant.Total,
  };

  const getIcon = (id) => {
    switch (id) {
      case "Creadas":
        return <LocalPostOfficeIcon sx={{ color: COLORS2[id] }} />;
      case "Ruta":
        return <DirectionsBikeIcon sx={{ color: COLORS2[id] }} />;
      // Agrega más casos según los íconos que necesites
      default:
        return <DirectionsBikeIcon sx={{ color: COLORS2[id] }} />;
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {props.items.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={2.4}
            key={item.id}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                borderRadius: 2,
                boxShadow: 3,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: "#999",
                  backgroundImage: `linear-gradient(60deg, #c8c6c3, #c8b49c)`,
                  boxShadow: `0 4px 20px 0 rgba(0,0,0,0.14), 0 7px 10px -5px  ${
                    COLORS2[item.id]
                  }`,
                  mb: 2,
                }}
              >
                {getIcon(item.id)}
              </Box>
              <Typography
                variant="h2"
                sx={{ fontWeight: 400, color: "#030303" }}
              >
                {CANT[item.id]}
              </Typography>
              <Typography variant="body2" className={COLORS[item.id]}>
                {item.id}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashIconsResumenDispachsMinimal;
