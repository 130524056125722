import React from "react";
import { Form, Input } from "semantic-ui-react";
import Moment from "moment";

const ToolDashSeachByDaRang = (props) => {
  return (
    <div className="typeMiniformsBarsMetricsTool">
      <div className="m-2">
        <h3>Buscar por Rango</h3>
        <div> Fecha Inicio </div>
        <Form.Field
          id="createDate"
          control={Input}
          max={Moment(Date.now()).format("YYYY-MM-DD")}
          format={"YYYY-MM-DD"}
          type="date"
          name="rangoInicio"
          onChange={(e) => props.onChageRange(e)}
          value={props.initDate ? props.initDate : props.initDateBillingFilter}
        />
        <div> Fecha Fin </div>
        <Form.Field
          id="FinalDate"
          max={Moment(Date.now()).format("YYYY-MM-DD")}
          control={Input}
          format={"YYYY-MM-DD"}
          type="date"
          name="rangoFinal"
          onChange={(e) => props.onChageRange(e)}
          value={props.endDate ? props.endDate : props.endtDateBillingFilter}
        />
      </div>
    </div>
  );
};

export default ToolDashSeachByDaRang;
