import axios from "axios";
// data inicial
const dataInicial = {
  loading: false,
  activo: false,
};

const LOADING_MOMENT = "LOADING_MOMENT";
const EDIT_DRIVER_EBIEX = "EDIT_DRIVER_EBIEX";
const EDIT_DRIVER_ERROR = "EDIT_DRIVER_ERROR";
const EDIT_DRIVER_EXITO = "EDIT_DRIVER_EXITO";
const ASSING_ORDERS_DRIVER = "ASSING_ORDERS_DRIVER";
const FIND_INFORMATION_DRIVER_ERROR = "FIND_INFORMATION_DRIVER_ERROR";

export default function usuarioClienteDriverEbiex(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_MOMENT:
      return { ...state, loading: true };
    case EDIT_DRIVER_EBIEX:
      return { ...state, user: action.payload };
    case EDIT_DRIVER_EXITO:
      return { ...state };
    case EDIT_DRIVER_ERROR:
      return { loading: false };
    // case SUPPRESS_USERUSER_EBIEX:
    //     return {...state, error:action.error}
    // case CREATE_COMPANYS_BY_USER:
    //     return {...state, loading: false, company: action.payload, levelUp: true}
    case ASSING_ORDERS_DRIVER:
      return { resp: action.mjsstatus };
    case FIND_INFORMATION_DRIVER_ERROR:
      return { error: action.mjsstatus };
    default:
      return state;
  }
}

export const actualizarDatosdeDriver = (data) => async (dispatch) => {
  //  // console.log(data.uid)
  dispatch({
    type: LOADING_MOMENT,
  });
  try {
    // console.log(data.username)
    // await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(data.uid).update({
    //  username:data.username,
    //  userLastName:data.userLastName,
    //  userPhone:data.phone,
    //  userPreferenCont:data.userPreferenCont,
    //  userRut:data.userRut,})
    // console.log('actualizarDatosdeDriver')
    //userType:data.userType,
    //     email:data.email,
    //     checked:data.checked,
  } catch (error) {
    // console.log(error)
    dispatch({
      type: EDIT_DRIVER_ERROR,
    });
  }
};

export const crearPointADireccionByDriver = (data) => async (dispatch) => {
  // console.log('its Fine')
  try {
    //  const respon = await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(data.uid).collection('direcciones').doc().set({
    //         companyPropietary:'',
    //         companyAsignament:'',
    //         propietary:data.uid,
    //         userName:data.userName,
    //         adressName:data.adressName,
    //         adressPhone: data.adressPhone,
    //         adressAddress:data.adressAddress,
    //         adressComent:data.adressComent,
    //         adressRegion:data.adressRegion,
    //         adressNumAddress:data.adressNumAddress,
    //         adressCity:data.adressCity,
    //         adressComuna:data.adressComuna,
    //         adressGeoNumAddressLat:data.adressGeoNumAddressLat,
    //         adressGeoNumAddressLng:data.adressGeoNumAddressLng,
    //         delete:false,
    //     })
    //  const messageRef = await db.collection('user').doc('idUsuarios').collection('direcciones').doc('nuevadireccion3').get()
    // console.log('crearPointADireccionByCompany')
  } catch (error) {
    // console.log(error)
  }
};

export const deletePointADireccionByDriver = (data) => async (dispatch) => {
  // console.log(data)
  try {
    //   await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(data.propietary).collection('direcciones').doc(data.id).delete()
    // console.log('it delete')
  } catch (error) {
    // console.log(error)
  }
};

export const updatePointADireccionByDriver = (data) => async (dispatch) => {
  try {
    //   await db.collection(`${process.env.REACT_APP_COL_USERS}`).doc(data.uid).collection('direcciones').doc(data.documentDir).update({
    //     companyPropietary:'',
    //     companyAsignament:'',
    //     propietary:data.uid,
    //     userName:data.userName,
    //     adressName:data.adressName,
    //     adressPhone: data.adressPhone,
    //     adressAddress:data.adressAddress,
    //     adressComent:data.adressComent,
    //     adressRegion:data.adressRegion,
    //     adressNumAddress:data.adressNumAddress,
    //     adressCity:data.adressCity,
    //     adressComuna:data.adressComuna,
    //     adressGeoNumAddressLat:data.adressGeoNumAddressLat,
    //     adressGeoNumAddressLng:data.adressGeoNumAddressLng,
    //     delete:false,
    //   })
    // console.log('it UpDate updatePointADireccionByCompany')
  } catch (error) {
    // console.log(error)
  }
};

export const assingMultipleDriver = (dataAsid) => async (dispatch) => {
  try {
    let data = JSON.stringify(dataAsid);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_BICCI_API_PRO_MULTIPLE_ASSIGN_DRIVER,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};
