import React, { useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  getFirestore,
  collection,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BasicListElements from "../../../../components/BasicListElements/BasicListElements";
import { Box, Typography } from "@mui/material";
import {
  fetcherRoles,
  fetcherUsers,
} from "../../../../utils/FectcherData/FectcherUser";
import { DataGrid } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import ReusableVewData from "../../../../components/ReusableVewData/ReusableVewData";
import ReusableFormEdit from "../../../../components/ReusableFormEdit/ReusableFormEdit";
import { db } from "../../../../firebase";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import generateReportByData from "../../../../utils/Reports/generateReportByData";
//import { FectcherOrders, endpoints } from "../../../Utils/FectcherOrders";
const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});
const AdminsCompanies = () => {
  const [bodyItems, setBodyItems] = useState([]);
  const [rolesOptiones, setRolesOptiones] = useState([]);
  const [render, setRender] = useState(false);
  const [columns, setColum] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [initialValues, setInitialValues] = useState(false);
  const [statusActive, setStatusActive] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    getData();
  }, [render]);

  const getData = async () => {
    const users = await fetcherUsers();
    const filteredUsers = users.filter((user) => user.roll !== "company_admin");

    setBodyItems(filteredUsers);

    const roles = await fetcherRoles();
    const options = roles.map((role) => {
      return { value: role.id, label: role.id };
    });
    setRolesOptiones(options);
  };

  useEffect(() => {
    upDateTable();
  }, [bodyItems]);

  const handleView = (row) => {
    console.log("Ver", row);
    setInitialValues(row);
    setOpen(true);
  };
  const handleEdit = (row) => {
    console.log("Editar", row);
    setInitialValues(row);
    setOpenEdit(true);
  };
  const handleActivate = async (row) => {
    row.userActive = true;
    setStatusActive(true);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { userActive: true });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeactivate = async (row) => {
    row.userActive = false;
    setStatusActive(false);
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, row.id);
      await updateDoc(docRef, { userActive: false });
      console.log("Documento actualizado con éxito.");
    } catch (error) {
      console.log(error);
    }
  };

  const upDateTable = () => {
    const renderActionButtons = (params) => (
      <Box sx={{ minWidth: "200px" }}>
        {/* Botón Ver */}
        <IconButton
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
          aria-label="ver"
          onClick={() => handleView(params.row)}
        >
          <VisibilityIcon />
        </IconButton>

        {/* Botón Editar */}
        <IconButton
          aria-label="editar"
          onClick={() => handleEdit(params.row)}
          sx={{ width: "30px !important", color: "#1e2158 !important" }}
        >
          <EditIcon />
        </IconButton>

        {/* Botón Activar/Desactivar */}
        {params.row.userActive ? (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#21ba45 !important",
            }}
            aria-label="desactivar"
            onClick={() => handleDeactivate(params.row)}
          >
            <ToggleOffIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              width: "50px !important",
              color: "#1e2158 !important",
            }}
            aria-label="activar"
            onClick={() => handleActivate(params.row)}
          >
            <ToggleOnIcon sx={{ fontSize: "40px" }} />
          </IconButton>
        )}
      </Box>
    );

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },
      {
        field: "userName",
        headerName: "Nombre",
        width: 150,
        editable: true,
      },
      {
        field: "userLastName",
        headerName: "Apellido",
        width: 100,
      },
      {
        field: "userPhone",
        headerName: "Teléfono",
        width: 120,
        editable: true,
      },
      {
        field: "email",
        headerName: "email",
        width: 200,
        editable: true,
      },
      {
        field: "roll",
        headerName: "roll",
        width: 150,
        editable: true,
      },
      {
        field: "userType",
        headerName: "userType",
        width: 100,
        editable: true,
      },
      {
        field: "userRut",
        headerName: "userRut",
        width: 100,
        editable: true,
      },
      {
        field: "actions",
        headerName: "Acciones",
        width: 300,
        renderCell: renderActionButtons,
        sortable: false,
        filterable: false,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < bodyItems.length; i++) {
      rows.push({
        id: bodyItems[i].id,
        userName: bodyItems[i].userName,
        userLastName: bodyItems[i].userLastName,
        email: bodyItems[i].email,
        userPhone: bodyItems[i].userPhone,
        roll: bodyItems[i].roll,
        userType: bodyItems[i].userType,
        userRut: bodyItems[i].userRut,
        userActive: bodyItems[i].userActive,
      });
    }
    setRows(rows);
  };

  // Formolario Nuevo Item
  const formFields = [
    { variable: "id", label: "id", type: "string" },
    { variable: "userName", label: "userName", type: "userName" },
    { variable: "userLastName", label: "userLastName", type: "string" },
    { variable: "email", label: "email", type: "string" },
    {
      variable: "roll",
      label: "roll",
      type: "select",
      options: rolesOptiones,
    },
    { variable: "userType", label: "userType", type: "string" },
    { variable: "userRut", label: "userRut", type: "string" },
  ];

  // Formolario Nuevo Item
  const formFieldsEdit = [
    { variable: "id", label: "id", type: "string" },
    { variable: "userName", label: "userName", type: "userName" },
    { variable: "userLastName", label: "userLastName", type: "string" },
    { variable: "email", label: "email", type: "string" },
    {
      variable: "roll",
      label: "roll",
      type: "select",
      options: rolesOptiones,
    },
    { variable: "userType", label: "userType", type: "string" },
    { variable: "userRut", label: "userRut", type: "string" },
  ];

  const handleAddItem = (formData) => {
    const itemIndex = rows.findIndex((item) => item.id === formData.id);

    if (itemIndex > -1) {
      const updatedRows = rows.map((item, index) => {
        if (index === itemIndex) {
          return {
            ...item,
            name: formData.name,
            path: formData.path,
            userActive: formData.userActive,
          };
        }
        return item;
      });
      setRows(updatedRows);
    } else {
      const newItem = {
        id: formData.id || rows.length + 1,
        name: formData.name,
        path: formData.path,
        userActive: formData.userActive,
      };
      setRows([...rows, newItem]);
    }
  };

  const handleSave = async (formData) => {
    console.log(formData);

    const caracteresValidos =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    const generarCadenaAleatoria = (longitud) => {
      let resultado = "";
      for (let i = 0; i < longitud; i++) {
        const indice = Math.floor(Math.random() * caracteresValidos.length);
        resultado += caracteresValidos.charAt(indice);
      }
      return resultado;
    };

    // Función para crear o actualizar un único documento
    const upsertDocument = async (docData) => {
      let id = docData.id || generarCadenaAleatoria(10);
      if (!docData.id || docData.id === "") {
        docData.id = id;
        docData.servicio_id = id; // Asumiendo que quieres asignar el mismo ID generado a servicio_id
      }

      const collRef = collection(db, process.env.REACT_APP_COL_USERS);
      const docRef = doc(collRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, docData);
        console.log("Documento actualizado con éxito.");
      } else {
        await setDoc(docRef, docData);
        console.log("Documento creado con éxito.");
      }

      handleAddItem(docData); // Asumiendo que esto actualiza la UI adecuadamente
    };

    if (Array.isArray(formData)) {
      // Si formData es un arreglo, procesa cada objeto individualmente
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
    setOpenEdit(false);
  };

  const createReportByData = () => {
    console.log("Crear reporte");
    generateReportByData(bodyItems);
  };

  return (
    <div>
      <Typography variant={"h2"} sx={{ color: "#F8F8F8" }}>
        Gestionar Nuevos Clientes
      </Typography>
      <Button
        variant="outline"
        startIcon={<DownloadIcon />}
        onClick={() => createReportByData(true)}
        sx={{ mb: 2, color: "#f8f8f8" }} // margen inferior para separar del DataGrid
      >
        Exportar
      </Button>
      <ReusableVewData
        open={open}
        handleClose={() => setOpen(false)}
        formFields={formFields}
        initialValues={initialValues}
      />
      {/* // Formolario view Item */}
      <ReusableFormEdit
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        formFields={formFieldsEdit}
        handleSave={handleSave}
        initialValues={initialValues}
      />
      {/* // Formolario View Item */}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        className={classes.root}
        groupingModel={["roll"]}
      ></DataGrid>
    </div>
  );
};

export default AdminsCompanies;
