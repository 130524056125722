import { TrendingUpOutlined } from "@mui/icons-material";
import { id } from "date-fns/locale";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

function useObtenerDatosdeCliente(uid) {
  const [data, setData] = useState(null);
  const [userdata, setUserdata] = useState(null);
  const [loadingDatosdeCliente, setLoading] = useState(false);
  const [errorDatosdeCliente, setError] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (uid) {
      fetchData(uid);
    }
  }, [uid]);

  const fetchData = async (uid) => {
    setIsMounted(true);
    try {
      setLoading(true);

      try {
        // Obtener los datos de un documento
        const docRef = doc(db, `${process.env.REACT_APP_COL_USERS}/${uid}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const dataResult = docSnap.data();
          console.log(dataResult.userCompanyID);
          setUserdata(dataResult);
          fetchDataCompany(dataResult.userCompanyID);
        }
      } catch (error) {
        console.log(error);
        console.log("error de usuario");
      }

      setLoading(false);
      setError(false);
    } catch (error) {
      console.log(error);
      //console.log('error')
      setError(true);
    }
  };

  const fetchDataCompany = async (userCompanyID) => {
    try {
      const collRef = collection(db, process.env.REACT_APP_COL_COMPANIES);
      const q = query(collRef, where("companyID", "==", userCompanyID));
      const dataComp = await getDocs(q);
      const dataCompItems = dataComp.docs.map((doc) => doc.data());
      setData(dataCompItems[0]);
    } catch (error) {
      //console.log(error)
      //console.log('error Company')
      setData(null);
      setError(true);
    }
  };

  if (userdata && data) {
    return {
      userdata,
      data,
      loadingDatosdeCliente,
      errorDatosdeCliente,
    };
  } else {
    return {
      userdata,
      data: null,
    };
  }
}

export default useObtenerDatosdeCliente;
