import { Button, Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ReusableFormImportOrdersByDataSDD from "../../../components/ReusableFormImportOrdersByDataSDD/ReusableFormImportOrdersByDataSDD";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReusableFormPiking from "../../../components/ReusableFormPiking/ReusableFormPiking";
import { Card } from "semantic-ui-react";

const CreateOrderMasive = (props) => {
  const [openImportDataSDD, setOpenImportDataSDD] = React.useState(false);
  const [openImportDataPiking, setOpenImportPiking] = React.useState(false);
  const [display, setDisplay] = React.useState(null);
  const [resultsItems, setResultsItems] = React.useState(null);
  const [resultsErros, setResultsErros] = React.useState([]);
  const [resultsCant, setResultsCant] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const UploadDocumentsByDataSDD = () => {
    console.log("Importador de documentos DataSDD");
    setOpenImportDataSDD(true);
  };

  const UploadDocumentsByPiking = () => {
    console.log("Importador de documentos Piking");
    setOpenImportPiking(true);
  };
  useEffect(() => {
    console.log(resultsErros);
  }, [resultsErros]);

  const clearResults = () => {
    setDisplay(null);
    setResultsItems(null);
    setResultsErros([]);
    setResultsCant(null);
    setLoading(null);
  };

  return (
    <div>
      <Grid
        item
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
        }}
      >
        <Button
          variant="outline"
          startIcon={<FileUploadIcon />}
          onClick={() => UploadDocumentsByDataSDD(true)}
          sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
        >
          1- Crear o Registrar Ordenes de envío.
        </Button>
        <ReusableFormImportOrdersByDataSDD
          open={openImportDataSDD}
          setOpen={setOpenImportDataSDD}
          handleClose={() => setOpenImportDataSDD(false)}
          companyData={props.companyData}
          userDataContext={props.userDataContext}
          prefix={props.prefix}
          dataOrigin={"DATA"}
        />
        <Button
          variant="outline"
          startIcon={<FileUploadIcon />}
          onClick={() => UploadDocumentsByPiking(true)}
          sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
        >
          1- Registrar Picking
        </Button>
        <ReusableFormPiking
          open={openImportDataPiking}
          setOpen={setOpenImportPiking}
          handleClose={() => setOpenImportPiking(false)}
          companyData={props.companyData}
          userDataContext={props.userDataContext}
          prefix={props.prefix}
          dataOrigin={"DATA"}
          setResultsItems={setResultsItems}
          resultsErros={resultsErros}
          setResultsErros={setResultsErros}
          setResultsCant={setResultsCant}
          setLoading={setLoading}
          setDisplay={setDisplay}
        />
      </Grid>

      <Grid
        item
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
          display: "flex",
        }}
      >
        {display ? (
          <>
            Resultados :<>{}</>
            <>{loading ? <LinearProgress></LinearProgress> : null}</>
            <Grid
              item
              sx={{
                width: {
                  xs: "50%",
                  sm: "50%",
                  md: "50%",
                  lg: "50%",
                },
                minHeight: "350px",
                padding: "20px",
              }}
            >
              <div>Progreso:</div>
              <div>
                {resultsItems ? (
                  <Card>
                    <Typography
                      sx={{
                        padding: "5px",
                        backgroundColor: "green",
                        color: "#f8f8f8",
                        borderRadius: "8%",
                      }}
                    >
                      Actualizando el ID: ${resultsItems}
                    </Typography>
                  </Card>
                ) : null}

                <>{resultsCant}</>
              </div>
            </Grid>
            {resultsErros && resultsErros.length > 0 ? (
              <Grid
                item
                sx={{
                  width: {
                    xs: "50%",
                    sm: "50%",
                    md: "50%",
                    lg: "50%",
                    minHeight: "350px",
                    padding: "20px",
                  },
                }}
              >
                <div>Alertas</div>
                <div>
                  {resultsErros && resultsErros.length > 0 ? (
                    <Card>
                      {" "}
                      {resultsErros.map((item) => (
                        <Card>
                          <Typography
                            sx={{
                              padding: "5px",
                              backgroundColor: "red",
                              color: "#f8f8f8",
                              borderRadius: "8%",
                            }}
                          >
                            {item}
                          </Typography>
                        </Card>
                      ))}{" "}
                    </Card>
                  ) : null}
                </div>
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
    </div>
  );
};

export default CreateOrderMasive;
