import { getGeocode, getLatLng } from "use-places-autocomplete";

async function getResolveValidationAdressGeoRef(adressDirection) {
  const parsedAddress = parseAddress(adressDirection);
  const geocodeResult = await fetchGeocode(parsedAddress);
  if (!geocodeResult) return { response: "null" };

  const validationData = extractValidationData(geocodeResult);
  if (isAddressValid(parsedAddress, validationData.formatted_address)) {
    return {
      response: "OK",
      newDir: parsedAddress,
      newGeoRef: { lat: validationData.lat, lng: validationData.lng },
      Validarobjeto: validationData,
    };
  }

  return { response: "Error" };
}

function parseAddress(address) {
  const elements = address.split(",");
  const firstElementWords = elements[0].split(" ");
  const index = firstElementWords.findIndex((word) => !isNaN(parseInt(word)));
  return (
    firstElementWords.slice(0, index + 1).join(" ") +
    ", " +
    elements.slice(1).join(",")
  );
}

async function fetchGeocode(address) {
  try {
    const results = await getGeocode({ address });
    return results[0];
  } catch (error) {
    console.error("Error fetching geocode for address:", address);
    return null;
  }
}

function extractValidationData(geocodeResult) {
  const validationData = {
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_3: "",
    administrative_area_level_2: "",
    administrative_area_level_1: "",
    country: "",
    postal_code: "",
    lngLat: "",
    formatted_address: "",
    place_id: "",
  };

  geocodeResult.address_components.forEach((component) => {
    const type = component.types[0];
    if (validationData.hasOwnProperty(type)) {
      validationData[type] = component.long_name;
    }
  });

  const { lat, lng } = getLatLng(geocodeResult);
  validationData.lat = lat;
  validationData.lng = lng;
  validationData.lngLat = `${lat},${lng}`;
  validationData.formatted_address = geocodeResult.formatted_address;
  validationData.place_id = geocodeResult.place_id;

  return validationData;
}

function isAddressValid(inputAddress, geocodeAddress) {
  const inputWords = inputAddress
    .split(",")
    .flatMap((segment) => segment.trim().split(" "));
  const geocodeWords = geocodeAddress
    .split(",")
    .flatMap((segment) => segment.trim().split(" "));
  const matches = inputWords.filter((word) => geocodeWords.includes(word));
  return matches.length >= 2;
}

export default getResolveValidationAdressGeoRef;
