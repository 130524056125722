import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import GuiasByFalabella from "../Guias/GuiasByFalabella";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import { useLoadScript } from "@react-google-maps/api";
import activateDevolutionUser from "../../../utils/activateDevolutionUser";

const libraries = ["places"];

const GuiasFalabellaHD = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [companyCollaborator, setCompanyCollaborator] = React.useState(null);

  const user = useSelector((store) => store.usuario.user);
  const userRoll = useSelector((store) => store.usuario.user.roll);
  const { userdata } = useObtenerDatosdeCliente(user.uid);

  React.useEffect(() => {
    if (userdata) {
      setCompanyCollaborator(userdata.companyCollaborator);
      activateDevolutionUser();
    }
  }, [userdata]);

  React.useEffect(() => {}, [userdata]);

  //console.log(companyCollaborator)
  if (loadError) {
    // console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }
  return userRoll ? (
    <>
      {userRoll === "biccixpressAdmin" ? (
        <GuiasByFalabella
          vertical={"HD"}
          userdata={userdata}
        ></GuiasByFalabella>
      ) : (
        <></>
      )}
      {userRoll === "company_admin" ? (
        <>
          {companyCollaborator === "falabella" ? (
            <>
              <GuiasByFalabella
                userdata={userdata}
                vertical={"HD"}
              ></GuiasByFalabella>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {userRoll === "client" ? <>Error 400</> : <></>}
    </>
  ) : (
    <LinearProgress />
  );
};

export default GuiasFalabellaHD;
