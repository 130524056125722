import React, { useContext, useState } from "react";
import { LinearProgress } from "@mui/material";
import { Box, Button, Toolbar, Typography } from "@mui/material";
import { UserContext } from "../../../context/CurrentUserProvider";
import GuiasByCompanyForPrint from "../Guias/GuiasByCompanyForPrint";
import { useOrders } from "../../../context/OrdersProvider";
import useGetOrdersDataByCompany from "../../../hooks/getOrdersDataContextByCompany";
import Moment from "moment";

const ShippingPrint = (props) => {
  const { userDataContext } = useContext(UserContext);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  const {
    mergedOrders,
    loadingContext,
    setInitDate,
    setEndDate,
    initDate,
    endDate,
  } = useGetOrdersDataByCompany(initDateInitial, endDateInitial);

  console.log(mergedOrders);

  const userRoll = userDataContext.roll;
  const userdata = userDataContext.userData;

  return (
    <>
      {userRoll && userdata ? (
        <Box sx={{ backgroundColor: "#f8f8f8" }}>
          <GuiasByCompanyForPrint
            userdata={userdata}
            firebaseUser={props.firebaseUser}
            usuarioStorage={props.usuarioStorage}
            userDataContext={userDataContext}
            mergedOrders={mergedOrders}
            loadingContext={loadingContext}
            setInitDate={setInitDate}
            setEndDate={setEndDate}
            initDate={initDate}
            endDate={endDate}
          ></GuiasByCompanyForPrint>{" "}
        </Box>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default ShippingPrint;
