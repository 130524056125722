import React from "react";
import { Form, Input } from "semantic-ui-react";
import Moment from "moment";

const ToolDashSeachByDay = (props) => {
  return (
    <div className="typeMiniformsBarsMetricsTool">
      <div className="m-2">
        <h3>Buscar por día</h3>

        <Form.Field
          id="staticDateBillingFilter"
          max={Moment(Date.now()).format("YYYY-MM-DD")}
          control={Input}
          iconPosition="left"
          type="date"
          name="staticDateBillingFilter"
          onChange={(e) => props.onChageRange(e)}
          value={props.staticDateBillingFilter}
        />
      </div>
    </div>
  );
};

export default ToolDashSeachByDay;
