export const bicciExpressPolygons = { 
  type: "FeatureCollection",
  name: "MapaJson",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        fid: 1,
        left: -7868234.1304086847,
        bottom: -3980938.7402535151,
        right: -7867079.4293319257,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.639058347612803],
              [-70.678956569705335, -33.63531883056551],
              [-70.673770141576128, -33.63531883056551],
              [-70.671176927511524, -33.639058347612803],
              [-70.673770141576128, -33.642797702269725],
              [-70.678956569705335, -33.642797702269725],
              [-70.681549783769924, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 2,
        left: -7868234.1304086847,
        bottom: -3979938.7397872647,
        right: -7867079.4293319257,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.631579151136748],
              [-70.678956569705335, -33.627839309335386],
              [-70.673770141576128, -33.627839309335386],
              [-70.671176927511524, -33.631579151136748],
              [-70.673770141576128, -33.63531883056551],
              [-70.678956569705335, -33.63531883056551],
              [-70.681549783769924, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 3,
        left: -7868234.1304086847,
        bottom: -3978938.7393210144,
        right: -7867079.4293319257,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.624099305170297],
              [-70.678956569705335, -33.620359138650379],
              [-70.673770141576128, -33.620359138650379],
              [-70.671176927511524, -33.624099305170297],
              [-70.673770141576128, -33.627839309335386],
              [-70.678956569705335, -33.627839309335386],
              [-70.681549783769924, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 4,
        left: -7868234.1304086847,
        bottom: -3977938.738854764,
        right: -7867079.4293319257,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.616618809784534],
              [-70.678956569705335, -33.612878318581629],
              [-70.673770141576128, -33.612878318581629],
              [-70.671176927511524, -33.616618809784534],
              [-70.673770141576128, -33.620359138650379],
              [-70.678956569705335, -33.620359138650379],
              [-70.681549783769924, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 5,
        left: -7868234.1304086847,
        bottom: -3976938.7383885137,
        right: -7867079.4293319257,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.609137665050582],
              [-70.678956569705335, -33.605396849200282],
              [-70.673770141576128, -33.605396849200282],
              [-70.671176927511524, -33.609137665050582],
              [-70.673770141576128, -33.612878318581629],
              [-70.678956569705335, -33.612878318581629],
              [-70.681549783769924, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 6,
        left: -7868234.1304086847,
        bottom: -3975938.7379222633,
        right: -7867079.4293319257,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.601655871039647],
              [-70.678956569705335, -33.597914730577571],
              [-70.673770141576128, -33.597914730577571],
              [-70.671176927511524, -33.601655871039647],
              [-70.673770141576128, -33.605396849200282],
              [-70.678956569705335, -33.605396849200282],
              [-70.681549783769924, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 7,
        left: -7868234.1304086847,
        bottom: -3974938.737456013,
        right: -7867079.4293319257,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.594173427822959],
              [-70.678956569705335, -33.590431962784734],
              [-70.673770141576128, -33.590431962784734],
              [-70.671176927511524, -33.594173427822959],
              [-70.673770141576128, -33.597914730577571],
              [-70.678956569705335, -33.597914730577571],
              [-70.681549783769924, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 8,
        left: -7868234.1304086847,
        bottom: -3973938.7369897626,
        right: -7867079.4293319257,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.586690335471808],
              [-70.678956569705335, -33.582948545893096],
              [-70.673770141576128, -33.582948545893096],
              [-70.671176927511524, -33.586690335471808],
              [-70.673770141576128, -33.590431962784734],
              [-70.678956569705335, -33.590431962784734],
              [-70.681549783769924, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 9,
        left: -7868234.1304086847,
        bottom: -3972938.7365235123,
        right: -7867079.4293319257,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.579206594057531],
              [-70.678956569705335, -33.575464479974038],
              [-70.673770141576128, -33.575464479974038],
              [-70.671176927511524, -33.579206594057531],
              [-70.673770141576128, -33.582948545893096],
              [-70.678956569705335, -33.582948545893096],
              [-70.681549783769924, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 10,
        left: -7868234.1304086847,
        bottom: -3971938.7360572619,
        right: -7867079.4293319257,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.571722203651518],
              [-70.678956569705335, -33.56797976509894],
              [-70.673770141576128, -33.56797976509894],
              [-70.671176927511524, -33.571722203651518],
              [-70.673770141576128, -33.575464479974038],
              [-70.678956569705335, -33.575464479974038],
              [-70.681549783769924, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 11,
        left: -7868234.1304086847,
        bottom: -3970938.7355910116,
        right: -7867079.4293319257,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.56423716432522],
              [-70.678956569705335, -33.560494401339298],
              [-70.673770141576128, -33.560494401339298],
              [-70.671176927511524, -33.56423716432522],
              [-70.673770141576128, -33.56797976509894],
              [-70.678956569705335, -33.56797976509894],
              [-70.681549783769924, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 12,
        left: -7868234.1304086847,
        bottom: -3969938.7351247617,
        right: -7867079.4293319257,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.556751476150104],
              [-70.678956569705335, -33.553008388766614],
              [-70.673770141576128, -33.553008388766614],
              [-70.671176927511524, -33.556751476150104],
              [-70.673770141576128, -33.560494401339298],
              [-70.678956569705335, -33.560494401339298],
              [-70.681549783769924, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 13,
        left: -7868234.1304086847,
        bottom: -3968938.7346585114,
        right: -7867079.4293319257,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.549265139197736],
              [-70.678956569705335, -33.54552172745246],
              [-70.673770141576128, -33.54552172745246],
              [-70.671176927511524, -33.549265139197736],
              [-70.673770141576128, -33.553008388766614],
              [-70.678956569705335, -33.553008388766614],
              [-70.681549783769924, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 14,
        left: -7868234.1304086847,
        bottom: -3967938.734192261,
        right: -7867079.4293319257,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.541778153539695],
              [-70.678956569705335, -33.538034417468438],
              [-70.673770141576128, -33.538034417468438],
              [-70.671176927511524, -33.541778153539695],
              [-70.673770141576128, -33.54552172745246],
              [-70.678956569705335, -33.54552172745246],
              [-70.681549783769924, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 15,
        left: -7868234.1304086847,
        bottom: -3966938.7337260107,
        right: -7867079.4293319257,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.534290519247627],
              [-70.678956569705335, -33.530546458886235],
              [-70.673770141576128, -33.530546458886235],
              [-70.671176927511524, -33.534290519247627],
              [-70.673770141576128, -33.538034417468438],
              [-70.678956569705335, -33.538034417468438],
              [-70.681549783769924, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 16,
        left: -7868234.1304086847,
        bottom: -3965938.7332597603,
        right: -7867079.4293319257,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.526802236393216],
              [-70.678956569705335, -33.523057851777558],
              [-70.673770141576128, -33.523057851777558],
              [-70.671176927511524, -33.526802236393216],
              [-70.673770141576128, -33.530546458886235],
              [-70.678956569705335, -33.530546458886235],
              [-70.681549783769924, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 17,
        left: -7868234.1304086847,
        bottom: -3964938.7327935095,
        right: -7867079.4293319257,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.519313305048215],
              [-70.678956569705335, -33.515568596214173],
              [-70.673770141576128, -33.515568596214173],
              [-70.671176927511524, -33.519313305048215],
              [-70.673770141576128, -33.523057851777558],
              [-70.678956569705335, -33.523057851777558],
              [-70.681549783769924, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 18,
        left: -7868234.1304086847,
        bottom: -3963938.7323272596,
        right: -7867079.4293319257,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.511823725284408],
              [-70.678956569705335, -33.508078692267915],
              [-70.673770141576128, -33.508078692267915],
              [-70.671176927511524, -33.511823725284408],
              [-70.673770141576128, -33.515568596214173],
              [-70.678956569705335, -33.515568596214173],
              [-70.681549783769924, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 19,
        left: -7868234.1304086847,
        bottom: -3962938.7318610097,
        right: -7867079.4293319257,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.504333497173661],
              [-70.678956569705335, -33.500588140010635],
              [-70.673770141576128, -33.500588140010635],
              [-70.671176927511524, -33.504333497173661],
              [-70.673770141576128, -33.508078692267915],
              [-70.678956569705335, -33.508078692267915],
              [-70.681549783769924, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 20,
        left: -7868234.1304086847,
        bottom: -3961938.7313947589,
        right: -7867079.4293319257,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.496842620787838],
              [-70.678956569705335, -33.493096939514267],
              [-70.673770141576128, -33.493096939514267],
              [-70.671176927511524, -33.496842620787838],
              [-70.673770141576128, -33.500588140010635],
              [-70.678956569705335, -33.500588140010635],
              [-70.681549783769924, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 21,
        left: -7868234.1304086847,
        bottom: -3960938.7309285086,
        right: -7867079.4293319257,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.489351096198902],
              [-70.678956569705335, -33.485605090850768],
              [-70.673770141576128, -33.485605090850768],
              [-70.671176927511524, -33.489351096198902],
              [-70.673770141576128, -33.493096939514267],
              [-70.678956569705335, -33.493096939514267],
              [-70.681549783769924, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 22,
        left: -7868234.1304086847,
        bottom: -3959938.7304622582,
        right: -7867079.4293319257,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.48185892347886],
              [-70.678956569705335, -33.478112594092174],
              [-70.673770141576128, -33.478112594092174],
              [-70.671176927511524, -33.48185892347886],
              [-70.673770141576128, -33.485605090850768],
              [-70.678956569705335, -33.485605090850768],
              [-70.681549783769924, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 23,
        left: -7868234.1304086847,
        bottom: -3958938.7299960079,
        right: -7867079.4293319257,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.474366102699733],
              [-70.678956569705335, -33.470619449310547],
              [-70.673770141576128, -33.470619449310547],
              [-70.671176927511524, -33.474366102699733],
              [-70.673770141576128, -33.478112594092174],
              [-70.678956569705335, -33.478112594092174],
              [-70.681549783769924, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 24,
        left: -7868234.1304086847,
        bottom: -3957938.7295297575,
        right: -7867079.4293319257,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.466872633933626],
              [-70.678956569705335, -33.463125656578008],
              [-70.673770141576128, -33.463125656578008],
              [-70.671176927511524, -33.466872633933626],
              [-70.673770141576128, -33.470619449310547],
              [-70.678956569705335, -33.470619449310547],
              [-70.681549783769924, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 25,
        left: -7868234.1304086847,
        bottom: -3956938.7290635072,
        right: -7867079.4293319257,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.459378517252702],
              [-70.678956569705335, -33.45563121596674],
              [-70.673770141576128, -33.45563121596674],
              [-70.671176927511524, -33.459378517252702],
              [-70.673770141576128, -33.463125656578008],
              [-70.678956569705335, -33.463125656578008],
              [-70.681549783769924, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 26,
        left: -7868234.1304086847,
        bottom: -3955938.7285972568,
        right: -7867079.4293319257,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.451883752729138],
              [-70.678956569705335, -33.448136127548949],
              [-70.673770141576128, -33.448136127548949],
              [-70.671176927511524, -33.451883752729138],
              [-70.673770141576128, -33.45563121596674],
              [-70.678956569705335, -33.45563121596674],
              [-70.681549783769924, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 27,
        left: -7868234.1304086847,
        bottom: -3954938.7281310065,
        right: -7867079.4293319257,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.444388340435196],
              [-70.678956569705335, -33.440640391396926],
              [-70.673770141576128, -33.440640391396926],
              [-70.671176927511524, -33.444388340435196],
              [-70.673770141576128, -33.448136127548949],
              [-70.678956569705335, -33.448136127548949],
              [-70.681549783769924, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 28,
        left: -7868234.1304086847,
        bottom: -3953938.7276647561,
        right: -7867079.4293319257,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.436892280443168],
              [-70.678956569705335, -33.433144007582989],
              [-70.673770141576128, -33.433144007582989],
              [-70.671176927511524, -33.436892280443168],
              [-70.673770141576128, -33.440640391396926],
              [-70.678956569705335, -33.440640391396926],
              [-70.681549783769924, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 29,
        left: -7868234.1304086847,
        bottom: -3952938.7271985058,
        right: -7867079.4293319257,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.429395572825413],
              [-70.678956569705335, -33.425646976179515],
              [-70.673770141576128, -33.425646976179515],
              [-70.671176927511524, -33.429395572825413],
              [-70.673770141576128, -33.433144007582989],
              [-70.678956569705335, -33.433144007582989],
              [-70.681549783769924, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 30,
        left: -7868234.1304086847,
        bottom: -3951938.7267322554,
        right: -7867079.4293319257,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.421898217654316],
              [-70.678956569705335, -33.418149297258921],
              [-70.673770141576128, -33.418149297258921],
              [-70.671176927511524, -33.421898217654316],
              [-70.673770141576128, -33.425646976179515],
              [-70.678956569705335, -33.425646976179515],
              [-70.681549783769924, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 31,
        left: -7868234.1304086847,
        bottom: -3950938.7262660051,
        right: -7867079.4293319257,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.414400215002352],
              [-70.678956569705335, -33.410650970893691],
              [-70.673770141576128, -33.410650970893691],
              [-70.671176927511524, -33.414400215002352],
              [-70.673770141576128, -33.418149297258921],
              [-70.678956569705335, -33.418149297258921],
              [-70.681549783769924, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 32,
        left: -7868234.1304086847,
        bottom: -3949938.7257997552,
        right: -7867079.4293319257,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.406901564941997],
              [-70.678956569705335, -33.40315199715635],
              [-70.673770141576128, -33.40315199715635],
              [-70.671176927511524, -33.406901564941997],
              [-70.673770141576128, -33.410650970893691],
              [-70.678956569705335, -33.410650970893691],
              [-70.681549783769924, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 33,
        left: -7868234.1304086847,
        bottom: -3948938.7253335048,
        right: -7867079.4293319257,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.399402267545824],
              [-70.678956569705335, -33.395652376119486],
              [-70.673770141576128, -33.395652376119486],
              [-70.671176927511524, -33.399402267545824],
              [-70.673770141576128, -33.40315199715635],
              [-70.678956569705335, -33.40315199715635],
              [-70.681549783769924, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 34,
        left: -7868234.1304086847,
        bottom: -3947938.724867254,
        right: -7867079.4293319257,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.391902322886416],
              [-70.678956569705335, -33.388152107855717],
              [-70.673770141576128, -33.388152107855717],
              [-70.671176927511524, -33.391902322886416],
              [-70.673770141576128, -33.395652376119486],
              [-70.678956569705335, -33.395652376119486],
              [-70.681549783769924, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 35,
        left: -7868234.1304086847,
        bottom: -3946938.7244010041,
        right: -7867079.4293319257,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.384401731036441],
              [-70.678956569705335, -33.380651192437711],
              [-70.673770141576128, -33.380651192437711],
              [-70.671176927511524, -33.384401731036441],
              [-70.673770141576128, -33.388152107855717],
              [-70.678956569705335, -33.388152107855717],
              [-70.681549783769924, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 36,
        left: -7868234.1304086847,
        bottom: -3945938.7239347543,
        right: -7867079.4293319257,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.3769004920686],
              [-70.678956569705335, -33.373149629938226],
              [-70.673770141576128, -33.373149629938226],
              [-70.671176927511524, -33.3769004920686],
              [-70.673770141576128, -33.380651192437711],
              [-70.678956569705335, -33.380651192437711],
              [-70.681549783769924, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 37,
        left: -7868234.1304086847,
        bottom: -3944938.7234685034,
        right: -7867079.4293319257,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.369398606055647],
              [-70.678956569705335, -33.365647420430008],
              [-70.673770141576128, -33.365647420430008],
              [-70.671176927511524, -33.369398606055647],
              [-70.673770141576128, -33.373149629938226],
              [-70.678956569705335, -33.373149629938226],
              [-70.681549783769924, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 38,
        left: -7868234.1304086847,
        bottom: -3943938.7230022531,
        right: -7867079.4293319257,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.36189607307039],
              [-70.678956569705335, -33.358144563985917],
              [-70.673770141576128, -33.358144563985917],
              [-70.671176927511524, -33.36189607307039],
              [-70.673770141576128, -33.365647420430008],
              [-70.678956569705335, -33.365647420430008],
              [-70.681549783769924, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 39,
        left: -7868234.1304086847,
        bottom: -3942938.7225360028,
        right: -7867079.4293319257,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.35439289318569],
              [-70.678956569705335, -33.350641060678818],
              [-70.673770141576128, -33.350641060678818],
              [-70.671176927511524, -33.35439289318569],
              [-70.673770141576128, -33.358144563985917],
              [-70.678956569705335, -33.358144563985917],
              [-70.681549783769924, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 40,
        left: -7868234.1304086847,
        bottom: -3941938.7220697524,
        right: -7867079.4293319257,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.346889066474425],
              [-70.678956569705335, -33.343136910581649],
              [-70.673770141576128, -33.343136910581649],
              [-70.671176927511524, -33.346889066474425],
              [-70.673770141576128, -33.350641060678818],
              [-70.678956569705335, -33.350641060678818],
              [-70.681549783769924, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 41,
        left: -7868234.1304086847,
        bottom: -3940938.7216035021,
        right: -7867079.4293319257,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.339384593009591],
              [-70.678956569705335, -33.335632113767389],
              [-70.673770141576128, -33.335632113767389],
              [-70.671176927511524, -33.339384593009591],
              [-70.673770141576128, -33.343136910581649],
              [-70.678956569705335, -33.343136910581649],
              [-70.681549783769924, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 42,
        left: -7868234.1304086847,
        bottom: -3939938.7211372517,
        right: -7867079.4293319257,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.331879472864181],
              [-70.678956569705335, -33.328126670309082],
              [-70.673770141576128, -33.328126670309082],
              [-70.671176927511524, -33.331879472864181],
              [-70.673770141576128, -33.335632113767389],
              [-70.678956569705335, -33.335632113767389],
              [-70.681549783769924, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 43,
        left: -7868234.1304086847,
        bottom: -3938938.7206710014,
        right: -7867079.4293319257,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.324373706111238],
              [-70.678956569705335, -33.320620580279794],
              [-70.673770141576128, -33.320620580279794],
              [-70.671176927511524, -33.324373706111238],
              [-70.673770141576128, -33.328126670309082],
              [-70.678956569705335, -33.328126670309082],
              [-70.681549783769924, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 44,
        left: -7868234.1304086847,
        bottom: -3937938.720204751,
        right: -7867079.4293319257,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.3168672928239],
              [-70.678956569705335, -33.313113843752674],
              [-70.673770141576128, -33.313113843752674],
              [-70.671176927511524, -33.3168672928239],
              [-70.673770141576128, -33.320620580279794],
              [-70.678956569705335, -33.320620580279794],
              [-70.681549783769924, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 45,
        left: -7868234.1304086847,
        bottom: -3936938.7197385007,
        right: -7867079.4293319257,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.309360233075296],
              [-70.678956569705335, -33.305606460800909],
              [-70.673770141576128, -33.305606460800909],
              [-70.671176927511524, -33.309360233075296],
              [-70.673770141576128, -33.313113843752674],
              [-70.678956569705335, -33.313113843752674],
              [-70.681549783769924, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 46,
        left: -7868234.1304086847,
        bottom: -3935938.7192722503,
        right: -7867079.4293319257,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.30185252693866],
              [-70.678956569705335, -33.298098431497721],
              [-70.673770141576128, -33.298098431497721],
              [-70.671176927511524, -33.30185252693866],
              [-70.673770141576128, -33.305606460800909],
              [-70.678956569705335, -33.305606460800909],
              [-70.681549783769924, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 47,
        left: -7868234.1304086847,
        bottom: -3934938.718806,
        right: -7867079.4293319257,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.294344174487243],
              [-70.678956569705335, -33.290589755916407],
              [-70.673770141576128, -33.290589755916407],
              [-70.671176927511524, -33.294344174487243],
              [-70.673770141576128, -33.298098431497721],
              [-70.678956569705335, -33.298098431497721],
              [-70.681549783769924, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 48,
        left: -7868234.1304086847,
        bottom: -3933938.7183397496,
        right: -7867079.4293319257,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.681549783769924, -33.286835175794366],
              [-70.678956569705335, -33.283080434130305],
              [-70.673770141576128, -33.283080434130305],
              [-70.671176927511524, -33.286835175794366],
              [-70.673770141576128, -33.290589755916407],
              [-70.678956569705335, -33.290589755916407],
              [-70.681549783769924, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 49,
        left: -7867368.1046011159,
        bottom: -3980438.7400203901,
        right: -7866213.4035243569,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.63531883056551],
              [-70.671176927511524, -33.631579151136748],
              [-70.665990499382303, -33.631579151136748],
              [-70.6633972853177, -33.63531883056551],
              [-70.665990499382303, -33.639058347612803],
              [-70.671176927511524, -33.639058347612803],
              [-70.673770141576128, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 50,
        left: -7867368.1046011159,
        bottom: -3979438.7395541398,
        right: -7866213.4035243569,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.627839309335386],
              [-70.671176927511524, -33.624099305170297],
              [-70.665990499382303, -33.624099305170297],
              [-70.6633972853177, -33.627839309335386],
              [-70.665990499382303, -33.631579151136748],
              [-70.671176927511524, -33.631579151136748],
              [-70.673770141576128, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 51,
        left: -7867368.1046011159,
        bottom: -3978438.7390878894,
        right: -7866213.4035243569,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.620359138650379],
              [-70.671176927511524, -33.616618809784534],
              [-70.665990499382303, -33.616618809784534],
              [-70.6633972853177, -33.620359138650379],
              [-70.665990499382303, -33.624099305170297],
              [-70.671176927511524, -33.624099305170297],
              [-70.673770141576128, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 52,
        left: -7867368.1046011159,
        bottom: -3977438.7386216391,
        right: -7866213.4035243569,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.612878318581629],
              [-70.671176927511524, -33.609137665050582],
              [-70.665990499382303, -33.609137665050582],
              [-70.6633972853177, -33.612878318581629],
              [-70.665990499382303, -33.616618809784534],
              [-70.671176927511524, -33.616618809784534],
              [-70.673770141576128, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 53,
        left: -7867368.1046011159,
        bottom: -3976438.7381553887,
        right: -7866213.4035243569,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.605396849200282],
              [-70.671176927511524, -33.601655871039647],
              [-70.665990499382303, -33.601655871039647],
              [-70.6633972853177, -33.605396849200282],
              [-70.665990499382303, -33.609137665050582],
              [-70.671176927511524, -33.609137665050582],
              [-70.673770141576128, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 54,
        left: -7867368.1046011159,
        bottom: -3975438.7376891384,
        right: -7866213.4035243569,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.597914730577571],
              [-70.671176927511524, -33.594173427822959],
              [-70.665990499382303, -33.594173427822959],
              [-70.6633972853177, -33.597914730577571],
              [-70.665990499382303, -33.601655871039647],
              [-70.671176927511524, -33.601655871039647],
              [-70.673770141576128, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 55,
        left: -7867368.1046011159,
        bottom: -3974438.7372228876,
        right: -7866213.4035243569,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.590431962784734],
              [-70.671176927511524, -33.586690335471808],
              [-70.665990499382303, -33.586690335471808],
              [-70.6633972853177, -33.590431962784734],
              [-70.665990499382303, -33.594173427822959],
              [-70.671176927511524, -33.594173427822959],
              [-70.673770141576128, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 56,
        left: -7867368.1046011159,
        bottom: -3973438.7367566377,
        right: -7866213.4035243569,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.582948545893096],
              [-70.671176927511524, -33.579206594057531],
              [-70.665990499382303, -33.579206594057531],
              [-70.6633972853177, -33.582948545893096],
              [-70.665990499382303, -33.586690335471808],
              [-70.671176927511524, -33.586690335471808],
              [-70.673770141576128, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 57,
        left: -7867368.1046011159,
        bottom: -3972438.7362903878,
        right: -7866213.4035243569,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.575464479974038],
              [-70.671176927511524, -33.571722203651518],
              [-70.665990499382303, -33.571722203651518],
              [-70.6633972853177, -33.575464479974038],
              [-70.665990499382303, -33.579206594057531],
              [-70.671176927511524, -33.579206594057531],
              [-70.673770141576128, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 58,
        left: -7867368.1046011159,
        bottom: -3971438.735824137,
        right: -7866213.4035243569,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.56797976509894],
              [-70.671176927511524, -33.56423716432522],
              [-70.665990499382303, -33.56423716432522],
              [-70.6633972853177, -33.56797976509894],
              [-70.665990499382303, -33.571722203651518],
              [-70.671176927511524, -33.571722203651518],
              [-70.673770141576128, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 59,
        left: -7867368.1046011159,
        bottom: -3970438.7353578866,
        right: -7866213.4035243569,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.560494401339298],
              [-70.671176927511524, -33.556751476150104],
              [-70.665990499382303, -33.556751476150104],
              [-70.6633972853177, -33.560494401339298],
              [-70.665990499382303, -33.56423716432522],
              [-70.671176927511524, -33.56423716432522],
              [-70.673770141576128, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 60,
        left: -7867368.1046011159,
        bottom: -3969438.7348916363,
        right: -7866213.4035243569,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.553008388766614],
              [-70.671176927511524, -33.549265139197736],
              [-70.665990499382303, -33.549265139197736],
              [-70.6633972853177, -33.553008388766614],
              [-70.665990499382303, -33.556751476150104],
              [-70.671176927511524, -33.556751476150104],
              [-70.673770141576128, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 61,
        left: -7867368.1046011159,
        bottom: -3968438.7344253859,
        right: -7866213.4035243569,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.54552172745246],
              [-70.671176927511524, -33.541778153539695],
              [-70.665990499382303, -33.541778153539695],
              [-70.6633972853177, -33.54552172745246],
              [-70.665990499382303, -33.549265139197736],
              [-70.671176927511524, -33.549265139197736],
              [-70.673770141576128, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 62,
        left: -7867368.1046011159,
        bottom: -3967438.7339591356,
        right: -7866213.4035243569,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.538034417468438],
              [-70.671176927511524, -33.534290519247627],
              [-70.665990499382303, -33.534290519247627],
              [-70.6633972853177, -33.538034417468438],
              [-70.665990499382303, -33.541778153539695],
              [-70.671176927511524, -33.541778153539695],
              [-70.673770141576128, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 63,
        left: -7867368.1046011159,
        bottom: -3966438.7334928853,
        right: -7866213.4035243569,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.530546458886235],
              [-70.671176927511524, -33.526802236393216],
              [-70.665990499382303, -33.526802236393216],
              [-70.6633972853177, -33.530546458886235],
              [-70.665990499382303, -33.534290519247627],
              [-70.671176927511524, -33.534290519247627],
              [-70.673770141576128, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 64,
        left: -7867368.1046011159,
        bottom: -3965438.7330266349,
        right: -7866213.4035243569,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.523057851777558],
              [-70.671176927511524, -33.519313305048215],
              [-70.665990499382303, -33.519313305048215],
              [-70.6633972853177, -33.523057851777558],
              [-70.665990499382303, -33.526802236393216],
              [-70.671176927511524, -33.526802236393216],
              [-70.673770141576128, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 65,
        left: -7867368.1046011159,
        bottom: -3964438.7325603846,
        right: -7866213.4035243569,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.515568596214173],
              [-70.671176927511524, -33.511823725284408],
              [-70.665990499382303, -33.511823725284408],
              [-70.6633972853177, -33.515568596214173],
              [-70.665990499382303, -33.519313305048215],
              [-70.671176927511524, -33.519313305048215],
              [-70.673770141576128, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 66,
        left: -7867368.1046011159,
        bottom: -3963438.7320941342,
        right: -7866213.4035243569,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.508078692267915],
              [-70.671176927511524, -33.504333497173661],
              [-70.665990499382303, -33.504333497173661],
              [-70.6633972853177, -33.508078692267915],
              [-70.665990499382303, -33.511823725284408],
              [-70.671176927511524, -33.511823725284408],
              [-70.673770141576128, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 67,
        left: -7867368.1046011159,
        bottom: -3962438.7316278839,
        right: -7866213.4035243569,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.500588140010635],
              [-70.671176927511524, -33.496842620787838],
              [-70.665990499382303, -33.496842620787838],
              [-70.6633972853177, -33.500588140010635],
              [-70.665990499382303, -33.504333497173661],
              [-70.671176927511524, -33.504333497173661],
              [-70.673770141576128, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 68,
        left: -7867368.1046011159,
        bottom: -3961438.7311616335,
        right: -7866213.4035243569,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.493096939514267],
              [-70.671176927511524, -33.489351096198902],
              [-70.665990499382303, -33.489351096198902],
              [-70.6633972853177, -33.493096939514267],
              [-70.665990499382303, -33.496842620787838],
              [-70.671176927511524, -33.496842620787838],
              [-70.673770141576128, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 69,
        left: -7867368.1046011159,
        bottom: -3960438.7306953832,
        right: -7866213.4035243569,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.485605090850768],
              [-70.671176927511524, -33.48185892347886],
              [-70.665990499382303, -33.48185892347886],
              [-70.6633972853177, -33.485605090850768],
              [-70.665990499382303, -33.489351096198902],
              [-70.671176927511524, -33.489351096198902],
              [-70.673770141576128, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 70,
        left: -7867368.1046011159,
        bottom: -3959438.7302291333,
        right: -7866213.4035243569,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.478112594092174],
              [-70.671176927511524, -33.474366102699733],
              [-70.665990499382303, -33.474366102699733],
              [-70.6633972853177, -33.478112594092174],
              [-70.665990499382303, -33.48185892347886],
              [-70.671176927511524, -33.48185892347886],
              [-70.673770141576128, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 71,
        left: -7867368.1046011159,
        bottom: -3958438.7297628829,
        right: -7866213.4035243569,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.470619449310547],
              [-70.671176927511524, -33.466872633933626],
              [-70.665990499382303, -33.466872633933626],
              [-70.6633972853177, -33.470619449310547],
              [-70.665990499382303, -33.474366102699733],
              [-70.671176927511524, -33.474366102699733],
              [-70.673770141576128, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 72,
        left: -7867368.1046011159,
        bottom: -3957438.7292966326,
        right: -7866213.4035243569,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.463125656578008],
              [-70.671176927511524, -33.459378517252702],
              [-70.665990499382303, -33.459378517252702],
              [-70.6633972853177, -33.463125656578008],
              [-70.665990499382303, -33.466872633933626],
              [-70.671176927511524, -33.466872633933626],
              [-70.673770141576128, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 73,
        left: -7867368.1046011159,
        bottom: -3956438.7288303822,
        right: -7866213.4035243569,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.45563121596674],
              [-70.671176927511524, -33.451883752729138],
              [-70.665990499382303, -33.451883752729138],
              [-70.6633972853177, -33.45563121596674],
              [-70.665990499382303, -33.459378517252702],
              [-70.671176927511524, -33.459378517252702],
              [-70.673770141576128, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 74,
        left: -7867368.1046011159,
        bottom: -3955438.7283641323,
        right: -7866213.4035243569,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.448136127548949],
              [-70.671176927511524, -33.444388340435196],
              [-70.665990499382303, -33.444388340435196],
              [-70.6633972853177, -33.448136127548949],
              [-70.665990499382303, -33.451883752729138],
              [-70.671176927511524, -33.451883752729138],
              [-70.673770141576128, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 75,
        left: -7867368.1046011159,
        bottom: -3954438.7278978815,
        right: -7866213.4035243569,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.440640391396926],
              [-70.671176927511524, -33.436892280443168],
              [-70.665990499382303, -33.436892280443168],
              [-70.6633972853177, -33.440640391396926],
              [-70.665990499382303, -33.444388340435196],
              [-70.671176927511524, -33.444388340435196],
              [-70.673770141576128, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 76,
        left: -7867368.1046011159,
        bottom: -3953438.7274316312,
        right: -7866213.4035243569,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.433144007582989],
              [-70.671176927511524, -33.429395572825413],
              [-70.665990499382303, -33.429395572825413],
              [-70.6633972853177, -33.433144007582989],
              [-70.665990499382303, -33.436892280443168],
              [-70.671176927511524, -33.436892280443168],
              [-70.673770141576128, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 77,
        left: -7867368.1046011159,
        bottom: -3952438.7269653808,
        right: -7866213.4035243569,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.425646976179515],
              [-70.671176927511524, -33.421898217654316],
              [-70.665990499382303, -33.421898217654316],
              [-70.6633972853177, -33.425646976179515],
              [-70.665990499382303, -33.429395572825413],
              [-70.671176927511524, -33.429395572825413],
              [-70.673770141576128, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 78,
        left: -7867368.1046011159,
        bottom: -3951438.7264991305,
        right: -7866213.4035243569,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.418149297258921],
              [-70.671176927511524, -33.414400215002352],
              [-70.665990499382303, -33.414400215002352],
              [-70.6633972853177, -33.418149297258921],
              [-70.665990499382303, -33.421898217654316],
              [-70.671176927511524, -33.421898217654316],
              [-70.673770141576128, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 79,
        left: -7867368.1046011159,
        bottom: -3950438.7260328801,
        right: -7866213.4035243569,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.410650970893691],
              [-70.671176927511524, -33.406901564941997],
              [-70.665990499382303, -33.406901564941997],
              [-70.6633972853177, -33.410650970893691],
              [-70.665990499382303, -33.414400215002352],
              [-70.671176927511524, -33.414400215002352],
              [-70.673770141576128, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 80,
        left: -7867368.1046011159,
        bottom: -3949438.7255666298,
        right: -7866213.4035243569,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.40315199715635],
              [-70.671176927511524, -33.399402267545824],
              [-70.665990499382303, -33.399402267545824],
              [-70.6633972853177, -33.40315199715635],
              [-70.665990499382303, -33.406901564941997],
              [-70.671176927511524, -33.406901564941997],
              [-70.673770141576128, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 81,
        left: -7867368.1046011159,
        bottom: -3948438.7251003794,
        right: -7866213.4035243569,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.395652376119486],
              [-70.671176927511524, -33.391902322886416],
              [-70.665990499382303, -33.391902322886416],
              [-70.6633972853177, -33.395652376119486],
              [-70.665990499382303, -33.399402267545824],
              [-70.671176927511524, -33.399402267545824],
              [-70.673770141576128, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 82,
        left: -7867368.1046011159,
        bottom: -3947438.7246341291,
        right: -7866213.4035243569,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.388152107855717],
              [-70.671176927511524, -33.384401731036441],
              [-70.665990499382303, -33.384401731036441],
              [-70.6633972853177, -33.388152107855717],
              [-70.665990499382303, -33.391902322886416],
              [-70.671176927511524, -33.391902322886416],
              [-70.673770141576128, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 83,
        left: -7867368.1046011159,
        bottom: -3946438.7241678787,
        right: -7866213.4035243569,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.380651192437711],
              [-70.671176927511524, -33.3769004920686],
              [-70.665990499382303, -33.3769004920686],
              [-70.6633972853177, -33.380651192437711],
              [-70.665990499382303, -33.384401731036441],
              [-70.671176927511524, -33.384401731036441],
              [-70.673770141576128, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 84,
        left: -7867368.1046011159,
        bottom: -3945438.7237016284,
        right: -7866213.4035243569,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.373149629938226],
              [-70.671176927511524, -33.369398606055647],
              [-70.665990499382303, -33.369398606055647],
              [-70.6633972853177, -33.373149629938226],
              [-70.665990499382303, -33.3769004920686],
              [-70.671176927511524, -33.3769004920686],
              [-70.673770141576128, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 85,
        left: -7867368.1046011159,
        bottom: -3944438.723235378,
        right: -7866213.4035243569,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.365647420430008],
              [-70.671176927511524, -33.36189607307039],
              [-70.665990499382303, -33.36189607307039],
              [-70.6633972853177, -33.365647420430008],
              [-70.665990499382303, -33.369398606055647],
              [-70.671176927511524, -33.369398606055647],
              [-70.673770141576128, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 86,
        left: -7867368.1046011159,
        bottom: -3943438.7227691277,
        right: -7866213.4035243569,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.358144563985917],
              [-70.671176927511524, -33.35439289318569],
              [-70.665990499382303, -33.35439289318569],
              [-70.6633972853177, -33.358144563985917],
              [-70.665990499382303, -33.36189607307039],
              [-70.671176927511524, -33.36189607307039],
              [-70.673770141576128, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 87,
        left: -7867368.1046011159,
        bottom: -3942438.7223028769,
        right: -7866213.4035243569,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.350641060678818],
              [-70.671176927511524, -33.346889066474425],
              [-70.665990499382303, -33.346889066474425],
              [-70.6633972853177, -33.350641060678818],
              [-70.665990499382303, -33.35439289318569],
              [-70.671176927511524, -33.35439289318569],
              [-70.673770141576128, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 88,
        left: -7867368.1046011159,
        bottom: -3941438.721836627,
        right: -7866213.4035243569,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.343136910581649],
              [-70.671176927511524, -33.339384593009591],
              [-70.665990499382303, -33.339384593009591],
              [-70.6633972853177, -33.343136910581649],
              [-70.665990499382303, -33.346889066474425],
              [-70.671176927511524, -33.346889066474425],
              [-70.673770141576128, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 89,
        left: -7867368.1046011159,
        bottom: -3940438.7213703771,
        right: -7866213.4035243569,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.335632113767389],
              [-70.671176927511524, -33.331879472864181],
              [-70.665990499382303, -33.331879472864181],
              [-70.6633972853177, -33.335632113767389],
              [-70.665990499382303, -33.339384593009591],
              [-70.671176927511524, -33.339384593009591],
              [-70.673770141576128, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 90,
        left: -7867368.1046011159,
        bottom: -3939438.7209041263,
        right: -7866213.4035243569,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.328126670309082],
              [-70.671176927511524, -33.324373706111238],
              [-70.665990499382303, -33.324373706111238],
              [-70.6633972853177, -33.328126670309082],
              [-70.665990499382303, -33.331879472864181],
              [-70.671176927511524, -33.331879472864181],
              [-70.673770141576128, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 91,
        left: -7867368.1046011159,
        bottom: -3938438.7204378759,
        right: -7866213.4035243569,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.320620580279794],
              [-70.671176927511524, -33.3168672928239],
              [-70.665990499382303, -33.3168672928239],
              [-70.6633972853177, -33.320620580279794],
              [-70.665990499382303, -33.324373706111238],
              [-70.671176927511524, -33.324373706111238],
              [-70.673770141576128, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 92,
        left: -7867368.1046011159,
        bottom: -3937438.7199716261,
        right: -7866213.4035243569,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.313113843752674],
              [-70.671176927511524, -33.309360233075296],
              [-70.665990499382303, -33.309360233075296],
              [-70.6633972853177, -33.313113843752674],
              [-70.665990499382303, -33.3168672928239],
              [-70.671176927511524, -33.3168672928239],
              [-70.673770141576128, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 93,
        left: -7867368.1046011159,
        bottom: -3936438.7195053762,
        right: -7866213.4035243569,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.305606460800909],
              [-70.671176927511524, -33.30185252693866],
              [-70.665990499382303, -33.30185252693866],
              [-70.6633972853177, -33.305606460800909],
              [-70.665990499382303, -33.309360233075296],
              [-70.671176927511524, -33.309360233075296],
              [-70.673770141576128, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 94,
        left: -7867368.1046011159,
        bottom: -3935438.7190391254,
        right: -7866213.4035243569,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.298098431497721],
              [-70.671176927511524, -33.294344174487243],
              [-70.665990499382303, -33.294344174487243],
              [-70.6633972853177, -33.298098431497721],
              [-70.665990499382303, -33.30185252693866],
              [-70.671176927511524, -33.30185252693866],
              [-70.673770141576128, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 95,
        left: -7867368.1046011159,
        bottom: -3934438.718572875,
        right: -7866213.4035243569,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.290589755916407],
              [-70.671176927511524, -33.286835175794366],
              [-70.665990499382303, -33.286835175794366],
              [-70.6633972853177, -33.290589755916407],
              [-70.665990499382303, -33.294344174487243],
              [-70.671176927511524, -33.294344174487243],
              [-70.673770141576128, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 96,
        left: -7867368.1046011159,
        bottom: -3933438.7181066247,
        right: -7866213.4035243569,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.673770141576128, -33.283080434130305],
              [-70.671176927511524, -33.279325530933384],
              [-70.665990499382303, -33.279325530933384],
              [-70.6633972853177, -33.283080434130305],
              [-70.665990499382303, -33.286835175794366],
              [-70.671176927511524, -33.286835175794366],
              [-70.673770141576128, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 97,
        left: -7866502.0787935462,
        bottom: -3980938.7402535151,
        right: -7865347.3777167881,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.639058347612803],
              [-70.6633972853177, -33.63531883056551],
              [-70.658210857188493, -33.63531883056551],
              [-70.65561764312389, -33.639058347612803],
              [-70.658210857188493, -33.642797702269725],
              [-70.6633972853177, -33.642797702269725],
              [-70.665990499382303, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 98,
        left: -7866502.0787935462,
        bottom: -3979938.7397872647,
        right: -7865347.3777167881,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.631579151136748],
              [-70.6633972853177, -33.627839309335386],
              [-70.658210857188493, -33.627839309335386],
              [-70.65561764312389, -33.631579151136748],
              [-70.658210857188493, -33.63531883056551],
              [-70.6633972853177, -33.63531883056551],
              [-70.665990499382303, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 99,
        left: -7866502.0787935462,
        bottom: -3978938.7393210144,
        right: -7865347.3777167881,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.624099305170297],
              [-70.6633972853177, -33.620359138650379],
              [-70.658210857188493, -33.620359138650379],
              [-70.65561764312389, -33.624099305170297],
              [-70.658210857188493, -33.627839309335386],
              [-70.6633972853177, -33.627839309335386],
              [-70.665990499382303, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 100,
        left: -7866502.0787935462,
        bottom: -3977938.738854764,
        right: -7865347.3777167881,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.616618809784534],
              [-70.6633972853177, -33.612878318581629],
              [-70.658210857188493, -33.612878318581629],
              [-70.65561764312389, -33.616618809784534],
              [-70.658210857188493, -33.620359138650379],
              [-70.6633972853177, -33.620359138650379],
              [-70.665990499382303, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 101,
        left: -7866502.0787935462,
        bottom: -3976938.7383885137,
        right: -7865347.3777167881,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.609137665050582],
              [-70.6633972853177, -33.605396849200282],
              [-70.658210857188493, -33.605396849200282],
              [-70.65561764312389, -33.609137665050582],
              [-70.658210857188493, -33.612878318581629],
              [-70.6633972853177, -33.612878318581629],
              [-70.665990499382303, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 102,
        left: -7866502.0787935462,
        bottom: -3975938.7379222633,
        right: -7865347.3777167881,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.601655871039647],
              [-70.6633972853177, -33.597914730577571],
              [-70.658210857188493, -33.597914730577571],
              [-70.65561764312389, -33.601655871039647],
              [-70.658210857188493, -33.605396849200282],
              [-70.6633972853177, -33.605396849200282],
              [-70.665990499382303, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 103,
        left: -7866502.0787935462,
        bottom: -3974938.737456013,
        right: -7865347.3777167881,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.594173427822959],
              [-70.6633972853177, -33.590431962784734],
              [-70.658210857188493, -33.590431962784734],
              [-70.65561764312389, -33.594173427822959],
              [-70.658210857188493, -33.597914730577571],
              [-70.6633972853177, -33.597914730577571],
              [-70.665990499382303, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 104,
        left: -7866502.0787935462,
        bottom: -3973938.7369897626,
        right: -7865347.3777167881,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.586690335471808],
              [-70.6633972853177, -33.582948545893096],
              [-70.658210857188493, -33.582948545893096],
              [-70.65561764312389, -33.586690335471808],
              [-70.658210857188493, -33.590431962784734],
              [-70.6633972853177, -33.590431962784734],
              [-70.665990499382303, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 105,
        left: -7866502.0787935462,
        bottom: -3972938.7365235123,
        right: -7865347.3777167881,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.579206594057531],
              [-70.6633972853177, -33.575464479974038],
              [-70.658210857188493, -33.575464479974038],
              [-70.65561764312389, -33.579206594057531],
              [-70.658210857188493, -33.582948545893096],
              [-70.6633972853177, -33.582948545893096],
              [-70.665990499382303, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 106,
        left: -7866502.0787935462,
        bottom: -3971938.7360572619,
        right: -7865347.3777167881,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.571722203651518],
              [-70.6633972853177, -33.56797976509894],
              [-70.658210857188493, -33.56797976509894],
              [-70.65561764312389, -33.571722203651518],
              [-70.658210857188493, -33.575464479974038],
              [-70.6633972853177, -33.575464479974038],
              [-70.665990499382303, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 107,
        left: -7866502.0787935462,
        bottom: -3970938.7355910116,
        right: -7865347.3777167881,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.56423716432522],
              [-70.6633972853177, -33.560494401339298],
              [-70.658210857188493, -33.560494401339298],
              [-70.65561764312389, -33.56423716432522],
              [-70.658210857188493, -33.56797976509894],
              [-70.6633972853177, -33.56797976509894],
              [-70.665990499382303, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 108,
        left: -7866502.0787935462,
        bottom: -3969938.7351247617,
        right: -7865347.3777167881,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.556751476150104],
              [-70.6633972853177, -33.553008388766614],
              [-70.658210857188493, -33.553008388766614],
              [-70.65561764312389, -33.556751476150104],
              [-70.658210857188493, -33.560494401339298],
              [-70.6633972853177, -33.560494401339298],
              [-70.665990499382303, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 109,
        left: -7866502.0787935462,
        bottom: -3968938.7346585114,
        right: -7865347.3777167881,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.549265139197736],
              [-70.6633972853177, -33.54552172745246],
              [-70.658210857188493, -33.54552172745246],
              [-70.65561764312389, -33.549265139197736],
              [-70.658210857188493, -33.553008388766614],
              [-70.6633972853177, -33.553008388766614],
              [-70.665990499382303, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 110,
        left: -7866502.0787935462,
        bottom: -3967938.734192261,
        right: -7865347.3777167881,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.541778153539695],
              [-70.6633972853177, -33.538034417468438],
              [-70.658210857188493, -33.538034417468438],
              [-70.65561764312389, -33.541778153539695],
              [-70.658210857188493, -33.54552172745246],
              [-70.6633972853177, -33.54552172745246],
              [-70.665990499382303, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 111,
        left: -7866502.0787935462,
        bottom: -3966938.7337260107,
        right: -7865347.3777167881,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.534290519247627],
              [-70.6633972853177, -33.530546458886235],
              [-70.658210857188493, -33.530546458886235],
              [-70.65561764312389, -33.534290519247627],
              [-70.658210857188493, -33.538034417468438],
              [-70.6633972853177, -33.538034417468438],
              [-70.665990499382303, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 112,
        left: -7866502.0787935462,
        bottom: -3965938.7332597603,
        right: -7865347.3777167881,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.526802236393216],
              [-70.6633972853177, -33.523057851777558],
              [-70.658210857188493, -33.523057851777558],
              [-70.65561764312389, -33.526802236393216],
              [-70.658210857188493, -33.530546458886235],
              [-70.6633972853177, -33.530546458886235],
              [-70.665990499382303, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 113,
        left: -7866502.0787935462,
        bottom: -3964938.7327935095,
        right: -7865347.3777167881,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.519313305048215],
              [-70.6633972853177, -33.515568596214173],
              [-70.658210857188493, -33.515568596214173],
              [-70.65561764312389, -33.519313305048215],
              [-70.658210857188493, -33.523057851777558],
              [-70.6633972853177, -33.523057851777558],
              [-70.665990499382303, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 114,
        left: -7866502.0787935462,
        bottom: -3963938.7323272596,
        right: -7865347.3777167881,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.511823725284408],
              [-70.6633972853177, -33.508078692267915],
              [-70.658210857188493, -33.508078692267915],
              [-70.65561764312389, -33.511823725284408],
              [-70.658210857188493, -33.515568596214173],
              [-70.6633972853177, -33.515568596214173],
              [-70.665990499382303, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 115,
        left: -7866502.0787935462,
        bottom: -3962938.7318610097,
        right: -7865347.3777167881,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.504333497173661],
              [-70.6633972853177, -33.500588140010635],
              [-70.658210857188493, -33.500588140010635],
              [-70.65561764312389, -33.504333497173661],
              [-70.658210857188493, -33.508078692267915],
              [-70.6633972853177, -33.508078692267915],
              [-70.665990499382303, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 116,
        left: -7866502.0787935462,
        bottom: -3961938.7313947589,
        right: -7865347.3777167881,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.496842620787838],
              [-70.6633972853177, -33.493096939514267],
              [-70.658210857188493, -33.493096939514267],
              [-70.65561764312389, -33.496842620787838],
              [-70.658210857188493, -33.500588140010635],
              [-70.6633972853177, -33.500588140010635],
              [-70.665990499382303, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 117,
        left: -7866502.0787935462,
        bottom: -3960938.7309285086,
        right: -7865347.3777167881,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.489351096198902],
              [-70.6633972853177, -33.485605090850768],
              [-70.658210857188493, -33.485605090850768],
              [-70.65561764312389, -33.489351096198902],
              [-70.658210857188493, -33.493096939514267],
              [-70.6633972853177, -33.493096939514267],
              [-70.665990499382303, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 118,
        left: -7866502.0787935462,
        bottom: -3959938.7304622582,
        right: -7865347.3777167881,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.48185892347886],
              [-70.6633972853177, -33.478112594092174],
              [-70.658210857188493, -33.478112594092174],
              [-70.65561764312389, -33.48185892347886],
              [-70.658210857188493, -33.485605090850768],
              [-70.6633972853177, -33.485605090850768],
              [-70.665990499382303, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 119,
        left: -7866502.0787935462,
        bottom: -3958938.7299960079,
        right: -7865347.3777167881,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.474366102699733],
              [-70.6633972853177, -33.470619449310547],
              [-70.658210857188493, -33.470619449310547],
              [-70.65561764312389, -33.474366102699733],
              [-70.658210857188493, -33.478112594092174],
              [-70.6633972853177, -33.478112594092174],
              [-70.665990499382303, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 120,
        left: -7866502.0787935462,
        bottom: -3957938.7295297575,
        right: -7865347.3777167881,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.466872633933626],
              [-70.6633972853177, -33.463125656578008],
              [-70.658210857188493, -33.463125656578008],
              [-70.65561764312389, -33.466872633933626],
              [-70.658210857188493, -33.470619449310547],
              [-70.6633972853177, -33.470619449310547],
              [-70.665990499382303, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 121,
        left: -7866502.0787935462,
        bottom: -3956938.7290635072,
        right: -7865347.3777167881,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.459378517252702],
              [-70.6633972853177, -33.45563121596674],
              [-70.658210857188493, -33.45563121596674],
              [-70.65561764312389, -33.459378517252702],
              [-70.658210857188493, -33.463125656578008],
              [-70.6633972853177, -33.463125656578008],
              [-70.665990499382303, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 122,
        left: -7866502.0787935462,
        bottom: -3955938.7285972568,
        right: -7865347.3777167881,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.451883752729138],
              [-70.6633972853177, -33.448136127548949],
              [-70.658210857188493, -33.448136127548949],
              [-70.65561764312389, -33.451883752729138],
              [-70.658210857188493, -33.45563121596674],
              [-70.6633972853177, -33.45563121596674],
              [-70.665990499382303, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 123,
        left: -7866502.0787935462,
        bottom: -3954938.7281310065,
        right: -7865347.3777167881,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.444388340435196],
              [-70.6633972853177, -33.440640391396926],
              [-70.658210857188493, -33.440640391396926],
              [-70.65561764312389, -33.444388340435196],
              [-70.658210857188493, -33.448136127548949],
              [-70.6633972853177, -33.448136127548949],
              [-70.665990499382303, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 124,
        left: -7866502.0787935462,
        bottom: -3953938.7276647561,
        right: -7865347.3777167881,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.436892280443168],
              [-70.6633972853177, -33.433144007582989],
              [-70.658210857188493, -33.433144007582989],
              [-70.65561764312389, -33.436892280443168],
              [-70.658210857188493, -33.440640391396926],
              [-70.6633972853177, -33.440640391396926],
              [-70.665990499382303, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 125,
        left: -7866502.0787935462,
        bottom: -3952938.7271985058,
        right: -7865347.3777167881,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.429395572825413],
              [-70.6633972853177, -33.425646976179515],
              [-70.658210857188493, -33.425646976179515],
              [-70.65561764312389, -33.429395572825413],
              [-70.658210857188493, -33.433144007582989],
              [-70.6633972853177, -33.433144007582989],
              [-70.665990499382303, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 126,
        left: -7866502.0787935462,
        bottom: -3951938.7267322554,
        right: -7865347.3777167881,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.421898217654316],
              [-70.6633972853177, -33.418149297258921],
              [-70.658210857188493, -33.418149297258921],
              [-70.65561764312389, -33.421898217654316],
              [-70.658210857188493, -33.425646976179515],
              [-70.6633972853177, -33.425646976179515],
              [-70.665990499382303, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 127,
        left: -7866502.0787935462,
        bottom: -3950938.7262660051,
        right: -7865347.3777167881,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.414400215002352],
              [-70.6633972853177, -33.410650970893691],
              [-70.658210857188493, -33.410650970893691],
              [-70.65561764312389, -33.414400215002352],
              [-70.658210857188493, -33.418149297258921],
              [-70.6633972853177, -33.418149297258921],
              [-70.665990499382303, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 128,
        left: -7866502.0787935462,
        bottom: -3949938.7257997552,
        right: -7865347.3777167881,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.406901564941997],
              [-70.6633972853177, -33.40315199715635],
              [-70.658210857188493, -33.40315199715635],
              [-70.65561764312389, -33.406901564941997],
              [-70.658210857188493, -33.410650970893691],
              [-70.6633972853177, -33.410650970893691],
              [-70.665990499382303, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 129,
        left: -7866502.0787935462,
        bottom: -3948938.7253335048,
        right: -7865347.3777167881,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.399402267545824],
              [-70.6633972853177, -33.395652376119486],
              [-70.658210857188493, -33.395652376119486],
              [-70.65561764312389, -33.399402267545824],
              [-70.658210857188493, -33.40315199715635],
              [-70.6633972853177, -33.40315199715635],
              [-70.665990499382303, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 130,
        left: -7866502.0787935462,
        bottom: -3947938.724867254,
        right: -7865347.3777167881,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.391902322886416],
              [-70.6633972853177, -33.388152107855717],
              [-70.658210857188493, -33.388152107855717],
              [-70.65561764312389, -33.391902322886416],
              [-70.658210857188493, -33.395652376119486],
              [-70.6633972853177, -33.395652376119486],
              [-70.665990499382303, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 131,
        left: -7866502.0787935462,
        bottom: -3946938.7244010041,
        right: -7865347.3777167881,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.384401731036441],
              [-70.6633972853177, -33.380651192437711],
              [-70.658210857188493, -33.380651192437711],
              [-70.65561764312389, -33.384401731036441],
              [-70.658210857188493, -33.388152107855717],
              [-70.6633972853177, -33.388152107855717],
              [-70.665990499382303, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 132,
        left: -7866502.0787935462,
        bottom: -3945938.7239347543,
        right: -7865347.3777167881,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.3769004920686],
              [-70.6633972853177, -33.373149629938226],
              [-70.658210857188493, -33.373149629938226],
              [-70.65561764312389, -33.3769004920686],
              [-70.658210857188493, -33.380651192437711],
              [-70.6633972853177, -33.380651192437711],
              [-70.665990499382303, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 133,
        left: -7866502.0787935462,
        bottom: -3944938.7234685034,
        right: -7865347.3777167881,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.369398606055647],
              [-70.6633972853177, -33.365647420430008],
              [-70.658210857188493, -33.365647420430008],
              [-70.65561764312389, -33.369398606055647],
              [-70.658210857188493, -33.373149629938226],
              [-70.6633972853177, -33.373149629938226],
              [-70.665990499382303, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 134,
        left: -7866502.0787935462,
        bottom: -3943938.7230022531,
        right: -7865347.3777167881,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.36189607307039],
              [-70.6633972853177, -33.358144563985917],
              [-70.658210857188493, -33.358144563985917],
              [-70.65561764312389, -33.36189607307039],
              [-70.658210857188493, -33.365647420430008],
              [-70.6633972853177, -33.365647420430008],
              [-70.665990499382303, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 135,
        left: -7866502.0787935462,
        bottom: -3942938.7225360028,
        right: -7865347.3777167881,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.35439289318569],
              [-70.6633972853177, -33.350641060678818],
              [-70.658210857188493, -33.350641060678818],
              [-70.65561764312389, -33.35439289318569],
              [-70.658210857188493, -33.358144563985917],
              [-70.6633972853177, -33.358144563985917],
              [-70.665990499382303, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 136,
        left: -7866502.0787935462,
        bottom: -3941938.7220697524,
        right: -7865347.3777167881,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.346889066474425],
              [-70.6633972853177, -33.343136910581649],
              [-70.658210857188493, -33.343136910581649],
              [-70.65561764312389, -33.346889066474425],
              [-70.658210857188493, -33.350641060678818],
              [-70.6633972853177, -33.350641060678818],
              [-70.665990499382303, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 137,
        left: -7866502.0787935462,
        bottom: -3940938.7216035021,
        right: -7865347.3777167881,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.339384593009591],
              [-70.6633972853177, -33.335632113767389],
              [-70.658210857188493, -33.335632113767389],
              [-70.65561764312389, -33.339384593009591],
              [-70.658210857188493, -33.343136910581649],
              [-70.6633972853177, -33.343136910581649],
              [-70.665990499382303, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 138,
        left: -7866502.0787935462,
        bottom: -3939938.7211372517,
        right: -7865347.3777167881,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.331879472864181],
              [-70.6633972853177, -33.328126670309082],
              [-70.658210857188493, -33.328126670309082],
              [-70.65561764312389, -33.331879472864181],
              [-70.658210857188493, -33.335632113767389],
              [-70.6633972853177, -33.335632113767389],
              [-70.665990499382303, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 139,
        left: -7866502.0787935462,
        bottom: -3938938.7206710014,
        right: -7865347.3777167881,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.324373706111238],
              [-70.6633972853177, -33.320620580279794],
              [-70.658210857188493, -33.320620580279794],
              [-70.65561764312389, -33.324373706111238],
              [-70.658210857188493, -33.328126670309082],
              [-70.6633972853177, -33.328126670309082],
              [-70.665990499382303, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 140,
        left: -7866502.0787935462,
        bottom: -3937938.720204751,
        right: -7865347.3777167881,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.3168672928239],
              [-70.6633972853177, -33.313113843752674],
              [-70.658210857188493, -33.313113843752674],
              [-70.65561764312389, -33.3168672928239],
              [-70.658210857188493, -33.320620580279794],
              [-70.6633972853177, -33.320620580279794],
              [-70.665990499382303, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 141,
        left: -7866502.0787935462,
        bottom: -3936938.7197385007,
        right: -7865347.3777167881,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.309360233075296],
              [-70.6633972853177, -33.305606460800909],
              [-70.658210857188493, -33.305606460800909],
              [-70.65561764312389, -33.309360233075296],
              [-70.658210857188493, -33.313113843752674],
              [-70.6633972853177, -33.313113843752674],
              [-70.665990499382303, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 142,
        left: -7866502.0787935462,
        bottom: -3935938.7192722503,
        right: -7865347.3777167881,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.30185252693866],
              [-70.6633972853177, -33.298098431497721],
              [-70.658210857188493, -33.298098431497721],
              [-70.65561764312389, -33.30185252693866],
              [-70.658210857188493, -33.305606460800909],
              [-70.6633972853177, -33.305606460800909],
              [-70.665990499382303, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 143,
        left: -7866502.0787935462,
        bottom: -3934938.718806,
        right: -7865347.3777167881,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.294344174487243],
              [-70.6633972853177, -33.290589755916407],
              [-70.658210857188493, -33.290589755916407],
              [-70.65561764312389, -33.294344174487243],
              [-70.658210857188493, -33.298098431497721],
              [-70.6633972853177, -33.298098431497721],
              [-70.665990499382303, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 144,
        left: -7866502.0787935462,
        bottom: -3933938.7183397496,
        right: -7865347.3777167881,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.665990499382303, -33.286835175794366],
              [-70.6633972853177, -33.283080434130305],
              [-70.658210857188493, -33.283080434130305],
              [-70.65561764312389, -33.286835175794366],
              [-70.658210857188493, -33.290589755916407],
              [-70.6633972853177, -33.290589755916407],
              [-70.665990499382303, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 145,
        left: -7865636.0529859774,
        bottom: -3980438.7400203901,
        right: -7864481.3519092202,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.63531883056551],
              [-70.65561764312389, -33.631579151136748],
              [-70.650431214994683, -33.631579151136748],
              [-70.647838000930079, -33.63531883056551],
              [-70.650431214994683, -33.639058347612803],
              [-70.65561764312389, -33.639058347612803],
              [-70.658210857188493, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 146,
        left: -7865636.0529859774,
        bottom: -3979438.7395541398,
        right: -7864481.3519092202,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.627839309335386],
              [-70.65561764312389, -33.624099305170297],
              [-70.650431214994683, -33.624099305170297],
              [-70.647838000930079, -33.627839309335386],
              [-70.650431214994683, -33.631579151136748],
              [-70.65561764312389, -33.631579151136748],
              [-70.658210857188493, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 147,
        left: -7865636.0529859774,
        bottom: -3978438.7390878894,
        right: -7864481.3519092202,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.620359138650379],
              [-70.65561764312389, -33.616618809784534],
              [-70.650431214994683, -33.616618809784534],
              [-70.647838000930079, -33.620359138650379],
              [-70.650431214994683, -33.624099305170297],
              [-70.65561764312389, -33.624099305170297],
              [-70.658210857188493, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 148,
        left: -7865636.0529859774,
        bottom: -3977438.7386216391,
        right: -7864481.3519092202,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.612878318581629],
              [-70.65561764312389, -33.609137665050582],
              [-70.650431214994683, -33.609137665050582],
              [-70.647838000930079, -33.612878318581629],
              [-70.650431214994683, -33.616618809784534],
              [-70.65561764312389, -33.616618809784534],
              [-70.658210857188493, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 149,
        left: -7865636.0529859774,
        bottom: -3976438.7381553887,
        right: -7864481.3519092202,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.605396849200282],
              [-70.65561764312389, -33.601655871039647],
              [-70.650431214994683, -33.601655871039647],
              [-70.647838000930079, -33.605396849200282],
              [-70.650431214994683, -33.609137665050582],
              [-70.65561764312389, -33.609137665050582],
              [-70.658210857188493, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 150,
        left: -7865636.0529859774,
        bottom: -3975438.7376891384,
        right: -7864481.3519092202,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.597914730577571],
              [-70.65561764312389, -33.594173427822959],
              [-70.650431214994683, -33.594173427822959],
              [-70.647838000930079, -33.597914730577571],
              [-70.650431214994683, -33.601655871039647],
              [-70.65561764312389, -33.601655871039647],
              [-70.658210857188493, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 151,
        left: -7865636.0529859774,
        bottom: -3974438.7372228876,
        right: -7864481.3519092202,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.590431962784734],
              [-70.65561764312389, -33.586690335471808],
              [-70.650431214994683, -33.586690335471808],
              [-70.647838000930079, -33.590431962784734],
              [-70.650431214994683, -33.594173427822959],
              [-70.65561764312389, -33.594173427822959],
              [-70.658210857188493, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 152,
        left: -7865636.0529859774,
        bottom: -3973438.7367566377,
        right: -7864481.3519092202,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.582948545893096],
              [-70.65561764312389, -33.579206594057531],
              [-70.650431214994683, -33.579206594057531],
              [-70.647838000930079, -33.582948545893096],
              [-70.650431214994683, -33.586690335471808],
              [-70.65561764312389, -33.586690335471808],
              [-70.658210857188493, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 153,
        left: -7865636.0529859774,
        bottom: -3972438.7362903878,
        right: -7864481.3519092202,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.575464479974038],
              [-70.65561764312389, -33.571722203651518],
              [-70.650431214994683, -33.571722203651518],
              [-70.647838000930079, -33.575464479974038],
              [-70.650431214994683, -33.579206594057531],
              [-70.65561764312389, -33.579206594057531],
              [-70.658210857188493, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 154,
        left: -7865636.0529859774,
        bottom: -3971438.735824137,
        right: -7864481.3519092202,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.56797976509894],
              [-70.65561764312389, -33.56423716432522],
              [-70.650431214994683, -33.56423716432522],
              [-70.647838000930079, -33.56797976509894],
              [-70.650431214994683, -33.571722203651518],
              [-70.65561764312389, -33.571722203651518],
              [-70.658210857188493, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 155,
        left: -7865636.0529859774,
        bottom: -3970438.7353578866,
        right: -7864481.3519092202,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.560494401339298],
              [-70.65561764312389, -33.556751476150104],
              [-70.650431214994683, -33.556751476150104],
              [-70.647838000930079, -33.560494401339298],
              [-70.650431214994683, -33.56423716432522],
              [-70.65561764312389, -33.56423716432522],
              [-70.658210857188493, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 156,
        left: -7865636.0529859774,
        bottom: -3969438.7348916363,
        right: -7864481.3519092202,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.553008388766614],
              [-70.65561764312389, -33.549265139197736],
              [-70.650431214994683, -33.549265139197736],
              [-70.647838000930079, -33.553008388766614],
              [-70.650431214994683, -33.556751476150104],
              [-70.65561764312389, -33.556751476150104],
              [-70.658210857188493, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 157,
        left: -7865636.0529859774,
        bottom: -3968438.7344253859,
        right: -7864481.3519092202,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.54552172745246],
              [-70.65561764312389, -33.541778153539695],
              [-70.650431214994683, -33.541778153539695],
              [-70.647838000930079, -33.54552172745246],
              [-70.650431214994683, -33.549265139197736],
              [-70.65561764312389, -33.549265139197736],
              [-70.658210857188493, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 158,
        left: -7865636.0529859774,
        bottom: -3967438.7339591356,
        right: -7864481.3519092202,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.538034417468438],
              [-70.65561764312389, -33.534290519247627],
              [-70.650431214994683, -33.534290519247627],
              [-70.647838000930079, -33.538034417468438],
              [-70.650431214994683, -33.541778153539695],
              [-70.65561764312389, -33.541778153539695],
              [-70.658210857188493, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 159,
        left: -7865636.0529859774,
        bottom: -3966438.7334928853,
        right: -7864481.3519092202,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.530546458886235],
              [-70.65561764312389, -33.526802236393216],
              [-70.650431214994683, -33.526802236393216],
              [-70.647838000930079, -33.530546458886235],
              [-70.650431214994683, -33.534290519247627],
              [-70.65561764312389, -33.534290519247627],
              [-70.658210857188493, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 160,
        left: -7865636.0529859774,
        bottom: -3965438.7330266349,
        right: -7864481.3519092202,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.523057851777558],
              [-70.65561764312389, -33.519313305048215],
              [-70.650431214994683, -33.519313305048215],
              [-70.647838000930079, -33.523057851777558],
              [-70.650431214994683, -33.526802236393216],
              [-70.65561764312389, -33.526802236393216],
              [-70.658210857188493, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 161,
        left: -7865636.0529859774,
        bottom: -3964438.7325603846,
        right: -7864481.3519092202,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.515568596214173],
              [-70.65561764312389, -33.511823725284408],
              [-70.650431214994683, -33.511823725284408],
              [-70.647838000930079, -33.515568596214173],
              [-70.650431214994683, -33.519313305048215],
              [-70.65561764312389, -33.519313305048215],
              [-70.658210857188493, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 162,
        left: -7865636.0529859774,
        bottom: -3963438.7320941342,
        right: -7864481.3519092202,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.508078692267915],
              [-70.65561764312389, -33.504333497173661],
              [-70.650431214994683, -33.504333497173661],
              [-70.647838000930079, -33.508078692267915],
              [-70.650431214994683, -33.511823725284408],
              [-70.65561764312389, -33.511823725284408],
              [-70.658210857188493, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 163,
        left: -7865636.0529859774,
        bottom: -3962438.7316278839,
        right: -7864481.3519092202,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.500588140010635],
              [-70.65561764312389, -33.496842620787838],
              [-70.650431214994683, -33.496842620787838],
              [-70.647838000930079, -33.500588140010635],
              [-70.650431214994683, -33.504333497173661],
              [-70.65561764312389, -33.504333497173661],
              [-70.658210857188493, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 164,
        left: -7865636.0529859774,
        bottom: -3961438.7311616335,
        right: -7864481.3519092202,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.493096939514267],
              [-70.65561764312389, -33.489351096198902],
              [-70.650431214994683, -33.489351096198902],
              [-70.647838000930079, -33.493096939514267],
              [-70.650431214994683, -33.496842620787838],
              [-70.65561764312389, -33.496842620787838],
              [-70.658210857188493, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 165,
        left: -7865636.0529859774,
        bottom: -3960438.7306953832,
        right: -7864481.3519092202,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.485605090850768],
              [-70.65561764312389, -33.48185892347886],
              [-70.650431214994683, -33.48185892347886],
              [-70.647838000930079, -33.485605090850768],
              [-70.650431214994683, -33.489351096198902],
              [-70.65561764312389, -33.489351096198902],
              [-70.658210857188493, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 166,
        left: -7865636.0529859774,
        bottom: -3959438.7302291333,
        right: -7864481.3519092202,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.478112594092174],
              [-70.65561764312389, -33.474366102699733],
              [-70.650431214994683, -33.474366102699733],
              [-70.647838000930079, -33.478112594092174],
              [-70.650431214994683, -33.48185892347886],
              [-70.65561764312389, -33.48185892347886],
              [-70.658210857188493, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 167,
        left: -7865636.0529859774,
        bottom: -3958438.7297628829,
        right: -7864481.3519092202,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.470619449310547],
              [-70.65561764312389, -33.466872633933626],
              [-70.650431214994683, -33.466872633933626],
              [-70.647838000930079, -33.470619449310547],
              [-70.650431214994683, -33.474366102699733],
              [-70.65561764312389, -33.474366102699733],
              [-70.658210857188493, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 168,
        left: -7865636.0529859774,
        bottom: -3957438.7292966326,
        right: -7864481.3519092202,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.463125656578008],
              [-70.65561764312389, -33.459378517252702],
              [-70.650431214994683, -33.459378517252702],
              [-70.647838000930079, -33.463125656578008],
              [-70.650431214994683, -33.466872633933626],
              [-70.65561764312389, -33.466872633933626],
              [-70.658210857188493, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 169,
        left: -7865636.0529859774,
        bottom: -3956438.7288303822,
        right: -7864481.3519092202,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.45563121596674],
              [-70.65561764312389, -33.451883752729138],
              [-70.650431214994683, -33.451883752729138],
              [-70.647838000930079, -33.45563121596674],
              [-70.650431214994683, -33.459378517252702],
              [-70.65561764312389, -33.459378517252702],
              [-70.658210857188493, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 170,
        left: -7865636.0529859774,
        bottom: -3955438.7283641323,
        right: -7864481.3519092202,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.448136127548949],
              [-70.65561764312389, -33.444388340435196],
              [-70.650431214994683, -33.444388340435196],
              [-70.647838000930079, -33.448136127548949],
              [-70.650431214994683, -33.451883752729138],
              [-70.65561764312389, -33.451883752729138],
              [-70.658210857188493, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 171,
        left: -7865636.0529859774,
        bottom: -3954438.7278978815,
        right: -7864481.3519092202,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.440640391396926],
              [-70.65561764312389, -33.436892280443168],
              [-70.650431214994683, -33.436892280443168],
              [-70.647838000930079, -33.440640391396926],
              [-70.650431214994683, -33.444388340435196],
              [-70.65561764312389, -33.444388340435196],
              [-70.658210857188493, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 172,
        left: -7865636.0529859774,
        bottom: -3953438.7274316312,
        right: -7864481.3519092202,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.433144007582989],
              [-70.65561764312389, -33.429395572825413],
              [-70.650431214994683, -33.429395572825413],
              [-70.647838000930079, -33.433144007582989],
              [-70.650431214994683, -33.436892280443168],
              [-70.65561764312389, -33.436892280443168],
              [-70.658210857188493, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 173,
        left: -7865636.0529859774,
        bottom: -3952438.7269653808,
        right: -7864481.3519092202,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.425646976179515],
              [-70.65561764312389, -33.421898217654316],
              [-70.650431214994683, -33.421898217654316],
              [-70.647838000930079, -33.425646976179515],
              [-70.650431214994683, -33.429395572825413],
              [-70.65561764312389, -33.429395572825413],
              [-70.658210857188493, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 174,
        left: -7865636.0529859774,
        bottom: -3951438.7264991305,
        right: -7864481.3519092202,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.418149297258921],
              [-70.65561764312389, -33.414400215002352],
              [-70.650431214994683, -33.414400215002352],
              [-70.647838000930079, -33.418149297258921],
              [-70.650431214994683, -33.421898217654316],
              [-70.65561764312389, -33.421898217654316],
              [-70.658210857188493, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 175,
        left: -7865636.0529859774,
        bottom: -3950438.7260328801,
        right: -7864481.3519092202,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.410650970893691],
              [-70.65561764312389, -33.406901564941997],
              [-70.650431214994683, -33.406901564941997],
              [-70.647838000930079, -33.410650970893691],
              [-70.650431214994683, -33.414400215002352],
              [-70.65561764312389, -33.414400215002352],
              [-70.658210857188493, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 176,
        left: -7865636.0529859774,
        bottom: -3949438.7255666298,
        right: -7864481.3519092202,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.40315199715635],
              [-70.65561764312389, -33.399402267545824],
              [-70.650431214994683, -33.399402267545824],
              [-70.647838000930079, -33.40315199715635],
              [-70.650431214994683, -33.406901564941997],
              [-70.65561764312389, -33.406901564941997],
              [-70.658210857188493, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 177,
        left: -7865636.0529859774,
        bottom: -3948438.7251003794,
        right: -7864481.3519092202,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.395652376119486],
              [-70.65561764312389, -33.391902322886416],
              [-70.650431214994683, -33.391902322886416],
              [-70.647838000930079, -33.395652376119486],
              [-70.650431214994683, -33.399402267545824],
              [-70.65561764312389, -33.399402267545824],
              [-70.658210857188493, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 178,
        left: -7865636.0529859774,
        bottom: -3947438.7246341291,
        right: -7864481.3519092202,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.388152107855717],
              [-70.65561764312389, -33.384401731036441],
              [-70.650431214994683, -33.384401731036441],
              [-70.647838000930079, -33.388152107855717],
              [-70.650431214994683, -33.391902322886416],
              [-70.65561764312389, -33.391902322886416],
              [-70.658210857188493, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 179,
        left: -7865636.0529859774,
        bottom: -3946438.7241678787,
        right: -7864481.3519092202,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.380651192437711],
              [-70.65561764312389, -33.3769004920686],
              [-70.650431214994683, -33.3769004920686],
              [-70.647838000930079, -33.380651192437711],
              [-70.650431214994683, -33.384401731036441],
              [-70.65561764312389, -33.384401731036441],
              [-70.658210857188493, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 180,
        left: -7865636.0529859774,
        bottom: -3945438.7237016284,
        right: -7864481.3519092202,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.373149629938226],
              [-70.65561764312389, -33.369398606055647],
              [-70.650431214994683, -33.369398606055647],
              [-70.647838000930079, -33.373149629938226],
              [-70.650431214994683, -33.3769004920686],
              [-70.65561764312389, -33.3769004920686],
              [-70.658210857188493, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 181,
        left: -7865636.0529859774,
        bottom: -3944438.723235378,
        right: -7864481.3519092202,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.365647420430008],
              [-70.65561764312389, -33.36189607307039],
              [-70.650431214994683, -33.36189607307039],
              [-70.647838000930079, -33.365647420430008],
              [-70.650431214994683, -33.369398606055647],
              [-70.65561764312389, -33.369398606055647],
              [-70.658210857188493, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 182,
        left: -7865636.0529859774,
        bottom: -3943438.7227691277,
        right: -7864481.3519092202,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.358144563985917],
              [-70.65561764312389, -33.35439289318569],
              [-70.650431214994683, -33.35439289318569],
              [-70.647838000930079, -33.358144563985917],
              [-70.650431214994683, -33.36189607307039],
              [-70.65561764312389, -33.36189607307039],
              [-70.658210857188493, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 183,
        left: -7865636.0529859774,
        bottom: -3942438.7223028769,
        right: -7864481.3519092202,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.350641060678818],
              [-70.65561764312389, -33.346889066474425],
              [-70.650431214994683, -33.346889066474425],
              [-70.647838000930079, -33.350641060678818],
              [-70.650431214994683, -33.35439289318569],
              [-70.65561764312389, -33.35439289318569],
              [-70.658210857188493, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 184,
        left: -7865636.0529859774,
        bottom: -3941438.721836627,
        right: -7864481.3519092202,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.343136910581649],
              [-70.65561764312389, -33.339384593009591],
              [-70.650431214994683, -33.339384593009591],
              [-70.647838000930079, -33.343136910581649],
              [-70.650431214994683, -33.346889066474425],
              [-70.65561764312389, -33.346889066474425],
              [-70.658210857188493, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 185,
        left: -7865636.0529859774,
        bottom: -3940438.7213703771,
        right: -7864481.3519092202,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.335632113767389],
              [-70.65561764312389, -33.331879472864181],
              [-70.650431214994683, -33.331879472864181],
              [-70.647838000930079, -33.335632113767389],
              [-70.650431214994683, -33.339384593009591],
              [-70.65561764312389, -33.339384593009591],
              [-70.658210857188493, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 186,
        left: -7865636.0529859774,
        bottom: -3939438.7209041263,
        right: -7864481.3519092202,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.328126670309082],
              [-70.65561764312389, -33.324373706111238],
              [-70.650431214994683, -33.324373706111238],
              [-70.647838000930079, -33.328126670309082],
              [-70.650431214994683, -33.331879472864181],
              [-70.65561764312389, -33.331879472864181],
              [-70.658210857188493, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 187,
        left: -7865636.0529859774,
        bottom: -3938438.7204378759,
        right: -7864481.3519092202,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.320620580279794],
              [-70.65561764312389, -33.3168672928239],
              [-70.650431214994683, -33.3168672928239],
              [-70.647838000930079, -33.320620580279794],
              [-70.650431214994683, -33.324373706111238],
              [-70.65561764312389, -33.324373706111238],
              [-70.658210857188493, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 188,
        left: -7865636.0529859774,
        bottom: -3937438.7199716261,
        right: -7864481.3519092202,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.313113843752674],
              [-70.65561764312389, -33.309360233075296],
              [-70.650431214994683, -33.309360233075296],
              [-70.647838000930079, -33.313113843752674],
              [-70.650431214994683, -33.3168672928239],
              [-70.65561764312389, -33.3168672928239],
              [-70.658210857188493, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 189,
        left: -7865636.0529859774,
        bottom: -3936438.7195053762,
        right: -7864481.3519092202,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.305606460800909],
              [-70.65561764312389, -33.30185252693866],
              [-70.650431214994683, -33.30185252693866],
              [-70.647838000930079, -33.305606460800909],
              [-70.650431214994683, -33.309360233075296],
              [-70.65561764312389, -33.309360233075296],
              [-70.658210857188493, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 190,
        left: -7865636.0529859774,
        bottom: -3935438.7190391254,
        right: -7864481.3519092202,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.298098431497721],
              [-70.65561764312389, -33.294344174487243],
              [-70.650431214994683, -33.294344174487243],
              [-70.647838000930079, -33.298098431497721],
              [-70.650431214994683, -33.30185252693866],
              [-70.65561764312389, -33.30185252693866],
              [-70.658210857188493, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 191,
        left: -7865636.0529859774,
        bottom: -3934438.718572875,
        right: -7864481.3519092202,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.290589755916407],
              [-70.65561764312389, -33.286835175794366],
              [-70.650431214994683, -33.286835175794366],
              [-70.647838000930079, -33.290589755916407],
              [-70.650431214994683, -33.294344174487243],
              [-70.65561764312389, -33.294344174487243],
              [-70.658210857188493, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 192,
        left: -7865636.0529859774,
        bottom: -3933438.7181066247,
        right: -7864481.3519092202,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.658210857188493, -33.283080434130305],
              [-70.65561764312389, -33.279325530933384],
              [-70.650431214994683, -33.279325530933384],
              [-70.647838000930079, -33.283080434130305],
              [-70.650431214994683, -33.286835175794366],
              [-70.65561764312389, -33.286835175794366],
              [-70.658210857188493, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 193,
        left: -7864770.0271784086,
        bottom: -3980938.7402535151,
        right: -7863615.3261016505,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.639058347612803],
              [-70.647838000930079, -33.63531883056551],
              [-70.642651572800872, -33.63531883056551],
              [-70.640058358736269, -33.639058347612803],
              [-70.642651572800872, -33.642797702269725],
              [-70.647838000930079, -33.642797702269725],
              [-70.650431214994683, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 194,
        left: -7864770.0271784086,
        bottom: -3979938.7397872647,
        right: -7863615.3261016505,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.631579151136748],
              [-70.647838000930079, -33.627839309335386],
              [-70.642651572800872, -33.627839309335386],
              [-70.640058358736269, -33.631579151136748],
              [-70.642651572800872, -33.63531883056551],
              [-70.647838000930079, -33.63531883056551],
              [-70.650431214994683, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 195,
        left: -7864770.0271784086,
        bottom: -3978938.7393210144,
        right: -7863615.3261016505,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.624099305170297],
              [-70.647838000930079, -33.620359138650379],
              [-70.642651572800872, -33.620359138650379],
              [-70.640058358736269, -33.624099305170297],
              [-70.642651572800872, -33.627839309335386],
              [-70.647838000930079, -33.627839309335386],
              [-70.650431214994683, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 196,
        left: -7864770.0271784086,
        bottom: -3977938.738854764,
        right: -7863615.3261016505,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.616618809784534],
              [-70.647838000930079, -33.612878318581629],
              [-70.642651572800872, -33.612878318581629],
              [-70.640058358736269, -33.616618809784534],
              [-70.642651572800872, -33.620359138650379],
              [-70.647838000930079, -33.620359138650379],
              [-70.650431214994683, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 197,
        left: -7864770.0271784086,
        bottom: -3976938.7383885137,
        right: -7863615.3261016505,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.609137665050582],
              [-70.647838000930079, -33.605396849200282],
              [-70.642651572800872, -33.605396849200282],
              [-70.640058358736269, -33.609137665050582],
              [-70.642651572800872, -33.612878318581629],
              [-70.647838000930079, -33.612878318581629],
              [-70.650431214994683, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 198,
        left: -7864770.0271784086,
        bottom: -3975938.7379222633,
        right: -7863615.3261016505,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.601655871039647],
              [-70.647838000930079, -33.597914730577571],
              [-70.642651572800872, -33.597914730577571],
              [-70.640058358736269, -33.601655871039647],
              [-70.642651572800872, -33.605396849200282],
              [-70.647838000930079, -33.605396849200282],
              [-70.650431214994683, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 199,
        left: -7864770.0271784086,
        bottom: -3974938.737456013,
        right: -7863615.3261016505,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.594173427822959],
              [-70.647838000930079, -33.590431962784734],
              [-70.642651572800872, -33.590431962784734],
              [-70.640058358736269, -33.594173427822959],
              [-70.642651572800872, -33.597914730577571],
              [-70.647838000930079, -33.597914730577571],
              [-70.650431214994683, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 200,
        left: -7864770.0271784086,
        bottom: -3973938.7369897626,
        right: -7863615.3261016505,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.586690335471808],
              [-70.647838000930079, -33.582948545893096],
              [-70.642651572800872, -33.582948545893096],
              [-70.640058358736269, -33.586690335471808],
              [-70.642651572800872, -33.590431962784734],
              [-70.647838000930079, -33.590431962784734],
              [-70.650431214994683, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 201,
        left: -7864770.0271784086,
        bottom: -3972938.7365235123,
        right: -7863615.3261016505,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.579206594057531],
              [-70.647838000930079, -33.575464479974038],
              [-70.642651572800872, -33.575464479974038],
              [-70.640058358736269, -33.579206594057531],
              [-70.642651572800872, -33.582948545893096],
              [-70.647838000930079, -33.582948545893096],
              [-70.650431214994683, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 202,
        left: -7864770.0271784086,
        bottom: -3971938.7360572619,
        right: -7863615.3261016505,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.571722203651518],
              [-70.647838000930079, -33.56797976509894],
              [-70.642651572800872, -33.56797976509894],
              [-70.640058358736269, -33.571722203651518],
              [-70.642651572800872, -33.575464479974038],
              [-70.647838000930079, -33.575464479974038],
              [-70.650431214994683, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 203,
        left: -7864770.0271784086,
        bottom: -3970938.7355910116,
        right: -7863615.3261016505,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.56423716432522],
              [-70.647838000930079, -33.560494401339298],
              [-70.642651572800872, -33.560494401339298],
              [-70.640058358736269, -33.56423716432522],
              [-70.642651572800872, -33.56797976509894],
              [-70.647838000930079, -33.56797976509894],
              [-70.650431214994683, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 204,
        left: -7864770.0271784086,
        bottom: -3969938.7351247617,
        right: -7863615.3261016505,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.556751476150104],
              [-70.647838000930079, -33.553008388766614],
              [-70.642651572800872, -33.553008388766614],
              [-70.640058358736269, -33.556751476150104],
              [-70.642651572800872, -33.560494401339298],
              [-70.647838000930079, -33.560494401339298],
              [-70.650431214994683, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 205,
        left: -7864770.0271784086,
        bottom: -3968938.7346585114,
        right: -7863615.3261016505,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.549265139197736],
              [-70.647838000930079, -33.54552172745246],
              [-70.642651572800872, -33.54552172745246],
              [-70.640058358736269, -33.549265139197736],
              [-70.642651572800872, -33.553008388766614],
              [-70.647838000930079, -33.553008388766614],
              [-70.650431214994683, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 206,
        left: -7864770.0271784086,
        bottom: -3967938.734192261,
        right: -7863615.3261016505,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.541778153539695],
              [-70.647838000930079, -33.538034417468438],
              [-70.642651572800872, -33.538034417468438],
              [-70.640058358736269, -33.541778153539695],
              [-70.642651572800872, -33.54552172745246],
              [-70.647838000930079, -33.54552172745246],
              [-70.650431214994683, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 207,
        left: -7864770.0271784086,
        bottom: -3966938.7337260107,
        right: -7863615.3261016505,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.534290519247627],
              [-70.647838000930079, -33.530546458886235],
              [-70.642651572800872, -33.530546458886235],
              [-70.640058358736269, -33.534290519247627],
              [-70.642651572800872, -33.538034417468438],
              [-70.647838000930079, -33.538034417468438],
              [-70.650431214994683, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 208,
        left: -7864770.0271784086,
        bottom: -3965938.7332597603,
        right: -7863615.3261016505,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.526802236393216],
              [-70.647838000930079, -33.523057851777558],
              [-70.642651572800872, -33.523057851777558],
              [-70.640058358736269, -33.526802236393216],
              [-70.642651572800872, -33.530546458886235],
              [-70.647838000930079, -33.530546458886235],
              [-70.650431214994683, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 209,
        left: -7864770.0271784086,
        bottom: -3964938.7327935095,
        right: -7863615.3261016505,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.519313305048215],
              [-70.647838000930079, -33.515568596214173],
              [-70.642651572800872, -33.515568596214173],
              [-70.640058358736269, -33.519313305048215],
              [-70.642651572800872, -33.523057851777558],
              [-70.647838000930079, -33.523057851777558],
              [-70.650431214994683, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 210,
        left: -7864770.0271784086,
        bottom: -3963938.7323272596,
        right: -7863615.3261016505,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.511823725284408],
              [-70.647838000930079, -33.508078692267915],
              [-70.642651572800872, -33.508078692267915],
              [-70.640058358736269, -33.511823725284408],
              [-70.642651572800872, -33.515568596214173],
              [-70.647838000930079, -33.515568596214173],
              [-70.650431214994683, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 211,
        left: -7864770.0271784086,
        bottom: -3962938.7318610097,
        right: -7863615.3261016505,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.504333497173661],
              [-70.647838000930079, -33.500588140010635],
              [-70.642651572800872, -33.500588140010635],
              [-70.640058358736269, -33.504333497173661],
              [-70.642651572800872, -33.508078692267915],
              [-70.647838000930079, -33.508078692267915],
              [-70.650431214994683, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 212,
        left: -7864770.0271784086,
        bottom: -3961938.7313947589,
        right: -7863615.3261016505,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.496842620787838],
              [-70.647838000930079, -33.493096939514267],
              [-70.642651572800872, -33.493096939514267],
              [-70.640058358736269, -33.496842620787838],
              [-70.642651572800872, -33.500588140010635],
              [-70.647838000930079, -33.500588140010635],
              [-70.650431214994683, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 213,
        left: -7864770.0271784086,
        bottom: -3960938.7309285086,
        right: -7863615.3261016505,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.489351096198902],
              [-70.647838000930079, -33.485605090850768],
              [-70.642651572800872, -33.485605090850768],
              [-70.640058358736269, -33.489351096198902],
              [-70.642651572800872, -33.493096939514267],
              [-70.647838000930079, -33.493096939514267],
              [-70.650431214994683, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 214,
        left: -7864770.0271784086,
        bottom: -3959938.7304622582,
        right: -7863615.3261016505,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.48185892347886],
              [-70.647838000930079, -33.478112594092174],
              [-70.642651572800872, -33.478112594092174],
              [-70.640058358736269, -33.48185892347886],
              [-70.642651572800872, -33.485605090850768],
              [-70.647838000930079, -33.485605090850768],
              [-70.650431214994683, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 215,
        left: -7864770.0271784086,
        bottom: -3958938.7299960079,
        right: -7863615.3261016505,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.474366102699733],
              [-70.647838000930079, -33.470619449310547],
              [-70.642651572800872, -33.470619449310547],
              [-70.640058358736269, -33.474366102699733],
              [-70.642651572800872, -33.478112594092174],
              [-70.647838000930079, -33.478112594092174],
              [-70.650431214994683, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 216,
        left: -7864770.0271784086,
        bottom: -3957938.7295297575,
        right: -7863615.3261016505,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.466872633933626],
              [-70.647838000930079, -33.463125656578008],
              [-70.642651572800872, -33.463125656578008],
              [-70.640058358736269, -33.466872633933626],
              [-70.642651572800872, -33.470619449310547],
              [-70.647838000930079, -33.470619449310547],
              [-70.650431214994683, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 217,
        left: -7864770.0271784086,
        bottom: -3956938.7290635072,
        right: -7863615.3261016505,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.459378517252702],
              [-70.647838000930079, -33.45563121596674],
              [-70.642651572800872, -33.45563121596674],
              [-70.640058358736269, -33.459378517252702],
              [-70.642651572800872, -33.463125656578008],
              [-70.647838000930079, -33.463125656578008],
              [-70.650431214994683, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 218,
        left: -7864770.0271784086,
        bottom: -3955938.7285972568,
        right: -7863615.3261016505,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.451883752729138],
              [-70.647838000930079, -33.448136127548949],
              [-70.642651572800872, -33.448136127548949],
              [-70.640058358736269, -33.451883752729138],
              [-70.642651572800872, -33.45563121596674],
              [-70.647838000930079, -33.45563121596674],
              [-70.650431214994683, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 219,
        left: -7864770.0271784086,
        bottom: -3954938.7281310065,
        right: -7863615.3261016505,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.444388340435196],
              [-70.647838000930079, -33.440640391396926],
              [-70.642651572800872, -33.440640391396926],
              [-70.640058358736269, -33.444388340435196],
              [-70.642651572800872, -33.448136127548949],
              [-70.647838000930079, -33.448136127548949],
              [-70.650431214994683, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 220,
        left: -7864770.0271784086,
        bottom: -3953938.7276647561,
        right: -7863615.3261016505,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.436892280443168],
              [-70.647838000930079, -33.433144007582989],
              [-70.642651572800872, -33.433144007582989],
              [-70.640058358736269, -33.436892280443168],
              [-70.642651572800872, -33.440640391396926],
              [-70.647838000930079, -33.440640391396926],
              [-70.650431214994683, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 221,
        left: -7864770.0271784086,
        bottom: -3952938.7271985058,
        right: -7863615.3261016505,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.429395572825413],
              [-70.647838000930079, -33.425646976179515],
              [-70.642651572800872, -33.425646976179515],
              [-70.640058358736269, -33.429395572825413],
              [-70.642651572800872, -33.433144007582989],
              [-70.647838000930079, -33.433144007582989],
              [-70.650431214994683, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 222,
        left: -7864770.0271784086,
        bottom: -3951938.7267322554,
        right: -7863615.3261016505,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.421898217654316],
              [-70.647838000930079, -33.418149297258921],
              [-70.642651572800872, -33.418149297258921],
              [-70.640058358736269, -33.421898217654316],
              [-70.642651572800872, -33.425646976179515],
              [-70.647838000930079, -33.425646976179515],
              [-70.650431214994683, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 223,
        left: -7864770.0271784086,
        bottom: -3950938.7262660051,
        right: -7863615.3261016505,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.414400215002352],
              [-70.647838000930079, -33.410650970893691],
              [-70.642651572800872, -33.410650970893691],
              [-70.640058358736269, -33.414400215002352],
              [-70.642651572800872, -33.418149297258921],
              [-70.647838000930079, -33.418149297258921],
              [-70.650431214994683, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 224,
        left: -7864770.0271784086,
        bottom: -3949938.7257997552,
        right: -7863615.3261016505,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.406901564941997],
              [-70.647838000930079, -33.40315199715635],
              [-70.642651572800872, -33.40315199715635],
              [-70.640058358736269, -33.406901564941997],
              [-70.642651572800872, -33.410650970893691],
              [-70.647838000930079, -33.410650970893691],
              [-70.650431214994683, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 225,
        left: -7864770.0271784086,
        bottom: -3948938.7253335048,
        right: -7863615.3261016505,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.399402267545824],
              [-70.647838000930079, -33.395652376119486],
              [-70.642651572800872, -33.395652376119486],
              [-70.640058358736269, -33.399402267545824],
              [-70.642651572800872, -33.40315199715635],
              [-70.647838000930079, -33.40315199715635],
              [-70.650431214994683, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 226,
        left: -7864770.0271784086,
        bottom: -3947938.724867254,
        right: -7863615.3261016505,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.391902322886416],
              [-70.647838000930079, -33.388152107855717],
              [-70.642651572800872, -33.388152107855717],
              [-70.640058358736269, -33.391902322886416],
              [-70.642651572800872, -33.395652376119486],
              [-70.647838000930079, -33.395652376119486],
              [-70.650431214994683, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 227,
        left: -7864770.0271784086,
        bottom: -3946938.7244010041,
        right: -7863615.3261016505,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.384401731036441],
              [-70.647838000930079, -33.380651192437711],
              [-70.642651572800872, -33.380651192437711],
              [-70.640058358736269, -33.384401731036441],
              [-70.642651572800872, -33.388152107855717],
              [-70.647838000930079, -33.388152107855717],
              [-70.650431214994683, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 228,
        left: -7864770.0271784086,
        bottom: -3945938.7239347543,
        right: -7863615.3261016505,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.3769004920686],
              [-70.647838000930079, -33.373149629938226],
              [-70.642651572800872, -33.373149629938226],
              [-70.640058358736269, -33.3769004920686],
              [-70.642651572800872, -33.380651192437711],
              [-70.647838000930079, -33.380651192437711],
              [-70.650431214994683, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 229,
        left: -7864770.0271784086,
        bottom: -3944938.7234685034,
        right: -7863615.3261016505,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.369398606055647],
              [-70.647838000930079, -33.365647420430008],
              [-70.642651572800872, -33.365647420430008],
              [-70.640058358736269, -33.369398606055647],
              [-70.642651572800872, -33.373149629938226],
              [-70.647838000930079, -33.373149629938226],
              [-70.650431214994683, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 230,
        left: -7864770.0271784086,
        bottom: -3943938.7230022531,
        right: -7863615.3261016505,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.36189607307039],
              [-70.647838000930079, -33.358144563985917],
              [-70.642651572800872, -33.358144563985917],
              [-70.640058358736269, -33.36189607307039],
              [-70.642651572800872, -33.365647420430008],
              [-70.647838000930079, -33.365647420430008],
              [-70.650431214994683, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 231,
        left: -7864770.0271784086,
        bottom: -3942938.7225360028,
        right: -7863615.3261016505,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.35439289318569],
              [-70.647838000930079, -33.350641060678818],
              [-70.642651572800872, -33.350641060678818],
              [-70.640058358736269, -33.35439289318569],
              [-70.642651572800872, -33.358144563985917],
              [-70.647838000930079, -33.358144563985917],
              [-70.650431214994683, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 232,
        left: -7864770.0271784086,
        bottom: -3941938.7220697524,
        right: -7863615.3261016505,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.346889066474425],
              [-70.647838000930079, -33.343136910581649],
              [-70.642651572800872, -33.343136910581649],
              [-70.640058358736269, -33.346889066474425],
              [-70.642651572800872, -33.350641060678818],
              [-70.647838000930079, -33.350641060678818],
              [-70.650431214994683, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 233,
        left: -7864770.0271784086,
        bottom: -3940938.7216035021,
        right: -7863615.3261016505,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.339384593009591],
              [-70.647838000930079, -33.335632113767389],
              [-70.642651572800872, -33.335632113767389],
              [-70.640058358736269, -33.339384593009591],
              [-70.642651572800872, -33.343136910581649],
              [-70.647838000930079, -33.343136910581649],
              [-70.650431214994683, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 234,
        left: -7864770.0271784086,
        bottom: -3939938.7211372517,
        right: -7863615.3261016505,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.331879472864181],
              [-70.647838000930079, -33.328126670309082],
              [-70.642651572800872, -33.328126670309082],
              [-70.640058358736269, -33.331879472864181],
              [-70.642651572800872, -33.335632113767389],
              [-70.647838000930079, -33.335632113767389],
              [-70.650431214994683, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 235,
        left: -7864770.0271784086,
        bottom: -3938938.7206710014,
        right: -7863615.3261016505,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.324373706111238],
              [-70.647838000930079, -33.320620580279794],
              [-70.642651572800872, -33.320620580279794],
              [-70.640058358736269, -33.324373706111238],
              [-70.642651572800872, -33.328126670309082],
              [-70.647838000930079, -33.328126670309082],
              [-70.650431214994683, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 236,
        left: -7864770.0271784086,
        bottom: -3937938.720204751,
        right: -7863615.3261016505,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.3168672928239],
              [-70.647838000930079, -33.313113843752674],
              [-70.642651572800872, -33.313113843752674],
              [-70.640058358736269, -33.3168672928239],
              [-70.642651572800872, -33.320620580279794],
              [-70.647838000930079, -33.320620580279794],
              [-70.650431214994683, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 237,
        left: -7864770.0271784086,
        bottom: -3936938.7197385007,
        right: -7863615.3261016505,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.309360233075296],
              [-70.647838000930079, -33.305606460800909],
              [-70.642651572800872, -33.305606460800909],
              [-70.640058358736269, -33.309360233075296],
              [-70.642651572800872, -33.313113843752674],
              [-70.647838000930079, -33.313113843752674],
              [-70.650431214994683, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 238,
        left: -7864770.0271784086,
        bottom: -3935938.7192722503,
        right: -7863615.3261016505,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.30185252693866],
              [-70.647838000930079, -33.298098431497721],
              [-70.642651572800872, -33.298098431497721],
              [-70.640058358736269, -33.30185252693866],
              [-70.642651572800872, -33.305606460800909],
              [-70.647838000930079, -33.305606460800909],
              [-70.650431214994683, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 239,
        left: -7864770.0271784086,
        bottom: -3934938.718806,
        right: -7863615.3261016505,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.294344174487243],
              [-70.647838000930079, -33.290589755916407],
              [-70.642651572800872, -33.290589755916407],
              [-70.640058358736269, -33.294344174487243],
              [-70.642651572800872, -33.298098431497721],
              [-70.647838000930079, -33.298098431497721],
              [-70.650431214994683, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 240,
        left: -7864770.0271784086,
        bottom: -3933938.7183397496,
        right: -7863615.3261016505,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.650431214994683, -33.286835175794366],
              [-70.647838000930079, -33.283080434130305],
              [-70.642651572800872, -33.283080434130305],
              [-70.640058358736269, -33.286835175794366],
              [-70.642651572800872, -33.290589755916407],
              [-70.647838000930079, -33.290589755916407],
              [-70.650431214994683, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 241,
        left: -7863904.0013708398,
        bottom: -3980438.7400203901,
        right: -7862749.3002940807,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.63531883056551],
              [-70.640058358736269, -33.631579151136748],
              [-70.634871930607048, -33.631579151136748],
              [-70.632278716542459, -33.63531883056551],
              [-70.634871930607048, -33.639058347612803],
              [-70.640058358736269, -33.639058347612803],
              [-70.642651572800872, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 242,
        left: -7863904.0013708398,
        bottom: -3979438.7395541398,
        right: -7862749.3002940807,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.627839309335386],
              [-70.640058358736269, -33.624099305170297],
              [-70.634871930607048, -33.624099305170297],
              [-70.632278716542459, -33.627839309335386],
              [-70.634871930607048, -33.631579151136748],
              [-70.640058358736269, -33.631579151136748],
              [-70.642651572800872, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 243,
        left: -7863904.0013708398,
        bottom: -3978438.7390878894,
        right: -7862749.3002940807,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.620359138650379],
              [-70.640058358736269, -33.616618809784534],
              [-70.634871930607048, -33.616618809784534],
              [-70.632278716542459, -33.620359138650379],
              [-70.634871930607048, -33.624099305170297],
              [-70.640058358736269, -33.624099305170297],
              [-70.642651572800872, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 244,
        left: -7863904.0013708398,
        bottom: -3977438.7386216391,
        right: -7862749.3002940807,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.612878318581629],
              [-70.640058358736269, -33.609137665050582],
              [-70.634871930607048, -33.609137665050582],
              [-70.632278716542459, -33.612878318581629],
              [-70.634871930607048, -33.616618809784534],
              [-70.640058358736269, -33.616618809784534],
              [-70.642651572800872, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 245,
        left: -7863904.0013708398,
        bottom: -3976438.7381553887,
        right: -7862749.3002940807,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.605396849200282],
              [-70.640058358736269, -33.601655871039647],
              [-70.634871930607048, -33.601655871039647],
              [-70.632278716542459, -33.605396849200282],
              [-70.634871930607048, -33.609137665050582],
              [-70.640058358736269, -33.609137665050582],
              [-70.642651572800872, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 246,
        left: -7863904.0013708398,
        bottom: -3975438.7376891384,
        right: -7862749.3002940807,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.597914730577571],
              [-70.640058358736269, -33.594173427822959],
              [-70.634871930607048, -33.594173427822959],
              [-70.632278716542459, -33.597914730577571],
              [-70.634871930607048, -33.601655871039647],
              [-70.640058358736269, -33.601655871039647],
              [-70.642651572800872, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 247,
        left: -7863904.0013708398,
        bottom: -3974438.7372228876,
        right: -7862749.3002940807,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.590431962784734],
              [-70.640058358736269, -33.586690335471808],
              [-70.634871930607048, -33.586690335471808],
              [-70.632278716542459, -33.590431962784734],
              [-70.634871930607048, -33.594173427822959],
              [-70.640058358736269, -33.594173427822959],
              [-70.642651572800872, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 248,
        left: -7863904.0013708398,
        bottom: -3973438.7367566377,
        right: -7862749.3002940807,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.582948545893096],
              [-70.640058358736269, -33.579206594057531],
              [-70.634871930607048, -33.579206594057531],
              [-70.632278716542459, -33.582948545893096],
              [-70.634871930607048, -33.586690335471808],
              [-70.640058358736269, -33.586690335471808],
              [-70.642651572800872, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 249,
        left: -7863904.0013708398,
        bottom: -3972438.7362903878,
        right: -7862749.3002940807,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.575464479974038],
              [-70.640058358736269, -33.571722203651518],
              [-70.634871930607048, -33.571722203651518],
              [-70.632278716542459, -33.575464479974038],
              [-70.634871930607048, -33.579206594057531],
              [-70.640058358736269, -33.579206594057531],
              [-70.642651572800872, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 250,
        left: -7863904.0013708398,
        bottom: -3971438.735824137,
        right: -7862749.3002940807,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.56797976509894],
              [-70.640058358736269, -33.56423716432522],
              [-70.634871930607048, -33.56423716432522],
              [-70.632278716542459, -33.56797976509894],
              [-70.634871930607048, -33.571722203651518],
              [-70.640058358736269, -33.571722203651518],
              [-70.642651572800872, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 251,
        left: -7863904.0013708398,
        bottom: -3970438.7353578866,
        right: -7862749.3002940807,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.560494401339298],
              [-70.640058358736269, -33.556751476150104],
              [-70.634871930607048, -33.556751476150104],
              [-70.632278716542459, -33.560494401339298],
              [-70.634871930607048, -33.56423716432522],
              [-70.640058358736269, -33.56423716432522],
              [-70.642651572800872, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 252,
        left: -7863904.0013708398,
        bottom: -3969438.7348916363,
        right: -7862749.3002940807,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.553008388766614],
              [-70.640058358736269, -33.549265139197736],
              [-70.634871930607048, -33.549265139197736],
              [-70.632278716542459, -33.553008388766614],
              [-70.634871930607048, -33.556751476150104],
              [-70.640058358736269, -33.556751476150104],
              [-70.642651572800872, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 253,
        left: -7863904.0013708398,
        bottom: -3968438.7344253859,
        right: -7862749.3002940807,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.54552172745246],
              [-70.640058358736269, -33.541778153539695],
              [-70.634871930607048, -33.541778153539695],
              [-70.632278716542459, -33.54552172745246],
              [-70.634871930607048, -33.549265139197736],
              [-70.640058358736269, -33.549265139197736],
              [-70.642651572800872, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 254,
        left: -7863904.0013708398,
        bottom: -3967438.7339591356,
        right: -7862749.3002940807,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.538034417468438],
              [-70.640058358736269, -33.534290519247627],
              [-70.634871930607048, -33.534290519247627],
              [-70.632278716542459, -33.538034417468438],
              [-70.634871930607048, -33.541778153539695],
              [-70.640058358736269, -33.541778153539695],
              [-70.642651572800872, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 255,
        left: -7863904.0013708398,
        bottom: -3966438.7334928853,
        right: -7862749.3002940807,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.530546458886235],
              [-70.640058358736269, -33.526802236393216],
              [-70.634871930607048, -33.526802236393216],
              [-70.632278716542459, -33.530546458886235],
              [-70.634871930607048, -33.534290519247627],
              [-70.640058358736269, -33.534290519247627],
              [-70.642651572800872, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 256,
        left: -7863904.0013708398,
        bottom: -3965438.7330266349,
        right: -7862749.3002940807,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.523057851777558],
              [-70.640058358736269, -33.519313305048215],
              [-70.634871930607048, -33.519313305048215],
              [-70.632278716542459, -33.523057851777558],
              [-70.634871930607048, -33.526802236393216],
              [-70.640058358736269, -33.526802236393216],
              [-70.642651572800872, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 257,
        left: -7863904.0013708398,
        bottom: -3964438.7325603846,
        right: -7862749.3002940807,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.515568596214173],
              [-70.640058358736269, -33.511823725284408],
              [-70.634871930607048, -33.511823725284408],
              [-70.632278716542459, -33.515568596214173],
              [-70.634871930607048, -33.519313305048215],
              [-70.640058358736269, -33.519313305048215],
              [-70.642651572800872, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 258,
        left: -7863904.0013708398,
        bottom: -3963438.7320941342,
        right: -7862749.3002940807,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.508078692267915],
              [-70.640058358736269, -33.504333497173661],
              [-70.634871930607048, -33.504333497173661],
              [-70.632278716542459, -33.508078692267915],
              [-70.634871930607048, -33.511823725284408],
              [-70.640058358736269, -33.511823725284408],
              [-70.642651572800872, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 259,
        left: -7863904.0013708398,
        bottom: -3962438.7316278839,
        right: -7862749.3002940807,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.500588140010635],
              [-70.640058358736269, -33.496842620787838],
              [-70.634871930607048, -33.496842620787838],
              [-70.632278716542459, -33.500588140010635],
              [-70.634871930607048, -33.504333497173661],
              [-70.640058358736269, -33.504333497173661],
              [-70.642651572800872, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 260,
        left: -7863904.0013708398,
        bottom: -3961438.7311616335,
        right: -7862749.3002940807,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.493096939514267],
              [-70.640058358736269, -33.489351096198902],
              [-70.634871930607048, -33.489351096198902],
              [-70.632278716542459, -33.493096939514267],
              [-70.634871930607048, -33.496842620787838],
              [-70.640058358736269, -33.496842620787838],
              [-70.642651572800872, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 261,
        left: -7863904.0013708398,
        bottom: -3960438.7306953832,
        right: -7862749.3002940807,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.485605090850768],
              [-70.640058358736269, -33.48185892347886],
              [-70.634871930607048, -33.48185892347886],
              [-70.632278716542459, -33.485605090850768],
              [-70.634871930607048, -33.489351096198902],
              [-70.640058358736269, -33.489351096198902],
              [-70.642651572800872, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 262,
        left: -7863904.0013708398,
        bottom: -3959438.7302291333,
        right: -7862749.3002940807,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.478112594092174],
              [-70.640058358736269, -33.474366102699733],
              [-70.634871930607048, -33.474366102699733],
              [-70.632278716542459, -33.478112594092174],
              [-70.634871930607048, -33.48185892347886],
              [-70.640058358736269, -33.48185892347886],
              [-70.642651572800872, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 263,
        left: -7863904.0013708398,
        bottom: -3958438.7297628829,
        right: -7862749.3002940807,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.470619449310547],
              [-70.640058358736269, -33.466872633933626],
              [-70.634871930607048, -33.466872633933626],
              [-70.632278716542459, -33.470619449310547],
              [-70.634871930607048, -33.474366102699733],
              [-70.640058358736269, -33.474366102699733],
              [-70.642651572800872, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 264,
        left: -7863904.0013708398,
        bottom: -3957438.7292966326,
        right: -7862749.3002940807,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.463125656578008],
              [-70.640058358736269, -33.459378517252702],
              [-70.634871930607048, -33.459378517252702],
              [-70.632278716542459, -33.463125656578008],
              [-70.634871930607048, -33.466872633933626],
              [-70.640058358736269, -33.466872633933626],
              [-70.642651572800872, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 265,
        left: -7863904.0013708398,
        bottom: -3956438.7288303822,
        right: -7862749.3002940807,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.45563121596674],
              [-70.640058358736269, -33.451883752729138],
              [-70.634871930607048, -33.451883752729138],
              [-70.632278716542459, -33.45563121596674],
              [-70.634871930607048, -33.459378517252702],
              [-70.640058358736269, -33.459378517252702],
              [-70.642651572800872, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 266,
        left: -7863904.0013708398,
        bottom: -3955438.7283641323,
        right: -7862749.3002940807,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.448136127548949],
              [-70.640058358736269, -33.444388340435196],
              [-70.634871930607048, -33.444388340435196],
              [-70.632278716542459, -33.448136127548949],
              [-70.634871930607048, -33.451883752729138],
              [-70.640058358736269, -33.451883752729138],
              [-70.642651572800872, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 267,
        left: -7863904.0013708398,
        bottom: -3954438.7278978815,
        right: -7862749.3002940807,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.440640391396926],
              [-70.640058358736269, -33.436892280443168],
              [-70.634871930607048, -33.436892280443168],
              [-70.632278716542459, -33.440640391396926],
              [-70.634871930607048, -33.444388340435196],
              [-70.640058358736269, -33.444388340435196],
              [-70.642651572800872, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 268,
        left: -7863904.0013708398,
        bottom: -3953438.7274316312,
        right: -7862749.3002940807,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.433144007582989],
              [-70.640058358736269, -33.429395572825413],
              [-70.634871930607048, -33.429395572825413],
              [-70.632278716542459, -33.433144007582989],
              [-70.634871930607048, -33.436892280443168],
              [-70.640058358736269, -33.436892280443168],
              [-70.642651572800872, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 269,
        left: -7863904.0013708398,
        bottom: -3952438.7269653808,
        right: -7862749.3002940807,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.425646976179515],
              [-70.640058358736269, -33.421898217654316],
              [-70.634871930607048, -33.421898217654316],
              [-70.632278716542459, -33.425646976179515],
              [-70.634871930607048, -33.429395572825413],
              [-70.640058358736269, -33.429395572825413],
              [-70.642651572800872, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 270,
        left: -7863904.0013708398,
        bottom: -3951438.7264991305,
        right: -7862749.3002940807,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.418149297258921],
              [-70.640058358736269, -33.414400215002352],
              [-70.634871930607048, -33.414400215002352],
              [-70.632278716542459, -33.418149297258921],
              [-70.634871930607048, -33.421898217654316],
              [-70.640058358736269, -33.421898217654316],
              [-70.642651572800872, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 271,
        left: -7863904.0013708398,
        bottom: -3950438.7260328801,
        right: -7862749.3002940807,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.410650970893691],
              [-70.640058358736269, -33.406901564941997],
              [-70.634871930607048, -33.406901564941997],
              [-70.632278716542459, -33.410650970893691],
              [-70.634871930607048, -33.414400215002352],
              [-70.640058358736269, -33.414400215002352],
              [-70.642651572800872, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 272,
        left: -7863904.0013708398,
        bottom: -3949438.7255666298,
        right: -7862749.3002940807,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.40315199715635],
              [-70.640058358736269, -33.399402267545824],
              [-70.634871930607048, -33.399402267545824],
              [-70.632278716542459, -33.40315199715635],
              [-70.634871930607048, -33.406901564941997],
              [-70.640058358736269, -33.406901564941997],
              [-70.642651572800872, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 273,
        left: -7863904.0013708398,
        bottom: -3948438.7251003794,
        right: -7862749.3002940807,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.395652376119486],
              [-70.640058358736269, -33.391902322886416],
              [-70.634871930607048, -33.391902322886416],
              [-70.632278716542459, -33.395652376119486],
              [-70.634871930607048, -33.399402267545824],
              [-70.640058358736269, -33.399402267545824],
              [-70.642651572800872, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 274,
        left: -7863904.0013708398,
        bottom: -3947438.7246341291,
        right: -7862749.3002940807,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.388152107855717],
              [-70.640058358736269, -33.384401731036441],
              [-70.634871930607048, -33.384401731036441],
              [-70.632278716542459, -33.388152107855717],
              [-70.634871930607048, -33.391902322886416],
              [-70.640058358736269, -33.391902322886416],
              [-70.642651572800872, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 275,
        left: -7863904.0013708398,
        bottom: -3946438.7241678787,
        right: -7862749.3002940807,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.380651192437711],
              [-70.640058358736269, -33.3769004920686],
              [-70.634871930607048, -33.3769004920686],
              [-70.632278716542459, -33.380651192437711],
              [-70.634871930607048, -33.384401731036441],
              [-70.640058358736269, -33.384401731036441],
              [-70.642651572800872, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 276,
        left: -7863904.0013708398,
        bottom: -3945438.7237016284,
        right: -7862749.3002940807,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.373149629938226],
              [-70.640058358736269, -33.369398606055647],
              [-70.634871930607048, -33.369398606055647],
              [-70.632278716542459, -33.373149629938226],
              [-70.634871930607048, -33.3769004920686],
              [-70.640058358736269, -33.3769004920686],
              [-70.642651572800872, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 277,
        left: -7863904.0013708398,
        bottom: -3944438.723235378,
        right: -7862749.3002940807,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.365647420430008],
              [-70.640058358736269, -33.36189607307039],
              [-70.634871930607048, -33.36189607307039],
              [-70.632278716542459, -33.365647420430008],
              [-70.634871930607048, -33.369398606055647],
              [-70.640058358736269, -33.369398606055647],
              [-70.642651572800872, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 278,
        left: -7863904.0013708398,
        bottom: -3943438.7227691277,
        right: -7862749.3002940807,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.358144563985917],
              [-70.640058358736269, -33.35439289318569],
              [-70.634871930607048, -33.35439289318569],
              [-70.632278716542459, -33.358144563985917],
              [-70.634871930607048, -33.36189607307039],
              [-70.640058358736269, -33.36189607307039],
              [-70.642651572800872, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 279,
        left: -7863904.0013708398,
        bottom: -3942438.7223028769,
        right: -7862749.3002940807,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.350641060678818],
              [-70.640058358736269, -33.346889066474425],
              [-70.634871930607048, -33.346889066474425],
              [-70.632278716542459, -33.350641060678818],
              [-70.634871930607048, -33.35439289318569],
              [-70.640058358736269, -33.35439289318569],
              [-70.642651572800872, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 280,
        left: -7863904.0013708398,
        bottom: -3941438.721836627,
        right: -7862749.3002940807,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.343136910581649],
              [-70.640058358736269, -33.339384593009591],
              [-70.634871930607048, -33.339384593009591],
              [-70.632278716542459, -33.343136910581649],
              [-70.634871930607048, -33.346889066474425],
              [-70.640058358736269, -33.346889066474425],
              [-70.642651572800872, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 281,
        left: -7863904.0013708398,
        bottom: -3940438.7213703771,
        right: -7862749.3002940807,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.335632113767389],
              [-70.640058358736269, -33.331879472864181],
              [-70.634871930607048, -33.331879472864181],
              [-70.632278716542459, -33.335632113767389],
              [-70.634871930607048, -33.339384593009591],
              [-70.640058358736269, -33.339384593009591],
              [-70.642651572800872, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 282,
        left: -7863904.0013708398,
        bottom: -3939438.7209041263,
        right: -7862749.3002940807,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.328126670309082],
              [-70.640058358736269, -33.324373706111238],
              [-70.634871930607048, -33.324373706111238],
              [-70.632278716542459, -33.328126670309082],
              [-70.634871930607048, -33.331879472864181],
              [-70.640058358736269, -33.331879472864181],
              [-70.642651572800872, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 283,
        left: -7863904.0013708398,
        bottom: -3938438.7204378759,
        right: -7862749.3002940807,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.320620580279794],
              [-70.640058358736269, -33.3168672928239],
              [-70.634871930607048, -33.3168672928239],
              [-70.632278716542459, -33.320620580279794],
              [-70.634871930607048, -33.324373706111238],
              [-70.640058358736269, -33.324373706111238],
              [-70.642651572800872, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 284,
        left: -7863904.0013708398,
        bottom: -3937438.7199716261,
        right: -7862749.3002940807,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.313113843752674],
              [-70.640058358736269, -33.309360233075296],
              [-70.634871930607048, -33.309360233075296],
              [-70.632278716542459, -33.313113843752674],
              [-70.634871930607048, -33.3168672928239],
              [-70.640058358736269, -33.3168672928239],
              [-70.642651572800872, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 285,
        left: -7863904.0013708398,
        bottom: -3936438.7195053762,
        right: -7862749.3002940807,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.305606460800909],
              [-70.640058358736269, -33.30185252693866],
              [-70.634871930607048, -33.30185252693866],
              [-70.632278716542459, -33.305606460800909],
              [-70.634871930607048, -33.309360233075296],
              [-70.640058358736269, -33.309360233075296],
              [-70.642651572800872, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 286,
        left: -7863904.0013708398,
        bottom: -3935438.7190391254,
        right: -7862749.3002940807,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.298098431497721],
              [-70.640058358736269, -33.294344174487243],
              [-70.634871930607048, -33.294344174487243],
              [-70.632278716542459, -33.298098431497721],
              [-70.634871930607048, -33.30185252693866],
              [-70.640058358736269, -33.30185252693866],
              [-70.642651572800872, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 287,
        left: -7863904.0013708398,
        bottom: -3934438.718572875,
        right: -7862749.3002940807,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.290589755916407],
              [-70.640058358736269, -33.286835175794366],
              [-70.634871930607048, -33.286835175794366],
              [-70.632278716542459, -33.290589755916407],
              [-70.634871930607048, -33.294344174487243],
              [-70.640058358736269, -33.294344174487243],
              [-70.642651572800872, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 288,
        left: -7863904.0013708398,
        bottom: -3933438.7181066247,
        right: -7862749.3002940807,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.642651572800872, -33.283080434130305],
              [-70.640058358736269, -33.279325530933384],
              [-70.634871930607048, -33.279325530933384],
              [-70.632278716542459, -33.283080434130305],
              [-70.634871930607048, -33.286835175794366],
              [-70.640058358736269, -33.286835175794366],
              [-70.642651572800872, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 289,
        left: -7863037.9755632691,
        bottom: -3980938.7402535151,
        right: -7861883.2744865119,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.639058347612803],
              [-70.632278716542459, -33.63531883056551],
              [-70.627092288413237, -33.63531883056551],
              [-70.624499074348648, -33.639058347612803],
              [-70.627092288413237, -33.642797702269725],
              [-70.632278716542459, -33.642797702269725],
              [-70.634871930607048, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 290,
        left: -7863037.9755632691,
        bottom: -3979938.7397872647,
        right: -7861883.2744865119,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.631579151136748],
              [-70.632278716542459, -33.627839309335386],
              [-70.627092288413237, -33.627839309335386],
              [-70.624499074348648, -33.631579151136748],
              [-70.627092288413237, -33.63531883056551],
              [-70.632278716542459, -33.63531883056551],
              [-70.634871930607048, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 291,
        left: -7863037.9755632691,
        bottom: -3978938.7393210144,
        right: -7861883.2744865119,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.624099305170297],
              [-70.632278716542459, -33.620359138650379],
              [-70.627092288413237, -33.620359138650379],
              [-70.624499074348648, -33.624099305170297],
              [-70.627092288413237, -33.627839309335386],
              [-70.632278716542459, -33.627839309335386],
              [-70.634871930607048, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 292,
        left: -7863037.9755632691,
        bottom: -3977938.738854764,
        right: -7861883.2744865119,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.616618809784534],
              [-70.632278716542459, -33.612878318581629],
              [-70.627092288413237, -33.612878318581629],
              [-70.624499074348648, -33.616618809784534],
              [-70.627092288413237, -33.620359138650379],
              [-70.632278716542459, -33.620359138650379],
              [-70.634871930607048, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 293,
        left: -7863037.9755632691,
        bottom: -3976938.7383885137,
        right: -7861883.2744865119,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.609137665050582],
              [-70.632278716542459, -33.605396849200282],
              [-70.627092288413237, -33.605396849200282],
              [-70.624499074348648, -33.609137665050582],
              [-70.627092288413237, -33.612878318581629],
              [-70.632278716542459, -33.612878318581629],
              [-70.634871930607048, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 294,
        left: -7863037.9755632691,
        bottom: -3975938.7379222633,
        right: -7861883.2744865119,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.601655871039647],
              [-70.632278716542459, -33.597914730577571],
              [-70.627092288413237, -33.597914730577571],
              [-70.624499074348648, -33.601655871039647],
              [-70.627092288413237, -33.605396849200282],
              [-70.632278716542459, -33.605396849200282],
              [-70.634871930607048, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 295,
        left: -7863037.9755632691,
        bottom: -3974938.737456013,
        right: -7861883.2744865119,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.594173427822959],
              [-70.632278716542459, -33.590431962784734],
              [-70.627092288413237, -33.590431962784734],
              [-70.624499074348648, -33.594173427822959],
              [-70.627092288413237, -33.597914730577571],
              [-70.632278716542459, -33.597914730577571],
              [-70.634871930607048, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 296,
        left: -7863037.9755632691,
        bottom: -3973938.7369897626,
        right: -7861883.2744865119,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.586690335471808],
              [-70.632278716542459, -33.582948545893096],
              [-70.627092288413237, -33.582948545893096],
              [-70.624499074348648, -33.586690335471808],
              [-70.627092288413237, -33.590431962784734],
              [-70.632278716542459, -33.590431962784734],
              [-70.634871930607048, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 297,
        left: -7863037.9755632691,
        bottom: -3972938.7365235123,
        right: -7861883.2744865119,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.579206594057531],
              [-70.632278716542459, -33.575464479974038],
              [-70.627092288413237, -33.575464479974038],
              [-70.624499074348648, -33.579206594057531],
              [-70.627092288413237, -33.582948545893096],
              [-70.632278716542459, -33.582948545893096],
              [-70.634871930607048, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 298,
        left: -7863037.9755632691,
        bottom: -3971938.7360572619,
        right: -7861883.2744865119,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.571722203651518],
              [-70.632278716542459, -33.56797976509894],
              [-70.627092288413237, -33.56797976509894],
              [-70.624499074348648, -33.571722203651518],
              [-70.627092288413237, -33.575464479974038],
              [-70.632278716542459, -33.575464479974038],
              [-70.634871930607048, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 299,
        left: -7863037.9755632691,
        bottom: -3970938.7355910116,
        right: -7861883.2744865119,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.56423716432522],
              [-70.632278716542459, -33.560494401339298],
              [-70.627092288413237, -33.560494401339298],
              [-70.624499074348648, -33.56423716432522],
              [-70.627092288413237, -33.56797976509894],
              [-70.632278716542459, -33.56797976509894],
              [-70.634871930607048, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 300,
        left: -7863037.9755632691,
        bottom: -3969938.7351247617,
        right: -7861883.2744865119,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.556751476150104],
              [-70.632278716542459, -33.553008388766614],
              [-70.627092288413237, -33.553008388766614],
              [-70.624499074348648, -33.556751476150104],
              [-70.627092288413237, -33.560494401339298],
              [-70.632278716542459, -33.560494401339298],
              [-70.634871930607048, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 301,
        left: -7863037.9755632691,
        bottom: -3968938.7346585114,
        right: -7861883.2744865119,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.549265139197736],
              [-70.632278716542459, -33.54552172745246],
              [-70.627092288413237, -33.54552172745246],
              [-70.624499074348648, -33.549265139197736],
              [-70.627092288413237, -33.553008388766614],
              [-70.632278716542459, -33.553008388766614],
              [-70.634871930607048, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 302,
        left: -7863037.9755632691,
        bottom: -3967938.734192261,
        right: -7861883.2744865119,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.541778153539695],
              [-70.632278716542459, -33.538034417468438],
              [-70.627092288413237, -33.538034417468438],
              [-70.624499074348648, -33.541778153539695],
              [-70.627092288413237, -33.54552172745246],
              [-70.632278716542459, -33.54552172745246],
              [-70.634871930607048, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 303,
        left: -7863037.9755632691,
        bottom: -3966938.7337260107,
        right: -7861883.2744865119,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.534290519247627],
              [-70.632278716542459, -33.530546458886235],
              [-70.627092288413237, -33.530546458886235],
              [-70.624499074348648, -33.534290519247627],
              [-70.627092288413237, -33.538034417468438],
              [-70.632278716542459, -33.538034417468438],
              [-70.634871930607048, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 304,
        left: -7863037.9755632691,
        bottom: -3965938.7332597603,
        right: -7861883.2744865119,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.526802236393216],
              [-70.632278716542459, -33.523057851777558],
              [-70.627092288413237, -33.523057851777558],
              [-70.624499074348648, -33.526802236393216],
              [-70.627092288413237, -33.530546458886235],
              [-70.632278716542459, -33.530546458886235],
              [-70.634871930607048, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 305,
        left: -7863037.9755632691,
        bottom: -3964938.7327935095,
        right: -7861883.2744865119,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.519313305048215],
              [-70.632278716542459, -33.515568596214173],
              [-70.627092288413237, -33.515568596214173],
              [-70.624499074348648, -33.519313305048215],
              [-70.627092288413237, -33.523057851777558],
              [-70.632278716542459, -33.523057851777558],
              [-70.634871930607048, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 306,
        left: -7863037.9755632691,
        bottom: -3963938.7323272596,
        right: -7861883.2744865119,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.511823725284408],
              [-70.632278716542459, -33.508078692267915],
              [-70.627092288413237, -33.508078692267915],
              [-70.624499074348648, -33.511823725284408],
              [-70.627092288413237, -33.515568596214173],
              [-70.632278716542459, -33.515568596214173],
              [-70.634871930607048, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 307,
        left: -7863037.9755632691,
        bottom: -3962938.7318610097,
        right: -7861883.2744865119,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.504333497173661],
              [-70.632278716542459, -33.500588140010635],
              [-70.627092288413237, -33.500588140010635],
              [-70.624499074348648, -33.504333497173661],
              [-70.627092288413237, -33.508078692267915],
              [-70.632278716542459, -33.508078692267915],
              [-70.634871930607048, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 308,
        left: -7863037.9755632691,
        bottom: -3961938.7313947589,
        right: -7861883.2744865119,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.496842620787838],
              [-70.632278716542459, -33.493096939514267],
              [-70.627092288413237, -33.493096939514267],
              [-70.624499074348648, -33.496842620787838],
              [-70.627092288413237, -33.500588140010635],
              [-70.632278716542459, -33.500588140010635],
              [-70.634871930607048, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 309,
        left: -7863037.9755632691,
        bottom: -3960938.7309285086,
        right: -7861883.2744865119,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.489351096198902],
              [-70.632278716542459, -33.485605090850768],
              [-70.627092288413237, -33.485605090850768],
              [-70.624499074348648, -33.489351096198902],
              [-70.627092288413237, -33.493096939514267],
              [-70.632278716542459, -33.493096939514267],
              [-70.634871930607048, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 310,
        left: -7863037.9755632691,
        bottom: -3959938.7304622582,
        right: -7861883.2744865119,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.48185892347886],
              [-70.632278716542459, -33.478112594092174],
              [-70.627092288413237, -33.478112594092174],
              [-70.624499074348648, -33.48185892347886],
              [-70.627092288413237, -33.485605090850768],
              [-70.632278716542459, -33.485605090850768],
              [-70.634871930607048, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 311,
        left: -7863037.9755632691,
        bottom: -3958938.7299960079,
        right: -7861883.2744865119,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.474366102699733],
              [-70.632278716542459, -33.470619449310547],
              [-70.627092288413237, -33.470619449310547],
              [-70.624499074348648, -33.474366102699733],
              [-70.627092288413237, -33.478112594092174],
              [-70.632278716542459, -33.478112594092174],
              [-70.634871930607048, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 312,
        left: -7863037.9755632691,
        bottom: -3957938.7295297575,
        right: -7861883.2744865119,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.466872633933626],
              [-70.632278716542459, -33.463125656578008],
              [-70.627092288413237, -33.463125656578008],
              [-70.624499074348648, -33.466872633933626],
              [-70.627092288413237, -33.470619449310547],
              [-70.632278716542459, -33.470619449310547],
              [-70.634871930607048, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 313,
        left: -7863037.9755632691,
        bottom: -3956938.7290635072,
        right: -7861883.2744865119,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.459378517252702],
              [-70.632278716542459, -33.45563121596674],
              [-70.627092288413237, -33.45563121596674],
              [-70.624499074348648, -33.459378517252702],
              [-70.627092288413237, -33.463125656578008],
              [-70.632278716542459, -33.463125656578008],
              [-70.634871930607048, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 314,
        left: -7863037.9755632691,
        bottom: -3955938.7285972568,
        right: -7861883.2744865119,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.451883752729138],
              [-70.632278716542459, -33.448136127548949],
              [-70.627092288413237, -33.448136127548949],
              [-70.624499074348648, -33.451883752729138],
              [-70.627092288413237, -33.45563121596674],
              [-70.632278716542459, -33.45563121596674],
              [-70.634871930607048, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 315,
        left: -7863037.9755632691,
        bottom: -3954938.7281310065,
        right: -7861883.2744865119,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.444388340435196],
              [-70.632278716542459, -33.440640391396926],
              [-70.627092288413237, -33.440640391396926],
              [-70.624499074348648, -33.444388340435196],
              [-70.627092288413237, -33.448136127548949],
              [-70.632278716542459, -33.448136127548949],
              [-70.634871930607048, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 316,
        left: -7863037.9755632691,
        bottom: -3953938.7276647561,
        right: -7861883.2744865119,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.436892280443168],
              [-70.632278716542459, -33.433144007582989],
              [-70.627092288413237, -33.433144007582989],
              [-70.624499074348648, -33.436892280443168],
              [-70.627092288413237, -33.440640391396926],
              [-70.632278716542459, -33.440640391396926],
              [-70.634871930607048, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 317,
        left: -7863037.9755632691,
        bottom: -3952938.7271985058,
        right: -7861883.2744865119,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.429395572825413],
              [-70.632278716542459, -33.425646976179515],
              [-70.627092288413237, -33.425646976179515],
              [-70.624499074348648, -33.429395572825413],
              [-70.627092288413237, -33.433144007582989],
              [-70.632278716542459, -33.433144007582989],
              [-70.634871930607048, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 318,
        left: -7863037.9755632691,
        bottom: -3951938.7267322554,
        right: -7861883.2744865119,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.421898217654316],
              [-70.632278716542459, -33.418149297258921],
              [-70.627092288413237, -33.418149297258921],
              [-70.624499074348648, -33.421898217654316],
              [-70.627092288413237, -33.425646976179515],
              [-70.632278716542459, -33.425646976179515],
              [-70.634871930607048, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 319,
        left: -7863037.9755632691,
        bottom: -3950938.7262660051,
        right: -7861883.2744865119,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.414400215002352],
              [-70.632278716542459, -33.410650970893691],
              [-70.627092288413237, -33.410650970893691],
              [-70.624499074348648, -33.414400215002352],
              [-70.627092288413237, -33.418149297258921],
              [-70.632278716542459, -33.418149297258921],
              [-70.634871930607048, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 320,
        left: -7863037.9755632691,
        bottom: -3949938.7257997552,
        right: -7861883.2744865119,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.406901564941997],
              [-70.632278716542459, -33.40315199715635],
              [-70.627092288413237, -33.40315199715635],
              [-70.624499074348648, -33.406901564941997],
              [-70.627092288413237, -33.410650970893691],
              [-70.632278716542459, -33.410650970893691],
              [-70.634871930607048, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 321,
        left: -7863037.9755632691,
        bottom: -3948938.7253335048,
        right: -7861883.2744865119,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.399402267545824],
              [-70.632278716542459, -33.395652376119486],
              [-70.627092288413237, -33.395652376119486],
              [-70.624499074348648, -33.399402267545824],
              [-70.627092288413237, -33.40315199715635],
              [-70.632278716542459, -33.40315199715635],
              [-70.634871930607048, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 322,
        left: -7863037.9755632691,
        bottom: -3947938.724867254,
        right: -7861883.2744865119,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.391902322886416],
              [-70.632278716542459, -33.388152107855717],
              [-70.627092288413237, -33.388152107855717],
              [-70.624499074348648, -33.391902322886416],
              [-70.627092288413237, -33.395652376119486],
              [-70.632278716542459, -33.395652376119486],
              [-70.634871930607048, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 323,
        left: -7863037.9755632691,
        bottom: -3946938.7244010041,
        right: -7861883.2744865119,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.384401731036441],
              [-70.632278716542459, -33.380651192437711],
              [-70.627092288413237, -33.380651192437711],
              [-70.624499074348648, -33.384401731036441],
              [-70.627092288413237, -33.388152107855717],
              [-70.632278716542459, -33.388152107855717],
              [-70.634871930607048, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 324,
        left: -7863037.9755632691,
        bottom: -3945938.7239347543,
        right: -7861883.2744865119,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.3769004920686],
              [-70.632278716542459, -33.373149629938226],
              [-70.627092288413237, -33.373149629938226],
              [-70.624499074348648, -33.3769004920686],
              [-70.627092288413237, -33.380651192437711],
              [-70.632278716542459, -33.380651192437711],
              [-70.634871930607048, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 325,
        left: -7863037.9755632691,
        bottom: -3944938.7234685034,
        right: -7861883.2744865119,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.369398606055647],
              [-70.632278716542459, -33.365647420430008],
              [-70.627092288413237, -33.365647420430008],
              [-70.624499074348648, -33.369398606055647],
              [-70.627092288413237, -33.373149629938226],
              [-70.632278716542459, -33.373149629938226],
              [-70.634871930607048, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 326,
        left: -7863037.9755632691,
        bottom: -3943938.7230022531,
        right: -7861883.2744865119,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.36189607307039],
              [-70.632278716542459, -33.358144563985917],
              [-70.627092288413237, -33.358144563985917],
              [-70.624499074348648, -33.36189607307039],
              [-70.627092288413237, -33.365647420430008],
              [-70.632278716542459, -33.365647420430008],
              [-70.634871930607048, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 327,
        left: -7863037.9755632691,
        bottom: -3942938.7225360028,
        right: -7861883.2744865119,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.35439289318569],
              [-70.632278716542459, -33.350641060678818],
              [-70.627092288413237, -33.350641060678818],
              [-70.624499074348648, -33.35439289318569],
              [-70.627092288413237, -33.358144563985917],
              [-70.632278716542459, -33.358144563985917],
              [-70.634871930607048, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 328,
        left: -7863037.9755632691,
        bottom: -3941938.7220697524,
        right: -7861883.2744865119,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.346889066474425],
              [-70.632278716542459, -33.343136910581649],
              [-70.627092288413237, -33.343136910581649],
              [-70.624499074348648, -33.346889066474425],
              [-70.627092288413237, -33.350641060678818],
              [-70.632278716542459, -33.350641060678818],
              [-70.634871930607048, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 329,
        left: -7863037.9755632691,
        bottom: -3940938.7216035021,
        right: -7861883.2744865119,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.339384593009591],
              [-70.632278716542459, -33.335632113767389],
              [-70.627092288413237, -33.335632113767389],
              [-70.624499074348648, -33.339384593009591],
              [-70.627092288413237, -33.343136910581649],
              [-70.632278716542459, -33.343136910581649],
              [-70.634871930607048, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 330,
        left: -7863037.9755632691,
        bottom: -3939938.7211372517,
        right: -7861883.2744865119,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.331879472864181],
              [-70.632278716542459, -33.328126670309082],
              [-70.627092288413237, -33.328126670309082],
              [-70.624499074348648, -33.331879472864181],
              [-70.627092288413237, -33.335632113767389],
              [-70.632278716542459, -33.335632113767389],
              [-70.634871930607048, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 331,
        left: -7863037.9755632691,
        bottom: -3938938.7206710014,
        right: -7861883.2744865119,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.324373706111238],
              [-70.632278716542459, -33.320620580279794],
              [-70.627092288413237, -33.320620580279794],
              [-70.624499074348648, -33.324373706111238],
              [-70.627092288413237, -33.328126670309082],
              [-70.632278716542459, -33.328126670309082],
              [-70.634871930607048, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 332,
        left: -7863037.9755632691,
        bottom: -3937938.720204751,
        right: -7861883.2744865119,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.3168672928239],
              [-70.632278716542459, -33.313113843752674],
              [-70.627092288413237, -33.313113843752674],
              [-70.624499074348648, -33.3168672928239],
              [-70.627092288413237, -33.320620580279794],
              [-70.632278716542459, -33.320620580279794],
              [-70.634871930607048, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 333,
        left: -7863037.9755632691,
        bottom: -3936938.7197385007,
        right: -7861883.2744865119,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.309360233075296],
              [-70.632278716542459, -33.305606460800909],
              [-70.627092288413237, -33.305606460800909],
              [-70.624499074348648, -33.309360233075296],
              [-70.627092288413237, -33.313113843752674],
              [-70.632278716542459, -33.313113843752674],
              [-70.634871930607048, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 334,
        left: -7863037.9755632691,
        bottom: -3935938.7192722503,
        right: -7861883.2744865119,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.30185252693866],
              [-70.632278716542459, -33.298098431497721],
              [-70.627092288413237, -33.298098431497721],
              [-70.624499074348648, -33.30185252693866],
              [-70.627092288413237, -33.305606460800909],
              [-70.632278716542459, -33.305606460800909],
              [-70.634871930607048, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 335,
        left: -7863037.9755632691,
        bottom: -3934938.718806,
        right: -7861883.2744865119,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.294344174487243],
              [-70.632278716542459, -33.290589755916407],
              [-70.627092288413237, -33.290589755916407],
              [-70.624499074348648, -33.294344174487243],
              [-70.627092288413237, -33.298098431497721],
              [-70.632278716542459, -33.298098431497721],
              [-70.634871930607048, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 336,
        left: -7863037.9755632691,
        bottom: -3933938.7183397496,
        right: -7861883.2744865119,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.634871930607048, -33.286835175794366],
              [-70.632278716542459, -33.283080434130305],
              [-70.627092288413237, -33.283080434130305],
              [-70.624499074348648, -33.286835175794366],
              [-70.627092288413237, -33.290589755916407],
              [-70.632278716542459, -33.290589755916407],
              [-70.634871930607048, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 337,
        left: -7862171.9497557012,
        bottom: -3980438.7400203901,
        right: -7861017.2486789431,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.63531883056551],
              [-70.624499074348648, -33.631579151136748],
              [-70.619312646219441, -33.631579151136748],
              [-70.616719432154824, -33.63531883056551],
              [-70.619312646219441, -33.639058347612803],
              [-70.624499074348648, -33.639058347612803],
              [-70.627092288413237, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 338,
        left: -7862171.9497557012,
        bottom: -3979438.7395541398,
        right: -7861017.2486789431,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.627839309335386],
              [-70.624499074348648, -33.624099305170297],
              [-70.619312646219441, -33.624099305170297],
              [-70.616719432154824, -33.627839309335386],
              [-70.619312646219441, -33.631579151136748],
              [-70.624499074348648, -33.631579151136748],
              [-70.627092288413237, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 339,
        left: -7862171.9497557012,
        bottom: -3978438.7390878894,
        right: -7861017.2486789431,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.620359138650379],
              [-70.624499074348648, -33.616618809784534],
              [-70.619312646219441, -33.616618809784534],
              [-70.616719432154824, -33.620359138650379],
              [-70.619312646219441, -33.624099305170297],
              [-70.624499074348648, -33.624099305170297],
              [-70.627092288413237, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 340,
        left: -7862171.9497557012,
        bottom: -3977438.7386216391,
        right: -7861017.2486789431,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.612878318581629],
              [-70.624499074348648, -33.609137665050582],
              [-70.619312646219441, -33.609137665050582],
              [-70.616719432154824, -33.612878318581629],
              [-70.619312646219441, -33.616618809784534],
              [-70.624499074348648, -33.616618809784534],
              [-70.627092288413237, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 341,
        left: -7862171.9497557012,
        bottom: -3976438.7381553887,
        right: -7861017.2486789431,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.605396849200282],
              [-70.624499074348648, -33.601655871039647],
              [-70.619312646219441, -33.601655871039647],
              [-70.616719432154824, -33.605396849200282],
              [-70.619312646219441, -33.609137665050582],
              [-70.624499074348648, -33.609137665050582],
              [-70.627092288413237, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 342,
        left: -7862171.9497557012,
        bottom: -3975438.7376891384,
        right: -7861017.2486789431,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.597914730577571],
              [-70.624499074348648, -33.594173427822959],
              [-70.619312646219441, -33.594173427822959],
              [-70.616719432154824, -33.597914730577571],
              [-70.619312646219441, -33.601655871039647],
              [-70.624499074348648, -33.601655871039647],
              [-70.627092288413237, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 343,
        left: -7862171.9497557012,
        bottom: -3974438.7372228876,
        right: -7861017.2486789431,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.590431962784734],
              [-70.624499074348648, -33.586690335471808],
              [-70.619312646219441, -33.586690335471808],
              [-70.616719432154824, -33.590431962784734],
              [-70.619312646219441, -33.594173427822959],
              [-70.624499074348648, -33.594173427822959],
              [-70.627092288413237, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 344,
        left: -7862171.9497557012,
        bottom: -3973438.7367566377,
        right: -7861017.2486789431,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.582948545893096],
              [-70.624499074348648, -33.579206594057531],
              [-70.619312646219441, -33.579206594057531],
              [-70.616719432154824, -33.582948545893096],
              [-70.619312646219441, -33.586690335471808],
              [-70.624499074348648, -33.586690335471808],
              [-70.627092288413237, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 345,
        left: -7862171.9497557012,
        bottom: -3972438.7362903878,
        right: -7861017.2486789431,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.575464479974038],
              [-70.624499074348648, -33.571722203651518],
              [-70.619312646219441, -33.571722203651518],
              [-70.616719432154824, -33.575464479974038],
              [-70.619312646219441, -33.579206594057531],
              [-70.624499074348648, -33.579206594057531],
              [-70.627092288413237, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 346,
        left: -7862171.9497557012,
        bottom: -3971438.735824137,
        right: -7861017.2486789431,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.56797976509894],
              [-70.624499074348648, -33.56423716432522],
              [-70.619312646219441, -33.56423716432522],
              [-70.616719432154824, -33.56797976509894],
              [-70.619312646219441, -33.571722203651518],
              [-70.624499074348648, -33.571722203651518],
              [-70.627092288413237, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 347,
        left: -7862171.9497557012,
        bottom: -3970438.7353578866,
        right: -7861017.2486789431,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.560494401339298],
              [-70.624499074348648, -33.556751476150104],
              [-70.619312646219441, -33.556751476150104],
              [-70.616719432154824, -33.560494401339298],
              [-70.619312646219441, -33.56423716432522],
              [-70.624499074348648, -33.56423716432522],
              [-70.627092288413237, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 348,
        left: -7862171.9497557012,
        bottom: -3969438.7348916363,
        right: -7861017.2486789431,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.553008388766614],
              [-70.624499074348648, -33.549265139197736],
              [-70.619312646219441, -33.549265139197736],
              [-70.616719432154824, -33.553008388766614],
              [-70.619312646219441, -33.556751476150104],
              [-70.624499074348648, -33.556751476150104],
              [-70.627092288413237, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 349,
        left: -7862171.9497557012,
        bottom: -3968438.7344253859,
        right: -7861017.2486789431,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.54552172745246],
              [-70.624499074348648, -33.541778153539695],
              [-70.619312646219441, -33.541778153539695],
              [-70.616719432154824, -33.54552172745246],
              [-70.619312646219441, -33.549265139197736],
              [-70.624499074348648, -33.549265139197736],
              [-70.627092288413237, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 350,
        left: -7862171.9497557012,
        bottom: -3967438.7339591356,
        right: -7861017.2486789431,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.538034417468438],
              [-70.624499074348648, -33.534290519247627],
              [-70.619312646219441, -33.534290519247627],
              [-70.616719432154824, -33.538034417468438],
              [-70.619312646219441, -33.541778153539695],
              [-70.624499074348648, -33.541778153539695],
              [-70.627092288413237, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 351,
        left: -7862171.9497557012,
        bottom: -3966438.7334928853,
        right: -7861017.2486789431,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.530546458886235],
              [-70.624499074348648, -33.526802236393216],
              [-70.619312646219441, -33.526802236393216],
              [-70.616719432154824, -33.530546458886235],
              [-70.619312646219441, -33.534290519247627],
              [-70.624499074348648, -33.534290519247627],
              [-70.627092288413237, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 352,
        left: -7862171.9497557012,
        bottom: -3965438.7330266349,
        right: -7861017.2486789431,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.523057851777558],
              [-70.624499074348648, -33.519313305048215],
              [-70.619312646219441, -33.519313305048215],
              [-70.616719432154824, -33.523057851777558],
              [-70.619312646219441, -33.526802236393216],
              [-70.624499074348648, -33.526802236393216],
              [-70.627092288413237, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 353,
        left: -7862171.9497557012,
        bottom: -3964438.7325603846,
        right: -7861017.2486789431,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.515568596214173],
              [-70.624499074348648, -33.511823725284408],
              [-70.619312646219441, -33.511823725284408],
              [-70.616719432154824, -33.515568596214173],
              [-70.619312646219441, -33.519313305048215],
              [-70.624499074348648, -33.519313305048215],
              [-70.627092288413237, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 354,
        left: -7862171.9497557012,
        bottom: -3963438.7320941342,
        right: -7861017.2486789431,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.508078692267915],
              [-70.624499074348648, -33.504333497173661],
              [-70.619312646219441, -33.504333497173661],
              [-70.616719432154824, -33.508078692267915],
              [-70.619312646219441, -33.511823725284408],
              [-70.624499074348648, -33.511823725284408],
              [-70.627092288413237, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 355,
        left: -7862171.9497557012,
        bottom: -3962438.7316278839,
        right: -7861017.2486789431,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.500588140010635],
              [-70.624499074348648, -33.496842620787838],
              [-70.619312646219441, -33.496842620787838],
              [-70.616719432154824, -33.500588140010635],
              [-70.619312646219441, -33.504333497173661],
              [-70.624499074348648, -33.504333497173661],
              [-70.627092288413237, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 356,
        left: -7862171.9497557012,
        bottom: -3961438.7311616335,
        right: -7861017.2486789431,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.493096939514267],
              [-70.624499074348648, -33.489351096198902],
              [-70.619312646219441, -33.489351096198902],
              [-70.616719432154824, -33.493096939514267],
              [-70.619312646219441, -33.496842620787838],
              [-70.624499074348648, -33.496842620787838],
              [-70.627092288413237, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 357,
        left: -7862171.9497557012,
        bottom: -3960438.7306953832,
        right: -7861017.2486789431,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.485605090850768],
              [-70.624499074348648, -33.48185892347886],
              [-70.619312646219441, -33.48185892347886],
              [-70.616719432154824, -33.485605090850768],
              [-70.619312646219441, -33.489351096198902],
              [-70.624499074348648, -33.489351096198902],
              [-70.627092288413237, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 358,
        left: -7862171.9497557012,
        bottom: -3959438.7302291333,
        right: -7861017.2486789431,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.478112594092174],
              [-70.624499074348648, -33.474366102699733],
              [-70.619312646219441, -33.474366102699733],
              [-70.616719432154824, -33.478112594092174],
              [-70.619312646219441, -33.48185892347886],
              [-70.624499074348648, -33.48185892347886],
              [-70.627092288413237, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 359,
        left: -7862171.9497557012,
        bottom: -3958438.7297628829,
        right: -7861017.2486789431,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.470619449310547],
              [-70.624499074348648, -33.466872633933626],
              [-70.619312646219441, -33.466872633933626],
              [-70.616719432154824, -33.470619449310547],
              [-70.619312646219441, -33.474366102699733],
              [-70.624499074348648, -33.474366102699733],
              [-70.627092288413237, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 360,
        left: -7862171.9497557012,
        bottom: -3957438.7292966326,
        right: -7861017.2486789431,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.463125656578008],
              [-70.624499074348648, -33.459378517252702],
              [-70.619312646219441, -33.459378517252702],
              [-70.616719432154824, -33.463125656578008],
              [-70.619312646219441, -33.466872633933626],
              [-70.624499074348648, -33.466872633933626],
              [-70.627092288413237, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 361,
        left: -7862171.9497557012,
        bottom: -3956438.7288303822,
        right: -7861017.2486789431,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.45563121596674],
              [-70.624499074348648, -33.451883752729138],
              [-70.619312646219441, -33.451883752729138],
              [-70.616719432154824, -33.45563121596674],
              [-70.619312646219441, -33.459378517252702],
              [-70.624499074348648, -33.459378517252702],
              [-70.627092288413237, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 362,
        left: -7862171.9497557012,
        bottom: -3955438.7283641323,
        right: -7861017.2486789431,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.448136127548949],
              [-70.624499074348648, -33.444388340435196],
              [-70.619312646219441, -33.444388340435196],
              [-70.616719432154824, -33.448136127548949],
              [-70.619312646219441, -33.451883752729138],
              [-70.624499074348648, -33.451883752729138],
              [-70.627092288413237, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 363,
        left: -7862171.9497557012,
        bottom: -3954438.7278978815,
        right: -7861017.2486789431,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.440640391396926],
              [-70.624499074348648, -33.436892280443168],
              [-70.619312646219441, -33.436892280443168],
              [-70.616719432154824, -33.440640391396926],
              [-70.619312646219441, -33.444388340435196],
              [-70.624499074348648, -33.444388340435196],
              [-70.627092288413237, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 364,
        left: -7862171.9497557012,
        bottom: -3953438.7274316312,
        right: -7861017.2486789431,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.433144007582989],
              [-70.624499074348648, -33.429395572825413],
              [-70.619312646219441, -33.429395572825413],
              [-70.616719432154824, -33.433144007582989],
              [-70.619312646219441, -33.436892280443168],
              [-70.624499074348648, -33.436892280443168],
              [-70.627092288413237, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 365,
        left: -7862171.9497557012,
        bottom: -3952438.7269653808,
        right: -7861017.2486789431,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.425646976179515],
              [-70.624499074348648, -33.421898217654316],
              [-70.619312646219441, -33.421898217654316],
              [-70.616719432154824, -33.425646976179515],
              [-70.619312646219441, -33.429395572825413],
              [-70.624499074348648, -33.429395572825413],
              [-70.627092288413237, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 366,
        left: -7862171.9497557012,
        bottom: -3951438.7264991305,
        right: -7861017.2486789431,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.418149297258921],
              [-70.624499074348648, -33.414400215002352],
              [-70.619312646219441, -33.414400215002352],
              [-70.616719432154824, -33.418149297258921],
              [-70.619312646219441, -33.421898217654316],
              [-70.624499074348648, -33.421898217654316],
              [-70.627092288413237, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 367,
        left: -7862171.9497557012,
        bottom: -3950438.7260328801,
        right: -7861017.2486789431,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.410650970893691],
              [-70.624499074348648, -33.406901564941997],
              [-70.619312646219441, -33.406901564941997],
              [-70.616719432154824, -33.410650970893691],
              [-70.619312646219441, -33.414400215002352],
              [-70.624499074348648, -33.414400215002352],
              [-70.627092288413237, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 368,
        left: -7862171.9497557012,
        bottom: -3949438.7255666298,
        right: -7861017.2486789431,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.40315199715635],
              [-70.624499074348648, -33.399402267545824],
              [-70.619312646219441, -33.399402267545824],
              [-70.616719432154824, -33.40315199715635],
              [-70.619312646219441, -33.406901564941997],
              [-70.624499074348648, -33.406901564941997],
              [-70.627092288413237, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 369,
        left: -7862171.9497557012,
        bottom: -3948438.7251003794,
        right: -7861017.2486789431,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.395652376119486],
              [-70.624499074348648, -33.391902322886416],
              [-70.619312646219441, -33.391902322886416],
              [-70.616719432154824, -33.395652376119486],
              [-70.619312646219441, -33.399402267545824],
              [-70.624499074348648, -33.399402267545824],
              [-70.627092288413237, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 370,
        left: -7862171.9497557012,
        bottom: -3947438.7246341291,
        right: -7861017.2486789431,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.388152107855717],
              [-70.624499074348648, -33.384401731036441],
              [-70.619312646219441, -33.384401731036441],
              [-70.616719432154824, -33.388152107855717],
              [-70.619312646219441, -33.391902322886416],
              [-70.624499074348648, -33.391902322886416],
              [-70.627092288413237, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 371,
        left: -7862171.9497557012,
        bottom: -3946438.7241678787,
        right: -7861017.2486789431,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.380651192437711],
              [-70.624499074348648, -33.3769004920686],
              [-70.619312646219441, -33.3769004920686],
              [-70.616719432154824, -33.380651192437711],
              [-70.619312646219441, -33.384401731036441],
              [-70.624499074348648, -33.384401731036441],
              [-70.627092288413237, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 372,
        left: -7862171.9497557012,
        bottom: -3945438.7237016284,
        right: -7861017.2486789431,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.373149629938226],
              [-70.624499074348648, -33.369398606055647],
              [-70.619312646219441, -33.369398606055647],
              [-70.616719432154824, -33.373149629938226],
              [-70.619312646219441, -33.3769004920686],
              [-70.624499074348648, -33.3769004920686],
              [-70.627092288413237, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 373,
        left: -7862171.9497557012,
        bottom: -3944438.723235378,
        right: -7861017.2486789431,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.365647420430008],
              [-70.624499074348648, -33.36189607307039],
              [-70.619312646219441, -33.36189607307039],
              [-70.616719432154824, -33.365647420430008],
              [-70.619312646219441, -33.369398606055647],
              [-70.624499074348648, -33.369398606055647],
              [-70.627092288413237, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 374,
        left: -7862171.9497557012,
        bottom: -3943438.7227691277,
        right: -7861017.2486789431,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.358144563985917],
              [-70.624499074348648, -33.35439289318569],
              [-70.619312646219441, -33.35439289318569],
              [-70.616719432154824, -33.358144563985917],
              [-70.619312646219441, -33.36189607307039],
              [-70.624499074348648, -33.36189607307039],
              [-70.627092288413237, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 375,
        left: -7862171.9497557012,
        bottom: -3942438.7223028769,
        right: -7861017.2486789431,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.350641060678818],
              [-70.624499074348648, -33.346889066474425],
              [-70.619312646219441, -33.346889066474425],
              [-70.616719432154824, -33.350641060678818],
              [-70.619312646219441, -33.35439289318569],
              [-70.624499074348648, -33.35439289318569],
              [-70.627092288413237, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 376,
        left: -7862171.9497557012,
        bottom: -3941438.721836627,
        right: -7861017.2486789431,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.343136910581649],
              [-70.624499074348648, -33.339384593009591],
              [-70.619312646219441, -33.339384593009591],
              [-70.616719432154824, -33.343136910581649],
              [-70.619312646219441, -33.346889066474425],
              [-70.624499074348648, -33.346889066474425],
              [-70.627092288413237, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 377,
        left: -7862171.9497557012,
        bottom: -3940438.7213703771,
        right: -7861017.2486789431,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.335632113767389],
              [-70.624499074348648, -33.331879472864181],
              [-70.619312646219441, -33.331879472864181],
              [-70.616719432154824, -33.335632113767389],
              [-70.619312646219441, -33.339384593009591],
              [-70.624499074348648, -33.339384593009591],
              [-70.627092288413237, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 378,
        left: -7862171.9497557012,
        bottom: -3939438.7209041263,
        right: -7861017.2486789431,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.328126670309082],
              [-70.624499074348648, -33.324373706111238],
              [-70.619312646219441, -33.324373706111238],
              [-70.616719432154824, -33.328126670309082],
              [-70.619312646219441, -33.331879472864181],
              [-70.624499074348648, -33.331879472864181],
              [-70.627092288413237, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 379,
        left: -7862171.9497557012,
        bottom: -3938438.7204378759,
        right: -7861017.2486789431,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.320620580279794],
              [-70.624499074348648, -33.3168672928239],
              [-70.619312646219441, -33.3168672928239],
              [-70.616719432154824, -33.320620580279794],
              [-70.619312646219441, -33.324373706111238],
              [-70.624499074348648, -33.324373706111238],
              [-70.627092288413237, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 380,
        left: -7862171.9497557012,
        bottom: -3937438.7199716261,
        right: -7861017.2486789431,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.313113843752674],
              [-70.624499074348648, -33.309360233075296],
              [-70.619312646219441, -33.309360233075296],
              [-70.616719432154824, -33.313113843752674],
              [-70.619312646219441, -33.3168672928239],
              [-70.624499074348648, -33.3168672928239],
              [-70.627092288413237, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 381,
        left: -7862171.9497557012,
        bottom: -3936438.7195053762,
        right: -7861017.2486789431,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.305606460800909],
              [-70.624499074348648, -33.30185252693866],
              [-70.619312646219441, -33.30185252693866],
              [-70.616719432154824, -33.305606460800909],
              [-70.619312646219441, -33.309360233075296],
              [-70.624499074348648, -33.309360233075296],
              [-70.627092288413237, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 382,
        left: -7862171.9497557012,
        bottom: -3935438.7190391254,
        right: -7861017.2486789431,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.298098431497721],
              [-70.624499074348648, -33.294344174487243],
              [-70.619312646219441, -33.294344174487243],
              [-70.616719432154824, -33.298098431497721],
              [-70.619312646219441, -33.30185252693866],
              [-70.624499074348648, -33.30185252693866],
              [-70.627092288413237, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 383,
        left: -7862171.9497557012,
        bottom: -3934438.718572875,
        right: -7861017.2486789431,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.290589755916407],
              [-70.624499074348648, -33.286835175794366],
              [-70.619312646219441, -33.286835175794366],
              [-70.616719432154824, -33.290589755916407],
              [-70.619312646219441, -33.294344174487243],
              [-70.624499074348648, -33.294344174487243],
              [-70.627092288413237, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 384,
        left: -7862171.9497557012,
        bottom: -3933438.7181066247,
        right: -7861017.2486789431,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.627092288413237, -33.283080434130305],
              [-70.624499074348648, -33.279325530933384],
              [-70.619312646219441, -33.279325530933384],
              [-70.616719432154824, -33.283080434130305],
              [-70.619312646219441, -33.286835175794366],
              [-70.624499074348648, -33.286835175794366],
              [-70.627092288413237, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 385,
        left: -7861305.9239481324,
        bottom: -3980938.7402535151,
        right: -7860151.2228713734,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.639058347612803],
              [-70.616719432154824, -33.63531883056551],
              [-70.611533004025617, -33.63531883056551],
              [-70.608939789961013, -33.639058347612803],
              [-70.611533004025617, -33.642797702269725],
              [-70.616719432154824, -33.642797702269725],
              [-70.619312646219441, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 386,
        left: -7861305.9239481324,
        bottom: -3979938.7397872647,
        right: -7860151.2228713734,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.631579151136748],
              [-70.616719432154824, -33.627839309335386],
              [-70.611533004025617, -33.627839309335386],
              [-70.608939789961013, -33.631579151136748],
              [-70.611533004025617, -33.63531883056551],
              [-70.616719432154824, -33.63531883056551],
              [-70.619312646219441, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 387,
        left: -7861305.9239481324,
        bottom: -3978938.7393210144,
        right: -7860151.2228713734,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.624099305170297],
              [-70.616719432154824, -33.620359138650379],
              [-70.611533004025617, -33.620359138650379],
              [-70.608939789961013, -33.624099305170297],
              [-70.611533004025617, -33.627839309335386],
              [-70.616719432154824, -33.627839309335386],
              [-70.619312646219441, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 388,
        left: -7861305.9239481324,
        bottom: -3977938.738854764,
        right: -7860151.2228713734,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.616618809784534],
              [-70.616719432154824, -33.612878318581629],
              [-70.611533004025617, -33.612878318581629],
              [-70.608939789961013, -33.616618809784534],
              [-70.611533004025617, -33.620359138650379],
              [-70.616719432154824, -33.620359138650379],
              [-70.619312646219441, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 389,
        left: -7861305.9239481324,
        bottom: -3976938.7383885137,
        right: -7860151.2228713734,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.609137665050582],
              [-70.616719432154824, -33.605396849200282],
              [-70.611533004025617, -33.605396849200282],
              [-70.608939789961013, -33.609137665050582],
              [-70.611533004025617, -33.612878318581629],
              [-70.616719432154824, -33.612878318581629],
              [-70.619312646219441, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 390,
        left: -7861305.9239481324,
        bottom: -3975938.7379222633,
        right: -7860151.2228713734,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.601655871039647],
              [-70.616719432154824, -33.597914730577571],
              [-70.611533004025617, -33.597914730577571],
              [-70.608939789961013, -33.601655871039647],
              [-70.611533004025617, -33.605396849200282],
              [-70.616719432154824, -33.605396849200282],
              [-70.619312646219441, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 391,
        left: -7861305.9239481324,
        bottom: -3974938.737456013,
        right: -7860151.2228713734,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.594173427822959],
              [-70.616719432154824, -33.590431962784734],
              [-70.611533004025617, -33.590431962784734],
              [-70.608939789961013, -33.594173427822959],
              [-70.611533004025617, -33.597914730577571],
              [-70.616719432154824, -33.597914730577571],
              [-70.619312646219441, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 392,
        left: -7861305.9239481324,
        bottom: -3973938.7369897626,
        right: -7860151.2228713734,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.586690335471808],
              [-70.616719432154824, -33.582948545893096],
              [-70.611533004025617, -33.582948545893096],
              [-70.608939789961013, -33.586690335471808],
              [-70.611533004025617, -33.590431962784734],
              [-70.616719432154824, -33.590431962784734],
              [-70.619312646219441, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 393,
        left: -7861305.9239481324,
        bottom: -3972938.7365235123,
        right: -7860151.2228713734,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.579206594057531],
              [-70.616719432154824, -33.575464479974038],
              [-70.611533004025617, -33.575464479974038],
              [-70.608939789961013, -33.579206594057531],
              [-70.611533004025617, -33.582948545893096],
              [-70.616719432154824, -33.582948545893096],
              [-70.619312646219441, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 394,
        left: -7861305.9239481324,
        bottom: -3971938.7360572619,
        right: -7860151.2228713734,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.571722203651518],
              [-70.616719432154824, -33.56797976509894],
              [-70.611533004025617, -33.56797976509894],
              [-70.608939789961013, -33.571722203651518],
              [-70.611533004025617, -33.575464479974038],
              [-70.616719432154824, -33.575464479974038],
              [-70.619312646219441, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 395,
        left: -7861305.9239481324,
        bottom: -3970938.7355910116,
        right: -7860151.2228713734,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.56423716432522],
              [-70.616719432154824, -33.560494401339298],
              [-70.611533004025617, -33.560494401339298],
              [-70.608939789961013, -33.56423716432522],
              [-70.611533004025617, -33.56797976509894],
              [-70.616719432154824, -33.56797976509894],
              [-70.619312646219441, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 396,
        left: -7861305.9239481324,
        bottom: -3969938.7351247617,
        right: -7860151.2228713734,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.556751476150104],
              [-70.616719432154824, -33.553008388766614],
              [-70.611533004025617, -33.553008388766614],
              [-70.608939789961013, -33.556751476150104],
              [-70.611533004025617, -33.560494401339298],
              [-70.616719432154824, -33.560494401339298],
              [-70.619312646219441, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 397,
        left: -7861305.9239481324,
        bottom: -3968938.7346585114,
        right: -7860151.2228713734,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.549265139197736],
              [-70.616719432154824, -33.54552172745246],
              [-70.611533004025617, -33.54552172745246],
              [-70.608939789961013, -33.549265139197736],
              [-70.611533004025617, -33.553008388766614],
              [-70.616719432154824, -33.553008388766614],
              [-70.619312646219441, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 398,
        left: -7861305.9239481324,
        bottom: -3967938.734192261,
        right: -7860151.2228713734,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.541778153539695],
              [-70.616719432154824, -33.538034417468438],
              [-70.611533004025617, -33.538034417468438],
              [-70.608939789961013, -33.541778153539695],
              [-70.611533004025617, -33.54552172745246],
              [-70.616719432154824, -33.54552172745246],
              [-70.619312646219441, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 399,
        left: -7861305.9239481324,
        bottom: -3966938.7337260107,
        right: -7860151.2228713734,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.534290519247627],
              [-70.616719432154824, -33.530546458886235],
              [-70.611533004025617, -33.530546458886235],
              [-70.608939789961013, -33.534290519247627],
              [-70.611533004025617, -33.538034417468438],
              [-70.616719432154824, -33.538034417468438],
              [-70.619312646219441, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 400,
        left: -7861305.9239481324,
        bottom: -3965938.7332597603,
        right: -7860151.2228713734,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.526802236393216],
              [-70.616719432154824, -33.523057851777558],
              [-70.611533004025617, -33.523057851777558],
              [-70.608939789961013, -33.526802236393216],
              [-70.611533004025617, -33.530546458886235],
              [-70.616719432154824, -33.530546458886235],
              [-70.619312646219441, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 401,
        left: -7861305.9239481324,
        bottom: -3964938.7327935095,
        right: -7860151.2228713734,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.519313305048215],
              [-70.616719432154824, -33.515568596214173],
              [-70.611533004025617, -33.515568596214173],
              [-70.608939789961013, -33.519313305048215],
              [-70.611533004025617, -33.523057851777558],
              [-70.616719432154824, -33.523057851777558],
              [-70.619312646219441, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 402,
        left: -7861305.9239481324,
        bottom: -3963938.7323272596,
        right: -7860151.2228713734,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.511823725284408],
              [-70.616719432154824, -33.508078692267915],
              [-70.611533004025617, -33.508078692267915],
              [-70.608939789961013, -33.511823725284408],
              [-70.611533004025617, -33.515568596214173],
              [-70.616719432154824, -33.515568596214173],
              [-70.619312646219441, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 403,
        left: -7861305.9239481324,
        bottom: -3962938.7318610097,
        right: -7860151.2228713734,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.504333497173661],
              [-70.616719432154824, -33.500588140010635],
              [-70.611533004025617, -33.500588140010635],
              [-70.608939789961013, -33.504333497173661],
              [-70.611533004025617, -33.508078692267915],
              [-70.616719432154824, -33.508078692267915],
              [-70.619312646219441, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 404,
        left: -7861305.9239481324,
        bottom: -3961938.7313947589,
        right: -7860151.2228713734,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.496842620787838],
              [-70.616719432154824, -33.493096939514267],
              [-70.611533004025617, -33.493096939514267],
              [-70.608939789961013, -33.496842620787838],
              [-70.611533004025617, -33.500588140010635],
              [-70.616719432154824, -33.500588140010635],
              [-70.619312646219441, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 405,
        left: -7861305.9239481324,
        bottom: -3960938.7309285086,
        right: -7860151.2228713734,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.489351096198902],
              [-70.616719432154824, -33.485605090850768],
              [-70.611533004025617, -33.485605090850768],
              [-70.608939789961013, -33.489351096198902],
              [-70.611533004025617, -33.493096939514267],
              [-70.616719432154824, -33.493096939514267],
              [-70.619312646219441, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 406,
        left: -7861305.9239481324,
        bottom: -3959938.7304622582,
        right: -7860151.2228713734,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.48185892347886],
              [-70.616719432154824, -33.478112594092174],
              [-70.611533004025617, -33.478112594092174],
              [-70.608939789961013, -33.48185892347886],
              [-70.611533004025617, -33.485605090850768],
              [-70.616719432154824, -33.485605090850768],
              [-70.619312646219441, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 407,
        left: -7861305.9239481324,
        bottom: -3958938.7299960079,
        right: -7860151.2228713734,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.474366102699733],
              [-70.616719432154824, -33.470619449310547],
              [-70.611533004025617, -33.470619449310547],
              [-70.608939789961013, -33.474366102699733],
              [-70.611533004025617, -33.478112594092174],
              [-70.616719432154824, -33.478112594092174],
              [-70.619312646219441, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 408,
        left: -7861305.9239481324,
        bottom: -3957938.7295297575,
        right: -7860151.2228713734,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.466872633933626],
              [-70.616719432154824, -33.463125656578008],
              [-70.611533004025617, -33.463125656578008],
              [-70.608939789961013, -33.466872633933626],
              [-70.611533004025617, -33.470619449310547],
              [-70.616719432154824, -33.470619449310547],
              [-70.619312646219441, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 409,
        left: -7861305.9239481324,
        bottom: -3956938.7290635072,
        right: -7860151.2228713734,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.459378517252702],
              [-70.616719432154824, -33.45563121596674],
              [-70.611533004025617, -33.45563121596674],
              [-70.608939789961013, -33.459378517252702],
              [-70.611533004025617, -33.463125656578008],
              [-70.616719432154824, -33.463125656578008],
              [-70.619312646219441, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 410,
        left: -7861305.9239481324,
        bottom: -3955938.7285972568,
        right: -7860151.2228713734,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.451883752729138],
              [-70.616719432154824, -33.448136127548949],
              [-70.611533004025617, -33.448136127548949],
              [-70.608939789961013, -33.451883752729138],
              [-70.611533004025617, -33.45563121596674],
              [-70.616719432154824, -33.45563121596674],
              [-70.619312646219441, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 411,
        left: -7861305.9239481324,
        bottom: -3954938.7281310065,
        right: -7860151.2228713734,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.444388340435196],
              [-70.616719432154824, -33.440640391396926],
              [-70.611533004025617, -33.440640391396926],
              [-70.608939789961013, -33.444388340435196],
              [-70.611533004025617, -33.448136127548949],
              [-70.616719432154824, -33.448136127548949],
              [-70.619312646219441, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 412,
        left: -7861305.9239481324,
        bottom: -3953938.7276647561,
        right: -7860151.2228713734,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.436892280443168],
              [-70.616719432154824, -33.433144007582989],
              [-70.611533004025617, -33.433144007582989],
              [-70.608939789961013, -33.436892280443168],
              [-70.611533004025617, -33.440640391396926],
              [-70.616719432154824, -33.440640391396926],
              [-70.619312646219441, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 413,
        left: -7861305.9239481324,
        bottom: -3952938.7271985058,
        right: -7860151.2228713734,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.429395572825413],
              [-70.616719432154824, -33.425646976179515],
              [-70.611533004025617, -33.425646976179515],
              [-70.608939789961013, -33.429395572825413],
              [-70.611533004025617, -33.433144007582989],
              [-70.616719432154824, -33.433144007582989],
              [-70.619312646219441, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 414,
        left: -7861305.9239481324,
        bottom: -3951938.7267322554,
        right: -7860151.2228713734,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.421898217654316],
              [-70.616719432154824, -33.418149297258921],
              [-70.611533004025617, -33.418149297258921],
              [-70.608939789961013, -33.421898217654316],
              [-70.611533004025617, -33.425646976179515],
              [-70.616719432154824, -33.425646976179515],
              [-70.619312646219441, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 415,
        left: -7861305.9239481324,
        bottom: -3950938.7262660051,
        right: -7860151.2228713734,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.414400215002352],
              [-70.616719432154824, -33.410650970893691],
              [-70.611533004025617, -33.410650970893691],
              [-70.608939789961013, -33.414400215002352],
              [-70.611533004025617, -33.418149297258921],
              [-70.616719432154824, -33.418149297258921],
              [-70.619312646219441, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 416,
        left: -7861305.9239481324,
        bottom: -3949938.7257997552,
        right: -7860151.2228713734,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.406901564941997],
              [-70.616719432154824, -33.40315199715635],
              [-70.611533004025617, -33.40315199715635],
              [-70.608939789961013, -33.406901564941997],
              [-70.611533004025617, -33.410650970893691],
              [-70.616719432154824, -33.410650970893691],
              [-70.619312646219441, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 417,
        left: -7861305.9239481324,
        bottom: -3948938.7253335048,
        right: -7860151.2228713734,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.399402267545824],
              [-70.616719432154824, -33.395652376119486],
              [-70.611533004025617, -33.395652376119486],
              [-70.608939789961013, -33.399402267545824],
              [-70.611533004025617, -33.40315199715635],
              [-70.616719432154824, -33.40315199715635],
              [-70.619312646219441, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 418,
        left: -7861305.9239481324,
        bottom: -3947938.724867254,
        right: -7860151.2228713734,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.391902322886416],
              [-70.616719432154824, -33.388152107855717],
              [-70.611533004025617, -33.388152107855717],
              [-70.608939789961013, -33.391902322886416],
              [-70.611533004025617, -33.395652376119486],
              [-70.616719432154824, -33.395652376119486],
              [-70.619312646219441, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 419,
        left: -7861305.9239481324,
        bottom: -3946938.7244010041,
        right: -7860151.2228713734,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.384401731036441],
              [-70.616719432154824, -33.380651192437711],
              [-70.611533004025617, -33.380651192437711],
              [-70.608939789961013, -33.384401731036441],
              [-70.611533004025617, -33.388152107855717],
              [-70.616719432154824, -33.388152107855717],
              [-70.619312646219441, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 420,
        left: -7861305.9239481324,
        bottom: -3945938.7239347543,
        right: -7860151.2228713734,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.3769004920686],
              [-70.616719432154824, -33.373149629938226],
              [-70.611533004025617, -33.373149629938226],
              [-70.608939789961013, -33.3769004920686],
              [-70.611533004025617, -33.380651192437711],
              [-70.616719432154824, -33.380651192437711],
              [-70.619312646219441, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 421,
        left: -7861305.9239481324,
        bottom: -3944938.7234685034,
        right: -7860151.2228713734,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.369398606055647],
              [-70.616719432154824, -33.365647420430008],
              [-70.611533004025617, -33.365647420430008],
              [-70.608939789961013, -33.369398606055647],
              [-70.611533004025617, -33.373149629938226],
              [-70.616719432154824, -33.373149629938226],
              [-70.619312646219441, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 422,
        left: -7861305.9239481324,
        bottom: -3943938.7230022531,
        right: -7860151.2228713734,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.36189607307039],
              [-70.616719432154824, -33.358144563985917],
              [-70.611533004025617, -33.358144563985917],
              [-70.608939789961013, -33.36189607307039],
              [-70.611533004025617, -33.365647420430008],
              [-70.616719432154824, -33.365647420430008],
              [-70.619312646219441, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 423,
        left: -7861305.9239481324,
        bottom: -3942938.7225360028,
        right: -7860151.2228713734,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.35439289318569],
              [-70.616719432154824, -33.350641060678818],
              [-70.611533004025617, -33.350641060678818],
              [-70.608939789961013, -33.35439289318569],
              [-70.611533004025617, -33.358144563985917],
              [-70.616719432154824, -33.358144563985917],
              [-70.619312646219441, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 424,
        left: -7861305.9239481324,
        bottom: -3941938.7220697524,
        right: -7860151.2228713734,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.346889066474425],
              [-70.616719432154824, -33.343136910581649],
              [-70.611533004025617, -33.343136910581649],
              [-70.608939789961013, -33.346889066474425],
              [-70.611533004025617, -33.350641060678818],
              [-70.616719432154824, -33.350641060678818],
              [-70.619312646219441, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 425,
        left: -7861305.9239481324,
        bottom: -3940938.7216035021,
        right: -7860151.2228713734,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.339384593009591],
              [-70.616719432154824, -33.335632113767389],
              [-70.611533004025617, -33.335632113767389],
              [-70.608939789961013, -33.339384593009591],
              [-70.611533004025617, -33.343136910581649],
              [-70.616719432154824, -33.343136910581649],
              [-70.619312646219441, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 426,
        left: -7861305.9239481324,
        bottom: -3939938.7211372517,
        right: -7860151.2228713734,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.331879472864181],
              [-70.616719432154824, -33.328126670309082],
              [-70.611533004025617, -33.328126670309082],
              [-70.608939789961013, -33.331879472864181],
              [-70.611533004025617, -33.335632113767389],
              [-70.616719432154824, -33.335632113767389],
              [-70.619312646219441, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 427,
        left: -7861305.9239481324,
        bottom: -3938938.7206710014,
        right: -7860151.2228713734,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.324373706111238],
              [-70.616719432154824, -33.320620580279794],
              [-70.611533004025617, -33.320620580279794],
              [-70.608939789961013, -33.324373706111238],
              [-70.611533004025617, -33.328126670309082],
              [-70.616719432154824, -33.328126670309082],
              [-70.619312646219441, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 428,
        left: -7861305.9239481324,
        bottom: -3937938.720204751,
        right: -7860151.2228713734,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.3168672928239],
              [-70.616719432154824, -33.313113843752674],
              [-70.611533004025617, -33.313113843752674],
              [-70.608939789961013, -33.3168672928239],
              [-70.611533004025617, -33.320620580279794],
              [-70.616719432154824, -33.320620580279794],
              [-70.619312646219441, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 429,
        left: -7861305.9239481324,
        bottom: -3936938.7197385007,
        right: -7860151.2228713734,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.309360233075296],
              [-70.616719432154824, -33.305606460800909],
              [-70.611533004025617, -33.305606460800909],
              [-70.608939789961013, -33.309360233075296],
              [-70.611533004025617, -33.313113843752674],
              [-70.616719432154824, -33.313113843752674],
              [-70.619312646219441, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 430,
        left: -7861305.9239481324,
        bottom: -3935938.7192722503,
        right: -7860151.2228713734,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.30185252693866],
              [-70.616719432154824, -33.298098431497721],
              [-70.611533004025617, -33.298098431497721],
              [-70.608939789961013, -33.30185252693866],
              [-70.611533004025617, -33.305606460800909],
              [-70.616719432154824, -33.305606460800909],
              [-70.619312646219441, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 431,
        left: -7861305.9239481324,
        bottom: -3934938.718806,
        right: -7860151.2228713734,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.294344174487243],
              [-70.616719432154824, -33.290589755916407],
              [-70.611533004025617, -33.290589755916407],
              [-70.608939789961013, -33.294344174487243],
              [-70.611533004025617, -33.298098431497721],
              [-70.616719432154824, -33.298098431497721],
              [-70.619312646219441, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 432,
        left: -7861305.9239481324,
        bottom: -3933938.7183397496,
        right: -7860151.2228713734,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.619312646219441, -33.286835175794366],
              [-70.616719432154824, -33.283080434130305],
              [-70.611533004025617, -33.283080434130305],
              [-70.608939789961013, -33.286835175794366],
              [-70.611533004025617, -33.290589755916407],
              [-70.616719432154824, -33.290589755916407],
              [-70.619312646219441, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 433,
        left: -7860439.8981405636,
        bottom: -3980438.7400203901,
        right: -7859285.1970638046,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.63531883056551],
              [-70.608939789961013, -33.631579151136748],
              [-70.603753361831792, -33.631579151136748],
              [-70.601160147767203, -33.63531883056551],
              [-70.603753361831792, -33.639058347612803],
              [-70.608939789961013, -33.639058347612803],
              [-70.611533004025617, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 434,
        left: -7860439.8981405636,
        bottom: -3979438.7395541398,
        right: -7859285.1970638046,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.627839309335386],
              [-70.608939789961013, -33.624099305170297],
              [-70.603753361831792, -33.624099305170297],
              [-70.601160147767203, -33.627839309335386],
              [-70.603753361831792, -33.631579151136748],
              [-70.608939789961013, -33.631579151136748],
              [-70.611533004025617, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 435,
        left: -7860439.8981405636,
        bottom: -3978438.7390878894,
        right: -7859285.1970638046,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.620359138650379],
              [-70.608939789961013, -33.616618809784534],
              [-70.603753361831792, -33.616618809784534],
              [-70.601160147767203, -33.620359138650379],
              [-70.603753361831792, -33.624099305170297],
              [-70.608939789961013, -33.624099305170297],
              [-70.611533004025617, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 436,
        left: -7860439.8981405636,
        bottom: -3977438.7386216391,
        right: -7859285.1970638046,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.612878318581629],
              [-70.608939789961013, -33.609137665050582],
              [-70.603753361831792, -33.609137665050582],
              [-70.601160147767203, -33.612878318581629],
              [-70.603753361831792, -33.616618809784534],
              [-70.608939789961013, -33.616618809784534],
              [-70.611533004025617, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 437,
        left: -7860439.8981405636,
        bottom: -3976438.7381553887,
        right: -7859285.1970638046,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.605396849200282],
              [-70.608939789961013, -33.601655871039647],
              [-70.603753361831792, -33.601655871039647],
              [-70.601160147767203, -33.605396849200282],
              [-70.603753361831792, -33.609137665050582],
              [-70.608939789961013, -33.609137665050582],
              [-70.611533004025617, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 438,
        left: -7860439.8981405636,
        bottom: -3975438.7376891384,
        right: -7859285.1970638046,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.597914730577571],
              [-70.608939789961013, -33.594173427822959],
              [-70.603753361831792, -33.594173427822959],
              [-70.601160147767203, -33.597914730577571],
              [-70.603753361831792, -33.601655871039647],
              [-70.608939789961013, -33.601655871039647],
              [-70.611533004025617, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 439,
        left: -7860439.8981405636,
        bottom: -3974438.7372228876,
        right: -7859285.1970638046,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.590431962784734],
              [-70.608939789961013, -33.586690335471808],
              [-70.603753361831792, -33.586690335471808],
              [-70.601160147767203, -33.590431962784734],
              [-70.603753361831792, -33.594173427822959],
              [-70.608939789961013, -33.594173427822959],
              [-70.611533004025617, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 440,
        left: -7860439.8981405636,
        bottom: -3973438.7367566377,
        right: -7859285.1970638046,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.582948545893096],
              [-70.608939789961013, -33.579206594057531],
              [-70.603753361831792, -33.579206594057531],
              [-70.601160147767203, -33.582948545893096],
              [-70.603753361831792, -33.586690335471808],
              [-70.608939789961013, -33.586690335471808],
              [-70.611533004025617, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 441,
        left: -7860439.8981405636,
        bottom: -3972438.7362903878,
        right: -7859285.1970638046,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.575464479974038],
              [-70.608939789961013, -33.571722203651518],
              [-70.603753361831792, -33.571722203651518],
              [-70.601160147767203, -33.575464479974038],
              [-70.603753361831792, -33.579206594057531],
              [-70.608939789961013, -33.579206594057531],
              [-70.611533004025617, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 442,
        left: -7860439.8981405636,
        bottom: -3971438.735824137,
        right: -7859285.1970638046,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.56797976509894],
              [-70.608939789961013, -33.56423716432522],
              [-70.603753361831792, -33.56423716432522],
              [-70.601160147767203, -33.56797976509894],
              [-70.603753361831792, -33.571722203651518],
              [-70.608939789961013, -33.571722203651518],
              [-70.611533004025617, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 443,
        left: -7860439.8981405636,
        bottom: -3970438.7353578866,
        right: -7859285.1970638046,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.560494401339298],
              [-70.608939789961013, -33.556751476150104],
              [-70.603753361831792, -33.556751476150104],
              [-70.601160147767203, -33.560494401339298],
              [-70.603753361831792, -33.56423716432522],
              [-70.608939789961013, -33.56423716432522],
              [-70.611533004025617, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 444,
        left: -7860439.8981405636,
        bottom: -3969438.7348916363,
        right: -7859285.1970638046,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.553008388766614],
              [-70.608939789961013, -33.549265139197736],
              [-70.603753361831792, -33.549265139197736],
              [-70.601160147767203, -33.553008388766614],
              [-70.603753361831792, -33.556751476150104],
              [-70.608939789961013, -33.556751476150104],
              [-70.611533004025617, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 445,
        left: -7860439.8981405636,
        bottom: -3968438.7344253859,
        right: -7859285.1970638046,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.54552172745246],
              [-70.608939789961013, -33.541778153539695],
              [-70.603753361831792, -33.541778153539695],
              [-70.601160147767203, -33.54552172745246],
              [-70.603753361831792, -33.549265139197736],
              [-70.608939789961013, -33.549265139197736],
              [-70.611533004025617, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 446,
        left: -7860439.8981405636,
        bottom: -3967438.7339591356,
        right: -7859285.1970638046,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.538034417468438],
              [-70.608939789961013, -33.534290519247627],
              [-70.603753361831792, -33.534290519247627],
              [-70.601160147767203, -33.538034417468438],
              [-70.603753361831792, -33.541778153539695],
              [-70.608939789961013, -33.541778153539695],
              [-70.611533004025617, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 447,
        left: -7860439.8981405636,
        bottom: -3966438.7334928853,
        right: -7859285.1970638046,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.530546458886235],
              [-70.608939789961013, -33.526802236393216],
              [-70.603753361831792, -33.526802236393216],
              [-70.601160147767203, -33.530546458886235],
              [-70.603753361831792, -33.534290519247627],
              [-70.608939789961013, -33.534290519247627],
              [-70.611533004025617, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 448,
        left: -7860439.8981405636,
        bottom: -3965438.7330266349,
        right: -7859285.1970638046,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.523057851777558],
              [-70.608939789961013, -33.519313305048215],
              [-70.603753361831792, -33.519313305048215],
              [-70.601160147767203, -33.523057851777558],
              [-70.603753361831792, -33.526802236393216],
              [-70.608939789961013, -33.526802236393216],
              [-70.611533004025617, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 449,
        left: -7860439.8981405636,
        bottom: -3964438.7325603846,
        right: -7859285.1970638046,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.515568596214173],
              [-70.608939789961013, -33.511823725284408],
              [-70.603753361831792, -33.511823725284408],
              [-70.601160147767203, -33.515568596214173],
              [-70.603753361831792, -33.519313305048215],
              [-70.608939789961013, -33.519313305048215],
              [-70.611533004025617, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 450,
        left: -7860439.8981405636,
        bottom: -3963438.7320941342,
        right: -7859285.1970638046,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.508078692267915],
              [-70.608939789961013, -33.504333497173661],
              [-70.603753361831792, -33.504333497173661],
              [-70.601160147767203, -33.508078692267915],
              [-70.603753361831792, -33.511823725284408],
              [-70.608939789961013, -33.511823725284408],
              [-70.611533004025617, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 451,
        left: -7860439.8981405636,
        bottom: -3962438.7316278839,
        right: -7859285.1970638046,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.500588140010635],
              [-70.608939789961013, -33.496842620787838],
              [-70.603753361831792, -33.496842620787838],
              [-70.601160147767203, -33.500588140010635],
              [-70.603753361831792, -33.504333497173661],
              [-70.608939789961013, -33.504333497173661],
              [-70.611533004025617, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 452,
        left: -7860439.8981405636,
        bottom: -3961438.7311616335,
        right: -7859285.1970638046,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.493096939514267],
              [-70.608939789961013, -33.489351096198902],
              [-70.603753361831792, -33.489351096198902],
              [-70.601160147767203, -33.493096939514267],
              [-70.603753361831792, -33.496842620787838],
              [-70.608939789961013, -33.496842620787838],
              [-70.611533004025617, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 453,
        left: -7860439.8981405636,
        bottom: -3960438.7306953832,
        right: -7859285.1970638046,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.485605090850768],
              [-70.608939789961013, -33.48185892347886],
              [-70.603753361831792, -33.48185892347886],
              [-70.601160147767203, -33.485605090850768],
              [-70.603753361831792, -33.489351096198902],
              [-70.608939789961013, -33.489351096198902],
              [-70.611533004025617, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 454,
        left: -7860439.8981405636,
        bottom: -3959438.7302291333,
        right: -7859285.1970638046,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.478112594092174],
              [-70.608939789961013, -33.474366102699733],
              [-70.603753361831792, -33.474366102699733],
              [-70.601160147767203, -33.478112594092174],
              [-70.603753361831792, -33.48185892347886],
              [-70.608939789961013, -33.48185892347886],
              [-70.611533004025617, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 455,
        left: -7860439.8981405636,
        bottom: -3958438.7297628829,
        right: -7859285.1970638046,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.470619449310547],
              [-70.608939789961013, -33.466872633933626],
              [-70.603753361831792, -33.466872633933626],
              [-70.601160147767203, -33.470619449310547],
              [-70.603753361831792, -33.474366102699733],
              [-70.608939789961013, -33.474366102699733],
              [-70.611533004025617, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 456,
        left: -7860439.8981405636,
        bottom: -3957438.7292966326,
        right: -7859285.1970638046,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.463125656578008],
              [-70.608939789961013, -33.459378517252702],
              [-70.603753361831792, -33.459378517252702],
              [-70.601160147767203, -33.463125656578008],
              [-70.603753361831792, -33.466872633933626],
              [-70.608939789961013, -33.466872633933626],
              [-70.611533004025617, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 457,
        left: -7860439.8981405636,
        bottom: -3956438.7288303822,
        right: -7859285.1970638046,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.45563121596674],
              [-70.608939789961013, -33.451883752729138],
              [-70.603753361831792, -33.451883752729138],
              [-70.601160147767203, -33.45563121596674],
              [-70.603753361831792, -33.459378517252702],
              [-70.608939789961013, -33.459378517252702],
              [-70.611533004025617, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 458,
        left: -7860439.8981405636,
        bottom: -3955438.7283641323,
        right: -7859285.1970638046,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.448136127548949],
              [-70.608939789961013, -33.444388340435196],
              [-70.603753361831792, -33.444388340435196],
              [-70.601160147767203, -33.448136127548949],
              [-70.603753361831792, -33.451883752729138],
              [-70.608939789961013, -33.451883752729138],
              [-70.611533004025617, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 459,
        left: -7860439.8981405636,
        bottom: -3954438.7278978815,
        right: -7859285.1970638046,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.440640391396926],
              [-70.608939789961013, -33.436892280443168],
              [-70.603753361831792, -33.436892280443168],
              [-70.601160147767203, -33.440640391396926],
              [-70.603753361831792, -33.444388340435196],
              [-70.608939789961013, -33.444388340435196],
              [-70.611533004025617, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 460,
        left: -7860439.8981405636,
        bottom: -3953438.7274316312,
        right: -7859285.1970638046,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.433144007582989],
              [-70.608939789961013, -33.429395572825413],
              [-70.603753361831792, -33.429395572825413],
              [-70.601160147767203, -33.433144007582989],
              [-70.603753361831792, -33.436892280443168],
              [-70.608939789961013, -33.436892280443168],
              [-70.611533004025617, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 461,
        left: -7860439.8981405636,
        bottom: -3952438.7269653808,
        right: -7859285.1970638046,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.425646976179515],
              [-70.608939789961013, -33.421898217654316],
              [-70.603753361831792, -33.421898217654316],
              [-70.601160147767203, -33.425646976179515],
              [-70.603753361831792, -33.429395572825413],
              [-70.608939789961013, -33.429395572825413],
              [-70.611533004025617, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 462,
        left: -7860439.8981405636,
        bottom: -3951438.7264991305,
        right: -7859285.1970638046,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.418149297258921],
              [-70.608939789961013, -33.414400215002352],
              [-70.603753361831792, -33.414400215002352],
              [-70.601160147767203, -33.418149297258921],
              [-70.603753361831792, -33.421898217654316],
              [-70.608939789961013, -33.421898217654316],
              [-70.611533004025617, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 463,
        left: -7860439.8981405636,
        bottom: -3950438.7260328801,
        right: -7859285.1970638046,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.410650970893691],
              [-70.608939789961013, -33.406901564941997],
              [-70.603753361831792, -33.406901564941997],
              [-70.601160147767203, -33.410650970893691],
              [-70.603753361831792, -33.414400215002352],
              [-70.608939789961013, -33.414400215002352],
              [-70.611533004025617, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 464,
        left: -7860439.8981405636,
        bottom: -3949438.7255666298,
        right: -7859285.1970638046,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.40315199715635],
              [-70.608939789961013, -33.399402267545824],
              [-70.603753361831792, -33.399402267545824],
              [-70.601160147767203, -33.40315199715635],
              [-70.603753361831792, -33.406901564941997],
              [-70.608939789961013, -33.406901564941997],
              [-70.611533004025617, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 465,
        left: -7860439.8981405636,
        bottom: -3948438.7251003794,
        right: -7859285.1970638046,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.395652376119486],
              [-70.608939789961013, -33.391902322886416],
              [-70.603753361831792, -33.391902322886416],
              [-70.601160147767203, -33.395652376119486],
              [-70.603753361831792, -33.399402267545824],
              [-70.608939789961013, -33.399402267545824],
              [-70.611533004025617, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 466,
        left: -7860439.8981405636,
        bottom: -3947438.7246341291,
        right: -7859285.1970638046,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.388152107855717],
              [-70.608939789961013, -33.384401731036441],
              [-70.603753361831792, -33.384401731036441],
              [-70.601160147767203, -33.388152107855717],
              [-70.603753361831792, -33.391902322886416],
              [-70.608939789961013, -33.391902322886416],
              [-70.611533004025617, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 467,
        left: -7860439.8981405636,
        bottom: -3946438.7241678787,
        right: -7859285.1970638046,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.380651192437711],
              [-70.608939789961013, -33.3769004920686],
              [-70.603753361831792, -33.3769004920686],
              [-70.601160147767203, -33.380651192437711],
              [-70.603753361831792, -33.384401731036441],
              [-70.608939789961013, -33.384401731036441],
              [-70.611533004025617, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 468,
        left: -7860439.8981405636,
        bottom: -3945438.7237016284,
        right: -7859285.1970638046,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.373149629938226],
              [-70.608939789961013, -33.369398606055647],
              [-70.603753361831792, -33.369398606055647],
              [-70.601160147767203, -33.373149629938226],
              [-70.603753361831792, -33.3769004920686],
              [-70.608939789961013, -33.3769004920686],
              [-70.611533004025617, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 469,
        left: -7860439.8981405636,
        bottom: -3944438.723235378,
        right: -7859285.1970638046,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.365647420430008],
              [-70.608939789961013, -33.36189607307039],
              [-70.603753361831792, -33.36189607307039],
              [-70.601160147767203, -33.365647420430008],
              [-70.603753361831792, -33.369398606055647],
              [-70.608939789961013, -33.369398606055647],
              [-70.611533004025617, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 470,
        left: -7860439.8981405636,
        bottom: -3943438.7227691277,
        right: -7859285.1970638046,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.358144563985917],
              [-70.608939789961013, -33.35439289318569],
              [-70.603753361831792, -33.35439289318569],
              [-70.601160147767203, -33.358144563985917],
              [-70.603753361831792, -33.36189607307039],
              [-70.608939789961013, -33.36189607307039],
              [-70.611533004025617, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 471,
        left: -7860439.8981405636,
        bottom: -3942438.7223028769,
        right: -7859285.1970638046,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.350641060678818],
              [-70.608939789961013, -33.346889066474425],
              [-70.603753361831792, -33.346889066474425],
              [-70.601160147767203, -33.350641060678818],
              [-70.603753361831792, -33.35439289318569],
              [-70.608939789961013, -33.35439289318569],
              [-70.611533004025617, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 472,
        left: -7860439.8981405636,
        bottom: -3941438.721836627,
        right: -7859285.1970638046,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.343136910581649],
              [-70.608939789961013, -33.339384593009591],
              [-70.603753361831792, -33.339384593009591],
              [-70.601160147767203, -33.343136910581649],
              [-70.603753361831792, -33.346889066474425],
              [-70.608939789961013, -33.346889066474425],
              [-70.611533004025617, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 473,
        left: -7860439.8981405636,
        bottom: -3940438.7213703771,
        right: -7859285.1970638046,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.335632113767389],
              [-70.608939789961013, -33.331879472864181],
              [-70.603753361831792, -33.331879472864181],
              [-70.601160147767203, -33.335632113767389],
              [-70.603753361831792, -33.339384593009591],
              [-70.608939789961013, -33.339384593009591],
              [-70.611533004025617, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 474,
        left: -7860439.8981405636,
        bottom: -3939438.7209041263,
        right: -7859285.1970638046,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.328126670309082],
              [-70.608939789961013, -33.324373706111238],
              [-70.603753361831792, -33.324373706111238],
              [-70.601160147767203, -33.328126670309082],
              [-70.603753361831792, -33.331879472864181],
              [-70.608939789961013, -33.331879472864181],
              [-70.611533004025617, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 475,
        left: -7860439.8981405636,
        bottom: -3938438.7204378759,
        right: -7859285.1970638046,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.320620580279794],
              [-70.608939789961013, -33.3168672928239],
              [-70.603753361831792, -33.3168672928239],
              [-70.601160147767203, -33.320620580279794],
              [-70.603753361831792, -33.324373706111238],
              [-70.608939789961013, -33.324373706111238],
              [-70.611533004025617, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 476,
        left: -7860439.8981405636,
        bottom: -3937438.7199716261,
        right: -7859285.1970638046,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.313113843752674],
              [-70.608939789961013, -33.309360233075296],
              [-70.603753361831792, -33.309360233075296],
              [-70.601160147767203, -33.313113843752674],
              [-70.603753361831792, -33.3168672928239],
              [-70.608939789961013, -33.3168672928239],
              [-70.611533004025617, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 477,
        left: -7860439.8981405636,
        bottom: -3936438.7195053762,
        right: -7859285.1970638046,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.305606460800909],
              [-70.608939789961013, -33.30185252693866],
              [-70.603753361831792, -33.30185252693866],
              [-70.601160147767203, -33.305606460800909],
              [-70.603753361831792, -33.309360233075296],
              [-70.608939789961013, -33.309360233075296],
              [-70.611533004025617, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 478,
        left: -7860439.8981405636,
        bottom: -3935438.7190391254,
        right: -7859285.1970638046,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.298098431497721],
              [-70.608939789961013, -33.294344174487243],
              [-70.603753361831792, -33.294344174487243],
              [-70.601160147767203, -33.298098431497721],
              [-70.603753361831792, -33.30185252693866],
              [-70.608939789961013, -33.30185252693866],
              [-70.611533004025617, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 479,
        left: -7860439.8981405636,
        bottom: -3934438.718572875,
        right: -7859285.1970638046,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.290589755916407],
              [-70.608939789961013, -33.286835175794366],
              [-70.603753361831792, -33.286835175794366],
              [-70.601160147767203, -33.290589755916407],
              [-70.603753361831792, -33.294344174487243],
              [-70.608939789961013, -33.294344174487243],
              [-70.611533004025617, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 480,
        left: -7860439.8981405636,
        bottom: -3933438.7181066247,
        right: -7859285.1970638046,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.611533004025617, -33.283080434130305],
              [-70.608939789961013, -33.279325530933384],
              [-70.603753361831792, -33.279325530933384],
              [-70.601160147767203, -33.283080434130305],
              [-70.603753361831792, -33.286835175794366],
              [-70.608939789961013, -33.286835175794366],
              [-70.611533004025617, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 481,
        left: -7859573.8723329939,
        bottom: -3980938.7402535151,
        right: -7858419.1712562358,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.639058347612803],
              [-70.601160147767203, -33.63531883056551],
              [-70.595973719637996, -33.63531883056551],
              [-70.593380505573393, -33.639058347612803],
              [-70.595973719637996, -33.642797702269725],
              [-70.601160147767203, -33.642797702269725],
              [-70.603753361831792, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 482,
        left: -7859573.8723329939,
        bottom: -3979938.7397872647,
        right: -7858419.1712562358,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.631579151136748],
              [-70.601160147767203, -33.627839309335386],
              [-70.595973719637996, -33.627839309335386],
              [-70.593380505573393, -33.631579151136748],
              [-70.595973719637996, -33.63531883056551],
              [-70.601160147767203, -33.63531883056551],
              [-70.603753361831792, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 483,
        left: -7859573.8723329939,
        bottom: -3978938.7393210144,
        right: -7858419.1712562358,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.624099305170297],
              [-70.601160147767203, -33.620359138650379],
              [-70.595973719637996, -33.620359138650379],
              [-70.593380505573393, -33.624099305170297],
              [-70.595973719637996, -33.627839309335386],
              [-70.601160147767203, -33.627839309335386],
              [-70.603753361831792, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 484,
        left: -7859573.8723329939,
        bottom: -3977938.738854764,
        right: -7858419.1712562358,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.616618809784534],
              [-70.601160147767203, -33.612878318581629],
              [-70.595973719637996, -33.612878318581629],
              [-70.593380505573393, -33.616618809784534],
              [-70.595973719637996, -33.620359138650379],
              [-70.601160147767203, -33.620359138650379],
              [-70.603753361831792, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 485,
        left: -7859573.8723329939,
        bottom: -3976938.7383885137,
        right: -7858419.1712562358,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.609137665050582],
              [-70.601160147767203, -33.605396849200282],
              [-70.595973719637996, -33.605396849200282],
              [-70.593380505573393, -33.609137665050582],
              [-70.595973719637996, -33.612878318581629],
              [-70.601160147767203, -33.612878318581629],
              [-70.603753361831792, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 486,
        left: -7859573.8723329939,
        bottom: -3975938.7379222633,
        right: -7858419.1712562358,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.601655871039647],
              [-70.601160147767203, -33.597914730577571],
              [-70.595973719637996, -33.597914730577571],
              [-70.593380505573393, -33.601655871039647],
              [-70.595973719637996, -33.605396849200282],
              [-70.601160147767203, -33.605396849200282],
              [-70.603753361831792, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 487,
        left: -7859573.8723329939,
        bottom: -3974938.737456013,
        right: -7858419.1712562358,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.594173427822959],
              [-70.601160147767203, -33.590431962784734],
              [-70.595973719637996, -33.590431962784734],
              [-70.593380505573393, -33.594173427822959],
              [-70.595973719637996, -33.597914730577571],
              [-70.601160147767203, -33.597914730577571],
              [-70.603753361831792, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 488,
        left: -7859573.8723329939,
        bottom: -3973938.7369897626,
        right: -7858419.1712562358,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.586690335471808],
              [-70.601160147767203, -33.582948545893096],
              [-70.595973719637996, -33.582948545893096],
              [-70.593380505573393, -33.586690335471808],
              [-70.595973719637996, -33.590431962784734],
              [-70.601160147767203, -33.590431962784734],
              [-70.603753361831792, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 489,
        left: -7859573.8723329939,
        bottom: -3972938.7365235123,
        right: -7858419.1712562358,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.579206594057531],
              [-70.601160147767203, -33.575464479974038],
              [-70.595973719637996, -33.575464479974038],
              [-70.593380505573393, -33.579206594057531],
              [-70.595973719637996, -33.582948545893096],
              [-70.601160147767203, -33.582948545893096],
              [-70.603753361831792, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 490,
        left: -7859573.8723329939,
        bottom: -3971938.7360572619,
        right: -7858419.1712562358,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.571722203651518],
              [-70.601160147767203, -33.56797976509894],
              [-70.595973719637996, -33.56797976509894],
              [-70.593380505573393, -33.571722203651518],
              [-70.595973719637996, -33.575464479974038],
              [-70.601160147767203, -33.575464479974038],
              [-70.603753361831792, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 491,
        left: -7859573.8723329939,
        bottom: -3970938.7355910116,
        right: -7858419.1712562358,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.56423716432522],
              [-70.601160147767203, -33.560494401339298],
              [-70.595973719637996, -33.560494401339298],
              [-70.593380505573393, -33.56423716432522],
              [-70.595973719637996, -33.56797976509894],
              [-70.601160147767203, -33.56797976509894],
              [-70.603753361831792, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 492,
        left: -7859573.8723329939,
        bottom: -3969938.7351247617,
        right: -7858419.1712562358,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.556751476150104],
              [-70.601160147767203, -33.553008388766614],
              [-70.595973719637996, -33.553008388766614],
              [-70.593380505573393, -33.556751476150104],
              [-70.595973719637996, -33.560494401339298],
              [-70.601160147767203, -33.560494401339298],
              [-70.603753361831792, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 493,
        left: -7859573.8723329939,
        bottom: -3968938.7346585114,
        right: -7858419.1712562358,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.549265139197736],
              [-70.601160147767203, -33.54552172745246],
              [-70.595973719637996, -33.54552172745246],
              [-70.593380505573393, -33.549265139197736],
              [-70.595973719637996, -33.553008388766614],
              [-70.601160147767203, -33.553008388766614],
              [-70.603753361831792, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 494,
        left: -7859573.8723329939,
        bottom: -3967938.734192261,
        right: -7858419.1712562358,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.541778153539695],
              [-70.601160147767203, -33.538034417468438],
              [-70.595973719637996, -33.538034417468438],
              [-70.593380505573393, -33.541778153539695],
              [-70.595973719637996, -33.54552172745246],
              [-70.601160147767203, -33.54552172745246],
              [-70.603753361831792, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 495,
        left: -7859573.8723329939,
        bottom: -3966938.7337260107,
        right: -7858419.1712562358,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.534290519247627],
              [-70.601160147767203, -33.530546458886235],
              [-70.595973719637996, -33.530546458886235],
              [-70.593380505573393, -33.534290519247627],
              [-70.595973719637996, -33.538034417468438],
              [-70.601160147767203, -33.538034417468438],
              [-70.603753361831792, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 496,
        left: -7859573.8723329939,
        bottom: -3965938.7332597603,
        right: -7858419.1712562358,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.526802236393216],
              [-70.601160147767203, -33.523057851777558],
              [-70.595973719637996, -33.523057851777558],
              [-70.593380505573393, -33.526802236393216],
              [-70.595973719637996, -33.530546458886235],
              [-70.601160147767203, -33.530546458886235],
              [-70.603753361831792, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 497,
        left: -7859573.8723329939,
        bottom: -3964938.7327935095,
        right: -7858419.1712562358,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.519313305048215],
              [-70.601160147767203, -33.515568596214173],
              [-70.595973719637996, -33.515568596214173],
              [-70.593380505573393, -33.519313305048215],
              [-70.595973719637996, -33.523057851777558],
              [-70.601160147767203, -33.523057851777558],
              [-70.603753361831792, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 498,
        left: -7859573.8723329939,
        bottom: -3963938.7323272596,
        right: -7858419.1712562358,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.511823725284408],
              [-70.601160147767203, -33.508078692267915],
              [-70.595973719637996, -33.508078692267915],
              [-70.593380505573393, -33.511823725284408],
              [-70.595973719637996, -33.515568596214173],
              [-70.601160147767203, -33.515568596214173],
              [-70.603753361831792, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 499,
        left: -7859573.8723329939,
        bottom: -3962938.7318610097,
        right: -7858419.1712562358,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.504333497173661],
              [-70.601160147767203, -33.500588140010635],
              [-70.595973719637996, -33.500588140010635],
              [-70.593380505573393, -33.504333497173661],
              [-70.595973719637996, -33.508078692267915],
              [-70.601160147767203, -33.508078692267915],
              [-70.603753361831792, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 500,
        left: -7859573.8723329939,
        bottom: -3961938.7313947589,
        right: -7858419.1712562358,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.496842620787838],
              [-70.601160147767203, -33.493096939514267],
              [-70.595973719637996, -33.493096939514267],
              [-70.593380505573393, -33.496842620787838],
              [-70.595973719637996, -33.500588140010635],
              [-70.601160147767203, -33.500588140010635],
              [-70.603753361831792, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 501,
        left: -7859573.8723329939,
        bottom: -3960938.7309285086,
        right: -7858419.1712562358,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.489351096198902],
              [-70.601160147767203, -33.485605090850768],
              [-70.595973719637996, -33.485605090850768],
              [-70.593380505573393, -33.489351096198902],
              [-70.595973719637996, -33.493096939514267],
              [-70.601160147767203, -33.493096939514267],
              [-70.603753361831792, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 502,
        left: -7859573.8723329939,
        bottom: -3959938.7304622582,
        right: -7858419.1712562358,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.48185892347886],
              [-70.601160147767203, -33.478112594092174],
              [-70.595973719637996, -33.478112594092174],
              [-70.593380505573393, -33.48185892347886],
              [-70.595973719637996, -33.485605090850768],
              [-70.601160147767203, -33.485605090850768],
              [-70.603753361831792, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 503,
        left: -7859573.8723329939,
        bottom: -3958938.7299960079,
        right: -7858419.1712562358,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.474366102699733],
              [-70.601160147767203, -33.470619449310547],
              [-70.595973719637996, -33.470619449310547],
              [-70.593380505573393, -33.474366102699733],
              [-70.595973719637996, -33.478112594092174],
              [-70.601160147767203, -33.478112594092174],
              [-70.603753361831792, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 504,
        left: -7859573.8723329939,
        bottom: -3957938.7295297575,
        right: -7858419.1712562358,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.466872633933626],
              [-70.601160147767203, -33.463125656578008],
              [-70.595973719637996, -33.463125656578008],
              [-70.593380505573393, -33.466872633933626],
              [-70.595973719637996, -33.470619449310547],
              [-70.601160147767203, -33.470619449310547],
              [-70.603753361831792, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 505,
        left: -7859573.8723329939,
        bottom: -3956938.7290635072,
        right: -7858419.1712562358,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.459378517252702],
              [-70.601160147767203, -33.45563121596674],
              [-70.595973719637996, -33.45563121596674],
              [-70.593380505573393, -33.459378517252702],
              [-70.595973719637996, -33.463125656578008],
              [-70.601160147767203, -33.463125656578008],
              [-70.603753361831792, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 506,
        left: -7859573.8723329939,
        bottom: -3955938.7285972568,
        right: -7858419.1712562358,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.451883752729138],
              [-70.601160147767203, -33.448136127548949],
              [-70.595973719637996, -33.448136127548949],
              [-70.593380505573393, -33.451883752729138],
              [-70.595973719637996, -33.45563121596674],
              [-70.601160147767203, -33.45563121596674],
              [-70.603753361831792, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 507,
        left: -7859573.8723329939,
        bottom: -3954938.7281310065,
        right: -7858419.1712562358,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.444388340435196],
              [-70.601160147767203, -33.440640391396926],
              [-70.595973719637996, -33.440640391396926],
              [-70.593380505573393, -33.444388340435196],
              [-70.595973719637996, -33.448136127548949],
              [-70.601160147767203, -33.448136127548949],
              [-70.603753361831792, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 508,
        left: -7859573.8723329939,
        bottom: -3953938.7276647561,
        right: -7858419.1712562358,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.436892280443168],
              [-70.601160147767203, -33.433144007582989],
              [-70.595973719637996, -33.433144007582989],
              [-70.593380505573393, -33.436892280443168],
              [-70.595973719637996, -33.440640391396926],
              [-70.601160147767203, -33.440640391396926],
              [-70.603753361831792, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 509,
        left: -7859573.8723329939,
        bottom: -3952938.7271985058,
        right: -7858419.1712562358,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.429395572825413],
              [-70.601160147767203, -33.425646976179515],
              [-70.595973719637996, -33.425646976179515],
              [-70.593380505573393, -33.429395572825413],
              [-70.595973719637996, -33.433144007582989],
              [-70.601160147767203, -33.433144007582989],
              [-70.603753361831792, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 510,
        left: -7859573.8723329939,
        bottom: -3951938.7267322554,
        right: -7858419.1712562358,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.421898217654316],
              [-70.601160147767203, -33.418149297258921],
              [-70.595973719637996, -33.418149297258921],
              [-70.593380505573393, -33.421898217654316],
              [-70.595973719637996, -33.425646976179515],
              [-70.601160147767203, -33.425646976179515],
              [-70.603753361831792, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 511,
        left: -7859573.8723329939,
        bottom: -3950938.7262660051,
        right: -7858419.1712562358,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.414400215002352],
              [-70.601160147767203, -33.410650970893691],
              [-70.595973719637996, -33.410650970893691],
              [-70.593380505573393, -33.414400215002352],
              [-70.595973719637996, -33.418149297258921],
              [-70.601160147767203, -33.418149297258921],
              [-70.603753361831792, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 512,
        left: -7859573.8723329939,
        bottom: -3949938.7257997552,
        right: -7858419.1712562358,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.406901564941997],
              [-70.601160147767203, -33.40315199715635],
              [-70.595973719637996, -33.40315199715635],
              [-70.593380505573393, -33.406901564941997],
              [-70.595973719637996, -33.410650970893691],
              [-70.601160147767203, -33.410650970893691],
              [-70.603753361831792, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 513,
        left: -7859573.8723329939,
        bottom: -3948938.7253335048,
        right: -7858419.1712562358,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.399402267545824],
              [-70.601160147767203, -33.395652376119486],
              [-70.595973719637996, -33.395652376119486],
              [-70.593380505573393, -33.399402267545824],
              [-70.595973719637996, -33.40315199715635],
              [-70.601160147767203, -33.40315199715635],
              [-70.603753361831792, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 514,
        left: -7859573.8723329939,
        bottom: -3947938.724867254,
        right: -7858419.1712562358,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.391902322886416],
              [-70.601160147767203, -33.388152107855717],
              [-70.595973719637996, -33.388152107855717],
              [-70.593380505573393, -33.391902322886416],
              [-70.595973719637996, -33.395652376119486],
              [-70.601160147767203, -33.395652376119486],
              [-70.603753361831792, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 515,
        left: -7859573.8723329939,
        bottom: -3946938.7244010041,
        right: -7858419.1712562358,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.384401731036441],
              [-70.601160147767203, -33.380651192437711],
              [-70.595973719637996, -33.380651192437711],
              [-70.593380505573393, -33.384401731036441],
              [-70.595973719637996, -33.388152107855717],
              [-70.601160147767203, -33.388152107855717],
              [-70.603753361831792, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 516,
        left: -7859573.8723329939,
        bottom: -3945938.7239347543,
        right: -7858419.1712562358,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.3769004920686],
              [-70.601160147767203, -33.373149629938226],
              [-70.595973719637996, -33.373149629938226],
              [-70.593380505573393, -33.3769004920686],
              [-70.595973719637996, -33.380651192437711],
              [-70.601160147767203, -33.380651192437711],
              [-70.603753361831792, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 517,
        left: -7859573.8723329939,
        bottom: -3944938.7234685034,
        right: -7858419.1712562358,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.369398606055647],
              [-70.601160147767203, -33.365647420430008],
              [-70.595973719637996, -33.365647420430008],
              [-70.593380505573393, -33.369398606055647],
              [-70.595973719637996, -33.373149629938226],
              [-70.601160147767203, -33.373149629938226],
              [-70.603753361831792, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 518,
        left: -7859573.8723329939,
        bottom: -3943938.7230022531,
        right: -7858419.1712562358,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.36189607307039],
              [-70.601160147767203, -33.358144563985917],
              [-70.595973719637996, -33.358144563985917],
              [-70.593380505573393, -33.36189607307039],
              [-70.595973719637996, -33.365647420430008],
              [-70.601160147767203, -33.365647420430008],
              [-70.603753361831792, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 519,
        left: -7859573.8723329939,
        bottom: -3942938.7225360028,
        right: -7858419.1712562358,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.35439289318569],
              [-70.601160147767203, -33.350641060678818],
              [-70.595973719637996, -33.350641060678818],
              [-70.593380505573393, -33.35439289318569],
              [-70.595973719637996, -33.358144563985917],
              [-70.601160147767203, -33.358144563985917],
              [-70.603753361831792, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 520,
        left: -7859573.8723329939,
        bottom: -3941938.7220697524,
        right: -7858419.1712562358,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.346889066474425],
              [-70.601160147767203, -33.343136910581649],
              [-70.595973719637996, -33.343136910581649],
              [-70.593380505573393, -33.346889066474425],
              [-70.595973719637996, -33.350641060678818],
              [-70.601160147767203, -33.350641060678818],
              [-70.603753361831792, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 521,
        left: -7859573.8723329939,
        bottom: -3940938.7216035021,
        right: -7858419.1712562358,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.339384593009591],
              [-70.601160147767203, -33.335632113767389],
              [-70.595973719637996, -33.335632113767389],
              [-70.593380505573393, -33.339384593009591],
              [-70.595973719637996, -33.343136910581649],
              [-70.601160147767203, -33.343136910581649],
              [-70.603753361831792, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 522,
        left: -7859573.8723329939,
        bottom: -3939938.7211372517,
        right: -7858419.1712562358,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.331879472864181],
              [-70.601160147767203, -33.328126670309082],
              [-70.595973719637996, -33.328126670309082],
              [-70.593380505573393, -33.331879472864181],
              [-70.595973719637996, -33.335632113767389],
              [-70.601160147767203, -33.335632113767389],
              [-70.603753361831792, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 523,
        left: -7859573.8723329939,
        bottom: -3938938.7206710014,
        right: -7858419.1712562358,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.324373706111238],
              [-70.601160147767203, -33.320620580279794],
              [-70.595973719637996, -33.320620580279794],
              [-70.593380505573393, -33.324373706111238],
              [-70.595973719637996, -33.328126670309082],
              [-70.601160147767203, -33.328126670309082],
              [-70.603753361831792, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 524,
        left: -7859573.8723329939,
        bottom: -3937938.720204751,
        right: -7858419.1712562358,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.3168672928239],
              [-70.601160147767203, -33.313113843752674],
              [-70.595973719637996, -33.313113843752674],
              [-70.593380505573393, -33.3168672928239],
              [-70.595973719637996, -33.320620580279794],
              [-70.601160147767203, -33.320620580279794],
              [-70.603753361831792, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 525,
        left: -7859573.8723329939,
        bottom: -3936938.7197385007,
        right: -7858419.1712562358,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.309360233075296],
              [-70.601160147767203, -33.305606460800909],
              [-70.595973719637996, -33.305606460800909],
              [-70.593380505573393, -33.309360233075296],
              [-70.595973719637996, -33.313113843752674],
              [-70.601160147767203, -33.313113843752674],
              [-70.603753361831792, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 526,
        left: -7859573.8723329939,
        bottom: -3935938.7192722503,
        right: -7858419.1712562358,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.30185252693866],
              [-70.601160147767203, -33.298098431497721],
              [-70.595973719637996, -33.298098431497721],
              [-70.593380505573393, -33.30185252693866],
              [-70.595973719637996, -33.305606460800909],
              [-70.601160147767203, -33.305606460800909],
              [-70.603753361831792, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 527,
        left: -7859573.8723329939,
        bottom: -3934938.718806,
        right: -7858419.1712562358,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.294344174487243],
              [-70.601160147767203, -33.290589755916407],
              [-70.595973719637996, -33.290589755916407],
              [-70.593380505573393, -33.294344174487243],
              [-70.595973719637996, -33.298098431497721],
              [-70.601160147767203, -33.298098431497721],
              [-70.603753361831792, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 528,
        left: -7859573.8723329939,
        bottom: -3933938.7183397496,
        right: -7858419.1712562358,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.603753361831792, -33.286835175794366],
              [-70.601160147767203, -33.283080434130305],
              [-70.595973719637996, -33.283080434130305],
              [-70.593380505573393, -33.286835175794366],
              [-70.595973719637996, -33.290589755916407],
              [-70.601160147767203, -33.290589755916407],
              [-70.603753361831792, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 529,
        left: -7858707.8465254251,
        bottom: -3980438.7400203901,
        right: -7857553.145448667,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.63531883056551],
              [-70.593380505573393, -33.631579151136748],
              [-70.588194077444186, -33.631579151136748],
              [-70.585600863379582, -33.63531883056551],
              [-70.588194077444186, -33.639058347612803],
              [-70.593380505573393, -33.639058347612803],
              [-70.595973719637996, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 530,
        left: -7858707.8465254251,
        bottom: -3979438.7395541398,
        right: -7857553.145448667,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.627839309335386],
              [-70.593380505573393, -33.624099305170297],
              [-70.588194077444186, -33.624099305170297],
              [-70.585600863379582, -33.627839309335386],
              [-70.588194077444186, -33.631579151136748],
              [-70.593380505573393, -33.631579151136748],
              [-70.595973719637996, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 531,
        left: -7858707.8465254251,
        bottom: -3978438.7390878894,
        right: -7857553.145448667,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.620359138650379],
              [-70.593380505573393, -33.616618809784534],
              [-70.588194077444186, -33.616618809784534],
              [-70.585600863379582, -33.620359138650379],
              [-70.588194077444186, -33.624099305170297],
              [-70.593380505573393, -33.624099305170297],
              [-70.595973719637996, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 532,
        left: -7858707.8465254251,
        bottom: -3977438.7386216391,
        right: -7857553.145448667,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.612878318581629],
              [-70.593380505573393, -33.609137665050582],
              [-70.588194077444186, -33.609137665050582],
              [-70.585600863379582, -33.612878318581629],
              [-70.588194077444186, -33.616618809784534],
              [-70.593380505573393, -33.616618809784534],
              [-70.595973719637996, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 533,
        left: -7858707.8465254251,
        bottom: -3976438.7381553887,
        right: -7857553.145448667,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.605396849200282],
              [-70.593380505573393, -33.601655871039647],
              [-70.588194077444186, -33.601655871039647],
              [-70.585600863379582, -33.605396849200282],
              [-70.588194077444186, -33.609137665050582],
              [-70.593380505573393, -33.609137665050582],
              [-70.595973719637996, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 534,
        left: -7858707.8465254251,
        bottom: -3975438.7376891384,
        right: -7857553.145448667,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.597914730577571],
              [-70.593380505573393, -33.594173427822959],
              [-70.588194077444186, -33.594173427822959],
              [-70.585600863379582, -33.597914730577571],
              [-70.588194077444186, -33.601655871039647],
              [-70.593380505573393, -33.601655871039647],
              [-70.595973719637996, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 535,
        left: -7858707.8465254251,
        bottom: -3974438.7372228876,
        right: -7857553.145448667,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.590431962784734],
              [-70.593380505573393, -33.586690335471808],
              [-70.588194077444186, -33.586690335471808],
              [-70.585600863379582, -33.590431962784734],
              [-70.588194077444186, -33.594173427822959],
              [-70.593380505573393, -33.594173427822959],
              [-70.595973719637996, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 536,
        left: -7858707.8465254251,
        bottom: -3973438.7367566377,
        right: -7857553.145448667,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.582948545893096],
              [-70.593380505573393, -33.579206594057531],
              [-70.588194077444186, -33.579206594057531],
              [-70.585600863379582, -33.582948545893096],
              [-70.588194077444186, -33.586690335471808],
              [-70.593380505573393, -33.586690335471808],
              [-70.595973719637996, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 537,
        left: -7858707.8465254251,
        bottom: -3972438.7362903878,
        right: -7857553.145448667,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.575464479974038],
              [-70.593380505573393, -33.571722203651518],
              [-70.588194077444186, -33.571722203651518],
              [-70.585600863379582, -33.575464479974038],
              [-70.588194077444186, -33.579206594057531],
              [-70.593380505573393, -33.579206594057531],
              [-70.595973719637996, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 538,
        left: -7858707.8465254251,
        bottom: -3971438.735824137,
        right: -7857553.145448667,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.56797976509894],
              [-70.593380505573393, -33.56423716432522],
              [-70.588194077444186, -33.56423716432522],
              [-70.585600863379582, -33.56797976509894],
              [-70.588194077444186, -33.571722203651518],
              [-70.593380505573393, -33.571722203651518],
              [-70.595973719637996, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 539,
        left: -7858707.8465254251,
        bottom: -3970438.7353578866,
        right: -7857553.145448667,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.560494401339298],
              [-70.593380505573393, -33.556751476150104],
              [-70.588194077444186, -33.556751476150104],
              [-70.585600863379582, -33.560494401339298],
              [-70.588194077444186, -33.56423716432522],
              [-70.593380505573393, -33.56423716432522],
              [-70.595973719637996, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 540,
        left: -7858707.8465254251,
        bottom: -3969438.7348916363,
        right: -7857553.145448667,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.553008388766614],
              [-70.593380505573393, -33.549265139197736],
              [-70.588194077444186, -33.549265139197736],
              [-70.585600863379582, -33.553008388766614],
              [-70.588194077444186, -33.556751476150104],
              [-70.593380505573393, -33.556751476150104],
              [-70.595973719637996, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 541,
        left: -7858707.8465254251,
        bottom: -3968438.7344253859,
        right: -7857553.145448667,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.54552172745246],
              [-70.593380505573393, -33.541778153539695],
              [-70.588194077444186, -33.541778153539695],
              [-70.585600863379582, -33.54552172745246],
              [-70.588194077444186, -33.549265139197736],
              [-70.593380505573393, -33.549265139197736],
              [-70.595973719637996, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 542,
        left: -7858707.8465254251,
        bottom: -3967438.7339591356,
        right: -7857553.145448667,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.538034417468438],
              [-70.593380505573393, -33.534290519247627],
              [-70.588194077444186, -33.534290519247627],
              [-70.585600863379582, -33.538034417468438],
              [-70.588194077444186, -33.541778153539695],
              [-70.593380505573393, -33.541778153539695],
              [-70.595973719637996, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 543,
        left: -7858707.8465254251,
        bottom: -3966438.7334928853,
        right: -7857553.145448667,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.530546458886235],
              [-70.593380505573393, -33.526802236393216],
              [-70.588194077444186, -33.526802236393216],
              [-70.585600863379582, -33.530546458886235],
              [-70.588194077444186, -33.534290519247627],
              [-70.593380505573393, -33.534290519247627],
              [-70.595973719637996, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 544,
        left: -7858707.8465254251,
        bottom: -3965438.7330266349,
        right: -7857553.145448667,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.523057851777558],
              [-70.593380505573393, -33.519313305048215],
              [-70.588194077444186, -33.519313305048215],
              [-70.585600863379582, -33.523057851777558],
              [-70.588194077444186, -33.526802236393216],
              [-70.593380505573393, -33.526802236393216],
              [-70.595973719637996, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 545,
        left: -7858707.8465254251,
        bottom: -3964438.7325603846,
        right: -7857553.145448667,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.515568596214173],
              [-70.593380505573393, -33.511823725284408],
              [-70.588194077444186, -33.511823725284408],
              [-70.585600863379582, -33.515568596214173],
              [-70.588194077444186, -33.519313305048215],
              [-70.593380505573393, -33.519313305048215],
              [-70.595973719637996, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 546,
        left: -7858707.8465254251,
        bottom: -3963438.7320941342,
        right: -7857553.145448667,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.508078692267915],
              [-70.593380505573393, -33.504333497173661],
              [-70.588194077444186, -33.504333497173661],
              [-70.585600863379582, -33.508078692267915],
              [-70.588194077444186, -33.511823725284408],
              [-70.593380505573393, -33.511823725284408],
              [-70.595973719637996, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 547,
        left: -7858707.8465254251,
        bottom: -3962438.7316278839,
        right: -7857553.145448667,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.500588140010635],
              [-70.593380505573393, -33.496842620787838],
              [-70.588194077444186, -33.496842620787838],
              [-70.585600863379582, -33.500588140010635],
              [-70.588194077444186, -33.504333497173661],
              [-70.593380505573393, -33.504333497173661],
              [-70.595973719637996, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 548,
        left: -7858707.8465254251,
        bottom: -3961438.7311616335,
        right: -7857553.145448667,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.493096939514267],
              [-70.593380505573393, -33.489351096198902],
              [-70.588194077444186, -33.489351096198902],
              [-70.585600863379582, -33.493096939514267],
              [-70.588194077444186, -33.496842620787838],
              [-70.593380505573393, -33.496842620787838],
              [-70.595973719637996, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 549,
        left: -7858707.8465254251,
        bottom: -3960438.7306953832,
        right: -7857553.145448667,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.485605090850768],
              [-70.593380505573393, -33.48185892347886],
              [-70.588194077444186, -33.48185892347886],
              [-70.585600863379582, -33.485605090850768],
              [-70.588194077444186, -33.489351096198902],
              [-70.593380505573393, -33.489351096198902],
              [-70.595973719637996, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 550,
        left: -7858707.8465254251,
        bottom: -3959438.7302291333,
        right: -7857553.145448667,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.478112594092174],
              [-70.593380505573393, -33.474366102699733],
              [-70.588194077444186, -33.474366102699733],
              [-70.585600863379582, -33.478112594092174],
              [-70.588194077444186, -33.48185892347886],
              [-70.593380505573393, -33.48185892347886],
              [-70.595973719637996, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 551,
        left: -7858707.8465254251,
        bottom: -3958438.7297628829,
        right: -7857553.145448667,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.470619449310547],
              [-70.593380505573393, -33.466872633933626],
              [-70.588194077444186, -33.466872633933626],
              [-70.585600863379582, -33.470619449310547],
              [-70.588194077444186, -33.474366102699733],
              [-70.593380505573393, -33.474366102699733],
              [-70.595973719637996, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 552,
        left: -7858707.8465254251,
        bottom: -3957438.7292966326,
        right: -7857553.145448667,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.463125656578008],
              [-70.593380505573393, -33.459378517252702],
              [-70.588194077444186, -33.459378517252702],
              [-70.585600863379582, -33.463125656578008],
              [-70.588194077444186, -33.466872633933626],
              [-70.593380505573393, -33.466872633933626],
              [-70.595973719637996, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 553,
        left: -7858707.8465254251,
        bottom: -3956438.7288303822,
        right: -7857553.145448667,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.45563121596674],
              [-70.593380505573393, -33.451883752729138],
              [-70.588194077444186, -33.451883752729138],
              [-70.585600863379582, -33.45563121596674],
              [-70.588194077444186, -33.459378517252702],
              [-70.593380505573393, -33.459378517252702],
              [-70.595973719637996, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 554,
        left: -7858707.8465254251,
        bottom: -3955438.7283641323,
        right: -7857553.145448667,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.448136127548949],
              [-70.593380505573393, -33.444388340435196],
              [-70.588194077444186, -33.444388340435196],
              [-70.585600863379582, -33.448136127548949],
              [-70.588194077444186, -33.451883752729138],
              [-70.593380505573393, -33.451883752729138],
              [-70.595973719637996, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 555,
        left: -7858707.8465254251,
        bottom: -3954438.7278978815,
        right: -7857553.145448667,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.440640391396926],
              [-70.593380505573393, -33.436892280443168],
              [-70.588194077444186, -33.436892280443168],
              [-70.585600863379582, -33.440640391396926],
              [-70.588194077444186, -33.444388340435196],
              [-70.593380505573393, -33.444388340435196],
              [-70.595973719637996, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 556,
        left: -7858707.8465254251,
        bottom: -3953438.7274316312,
        right: -7857553.145448667,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.433144007582989],
              [-70.593380505573393, -33.429395572825413],
              [-70.588194077444186, -33.429395572825413],
              [-70.585600863379582, -33.433144007582989],
              [-70.588194077444186, -33.436892280443168],
              [-70.593380505573393, -33.436892280443168],
              [-70.595973719637996, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 557,
        left: -7858707.8465254251,
        bottom: -3952438.7269653808,
        right: -7857553.145448667,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.425646976179515],
              [-70.593380505573393, -33.421898217654316],
              [-70.588194077444186, -33.421898217654316],
              [-70.585600863379582, -33.425646976179515],
              [-70.588194077444186, -33.429395572825413],
              [-70.593380505573393, -33.429395572825413],
              [-70.595973719637996, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 558,
        left: -7858707.8465254251,
        bottom: -3951438.7264991305,
        right: -7857553.145448667,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.418149297258921],
              [-70.593380505573393, -33.414400215002352],
              [-70.588194077444186, -33.414400215002352],
              [-70.585600863379582, -33.418149297258921],
              [-70.588194077444186, -33.421898217654316],
              [-70.593380505573393, -33.421898217654316],
              [-70.595973719637996, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 559,
        left: -7858707.8465254251,
        bottom: -3950438.7260328801,
        right: -7857553.145448667,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.410650970893691],
              [-70.593380505573393, -33.406901564941997],
              [-70.588194077444186, -33.406901564941997],
              [-70.585600863379582, -33.410650970893691],
              [-70.588194077444186, -33.414400215002352],
              [-70.593380505573393, -33.414400215002352],
              [-70.595973719637996, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 560,
        left: -7858707.8465254251,
        bottom: -3949438.7255666298,
        right: -7857553.145448667,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.40315199715635],
              [-70.593380505573393, -33.399402267545824],
              [-70.588194077444186, -33.399402267545824],
              [-70.585600863379582, -33.40315199715635],
              [-70.588194077444186, -33.406901564941997],
              [-70.593380505573393, -33.406901564941997],
              [-70.595973719637996, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 561,
        left: -7858707.8465254251,
        bottom: -3948438.7251003794,
        right: -7857553.145448667,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.395652376119486],
              [-70.593380505573393, -33.391902322886416],
              [-70.588194077444186, -33.391902322886416],
              [-70.585600863379582, -33.395652376119486],
              [-70.588194077444186, -33.399402267545824],
              [-70.593380505573393, -33.399402267545824],
              [-70.595973719637996, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 562,
        left: -7858707.8465254251,
        bottom: -3947438.7246341291,
        right: -7857553.145448667,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.388152107855717],
              [-70.593380505573393, -33.384401731036441],
              [-70.588194077444186, -33.384401731036441],
              [-70.585600863379582, -33.388152107855717],
              [-70.588194077444186, -33.391902322886416],
              [-70.593380505573393, -33.391902322886416],
              [-70.595973719637996, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 563,
        left: -7858707.8465254251,
        bottom: -3946438.7241678787,
        right: -7857553.145448667,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.380651192437711],
              [-70.593380505573393, -33.3769004920686],
              [-70.588194077444186, -33.3769004920686],
              [-70.585600863379582, -33.380651192437711],
              [-70.588194077444186, -33.384401731036441],
              [-70.593380505573393, -33.384401731036441],
              [-70.595973719637996, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 564,
        left: -7858707.8465254251,
        bottom: -3945438.7237016284,
        right: -7857553.145448667,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.373149629938226],
              [-70.593380505573393, -33.369398606055647],
              [-70.588194077444186, -33.369398606055647],
              [-70.585600863379582, -33.373149629938226],
              [-70.588194077444186, -33.3769004920686],
              [-70.593380505573393, -33.3769004920686],
              [-70.595973719637996, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 565,
        left: -7858707.8465254251,
        bottom: -3944438.723235378,
        right: -7857553.145448667,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.365647420430008],
              [-70.593380505573393, -33.36189607307039],
              [-70.588194077444186, -33.36189607307039],
              [-70.585600863379582, -33.365647420430008],
              [-70.588194077444186, -33.369398606055647],
              [-70.593380505573393, -33.369398606055647],
              [-70.595973719637996, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 566,
        left: -7858707.8465254251,
        bottom: -3943438.7227691277,
        right: -7857553.145448667,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.358144563985917],
              [-70.593380505573393, -33.35439289318569],
              [-70.588194077444186, -33.35439289318569],
              [-70.585600863379582, -33.358144563985917],
              [-70.588194077444186, -33.36189607307039],
              [-70.593380505573393, -33.36189607307039],
              [-70.595973719637996, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 567,
        left: -7858707.8465254251,
        bottom: -3942438.7223028769,
        right: -7857553.145448667,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.350641060678818],
              [-70.593380505573393, -33.346889066474425],
              [-70.588194077444186, -33.346889066474425],
              [-70.585600863379582, -33.350641060678818],
              [-70.588194077444186, -33.35439289318569],
              [-70.593380505573393, -33.35439289318569],
              [-70.595973719637996, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 568,
        left: -7858707.8465254251,
        bottom: -3941438.721836627,
        right: -7857553.145448667,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.343136910581649],
              [-70.593380505573393, -33.339384593009591],
              [-70.588194077444186, -33.339384593009591],
              [-70.585600863379582, -33.343136910581649],
              [-70.588194077444186, -33.346889066474425],
              [-70.593380505573393, -33.346889066474425],
              [-70.595973719637996, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 569,
        left: -7858707.8465254251,
        bottom: -3940438.7213703771,
        right: -7857553.145448667,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.335632113767389],
              [-70.593380505573393, -33.331879472864181],
              [-70.588194077444186, -33.331879472864181],
              [-70.585600863379582, -33.335632113767389],
              [-70.588194077444186, -33.339384593009591],
              [-70.593380505573393, -33.339384593009591],
              [-70.595973719637996, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 570,
        left: -7858707.8465254251,
        bottom: -3939438.7209041263,
        right: -7857553.145448667,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.328126670309082],
              [-70.593380505573393, -33.324373706111238],
              [-70.588194077444186, -33.324373706111238],
              [-70.585600863379582, -33.328126670309082],
              [-70.588194077444186, -33.331879472864181],
              [-70.593380505573393, -33.331879472864181],
              [-70.595973719637996, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 571,
        left: -7858707.8465254251,
        bottom: -3938438.7204378759,
        right: -7857553.145448667,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.320620580279794],
              [-70.593380505573393, -33.3168672928239],
              [-70.588194077444186, -33.3168672928239],
              [-70.585600863379582, -33.320620580279794],
              [-70.588194077444186, -33.324373706111238],
              [-70.593380505573393, -33.324373706111238],
              [-70.595973719637996, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 572,
        left: -7858707.8465254251,
        bottom: -3937438.7199716261,
        right: -7857553.145448667,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.313113843752674],
              [-70.593380505573393, -33.309360233075296],
              [-70.588194077444186, -33.309360233075296],
              [-70.585600863379582, -33.313113843752674],
              [-70.588194077444186, -33.3168672928239],
              [-70.593380505573393, -33.3168672928239],
              [-70.595973719637996, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 573,
        left: -7858707.8465254251,
        bottom: -3936438.7195053762,
        right: -7857553.145448667,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.305606460800909],
              [-70.593380505573393, -33.30185252693866],
              [-70.588194077444186, -33.30185252693866],
              [-70.585600863379582, -33.305606460800909],
              [-70.588194077444186, -33.309360233075296],
              [-70.593380505573393, -33.309360233075296],
              [-70.595973719637996, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 574,
        left: -7858707.8465254251,
        bottom: -3935438.7190391254,
        right: -7857553.145448667,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.298098431497721],
              [-70.593380505573393, -33.294344174487243],
              [-70.588194077444186, -33.294344174487243],
              [-70.585600863379582, -33.298098431497721],
              [-70.588194077444186, -33.30185252693866],
              [-70.593380505573393, -33.30185252693866],
              [-70.595973719637996, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 575,
        left: -7858707.8465254251,
        bottom: -3934438.718572875,
        right: -7857553.145448667,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.290589755916407],
              [-70.593380505573393, -33.286835175794366],
              [-70.588194077444186, -33.286835175794366],
              [-70.585600863379582, -33.290589755916407],
              [-70.588194077444186, -33.294344174487243],
              [-70.593380505573393, -33.294344174487243],
              [-70.595973719637996, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 576,
        left: -7858707.8465254251,
        bottom: -3933438.7181066247,
        right: -7857553.145448667,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.595973719637996, -33.283080434130305],
              [-70.593380505573393, -33.279325530933384],
              [-70.588194077444186, -33.279325530933384],
              [-70.585600863379582, -33.283080434130305],
              [-70.588194077444186, -33.286835175794366],
              [-70.593380505573393, -33.286835175794366],
              [-70.595973719637996, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 577,
        left: -7857841.8207178554,
        bottom: -3980938.7402535151,
        right: -7856687.1196410973,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.639058347612803],
              [-70.585600863379582, -33.63531883056551],
              [-70.580414435250361, -33.63531883056551],
              [-70.577821221185772, -33.639058347612803],
              [-70.580414435250361, -33.642797702269725],
              [-70.585600863379582, -33.642797702269725],
              [-70.588194077444186, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 578,
        left: -7857841.8207178554,
        bottom: -3979938.7397872647,
        right: -7856687.1196410973,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.631579151136748],
              [-70.585600863379582, -33.627839309335386],
              [-70.580414435250361, -33.627839309335386],
              [-70.577821221185772, -33.631579151136748],
              [-70.580414435250361, -33.63531883056551],
              [-70.585600863379582, -33.63531883056551],
              [-70.588194077444186, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 579,
        left: -7857841.8207178554,
        bottom: -3978938.7393210144,
        right: -7856687.1196410973,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.624099305170297],
              [-70.585600863379582, -33.620359138650379],
              [-70.580414435250361, -33.620359138650379],
              [-70.577821221185772, -33.624099305170297],
              [-70.580414435250361, -33.627839309335386],
              [-70.585600863379582, -33.627839309335386],
              [-70.588194077444186, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 580,
        left: -7857841.8207178554,
        bottom: -3977938.738854764,
        right: -7856687.1196410973,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.616618809784534],
              [-70.585600863379582, -33.612878318581629],
              [-70.580414435250361, -33.612878318581629],
              [-70.577821221185772, -33.616618809784534],
              [-70.580414435250361, -33.620359138650379],
              [-70.585600863379582, -33.620359138650379],
              [-70.588194077444186, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 581,
        left: -7857841.8207178554,
        bottom: -3976938.7383885137,
        right: -7856687.1196410973,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.609137665050582],
              [-70.585600863379582, -33.605396849200282],
              [-70.580414435250361, -33.605396849200282],
              [-70.577821221185772, -33.609137665050582],
              [-70.580414435250361, -33.612878318581629],
              [-70.585600863379582, -33.612878318581629],
              [-70.588194077444186, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 582,
        left: -7857841.8207178554,
        bottom: -3975938.7379222633,
        right: -7856687.1196410973,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.601655871039647],
              [-70.585600863379582, -33.597914730577571],
              [-70.580414435250361, -33.597914730577571],
              [-70.577821221185772, -33.601655871039647],
              [-70.580414435250361, -33.605396849200282],
              [-70.585600863379582, -33.605396849200282],
              [-70.588194077444186, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 583,
        left: -7857841.8207178554,
        bottom: -3974938.737456013,
        right: -7856687.1196410973,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.594173427822959],
              [-70.585600863379582, -33.590431962784734],
              [-70.580414435250361, -33.590431962784734],
              [-70.577821221185772, -33.594173427822959],
              [-70.580414435250361, -33.597914730577571],
              [-70.585600863379582, -33.597914730577571],
              [-70.588194077444186, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 584,
        left: -7857841.8207178554,
        bottom: -3973938.7369897626,
        right: -7856687.1196410973,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.586690335471808],
              [-70.585600863379582, -33.582948545893096],
              [-70.580414435250361, -33.582948545893096],
              [-70.577821221185772, -33.586690335471808],
              [-70.580414435250361, -33.590431962784734],
              [-70.585600863379582, -33.590431962784734],
              [-70.588194077444186, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 585,
        left: -7857841.8207178554,
        bottom: -3972938.7365235123,
        right: -7856687.1196410973,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.579206594057531],
              [-70.585600863379582, -33.575464479974038],
              [-70.580414435250361, -33.575464479974038],
              [-70.577821221185772, -33.579206594057531],
              [-70.580414435250361, -33.582948545893096],
              [-70.585600863379582, -33.582948545893096],
              [-70.588194077444186, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 586,
        left: -7857841.8207178554,
        bottom: -3971938.7360572619,
        right: -7856687.1196410973,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.571722203651518],
              [-70.585600863379582, -33.56797976509894],
              [-70.580414435250361, -33.56797976509894],
              [-70.577821221185772, -33.571722203651518],
              [-70.580414435250361, -33.575464479974038],
              [-70.585600863379582, -33.575464479974038],
              [-70.588194077444186, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 587,
        left: -7857841.8207178554,
        bottom: -3970938.7355910116,
        right: -7856687.1196410973,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.56423716432522],
              [-70.585600863379582, -33.560494401339298],
              [-70.580414435250361, -33.560494401339298],
              [-70.577821221185772, -33.56423716432522],
              [-70.580414435250361, -33.56797976509894],
              [-70.585600863379582, -33.56797976509894],
              [-70.588194077444186, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 588,
        left: -7857841.8207178554,
        bottom: -3969938.7351247617,
        right: -7856687.1196410973,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.556751476150104],
              [-70.585600863379582, -33.553008388766614],
              [-70.580414435250361, -33.553008388766614],
              [-70.577821221185772, -33.556751476150104],
              [-70.580414435250361, -33.560494401339298],
              [-70.585600863379582, -33.560494401339298],
              [-70.588194077444186, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 589,
        left: -7857841.8207178554,
        bottom: -3968938.7346585114,
        right: -7856687.1196410973,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.549265139197736],
              [-70.585600863379582, -33.54552172745246],
              [-70.580414435250361, -33.54552172745246],
              [-70.577821221185772, -33.549265139197736],
              [-70.580414435250361, -33.553008388766614],
              [-70.585600863379582, -33.553008388766614],
              [-70.588194077444186, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 590,
        left: -7857841.8207178554,
        bottom: -3967938.734192261,
        right: -7856687.1196410973,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.541778153539695],
              [-70.585600863379582, -33.538034417468438],
              [-70.580414435250361, -33.538034417468438],
              [-70.577821221185772, -33.541778153539695],
              [-70.580414435250361, -33.54552172745246],
              [-70.585600863379582, -33.54552172745246],
              [-70.588194077444186, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 591,
        left: -7857841.8207178554,
        bottom: -3966938.7337260107,
        right: -7856687.1196410973,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.534290519247627],
              [-70.585600863379582, -33.530546458886235],
              [-70.580414435250361, -33.530546458886235],
              [-70.577821221185772, -33.534290519247627],
              [-70.580414435250361, -33.538034417468438],
              [-70.585600863379582, -33.538034417468438],
              [-70.588194077444186, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 592,
        left: -7857841.8207178554,
        bottom: -3965938.7332597603,
        right: -7856687.1196410973,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.526802236393216],
              [-70.585600863379582, -33.523057851777558],
              [-70.580414435250361, -33.523057851777558],
              [-70.577821221185772, -33.526802236393216],
              [-70.580414435250361, -33.530546458886235],
              [-70.585600863379582, -33.530546458886235],
              [-70.588194077444186, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 593,
        left: -7857841.8207178554,
        bottom: -3964938.7327935095,
        right: -7856687.1196410973,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.519313305048215],
              [-70.585600863379582, -33.515568596214173],
              [-70.580414435250361, -33.515568596214173],
              [-70.577821221185772, -33.519313305048215],
              [-70.580414435250361, -33.523057851777558],
              [-70.585600863379582, -33.523057851777558],
              [-70.588194077444186, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 594,
        left: -7857841.8207178554,
        bottom: -3963938.7323272596,
        right: -7856687.1196410973,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.511823725284408],
              [-70.585600863379582, -33.508078692267915],
              [-70.580414435250361, -33.508078692267915],
              [-70.577821221185772, -33.511823725284408],
              [-70.580414435250361, -33.515568596214173],
              [-70.585600863379582, -33.515568596214173],
              [-70.588194077444186, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 595,
        left: -7857841.8207178554,
        bottom: -3962938.7318610097,
        right: -7856687.1196410973,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.504333497173661],
              [-70.585600863379582, -33.500588140010635],
              [-70.580414435250361, -33.500588140010635],
              [-70.577821221185772, -33.504333497173661],
              [-70.580414435250361, -33.508078692267915],
              [-70.585600863379582, -33.508078692267915],
              [-70.588194077444186, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 596,
        left: -7857841.8207178554,
        bottom: -3961938.7313947589,
        right: -7856687.1196410973,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.496842620787838],
              [-70.585600863379582, -33.493096939514267],
              [-70.580414435250361, -33.493096939514267],
              [-70.577821221185772, -33.496842620787838],
              [-70.580414435250361, -33.500588140010635],
              [-70.585600863379582, -33.500588140010635],
              [-70.588194077444186, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 597,
        left: -7857841.8207178554,
        bottom: -3960938.7309285086,
        right: -7856687.1196410973,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.489351096198902],
              [-70.585600863379582, -33.485605090850768],
              [-70.580414435250361, -33.485605090850768],
              [-70.577821221185772, -33.489351096198902],
              [-70.580414435250361, -33.493096939514267],
              [-70.585600863379582, -33.493096939514267],
              [-70.588194077444186, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 598,
        left: -7857841.8207178554,
        bottom: -3959938.7304622582,
        right: -7856687.1196410973,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.48185892347886],
              [-70.585600863379582, -33.478112594092174],
              [-70.580414435250361, -33.478112594092174],
              [-70.577821221185772, -33.48185892347886],
              [-70.580414435250361, -33.485605090850768],
              [-70.585600863379582, -33.485605090850768],
              [-70.588194077444186, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 599,
        left: -7857841.8207178554,
        bottom: -3958938.7299960079,
        right: -7856687.1196410973,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.474366102699733],
              [-70.585600863379582, -33.470619449310547],
              [-70.580414435250361, -33.470619449310547],
              [-70.577821221185772, -33.474366102699733],
              [-70.580414435250361, -33.478112594092174],
              [-70.585600863379582, -33.478112594092174],
              [-70.588194077444186, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 600,
        left: -7857841.8207178554,
        bottom: -3957938.7295297575,
        right: -7856687.1196410973,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.466872633933626],
              [-70.585600863379582, -33.463125656578008],
              [-70.580414435250361, -33.463125656578008],
              [-70.577821221185772, -33.466872633933626],
              [-70.580414435250361, -33.470619449310547],
              [-70.585600863379582, -33.470619449310547],
              [-70.588194077444186, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 601,
        left: -7857841.8207178554,
        bottom: -3956938.7290635072,
        right: -7856687.1196410973,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.459378517252702],
              [-70.585600863379582, -33.45563121596674],
              [-70.580414435250361, -33.45563121596674],
              [-70.577821221185772, -33.459378517252702],
              [-70.580414435250361, -33.463125656578008],
              [-70.585600863379582, -33.463125656578008],
              [-70.588194077444186, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 602,
        left: -7857841.8207178554,
        bottom: -3955938.7285972568,
        right: -7856687.1196410973,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.451883752729138],
              [-70.585600863379582, -33.448136127548949],
              [-70.580414435250361, -33.448136127548949],
              [-70.577821221185772, -33.451883752729138],
              [-70.580414435250361, -33.45563121596674],
              [-70.585600863379582, -33.45563121596674],
              [-70.588194077444186, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 603,
        left: -7857841.8207178554,
        bottom: -3954938.7281310065,
        right: -7856687.1196410973,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.444388340435196],
              [-70.585600863379582, -33.440640391396926],
              [-70.580414435250361, -33.440640391396926],
              [-70.577821221185772, -33.444388340435196],
              [-70.580414435250361, -33.448136127548949],
              [-70.585600863379582, -33.448136127548949],
              [-70.588194077444186, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 604,
        left: -7857841.8207178554,
        bottom: -3953938.7276647561,
        right: -7856687.1196410973,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.436892280443168],
              [-70.585600863379582, -33.433144007582989],
              [-70.580414435250361, -33.433144007582989],
              [-70.577821221185772, -33.436892280443168],
              [-70.580414435250361, -33.440640391396926],
              [-70.585600863379582, -33.440640391396926],
              [-70.588194077444186, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 605,
        left: -7857841.8207178554,
        bottom: -3952938.7271985058,
        right: -7856687.1196410973,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.429395572825413],
              [-70.585600863379582, -33.425646976179515],
              [-70.580414435250361, -33.425646976179515],
              [-70.577821221185772, -33.429395572825413],
              [-70.580414435250361, -33.433144007582989],
              [-70.585600863379582, -33.433144007582989],
              [-70.588194077444186, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 606,
        left: -7857841.8207178554,
        bottom: -3951938.7267322554,
        right: -7856687.1196410973,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.421898217654316],
              [-70.585600863379582, -33.418149297258921],
              [-70.580414435250361, -33.418149297258921],
              [-70.577821221185772, -33.421898217654316],
              [-70.580414435250361, -33.425646976179515],
              [-70.585600863379582, -33.425646976179515],
              [-70.588194077444186, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 607,
        left: -7857841.8207178554,
        bottom: -3950938.7262660051,
        right: -7856687.1196410973,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.414400215002352],
              [-70.585600863379582, -33.410650970893691],
              [-70.580414435250361, -33.410650970893691],
              [-70.577821221185772, -33.414400215002352],
              [-70.580414435250361, -33.418149297258921],
              [-70.585600863379582, -33.418149297258921],
              [-70.588194077444186, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 608,
        left: -7857841.8207178554,
        bottom: -3949938.7257997552,
        right: -7856687.1196410973,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.406901564941997],
              [-70.585600863379582, -33.40315199715635],
              [-70.580414435250361, -33.40315199715635],
              [-70.577821221185772, -33.406901564941997],
              [-70.580414435250361, -33.410650970893691],
              [-70.585600863379582, -33.410650970893691],
              [-70.588194077444186, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 609,
        left: -7857841.8207178554,
        bottom: -3948938.7253335048,
        right: -7856687.1196410973,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.399402267545824],
              [-70.585600863379582, -33.395652376119486],
              [-70.580414435250361, -33.395652376119486],
              [-70.577821221185772, -33.399402267545824],
              [-70.580414435250361, -33.40315199715635],
              [-70.585600863379582, -33.40315199715635],
              [-70.588194077444186, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 610,
        left: -7857841.8207178554,
        bottom: -3947938.724867254,
        right: -7856687.1196410973,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.391902322886416],
              [-70.585600863379582, -33.388152107855717],
              [-70.580414435250361, -33.388152107855717],
              [-70.577821221185772, -33.391902322886416],
              [-70.580414435250361, -33.395652376119486],
              [-70.585600863379582, -33.395652376119486],
              [-70.588194077444186, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 611,
        left: -7857841.8207178554,
        bottom: -3946938.7244010041,
        right: -7856687.1196410973,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.384401731036441],
              [-70.585600863379582, -33.380651192437711],
              [-70.580414435250361, -33.380651192437711],
              [-70.577821221185772, -33.384401731036441],
              [-70.580414435250361, -33.388152107855717],
              [-70.585600863379582, -33.388152107855717],
              [-70.588194077444186, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 612,
        left: -7857841.8207178554,
        bottom: -3945938.7239347543,
        right: -7856687.1196410973,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.3769004920686],
              [-70.585600863379582, -33.373149629938226],
              [-70.580414435250361, -33.373149629938226],
              [-70.577821221185772, -33.3769004920686],
              [-70.580414435250361, -33.380651192437711],
              [-70.585600863379582, -33.380651192437711],
              [-70.588194077444186, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 613,
        left: -7857841.8207178554,
        bottom: -3944938.7234685034,
        right: -7856687.1196410973,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.369398606055647],
              [-70.585600863379582, -33.365647420430008],
              [-70.580414435250361, -33.365647420430008],
              [-70.577821221185772, -33.369398606055647],
              [-70.580414435250361, -33.373149629938226],
              [-70.585600863379582, -33.373149629938226],
              [-70.588194077444186, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 614,
        left: -7857841.8207178554,
        bottom: -3943938.7230022531,
        right: -7856687.1196410973,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.36189607307039],
              [-70.585600863379582, -33.358144563985917],
              [-70.580414435250361, -33.358144563985917],
              [-70.577821221185772, -33.36189607307039],
              [-70.580414435250361, -33.365647420430008],
              [-70.585600863379582, -33.365647420430008],
              [-70.588194077444186, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 615,
        left: -7857841.8207178554,
        bottom: -3942938.7225360028,
        right: -7856687.1196410973,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.35439289318569],
              [-70.585600863379582, -33.350641060678818],
              [-70.580414435250361, -33.350641060678818],
              [-70.577821221185772, -33.35439289318569],
              [-70.580414435250361, -33.358144563985917],
              [-70.585600863379582, -33.358144563985917],
              [-70.588194077444186, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 616,
        left: -7857841.8207178554,
        bottom: -3941938.7220697524,
        right: -7856687.1196410973,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.346889066474425],
              [-70.585600863379582, -33.343136910581649],
              [-70.580414435250361, -33.343136910581649],
              [-70.577821221185772, -33.346889066474425],
              [-70.580414435250361, -33.350641060678818],
              [-70.585600863379582, -33.350641060678818],
              [-70.588194077444186, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 617,
        left: -7857841.8207178554,
        bottom: -3940938.7216035021,
        right: -7856687.1196410973,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.339384593009591],
              [-70.585600863379582, -33.335632113767389],
              [-70.580414435250361, -33.335632113767389],
              [-70.577821221185772, -33.339384593009591],
              [-70.580414435250361, -33.343136910581649],
              [-70.585600863379582, -33.343136910581649],
              [-70.588194077444186, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 618,
        left: -7857841.8207178554,
        bottom: -3939938.7211372517,
        right: -7856687.1196410973,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.331879472864181],
              [-70.585600863379582, -33.328126670309082],
              [-70.580414435250361, -33.328126670309082],
              [-70.577821221185772, -33.331879472864181],
              [-70.580414435250361, -33.335632113767389],
              [-70.585600863379582, -33.335632113767389],
              [-70.588194077444186, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 619,
        left: -7857841.8207178554,
        bottom: -3938938.7206710014,
        right: -7856687.1196410973,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.324373706111238],
              [-70.585600863379582, -33.320620580279794],
              [-70.580414435250361, -33.320620580279794],
              [-70.577821221185772, -33.324373706111238],
              [-70.580414435250361, -33.328126670309082],
              [-70.585600863379582, -33.328126670309082],
              [-70.588194077444186, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 620,
        left: -7857841.8207178554,
        bottom: -3937938.720204751,
        right: -7856687.1196410973,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.3168672928239],
              [-70.585600863379582, -33.313113843752674],
              [-70.580414435250361, -33.313113843752674],
              [-70.577821221185772, -33.3168672928239],
              [-70.580414435250361, -33.320620580279794],
              [-70.585600863379582, -33.320620580279794],
              [-70.588194077444186, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 621,
        left: -7857841.8207178554,
        bottom: -3936938.7197385007,
        right: -7856687.1196410973,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.309360233075296],
              [-70.585600863379582, -33.305606460800909],
              [-70.580414435250361, -33.305606460800909],
              [-70.577821221185772, -33.309360233075296],
              [-70.580414435250361, -33.313113843752674],
              [-70.585600863379582, -33.313113843752674],
              [-70.588194077444186, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 622,
        left: -7857841.8207178554,
        bottom: -3935938.7192722503,
        right: -7856687.1196410973,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.30185252693866],
              [-70.585600863379582, -33.298098431497721],
              [-70.580414435250361, -33.298098431497721],
              [-70.577821221185772, -33.30185252693866],
              [-70.580414435250361, -33.305606460800909],
              [-70.585600863379582, -33.305606460800909],
              [-70.588194077444186, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 623,
        left: -7857841.8207178554,
        bottom: -3934938.718806,
        right: -7856687.1196410973,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.294344174487243],
              [-70.585600863379582, -33.290589755916407],
              [-70.580414435250361, -33.290589755916407],
              [-70.577821221185772, -33.294344174487243],
              [-70.580414435250361, -33.298098431497721],
              [-70.585600863379582, -33.298098431497721],
              [-70.588194077444186, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 624,
        left: -7857841.8207178554,
        bottom: -3933938.7183397496,
        right: -7856687.1196410973,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.588194077444186, -33.286835175794366],
              [-70.585600863379582, -33.283080434130305],
              [-70.580414435250361, -33.283080434130305],
              [-70.577821221185772, -33.286835175794366],
              [-70.580414435250361, -33.290589755916407],
              [-70.585600863379582, -33.290589755916407],
              [-70.588194077444186, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 625,
        left: -7856975.7949102866,
        bottom: -3980438.7400203901,
        right: -7855821.0938335285,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.63531883056551],
              [-70.577821221185772, -33.631579151136748],
              [-70.572634793056565, -33.631579151136748],
              [-70.570041578991948, -33.63531883056551],
              [-70.572634793056565, -33.639058347612803],
              [-70.577821221185772, -33.639058347612803],
              [-70.580414435250361, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 626,
        left: -7856975.7949102866,
        bottom: -3979438.7395541398,
        right: -7855821.0938335285,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.627839309335386],
              [-70.577821221185772, -33.624099305170297],
              [-70.572634793056565, -33.624099305170297],
              [-70.570041578991948, -33.627839309335386],
              [-70.572634793056565, -33.631579151136748],
              [-70.577821221185772, -33.631579151136748],
              [-70.580414435250361, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 627,
        left: -7856975.7949102866,
        bottom: -3978438.7390878894,
        right: -7855821.0938335285,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.620359138650379],
              [-70.577821221185772, -33.616618809784534],
              [-70.572634793056565, -33.616618809784534],
              [-70.570041578991948, -33.620359138650379],
              [-70.572634793056565, -33.624099305170297],
              [-70.577821221185772, -33.624099305170297],
              [-70.580414435250361, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 628,
        left: -7856975.7949102866,
        bottom: -3977438.7386216391,
        right: -7855821.0938335285,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.612878318581629],
              [-70.577821221185772, -33.609137665050582],
              [-70.572634793056565, -33.609137665050582],
              [-70.570041578991948, -33.612878318581629],
              [-70.572634793056565, -33.616618809784534],
              [-70.577821221185772, -33.616618809784534],
              [-70.580414435250361, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 629,
        left: -7856975.7949102866,
        bottom: -3976438.7381553887,
        right: -7855821.0938335285,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.605396849200282],
              [-70.577821221185772, -33.601655871039647],
              [-70.572634793056565, -33.601655871039647],
              [-70.570041578991948, -33.605396849200282],
              [-70.572634793056565, -33.609137665050582],
              [-70.577821221185772, -33.609137665050582],
              [-70.580414435250361, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 630,
        left: -7856975.7949102866,
        bottom: -3975438.7376891384,
        right: -7855821.0938335285,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.597914730577571],
              [-70.577821221185772, -33.594173427822959],
              [-70.572634793056565, -33.594173427822959],
              [-70.570041578991948, -33.597914730577571],
              [-70.572634793056565, -33.601655871039647],
              [-70.577821221185772, -33.601655871039647],
              [-70.580414435250361, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 631,
        left: -7856975.7949102866,
        bottom: -3974438.7372228876,
        right: -7855821.0938335285,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.590431962784734],
              [-70.577821221185772, -33.586690335471808],
              [-70.572634793056565, -33.586690335471808],
              [-70.570041578991948, -33.590431962784734],
              [-70.572634793056565, -33.594173427822959],
              [-70.577821221185772, -33.594173427822959],
              [-70.580414435250361, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 632,
        left: -7856975.7949102866,
        bottom: -3973438.7367566377,
        right: -7855821.0938335285,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.582948545893096],
              [-70.577821221185772, -33.579206594057531],
              [-70.572634793056565, -33.579206594057531],
              [-70.570041578991948, -33.582948545893096],
              [-70.572634793056565, -33.586690335471808],
              [-70.577821221185772, -33.586690335471808],
              [-70.580414435250361, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 633,
        left: -7856975.7949102866,
        bottom: -3972438.7362903878,
        right: -7855821.0938335285,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.575464479974038],
              [-70.577821221185772, -33.571722203651518],
              [-70.572634793056565, -33.571722203651518],
              [-70.570041578991948, -33.575464479974038],
              [-70.572634793056565, -33.579206594057531],
              [-70.577821221185772, -33.579206594057531],
              [-70.580414435250361, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 634,
        left: -7856975.7949102866,
        bottom: -3971438.735824137,
        right: -7855821.0938335285,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.56797976509894],
              [-70.577821221185772, -33.56423716432522],
              [-70.572634793056565, -33.56423716432522],
              [-70.570041578991948, -33.56797976509894],
              [-70.572634793056565, -33.571722203651518],
              [-70.577821221185772, -33.571722203651518],
              [-70.580414435250361, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 635,
        left: -7856975.7949102866,
        bottom: -3970438.7353578866,
        right: -7855821.0938335285,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.560494401339298],
              [-70.577821221185772, -33.556751476150104],
              [-70.572634793056565, -33.556751476150104],
              [-70.570041578991948, -33.560494401339298],
              [-70.572634793056565, -33.56423716432522],
              [-70.577821221185772, -33.56423716432522],
              [-70.580414435250361, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 636,
        left: -7856975.7949102866,
        bottom: -3969438.7348916363,
        right: -7855821.0938335285,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.553008388766614],
              [-70.577821221185772, -33.549265139197736],
              [-70.572634793056565, -33.549265139197736],
              [-70.570041578991948, -33.553008388766614],
              [-70.572634793056565, -33.556751476150104],
              [-70.577821221185772, -33.556751476150104],
              [-70.580414435250361, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 637,
        left: -7856975.7949102866,
        bottom: -3968438.7344253859,
        right: -7855821.0938335285,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.54552172745246],
              [-70.577821221185772, -33.541778153539695],
              [-70.572634793056565, -33.541778153539695],
              [-70.570041578991948, -33.54552172745246],
              [-70.572634793056565, -33.549265139197736],
              [-70.577821221185772, -33.549265139197736],
              [-70.580414435250361, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 638,
        left: -7856975.7949102866,
        bottom: -3967438.7339591356,
        right: -7855821.0938335285,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.538034417468438],
              [-70.577821221185772, -33.534290519247627],
              [-70.572634793056565, -33.534290519247627],
              [-70.570041578991948, -33.538034417468438],
              [-70.572634793056565, -33.541778153539695],
              [-70.577821221185772, -33.541778153539695],
              [-70.580414435250361, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 639,
        left: -7856975.7949102866,
        bottom: -3966438.7334928853,
        right: -7855821.0938335285,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.530546458886235],
              [-70.577821221185772, -33.526802236393216],
              [-70.572634793056565, -33.526802236393216],
              [-70.570041578991948, -33.530546458886235],
              [-70.572634793056565, -33.534290519247627],
              [-70.577821221185772, -33.534290519247627],
              [-70.580414435250361, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 640,
        left: -7856975.7949102866,
        bottom: -3965438.7330266349,
        right: -7855821.0938335285,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.523057851777558],
              [-70.577821221185772, -33.519313305048215],
              [-70.572634793056565, -33.519313305048215],
              [-70.570041578991948, -33.523057851777558],
              [-70.572634793056565, -33.526802236393216],
              [-70.577821221185772, -33.526802236393216],
              [-70.580414435250361, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 641,
        left: -7856975.7949102866,
        bottom: -3964438.7325603846,
        right: -7855821.0938335285,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.515568596214173],
              [-70.577821221185772, -33.511823725284408],
              [-70.572634793056565, -33.511823725284408],
              [-70.570041578991948, -33.515568596214173],
              [-70.572634793056565, -33.519313305048215],
              [-70.577821221185772, -33.519313305048215],
              [-70.580414435250361, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 642,
        left: -7856975.7949102866,
        bottom: -3963438.7320941342,
        right: -7855821.0938335285,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.508078692267915],
              [-70.577821221185772, -33.504333497173661],
              [-70.572634793056565, -33.504333497173661],
              [-70.570041578991948, -33.508078692267915],
              [-70.572634793056565, -33.511823725284408],
              [-70.577821221185772, -33.511823725284408],
              [-70.580414435250361, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 643,
        left: -7856975.7949102866,
        bottom: -3962438.7316278839,
        right: -7855821.0938335285,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.500588140010635],
              [-70.577821221185772, -33.496842620787838],
              [-70.572634793056565, -33.496842620787838],
              [-70.570041578991948, -33.500588140010635],
              [-70.572634793056565, -33.504333497173661],
              [-70.577821221185772, -33.504333497173661],
              [-70.580414435250361, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 644,
        left: -7856975.7949102866,
        bottom: -3961438.7311616335,
        right: -7855821.0938335285,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.493096939514267],
              [-70.577821221185772, -33.489351096198902],
              [-70.572634793056565, -33.489351096198902],
              [-70.570041578991948, -33.493096939514267],
              [-70.572634793056565, -33.496842620787838],
              [-70.577821221185772, -33.496842620787838],
              [-70.580414435250361, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 645,
        left: -7856975.7949102866,
        bottom: -3960438.7306953832,
        right: -7855821.0938335285,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.485605090850768],
              [-70.577821221185772, -33.48185892347886],
              [-70.572634793056565, -33.48185892347886],
              [-70.570041578991948, -33.485605090850768],
              [-70.572634793056565, -33.489351096198902],
              [-70.577821221185772, -33.489351096198902],
              [-70.580414435250361, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 646,
        left: -7856975.7949102866,
        bottom: -3959438.7302291333,
        right: -7855821.0938335285,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.478112594092174],
              [-70.577821221185772, -33.474366102699733],
              [-70.572634793056565, -33.474366102699733],
              [-70.570041578991948, -33.478112594092174],
              [-70.572634793056565, -33.48185892347886],
              [-70.577821221185772, -33.48185892347886],
              [-70.580414435250361, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 647,
        left: -7856975.7949102866,
        bottom: -3958438.7297628829,
        right: -7855821.0938335285,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.470619449310547],
              [-70.577821221185772, -33.466872633933626],
              [-70.572634793056565, -33.466872633933626],
              [-70.570041578991948, -33.470619449310547],
              [-70.572634793056565, -33.474366102699733],
              [-70.577821221185772, -33.474366102699733],
              [-70.580414435250361, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 648,
        left: -7856975.7949102866,
        bottom: -3957438.7292966326,
        right: -7855821.0938335285,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.463125656578008],
              [-70.577821221185772, -33.459378517252702],
              [-70.572634793056565, -33.459378517252702],
              [-70.570041578991948, -33.463125656578008],
              [-70.572634793056565, -33.466872633933626],
              [-70.577821221185772, -33.466872633933626],
              [-70.580414435250361, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 649,
        left: -7856975.7949102866,
        bottom: -3956438.7288303822,
        right: -7855821.0938335285,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.45563121596674],
              [-70.577821221185772, -33.451883752729138],
              [-70.572634793056565, -33.451883752729138],
              [-70.570041578991948, -33.45563121596674],
              [-70.572634793056565, -33.459378517252702],
              [-70.577821221185772, -33.459378517252702],
              [-70.580414435250361, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 650,
        left: -7856975.7949102866,
        bottom: -3955438.7283641323,
        right: -7855821.0938335285,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.448136127548949],
              [-70.577821221185772, -33.444388340435196],
              [-70.572634793056565, -33.444388340435196],
              [-70.570041578991948, -33.448136127548949],
              [-70.572634793056565, -33.451883752729138],
              [-70.577821221185772, -33.451883752729138],
              [-70.580414435250361, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 651,
        left: -7856975.7949102866,
        bottom: -3954438.7278978815,
        right: -7855821.0938335285,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.440640391396926],
              [-70.577821221185772, -33.436892280443168],
              [-70.572634793056565, -33.436892280443168],
              [-70.570041578991948, -33.440640391396926],
              [-70.572634793056565, -33.444388340435196],
              [-70.577821221185772, -33.444388340435196],
              [-70.580414435250361, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 652,
        left: -7856975.7949102866,
        bottom: -3953438.7274316312,
        right: -7855821.0938335285,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.433144007582989],
              [-70.577821221185772, -33.429395572825413],
              [-70.572634793056565, -33.429395572825413],
              [-70.570041578991948, -33.433144007582989],
              [-70.572634793056565, -33.436892280443168],
              [-70.577821221185772, -33.436892280443168],
              [-70.580414435250361, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 653,
        left: -7856975.7949102866,
        bottom: -3952438.7269653808,
        right: -7855821.0938335285,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.425646976179515],
              [-70.577821221185772, -33.421898217654316],
              [-70.572634793056565, -33.421898217654316],
              [-70.570041578991948, -33.425646976179515],
              [-70.572634793056565, -33.429395572825413],
              [-70.577821221185772, -33.429395572825413],
              [-70.580414435250361, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 654,
        left: -7856975.7949102866,
        bottom: -3951438.7264991305,
        right: -7855821.0938335285,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.418149297258921],
              [-70.577821221185772, -33.414400215002352],
              [-70.572634793056565, -33.414400215002352],
              [-70.570041578991948, -33.418149297258921],
              [-70.572634793056565, -33.421898217654316],
              [-70.577821221185772, -33.421898217654316],
              [-70.580414435250361, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 655,
        left: -7856975.7949102866,
        bottom: -3950438.7260328801,
        right: -7855821.0938335285,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.410650970893691],
              [-70.577821221185772, -33.406901564941997],
              [-70.572634793056565, -33.406901564941997],
              [-70.570041578991948, -33.410650970893691],
              [-70.572634793056565, -33.414400215002352],
              [-70.577821221185772, -33.414400215002352],
              [-70.580414435250361, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 656,
        left: -7856975.7949102866,
        bottom: -3949438.7255666298,
        right: -7855821.0938335285,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.40315199715635],
              [-70.577821221185772, -33.399402267545824],
              [-70.572634793056565, -33.399402267545824],
              [-70.570041578991948, -33.40315199715635],
              [-70.572634793056565, -33.406901564941997],
              [-70.577821221185772, -33.406901564941997],
              [-70.580414435250361, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 657,
        left: -7856975.7949102866,
        bottom: -3948438.7251003794,
        right: -7855821.0938335285,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.395652376119486],
              [-70.577821221185772, -33.391902322886416],
              [-70.572634793056565, -33.391902322886416],
              [-70.570041578991948, -33.395652376119486],
              [-70.572634793056565, -33.399402267545824],
              [-70.577821221185772, -33.399402267545824],
              [-70.580414435250361, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 658,
        left: -7856975.7949102866,
        bottom: -3947438.7246341291,
        right: -7855821.0938335285,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.388152107855717],
              [-70.577821221185772, -33.384401731036441],
              [-70.572634793056565, -33.384401731036441],
              [-70.570041578991948, -33.388152107855717],
              [-70.572634793056565, -33.391902322886416],
              [-70.577821221185772, -33.391902322886416],
              [-70.580414435250361, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 659,
        left: -7856975.7949102866,
        bottom: -3946438.7241678787,
        right: -7855821.0938335285,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.380651192437711],
              [-70.577821221185772, -33.3769004920686],
              [-70.572634793056565, -33.3769004920686],
              [-70.570041578991948, -33.380651192437711],
              [-70.572634793056565, -33.384401731036441],
              [-70.577821221185772, -33.384401731036441],
              [-70.580414435250361, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 660,
        left: -7856975.7949102866,
        bottom: -3945438.7237016284,
        right: -7855821.0938335285,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.373149629938226],
              [-70.577821221185772, -33.369398606055647],
              [-70.572634793056565, -33.369398606055647],
              [-70.570041578991948, -33.373149629938226],
              [-70.572634793056565, -33.3769004920686],
              [-70.577821221185772, -33.3769004920686],
              [-70.580414435250361, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 661,
        left: -7856975.7949102866,
        bottom: -3944438.723235378,
        right: -7855821.0938335285,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.365647420430008],
              [-70.577821221185772, -33.36189607307039],
              [-70.572634793056565, -33.36189607307039],
              [-70.570041578991948, -33.365647420430008],
              [-70.572634793056565, -33.369398606055647],
              [-70.577821221185772, -33.369398606055647],
              [-70.580414435250361, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 662,
        left: -7856975.7949102866,
        bottom: -3943438.7227691277,
        right: -7855821.0938335285,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.358144563985917],
              [-70.577821221185772, -33.35439289318569],
              [-70.572634793056565, -33.35439289318569],
              [-70.570041578991948, -33.358144563985917],
              [-70.572634793056565, -33.36189607307039],
              [-70.577821221185772, -33.36189607307039],
              [-70.580414435250361, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 663,
        left: -7856975.7949102866,
        bottom: -3942438.7223028769,
        right: -7855821.0938335285,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.350641060678818],
              [-70.577821221185772, -33.346889066474425],
              [-70.572634793056565, -33.346889066474425],
              [-70.570041578991948, -33.350641060678818],
              [-70.572634793056565, -33.35439289318569],
              [-70.577821221185772, -33.35439289318569],
              [-70.580414435250361, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 664,
        left: -7856975.7949102866,
        bottom: -3941438.721836627,
        right: -7855821.0938335285,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.343136910581649],
              [-70.577821221185772, -33.339384593009591],
              [-70.572634793056565, -33.339384593009591],
              [-70.570041578991948, -33.343136910581649],
              [-70.572634793056565, -33.346889066474425],
              [-70.577821221185772, -33.346889066474425],
              [-70.580414435250361, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 665,
        left: -7856975.7949102866,
        bottom: -3940438.7213703771,
        right: -7855821.0938335285,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.335632113767389],
              [-70.577821221185772, -33.331879472864181],
              [-70.572634793056565, -33.331879472864181],
              [-70.570041578991948, -33.335632113767389],
              [-70.572634793056565, -33.339384593009591],
              [-70.577821221185772, -33.339384593009591],
              [-70.580414435250361, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 666,
        left: -7856975.7949102866,
        bottom: -3939438.7209041263,
        right: -7855821.0938335285,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.328126670309082],
              [-70.577821221185772, -33.324373706111238],
              [-70.572634793056565, -33.324373706111238],
              [-70.570041578991948, -33.328126670309082],
              [-70.572634793056565, -33.331879472864181],
              [-70.577821221185772, -33.331879472864181],
              [-70.580414435250361, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 667,
        left: -7856975.7949102866,
        bottom: -3938438.7204378759,
        right: -7855821.0938335285,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.320620580279794],
              [-70.577821221185772, -33.3168672928239],
              [-70.572634793056565, -33.3168672928239],
              [-70.570041578991948, -33.320620580279794],
              [-70.572634793056565, -33.324373706111238],
              [-70.577821221185772, -33.324373706111238],
              [-70.580414435250361, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 668,
        left: -7856975.7949102866,
        bottom: -3937438.7199716261,
        right: -7855821.0938335285,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.313113843752674],
              [-70.577821221185772, -33.309360233075296],
              [-70.572634793056565, -33.309360233075296],
              [-70.570041578991948, -33.313113843752674],
              [-70.572634793056565, -33.3168672928239],
              [-70.577821221185772, -33.3168672928239],
              [-70.580414435250361, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 669,
        left: -7856975.7949102866,
        bottom: -3936438.7195053762,
        right: -7855821.0938335285,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.305606460800909],
              [-70.577821221185772, -33.30185252693866],
              [-70.572634793056565, -33.30185252693866],
              [-70.570041578991948, -33.305606460800909],
              [-70.572634793056565, -33.309360233075296],
              [-70.577821221185772, -33.309360233075296],
              [-70.580414435250361, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 670,
        left: -7856975.7949102866,
        bottom: -3935438.7190391254,
        right: -7855821.0938335285,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.298098431497721],
              [-70.577821221185772, -33.294344174487243],
              [-70.572634793056565, -33.294344174487243],
              [-70.570041578991948, -33.298098431497721],
              [-70.572634793056565, -33.30185252693866],
              [-70.577821221185772, -33.30185252693866],
              [-70.580414435250361, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 671,
        left: -7856975.7949102866,
        bottom: -3934438.718572875,
        right: -7855821.0938335285,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.290589755916407],
              [-70.577821221185772, -33.286835175794366],
              [-70.572634793056565, -33.286835175794366],
              [-70.570041578991948, -33.290589755916407],
              [-70.572634793056565, -33.294344174487243],
              [-70.577821221185772, -33.294344174487243],
              [-70.580414435250361, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 672,
        left: -7856975.7949102866,
        bottom: -3933438.7181066247,
        right: -7855821.0938335285,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.580414435250361, -33.283080434130305],
              [-70.577821221185772, -33.279325530933384],
              [-70.572634793056565, -33.279325530933384],
              [-70.570041578991948, -33.283080434130305],
              [-70.572634793056565, -33.286835175794366],
              [-70.577821221185772, -33.286835175794366],
              [-70.580414435250361, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 673,
        left: -7856109.7691027177,
        bottom: -3980938.7402535151,
        right: -7854955.0680259597,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.639058347612803],
              [-70.570041578991948, -33.63531883056551],
              [-70.564855150862741, -33.63531883056551],
              [-70.562261936798137, -33.639058347612803],
              [-70.564855150862741, -33.642797702269725],
              [-70.570041578991948, -33.642797702269725],
              [-70.572634793056565, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 674,
        left: -7856109.7691027177,
        bottom: -3979938.7397872647,
        right: -7854955.0680259597,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.631579151136748],
              [-70.570041578991948, -33.627839309335386],
              [-70.564855150862741, -33.627839309335386],
              [-70.562261936798137, -33.631579151136748],
              [-70.564855150862741, -33.63531883056551],
              [-70.570041578991948, -33.63531883056551],
              [-70.572634793056565, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 675,
        left: -7856109.7691027177,
        bottom: -3978938.7393210144,
        right: -7854955.0680259597,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.624099305170297],
              [-70.570041578991948, -33.620359138650379],
              [-70.564855150862741, -33.620359138650379],
              [-70.562261936798137, -33.624099305170297],
              [-70.564855150862741, -33.627839309335386],
              [-70.570041578991948, -33.627839309335386],
              [-70.572634793056565, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 676,
        left: -7856109.7691027177,
        bottom: -3977938.738854764,
        right: -7854955.0680259597,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.616618809784534],
              [-70.570041578991948, -33.612878318581629],
              [-70.564855150862741, -33.612878318581629],
              [-70.562261936798137, -33.616618809784534],
              [-70.564855150862741, -33.620359138650379],
              [-70.570041578991948, -33.620359138650379],
              [-70.572634793056565, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 677,
        left: -7856109.7691027177,
        bottom: -3976938.7383885137,
        right: -7854955.0680259597,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.609137665050582],
              [-70.570041578991948, -33.605396849200282],
              [-70.564855150862741, -33.605396849200282],
              [-70.562261936798137, -33.609137665050582],
              [-70.564855150862741, -33.612878318581629],
              [-70.570041578991948, -33.612878318581629],
              [-70.572634793056565, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 678,
        left: -7856109.7691027177,
        bottom: -3975938.7379222633,
        right: -7854955.0680259597,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.601655871039647],
              [-70.570041578991948, -33.597914730577571],
              [-70.564855150862741, -33.597914730577571],
              [-70.562261936798137, -33.601655871039647],
              [-70.564855150862741, -33.605396849200282],
              [-70.570041578991948, -33.605396849200282],
              [-70.572634793056565, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 679,
        left: -7856109.7691027177,
        bottom: -3974938.737456013,
        right: -7854955.0680259597,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.594173427822959],
              [-70.570041578991948, -33.590431962784734],
              [-70.564855150862741, -33.590431962784734],
              [-70.562261936798137, -33.594173427822959],
              [-70.564855150862741, -33.597914730577571],
              [-70.570041578991948, -33.597914730577571],
              [-70.572634793056565, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 680,
        left: -7856109.7691027177,
        bottom: -3973938.7369897626,
        right: -7854955.0680259597,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.586690335471808],
              [-70.570041578991948, -33.582948545893096],
              [-70.564855150862741, -33.582948545893096],
              [-70.562261936798137, -33.586690335471808],
              [-70.564855150862741, -33.590431962784734],
              [-70.570041578991948, -33.590431962784734],
              [-70.572634793056565, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 681,
        left: -7856109.7691027177,
        bottom: -3972938.7365235123,
        right: -7854955.0680259597,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.579206594057531],
              [-70.570041578991948, -33.575464479974038],
              [-70.564855150862741, -33.575464479974038],
              [-70.562261936798137, -33.579206594057531],
              [-70.564855150862741, -33.582948545893096],
              [-70.570041578991948, -33.582948545893096],
              [-70.572634793056565, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 682,
        left: -7856109.7691027177,
        bottom: -3971938.7360572619,
        right: -7854955.0680259597,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.571722203651518],
              [-70.570041578991948, -33.56797976509894],
              [-70.564855150862741, -33.56797976509894],
              [-70.562261936798137, -33.571722203651518],
              [-70.564855150862741, -33.575464479974038],
              [-70.570041578991948, -33.575464479974038],
              [-70.572634793056565, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 683,
        left: -7856109.7691027177,
        bottom: -3970938.7355910116,
        right: -7854955.0680259597,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.56423716432522],
              [-70.570041578991948, -33.560494401339298],
              [-70.564855150862741, -33.560494401339298],
              [-70.562261936798137, -33.56423716432522],
              [-70.564855150862741, -33.56797976509894],
              [-70.570041578991948, -33.56797976509894],
              [-70.572634793056565, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 684,
        left: -7856109.7691027177,
        bottom: -3969938.7351247617,
        right: -7854955.0680259597,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.556751476150104],
              [-70.570041578991948, -33.553008388766614],
              [-70.564855150862741, -33.553008388766614],
              [-70.562261936798137, -33.556751476150104],
              [-70.564855150862741, -33.560494401339298],
              [-70.570041578991948, -33.560494401339298],
              [-70.572634793056565, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 685,
        left: -7856109.7691027177,
        bottom: -3968938.7346585114,
        right: -7854955.0680259597,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.549265139197736],
              [-70.570041578991948, -33.54552172745246],
              [-70.564855150862741, -33.54552172745246],
              [-70.562261936798137, -33.549265139197736],
              [-70.564855150862741, -33.553008388766614],
              [-70.570041578991948, -33.553008388766614],
              [-70.572634793056565, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 686,
        left: -7856109.7691027177,
        bottom: -3967938.734192261,
        right: -7854955.0680259597,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.541778153539695],
              [-70.570041578991948, -33.538034417468438],
              [-70.564855150862741, -33.538034417468438],
              [-70.562261936798137, -33.541778153539695],
              [-70.564855150862741, -33.54552172745246],
              [-70.570041578991948, -33.54552172745246],
              [-70.572634793056565, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 687,
        left: -7856109.7691027177,
        bottom: -3966938.7337260107,
        right: -7854955.0680259597,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.534290519247627],
              [-70.570041578991948, -33.530546458886235],
              [-70.564855150862741, -33.530546458886235],
              [-70.562261936798137, -33.534290519247627],
              [-70.564855150862741, -33.538034417468438],
              [-70.570041578991948, -33.538034417468438],
              [-70.572634793056565, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 688,
        left: -7856109.7691027177,
        bottom: -3965938.7332597603,
        right: -7854955.0680259597,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.526802236393216],
              [-70.570041578991948, -33.523057851777558],
              [-70.564855150862741, -33.523057851777558],
              [-70.562261936798137, -33.526802236393216],
              [-70.564855150862741, -33.530546458886235],
              [-70.570041578991948, -33.530546458886235],
              [-70.572634793056565, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 689,
        left: -7856109.7691027177,
        bottom: -3964938.7327935095,
        right: -7854955.0680259597,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.519313305048215],
              [-70.570041578991948, -33.515568596214173],
              [-70.564855150862741, -33.515568596214173],
              [-70.562261936798137, -33.519313305048215],
              [-70.564855150862741, -33.523057851777558],
              [-70.570041578991948, -33.523057851777558],
              [-70.572634793056565, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 690,
        left: -7856109.7691027177,
        bottom: -3963938.7323272596,
        right: -7854955.0680259597,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.511823725284408],
              [-70.570041578991948, -33.508078692267915],
              [-70.564855150862741, -33.508078692267915],
              [-70.562261936798137, -33.511823725284408],
              [-70.564855150862741, -33.515568596214173],
              [-70.570041578991948, -33.515568596214173],
              [-70.572634793056565, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 691,
        left: -7856109.7691027177,
        bottom: -3962938.7318610097,
        right: -7854955.0680259597,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.504333497173661],
              [-70.570041578991948, -33.500588140010635],
              [-70.564855150862741, -33.500588140010635],
              [-70.562261936798137, -33.504333497173661],
              [-70.564855150862741, -33.508078692267915],
              [-70.570041578991948, -33.508078692267915],
              [-70.572634793056565, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 692,
        left: -7856109.7691027177,
        bottom: -3961938.7313947589,
        right: -7854955.0680259597,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.496842620787838],
              [-70.570041578991948, -33.493096939514267],
              [-70.564855150862741, -33.493096939514267],
              [-70.562261936798137, -33.496842620787838],
              [-70.564855150862741, -33.500588140010635],
              [-70.570041578991948, -33.500588140010635],
              [-70.572634793056565, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 693,
        left: -7856109.7691027177,
        bottom: -3960938.7309285086,
        right: -7854955.0680259597,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.489351096198902],
              [-70.570041578991948, -33.485605090850768],
              [-70.564855150862741, -33.485605090850768],
              [-70.562261936798137, -33.489351096198902],
              [-70.564855150862741, -33.493096939514267],
              [-70.570041578991948, -33.493096939514267],
              [-70.572634793056565, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 694,
        left: -7856109.7691027177,
        bottom: -3959938.7304622582,
        right: -7854955.0680259597,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.48185892347886],
              [-70.570041578991948, -33.478112594092174],
              [-70.564855150862741, -33.478112594092174],
              [-70.562261936798137, -33.48185892347886],
              [-70.564855150862741, -33.485605090850768],
              [-70.570041578991948, -33.485605090850768],
              [-70.572634793056565, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 695,
        left: -7856109.7691027177,
        bottom: -3958938.7299960079,
        right: -7854955.0680259597,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.474366102699733],
              [-70.570041578991948, -33.470619449310547],
              [-70.564855150862741, -33.470619449310547],
              [-70.562261936798137, -33.474366102699733],
              [-70.564855150862741, -33.478112594092174],
              [-70.570041578991948, -33.478112594092174],
              [-70.572634793056565, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 696,
        left: -7856109.7691027177,
        bottom: -3957938.7295297575,
        right: -7854955.0680259597,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.466872633933626],
              [-70.570041578991948, -33.463125656578008],
              [-70.564855150862741, -33.463125656578008],
              [-70.562261936798137, -33.466872633933626],
              [-70.564855150862741, -33.470619449310547],
              [-70.570041578991948, -33.470619449310547],
              [-70.572634793056565, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 697,
        left: -7856109.7691027177,
        bottom: -3956938.7290635072,
        right: -7854955.0680259597,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.459378517252702],
              [-70.570041578991948, -33.45563121596674],
              [-70.564855150862741, -33.45563121596674],
              [-70.562261936798137, -33.459378517252702],
              [-70.564855150862741, -33.463125656578008],
              [-70.570041578991948, -33.463125656578008],
              [-70.572634793056565, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 698,
        left: -7856109.7691027177,
        bottom: -3955938.7285972568,
        right: -7854955.0680259597,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.451883752729138],
              [-70.570041578991948, -33.448136127548949],
              [-70.564855150862741, -33.448136127548949],
              [-70.562261936798137, -33.451883752729138],
              [-70.564855150862741, -33.45563121596674],
              [-70.570041578991948, -33.45563121596674],
              [-70.572634793056565, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 699,
        left: -7856109.7691027177,
        bottom: -3954938.7281310065,
        right: -7854955.0680259597,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.444388340435196],
              [-70.570041578991948, -33.440640391396926],
              [-70.564855150862741, -33.440640391396926],
              [-70.562261936798137, -33.444388340435196],
              [-70.564855150862741, -33.448136127548949],
              [-70.570041578991948, -33.448136127548949],
              [-70.572634793056565, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 700,
        left: -7856109.7691027177,
        bottom: -3953938.7276647561,
        right: -7854955.0680259597,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.436892280443168],
              [-70.570041578991948, -33.433144007582989],
              [-70.564855150862741, -33.433144007582989],
              [-70.562261936798137, -33.436892280443168],
              [-70.564855150862741, -33.440640391396926],
              [-70.570041578991948, -33.440640391396926],
              [-70.572634793056565, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 701,
        left: -7856109.7691027177,
        bottom: -3952938.7271985058,
        right: -7854955.0680259597,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.429395572825413],
              [-70.570041578991948, -33.425646976179515],
              [-70.564855150862741, -33.425646976179515],
              [-70.562261936798137, -33.429395572825413],
              [-70.564855150862741, -33.433144007582989],
              [-70.570041578991948, -33.433144007582989],
              [-70.572634793056565, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 702,
        left: -7856109.7691027177,
        bottom: -3951938.7267322554,
        right: -7854955.0680259597,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.421898217654316],
              [-70.570041578991948, -33.418149297258921],
              [-70.564855150862741, -33.418149297258921],
              [-70.562261936798137, -33.421898217654316],
              [-70.564855150862741, -33.425646976179515],
              [-70.570041578991948, -33.425646976179515],
              [-70.572634793056565, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 703,
        left: -7856109.7691027177,
        bottom: -3950938.7262660051,
        right: -7854955.0680259597,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.414400215002352],
              [-70.570041578991948, -33.410650970893691],
              [-70.564855150862741, -33.410650970893691],
              [-70.562261936798137, -33.414400215002352],
              [-70.564855150862741, -33.418149297258921],
              [-70.570041578991948, -33.418149297258921],
              [-70.572634793056565, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 704,
        left: -7856109.7691027177,
        bottom: -3949938.7257997552,
        right: -7854955.0680259597,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.406901564941997],
              [-70.570041578991948, -33.40315199715635],
              [-70.564855150862741, -33.40315199715635],
              [-70.562261936798137, -33.406901564941997],
              [-70.564855150862741, -33.410650970893691],
              [-70.570041578991948, -33.410650970893691],
              [-70.572634793056565, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 705,
        left: -7856109.7691027177,
        bottom: -3948938.7253335048,
        right: -7854955.0680259597,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.399402267545824],
              [-70.570041578991948, -33.395652376119486],
              [-70.564855150862741, -33.395652376119486],
              [-70.562261936798137, -33.399402267545824],
              [-70.564855150862741, -33.40315199715635],
              [-70.570041578991948, -33.40315199715635],
              [-70.572634793056565, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 706,
        left: -7856109.7691027177,
        bottom: -3947938.724867254,
        right: -7854955.0680259597,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.391902322886416],
              [-70.570041578991948, -33.388152107855717],
              [-70.564855150862741, -33.388152107855717],
              [-70.562261936798137, -33.391902322886416],
              [-70.564855150862741, -33.395652376119486],
              [-70.570041578991948, -33.395652376119486],
              [-70.572634793056565, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 707,
        left: -7856109.7691027177,
        bottom: -3946938.7244010041,
        right: -7854955.0680259597,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.384401731036441],
              [-70.570041578991948, -33.380651192437711],
              [-70.564855150862741, -33.380651192437711],
              [-70.562261936798137, -33.384401731036441],
              [-70.564855150862741, -33.388152107855717],
              [-70.570041578991948, -33.388152107855717],
              [-70.572634793056565, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 708,
        left: -7856109.7691027177,
        bottom: -3945938.7239347543,
        right: -7854955.0680259597,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.3769004920686],
              [-70.570041578991948, -33.373149629938226],
              [-70.564855150862741, -33.373149629938226],
              [-70.562261936798137, -33.3769004920686],
              [-70.564855150862741, -33.380651192437711],
              [-70.570041578991948, -33.380651192437711],
              [-70.572634793056565, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 709,
        left: -7856109.7691027177,
        bottom: -3944938.7234685034,
        right: -7854955.0680259597,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.369398606055647],
              [-70.570041578991948, -33.365647420430008],
              [-70.564855150862741, -33.365647420430008],
              [-70.562261936798137, -33.369398606055647],
              [-70.564855150862741, -33.373149629938226],
              [-70.570041578991948, -33.373149629938226],
              [-70.572634793056565, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 710,
        left: -7856109.7691027177,
        bottom: -3943938.7230022531,
        right: -7854955.0680259597,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.36189607307039],
              [-70.570041578991948, -33.358144563985917],
              [-70.564855150862741, -33.358144563985917],
              [-70.562261936798137, -33.36189607307039],
              [-70.564855150862741, -33.365647420430008],
              [-70.570041578991948, -33.365647420430008],
              [-70.572634793056565, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 711,
        left: -7856109.7691027177,
        bottom: -3942938.7225360028,
        right: -7854955.0680259597,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.35439289318569],
              [-70.570041578991948, -33.350641060678818],
              [-70.564855150862741, -33.350641060678818],
              [-70.562261936798137, -33.35439289318569],
              [-70.564855150862741, -33.358144563985917],
              [-70.570041578991948, -33.358144563985917],
              [-70.572634793056565, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 712,
        left: -7856109.7691027177,
        bottom: -3941938.7220697524,
        right: -7854955.0680259597,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.346889066474425],
              [-70.570041578991948, -33.343136910581649],
              [-70.564855150862741, -33.343136910581649],
              [-70.562261936798137, -33.346889066474425],
              [-70.564855150862741, -33.350641060678818],
              [-70.570041578991948, -33.350641060678818],
              [-70.572634793056565, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 713,
        left: -7856109.7691027177,
        bottom: -3940938.7216035021,
        right: -7854955.0680259597,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.339384593009591],
              [-70.570041578991948, -33.335632113767389],
              [-70.564855150862741, -33.335632113767389],
              [-70.562261936798137, -33.339384593009591],
              [-70.564855150862741, -33.343136910581649],
              [-70.570041578991948, -33.343136910581649],
              [-70.572634793056565, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 714,
        left: -7856109.7691027177,
        bottom: -3939938.7211372517,
        right: -7854955.0680259597,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.331879472864181],
              [-70.570041578991948, -33.328126670309082],
              [-70.564855150862741, -33.328126670309082],
              [-70.562261936798137, -33.331879472864181],
              [-70.564855150862741, -33.335632113767389],
              [-70.570041578991948, -33.335632113767389],
              [-70.572634793056565, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 715,
        left: -7856109.7691027177,
        bottom: -3938938.7206710014,
        right: -7854955.0680259597,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.324373706111238],
              [-70.570041578991948, -33.320620580279794],
              [-70.564855150862741, -33.320620580279794],
              [-70.562261936798137, -33.324373706111238],
              [-70.564855150862741, -33.328126670309082],
              [-70.570041578991948, -33.328126670309082],
              [-70.572634793056565, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 716,
        left: -7856109.7691027177,
        bottom: -3937938.720204751,
        right: -7854955.0680259597,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.3168672928239],
              [-70.570041578991948, -33.313113843752674],
              [-70.564855150862741, -33.313113843752674],
              [-70.562261936798137, -33.3168672928239],
              [-70.564855150862741, -33.320620580279794],
              [-70.570041578991948, -33.320620580279794],
              [-70.572634793056565, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 717,
        left: -7856109.7691027177,
        bottom: -3936938.7197385007,
        right: -7854955.0680259597,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.309360233075296],
              [-70.570041578991948, -33.305606460800909],
              [-70.564855150862741, -33.305606460800909],
              [-70.562261936798137, -33.309360233075296],
              [-70.564855150862741, -33.313113843752674],
              [-70.570041578991948, -33.313113843752674],
              [-70.572634793056565, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 718,
        left: -7856109.7691027177,
        bottom: -3935938.7192722503,
        right: -7854955.0680259597,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.30185252693866],
              [-70.570041578991948, -33.298098431497721],
              [-70.564855150862741, -33.298098431497721],
              [-70.562261936798137, -33.30185252693866],
              [-70.564855150862741, -33.305606460800909],
              [-70.570041578991948, -33.305606460800909],
              [-70.572634793056565, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 719,
        left: -7856109.7691027177,
        bottom: -3934938.718806,
        right: -7854955.0680259597,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.294344174487243],
              [-70.570041578991948, -33.290589755916407],
              [-70.564855150862741, -33.290589755916407],
              [-70.562261936798137, -33.294344174487243],
              [-70.564855150862741, -33.298098431497721],
              [-70.570041578991948, -33.298098431497721],
              [-70.572634793056565, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 720,
        left: -7856109.7691027177,
        bottom: -3933938.7183397496,
        right: -7854955.0680259597,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.572634793056565, -33.286835175794366],
              [-70.570041578991948, -33.283080434130305],
              [-70.564855150862741, -33.283080434130305],
              [-70.562261936798137, -33.286835175794366],
              [-70.564855150862741, -33.290589755916407],
              [-70.570041578991948, -33.290589755916407],
              [-70.572634793056565, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 721,
        left: -7855243.7432951499,
        bottom: -3980438.7400203901,
        right: -7854089.0422183899,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.63531883056551],
              [-70.562261936798137, -33.631579151136748],
              [-70.557075508668916, -33.631579151136748],
              [-70.554482294604327, -33.63531883056551],
              [-70.557075508668916, -33.639058347612803],
              [-70.562261936798137, -33.639058347612803],
              [-70.564855150862741, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 722,
        left: -7855243.7432951499,
        bottom: -3979438.7395541398,
        right: -7854089.0422183899,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.627839309335386],
              [-70.562261936798137, -33.624099305170297],
              [-70.557075508668916, -33.624099305170297],
              [-70.554482294604327, -33.627839309335386],
              [-70.557075508668916, -33.631579151136748],
              [-70.562261936798137, -33.631579151136748],
              [-70.564855150862741, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 723,
        left: -7855243.7432951499,
        bottom: -3978438.7390878894,
        right: -7854089.0422183899,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.620359138650379],
              [-70.562261936798137, -33.616618809784534],
              [-70.557075508668916, -33.616618809784534],
              [-70.554482294604327, -33.620359138650379],
              [-70.557075508668916, -33.624099305170297],
              [-70.562261936798137, -33.624099305170297],
              [-70.564855150862741, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 724,
        left: -7855243.7432951499,
        bottom: -3977438.7386216391,
        right: -7854089.0422183899,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.612878318581629],
              [-70.562261936798137, -33.609137665050582],
              [-70.557075508668916, -33.609137665050582],
              [-70.554482294604327, -33.612878318581629],
              [-70.557075508668916, -33.616618809784534],
              [-70.562261936798137, -33.616618809784534],
              [-70.564855150862741, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 725,
        left: -7855243.7432951499,
        bottom: -3976438.7381553887,
        right: -7854089.0422183899,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.605396849200282],
              [-70.562261936798137, -33.601655871039647],
              [-70.557075508668916, -33.601655871039647],
              [-70.554482294604327, -33.605396849200282],
              [-70.557075508668916, -33.609137665050582],
              [-70.562261936798137, -33.609137665050582],
              [-70.564855150862741, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 726,
        left: -7855243.7432951499,
        bottom: -3975438.7376891384,
        right: -7854089.0422183899,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.597914730577571],
              [-70.562261936798137, -33.594173427822959],
              [-70.557075508668916, -33.594173427822959],
              [-70.554482294604327, -33.597914730577571],
              [-70.557075508668916, -33.601655871039647],
              [-70.562261936798137, -33.601655871039647],
              [-70.564855150862741, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 727,
        left: -7855243.7432951499,
        bottom: -3974438.7372228876,
        right: -7854089.0422183899,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.590431962784734],
              [-70.562261936798137, -33.586690335471808],
              [-70.557075508668916, -33.586690335471808],
              [-70.554482294604327, -33.590431962784734],
              [-70.557075508668916, -33.594173427822959],
              [-70.562261936798137, -33.594173427822959],
              [-70.564855150862741, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 728,
        left: -7855243.7432951499,
        bottom: -3973438.7367566377,
        right: -7854089.0422183899,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.582948545893096],
              [-70.562261936798137, -33.579206594057531],
              [-70.557075508668916, -33.579206594057531],
              [-70.554482294604327, -33.582948545893096],
              [-70.557075508668916, -33.586690335471808],
              [-70.562261936798137, -33.586690335471808],
              [-70.564855150862741, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 729,
        left: -7855243.7432951499,
        bottom: -3972438.7362903878,
        right: -7854089.0422183899,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.575464479974038],
              [-70.562261936798137, -33.571722203651518],
              [-70.557075508668916, -33.571722203651518],
              [-70.554482294604327, -33.575464479974038],
              [-70.557075508668916, -33.579206594057531],
              [-70.562261936798137, -33.579206594057531],
              [-70.564855150862741, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 730,
        left: -7855243.7432951499,
        bottom: -3971438.735824137,
        right: -7854089.0422183899,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.56797976509894],
              [-70.562261936798137, -33.56423716432522],
              [-70.557075508668916, -33.56423716432522],
              [-70.554482294604327, -33.56797976509894],
              [-70.557075508668916, -33.571722203651518],
              [-70.562261936798137, -33.571722203651518],
              [-70.564855150862741, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 731,
        left: -7855243.7432951499,
        bottom: -3970438.7353578866,
        right: -7854089.0422183899,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.560494401339298],
              [-70.562261936798137, -33.556751476150104],
              [-70.557075508668916, -33.556751476150104],
              [-70.554482294604327, -33.560494401339298],
              [-70.557075508668916, -33.56423716432522],
              [-70.562261936798137, -33.56423716432522],
              [-70.564855150862741, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 732,
        left: -7855243.7432951499,
        bottom: -3969438.7348916363,
        right: -7854089.0422183899,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.553008388766614],
              [-70.562261936798137, -33.549265139197736],
              [-70.557075508668916, -33.549265139197736],
              [-70.554482294604327, -33.553008388766614],
              [-70.557075508668916, -33.556751476150104],
              [-70.562261936798137, -33.556751476150104],
              [-70.564855150862741, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 733,
        left: -7855243.7432951499,
        bottom: -3968438.7344253859,
        right: -7854089.0422183899,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.54552172745246],
              [-70.562261936798137, -33.541778153539695],
              [-70.557075508668916, -33.541778153539695],
              [-70.554482294604327, -33.54552172745246],
              [-70.557075508668916, -33.549265139197736],
              [-70.562261936798137, -33.549265139197736],
              [-70.564855150862741, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 734,
        left: -7855243.7432951499,
        bottom: -3967438.7339591356,
        right: -7854089.0422183899,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.538034417468438],
              [-70.562261936798137, -33.534290519247627],
              [-70.557075508668916, -33.534290519247627],
              [-70.554482294604327, -33.538034417468438],
              [-70.557075508668916, -33.541778153539695],
              [-70.562261936798137, -33.541778153539695],
              [-70.564855150862741, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 735,
        left: -7855243.7432951499,
        bottom: -3966438.7334928853,
        right: -7854089.0422183899,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.530546458886235],
              [-70.562261936798137, -33.526802236393216],
              [-70.557075508668916, -33.526802236393216],
              [-70.554482294604327, -33.530546458886235],
              [-70.557075508668916, -33.534290519247627],
              [-70.562261936798137, -33.534290519247627],
              [-70.564855150862741, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 736,
        left: -7855243.7432951499,
        bottom: -3965438.7330266349,
        right: -7854089.0422183899,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.523057851777558],
              [-70.562261936798137, -33.519313305048215],
              [-70.557075508668916, -33.519313305048215],
              [-70.554482294604327, -33.523057851777558],
              [-70.557075508668916, -33.526802236393216],
              [-70.562261936798137, -33.526802236393216],
              [-70.564855150862741, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 737,
        left: -7855243.7432951499,
        bottom: -3964438.7325603846,
        right: -7854089.0422183899,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.515568596214173],
              [-70.562261936798137, -33.511823725284408],
              [-70.557075508668916, -33.511823725284408],
              [-70.554482294604327, -33.515568596214173],
              [-70.557075508668916, -33.519313305048215],
              [-70.562261936798137, -33.519313305048215],
              [-70.564855150862741, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 738,
        left: -7855243.7432951499,
        bottom: -3963438.7320941342,
        right: -7854089.0422183899,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.508078692267915],
              [-70.562261936798137, -33.504333497173661],
              [-70.557075508668916, -33.504333497173661],
              [-70.554482294604327, -33.508078692267915],
              [-70.557075508668916, -33.511823725284408],
              [-70.562261936798137, -33.511823725284408],
              [-70.564855150862741, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 739,
        left: -7855243.7432951499,
        bottom: -3962438.7316278839,
        right: -7854089.0422183899,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.500588140010635],
              [-70.562261936798137, -33.496842620787838],
              [-70.557075508668916, -33.496842620787838],
              [-70.554482294604327, -33.500588140010635],
              [-70.557075508668916, -33.504333497173661],
              [-70.562261936798137, -33.504333497173661],
              [-70.564855150862741, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 740,
        left: -7855243.7432951499,
        bottom: -3961438.7311616335,
        right: -7854089.0422183899,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.493096939514267],
              [-70.562261936798137, -33.489351096198902],
              [-70.557075508668916, -33.489351096198902],
              [-70.554482294604327, -33.493096939514267],
              [-70.557075508668916, -33.496842620787838],
              [-70.562261936798137, -33.496842620787838],
              [-70.564855150862741, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 741,
        left: -7855243.7432951499,
        bottom: -3960438.7306953832,
        right: -7854089.0422183899,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.485605090850768],
              [-70.562261936798137, -33.48185892347886],
              [-70.557075508668916, -33.48185892347886],
              [-70.554482294604327, -33.485605090850768],
              [-70.557075508668916, -33.489351096198902],
              [-70.562261936798137, -33.489351096198902],
              [-70.564855150862741, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 742,
        left: -7855243.7432951499,
        bottom: -3959438.7302291333,
        right: -7854089.0422183899,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.478112594092174],
              [-70.562261936798137, -33.474366102699733],
              [-70.557075508668916, -33.474366102699733],
              [-70.554482294604327, -33.478112594092174],
              [-70.557075508668916, -33.48185892347886],
              [-70.562261936798137, -33.48185892347886],
              [-70.564855150862741, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 743,
        left: -7855243.7432951499,
        bottom: -3958438.7297628829,
        right: -7854089.0422183899,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.470619449310547],
              [-70.562261936798137, -33.466872633933626],
              [-70.557075508668916, -33.466872633933626],
              [-70.554482294604327, -33.470619449310547],
              [-70.557075508668916, -33.474366102699733],
              [-70.562261936798137, -33.474366102699733],
              [-70.564855150862741, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 744,
        left: -7855243.7432951499,
        bottom: -3957438.7292966326,
        right: -7854089.0422183899,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.463125656578008],
              [-70.562261936798137, -33.459378517252702],
              [-70.557075508668916, -33.459378517252702],
              [-70.554482294604327, -33.463125656578008],
              [-70.557075508668916, -33.466872633933626],
              [-70.562261936798137, -33.466872633933626],
              [-70.564855150862741, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 745,
        left: -7855243.7432951499,
        bottom: -3956438.7288303822,
        right: -7854089.0422183899,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.45563121596674],
              [-70.562261936798137, -33.451883752729138],
              [-70.557075508668916, -33.451883752729138],
              [-70.554482294604327, -33.45563121596674],
              [-70.557075508668916, -33.459378517252702],
              [-70.562261936798137, -33.459378517252702],
              [-70.564855150862741, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 746,
        left: -7855243.7432951499,
        bottom: -3955438.7283641323,
        right: -7854089.0422183899,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.448136127548949],
              [-70.562261936798137, -33.444388340435196],
              [-70.557075508668916, -33.444388340435196],
              [-70.554482294604327, -33.448136127548949],
              [-70.557075508668916, -33.451883752729138],
              [-70.562261936798137, -33.451883752729138],
              [-70.564855150862741, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 747,
        left: -7855243.7432951499,
        bottom: -3954438.7278978815,
        right: -7854089.0422183899,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.440640391396926],
              [-70.562261936798137, -33.436892280443168],
              [-70.557075508668916, -33.436892280443168],
              [-70.554482294604327, -33.440640391396926],
              [-70.557075508668916, -33.444388340435196],
              [-70.562261936798137, -33.444388340435196],
              [-70.564855150862741, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 748,
        left: -7855243.7432951499,
        bottom: -3953438.7274316312,
        right: -7854089.0422183899,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.433144007582989],
              [-70.562261936798137, -33.429395572825413],
              [-70.557075508668916, -33.429395572825413],
              [-70.554482294604327, -33.433144007582989],
              [-70.557075508668916, -33.436892280443168],
              [-70.562261936798137, -33.436892280443168],
              [-70.564855150862741, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 749,
        left: -7855243.7432951499,
        bottom: -3952438.7269653808,
        right: -7854089.0422183899,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.425646976179515],
              [-70.562261936798137, -33.421898217654316],
              [-70.557075508668916, -33.421898217654316],
              [-70.554482294604327, -33.425646976179515],
              [-70.557075508668916, -33.429395572825413],
              [-70.562261936798137, -33.429395572825413],
              [-70.564855150862741, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 750,
        left: -7855243.7432951499,
        bottom: -3951438.7264991305,
        right: -7854089.0422183899,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.418149297258921],
              [-70.562261936798137, -33.414400215002352],
              [-70.557075508668916, -33.414400215002352],
              [-70.554482294604327, -33.418149297258921],
              [-70.557075508668916, -33.421898217654316],
              [-70.562261936798137, -33.421898217654316],
              [-70.564855150862741, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 751,
        left: -7855243.7432951499,
        bottom: -3950438.7260328801,
        right: -7854089.0422183899,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.410650970893691],
              [-70.562261936798137, -33.406901564941997],
              [-70.557075508668916, -33.406901564941997],
              [-70.554482294604327, -33.410650970893691],
              [-70.557075508668916, -33.414400215002352],
              [-70.562261936798137, -33.414400215002352],
              [-70.564855150862741, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 752,
        left: -7855243.7432951499,
        bottom: -3949438.7255666298,
        right: -7854089.0422183899,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.40315199715635],
              [-70.562261936798137, -33.399402267545824],
              [-70.557075508668916, -33.399402267545824],
              [-70.554482294604327, -33.40315199715635],
              [-70.557075508668916, -33.406901564941997],
              [-70.562261936798137, -33.406901564941997],
              [-70.564855150862741, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 753,
        left: -7855243.7432951499,
        bottom: -3948438.7251003794,
        right: -7854089.0422183899,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.395652376119486],
              [-70.562261936798137, -33.391902322886416],
              [-70.557075508668916, -33.391902322886416],
              [-70.554482294604327, -33.395652376119486],
              [-70.557075508668916, -33.399402267545824],
              [-70.562261936798137, -33.399402267545824],
              [-70.564855150862741, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 754,
        left: -7855243.7432951499,
        bottom: -3947438.7246341291,
        right: -7854089.0422183899,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.388152107855717],
              [-70.562261936798137, -33.384401731036441],
              [-70.557075508668916, -33.384401731036441],
              [-70.554482294604327, -33.388152107855717],
              [-70.557075508668916, -33.391902322886416],
              [-70.562261936798137, -33.391902322886416],
              [-70.564855150862741, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 755,
        left: -7855243.7432951499,
        bottom: -3946438.7241678787,
        right: -7854089.0422183899,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.380651192437711],
              [-70.562261936798137, -33.3769004920686],
              [-70.557075508668916, -33.3769004920686],
              [-70.554482294604327, -33.380651192437711],
              [-70.557075508668916, -33.384401731036441],
              [-70.562261936798137, -33.384401731036441],
              [-70.564855150862741, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 756,
        left: -7855243.7432951499,
        bottom: -3945438.7237016284,
        right: -7854089.0422183899,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.373149629938226],
              [-70.562261936798137, -33.369398606055647],
              [-70.557075508668916, -33.369398606055647],
              [-70.554482294604327, -33.373149629938226],
              [-70.557075508668916, -33.3769004920686],
              [-70.562261936798137, -33.3769004920686],
              [-70.564855150862741, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 757,
        left: -7855243.7432951499,
        bottom: -3944438.723235378,
        right: -7854089.0422183899,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.365647420430008],
              [-70.562261936798137, -33.36189607307039],
              [-70.557075508668916, -33.36189607307039],
              [-70.554482294604327, -33.365647420430008],
              [-70.557075508668916, -33.369398606055647],
              [-70.562261936798137, -33.369398606055647],
              [-70.564855150862741, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 758,
        left: -7855243.7432951499,
        bottom: -3943438.7227691277,
        right: -7854089.0422183899,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.358144563985917],
              [-70.562261936798137, -33.35439289318569],
              [-70.557075508668916, -33.35439289318569],
              [-70.554482294604327, -33.358144563985917],
              [-70.557075508668916, -33.36189607307039],
              [-70.562261936798137, -33.36189607307039],
              [-70.564855150862741, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 759,
        left: -7855243.7432951499,
        bottom: -3942438.7223028769,
        right: -7854089.0422183899,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.350641060678818],
              [-70.562261936798137, -33.346889066474425],
              [-70.557075508668916, -33.346889066474425],
              [-70.554482294604327, -33.350641060678818],
              [-70.557075508668916, -33.35439289318569],
              [-70.562261936798137, -33.35439289318569],
              [-70.564855150862741, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 760,
        left: -7855243.7432951499,
        bottom: -3941438.721836627,
        right: -7854089.0422183899,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.343136910581649],
              [-70.562261936798137, -33.339384593009591],
              [-70.557075508668916, -33.339384593009591],
              [-70.554482294604327, -33.343136910581649],
              [-70.557075508668916, -33.346889066474425],
              [-70.562261936798137, -33.346889066474425],
              [-70.564855150862741, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 761,
        left: -7855243.7432951499,
        bottom: -3940438.7213703771,
        right: -7854089.0422183899,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.335632113767389],
              [-70.562261936798137, -33.331879472864181],
              [-70.557075508668916, -33.331879472864181],
              [-70.554482294604327, -33.335632113767389],
              [-70.557075508668916, -33.339384593009591],
              [-70.562261936798137, -33.339384593009591],
              [-70.564855150862741, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 762,
        left: -7855243.7432951499,
        bottom: -3939438.7209041263,
        right: -7854089.0422183899,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.328126670309082],
              [-70.562261936798137, -33.324373706111238],
              [-70.557075508668916, -33.324373706111238],
              [-70.554482294604327, -33.328126670309082],
              [-70.557075508668916, -33.331879472864181],
              [-70.562261936798137, -33.331879472864181],
              [-70.564855150862741, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 763,
        left: -7855243.7432951499,
        bottom: -3938438.7204378759,
        right: -7854089.0422183899,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.320620580279794],
              [-70.562261936798137, -33.3168672928239],
              [-70.557075508668916, -33.3168672928239],
              [-70.554482294604327, -33.320620580279794],
              [-70.557075508668916, -33.324373706111238],
              [-70.562261936798137, -33.324373706111238],
              [-70.564855150862741, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 764,
        left: -7855243.7432951499,
        bottom: -3937438.7199716261,
        right: -7854089.0422183899,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.313113843752674],
              [-70.562261936798137, -33.309360233075296],
              [-70.557075508668916, -33.309360233075296],
              [-70.554482294604327, -33.313113843752674],
              [-70.557075508668916, -33.3168672928239],
              [-70.562261936798137, -33.3168672928239],
              [-70.564855150862741, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 765,
        left: -7855243.7432951499,
        bottom: -3936438.7195053762,
        right: -7854089.0422183899,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.305606460800909],
              [-70.562261936798137, -33.30185252693866],
              [-70.557075508668916, -33.30185252693866],
              [-70.554482294604327, -33.305606460800909],
              [-70.557075508668916, -33.309360233075296],
              [-70.562261936798137, -33.309360233075296],
              [-70.564855150862741, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 766,
        left: -7855243.7432951499,
        bottom: -3935438.7190391254,
        right: -7854089.0422183899,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.298098431497721],
              [-70.562261936798137, -33.294344174487243],
              [-70.557075508668916, -33.294344174487243],
              [-70.554482294604327, -33.298098431497721],
              [-70.557075508668916, -33.30185252693866],
              [-70.562261936798137, -33.30185252693866],
              [-70.564855150862741, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 767,
        left: -7855243.7432951499,
        bottom: -3934438.718572875,
        right: -7854089.0422183899,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.290589755916407],
              [-70.562261936798137, -33.286835175794366],
              [-70.557075508668916, -33.286835175794366],
              [-70.554482294604327, -33.290589755916407],
              [-70.557075508668916, -33.294344174487243],
              [-70.562261936798137, -33.294344174487243],
              [-70.564855150862741, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 768,
        left: -7855243.7432951499,
        bottom: -3933438.7181066247,
        right: -7854089.0422183899,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.564855150862741, -33.283080434130305],
              [-70.562261936798137, -33.279325530933384],
              [-70.557075508668916, -33.279325530933384],
              [-70.554482294604327, -33.283080434130305],
              [-70.557075508668916, -33.286835175794366],
              [-70.562261936798137, -33.286835175794366],
              [-70.564855150862741, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 769,
        left: -7854377.7174875792,
        bottom: -3980938.7402535151,
        right: -7853223.0164108202,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.639058347612803],
              [-70.554482294604327, -33.63531883056551],
              [-70.54929586647512, -33.63531883056551],
              [-70.546702652410517, -33.639058347612803],
              [-70.54929586647512, -33.642797702269725],
              [-70.554482294604327, -33.642797702269725],
              [-70.557075508668916, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 770,
        left: -7854377.7174875792,
        bottom: -3979938.7397872647,
        right: -7853223.0164108202,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.631579151136748],
              [-70.554482294604327, -33.627839309335386],
              [-70.54929586647512, -33.627839309335386],
              [-70.546702652410517, -33.631579151136748],
              [-70.54929586647512, -33.63531883056551],
              [-70.554482294604327, -33.63531883056551],
              [-70.557075508668916, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 771,
        left: -7854377.7174875792,
        bottom: -3978938.7393210144,
        right: -7853223.0164108202,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.624099305170297],
              [-70.554482294604327, -33.620359138650379],
              [-70.54929586647512, -33.620359138650379],
              [-70.546702652410517, -33.624099305170297],
              [-70.54929586647512, -33.627839309335386],
              [-70.554482294604327, -33.627839309335386],
              [-70.557075508668916, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 772,
        left: -7854377.7174875792,
        bottom: -3977938.738854764,
        right: -7853223.0164108202,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.616618809784534],
              [-70.554482294604327, -33.612878318581629],
              [-70.54929586647512, -33.612878318581629],
              [-70.546702652410517, -33.616618809784534],
              [-70.54929586647512, -33.620359138650379],
              [-70.554482294604327, -33.620359138650379],
              [-70.557075508668916, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 773,
        left: -7854377.7174875792,
        bottom: -3976938.7383885137,
        right: -7853223.0164108202,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.609137665050582],
              [-70.554482294604327, -33.605396849200282],
              [-70.54929586647512, -33.605396849200282],
              [-70.546702652410517, -33.609137665050582],
              [-70.54929586647512, -33.612878318581629],
              [-70.554482294604327, -33.612878318581629],
              [-70.557075508668916, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 774,
        left: -7854377.7174875792,
        bottom: -3975938.7379222633,
        right: -7853223.0164108202,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.601655871039647],
              [-70.554482294604327, -33.597914730577571],
              [-70.54929586647512, -33.597914730577571],
              [-70.546702652410517, -33.601655871039647],
              [-70.54929586647512, -33.605396849200282],
              [-70.554482294604327, -33.605396849200282],
              [-70.557075508668916, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 775,
        left: -7854377.7174875792,
        bottom: -3974938.737456013,
        right: -7853223.0164108202,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.594173427822959],
              [-70.554482294604327, -33.590431962784734],
              [-70.54929586647512, -33.590431962784734],
              [-70.546702652410517, -33.594173427822959],
              [-70.54929586647512, -33.597914730577571],
              [-70.554482294604327, -33.597914730577571],
              [-70.557075508668916, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 776,
        left: -7854377.7174875792,
        bottom: -3973938.7369897626,
        right: -7853223.0164108202,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.586690335471808],
              [-70.554482294604327, -33.582948545893096],
              [-70.54929586647512, -33.582948545893096],
              [-70.546702652410517, -33.586690335471808],
              [-70.54929586647512, -33.590431962784734],
              [-70.554482294604327, -33.590431962784734],
              [-70.557075508668916, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 777,
        left: -7854377.7174875792,
        bottom: -3972938.7365235123,
        right: -7853223.0164108202,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.579206594057531],
              [-70.554482294604327, -33.575464479974038],
              [-70.54929586647512, -33.575464479974038],
              [-70.546702652410517, -33.579206594057531],
              [-70.54929586647512, -33.582948545893096],
              [-70.554482294604327, -33.582948545893096],
              [-70.557075508668916, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 778,
        left: -7854377.7174875792,
        bottom: -3971938.7360572619,
        right: -7853223.0164108202,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.571722203651518],
              [-70.554482294604327, -33.56797976509894],
              [-70.54929586647512, -33.56797976509894],
              [-70.546702652410517, -33.571722203651518],
              [-70.54929586647512, -33.575464479974038],
              [-70.554482294604327, -33.575464479974038],
              [-70.557075508668916, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 779,
        left: -7854377.7174875792,
        bottom: -3970938.7355910116,
        right: -7853223.0164108202,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.56423716432522],
              [-70.554482294604327, -33.560494401339298],
              [-70.54929586647512, -33.560494401339298],
              [-70.546702652410517, -33.56423716432522],
              [-70.54929586647512, -33.56797976509894],
              [-70.554482294604327, -33.56797976509894],
              [-70.557075508668916, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 780,
        left: -7854377.7174875792,
        bottom: -3969938.7351247617,
        right: -7853223.0164108202,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.556751476150104],
              [-70.554482294604327, -33.553008388766614],
              [-70.54929586647512, -33.553008388766614],
              [-70.546702652410517, -33.556751476150104],
              [-70.54929586647512, -33.560494401339298],
              [-70.554482294604327, -33.560494401339298],
              [-70.557075508668916, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 781,
        left: -7854377.7174875792,
        bottom: -3968938.7346585114,
        right: -7853223.0164108202,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.549265139197736],
              [-70.554482294604327, -33.54552172745246],
              [-70.54929586647512, -33.54552172745246],
              [-70.546702652410517, -33.549265139197736],
              [-70.54929586647512, -33.553008388766614],
              [-70.554482294604327, -33.553008388766614],
              [-70.557075508668916, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 782,
        left: -7854377.7174875792,
        bottom: -3967938.734192261,
        right: -7853223.0164108202,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.541778153539695],
              [-70.554482294604327, -33.538034417468438],
              [-70.54929586647512, -33.538034417468438],
              [-70.546702652410517, -33.541778153539695],
              [-70.54929586647512, -33.54552172745246],
              [-70.554482294604327, -33.54552172745246],
              [-70.557075508668916, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 783,
        left: -7854377.7174875792,
        bottom: -3966938.7337260107,
        right: -7853223.0164108202,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.534290519247627],
              [-70.554482294604327, -33.530546458886235],
              [-70.54929586647512, -33.530546458886235],
              [-70.546702652410517, -33.534290519247627],
              [-70.54929586647512, -33.538034417468438],
              [-70.554482294604327, -33.538034417468438],
              [-70.557075508668916, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 784,
        left: -7854377.7174875792,
        bottom: -3965938.7332597603,
        right: -7853223.0164108202,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.526802236393216],
              [-70.554482294604327, -33.523057851777558],
              [-70.54929586647512, -33.523057851777558],
              [-70.546702652410517, -33.526802236393216],
              [-70.54929586647512, -33.530546458886235],
              [-70.554482294604327, -33.530546458886235],
              [-70.557075508668916, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 785,
        left: -7854377.7174875792,
        bottom: -3964938.7327935095,
        right: -7853223.0164108202,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.519313305048215],
              [-70.554482294604327, -33.515568596214173],
              [-70.54929586647512, -33.515568596214173],
              [-70.546702652410517, -33.519313305048215],
              [-70.54929586647512, -33.523057851777558],
              [-70.554482294604327, -33.523057851777558],
              [-70.557075508668916, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 786,
        left: -7854377.7174875792,
        bottom: -3963938.7323272596,
        right: -7853223.0164108202,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.511823725284408],
              [-70.554482294604327, -33.508078692267915],
              [-70.54929586647512, -33.508078692267915],
              [-70.546702652410517, -33.511823725284408],
              [-70.54929586647512, -33.515568596214173],
              [-70.554482294604327, -33.515568596214173],
              [-70.557075508668916, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 787,
        left: -7854377.7174875792,
        bottom: -3962938.7318610097,
        right: -7853223.0164108202,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.504333497173661],
              [-70.554482294604327, -33.500588140010635],
              [-70.54929586647512, -33.500588140010635],
              [-70.546702652410517, -33.504333497173661],
              [-70.54929586647512, -33.508078692267915],
              [-70.554482294604327, -33.508078692267915],
              [-70.557075508668916, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 788,
        left: -7854377.7174875792,
        bottom: -3961938.7313947589,
        right: -7853223.0164108202,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.496842620787838],
              [-70.554482294604327, -33.493096939514267],
              [-70.54929586647512, -33.493096939514267],
              [-70.546702652410517, -33.496842620787838],
              [-70.54929586647512, -33.500588140010635],
              [-70.554482294604327, -33.500588140010635],
              [-70.557075508668916, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 789,
        left: -7854377.7174875792,
        bottom: -3960938.7309285086,
        right: -7853223.0164108202,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.489351096198902],
              [-70.554482294604327, -33.485605090850768],
              [-70.54929586647512, -33.485605090850768],
              [-70.546702652410517, -33.489351096198902],
              [-70.54929586647512, -33.493096939514267],
              [-70.554482294604327, -33.493096939514267],
              [-70.557075508668916, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 790,
        left: -7854377.7174875792,
        bottom: -3959938.7304622582,
        right: -7853223.0164108202,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.48185892347886],
              [-70.554482294604327, -33.478112594092174],
              [-70.54929586647512, -33.478112594092174],
              [-70.546702652410517, -33.48185892347886],
              [-70.54929586647512, -33.485605090850768],
              [-70.554482294604327, -33.485605090850768],
              [-70.557075508668916, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 791,
        left: -7854377.7174875792,
        bottom: -3958938.7299960079,
        right: -7853223.0164108202,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.474366102699733],
              [-70.554482294604327, -33.470619449310547],
              [-70.54929586647512, -33.470619449310547],
              [-70.546702652410517, -33.474366102699733],
              [-70.54929586647512, -33.478112594092174],
              [-70.554482294604327, -33.478112594092174],
              [-70.557075508668916, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 792,
        left: -7854377.7174875792,
        bottom: -3957938.7295297575,
        right: -7853223.0164108202,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.466872633933626],
              [-70.554482294604327, -33.463125656578008],
              [-70.54929586647512, -33.463125656578008],
              [-70.546702652410517, -33.466872633933626],
              [-70.54929586647512, -33.470619449310547],
              [-70.554482294604327, -33.470619449310547],
              [-70.557075508668916, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 793,
        left: -7854377.7174875792,
        bottom: -3956938.7290635072,
        right: -7853223.0164108202,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.459378517252702],
              [-70.554482294604327, -33.45563121596674],
              [-70.54929586647512, -33.45563121596674],
              [-70.546702652410517, -33.459378517252702],
              [-70.54929586647512, -33.463125656578008],
              [-70.554482294604327, -33.463125656578008],
              [-70.557075508668916, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 794,
        left: -7854377.7174875792,
        bottom: -3955938.7285972568,
        right: -7853223.0164108202,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.451883752729138],
              [-70.554482294604327, -33.448136127548949],
              [-70.54929586647512, -33.448136127548949],
              [-70.546702652410517, -33.451883752729138],
              [-70.54929586647512, -33.45563121596674],
              [-70.554482294604327, -33.45563121596674],
              [-70.557075508668916, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 795,
        left: -7854377.7174875792,
        bottom: -3954938.7281310065,
        right: -7853223.0164108202,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.444388340435196],
              [-70.554482294604327, -33.440640391396926],
              [-70.54929586647512, -33.440640391396926],
              [-70.546702652410517, -33.444388340435196],
              [-70.54929586647512, -33.448136127548949],
              [-70.554482294604327, -33.448136127548949],
              [-70.557075508668916, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 796,
        left: -7854377.7174875792,
        bottom: -3953938.7276647561,
        right: -7853223.0164108202,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.436892280443168],
              [-70.554482294604327, -33.433144007582989],
              [-70.54929586647512, -33.433144007582989],
              [-70.546702652410517, -33.436892280443168],
              [-70.54929586647512, -33.440640391396926],
              [-70.554482294604327, -33.440640391396926],
              [-70.557075508668916, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 797,
        left: -7854377.7174875792,
        bottom: -3952938.7271985058,
        right: -7853223.0164108202,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.429395572825413],
              [-70.554482294604327, -33.425646976179515],
              [-70.54929586647512, -33.425646976179515],
              [-70.546702652410517, -33.429395572825413],
              [-70.54929586647512, -33.433144007582989],
              [-70.554482294604327, -33.433144007582989],
              [-70.557075508668916, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 798,
        left: -7854377.7174875792,
        bottom: -3951938.7267322554,
        right: -7853223.0164108202,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.421898217654316],
              [-70.554482294604327, -33.418149297258921],
              [-70.54929586647512, -33.418149297258921],
              [-70.546702652410517, -33.421898217654316],
              [-70.54929586647512, -33.425646976179515],
              [-70.554482294604327, -33.425646976179515],
              [-70.557075508668916, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 799,
        left: -7854377.7174875792,
        bottom: -3950938.7262660051,
        right: -7853223.0164108202,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.414400215002352],
              [-70.554482294604327, -33.410650970893691],
              [-70.54929586647512, -33.410650970893691],
              [-70.546702652410517, -33.414400215002352],
              [-70.54929586647512, -33.418149297258921],
              [-70.554482294604327, -33.418149297258921],
              [-70.557075508668916, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 800,
        left: -7854377.7174875792,
        bottom: -3949938.7257997552,
        right: -7853223.0164108202,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.406901564941997],
              [-70.554482294604327, -33.40315199715635],
              [-70.54929586647512, -33.40315199715635],
              [-70.546702652410517, -33.406901564941997],
              [-70.54929586647512, -33.410650970893691],
              [-70.554482294604327, -33.410650970893691],
              [-70.557075508668916, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 801,
        left: -7854377.7174875792,
        bottom: -3948938.7253335048,
        right: -7853223.0164108202,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.399402267545824],
              [-70.554482294604327, -33.395652376119486],
              [-70.54929586647512, -33.395652376119486],
              [-70.546702652410517, -33.399402267545824],
              [-70.54929586647512, -33.40315199715635],
              [-70.554482294604327, -33.40315199715635],
              [-70.557075508668916, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 802,
        left: -7854377.7174875792,
        bottom: -3947938.724867254,
        right: -7853223.0164108202,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.391902322886416],
              [-70.554482294604327, -33.388152107855717],
              [-70.54929586647512, -33.388152107855717],
              [-70.546702652410517, -33.391902322886416],
              [-70.54929586647512, -33.395652376119486],
              [-70.554482294604327, -33.395652376119486],
              [-70.557075508668916, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 803,
        left: -7854377.7174875792,
        bottom: -3946938.7244010041,
        right: -7853223.0164108202,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.384401731036441],
              [-70.554482294604327, -33.380651192437711],
              [-70.54929586647512, -33.380651192437711],
              [-70.546702652410517, -33.384401731036441],
              [-70.54929586647512, -33.388152107855717],
              [-70.554482294604327, -33.388152107855717],
              [-70.557075508668916, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 804,
        left: -7854377.7174875792,
        bottom: -3945938.7239347543,
        right: -7853223.0164108202,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.3769004920686],
              [-70.554482294604327, -33.373149629938226],
              [-70.54929586647512, -33.373149629938226],
              [-70.546702652410517, -33.3769004920686],
              [-70.54929586647512, -33.380651192437711],
              [-70.554482294604327, -33.380651192437711],
              [-70.557075508668916, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 805,
        left: -7854377.7174875792,
        bottom: -3944938.7234685034,
        right: -7853223.0164108202,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.369398606055647],
              [-70.554482294604327, -33.365647420430008],
              [-70.54929586647512, -33.365647420430008],
              [-70.546702652410517, -33.369398606055647],
              [-70.54929586647512, -33.373149629938226],
              [-70.554482294604327, -33.373149629938226],
              [-70.557075508668916, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 806,
        left: -7854377.7174875792,
        bottom: -3943938.7230022531,
        right: -7853223.0164108202,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.36189607307039],
              [-70.554482294604327, -33.358144563985917],
              [-70.54929586647512, -33.358144563985917],
              [-70.546702652410517, -33.36189607307039],
              [-70.54929586647512, -33.365647420430008],
              [-70.554482294604327, -33.365647420430008],
              [-70.557075508668916, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 807,
        left: -7854377.7174875792,
        bottom: -3942938.7225360028,
        right: -7853223.0164108202,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.35439289318569],
              [-70.554482294604327, -33.350641060678818],
              [-70.54929586647512, -33.350641060678818],
              [-70.546702652410517, -33.35439289318569],
              [-70.54929586647512, -33.358144563985917],
              [-70.554482294604327, -33.358144563985917],
              [-70.557075508668916, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 808,
        left: -7854377.7174875792,
        bottom: -3941938.7220697524,
        right: -7853223.0164108202,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.346889066474425],
              [-70.554482294604327, -33.343136910581649],
              [-70.54929586647512, -33.343136910581649],
              [-70.546702652410517, -33.346889066474425],
              [-70.54929586647512, -33.350641060678818],
              [-70.554482294604327, -33.350641060678818],
              [-70.557075508668916, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 809,
        left: -7854377.7174875792,
        bottom: -3940938.7216035021,
        right: -7853223.0164108202,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.339384593009591],
              [-70.554482294604327, -33.335632113767389],
              [-70.54929586647512, -33.335632113767389],
              [-70.546702652410517, -33.339384593009591],
              [-70.54929586647512, -33.343136910581649],
              [-70.554482294604327, -33.343136910581649],
              [-70.557075508668916, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 810,
        left: -7854377.7174875792,
        bottom: -3939938.7211372517,
        right: -7853223.0164108202,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.331879472864181],
              [-70.554482294604327, -33.328126670309082],
              [-70.54929586647512, -33.328126670309082],
              [-70.546702652410517, -33.331879472864181],
              [-70.54929586647512, -33.335632113767389],
              [-70.554482294604327, -33.335632113767389],
              [-70.557075508668916, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 811,
        left: -7854377.7174875792,
        bottom: -3938938.7206710014,
        right: -7853223.0164108202,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.324373706111238],
              [-70.554482294604327, -33.320620580279794],
              [-70.54929586647512, -33.320620580279794],
              [-70.546702652410517, -33.324373706111238],
              [-70.54929586647512, -33.328126670309082],
              [-70.554482294604327, -33.328126670309082],
              [-70.557075508668916, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 812,
        left: -7854377.7174875792,
        bottom: -3937938.720204751,
        right: -7853223.0164108202,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.3168672928239],
              [-70.554482294604327, -33.313113843752674],
              [-70.54929586647512, -33.313113843752674],
              [-70.546702652410517, -33.3168672928239],
              [-70.54929586647512, -33.320620580279794],
              [-70.554482294604327, -33.320620580279794],
              [-70.557075508668916, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 813,
        left: -7854377.7174875792,
        bottom: -3936938.7197385007,
        right: -7853223.0164108202,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.309360233075296],
              [-70.554482294604327, -33.305606460800909],
              [-70.54929586647512, -33.305606460800909],
              [-70.546702652410517, -33.309360233075296],
              [-70.54929586647512, -33.313113843752674],
              [-70.554482294604327, -33.313113843752674],
              [-70.557075508668916, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 814,
        left: -7854377.7174875792,
        bottom: -3935938.7192722503,
        right: -7853223.0164108202,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.30185252693866],
              [-70.554482294604327, -33.298098431497721],
              [-70.54929586647512, -33.298098431497721],
              [-70.546702652410517, -33.30185252693866],
              [-70.54929586647512, -33.305606460800909],
              [-70.554482294604327, -33.305606460800909],
              [-70.557075508668916, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 815,
        left: -7854377.7174875792,
        bottom: -3934938.718806,
        right: -7853223.0164108202,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.294344174487243],
              [-70.554482294604327, -33.290589755916407],
              [-70.54929586647512, -33.290589755916407],
              [-70.546702652410517, -33.294344174487243],
              [-70.54929586647512, -33.298098431497721],
              [-70.554482294604327, -33.298098431497721],
              [-70.557075508668916, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 816,
        left: -7854377.7174875792,
        bottom: -3933938.7183397496,
        right: -7853223.0164108202,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.557075508668916, -33.286835175794366],
              [-70.554482294604327, -33.283080434130305],
              [-70.54929586647512, -33.283080434130305],
              [-70.546702652410517, -33.286835175794366],
              [-70.54929586647512, -33.290589755916407],
              [-70.554482294604327, -33.290589755916407],
              [-70.557075508668916, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 817,
        left: -7853511.6916800104,
        bottom: -3980438.7400203901,
        right: -7852356.9906032523,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.63531883056551],
              [-70.546702652410517, -33.631579151136748],
              [-70.54151622428131, -33.631579151136748],
              [-70.538923010216706, -33.63531883056551],
              [-70.54151622428131, -33.639058347612803],
              [-70.546702652410517, -33.639058347612803],
              [-70.54929586647512, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 818,
        left: -7853511.6916800104,
        bottom: -3979438.7395541398,
        right: -7852356.9906032523,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.627839309335386],
              [-70.546702652410517, -33.624099305170297],
              [-70.54151622428131, -33.624099305170297],
              [-70.538923010216706, -33.627839309335386],
              [-70.54151622428131, -33.631579151136748],
              [-70.546702652410517, -33.631579151136748],
              [-70.54929586647512, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 819,
        left: -7853511.6916800104,
        bottom: -3978438.7390878894,
        right: -7852356.9906032523,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.620359138650379],
              [-70.546702652410517, -33.616618809784534],
              [-70.54151622428131, -33.616618809784534],
              [-70.538923010216706, -33.620359138650379],
              [-70.54151622428131, -33.624099305170297],
              [-70.546702652410517, -33.624099305170297],
              [-70.54929586647512, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 820,
        left: -7853511.6916800104,
        bottom: -3977438.7386216391,
        right: -7852356.9906032523,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.612878318581629],
              [-70.546702652410517, -33.609137665050582],
              [-70.54151622428131, -33.609137665050582],
              [-70.538923010216706, -33.612878318581629],
              [-70.54151622428131, -33.616618809784534],
              [-70.546702652410517, -33.616618809784534],
              [-70.54929586647512, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 821,
        left: -7853511.6916800104,
        bottom: -3976438.7381553887,
        right: -7852356.9906032523,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.605396849200282],
              [-70.546702652410517, -33.601655871039647],
              [-70.54151622428131, -33.601655871039647],
              [-70.538923010216706, -33.605396849200282],
              [-70.54151622428131, -33.609137665050582],
              [-70.546702652410517, -33.609137665050582],
              [-70.54929586647512, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 822,
        left: -7853511.6916800104,
        bottom: -3975438.7376891384,
        right: -7852356.9906032523,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.597914730577571],
              [-70.546702652410517, -33.594173427822959],
              [-70.54151622428131, -33.594173427822959],
              [-70.538923010216706, -33.597914730577571],
              [-70.54151622428131, -33.601655871039647],
              [-70.546702652410517, -33.601655871039647],
              [-70.54929586647512, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 823,
        left: -7853511.6916800104,
        bottom: -3974438.7372228876,
        right: -7852356.9906032523,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.590431962784734],
              [-70.546702652410517, -33.586690335471808],
              [-70.54151622428131, -33.586690335471808],
              [-70.538923010216706, -33.590431962784734],
              [-70.54151622428131, -33.594173427822959],
              [-70.546702652410517, -33.594173427822959],
              [-70.54929586647512, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 824,
        left: -7853511.6916800104,
        bottom: -3973438.7367566377,
        right: -7852356.9906032523,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.582948545893096],
              [-70.546702652410517, -33.579206594057531],
              [-70.54151622428131, -33.579206594057531],
              [-70.538923010216706, -33.582948545893096],
              [-70.54151622428131, -33.586690335471808],
              [-70.546702652410517, -33.586690335471808],
              [-70.54929586647512, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 825,
        left: -7853511.6916800104,
        bottom: -3972438.7362903878,
        right: -7852356.9906032523,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.575464479974038],
              [-70.546702652410517, -33.571722203651518],
              [-70.54151622428131, -33.571722203651518],
              [-70.538923010216706, -33.575464479974038],
              [-70.54151622428131, -33.579206594057531],
              [-70.546702652410517, -33.579206594057531],
              [-70.54929586647512, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 826,
        left: -7853511.6916800104,
        bottom: -3971438.735824137,
        right: -7852356.9906032523,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.56797976509894],
              [-70.546702652410517, -33.56423716432522],
              [-70.54151622428131, -33.56423716432522],
              [-70.538923010216706, -33.56797976509894],
              [-70.54151622428131, -33.571722203651518],
              [-70.546702652410517, -33.571722203651518],
              [-70.54929586647512, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 827,
        left: -7853511.6916800104,
        bottom: -3970438.7353578866,
        right: -7852356.9906032523,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.560494401339298],
              [-70.546702652410517, -33.556751476150104],
              [-70.54151622428131, -33.556751476150104],
              [-70.538923010216706, -33.560494401339298],
              [-70.54151622428131, -33.56423716432522],
              [-70.546702652410517, -33.56423716432522],
              [-70.54929586647512, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 828,
        left: -7853511.6916800104,
        bottom: -3969438.7348916363,
        right: -7852356.9906032523,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.553008388766614],
              [-70.546702652410517, -33.549265139197736],
              [-70.54151622428131, -33.549265139197736],
              [-70.538923010216706, -33.553008388766614],
              [-70.54151622428131, -33.556751476150104],
              [-70.546702652410517, -33.556751476150104],
              [-70.54929586647512, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 829,
        left: -7853511.6916800104,
        bottom: -3968438.7344253859,
        right: -7852356.9906032523,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.54552172745246],
              [-70.546702652410517, -33.541778153539695],
              [-70.54151622428131, -33.541778153539695],
              [-70.538923010216706, -33.54552172745246],
              [-70.54151622428131, -33.549265139197736],
              [-70.546702652410517, -33.549265139197736],
              [-70.54929586647512, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 830,
        left: -7853511.6916800104,
        bottom: -3967438.7339591356,
        right: -7852356.9906032523,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.538034417468438],
              [-70.546702652410517, -33.534290519247627],
              [-70.54151622428131, -33.534290519247627],
              [-70.538923010216706, -33.538034417468438],
              [-70.54151622428131, -33.541778153539695],
              [-70.546702652410517, -33.541778153539695],
              [-70.54929586647512, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 831,
        left: -7853511.6916800104,
        bottom: -3966438.7334928853,
        right: -7852356.9906032523,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.530546458886235],
              [-70.546702652410517, -33.526802236393216],
              [-70.54151622428131, -33.526802236393216],
              [-70.538923010216706, -33.530546458886235],
              [-70.54151622428131, -33.534290519247627],
              [-70.546702652410517, -33.534290519247627],
              [-70.54929586647512, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 832,
        left: -7853511.6916800104,
        bottom: -3965438.7330266349,
        right: -7852356.9906032523,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.523057851777558],
              [-70.546702652410517, -33.519313305048215],
              [-70.54151622428131, -33.519313305048215],
              [-70.538923010216706, -33.523057851777558],
              [-70.54151622428131, -33.526802236393216],
              [-70.546702652410517, -33.526802236393216],
              [-70.54929586647512, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 833,
        left: -7853511.6916800104,
        bottom: -3964438.7325603846,
        right: -7852356.9906032523,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.515568596214173],
              [-70.546702652410517, -33.511823725284408],
              [-70.54151622428131, -33.511823725284408],
              [-70.538923010216706, -33.515568596214173],
              [-70.54151622428131, -33.519313305048215],
              [-70.546702652410517, -33.519313305048215],
              [-70.54929586647512, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 834,
        left: -7853511.6916800104,
        bottom: -3963438.7320941342,
        right: -7852356.9906032523,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.508078692267915],
              [-70.546702652410517, -33.504333497173661],
              [-70.54151622428131, -33.504333497173661],
              [-70.538923010216706, -33.508078692267915],
              [-70.54151622428131, -33.511823725284408],
              [-70.546702652410517, -33.511823725284408],
              [-70.54929586647512, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 835,
        left: -7853511.6916800104,
        bottom: -3962438.7316278839,
        right: -7852356.9906032523,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.500588140010635],
              [-70.546702652410517, -33.496842620787838],
              [-70.54151622428131, -33.496842620787838],
              [-70.538923010216706, -33.500588140010635],
              [-70.54151622428131, -33.504333497173661],
              [-70.546702652410517, -33.504333497173661],
              [-70.54929586647512, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 836,
        left: -7853511.6916800104,
        bottom: -3961438.7311616335,
        right: -7852356.9906032523,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.493096939514267],
              [-70.546702652410517, -33.489351096198902],
              [-70.54151622428131, -33.489351096198902],
              [-70.538923010216706, -33.493096939514267],
              [-70.54151622428131, -33.496842620787838],
              [-70.546702652410517, -33.496842620787838],
              [-70.54929586647512, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 837,
        left: -7853511.6916800104,
        bottom: -3960438.7306953832,
        right: -7852356.9906032523,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.485605090850768],
              [-70.546702652410517, -33.48185892347886],
              [-70.54151622428131, -33.48185892347886],
              [-70.538923010216706, -33.485605090850768],
              [-70.54151622428131, -33.489351096198902],
              [-70.546702652410517, -33.489351096198902],
              [-70.54929586647512, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 838,
        left: -7853511.6916800104,
        bottom: -3959438.7302291333,
        right: -7852356.9906032523,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.478112594092174],
              [-70.546702652410517, -33.474366102699733],
              [-70.54151622428131, -33.474366102699733],
              [-70.538923010216706, -33.478112594092174],
              [-70.54151622428131, -33.48185892347886],
              [-70.546702652410517, -33.48185892347886],
              [-70.54929586647512, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 839,
        left: -7853511.6916800104,
        bottom: -3958438.7297628829,
        right: -7852356.9906032523,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.470619449310547],
              [-70.546702652410517, -33.466872633933626],
              [-70.54151622428131, -33.466872633933626],
              [-70.538923010216706, -33.470619449310547],
              [-70.54151622428131, -33.474366102699733],
              [-70.546702652410517, -33.474366102699733],
              [-70.54929586647512, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 840,
        left: -7853511.6916800104,
        bottom: -3957438.7292966326,
        right: -7852356.9906032523,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.463125656578008],
              [-70.546702652410517, -33.459378517252702],
              [-70.54151622428131, -33.459378517252702],
              [-70.538923010216706, -33.463125656578008],
              [-70.54151622428131, -33.466872633933626],
              [-70.546702652410517, -33.466872633933626],
              [-70.54929586647512, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 841,
        left: -7853511.6916800104,
        bottom: -3956438.7288303822,
        right: -7852356.9906032523,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.45563121596674],
              [-70.546702652410517, -33.451883752729138],
              [-70.54151622428131, -33.451883752729138],
              [-70.538923010216706, -33.45563121596674],
              [-70.54151622428131, -33.459378517252702],
              [-70.546702652410517, -33.459378517252702],
              [-70.54929586647512, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 842,
        left: -7853511.6916800104,
        bottom: -3955438.7283641323,
        right: -7852356.9906032523,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.448136127548949],
              [-70.546702652410517, -33.444388340435196],
              [-70.54151622428131, -33.444388340435196],
              [-70.538923010216706, -33.448136127548949],
              [-70.54151622428131, -33.451883752729138],
              [-70.546702652410517, -33.451883752729138],
              [-70.54929586647512, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 843,
        left: -7853511.6916800104,
        bottom: -3954438.7278978815,
        right: -7852356.9906032523,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.440640391396926],
              [-70.546702652410517, -33.436892280443168],
              [-70.54151622428131, -33.436892280443168],
              [-70.538923010216706, -33.440640391396926],
              [-70.54151622428131, -33.444388340435196],
              [-70.546702652410517, -33.444388340435196],
              [-70.54929586647512, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 844,
        left: -7853511.6916800104,
        bottom: -3953438.7274316312,
        right: -7852356.9906032523,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.433144007582989],
              [-70.546702652410517, -33.429395572825413],
              [-70.54151622428131, -33.429395572825413],
              [-70.538923010216706, -33.433144007582989],
              [-70.54151622428131, -33.436892280443168],
              [-70.546702652410517, -33.436892280443168],
              [-70.54929586647512, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 845,
        left: -7853511.6916800104,
        bottom: -3952438.7269653808,
        right: -7852356.9906032523,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.425646976179515],
              [-70.546702652410517, -33.421898217654316],
              [-70.54151622428131, -33.421898217654316],
              [-70.538923010216706, -33.425646976179515],
              [-70.54151622428131, -33.429395572825413],
              [-70.546702652410517, -33.429395572825413],
              [-70.54929586647512, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 846,
        left: -7853511.6916800104,
        bottom: -3951438.7264991305,
        right: -7852356.9906032523,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.418149297258921],
              [-70.546702652410517, -33.414400215002352],
              [-70.54151622428131, -33.414400215002352],
              [-70.538923010216706, -33.418149297258921],
              [-70.54151622428131, -33.421898217654316],
              [-70.546702652410517, -33.421898217654316],
              [-70.54929586647512, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 847,
        left: -7853511.6916800104,
        bottom: -3950438.7260328801,
        right: -7852356.9906032523,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.410650970893691],
              [-70.546702652410517, -33.406901564941997],
              [-70.54151622428131, -33.406901564941997],
              [-70.538923010216706, -33.410650970893691],
              [-70.54151622428131, -33.414400215002352],
              [-70.546702652410517, -33.414400215002352],
              [-70.54929586647512, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 848,
        left: -7853511.6916800104,
        bottom: -3949438.7255666298,
        right: -7852356.9906032523,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.40315199715635],
              [-70.546702652410517, -33.399402267545824],
              [-70.54151622428131, -33.399402267545824],
              [-70.538923010216706, -33.40315199715635],
              [-70.54151622428131, -33.406901564941997],
              [-70.546702652410517, -33.406901564941997],
              [-70.54929586647512, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 849,
        left: -7853511.6916800104,
        bottom: -3948438.7251003794,
        right: -7852356.9906032523,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.395652376119486],
              [-70.546702652410517, -33.391902322886416],
              [-70.54151622428131, -33.391902322886416],
              [-70.538923010216706, -33.395652376119486],
              [-70.54151622428131, -33.399402267545824],
              [-70.546702652410517, -33.399402267545824],
              [-70.54929586647512, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 850,
        left: -7853511.6916800104,
        bottom: -3947438.7246341291,
        right: -7852356.9906032523,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.388152107855717],
              [-70.546702652410517, -33.384401731036441],
              [-70.54151622428131, -33.384401731036441],
              [-70.538923010216706, -33.388152107855717],
              [-70.54151622428131, -33.391902322886416],
              [-70.546702652410517, -33.391902322886416],
              [-70.54929586647512, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 851,
        left: -7853511.6916800104,
        bottom: -3946438.7241678787,
        right: -7852356.9906032523,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.380651192437711],
              [-70.546702652410517, -33.3769004920686],
              [-70.54151622428131, -33.3769004920686],
              [-70.538923010216706, -33.380651192437711],
              [-70.54151622428131, -33.384401731036441],
              [-70.546702652410517, -33.384401731036441],
              [-70.54929586647512, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 852,
        left: -7853511.6916800104,
        bottom: -3945438.7237016284,
        right: -7852356.9906032523,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.373149629938226],
              [-70.546702652410517, -33.369398606055647],
              [-70.54151622428131, -33.369398606055647],
              [-70.538923010216706, -33.373149629938226],
              [-70.54151622428131, -33.3769004920686],
              [-70.546702652410517, -33.3769004920686],
              [-70.54929586647512, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 853,
        left: -7853511.6916800104,
        bottom: -3944438.723235378,
        right: -7852356.9906032523,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.365647420430008],
              [-70.546702652410517, -33.36189607307039],
              [-70.54151622428131, -33.36189607307039],
              [-70.538923010216706, -33.365647420430008],
              [-70.54151622428131, -33.369398606055647],
              [-70.546702652410517, -33.369398606055647],
              [-70.54929586647512, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 854,
        left: -7853511.6916800104,
        bottom: -3943438.7227691277,
        right: -7852356.9906032523,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.358144563985917],
              [-70.546702652410517, -33.35439289318569],
              [-70.54151622428131, -33.35439289318569],
              [-70.538923010216706, -33.358144563985917],
              [-70.54151622428131, -33.36189607307039],
              [-70.546702652410517, -33.36189607307039],
              [-70.54929586647512, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 855,
        left: -7853511.6916800104,
        bottom: -3942438.7223028769,
        right: -7852356.9906032523,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.350641060678818],
              [-70.546702652410517, -33.346889066474425],
              [-70.54151622428131, -33.346889066474425],
              [-70.538923010216706, -33.350641060678818],
              [-70.54151622428131, -33.35439289318569],
              [-70.546702652410517, -33.35439289318569],
              [-70.54929586647512, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 856,
        left: -7853511.6916800104,
        bottom: -3941438.721836627,
        right: -7852356.9906032523,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.343136910581649],
              [-70.546702652410517, -33.339384593009591],
              [-70.54151622428131, -33.339384593009591],
              [-70.538923010216706, -33.343136910581649],
              [-70.54151622428131, -33.346889066474425],
              [-70.546702652410517, -33.346889066474425],
              [-70.54929586647512, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 857,
        left: -7853511.6916800104,
        bottom: -3940438.7213703771,
        right: -7852356.9906032523,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.335632113767389],
              [-70.546702652410517, -33.331879472864181],
              [-70.54151622428131, -33.331879472864181],
              [-70.538923010216706, -33.335632113767389],
              [-70.54151622428131, -33.339384593009591],
              [-70.546702652410517, -33.339384593009591],
              [-70.54929586647512, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 858,
        left: -7853511.6916800104,
        bottom: -3939438.7209041263,
        right: -7852356.9906032523,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.328126670309082],
              [-70.546702652410517, -33.324373706111238],
              [-70.54151622428131, -33.324373706111238],
              [-70.538923010216706, -33.328126670309082],
              [-70.54151622428131, -33.331879472864181],
              [-70.546702652410517, -33.331879472864181],
              [-70.54929586647512, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 859,
        left: -7853511.6916800104,
        bottom: -3938438.7204378759,
        right: -7852356.9906032523,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.320620580279794],
              [-70.546702652410517, -33.3168672928239],
              [-70.54151622428131, -33.3168672928239],
              [-70.538923010216706, -33.320620580279794],
              [-70.54151622428131, -33.324373706111238],
              [-70.546702652410517, -33.324373706111238],
              [-70.54929586647512, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 860,
        left: -7853511.6916800104,
        bottom: -3937438.7199716261,
        right: -7852356.9906032523,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.313113843752674],
              [-70.546702652410517, -33.309360233075296],
              [-70.54151622428131, -33.309360233075296],
              [-70.538923010216706, -33.313113843752674],
              [-70.54151622428131, -33.3168672928239],
              [-70.546702652410517, -33.3168672928239],
              [-70.54929586647512, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 861,
        left: -7853511.6916800104,
        bottom: -3936438.7195053762,
        right: -7852356.9906032523,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.305606460800909],
              [-70.546702652410517, -33.30185252693866],
              [-70.54151622428131, -33.30185252693866],
              [-70.538923010216706, -33.305606460800909],
              [-70.54151622428131, -33.309360233075296],
              [-70.546702652410517, -33.309360233075296],
              [-70.54929586647512, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 862,
        left: -7853511.6916800104,
        bottom: -3935438.7190391254,
        right: -7852356.9906032523,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.298098431497721],
              [-70.546702652410517, -33.294344174487243],
              [-70.54151622428131, -33.294344174487243],
              [-70.538923010216706, -33.298098431497721],
              [-70.54151622428131, -33.30185252693866],
              [-70.546702652410517, -33.30185252693866],
              [-70.54929586647512, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 863,
        left: -7853511.6916800104,
        bottom: -3934438.718572875,
        right: -7852356.9906032523,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.290589755916407],
              [-70.546702652410517, -33.286835175794366],
              [-70.54151622428131, -33.286835175794366],
              [-70.538923010216706, -33.290589755916407],
              [-70.54151622428131, -33.294344174487243],
              [-70.546702652410517, -33.294344174487243],
              [-70.54929586647512, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 864,
        left: -7853511.6916800104,
        bottom: -3933438.7181066247,
        right: -7852356.9906032523,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54929586647512, -33.283080434130305],
              [-70.546702652410517, -33.279325530933384],
              [-70.54151622428131, -33.279325530933384],
              [-70.538923010216706, -33.283080434130305],
              [-70.54151622428131, -33.286835175794366],
              [-70.546702652410517, -33.286835175794366],
              [-70.54929586647512, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 865,
        left: -7852645.6658724416,
        bottom: -3980938.7402535151,
        right: -7851490.9647956835,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.639058347612803],
              [-70.538923010216706, -33.63531883056551],
              [-70.533736582087499, -33.63531883056551],
              [-70.531143368022896, -33.639058347612803],
              [-70.533736582087499, -33.642797702269725],
              [-70.538923010216706, -33.642797702269725],
              [-70.54151622428131, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 866,
        left: -7852645.6658724416,
        bottom: -3979938.7397872647,
        right: -7851490.9647956835,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.631579151136748],
              [-70.538923010216706, -33.627839309335386],
              [-70.533736582087499, -33.627839309335386],
              [-70.531143368022896, -33.631579151136748],
              [-70.533736582087499, -33.63531883056551],
              [-70.538923010216706, -33.63531883056551],
              [-70.54151622428131, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 867,
        left: -7852645.6658724416,
        bottom: -3978938.7393210144,
        right: -7851490.9647956835,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.624099305170297],
              [-70.538923010216706, -33.620359138650379],
              [-70.533736582087499, -33.620359138650379],
              [-70.531143368022896, -33.624099305170297],
              [-70.533736582087499, -33.627839309335386],
              [-70.538923010216706, -33.627839309335386],
              [-70.54151622428131, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 868,
        left: -7852645.6658724416,
        bottom: -3977938.738854764,
        right: -7851490.9647956835,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.616618809784534],
              [-70.538923010216706, -33.612878318581629],
              [-70.533736582087499, -33.612878318581629],
              [-70.531143368022896, -33.616618809784534],
              [-70.533736582087499, -33.620359138650379],
              [-70.538923010216706, -33.620359138650379],
              [-70.54151622428131, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 869,
        left: -7852645.6658724416,
        bottom: -3976938.7383885137,
        right: -7851490.9647956835,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.609137665050582],
              [-70.538923010216706, -33.605396849200282],
              [-70.533736582087499, -33.605396849200282],
              [-70.531143368022896, -33.609137665050582],
              [-70.533736582087499, -33.612878318581629],
              [-70.538923010216706, -33.612878318581629],
              [-70.54151622428131, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 870,
        left: -7852645.6658724416,
        bottom: -3975938.7379222633,
        right: -7851490.9647956835,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.601655871039647],
              [-70.538923010216706, -33.597914730577571],
              [-70.533736582087499, -33.597914730577571],
              [-70.531143368022896, -33.601655871039647],
              [-70.533736582087499, -33.605396849200282],
              [-70.538923010216706, -33.605396849200282],
              [-70.54151622428131, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 871,
        left: -7852645.6658724416,
        bottom: -3974938.737456013,
        right: -7851490.9647956835,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.594173427822959],
              [-70.538923010216706, -33.590431962784734],
              [-70.533736582087499, -33.590431962784734],
              [-70.531143368022896, -33.594173427822959],
              [-70.533736582087499, -33.597914730577571],
              [-70.538923010216706, -33.597914730577571],
              [-70.54151622428131, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 872,
        left: -7852645.6658724416,
        bottom: -3973938.7369897626,
        right: -7851490.9647956835,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.586690335471808],
              [-70.538923010216706, -33.582948545893096],
              [-70.533736582087499, -33.582948545893096],
              [-70.531143368022896, -33.586690335471808],
              [-70.533736582087499, -33.590431962784734],
              [-70.538923010216706, -33.590431962784734],
              [-70.54151622428131, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 873,
        left: -7852645.6658724416,
        bottom: -3972938.7365235123,
        right: -7851490.9647956835,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.579206594057531],
              [-70.538923010216706, -33.575464479974038],
              [-70.533736582087499, -33.575464479974038],
              [-70.531143368022896, -33.579206594057531],
              [-70.533736582087499, -33.582948545893096],
              [-70.538923010216706, -33.582948545893096],
              [-70.54151622428131, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 874,
        left: -7852645.6658724416,
        bottom: -3971938.7360572619,
        right: -7851490.9647956835,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.571722203651518],
              [-70.538923010216706, -33.56797976509894],
              [-70.533736582087499, -33.56797976509894],
              [-70.531143368022896, -33.571722203651518],
              [-70.533736582087499, -33.575464479974038],
              [-70.538923010216706, -33.575464479974038],
              [-70.54151622428131, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 875,
        left: -7852645.6658724416,
        bottom: -3970938.7355910116,
        right: -7851490.9647956835,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.56423716432522],
              [-70.538923010216706, -33.560494401339298],
              [-70.533736582087499, -33.560494401339298],
              [-70.531143368022896, -33.56423716432522],
              [-70.533736582087499, -33.56797976509894],
              [-70.538923010216706, -33.56797976509894],
              [-70.54151622428131, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 876,
        left: -7852645.6658724416,
        bottom: -3969938.7351247617,
        right: -7851490.9647956835,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.556751476150104],
              [-70.538923010216706, -33.553008388766614],
              [-70.533736582087499, -33.553008388766614],
              [-70.531143368022896, -33.556751476150104],
              [-70.533736582087499, -33.560494401339298],
              [-70.538923010216706, -33.560494401339298],
              [-70.54151622428131, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 877,
        left: -7852645.6658724416,
        bottom: -3968938.7346585114,
        right: -7851490.9647956835,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.549265139197736],
              [-70.538923010216706, -33.54552172745246],
              [-70.533736582087499, -33.54552172745246],
              [-70.531143368022896, -33.549265139197736],
              [-70.533736582087499, -33.553008388766614],
              [-70.538923010216706, -33.553008388766614],
              [-70.54151622428131, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 878,
        left: -7852645.6658724416,
        bottom: -3967938.734192261,
        right: -7851490.9647956835,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.541778153539695],
              [-70.538923010216706, -33.538034417468438],
              [-70.533736582087499, -33.538034417468438],
              [-70.531143368022896, -33.541778153539695],
              [-70.533736582087499, -33.54552172745246],
              [-70.538923010216706, -33.54552172745246],
              [-70.54151622428131, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 879,
        left: -7852645.6658724416,
        bottom: -3966938.7337260107,
        right: -7851490.9647956835,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.534290519247627],
              [-70.538923010216706, -33.530546458886235],
              [-70.533736582087499, -33.530546458886235],
              [-70.531143368022896, -33.534290519247627],
              [-70.533736582087499, -33.538034417468438],
              [-70.538923010216706, -33.538034417468438],
              [-70.54151622428131, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 880,
        left: -7852645.6658724416,
        bottom: -3965938.7332597603,
        right: -7851490.9647956835,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.526802236393216],
              [-70.538923010216706, -33.523057851777558],
              [-70.533736582087499, -33.523057851777558],
              [-70.531143368022896, -33.526802236393216],
              [-70.533736582087499, -33.530546458886235],
              [-70.538923010216706, -33.530546458886235],
              [-70.54151622428131, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 881,
        left: -7852645.6658724416,
        bottom: -3964938.7327935095,
        right: -7851490.9647956835,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.519313305048215],
              [-70.538923010216706, -33.515568596214173],
              [-70.533736582087499, -33.515568596214173],
              [-70.531143368022896, -33.519313305048215],
              [-70.533736582087499, -33.523057851777558],
              [-70.538923010216706, -33.523057851777558],
              [-70.54151622428131, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 882,
        left: -7852645.6658724416,
        bottom: -3963938.7323272596,
        right: -7851490.9647956835,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.511823725284408],
              [-70.538923010216706, -33.508078692267915],
              [-70.533736582087499, -33.508078692267915],
              [-70.531143368022896, -33.511823725284408],
              [-70.533736582087499, -33.515568596214173],
              [-70.538923010216706, -33.515568596214173],
              [-70.54151622428131, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 883,
        left: -7852645.6658724416,
        bottom: -3962938.7318610097,
        right: -7851490.9647956835,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.504333497173661],
              [-70.538923010216706, -33.500588140010635],
              [-70.533736582087499, -33.500588140010635],
              [-70.531143368022896, -33.504333497173661],
              [-70.533736582087499, -33.508078692267915],
              [-70.538923010216706, -33.508078692267915],
              [-70.54151622428131, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 884,
        left: -7852645.6658724416,
        bottom: -3961938.7313947589,
        right: -7851490.9647956835,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.496842620787838],
              [-70.538923010216706, -33.493096939514267],
              [-70.533736582087499, -33.493096939514267],
              [-70.531143368022896, -33.496842620787838],
              [-70.533736582087499, -33.500588140010635],
              [-70.538923010216706, -33.500588140010635],
              [-70.54151622428131, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 885,
        left: -7852645.6658724416,
        bottom: -3960938.7309285086,
        right: -7851490.9647956835,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.489351096198902],
              [-70.538923010216706, -33.485605090850768],
              [-70.533736582087499, -33.485605090850768],
              [-70.531143368022896, -33.489351096198902],
              [-70.533736582087499, -33.493096939514267],
              [-70.538923010216706, -33.493096939514267],
              [-70.54151622428131, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 886,
        left: -7852645.6658724416,
        bottom: -3959938.7304622582,
        right: -7851490.9647956835,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.48185892347886],
              [-70.538923010216706, -33.478112594092174],
              [-70.533736582087499, -33.478112594092174],
              [-70.531143368022896, -33.48185892347886],
              [-70.533736582087499, -33.485605090850768],
              [-70.538923010216706, -33.485605090850768],
              [-70.54151622428131, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 887,
        left: -7852645.6658724416,
        bottom: -3958938.7299960079,
        right: -7851490.9647956835,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.474366102699733],
              [-70.538923010216706, -33.470619449310547],
              [-70.533736582087499, -33.470619449310547],
              [-70.531143368022896, -33.474366102699733],
              [-70.533736582087499, -33.478112594092174],
              [-70.538923010216706, -33.478112594092174],
              [-70.54151622428131, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 888,
        left: -7852645.6658724416,
        bottom: -3957938.7295297575,
        right: -7851490.9647956835,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.466872633933626],
              [-70.538923010216706, -33.463125656578008],
              [-70.533736582087499, -33.463125656578008],
              [-70.531143368022896, -33.466872633933626],
              [-70.533736582087499, -33.470619449310547],
              [-70.538923010216706, -33.470619449310547],
              [-70.54151622428131, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 889,
        left: -7852645.6658724416,
        bottom: -3956938.7290635072,
        right: -7851490.9647956835,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.459378517252702],
              [-70.538923010216706, -33.45563121596674],
              [-70.533736582087499, -33.45563121596674],
              [-70.531143368022896, -33.459378517252702],
              [-70.533736582087499, -33.463125656578008],
              [-70.538923010216706, -33.463125656578008],
              [-70.54151622428131, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 890,
        left: -7852645.6658724416,
        bottom: -3955938.7285972568,
        right: -7851490.9647956835,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.451883752729138],
              [-70.538923010216706, -33.448136127548949],
              [-70.533736582087499, -33.448136127548949],
              [-70.531143368022896, -33.451883752729138],
              [-70.533736582087499, -33.45563121596674],
              [-70.538923010216706, -33.45563121596674],
              [-70.54151622428131, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 891,
        left: -7852645.6658724416,
        bottom: -3954938.7281310065,
        right: -7851490.9647956835,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.444388340435196],
              [-70.538923010216706, -33.440640391396926],
              [-70.533736582087499, -33.440640391396926],
              [-70.531143368022896, -33.444388340435196],
              [-70.533736582087499, -33.448136127548949],
              [-70.538923010216706, -33.448136127548949],
              [-70.54151622428131, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 892,
        left: -7852645.6658724416,
        bottom: -3953938.7276647561,
        right: -7851490.9647956835,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.436892280443168],
              [-70.538923010216706, -33.433144007582989],
              [-70.533736582087499, -33.433144007582989],
              [-70.531143368022896, -33.436892280443168],
              [-70.533736582087499, -33.440640391396926],
              [-70.538923010216706, -33.440640391396926],
              [-70.54151622428131, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 893,
        left: -7852645.6658724416,
        bottom: -3952938.7271985058,
        right: -7851490.9647956835,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.429395572825413],
              [-70.538923010216706, -33.425646976179515],
              [-70.533736582087499, -33.425646976179515],
              [-70.531143368022896, -33.429395572825413],
              [-70.533736582087499, -33.433144007582989],
              [-70.538923010216706, -33.433144007582989],
              [-70.54151622428131, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 894,
        left: -7852645.6658724416,
        bottom: -3951938.7267322554,
        right: -7851490.9647956835,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.421898217654316],
              [-70.538923010216706, -33.418149297258921],
              [-70.533736582087499, -33.418149297258921],
              [-70.531143368022896, -33.421898217654316],
              [-70.533736582087499, -33.425646976179515],
              [-70.538923010216706, -33.425646976179515],
              [-70.54151622428131, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 895,
        left: -7852645.6658724416,
        bottom: -3950938.7262660051,
        right: -7851490.9647956835,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.414400215002352],
              [-70.538923010216706, -33.410650970893691],
              [-70.533736582087499, -33.410650970893691],
              [-70.531143368022896, -33.414400215002352],
              [-70.533736582087499, -33.418149297258921],
              [-70.538923010216706, -33.418149297258921],
              [-70.54151622428131, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 896,
        left: -7852645.6658724416,
        bottom: -3949938.7257997552,
        right: -7851490.9647956835,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.406901564941997],
              [-70.538923010216706, -33.40315199715635],
              [-70.533736582087499, -33.40315199715635],
              [-70.531143368022896, -33.406901564941997],
              [-70.533736582087499, -33.410650970893691],
              [-70.538923010216706, -33.410650970893691],
              [-70.54151622428131, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 897,
        left: -7852645.6658724416,
        bottom: -3948938.7253335048,
        right: -7851490.9647956835,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.399402267545824],
              [-70.538923010216706, -33.395652376119486],
              [-70.533736582087499, -33.395652376119486],
              [-70.531143368022896, -33.399402267545824],
              [-70.533736582087499, -33.40315199715635],
              [-70.538923010216706, -33.40315199715635],
              [-70.54151622428131, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 898,
        left: -7852645.6658724416,
        bottom: -3947938.724867254,
        right: -7851490.9647956835,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.391902322886416],
              [-70.538923010216706, -33.388152107855717],
              [-70.533736582087499, -33.388152107855717],
              [-70.531143368022896, -33.391902322886416],
              [-70.533736582087499, -33.395652376119486],
              [-70.538923010216706, -33.395652376119486],
              [-70.54151622428131, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 899,
        left: -7852645.6658724416,
        bottom: -3946938.7244010041,
        right: -7851490.9647956835,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.384401731036441],
              [-70.538923010216706, -33.380651192437711],
              [-70.533736582087499, -33.380651192437711],
              [-70.531143368022896, -33.384401731036441],
              [-70.533736582087499, -33.388152107855717],
              [-70.538923010216706, -33.388152107855717],
              [-70.54151622428131, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 900,
        left: -7852645.6658724416,
        bottom: -3945938.7239347543,
        right: -7851490.9647956835,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.3769004920686],
              [-70.538923010216706, -33.373149629938226],
              [-70.533736582087499, -33.373149629938226],
              [-70.531143368022896, -33.3769004920686],
              [-70.533736582087499, -33.380651192437711],
              [-70.538923010216706, -33.380651192437711],
              [-70.54151622428131, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 901,
        left: -7852645.6658724416,
        bottom: -3944938.7234685034,
        right: -7851490.9647956835,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.369398606055647],
              [-70.538923010216706, -33.365647420430008],
              [-70.533736582087499, -33.365647420430008],
              [-70.531143368022896, -33.369398606055647],
              [-70.533736582087499, -33.373149629938226],
              [-70.538923010216706, -33.373149629938226],
              [-70.54151622428131, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 902,
        left: -7852645.6658724416,
        bottom: -3943938.7230022531,
        right: -7851490.9647956835,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.36189607307039],
              [-70.538923010216706, -33.358144563985917],
              [-70.533736582087499, -33.358144563985917],
              [-70.531143368022896, -33.36189607307039],
              [-70.533736582087499, -33.365647420430008],
              [-70.538923010216706, -33.365647420430008],
              [-70.54151622428131, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 903,
        left: -7852645.6658724416,
        bottom: -3942938.7225360028,
        right: -7851490.9647956835,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.35439289318569],
              [-70.538923010216706, -33.350641060678818],
              [-70.533736582087499, -33.350641060678818],
              [-70.531143368022896, -33.35439289318569],
              [-70.533736582087499, -33.358144563985917],
              [-70.538923010216706, -33.358144563985917],
              [-70.54151622428131, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 904,
        left: -7852645.6658724416,
        bottom: -3941938.7220697524,
        right: -7851490.9647956835,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.346889066474425],
              [-70.538923010216706, -33.343136910581649],
              [-70.533736582087499, -33.343136910581649],
              [-70.531143368022896, -33.346889066474425],
              [-70.533736582087499, -33.350641060678818],
              [-70.538923010216706, -33.350641060678818],
              [-70.54151622428131, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 905,
        left: -7852645.6658724416,
        bottom: -3940938.7216035021,
        right: -7851490.9647956835,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.339384593009591],
              [-70.538923010216706, -33.335632113767389],
              [-70.533736582087499, -33.335632113767389],
              [-70.531143368022896, -33.339384593009591],
              [-70.533736582087499, -33.343136910581649],
              [-70.538923010216706, -33.343136910581649],
              [-70.54151622428131, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 906,
        left: -7852645.6658724416,
        bottom: -3939938.7211372517,
        right: -7851490.9647956835,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.331879472864181],
              [-70.538923010216706, -33.328126670309082],
              [-70.533736582087499, -33.328126670309082],
              [-70.531143368022896, -33.331879472864181],
              [-70.533736582087499, -33.335632113767389],
              [-70.538923010216706, -33.335632113767389],
              [-70.54151622428131, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 907,
        left: -7852645.6658724416,
        bottom: -3938938.7206710014,
        right: -7851490.9647956835,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.324373706111238],
              [-70.538923010216706, -33.320620580279794],
              [-70.533736582087499, -33.320620580279794],
              [-70.531143368022896, -33.324373706111238],
              [-70.533736582087499, -33.328126670309082],
              [-70.538923010216706, -33.328126670309082],
              [-70.54151622428131, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 908,
        left: -7852645.6658724416,
        bottom: -3937938.720204751,
        right: -7851490.9647956835,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.3168672928239],
              [-70.538923010216706, -33.313113843752674],
              [-70.533736582087499, -33.313113843752674],
              [-70.531143368022896, -33.3168672928239],
              [-70.533736582087499, -33.320620580279794],
              [-70.538923010216706, -33.320620580279794],
              [-70.54151622428131, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 909,
        left: -7852645.6658724416,
        bottom: -3936938.7197385007,
        right: -7851490.9647956835,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.309360233075296],
              [-70.538923010216706, -33.305606460800909],
              [-70.533736582087499, -33.305606460800909],
              [-70.531143368022896, -33.309360233075296],
              [-70.533736582087499, -33.313113843752674],
              [-70.538923010216706, -33.313113843752674],
              [-70.54151622428131, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 910,
        left: -7852645.6658724416,
        bottom: -3935938.7192722503,
        right: -7851490.9647956835,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.30185252693866],
              [-70.538923010216706, -33.298098431497721],
              [-70.533736582087499, -33.298098431497721],
              [-70.531143368022896, -33.30185252693866],
              [-70.533736582087499, -33.305606460800909],
              [-70.538923010216706, -33.305606460800909],
              [-70.54151622428131, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 911,
        left: -7852645.6658724416,
        bottom: -3934938.718806,
        right: -7851490.9647956835,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.294344174487243],
              [-70.538923010216706, -33.290589755916407],
              [-70.533736582087499, -33.290589755916407],
              [-70.531143368022896, -33.294344174487243],
              [-70.533736582087499, -33.298098431497721],
              [-70.538923010216706, -33.298098431497721],
              [-70.54151622428131, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 912,
        left: -7852645.6658724416,
        bottom: -3933938.7183397496,
        right: -7851490.9647956835,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.54151622428131, -33.286835175794366],
              [-70.538923010216706, -33.283080434130305],
              [-70.533736582087499, -33.283080434130305],
              [-70.531143368022896, -33.286835175794366],
              [-70.533736582087499, -33.290589755916407],
              [-70.538923010216706, -33.290589755916407],
              [-70.54151622428131, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 913,
        left: -7851779.6400648728,
        bottom: -3980438.7400203901,
        right: -7850624.9389881147,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.63531883056551],
              [-70.531143368022896, -33.631579151136748],
              [-70.525956939893689, -33.631579151136748],
              [-70.523363725829086, -33.63531883056551],
              [-70.525956939893689, -33.639058347612803],
              [-70.531143368022896, -33.639058347612803],
              [-70.533736582087499, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 914,
        left: -7851779.6400648728,
        bottom: -3979438.7395541398,
        right: -7850624.9389881147,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.627839309335386],
              [-70.531143368022896, -33.624099305170297],
              [-70.525956939893689, -33.624099305170297],
              [-70.523363725829086, -33.627839309335386],
              [-70.525956939893689, -33.631579151136748],
              [-70.531143368022896, -33.631579151136748],
              [-70.533736582087499, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 915,
        left: -7851779.6400648728,
        bottom: -3978438.7390878894,
        right: -7850624.9389881147,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.620359138650379],
              [-70.531143368022896, -33.616618809784534],
              [-70.525956939893689, -33.616618809784534],
              [-70.523363725829086, -33.620359138650379],
              [-70.525956939893689, -33.624099305170297],
              [-70.531143368022896, -33.624099305170297],
              [-70.533736582087499, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 916,
        left: -7851779.6400648728,
        bottom: -3977438.7386216391,
        right: -7850624.9389881147,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.612878318581629],
              [-70.531143368022896, -33.609137665050582],
              [-70.525956939893689, -33.609137665050582],
              [-70.523363725829086, -33.612878318581629],
              [-70.525956939893689, -33.616618809784534],
              [-70.531143368022896, -33.616618809784534],
              [-70.533736582087499, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 917,
        left: -7851779.6400648728,
        bottom: -3976438.7381553887,
        right: -7850624.9389881147,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.605396849200282],
              [-70.531143368022896, -33.601655871039647],
              [-70.525956939893689, -33.601655871039647],
              [-70.523363725829086, -33.605396849200282],
              [-70.525956939893689, -33.609137665050582],
              [-70.531143368022896, -33.609137665050582],
              [-70.533736582087499, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 918,
        left: -7851779.6400648728,
        bottom: -3975438.7376891384,
        right: -7850624.9389881147,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.597914730577571],
              [-70.531143368022896, -33.594173427822959],
              [-70.525956939893689, -33.594173427822959],
              [-70.523363725829086, -33.597914730577571],
              [-70.525956939893689, -33.601655871039647],
              [-70.531143368022896, -33.601655871039647],
              [-70.533736582087499, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 919,
        left: -7851779.6400648728,
        bottom: -3974438.7372228876,
        right: -7850624.9389881147,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.590431962784734],
              [-70.531143368022896, -33.586690335471808],
              [-70.525956939893689, -33.586690335471808],
              [-70.523363725829086, -33.590431962784734],
              [-70.525956939893689, -33.594173427822959],
              [-70.531143368022896, -33.594173427822959],
              [-70.533736582087499, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 920,
        left: -7851779.6400648728,
        bottom: -3973438.7367566377,
        right: -7850624.9389881147,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.582948545893096],
              [-70.531143368022896, -33.579206594057531],
              [-70.525956939893689, -33.579206594057531],
              [-70.523363725829086, -33.582948545893096],
              [-70.525956939893689, -33.586690335471808],
              [-70.531143368022896, -33.586690335471808],
              [-70.533736582087499, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 921,
        left: -7851779.6400648728,
        bottom: -3972438.7362903878,
        right: -7850624.9389881147,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.575464479974038],
              [-70.531143368022896, -33.571722203651518],
              [-70.525956939893689, -33.571722203651518],
              [-70.523363725829086, -33.575464479974038],
              [-70.525956939893689, -33.579206594057531],
              [-70.531143368022896, -33.579206594057531],
              [-70.533736582087499, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 922,
        left: -7851779.6400648728,
        bottom: -3971438.735824137,
        right: -7850624.9389881147,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.56797976509894],
              [-70.531143368022896, -33.56423716432522],
              [-70.525956939893689, -33.56423716432522],
              [-70.523363725829086, -33.56797976509894],
              [-70.525956939893689, -33.571722203651518],
              [-70.531143368022896, -33.571722203651518],
              [-70.533736582087499, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 923,
        left: -7851779.6400648728,
        bottom: -3970438.7353578866,
        right: -7850624.9389881147,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.560494401339298],
              [-70.531143368022896, -33.556751476150104],
              [-70.525956939893689, -33.556751476150104],
              [-70.523363725829086, -33.560494401339298],
              [-70.525956939893689, -33.56423716432522],
              [-70.531143368022896, -33.56423716432522],
              [-70.533736582087499, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 924,
        left: -7851779.6400648728,
        bottom: -3969438.7348916363,
        right: -7850624.9389881147,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.553008388766614],
              [-70.531143368022896, -33.549265139197736],
              [-70.525956939893689, -33.549265139197736],
              [-70.523363725829086, -33.553008388766614],
              [-70.525956939893689, -33.556751476150104],
              [-70.531143368022896, -33.556751476150104],
              [-70.533736582087499, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 925,
        left: -7851779.6400648728,
        bottom: -3968438.7344253859,
        right: -7850624.9389881147,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.54552172745246],
              [-70.531143368022896, -33.541778153539695],
              [-70.525956939893689, -33.541778153539695],
              [-70.523363725829086, -33.54552172745246],
              [-70.525956939893689, -33.549265139197736],
              [-70.531143368022896, -33.549265139197736],
              [-70.533736582087499, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 926,
        left: -7851779.6400648728,
        bottom: -3967438.7339591356,
        right: -7850624.9389881147,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.538034417468438],
              [-70.531143368022896, -33.534290519247627],
              [-70.525956939893689, -33.534290519247627],
              [-70.523363725829086, -33.538034417468438],
              [-70.525956939893689, -33.541778153539695],
              [-70.531143368022896, -33.541778153539695],
              [-70.533736582087499, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 927,
        left: -7851779.6400648728,
        bottom: -3966438.7334928853,
        right: -7850624.9389881147,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.530546458886235],
              [-70.531143368022896, -33.526802236393216],
              [-70.525956939893689, -33.526802236393216],
              [-70.523363725829086, -33.530546458886235],
              [-70.525956939893689, -33.534290519247627],
              [-70.531143368022896, -33.534290519247627],
              [-70.533736582087499, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 928,
        left: -7851779.6400648728,
        bottom: -3965438.7330266349,
        right: -7850624.9389881147,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.523057851777558],
              [-70.531143368022896, -33.519313305048215],
              [-70.525956939893689, -33.519313305048215],
              [-70.523363725829086, -33.523057851777558],
              [-70.525956939893689, -33.526802236393216],
              [-70.531143368022896, -33.526802236393216],
              [-70.533736582087499, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 929,
        left: -7851779.6400648728,
        bottom: -3964438.7325603846,
        right: -7850624.9389881147,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.515568596214173],
              [-70.531143368022896, -33.511823725284408],
              [-70.525956939893689, -33.511823725284408],
              [-70.523363725829086, -33.515568596214173],
              [-70.525956939893689, -33.519313305048215],
              [-70.531143368022896, -33.519313305048215],
              [-70.533736582087499, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 930,
        left: -7851779.6400648728,
        bottom: -3963438.7320941342,
        right: -7850624.9389881147,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.508078692267915],
              [-70.531143368022896, -33.504333497173661],
              [-70.525956939893689, -33.504333497173661],
              [-70.523363725829086, -33.508078692267915],
              [-70.525956939893689, -33.511823725284408],
              [-70.531143368022896, -33.511823725284408],
              [-70.533736582087499, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 931,
        left: -7851779.6400648728,
        bottom: -3962438.7316278839,
        right: -7850624.9389881147,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.500588140010635],
              [-70.531143368022896, -33.496842620787838],
              [-70.525956939893689, -33.496842620787838],
              [-70.523363725829086, -33.500588140010635],
              [-70.525956939893689, -33.504333497173661],
              [-70.531143368022896, -33.504333497173661],
              [-70.533736582087499, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 932,
        left: -7851779.6400648728,
        bottom: -3961438.7311616335,
        right: -7850624.9389881147,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.493096939514267],
              [-70.531143368022896, -33.489351096198902],
              [-70.525956939893689, -33.489351096198902],
              [-70.523363725829086, -33.493096939514267],
              [-70.525956939893689, -33.496842620787838],
              [-70.531143368022896, -33.496842620787838],
              [-70.533736582087499, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 933,
        left: -7851779.6400648728,
        bottom: -3960438.7306953832,
        right: -7850624.9389881147,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.485605090850768],
              [-70.531143368022896, -33.48185892347886],
              [-70.525956939893689, -33.48185892347886],
              [-70.523363725829086, -33.485605090850768],
              [-70.525956939893689, -33.489351096198902],
              [-70.531143368022896, -33.489351096198902],
              [-70.533736582087499, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 934,
        left: -7851779.6400648728,
        bottom: -3959438.7302291333,
        right: -7850624.9389881147,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.478112594092174],
              [-70.531143368022896, -33.474366102699733],
              [-70.525956939893689, -33.474366102699733],
              [-70.523363725829086, -33.478112594092174],
              [-70.525956939893689, -33.48185892347886],
              [-70.531143368022896, -33.48185892347886],
              [-70.533736582087499, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 935,
        left: -7851779.6400648728,
        bottom: -3958438.7297628829,
        right: -7850624.9389881147,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.470619449310547],
              [-70.531143368022896, -33.466872633933626],
              [-70.525956939893689, -33.466872633933626],
              [-70.523363725829086, -33.470619449310547],
              [-70.525956939893689, -33.474366102699733],
              [-70.531143368022896, -33.474366102699733],
              [-70.533736582087499, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 936,
        left: -7851779.6400648728,
        bottom: -3957438.7292966326,
        right: -7850624.9389881147,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.463125656578008],
              [-70.531143368022896, -33.459378517252702],
              [-70.525956939893689, -33.459378517252702],
              [-70.523363725829086, -33.463125656578008],
              [-70.525956939893689, -33.466872633933626],
              [-70.531143368022896, -33.466872633933626],
              [-70.533736582087499, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 937,
        left: -7851779.6400648728,
        bottom: -3956438.7288303822,
        right: -7850624.9389881147,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.45563121596674],
              [-70.531143368022896, -33.451883752729138],
              [-70.525956939893689, -33.451883752729138],
              [-70.523363725829086, -33.45563121596674],
              [-70.525956939893689, -33.459378517252702],
              [-70.531143368022896, -33.459378517252702],
              [-70.533736582087499, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 938,
        left: -7851779.6400648728,
        bottom: -3955438.7283641323,
        right: -7850624.9389881147,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.448136127548949],
              [-70.531143368022896, -33.444388340435196],
              [-70.525956939893689, -33.444388340435196],
              [-70.523363725829086, -33.448136127548949],
              [-70.525956939893689, -33.451883752729138],
              [-70.531143368022896, -33.451883752729138],
              [-70.533736582087499, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 939,
        left: -7851779.6400648728,
        bottom: -3954438.7278978815,
        right: -7850624.9389881147,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.440640391396926],
              [-70.531143368022896, -33.436892280443168],
              [-70.525956939893689, -33.436892280443168],
              [-70.523363725829086, -33.440640391396926],
              [-70.525956939893689, -33.444388340435196],
              [-70.531143368022896, -33.444388340435196],
              [-70.533736582087499, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 940,
        left: -7851779.6400648728,
        bottom: -3953438.7274316312,
        right: -7850624.9389881147,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.433144007582989],
              [-70.531143368022896, -33.429395572825413],
              [-70.525956939893689, -33.429395572825413],
              [-70.523363725829086, -33.433144007582989],
              [-70.525956939893689, -33.436892280443168],
              [-70.531143368022896, -33.436892280443168],
              [-70.533736582087499, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 941,
        left: -7851779.6400648728,
        bottom: -3952438.7269653808,
        right: -7850624.9389881147,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.425646976179515],
              [-70.531143368022896, -33.421898217654316],
              [-70.525956939893689, -33.421898217654316],
              [-70.523363725829086, -33.425646976179515],
              [-70.525956939893689, -33.429395572825413],
              [-70.531143368022896, -33.429395572825413],
              [-70.533736582087499, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 942,
        left: -7851779.6400648728,
        bottom: -3951438.7264991305,
        right: -7850624.9389881147,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.418149297258921],
              [-70.531143368022896, -33.414400215002352],
              [-70.525956939893689, -33.414400215002352],
              [-70.523363725829086, -33.418149297258921],
              [-70.525956939893689, -33.421898217654316],
              [-70.531143368022896, -33.421898217654316],
              [-70.533736582087499, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 943,
        left: -7851779.6400648728,
        bottom: -3950438.7260328801,
        right: -7850624.9389881147,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.410650970893691],
              [-70.531143368022896, -33.406901564941997],
              [-70.525956939893689, -33.406901564941997],
              [-70.523363725829086, -33.410650970893691],
              [-70.525956939893689, -33.414400215002352],
              [-70.531143368022896, -33.414400215002352],
              [-70.533736582087499, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 944,
        left: -7851779.6400648728,
        bottom: -3949438.7255666298,
        right: -7850624.9389881147,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.40315199715635],
              [-70.531143368022896, -33.399402267545824],
              [-70.525956939893689, -33.399402267545824],
              [-70.523363725829086, -33.40315199715635],
              [-70.525956939893689, -33.406901564941997],
              [-70.531143368022896, -33.406901564941997],
              [-70.533736582087499, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 945,
        left: -7851779.6400648728,
        bottom: -3948438.7251003794,
        right: -7850624.9389881147,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.395652376119486],
              [-70.531143368022896, -33.391902322886416],
              [-70.525956939893689, -33.391902322886416],
              [-70.523363725829086, -33.395652376119486],
              [-70.525956939893689, -33.399402267545824],
              [-70.531143368022896, -33.399402267545824],
              [-70.533736582087499, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 946,
        left: -7851779.6400648728,
        bottom: -3947438.7246341291,
        right: -7850624.9389881147,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.388152107855717],
              [-70.531143368022896, -33.384401731036441],
              [-70.525956939893689, -33.384401731036441],
              [-70.523363725829086, -33.388152107855717],
              [-70.525956939893689, -33.391902322886416],
              [-70.531143368022896, -33.391902322886416],
              [-70.533736582087499, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 947,
        left: -7851779.6400648728,
        bottom: -3946438.7241678787,
        right: -7850624.9389881147,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.380651192437711],
              [-70.531143368022896, -33.3769004920686],
              [-70.525956939893689, -33.3769004920686],
              [-70.523363725829086, -33.380651192437711],
              [-70.525956939893689, -33.384401731036441],
              [-70.531143368022896, -33.384401731036441],
              [-70.533736582087499, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 948,
        left: -7851779.6400648728,
        bottom: -3945438.7237016284,
        right: -7850624.9389881147,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.373149629938226],
              [-70.531143368022896, -33.369398606055647],
              [-70.525956939893689, -33.369398606055647],
              [-70.523363725829086, -33.373149629938226],
              [-70.525956939893689, -33.3769004920686],
              [-70.531143368022896, -33.3769004920686],
              [-70.533736582087499, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 949,
        left: -7851779.6400648728,
        bottom: -3944438.723235378,
        right: -7850624.9389881147,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.365647420430008],
              [-70.531143368022896, -33.36189607307039],
              [-70.525956939893689, -33.36189607307039],
              [-70.523363725829086, -33.365647420430008],
              [-70.525956939893689, -33.369398606055647],
              [-70.531143368022896, -33.369398606055647],
              [-70.533736582087499, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 950,
        left: -7851779.6400648728,
        bottom: -3943438.7227691277,
        right: -7850624.9389881147,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.358144563985917],
              [-70.531143368022896, -33.35439289318569],
              [-70.525956939893689, -33.35439289318569],
              [-70.523363725829086, -33.358144563985917],
              [-70.525956939893689, -33.36189607307039],
              [-70.531143368022896, -33.36189607307039],
              [-70.533736582087499, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 951,
        left: -7851779.6400648728,
        bottom: -3942438.7223028769,
        right: -7850624.9389881147,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.350641060678818],
              [-70.531143368022896, -33.346889066474425],
              [-70.525956939893689, -33.346889066474425],
              [-70.523363725829086, -33.350641060678818],
              [-70.525956939893689, -33.35439289318569],
              [-70.531143368022896, -33.35439289318569],
              [-70.533736582087499, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 952,
        left: -7851779.6400648728,
        bottom: -3941438.721836627,
        right: -7850624.9389881147,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.343136910581649],
              [-70.531143368022896, -33.339384593009591],
              [-70.525956939893689, -33.339384593009591],
              [-70.523363725829086, -33.343136910581649],
              [-70.525956939893689, -33.346889066474425],
              [-70.531143368022896, -33.346889066474425],
              [-70.533736582087499, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 953,
        left: -7851779.6400648728,
        bottom: -3940438.7213703771,
        right: -7850624.9389881147,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.335632113767389],
              [-70.531143368022896, -33.331879472864181],
              [-70.525956939893689, -33.331879472864181],
              [-70.523363725829086, -33.335632113767389],
              [-70.525956939893689, -33.339384593009591],
              [-70.531143368022896, -33.339384593009591],
              [-70.533736582087499, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 954,
        left: -7851779.6400648728,
        bottom: -3939438.7209041263,
        right: -7850624.9389881147,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.328126670309082],
              [-70.531143368022896, -33.324373706111238],
              [-70.525956939893689, -33.324373706111238],
              [-70.523363725829086, -33.328126670309082],
              [-70.525956939893689, -33.331879472864181],
              [-70.531143368022896, -33.331879472864181],
              [-70.533736582087499, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 955,
        left: -7851779.6400648728,
        bottom: -3938438.7204378759,
        right: -7850624.9389881147,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.320620580279794],
              [-70.531143368022896, -33.3168672928239],
              [-70.525956939893689, -33.3168672928239],
              [-70.523363725829086, -33.320620580279794],
              [-70.525956939893689, -33.324373706111238],
              [-70.531143368022896, -33.324373706111238],
              [-70.533736582087499, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 956,
        left: -7851779.6400648728,
        bottom: -3937438.7199716261,
        right: -7850624.9389881147,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.313113843752674],
              [-70.531143368022896, -33.309360233075296],
              [-70.525956939893689, -33.309360233075296],
              [-70.523363725829086, -33.313113843752674],
              [-70.525956939893689, -33.3168672928239],
              [-70.531143368022896, -33.3168672928239],
              [-70.533736582087499, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 957,
        left: -7851779.6400648728,
        bottom: -3936438.7195053762,
        right: -7850624.9389881147,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.305606460800909],
              [-70.531143368022896, -33.30185252693866],
              [-70.525956939893689, -33.30185252693866],
              [-70.523363725829086, -33.305606460800909],
              [-70.525956939893689, -33.309360233075296],
              [-70.531143368022896, -33.309360233075296],
              [-70.533736582087499, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 958,
        left: -7851779.6400648728,
        bottom: -3935438.7190391254,
        right: -7850624.9389881147,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.298098431497721],
              [-70.531143368022896, -33.294344174487243],
              [-70.525956939893689, -33.294344174487243],
              [-70.523363725829086, -33.298098431497721],
              [-70.525956939893689, -33.30185252693866],
              [-70.531143368022896, -33.30185252693866],
              [-70.533736582087499, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 959,
        left: -7851779.6400648728,
        bottom: -3934438.718572875,
        right: -7850624.9389881147,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.290589755916407],
              [-70.531143368022896, -33.286835175794366],
              [-70.525956939893689, -33.286835175794366],
              [-70.523363725829086, -33.290589755916407],
              [-70.525956939893689, -33.294344174487243],
              [-70.531143368022896, -33.294344174487243],
              [-70.533736582087499, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 960,
        left: -7851779.6400648728,
        bottom: -3933438.7181066247,
        right: -7850624.9389881147,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.533736582087499, -33.283080434130305],
              [-70.531143368022896, -33.279325530933384],
              [-70.525956939893689, -33.279325530933384],
              [-70.523363725829086, -33.283080434130305],
              [-70.525956939893689, -33.286835175794366],
              [-70.531143368022896, -33.286835175794366],
              [-70.533736582087499, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 961,
        left: -7850913.6142573031,
        bottom: -3980938.7402535151,
        right: -7849758.913180545,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.639058347612803],
              [-70.523363725829086, -33.63531883056551],
              [-70.518177297699864, -33.63531883056551],
              [-70.515584083635261, -33.639058347612803],
              [-70.518177297699864, -33.642797702269725],
              [-70.523363725829086, -33.642797702269725],
              [-70.525956939893689, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 962,
        left: -7850913.6142573031,
        bottom: -3979938.7397872647,
        right: -7849758.913180545,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.631579151136748],
              [-70.523363725829086, -33.627839309335386],
              [-70.518177297699864, -33.627839309335386],
              [-70.515584083635261, -33.631579151136748],
              [-70.518177297699864, -33.63531883056551],
              [-70.523363725829086, -33.63531883056551],
              [-70.525956939893689, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 963,
        left: -7850913.6142573031,
        bottom: -3978938.7393210144,
        right: -7849758.913180545,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.624099305170297],
              [-70.523363725829086, -33.620359138650379],
              [-70.518177297699864, -33.620359138650379],
              [-70.515584083635261, -33.624099305170297],
              [-70.518177297699864, -33.627839309335386],
              [-70.523363725829086, -33.627839309335386],
              [-70.525956939893689, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 964,
        left: -7850913.6142573031,
        bottom: -3977938.738854764,
        right: -7849758.913180545,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.616618809784534],
              [-70.523363725829086, -33.612878318581629],
              [-70.518177297699864, -33.612878318581629],
              [-70.515584083635261, -33.616618809784534],
              [-70.518177297699864, -33.620359138650379],
              [-70.523363725829086, -33.620359138650379],
              [-70.525956939893689, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 965,
        left: -7850913.6142573031,
        bottom: -3976938.7383885137,
        right: -7849758.913180545,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.609137665050582],
              [-70.523363725829086, -33.605396849200282],
              [-70.518177297699864, -33.605396849200282],
              [-70.515584083635261, -33.609137665050582],
              [-70.518177297699864, -33.612878318581629],
              [-70.523363725829086, -33.612878318581629],
              [-70.525956939893689, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 966,
        left: -7850913.6142573031,
        bottom: -3975938.7379222633,
        right: -7849758.913180545,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.601655871039647],
              [-70.523363725829086, -33.597914730577571],
              [-70.518177297699864, -33.597914730577571],
              [-70.515584083635261, -33.601655871039647],
              [-70.518177297699864, -33.605396849200282],
              [-70.523363725829086, -33.605396849200282],
              [-70.525956939893689, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 967,
        left: -7850913.6142573031,
        bottom: -3974938.737456013,
        right: -7849758.913180545,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.594173427822959],
              [-70.523363725829086, -33.590431962784734],
              [-70.518177297699864, -33.590431962784734],
              [-70.515584083635261, -33.594173427822959],
              [-70.518177297699864, -33.597914730577571],
              [-70.523363725829086, -33.597914730577571],
              [-70.525956939893689, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 968,
        left: -7850913.6142573031,
        bottom: -3973938.7369897626,
        right: -7849758.913180545,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.586690335471808],
              [-70.523363725829086, -33.582948545893096],
              [-70.518177297699864, -33.582948545893096],
              [-70.515584083635261, -33.586690335471808],
              [-70.518177297699864, -33.590431962784734],
              [-70.523363725829086, -33.590431962784734],
              [-70.525956939893689, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 969,
        left: -7850913.6142573031,
        bottom: -3972938.7365235123,
        right: -7849758.913180545,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.579206594057531],
              [-70.523363725829086, -33.575464479974038],
              [-70.518177297699864, -33.575464479974038],
              [-70.515584083635261, -33.579206594057531],
              [-70.518177297699864, -33.582948545893096],
              [-70.523363725829086, -33.582948545893096],
              [-70.525956939893689, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 970,
        left: -7850913.6142573031,
        bottom: -3971938.7360572619,
        right: -7849758.913180545,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.571722203651518],
              [-70.523363725829086, -33.56797976509894],
              [-70.518177297699864, -33.56797976509894],
              [-70.515584083635261, -33.571722203651518],
              [-70.518177297699864, -33.575464479974038],
              [-70.523363725829086, -33.575464479974038],
              [-70.525956939893689, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 971,
        left: -7850913.6142573031,
        bottom: -3970938.7355910116,
        right: -7849758.913180545,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.56423716432522],
              [-70.523363725829086, -33.560494401339298],
              [-70.518177297699864, -33.560494401339298],
              [-70.515584083635261, -33.56423716432522],
              [-70.518177297699864, -33.56797976509894],
              [-70.523363725829086, -33.56797976509894],
              [-70.525956939893689, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 972,
        left: -7850913.6142573031,
        bottom: -3969938.7351247617,
        right: -7849758.913180545,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.556751476150104],
              [-70.523363725829086, -33.553008388766614],
              [-70.518177297699864, -33.553008388766614],
              [-70.515584083635261, -33.556751476150104],
              [-70.518177297699864, -33.560494401339298],
              [-70.523363725829086, -33.560494401339298],
              [-70.525956939893689, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 973,
        left: -7850913.6142573031,
        bottom: -3968938.7346585114,
        right: -7849758.913180545,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.549265139197736],
              [-70.523363725829086, -33.54552172745246],
              [-70.518177297699864, -33.54552172745246],
              [-70.515584083635261, -33.549265139197736],
              [-70.518177297699864, -33.553008388766614],
              [-70.523363725829086, -33.553008388766614],
              [-70.525956939893689, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 974,
        left: -7850913.6142573031,
        bottom: -3967938.734192261,
        right: -7849758.913180545,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.541778153539695],
              [-70.523363725829086, -33.538034417468438],
              [-70.518177297699864, -33.538034417468438],
              [-70.515584083635261, -33.541778153539695],
              [-70.518177297699864, -33.54552172745246],
              [-70.523363725829086, -33.54552172745246],
              [-70.525956939893689, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 975,
        left: -7850913.6142573031,
        bottom: -3966938.7337260107,
        right: -7849758.913180545,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.534290519247627],
              [-70.523363725829086, -33.530546458886235],
              [-70.518177297699864, -33.530546458886235],
              [-70.515584083635261, -33.534290519247627],
              [-70.518177297699864, -33.538034417468438],
              [-70.523363725829086, -33.538034417468438],
              [-70.525956939893689, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 976,
        left: -7850913.6142573031,
        bottom: -3965938.7332597603,
        right: -7849758.913180545,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.526802236393216],
              [-70.523363725829086, -33.523057851777558],
              [-70.518177297699864, -33.523057851777558],
              [-70.515584083635261, -33.526802236393216],
              [-70.518177297699864, -33.530546458886235],
              [-70.523363725829086, -33.530546458886235],
              [-70.525956939893689, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 977,
        left: -7850913.6142573031,
        bottom: -3964938.7327935095,
        right: -7849758.913180545,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.519313305048215],
              [-70.523363725829086, -33.515568596214173],
              [-70.518177297699864, -33.515568596214173],
              [-70.515584083635261, -33.519313305048215],
              [-70.518177297699864, -33.523057851777558],
              [-70.523363725829086, -33.523057851777558],
              [-70.525956939893689, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 978,
        left: -7850913.6142573031,
        bottom: -3963938.7323272596,
        right: -7849758.913180545,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.511823725284408],
              [-70.523363725829086, -33.508078692267915],
              [-70.518177297699864, -33.508078692267915],
              [-70.515584083635261, -33.511823725284408],
              [-70.518177297699864, -33.515568596214173],
              [-70.523363725829086, -33.515568596214173],
              [-70.525956939893689, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 979,
        left: -7850913.6142573031,
        bottom: -3962938.7318610097,
        right: -7849758.913180545,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.504333497173661],
              [-70.523363725829086, -33.500588140010635],
              [-70.518177297699864, -33.500588140010635],
              [-70.515584083635261, -33.504333497173661],
              [-70.518177297699864, -33.508078692267915],
              [-70.523363725829086, -33.508078692267915],
              [-70.525956939893689, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 980,
        left: -7850913.6142573031,
        bottom: -3961938.7313947589,
        right: -7849758.913180545,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.496842620787838],
              [-70.523363725829086, -33.493096939514267],
              [-70.518177297699864, -33.493096939514267],
              [-70.515584083635261, -33.496842620787838],
              [-70.518177297699864, -33.500588140010635],
              [-70.523363725829086, -33.500588140010635],
              [-70.525956939893689, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 981,
        left: -7850913.6142573031,
        bottom: -3960938.7309285086,
        right: -7849758.913180545,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.489351096198902],
              [-70.523363725829086, -33.485605090850768],
              [-70.518177297699864, -33.485605090850768],
              [-70.515584083635261, -33.489351096198902],
              [-70.518177297699864, -33.493096939514267],
              [-70.523363725829086, -33.493096939514267],
              [-70.525956939893689, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 982,
        left: -7850913.6142573031,
        bottom: -3959938.7304622582,
        right: -7849758.913180545,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.48185892347886],
              [-70.523363725829086, -33.478112594092174],
              [-70.518177297699864, -33.478112594092174],
              [-70.515584083635261, -33.48185892347886],
              [-70.518177297699864, -33.485605090850768],
              [-70.523363725829086, -33.485605090850768],
              [-70.525956939893689, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 983,
        left: -7850913.6142573031,
        bottom: -3958938.7299960079,
        right: -7849758.913180545,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.474366102699733],
              [-70.523363725829086, -33.470619449310547],
              [-70.518177297699864, -33.470619449310547],
              [-70.515584083635261, -33.474366102699733],
              [-70.518177297699864, -33.478112594092174],
              [-70.523363725829086, -33.478112594092174],
              [-70.525956939893689, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 984,
        left: -7850913.6142573031,
        bottom: -3957938.7295297575,
        right: -7849758.913180545,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.466872633933626],
              [-70.523363725829086, -33.463125656578008],
              [-70.518177297699864, -33.463125656578008],
              [-70.515584083635261, -33.466872633933626],
              [-70.518177297699864, -33.470619449310547],
              [-70.523363725829086, -33.470619449310547],
              [-70.525956939893689, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 985,
        left: -7850913.6142573031,
        bottom: -3956938.7290635072,
        right: -7849758.913180545,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.459378517252702],
              [-70.523363725829086, -33.45563121596674],
              [-70.518177297699864, -33.45563121596674],
              [-70.515584083635261, -33.459378517252702],
              [-70.518177297699864, -33.463125656578008],
              [-70.523363725829086, -33.463125656578008],
              [-70.525956939893689, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 986,
        left: -7850913.6142573031,
        bottom: -3955938.7285972568,
        right: -7849758.913180545,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.451883752729138],
              [-70.523363725829086, -33.448136127548949],
              [-70.518177297699864, -33.448136127548949],
              [-70.515584083635261, -33.451883752729138],
              [-70.518177297699864, -33.45563121596674],
              [-70.523363725829086, -33.45563121596674],
              [-70.525956939893689, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 987,
        left: -7850913.6142573031,
        bottom: -3954938.7281310065,
        right: -7849758.913180545,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.444388340435196],
              [-70.523363725829086, -33.440640391396926],
              [-70.518177297699864, -33.440640391396926],
              [-70.515584083635261, -33.444388340435196],
              [-70.518177297699864, -33.448136127548949],
              [-70.523363725829086, -33.448136127548949],
              [-70.525956939893689, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 988,
        left: -7850913.6142573031,
        bottom: -3953938.7276647561,
        right: -7849758.913180545,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.436892280443168],
              [-70.523363725829086, -33.433144007582989],
              [-70.518177297699864, -33.433144007582989],
              [-70.515584083635261, -33.436892280443168],
              [-70.518177297699864, -33.440640391396926],
              [-70.523363725829086, -33.440640391396926],
              [-70.525956939893689, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 989,
        left: -7850913.6142573031,
        bottom: -3952938.7271985058,
        right: -7849758.913180545,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.429395572825413],
              [-70.523363725829086, -33.425646976179515],
              [-70.518177297699864, -33.425646976179515],
              [-70.515584083635261, -33.429395572825413],
              [-70.518177297699864, -33.433144007582989],
              [-70.523363725829086, -33.433144007582989],
              [-70.525956939893689, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 990,
        left: -7850913.6142573031,
        bottom: -3951938.7267322554,
        right: -7849758.913180545,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.421898217654316],
              [-70.523363725829086, -33.418149297258921],
              [-70.518177297699864, -33.418149297258921],
              [-70.515584083635261, -33.421898217654316],
              [-70.518177297699864, -33.425646976179515],
              [-70.523363725829086, -33.425646976179515],
              [-70.525956939893689, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 991,
        left: -7850913.6142573031,
        bottom: -3950938.7262660051,
        right: -7849758.913180545,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.414400215002352],
              [-70.523363725829086, -33.410650970893691],
              [-70.518177297699864, -33.410650970893691],
              [-70.515584083635261, -33.414400215002352],
              [-70.518177297699864, -33.418149297258921],
              [-70.523363725829086, -33.418149297258921],
              [-70.525956939893689, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 992,
        left: -7850913.6142573031,
        bottom: -3949938.7257997552,
        right: -7849758.913180545,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.406901564941997],
              [-70.523363725829086, -33.40315199715635],
              [-70.518177297699864, -33.40315199715635],
              [-70.515584083635261, -33.406901564941997],
              [-70.518177297699864, -33.410650970893691],
              [-70.523363725829086, -33.410650970893691],
              [-70.525956939893689, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 993,
        left: -7850913.6142573031,
        bottom: -3948938.7253335048,
        right: -7849758.913180545,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.399402267545824],
              [-70.523363725829086, -33.395652376119486],
              [-70.518177297699864, -33.395652376119486],
              [-70.515584083635261, -33.399402267545824],
              [-70.518177297699864, -33.40315199715635],
              [-70.523363725829086, -33.40315199715635],
              [-70.525956939893689, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 994,
        left: -7850913.6142573031,
        bottom: -3947938.724867254,
        right: -7849758.913180545,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.391902322886416],
              [-70.523363725829086, -33.388152107855717],
              [-70.518177297699864, -33.388152107855717],
              [-70.515584083635261, -33.391902322886416],
              [-70.518177297699864, -33.395652376119486],
              [-70.523363725829086, -33.395652376119486],
              [-70.525956939893689, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 995,
        left: -7850913.6142573031,
        bottom: -3946938.7244010041,
        right: -7849758.913180545,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.384401731036441],
              [-70.523363725829086, -33.380651192437711],
              [-70.518177297699864, -33.380651192437711],
              [-70.515584083635261, -33.384401731036441],
              [-70.518177297699864, -33.388152107855717],
              [-70.523363725829086, -33.388152107855717],
              [-70.525956939893689, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 996,
        left: -7850913.6142573031,
        bottom: -3945938.7239347543,
        right: -7849758.913180545,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.3769004920686],
              [-70.523363725829086, -33.373149629938226],
              [-70.518177297699864, -33.373149629938226],
              [-70.515584083635261, -33.3769004920686],
              [-70.518177297699864, -33.380651192437711],
              [-70.523363725829086, -33.380651192437711],
              [-70.525956939893689, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 997,
        left: -7850913.6142573031,
        bottom: -3944938.7234685034,
        right: -7849758.913180545,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.369398606055647],
              [-70.523363725829086, -33.365647420430008],
              [-70.518177297699864, -33.365647420430008],
              [-70.515584083635261, -33.369398606055647],
              [-70.518177297699864, -33.373149629938226],
              [-70.523363725829086, -33.373149629938226],
              [-70.525956939893689, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 998,
        left: -7850913.6142573031,
        bottom: -3943938.7230022531,
        right: -7849758.913180545,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.36189607307039],
              [-70.523363725829086, -33.358144563985917],
              [-70.518177297699864, -33.358144563985917],
              [-70.515584083635261, -33.36189607307039],
              [-70.518177297699864, -33.365647420430008],
              [-70.523363725829086, -33.365647420430008],
              [-70.525956939893689, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 999,
        left: -7850913.6142573031,
        bottom: -3942938.7225360028,
        right: -7849758.913180545,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.35439289318569],
              [-70.523363725829086, -33.350641060678818],
              [-70.518177297699864, -33.350641060678818],
              [-70.515584083635261, -33.35439289318569],
              [-70.518177297699864, -33.358144563985917],
              [-70.523363725829086, -33.358144563985917],
              [-70.525956939893689, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1000,
        left: -7850913.6142573031,
        bottom: -3941938.7220697524,
        right: -7849758.913180545,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.346889066474425],
              [-70.523363725829086, -33.343136910581649],
              [-70.518177297699864, -33.343136910581649],
              [-70.515584083635261, -33.346889066474425],
              [-70.518177297699864, -33.350641060678818],
              [-70.523363725829086, -33.350641060678818],
              [-70.525956939893689, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1001,
        left: -7850913.6142573031,
        bottom: -3940938.7216035021,
        right: -7849758.913180545,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.339384593009591],
              [-70.523363725829086, -33.335632113767389],
              [-70.518177297699864, -33.335632113767389],
              [-70.515584083635261, -33.339384593009591],
              [-70.518177297699864, -33.343136910581649],
              [-70.523363725829086, -33.343136910581649],
              [-70.525956939893689, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1002,
        left: -7850913.6142573031,
        bottom: -3939938.7211372517,
        right: -7849758.913180545,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.331879472864181],
              [-70.523363725829086, -33.328126670309082],
              [-70.518177297699864, -33.328126670309082],
              [-70.515584083635261, -33.331879472864181],
              [-70.518177297699864, -33.335632113767389],
              [-70.523363725829086, -33.335632113767389],
              [-70.525956939893689, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1003,
        left: -7850913.6142573031,
        bottom: -3938938.7206710014,
        right: -7849758.913180545,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.324373706111238],
              [-70.523363725829086, -33.320620580279794],
              [-70.518177297699864, -33.320620580279794],
              [-70.515584083635261, -33.324373706111238],
              [-70.518177297699864, -33.328126670309082],
              [-70.523363725829086, -33.328126670309082],
              [-70.525956939893689, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1004,
        left: -7850913.6142573031,
        bottom: -3937938.720204751,
        right: -7849758.913180545,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.3168672928239],
              [-70.523363725829086, -33.313113843752674],
              [-70.518177297699864, -33.313113843752674],
              [-70.515584083635261, -33.3168672928239],
              [-70.518177297699864, -33.320620580279794],
              [-70.523363725829086, -33.320620580279794],
              [-70.525956939893689, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1005,
        left: -7850913.6142573031,
        bottom: -3936938.7197385007,
        right: -7849758.913180545,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.309360233075296],
              [-70.523363725829086, -33.305606460800909],
              [-70.518177297699864, -33.305606460800909],
              [-70.515584083635261, -33.309360233075296],
              [-70.518177297699864, -33.313113843752674],
              [-70.523363725829086, -33.313113843752674],
              [-70.525956939893689, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1006,
        left: -7850913.6142573031,
        bottom: -3935938.7192722503,
        right: -7849758.913180545,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.30185252693866],
              [-70.523363725829086, -33.298098431497721],
              [-70.518177297699864, -33.298098431497721],
              [-70.515584083635261, -33.30185252693866],
              [-70.518177297699864, -33.305606460800909],
              [-70.523363725829086, -33.305606460800909],
              [-70.525956939893689, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1007,
        left: -7850913.6142573031,
        bottom: -3934938.718806,
        right: -7849758.913180545,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.294344174487243],
              [-70.523363725829086, -33.290589755916407],
              [-70.518177297699864, -33.290589755916407],
              [-70.515584083635261, -33.294344174487243],
              [-70.518177297699864, -33.298098431497721],
              [-70.523363725829086, -33.298098431497721],
              [-70.525956939893689, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1008,
        left: -7850913.6142573031,
        bottom: -3933938.7183397496,
        right: -7849758.913180545,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.525956939893689, -33.286835175794366],
              [-70.523363725829086, -33.283080434130305],
              [-70.518177297699864, -33.283080434130305],
              [-70.515584083635261, -33.286835175794366],
              [-70.518177297699864, -33.290589755916407],
              [-70.523363725829086, -33.290589755916407],
              [-70.525956939893689, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1009,
        left: -7850047.5884497343,
        bottom: -3980438.7400203901,
        right: -7848892.8873729762,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.63531883056551],
              [-70.515584083635261, -33.631579151136748],
              [-70.510397655506054, -33.631579151136748],
              [-70.507804441441465, -33.63531883056551],
              [-70.510397655506054, -33.639058347612803],
              [-70.515584083635261, -33.639058347612803],
              [-70.518177297699864, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1010,
        left: -7850047.5884497343,
        bottom: -3979438.7395541398,
        right: -7848892.8873729762,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.627839309335386],
              [-70.515584083635261, -33.624099305170297],
              [-70.510397655506054, -33.624099305170297],
              [-70.507804441441465, -33.627839309335386],
              [-70.510397655506054, -33.631579151136748],
              [-70.515584083635261, -33.631579151136748],
              [-70.518177297699864, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1011,
        left: -7850047.5884497343,
        bottom: -3978438.7390878894,
        right: -7848892.8873729762,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.620359138650379],
              [-70.515584083635261, -33.616618809784534],
              [-70.510397655506054, -33.616618809784534],
              [-70.507804441441465, -33.620359138650379],
              [-70.510397655506054, -33.624099305170297],
              [-70.515584083635261, -33.624099305170297],
              [-70.518177297699864, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1012,
        left: -7850047.5884497343,
        bottom: -3977438.7386216391,
        right: -7848892.8873729762,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.612878318581629],
              [-70.515584083635261, -33.609137665050582],
              [-70.510397655506054, -33.609137665050582],
              [-70.507804441441465, -33.612878318581629],
              [-70.510397655506054, -33.616618809784534],
              [-70.515584083635261, -33.616618809784534],
              [-70.518177297699864, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1013,
        left: -7850047.5884497343,
        bottom: -3976438.7381553887,
        right: -7848892.8873729762,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.605396849200282],
              [-70.515584083635261, -33.601655871039647],
              [-70.510397655506054, -33.601655871039647],
              [-70.507804441441465, -33.605396849200282],
              [-70.510397655506054, -33.609137665050582],
              [-70.515584083635261, -33.609137665050582],
              [-70.518177297699864, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1014,
        left: -7850047.5884497343,
        bottom: -3975438.7376891384,
        right: -7848892.8873729762,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.597914730577571],
              [-70.515584083635261, -33.594173427822959],
              [-70.510397655506054, -33.594173427822959],
              [-70.507804441441465, -33.597914730577571],
              [-70.510397655506054, -33.601655871039647],
              [-70.515584083635261, -33.601655871039647],
              [-70.518177297699864, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1015,
        left: -7850047.5884497343,
        bottom: -3974438.7372228876,
        right: -7848892.8873729762,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.590431962784734],
              [-70.515584083635261, -33.586690335471808],
              [-70.510397655506054, -33.586690335471808],
              [-70.507804441441465, -33.590431962784734],
              [-70.510397655506054, -33.594173427822959],
              [-70.515584083635261, -33.594173427822959],
              [-70.518177297699864, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1016,
        left: -7850047.5884497343,
        bottom: -3973438.7367566377,
        right: -7848892.8873729762,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.582948545893096],
              [-70.515584083635261, -33.579206594057531],
              [-70.510397655506054, -33.579206594057531],
              [-70.507804441441465, -33.582948545893096],
              [-70.510397655506054, -33.586690335471808],
              [-70.515584083635261, -33.586690335471808],
              [-70.518177297699864, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1017,
        left: -7850047.5884497343,
        bottom: -3972438.7362903878,
        right: -7848892.8873729762,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.575464479974038],
              [-70.515584083635261, -33.571722203651518],
              [-70.510397655506054, -33.571722203651518],
              [-70.507804441441465, -33.575464479974038],
              [-70.510397655506054, -33.579206594057531],
              [-70.515584083635261, -33.579206594057531],
              [-70.518177297699864, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1018,
        left: -7850047.5884497343,
        bottom: -3971438.735824137,
        right: -7848892.8873729762,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.56797976509894],
              [-70.515584083635261, -33.56423716432522],
              [-70.510397655506054, -33.56423716432522],
              [-70.507804441441465, -33.56797976509894],
              [-70.510397655506054, -33.571722203651518],
              [-70.515584083635261, -33.571722203651518],
              [-70.518177297699864, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1019,
        left: -7850047.5884497343,
        bottom: -3970438.7353578866,
        right: -7848892.8873729762,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.560494401339298],
              [-70.515584083635261, -33.556751476150104],
              [-70.510397655506054, -33.556751476150104],
              [-70.507804441441465, -33.560494401339298],
              [-70.510397655506054, -33.56423716432522],
              [-70.515584083635261, -33.56423716432522],
              [-70.518177297699864, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1020,
        left: -7850047.5884497343,
        bottom: -3969438.7348916363,
        right: -7848892.8873729762,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.553008388766614],
              [-70.515584083635261, -33.549265139197736],
              [-70.510397655506054, -33.549265139197736],
              [-70.507804441441465, -33.553008388766614],
              [-70.510397655506054, -33.556751476150104],
              [-70.515584083635261, -33.556751476150104],
              [-70.518177297699864, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1021,
        left: -7850047.5884497343,
        bottom: -3968438.7344253859,
        right: -7848892.8873729762,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.54552172745246],
              [-70.515584083635261, -33.541778153539695],
              [-70.510397655506054, -33.541778153539695],
              [-70.507804441441465, -33.54552172745246],
              [-70.510397655506054, -33.549265139197736],
              [-70.515584083635261, -33.549265139197736],
              [-70.518177297699864, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1022,
        left: -7850047.5884497343,
        bottom: -3967438.7339591356,
        right: -7848892.8873729762,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.538034417468438],
              [-70.515584083635261, -33.534290519247627],
              [-70.510397655506054, -33.534290519247627],
              [-70.507804441441465, -33.538034417468438],
              [-70.510397655506054, -33.541778153539695],
              [-70.515584083635261, -33.541778153539695],
              [-70.518177297699864, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1023,
        left: -7850047.5884497343,
        bottom: -3966438.7334928853,
        right: -7848892.8873729762,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.530546458886235],
              [-70.515584083635261, -33.526802236393216],
              [-70.510397655506054, -33.526802236393216],
              [-70.507804441441465, -33.530546458886235],
              [-70.510397655506054, -33.534290519247627],
              [-70.515584083635261, -33.534290519247627],
              [-70.518177297699864, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1024,
        left: -7850047.5884497343,
        bottom: -3965438.7330266349,
        right: -7848892.8873729762,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.523057851777558],
              [-70.515584083635261, -33.519313305048215],
              [-70.510397655506054, -33.519313305048215],
              [-70.507804441441465, -33.523057851777558],
              [-70.510397655506054, -33.526802236393216],
              [-70.515584083635261, -33.526802236393216],
              [-70.518177297699864, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1025,
        left: -7850047.5884497343,
        bottom: -3964438.7325603846,
        right: -7848892.8873729762,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.515568596214173],
              [-70.515584083635261, -33.511823725284408],
              [-70.510397655506054, -33.511823725284408],
              [-70.507804441441465, -33.515568596214173],
              [-70.510397655506054, -33.519313305048215],
              [-70.515584083635261, -33.519313305048215],
              [-70.518177297699864, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1026,
        left: -7850047.5884497343,
        bottom: -3963438.7320941342,
        right: -7848892.8873729762,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.508078692267915],
              [-70.515584083635261, -33.504333497173661],
              [-70.510397655506054, -33.504333497173661],
              [-70.507804441441465, -33.508078692267915],
              [-70.510397655506054, -33.511823725284408],
              [-70.515584083635261, -33.511823725284408],
              [-70.518177297699864, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1027,
        left: -7850047.5884497343,
        bottom: -3962438.7316278839,
        right: -7848892.8873729762,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.500588140010635],
              [-70.515584083635261, -33.496842620787838],
              [-70.510397655506054, -33.496842620787838],
              [-70.507804441441465, -33.500588140010635],
              [-70.510397655506054, -33.504333497173661],
              [-70.515584083635261, -33.504333497173661],
              [-70.518177297699864, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1028,
        left: -7850047.5884497343,
        bottom: -3961438.7311616335,
        right: -7848892.8873729762,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.493096939514267],
              [-70.515584083635261, -33.489351096198902],
              [-70.510397655506054, -33.489351096198902],
              [-70.507804441441465, -33.493096939514267],
              [-70.510397655506054, -33.496842620787838],
              [-70.515584083635261, -33.496842620787838],
              [-70.518177297699864, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1029,
        left: -7850047.5884497343,
        bottom: -3960438.7306953832,
        right: -7848892.8873729762,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.485605090850768],
              [-70.515584083635261, -33.48185892347886],
              [-70.510397655506054, -33.48185892347886],
              [-70.507804441441465, -33.485605090850768],
              [-70.510397655506054, -33.489351096198902],
              [-70.515584083635261, -33.489351096198902],
              [-70.518177297699864, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1030,
        left: -7850047.5884497343,
        bottom: -3959438.7302291333,
        right: -7848892.8873729762,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.478112594092174],
              [-70.515584083635261, -33.474366102699733],
              [-70.510397655506054, -33.474366102699733],
              [-70.507804441441465, -33.478112594092174],
              [-70.510397655506054, -33.48185892347886],
              [-70.515584083635261, -33.48185892347886],
              [-70.518177297699864, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1031,
        left: -7850047.5884497343,
        bottom: -3958438.7297628829,
        right: -7848892.8873729762,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.470619449310547],
              [-70.515584083635261, -33.466872633933626],
              [-70.510397655506054, -33.466872633933626],
              [-70.507804441441465, -33.470619449310547],
              [-70.510397655506054, -33.474366102699733],
              [-70.515584083635261, -33.474366102699733],
              [-70.518177297699864, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1032,
        left: -7850047.5884497343,
        bottom: -3957438.7292966326,
        right: -7848892.8873729762,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.463125656578008],
              [-70.515584083635261, -33.459378517252702],
              [-70.510397655506054, -33.459378517252702],
              [-70.507804441441465, -33.463125656578008],
              [-70.510397655506054, -33.466872633933626],
              [-70.515584083635261, -33.466872633933626],
              [-70.518177297699864, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1033,
        left: -7850047.5884497343,
        bottom: -3956438.7288303822,
        right: -7848892.8873729762,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.45563121596674],
              [-70.515584083635261, -33.451883752729138],
              [-70.510397655506054, -33.451883752729138],
              [-70.507804441441465, -33.45563121596674],
              [-70.510397655506054, -33.459378517252702],
              [-70.515584083635261, -33.459378517252702],
              [-70.518177297699864, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1034,
        left: -7850047.5884497343,
        bottom: -3955438.7283641323,
        right: -7848892.8873729762,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.448136127548949],
              [-70.515584083635261, -33.444388340435196],
              [-70.510397655506054, -33.444388340435196],
              [-70.507804441441465, -33.448136127548949],
              [-70.510397655506054, -33.451883752729138],
              [-70.515584083635261, -33.451883752729138],
              [-70.518177297699864, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1035,
        left: -7850047.5884497343,
        bottom: -3954438.7278978815,
        right: -7848892.8873729762,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.440640391396926],
              [-70.515584083635261, -33.436892280443168],
              [-70.510397655506054, -33.436892280443168],
              [-70.507804441441465, -33.440640391396926],
              [-70.510397655506054, -33.444388340435196],
              [-70.515584083635261, -33.444388340435196],
              [-70.518177297699864, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1036,
        left: -7850047.5884497343,
        bottom: -3953438.7274316312,
        right: -7848892.8873729762,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.433144007582989],
              [-70.515584083635261, -33.429395572825413],
              [-70.510397655506054, -33.429395572825413],
              [-70.507804441441465, -33.433144007582989],
              [-70.510397655506054, -33.436892280443168],
              [-70.515584083635261, -33.436892280443168],
              [-70.518177297699864, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1037,
        left: -7850047.5884497343,
        bottom: -3952438.7269653808,
        right: -7848892.8873729762,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.425646976179515],
              [-70.515584083635261, -33.421898217654316],
              [-70.510397655506054, -33.421898217654316],
              [-70.507804441441465, -33.425646976179515],
              [-70.510397655506054, -33.429395572825413],
              [-70.515584083635261, -33.429395572825413],
              [-70.518177297699864, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1038,
        left: -7850047.5884497343,
        bottom: -3951438.7264991305,
        right: -7848892.8873729762,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.418149297258921],
              [-70.515584083635261, -33.414400215002352],
              [-70.510397655506054, -33.414400215002352],
              [-70.507804441441465, -33.418149297258921],
              [-70.510397655506054, -33.421898217654316],
              [-70.515584083635261, -33.421898217654316],
              [-70.518177297699864, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1039,
        left: -7850047.5884497343,
        bottom: -3950438.7260328801,
        right: -7848892.8873729762,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.410650970893691],
              [-70.515584083635261, -33.406901564941997],
              [-70.510397655506054, -33.406901564941997],
              [-70.507804441441465, -33.410650970893691],
              [-70.510397655506054, -33.414400215002352],
              [-70.515584083635261, -33.414400215002352],
              [-70.518177297699864, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1040,
        left: -7850047.5884497343,
        bottom: -3949438.7255666298,
        right: -7848892.8873729762,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.40315199715635],
              [-70.515584083635261, -33.399402267545824],
              [-70.510397655506054, -33.399402267545824],
              [-70.507804441441465, -33.40315199715635],
              [-70.510397655506054, -33.406901564941997],
              [-70.515584083635261, -33.406901564941997],
              [-70.518177297699864, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1041,
        left: -7850047.5884497343,
        bottom: -3948438.7251003794,
        right: -7848892.8873729762,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.395652376119486],
              [-70.515584083635261, -33.391902322886416],
              [-70.510397655506054, -33.391902322886416],
              [-70.507804441441465, -33.395652376119486],
              [-70.510397655506054, -33.399402267545824],
              [-70.515584083635261, -33.399402267545824],
              [-70.518177297699864, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1042,
        left: -7850047.5884497343,
        bottom: -3947438.7246341291,
        right: -7848892.8873729762,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.388152107855717],
              [-70.515584083635261, -33.384401731036441],
              [-70.510397655506054, -33.384401731036441],
              [-70.507804441441465, -33.388152107855717],
              [-70.510397655506054, -33.391902322886416],
              [-70.515584083635261, -33.391902322886416],
              [-70.518177297699864, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1043,
        left: -7850047.5884497343,
        bottom: -3946438.7241678787,
        right: -7848892.8873729762,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.380651192437711],
              [-70.515584083635261, -33.3769004920686],
              [-70.510397655506054, -33.3769004920686],
              [-70.507804441441465, -33.380651192437711],
              [-70.510397655506054, -33.384401731036441],
              [-70.515584083635261, -33.384401731036441],
              [-70.518177297699864, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1044,
        left: -7850047.5884497343,
        bottom: -3945438.7237016284,
        right: -7848892.8873729762,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.373149629938226],
              [-70.515584083635261, -33.369398606055647],
              [-70.510397655506054, -33.369398606055647],
              [-70.507804441441465, -33.373149629938226],
              [-70.510397655506054, -33.3769004920686],
              [-70.515584083635261, -33.3769004920686],
              [-70.518177297699864, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1045,
        left: -7850047.5884497343,
        bottom: -3944438.723235378,
        right: -7848892.8873729762,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.365647420430008],
              [-70.515584083635261, -33.36189607307039],
              [-70.510397655506054, -33.36189607307039],
              [-70.507804441441465, -33.365647420430008],
              [-70.510397655506054, -33.369398606055647],
              [-70.515584083635261, -33.369398606055647],
              [-70.518177297699864, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1046,
        left: -7850047.5884497343,
        bottom: -3943438.7227691277,
        right: -7848892.8873729762,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.358144563985917],
              [-70.515584083635261, -33.35439289318569],
              [-70.510397655506054, -33.35439289318569],
              [-70.507804441441465, -33.358144563985917],
              [-70.510397655506054, -33.36189607307039],
              [-70.515584083635261, -33.36189607307039],
              [-70.518177297699864, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1047,
        left: -7850047.5884497343,
        bottom: -3942438.7223028769,
        right: -7848892.8873729762,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.350641060678818],
              [-70.515584083635261, -33.346889066474425],
              [-70.510397655506054, -33.346889066474425],
              [-70.507804441441465, -33.350641060678818],
              [-70.510397655506054, -33.35439289318569],
              [-70.515584083635261, -33.35439289318569],
              [-70.518177297699864, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1048,
        left: -7850047.5884497343,
        bottom: -3941438.721836627,
        right: -7848892.8873729762,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.343136910581649],
              [-70.515584083635261, -33.339384593009591],
              [-70.510397655506054, -33.339384593009591],
              [-70.507804441441465, -33.343136910581649],
              [-70.510397655506054, -33.346889066474425],
              [-70.515584083635261, -33.346889066474425],
              [-70.518177297699864, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1049,
        left: -7850047.5884497343,
        bottom: -3940438.7213703771,
        right: -7848892.8873729762,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.335632113767389],
              [-70.515584083635261, -33.331879472864181],
              [-70.510397655506054, -33.331879472864181],
              [-70.507804441441465, -33.335632113767389],
              [-70.510397655506054, -33.339384593009591],
              [-70.515584083635261, -33.339384593009591],
              [-70.518177297699864, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1050,
        left: -7850047.5884497343,
        bottom: -3939438.7209041263,
        right: -7848892.8873729762,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.328126670309082],
              [-70.515584083635261, -33.324373706111238],
              [-70.510397655506054, -33.324373706111238],
              [-70.507804441441465, -33.328126670309082],
              [-70.510397655506054, -33.331879472864181],
              [-70.515584083635261, -33.331879472864181],
              [-70.518177297699864, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1051,
        left: -7850047.5884497343,
        bottom: -3938438.7204378759,
        right: -7848892.8873729762,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.320620580279794],
              [-70.515584083635261, -33.3168672928239],
              [-70.510397655506054, -33.3168672928239],
              [-70.507804441441465, -33.320620580279794],
              [-70.510397655506054, -33.324373706111238],
              [-70.515584083635261, -33.324373706111238],
              [-70.518177297699864, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1052,
        left: -7850047.5884497343,
        bottom: -3937438.7199716261,
        right: -7848892.8873729762,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.313113843752674],
              [-70.515584083635261, -33.309360233075296],
              [-70.510397655506054, -33.309360233075296],
              [-70.507804441441465, -33.313113843752674],
              [-70.510397655506054, -33.3168672928239],
              [-70.515584083635261, -33.3168672928239],
              [-70.518177297699864, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1053,
        left: -7850047.5884497343,
        bottom: -3936438.7195053762,
        right: -7848892.8873729762,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.305606460800909],
              [-70.515584083635261, -33.30185252693866],
              [-70.510397655506054, -33.30185252693866],
              [-70.507804441441465, -33.305606460800909],
              [-70.510397655506054, -33.309360233075296],
              [-70.515584083635261, -33.309360233075296],
              [-70.518177297699864, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1054,
        left: -7850047.5884497343,
        bottom: -3935438.7190391254,
        right: -7848892.8873729762,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.298098431497721],
              [-70.515584083635261, -33.294344174487243],
              [-70.510397655506054, -33.294344174487243],
              [-70.507804441441465, -33.298098431497721],
              [-70.510397655506054, -33.30185252693866],
              [-70.515584083635261, -33.30185252693866],
              [-70.518177297699864, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1055,
        left: -7850047.5884497343,
        bottom: -3934438.718572875,
        right: -7848892.8873729762,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.290589755916407],
              [-70.515584083635261, -33.286835175794366],
              [-70.510397655506054, -33.286835175794366],
              [-70.507804441441465, -33.290589755916407],
              [-70.510397655506054, -33.294344174487243],
              [-70.515584083635261, -33.294344174487243],
              [-70.518177297699864, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1056,
        left: -7850047.5884497343,
        bottom: -3933438.7181066247,
        right: -7848892.8873729762,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.518177297699864, -33.283080434130305],
              [-70.515584083635261, -33.279325530933384],
              [-70.510397655506054, -33.279325530933384],
              [-70.507804441441465, -33.283080434130305],
              [-70.510397655506054, -33.286835175794366],
              [-70.515584083635261, -33.286835175794366],
              [-70.518177297699864, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1057,
        left: -7849181.5626421655,
        bottom: -3980938.7402535151,
        right: -7848026.8615654064,
        top: -3979938.7397872647,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.639058347612803],
              [-70.507804441441465, -33.63531883056551],
              [-70.502618013312244, -33.63531883056551],
              [-70.500024799247655, -33.639058347612803],
              [-70.502618013312244, -33.642797702269725],
              [-70.507804441441465, -33.642797702269725],
              [-70.510397655506054, -33.639058347612803],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1058,
        left: -7849181.5626421655,
        bottom: -3979938.7397872647,
        right: -7848026.8615654064,
        top: -3978938.7393210144,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.631579151136748],
              [-70.507804441441465, -33.627839309335386],
              [-70.502618013312244, -33.627839309335386],
              [-70.500024799247655, -33.631579151136748],
              [-70.502618013312244, -33.63531883056551],
              [-70.507804441441465, -33.63531883056551],
              [-70.510397655506054, -33.631579151136748],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1059,
        left: -7849181.5626421655,
        bottom: -3978938.7393210144,
        right: -7848026.8615654064,
        top: -3977938.738854764,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.624099305170297],
              [-70.507804441441465, -33.620359138650379],
              [-70.502618013312244, -33.620359138650379],
              [-70.500024799247655, -33.624099305170297],
              [-70.502618013312244, -33.627839309335386],
              [-70.507804441441465, -33.627839309335386],
              [-70.510397655506054, -33.624099305170297],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1060,
        left: -7849181.5626421655,
        bottom: -3977938.738854764,
        right: -7848026.8615654064,
        top: -3976938.7383885137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.616618809784534],
              [-70.507804441441465, -33.612878318581629],
              [-70.502618013312244, -33.612878318581629],
              [-70.500024799247655, -33.616618809784534],
              [-70.502618013312244, -33.620359138650379],
              [-70.507804441441465, -33.620359138650379],
              [-70.510397655506054, -33.616618809784534],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1061,
        left: -7849181.5626421655,
        bottom: -3976938.7383885137,
        right: -7848026.8615654064,
        top: -3975938.7379222633,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.609137665050582],
              [-70.507804441441465, -33.605396849200282],
              [-70.502618013312244, -33.605396849200282],
              [-70.500024799247655, -33.609137665050582],
              [-70.502618013312244, -33.612878318581629],
              [-70.507804441441465, -33.612878318581629],
              [-70.510397655506054, -33.609137665050582],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1062,
        left: -7849181.5626421655,
        bottom: -3975938.7379222633,
        right: -7848026.8615654064,
        top: -3974938.737456013,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.601655871039647],
              [-70.507804441441465, -33.597914730577571],
              [-70.502618013312244, -33.597914730577571],
              [-70.500024799247655, -33.601655871039647],
              [-70.502618013312244, -33.605396849200282],
              [-70.507804441441465, -33.605396849200282],
              [-70.510397655506054, -33.601655871039647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1063,
        left: -7849181.5626421655,
        bottom: -3974938.737456013,
        right: -7848026.8615654064,
        top: -3973938.7369897626,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.594173427822959],
              [-70.507804441441465, -33.590431962784734],
              [-70.502618013312244, -33.590431962784734],
              [-70.500024799247655, -33.594173427822959],
              [-70.502618013312244, -33.597914730577571],
              [-70.507804441441465, -33.597914730577571],
              [-70.510397655506054, -33.594173427822959],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1064,
        left: -7849181.5626421655,
        bottom: -3973938.7369897626,
        right: -7848026.8615654064,
        top: -3972938.7365235123,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.586690335471808],
              [-70.507804441441465, -33.582948545893096],
              [-70.502618013312244, -33.582948545893096],
              [-70.500024799247655, -33.586690335471808],
              [-70.502618013312244, -33.590431962784734],
              [-70.507804441441465, -33.590431962784734],
              [-70.510397655506054, -33.586690335471808],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1065,
        left: -7849181.5626421655,
        bottom: -3972938.7365235123,
        right: -7848026.8615654064,
        top: -3971938.7360572619,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.579206594057531],
              [-70.507804441441465, -33.575464479974038],
              [-70.502618013312244, -33.575464479974038],
              [-70.500024799247655, -33.579206594057531],
              [-70.502618013312244, -33.582948545893096],
              [-70.507804441441465, -33.582948545893096],
              [-70.510397655506054, -33.579206594057531],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1066,
        left: -7849181.5626421655,
        bottom: -3971938.7360572619,
        right: -7848026.8615654064,
        top: -3970938.7355910116,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.571722203651518],
              [-70.507804441441465, -33.56797976509894],
              [-70.502618013312244, -33.56797976509894],
              [-70.500024799247655, -33.571722203651518],
              [-70.502618013312244, -33.575464479974038],
              [-70.507804441441465, -33.575464479974038],
              [-70.510397655506054, -33.571722203651518],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1067,
        left: -7849181.5626421655,
        bottom: -3970938.7355910116,
        right: -7848026.8615654064,
        top: -3969938.7351247617,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.56423716432522],
              [-70.507804441441465, -33.560494401339298],
              [-70.502618013312244, -33.560494401339298],
              [-70.500024799247655, -33.56423716432522],
              [-70.502618013312244, -33.56797976509894],
              [-70.507804441441465, -33.56797976509894],
              [-70.510397655506054, -33.56423716432522],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1068,
        left: -7849181.5626421655,
        bottom: -3969938.7351247617,
        right: -7848026.8615654064,
        top: -3968938.7346585114,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.556751476150104],
              [-70.507804441441465, -33.553008388766614],
              [-70.502618013312244, -33.553008388766614],
              [-70.500024799247655, -33.556751476150104],
              [-70.502618013312244, -33.560494401339298],
              [-70.507804441441465, -33.560494401339298],
              [-70.510397655506054, -33.556751476150104],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1069,
        left: -7849181.5626421655,
        bottom: -3968938.7346585114,
        right: -7848026.8615654064,
        top: -3967938.734192261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.549265139197736],
              [-70.507804441441465, -33.54552172745246],
              [-70.502618013312244, -33.54552172745246],
              [-70.500024799247655, -33.549265139197736],
              [-70.502618013312244, -33.553008388766614],
              [-70.507804441441465, -33.553008388766614],
              [-70.510397655506054, -33.549265139197736],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1070,
        left: -7849181.5626421655,
        bottom: -3967938.734192261,
        right: -7848026.8615654064,
        top: -3966938.7337260107,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.541778153539695],
              [-70.507804441441465, -33.538034417468438],
              [-70.502618013312244, -33.538034417468438],
              [-70.500024799247655, -33.541778153539695],
              [-70.502618013312244, -33.54552172745246],
              [-70.507804441441465, -33.54552172745246],
              [-70.510397655506054, -33.541778153539695],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1071,
        left: -7849181.5626421655,
        bottom: -3966938.7337260107,
        right: -7848026.8615654064,
        top: -3965938.7332597603,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.534290519247627],
              [-70.507804441441465, -33.530546458886235],
              [-70.502618013312244, -33.530546458886235],
              [-70.500024799247655, -33.534290519247627],
              [-70.502618013312244, -33.538034417468438],
              [-70.507804441441465, -33.538034417468438],
              [-70.510397655506054, -33.534290519247627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1072,
        left: -7849181.5626421655,
        bottom: -3965938.7332597603,
        right: -7848026.8615654064,
        top: -3964938.7327935095,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.526802236393216],
              [-70.507804441441465, -33.523057851777558],
              [-70.502618013312244, -33.523057851777558],
              [-70.500024799247655, -33.526802236393216],
              [-70.502618013312244, -33.530546458886235],
              [-70.507804441441465, -33.530546458886235],
              [-70.510397655506054, -33.526802236393216],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1073,
        left: -7849181.5626421655,
        bottom: -3964938.7327935095,
        right: -7848026.8615654064,
        top: -3963938.7323272596,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.519313305048215],
              [-70.507804441441465, -33.515568596214173],
              [-70.502618013312244, -33.515568596214173],
              [-70.500024799247655, -33.519313305048215],
              [-70.502618013312244, -33.523057851777558],
              [-70.507804441441465, -33.523057851777558],
              [-70.510397655506054, -33.519313305048215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1074,
        left: -7849181.5626421655,
        bottom: -3963938.7323272596,
        right: -7848026.8615654064,
        top: -3962938.7318610097,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.511823725284408],
              [-70.507804441441465, -33.508078692267915],
              [-70.502618013312244, -33.508078692267915],
              [-70.500024799247655, -33.511823725284408],
              [-70.502618013312244, -33.515568596214173],
              [-70.507804441441465, -33.515568596214173],
              [-70.510397655506054, -33.511823725284408],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1075,
        left: -7849181.5626421655,
        bottom: -3962938.7318610097,
        right: -7848026.8615654064,
        top: -3961938.7313947589,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.504333497173661],
              [-70.507804441441465, -33.500588140010635],
              [-70.502618013312244, -33.500588140010635],
              [-70.500024799247655, -33.504333497173661],
              [-70.502618013312244, -33.508078692267915],
              [-70.507804441441465, -33.508078692267915],
              [-70.510397655506054, -33.504333497173661],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1076,
        left: -7849181.5626421655,
        bottom: -3961938.7313947589,
        right: -7848026.8615654064,
        top: -3960938.7309285086,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.496842620787838],
              [-70.507804441441465, -33.493096939514267],
              [-70.502618013312244, -33.493096939514267],
              [-70.500024799247655, -33.496842620787838],
              [-70.502618013312244, -33.500588140010635],
              [-70.507804441441465, -33.500588140010635],
              [-70.510397655506054, -33.496842620787838],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1077,
        left: -7849181.5626421655,
        bottom: -3960938.7309285086,
        right: -7848026.8615654064,
        top: -3959938.7304622582,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.489351096198902],
              [-70.507804441441465, -33.485605090850768],
              [-70.502618013312244, -33.485605090850768],
              [-70.500024799247655, -33.489351096198902],
              [-70.502618013312244, -33.493096939514267],
              [-70.507804441441465, -33.493096939514267],
              [-70.510397655506054, -33.489351096198902],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1078,
        left: -7849181.5626421655,
        bottom: -3959938.7304622582,
        right: -7848026.8615654064,
        top: -3958938.7299960079,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.48185892347886],
              [-70.507804441441465, -33.478112594092174],
              [-70.502618013312244, -33.478112594092174],
              [-70.500024799247655, -33.48185892347886],
              [-70.502618013312244, -33.485605090850768],
              [-70.507804441441465, -33.485605090850768],
              [-70.510397655506054, -33.48185892347886],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1079,
        left: -7849181.5626421655,
        bottom: -3958938.7299960079,
        right: -7848026.8615654064,
        top: -3957938.7295297575,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.474366102699733],
              [-70.507804441441465, -33.470619449310547],
              [-70.502618013312244, -33.470619449310547],
              [-70.500024799247655, -33.474366102699733],
              [-70.502618013312244, -33.478112594092174],
              [-70.507804441441465, -33.478112594092174],
              [-70.510397655506054, -33.474366102699733],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1080,
        left: -7849181.5626421655,
        bottom: -3957938.7295297575,
        right: -7848026.8615654064,
        top: -3956938.7290635072,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.466872633933626],
              [-70.507804441441465, -33.463125656578008],
              [-70.502618013312244, -33.463125656578008],
              [-70.500024799247655, -33.466872633933626],
              [-70.502618013312244, -33.470619449310547],
              [-70.507804441441465, -33.470619449310547],
              [-70.510397655506054, -33.466872633933626],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1081,
        left: -7849181.5626421655,
        bottom: -3956938.7290635072,
        right: -7848026.8615654064,
        top: -3955938.7285972568,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.459378517252702],
              [-70.507804441441465, -33.45563121596674],
              [-70.502618013312244, -33.45563121596674],
              [-70.500024799247655, -33.459378517252702],
              [-70.502618013312244, -33.463125656578008],
              [-70.507804441441465, -33.463125656578008],
              [-70.510397655506054, -33.459378517252702],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1082,
        left: -7849181.5626421655,
        bottom: -3955938.7285972568,
        right: -7848026.8615654064,
        top: -3954938.7281310065,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.451883752729138],
              [-70.507804441441465, -33.448136127548949],
              [-70.502618013312244, -33.448136127548949],
              [-70.500024799247655, -33.451883752729138],
              [-70.502618013312244, -33.45563121596674],
              [-70.507804441441465, -33.45563121596674],
              [-70.510397655506054, -33.451883752729138],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1083,
        left: -7849181.5626421655,
        bottom: -3954938.7281310065,
        right: -7848026.8615654064,
        top: -3953938.7276647561,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.444388340435196],
              [-70.507804441441465, -33.440640391396926],
              [-70.502618013312244, -33.440640391396926],
              [-70.500024799247655, -33.444388340435196],
              [-70.502618013312244, -33.448136127548949],
              [-70.507804441441465, -33.448136127548949],
              [-70.510397655506054, -33.444388340435196],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1084,
        left: -7849181.5626421655,
        bottom: -3953938.7276647561,
        right: -7848026.8615654064,
        top: -3952938.7271985058,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.436892280443168],
              [-70.507804441441465, -33.433144007582989],
              [-70.502618013312244, -33.433144007582989],
              [-70.500024799247655, -33.436892280443168],
              [-70.502618013312244, -33.440640391396926],
              [-70.507804441441465, -33.440640391396926],
              [-70.510397655506054, -33.436892280443168],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1085,
        left: -7849181.5626421655,
        bottom: -3952938.7271985058,
        right: -7848026.8615654064,
        top: -3951938.7267322554,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.429395572825413],
              [-70.507804441441465, -33.425646976179515],
              [-70.502618013312244, -33.425646976179515],
              [-70.500024799247655, -33.429395572825413],
              [-70.502618013312244, -33.433144007582989],
              [-70.507804441441465, -33.433144007582989],
              [-70.510397655506054, -33.429395572825413],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1086,
        left: -7849181.5626421655,
        bottom: -3951938.7267322554,
        right: -7848026.8615654064,
        top: -3950938.7262660051,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.421898217654316],
              [-70.507804441441465, -33.418149297258921],
              [-70.502618013312244, -33.418149297258921],
              [-70.500024799247655, -33.421898217654316],
              [-70.502618013312244, -33.425646976179515],
              [-70.507804441441465, -33.425646976179515],
              [-70.510397655506054, -33.421898217654316],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1087,
        left: -7849181.5626421655,
        bottom: -3950938.7262660051,
        right: -7848026.8615654064,
        top: -3949938.7257997552,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.414400215002352],
              [-70.507804441441465, -33.410650970893691],
              [-70.502618013312244, -33.410650970893691],
              [-70.500024799247655, -33.414400215002352],
              [-70.502618013312244, -33.418149297258921],
              [-70.507804441441465, -33.418149297258921],
              [-70.510397655506054, -33.414400215002352],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1088,
        left: -7849181.5626421655,
        bottom: -3949938.7257997552,
        right: -7848026.8615654064,
        top: -3948938.7253335048,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.406901564941997],
              [-70.507804441441465, -33.40315199715635],
              [-70.502618013312244, -33.40315199715635],
              [-70.500024799247655, -33.406901564941997],
              [-70.502618013312244, -33.410650970893691],
              [-70.507804441441465, -33.410650970893691],
              [-70.510397655506054, -33.406901564941997],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1089,
        left: -7849181.5626421655,
        bottom: -3948938.7253335048,
        right: -7848026.8615654064,
        top: -3947938.724867254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.399402267545824],
              [-70.507804441441465, -33.395652376119486],
              [-70.502618013312244, -33.395652376119486],
              [-70.500024799247655, -33.399402267545824],
              [-70.502618013312244, -33.40315199715635],
              [-70.507804441441465, -33.40315199715635],
              [-70.510397655506054, -33.399402267545824],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1090,
        left: -7849181.5626421655,
        bottom: -3947938.724867254,
        right: -7848026.8615654064,
        top: -3946938.7244010041,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.391902322886416],
              [-70.507804441441465, -33.388152107855717],
              [-70.502618013312244, -33.388152107855717],
              [-70.500024799247655, -33.391902322886416],
              [-70.502618013312244, -33.395652376119486],
              [-70.507804441441465, -33.395652376119486],
              [-70.510397655506054, -33.391902322886416],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1091,
        left: -7849181.5626421655,
        bottom: -3946938.7244010041,
        right: -7848026.8615654064,
        top: -3945938.7239347543,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.384401731036441],
              [-70.507804441441465, -33.380651192437711],
              [-70.502618013312244, -33.380651192437711],
              [-70.500024799247655, -33.384401731036441],
              [-70.502618013312244, -33.388152107855717],
              [-70.507804441441465, -33.388152107855717],
              [-70.510397655506054, -33.384401731036441],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1092,
        left: -7849181.5626421655,
        bottom: -3945938.7239347543,
        right: -7848026.8615654064,
        top: -3944938.7234685034,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.3769004920686],
              [-70.507804441441465, -33.373149629938226],
              [-70.502618013312244, -33.373149629938226],
              [-70.500024799247655, -33.3769004920686],
              [-70.502618013312244, -33.380651192437711],
              [-70.507804441441465, -33.380651192437711],
              [-70.510397655506054, -33.3769004920686],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1093,
        left: -7849181.5626421655,
        bottom: -3944938.7234685034,
        right: -7848026.8615654064,
        top: -3943938.7230022531,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.369398606055647],
              [-70.507804441441465, -33.365647420430008],
              [-70.502618013312244, -33.365647420430008],
              [-70.500024799247655, -33.369398606055647],
              [-70.502618013312244, -33.373149629938226],
              [-70.507804441441465, -33.373149629938226],
              [-70.510397655506054, -33.369398606055647],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1094,
        left: -7849181.5626421655,
        bottom: -3943938.7230022531,
        right: -7848026.8615654064,
        top: -3942938.7225360028,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.36189607307039],
              [-70.507804441441465, -33.358144563985917],
              [-70.502618013312244, -33.358144563985917],
              [-70.500024799247655, -33.36189607307039],
              [-70.502618013312244, -33.365647420430008],
              [-70.507804441441465, -33.365647420430008],
              [-70.510397655506054, -33.36189607307039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1095,
        left: -7849181.5626421655,
        bottom: -3942938.7225360028,
        right: -7848026.8615654064,
        top: -3941938.7220697524,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.35439289318569],
              [-70.507804441441465, -33.350641060678818],
              [-70.502618013312244, -33.350641060678818],
              [-70.500024799247655, -33.35439289318569],
              [-70.502618013312244, -33.358144563985917],
              [-70.507804441441465, -33.358144563985917],
              [-70.510397655506054, -33.35439289318569],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1096,
        left: -7849181.5626421655,
        bottom: -3941938.7220697524,
        right: -7848026.8615654064,
        top: -3940938.7216035021,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.346889066474425],
              [-70.507804441441465, -33.343136910581649],
              [-70.502618013312244, -33.343136910581649],
              [-70.500024799247655, -33.346889066474425],
              [-70.502618013312244, -33.350641060678818],
              [-70.507804441441465, -33.350641060678818],
              [-70.510397655506054, -33.346889066474425],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1097,
        left: -7849181.5626421655,
        bottom: -3940938.7216035021,
        right: -7848026.8615654064,
        top: -3939938.7211372517,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.339384593009591],
              [-70.507804441441465, -33.335632113767389],
              [-70.502618013312244, -33.335632113767389],
              [-70.500024799247655, -33.339384593009591],
              [-70.502618013312244, -33.343136910581649],
              [-70.507804441441465, -33.343136910581649],
              [-70.510397655506054, -33.339384593009591],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1098,
        left: -7849181.5626421655,
        bottom: -3939938.7211372517,
        right: -7848026.8615654064,
        top: -3938938.7206710014,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.331879472864181],
              [-70.507804441441465, -33.328126670309082],
              [-70.502618013312244, -33.328126670309082],
              [-70.500024799247655, -33.331879472864181],
              [-70.502618013312244, -33.335632113767389],
              [-70.507804441441465, -33.335632113767389],
              [-70.510397655506054, -33.331879472864181],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1099,
        left: -7849181.5626421655,
        bottom: -3938938.7206710014,
        right: -7848026.8615654064,
        top: -3937938.720204751,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.324373706111238],
              [-70.507804441441465, -33.320620580279794],
              [-70.502618013312244, -33.320620580279794],
              [-70.500024799247655, -33.324373706111238],
              [-70.502618013312244, -33.328126670309082],
              [-70.507804441441465, -33.328126670309082],
              [-70.510397655506054, -33.324373706111238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1100,
        left: -7849181.5626421655,
        bottom: -3937938.720204751,
        right: -7848026.8615654064,
        top: -3936938.7197385007,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.3168672928239],
              [-70.507804441441465, -33.313113843752674],
              [-70.502618013312244, -33.313113843752674],
              [-70.500024799247655, -33.3168672928239],
              [-70.502618013312244, -33.320620580279794],
              [-70.507804441441465, -33.320620580279794],
              [-70.510397655506054, -33.3168672928239],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1101,
        left: -7849181.5626421655,
        bottom: -3936938.7197385007,
        right: -7848026.8615654064,
        top: -3935938.7192722503,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.309360233075296],
              [-70.507804441441465, -33.305606460800909],
              [-70.502618013312244, -33.305606460800909],
              [-70.500024799247655, -33.309360233075296],
              [-70.502618013312244, -33.313113843752674],
              [-70.507804441441465, -33.313113843752674],
              [-70.510397655506054, -33.309360233075296],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1102,
        left: -7849181.5626421655,
        bottom: -3935938.7192722503,
        right: -7848026.8615654064,
        top: -3934938.718806,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.30185252693866],
              [-70.507804441441465, -33.298098431497721],
              [-70.502618013312244, -33.298098431497721],
              [-70.500024799247655, -33.30185252693866],
              [-70.502618013312244, -33.305606460800909],
              [-70.507804441441465, -33.305606460800909],
              [-70.510397655506054, -33.30185252693866],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1103,
        left: -7849181.5626421655,
        bottom: -3934938.718806,
        right: -7848026.8615654064,
        top: -3933938.7183397496,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.294344174487243],
              [-70.507804441441465, -33.290589755916407],
              [-70.502618013312244, -33.290589755916407],
              [-70.500024799247655, -33.294344174487243],
              [-70.502618013312244, -33.298098431497721],
              [-70.507804441441465, -33.298098431497721],
              [-70.510397655506054, -33.294344174487243],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1104,
        left: -7849181.5626421655,
        bottom: -3933938.7183397496,
        right: -7848026.8615654064,
        top: -3932938.7178734988,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.510397655506054, -33.286835175794366],
              [-70.507804441441465, -33.283080434130305],
              [-70.502618013312244, -33.283080434130305],
              [-70.500024799247655, -33.286835175794366],
              [-70.502618013312244, -33.290589755916407],
              [-70.507804441441465, -33.290589755916407],
              [-70.510397655506054, -33.286835175794366],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1105,
        left: -7848315.5368345967,
        bottom: -3980438.7400203901,
        right: -7847160.8357578376,
        top: -3979438.7395541398,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.63531883056551],
              [-70.500024799247655, -33.631579151136748],
              [-70.494838371118433, -33.631579151136748],
              [-70.49224515705383, -33.63531883056551],
              [-70.494838371118433, -33.639058347612803],
              [-70.500024799247655, -33.639058347612803],
              [-70.502618013312244, -33.63531883056551],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1106,
        left: -7848315.5368345967,
        bottom: -3979438.7395541398,
        right: -7847160.8357578376,
        top: -3978438.7390878894,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.627839309335386],
              [-70.500024799247655, -33.624099305170297],
              [-70.494838371118433, -33.624099305170297],
              [-70.49224515705383, -33.627839309335386],
              [-70.494838371118433, -33.631579151136748],
              [-70.500024799247655, -33.631579151136748],
              [-70.502618013312244, -33.627839309335386],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1107,
        left: -7848315.5368345967,
        bottom: -3978438.7390878894,
        right: -7847160.8357578376,
        top: -3977438.7386216391,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.620359138650379],
              [-70.500024799247655, -33.616618809784534],
              [-70.494838371118433, -33.616618809784534],
              [-70.49224515705383, -33.620359138650379],
              [-70.494838371118433, -33.624099305170297],
              [-70.500024799247655, -33.624099305170297],
              [-70.502618013312244, -33.620359138650379],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1108,
        left: -7848315.5368345967,
        bottom: -3977438.7386216391,
        right: -7847160.8357578376,
        top: -3976438.7381553887,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.612878318581629],
              [-70.500024799247655, -33.609137665050582],
              [-70.494838371118433, -33.609137665050582],
              [-70.49224515705383, -33.612878318581629],
              [-70.494838371118433, -33.616618809784534],
              [-70.500024799247655, -33.616618809784534],
              [-70.502618013312244, -33.612878318581629],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1109,
        left: -7848315.5368345967,
        bottom: -3976438.7381553887,
        right: -7847160.8357578376,
        top: -3975438.7376891384,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.605396849200282],
              [-70.500024799247655, -33.601655871039647],
              [-70.494838371118433, -33.601655871039647],
              [-70.49224515705383, -33.605396849200282],
              [-70.494838371118433, -33.609137665050582],
              [-70.500024799247655, -33.609137665050582],
              [-70.502618013312244, -33.605396849200282],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1110,
        left: -7848315.5368345967,
        bottom: -3975438.7376891384,
        right: -7847160.8357578376,
        top: -3974438.7372228876,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.597914730577571],
              [-70.500024799247655, -33.594173427822959],
              [-70.494838371118433, -33.594173427822959],
              [-70.49224515705383, -33.597914730577571],
              [-70.494838371118433, -33.601655871039647],
              [-70.500024799247655, -33.601655871039647],
              [-70.502618013312244, -33.597914730577571],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1111,
        left: -7848315.5368345967,
        bottom: -3974438.7372228876,
        right: -7847160.8357578376,
        top: -3973438.7367566377,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.590431962784734],
              [-70.500024799247655, -33.586690335471808],
              [-70.494838371118433, -33.586690335471808],
              [-70.49224515705383, -33.590431962784734],
              [-70.494838371118433, -33.594173427822959],
              [-70.500024799247655, -33.594173427822959],
              [-70.502618013312244, -33.590431962784734],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1112,
        left: -7848315.5368345967,
        bottom: -3973438.7367566377,
        right: -7847160.8357578376,
        top: -3972438.7362903878,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.582948545893096],
              [-70.500024799247655, -33.579206594057531],
              [-70.494838371118433, -33.579206594057531],
              [-70.49224515705383, -33.582948545893096],
              [-70.494838371118433, -33.586690335471808],
              [-70.500024799247655, -33.586690335471808],
              [-70.502618013312244, -33.582948545893096],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1113,
        left: -7848315.5368345967,
        bottom: -3972438.7362903878,
        right: -7847160.8357578376,
        top: -3971438.735824137,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.575464479974038],
              [-70.500024799247655, -33.571722203651518],
              [-70.494838371118433, -33.571722203651518],
              [-70.49224515705383, -33.575464479974038],
              [-70.494838371118433, -33.579206594057531],
              [-70.500024799247655, -33.579206594057531],
              [-70.502618013312244, -33.575464479974038],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1114,
        left: -7848315.5368345967,
        bottom: -3971438.735824137,
        right: -7847160.8357578376,
        top: -3970438.7353578866,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.56797976509894],
              [-70.500024799247655, -33.56423716432522],
              [-70.494838371118433, -33.56423716432522],
              [-70.49224515705383, -33.56797976509894],
              [-70.494838371118433, -33.571722203651518],
              [-70.500024799247655, -33.571722203651518],
              [-70.502618013312244, -33.56797976509894],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1115,
        left: -7848315.5368345967,
        bottom: -3970438.7353578866,
        right: -7847160.8357578376,
        top: -3969438.7348916363,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.560494401339298],
              [-70.500024799247655, -33.556751476150104],
              [-70.494838371118433, -33.556751476150104],
              [-70.49224515705383, -33.560494401339298],
              [-70.494838371118433, -33.56423716432522],
              [-70.500024799247655, -33.56423716432522],
              [-70.502618013312244, -33.560494401339298],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1116,
        left: -7848315.5368345967,
        bottom: -3969438.7348916363,
        right: -7847160.8357578376,
        top: -3968438.7344253859,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.553008388766614],
              [-70.500024799247655, -33.549265139197736],
              [-70.494838371118433, -33.549265139197736],
              [-70.49224515705383, -33.553008388766614],
              [-70.494838371118433, -33.556751476150104],
              [-70.500024799247655, -33.556751476150104],
              [-70.502618013312244, -33.553008388766614],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1117,
        left: -7848315.5368345967,
        bottom: -3968438.7344253859,
        right: -7847160.8357578376,
        top: -3967438.7339591356,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.54552172745246],
              [-70.500024799247655, -33.541778153539695],
              [-70.494838371118433, -33.541778153539695],
              [-70.49224515705383, -33.54552172745246],
              [-70.494838371118433, -33.549265139197736],
              [-70.500024799247655, -33.549265139197736],
              [-70.502618013312244, -33.54552172745246],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1118,
        left: -7848315.5368345967,
        bottom: -3967438.7339591356,
        right: -7847160.8357578376,
        top: -3966438.7334928853,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.538034417468438],
              [-70.500024799247655, -33.534290519247627],
              [-70.494838371118433, -33.534290519247627],
              [-70.49224515705383, -33.538034417468438],
              [-70.494838371118433, -33.541778153539695],
              [-70.500024799247655, -33.541778153539695],
              [-70.502618013312244, -33.538034417468438],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1119,
        left: -7848315.5368345967,
        bottom: -3966438.7334928853,
        right: -7847160.8357578376,
        top: -3965438.7330266349,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.530546458886235],
              [-70.500024799247655, -33.526802236393216],
              [-70.494838371118433, -33.526802236393216],
              [-70.49224515705383, -33.530546458886235],
              [-70.494838371118433, -33.534290519247627],
              [-70.500024799247655, -33.534290519247627],
              [-70.502618013312244, -33.530546458886235],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1120,
        left: -7848315.5368345967,
        bottom: -3965438.7330266349,
        right: -7847160.8357578376,
        top: -3964438.7325603846,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.523057851777558],
              [-70.500024799247655, -33.519313305048215],
              [-70.494838371118433, -33.519313305048215],
              [-70.49224515705383, -33.523057851777558],
              [-70.494838371118433, -33.526802236393216],
              [-70.500024799247655, -33.526802236393216],
              [-70.502618013312244, -33.523057851777558],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1121,
        left: -7848315.5368345967,
        bottom: -3964438.7325603846,
        right: -7847160.8357578376,
        top: -3963438.7320941342,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.515568596214173],
              [-70.500024799247655, -33.511823725284408],
              [-70.494838371118433, -33.511823725284408],
              [-70.49224515705383, -33.515568596214173],
              [-70.494838371118433, -33.519313305048215],
              [-70.500024799247655, -33.519313305048215],
              [-70.502618013312244, -33.515568596214173],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1122,
        left: -7848315.5368345967,
        bottom: -3963438.7320941342,
        right: -7847160.8357578376,
        top: -3962438.7316278839,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.508078692267915],
              [-70.500024799247655, -33.504333497173661],
              [-70.494838371118433, -33.504333497173661],
              [-70.49224515705383, -33.508078692267915],
              [-70.494838371118433, -33.511823725284408],
              [-70.500024799247655, -33.511823725284408],
              [-70.502618013312244, -33.508078692267915],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1123,
        left: -7848315.5368345967,
        bottom: -3962438.7316278839,
        right: -7847160.8357578376,
        top: -3961438.7311616335,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.500588140010635],
              [-70.500024799247655, -33.496842620787838],
              [-70.494838371118433, -33.496842620787838],
              [-70.49224515705383, -33.500588140010635],
              [-70.494838371118433, -33.504333497173661],
              [-70.500024799247655, -33.504333497173661],
              [-70.502618013312244, -33.500588140010635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1124,
        left: -7848315.5368345967,
        bottom: -3961438.7311616335,
        right: -7847160.8357578376,
        top: -3960438.7306953832,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.493096939514267],
              [-70.500024799247655, -33.489351096198902],
              [-70.494838371118433, -33.489351096198902],
              [-70.49224515705383, -33.493096939514267],
              [-70.494838371118433, -33.496842620787838],
              [-70.500024799247655, -33.496842620787838],
              [-70.502618013312244, -33.493096939514267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1125,
        left: -7848315.5368345967,
        bottom: -3960438.7306953832,
        right: -7847160.8357578376,
        top: -3959438.7302291333,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.485605090850768],
              [-70.500024799247655, -33.48185892347886],
              [-70.494838371118433, -33.48185892347886],
              [-70.49224515705383, -33.485605090850768],
              [-70.494838371118433, -33.489351096198902],
              [-70.500024799247655, -33.489351096198902],
              [-70.502618013312244, -33.485605090850768],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1126,
        left: -7848315.5368345967,
        bottom: -3959438.7302291333,
        right: -7847160.8357578376,
        top: -3958438.7297628829,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.478112594092174],
              [-70.500024799247655, -33.474366102699733],
              [-70.494838371118433, -33.474366102699733],
              [-70.49224515705383, -33.478112594092174],
              [-70.494838371118433, -33.48185892347886],
              [-70.500024799247655, -33.48185892347886],
              [-70.502618013312244, -33.478112594092174],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1127,
        left: -7848315.5368345967,
        bottom: -3958438.7297628829,
        right: -7847160.8357578376,
        top: -3957438.7292966326,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.470619449310547],
              [-70.500024799247655, -33.466872633933626],
              [-70.494838371118433, -33.466872633933626],
              [-70.49224515705383, -33.470619449310547],
              [-70.494838371118433, -33.474366102699733],
              [-70.500024799247655, -33.474366102699733],
              [-70.502618013312244, -33.470619449310547],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1128,
        left: -7848315.5368345967,
        bottom: -3957438.7292966326,
        right: -7847160.8357578376,
        top: -3956438.7288303822,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.463125656578008],
              [-70.500024799247655, -33.459378517252702],
              [-70.494838371118433, -33.459378517252702],
              [-70.49224515705383, -33.463125656578008],
              [-70.494838371118433, -33.466872633933626],
              [-70.500024799247655, -33.466872633933626],
              [-70.502618013312244, -33.463125656578008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1129,
        left: -7848315.5368345967,
        bottom: -3956438.7288303822,
        right: -7847160.8357578376,
        top: -3955438.7283641323,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.45563121596674],
              [-70.500024799247655, -33.451883752729138],
              [-70.494838371118433, -33.451883752729138],
              [-70.49224515705383, -33.45563121596674],
              [-70.494838371118433, -33.459378517252702],
              [-70.500024799247655, -33.459378517252702],
              [-70.502618013312244, -33.45563121596674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1130,
        left: -7848315.5368345967,
        bottom: -3955438.7283641323,
        right: -7847160.8357578376,
        top: -3954438.7278978815,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.448136127548949],
              [-70.500024799247655, -33.444388340435196],
              [-70.494838371118433, -33.444388340435196],
              [-70.49224515705383, -33.448136127548949],
              [-70.494838371118433, -33.451883752729138],
              [-70.500024799247655, -33.451883752729138],
              [-70.502618013312244, -33.448136127548949],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1131,
        left: -7848315.5368345967,
        bottom: -3954438.7278978815,
        right: -7847160.8357578376,
        top: -3953438.7274316312,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.440640391396926],
              [-70.500024799247655, -33.436892280443168],
              [-70.494838371118433, -33.436892280443168],
              [-70.49224515705383, -33.440640391396926],
              [-70.494838371118433, -33.444388340435196],
              [-70.500024799247655, -33.444388340435196],
              [-70.502618013312244, -33.440640391396926],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1132,
        left: -7848315.5368345967,
        bottom: -3953438.7274316312,
        right: -7847160.8357578376,
        top: -3952438.7269653808,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.433144007582989],
              [-70.500024799247655, -33.429395572825413],
              [-70.494838371118433, -33.429395572825413],
              [-70.49224515705383, -33.433144007582989],
              [-70.494838371118433, -33.436892280443168],
              [-70.500024799247655, -33.436892280443168],
              [-70.502618013312244, -33.433144007582989],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1133,
        left: -7848315.5368345967,
        bottom: -3952438.7269653808,
        right: -7847160.8357578376,
        top: -3951438.7264991305,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.425646976179515],
              [-70.500024799247655, -33.421898217654316],
              [-70.494838371118433, -33.421898217654316],
              [-70.49224515705383, -33.425646976179515],
              [-70.494838371118433, -33.429395572825413],
              [-70.500024799247655, -33.429395572825413],
              [-70.502618013312244, -33.425646976179515],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1134,
        left: -7848315.5368345967,
        bottom: -3951438.7264991305,
        right: -7847160.8357578376,
        top: -3950438.7260328801,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.418149297258921],
              [-70.500024799247655, -33.414400215002352],
              [-70.494838371118433, -33.414400215002352],
              [-70.49224515705383, -33.418149297258921],
              [-70.494838371118433, -33.421898217654316],
              [-70.500024799247655, -33.421898217654316],
              [-70.502618013312244, -33.418149297258921],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1135,
        left: -7848315.5368345967,
        bottom: -3950438.7260328801,
        right: -7847160.8357578376,
        top: -3949438.7255666298,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.410650970893691],
              [-70.500024799247655, -33.406901564941997],
              [-70.494838371118433, -33.406901564941997],
              [-70.49224515705383, -33.410650970893691],
              [-70.494838371118433, -33.414400215002352],
              [-70.500024799247655, -33.414400215002352],
              [-70.502618013312244, -33.410650970893691],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1136,
        left: -7848315.5368345967,
        bottom: -3949438.7255666298,
        right: -7847160.8357578376,
        top: -3948438.7251003794,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.40315199715635],
              [-70.500024799247655, -33.399402267545824],
              [-70.494838371118433, -33.399402267545824],
              [-70.49224515705383, -33.40315199715635],
              [-70.494838371118433, -33.406901564941997],
              [-70.500024799247655, -33.406901564941997],
              [-70.502618013312244, -33.40315199715635],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1137,
        left: -7848315.5368345967,
        bottom: -3948438.7251003794,
        right: -7847160.8357578376,
        top: -3947438.7246341291,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.395652376119486],
              [-70.500024799247655, -33.391902322886416],
              [-70.494838371118433, -33.391902322886416],
              [-70.49224515705383, -33.395652376119486],
              [-70.494838371118433, -33.399402267545824],
              [-70.500024799247655, -33.399402267545824],
              [-70.502618013312244, -33.395652376119486],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1138,
        left: -7848315.5368345967,
        bottom: -3947438.7246341291,
        right: -7847160.8357578376,
        top: -3946438.7241678787,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.388152107855717],
              [-70.500024799247655, -33.384401731036441],
              [-70.494838371118433, -33.384401731036441],
              [-70.49224515705383, -33.388152107855717],
              [-70.494838371118433, -33.391902322886416],
              [-70.500024799247655, -33.391902322886416],
              [-70.502618013312244, -33.388152107855717],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1139,
        left: -7848315.5368345967,
        bottom: -3946438.7241678787,
        right: -7847160.8357578376,
        top: -3945438.7237016284,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.380651192437711],
              [-70.500024799247655, -33.3769004920686],
              [-70.494838371118433, -33.3769004920686],
              [-70.49224515705383, -33.380651192437711],
              [-70.494838371118433, -33.384401731036441],
              [-70.500024799247655, -33.384401731036441],
              [-70.502618013312244, -33.380651192437711],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1140,
        left: -7848315.5368345967,
        bottom: -3945438.7237016284,
        right: -7847160.8357578376,
        top: -3944438.723235378,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.373149629938226],
              [-70.500024799247655, -33.369398606055647],
              [-70.494838371118433, -33.369398606055647],
              [-70.49224515705383, -33.373149629938226],
              [-70.494838371118433, -33.3769004920686],
              [-70.500024799247655, -33.3769004920686],
              [-70.502618013312244, -33.373149629938226],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1141,
        left: -7848315.5368345967,
        bottom: -3944438.723235378,
        right: -7847160.8357578376,
        top: -3943438.7227691277,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.365647420430008],
              [-70.500024799247655, -33.36189607307039],
              [-70.494838371118433, -33.36189607307039],
              [-70.49224515705383, -33.365647420430008],
              [-70.494838371118433, -33.369398606055647],
              [-70.500024799247655, -33.369398606055647],
              [-70.502618013312244, -33.365647420430008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1142,
        left: -7848315.5368345967,
        bottom: -3943438.7227691277,
        right: -7847160.8357578376,
        top: -3942438.7223028769,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.358144563985917],
              [-70.500024799247655, -33.35439289318569],
              [-70.494838371118433, -33.35439289318569],
              [-70.49224515705383, -33.358144563985917],
              [-70.494838371118433, -33.36189607307039],
              [-70.500024799247655, -33.36189607307039],
              [-70.502618013312244, -33.358144563985917],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1143,
        left: -7848315.5368345967,
        bottom: -3942438.7223028769,
        right: -7847160.8357578376,
        top: -3941438.721836627,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.350641060678818],
              [-70.500024799247655, -33.346889066474425],
              [-70.494838371118433, -33.346889066474425],
              [-70.49224515705383, -33.350641060678818],
              [-70.494838371118433, -33.35439289318569],
              [-70.500024799247655, -33.35439289318569],
              [-70.502618013312244, -33.350641060678818],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1144,
        left: -7848315.5368345967,
        bottom: -3941438.721836627,
        right: -7847160.8357578376,
        top: -3940438.7213703771,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.343136910581649],
              [-70.500024799247655, -33.339384593009591],
              [-70.494838371118433, -33.339384593009591],
              [-70.49224515705383, -33.343136910581649],
              [-70.494838371118433, -33.346889066474425],
              [-70.500024799247655, -33.346889066474425],
              [-70.502618013312244, -33.343136910581649],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1145,
        left: -7848315.5368345967,
        bottom: -3940438.7213703771,
        right: -7847160.8357578376,
        top: -3939438.7209041263,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.335632113767389],
              [-70.500024799247655, -33.331879472864181],
              [-70.494838371118433, -33.331879472864181],
              [-70.49224515705383, -33.335632113767389],
              [-70.494838371118433, -33.339384593009591],
              [-70.500024799247655, -33.339384593009591],
              [-70.502618013312244, -33.335632113767389],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1146,
        left: -7848315.5368345967,
        bottom: -3939438.7209041263,
        right: -7847160.8357578376,
        top: -3938438.7204378759,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.328126670309082],
              [-70.500024799247655, -33.324373706111238],
              [-70.494838371118433, -33.324373706111238],
              [-70.49224515705383, -33.328126670309082],
              [-70.494838371118433, -33.331879472864181],
              [-70.500024799247655, -33.331879472864181],
              [-70.502618013312244, -33.328126670309082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1147,
        left: -7848315.5368345967,
        bottom: -3938438.7204378759,
        right: -7847160.8357578376,
        top: -3937438.7199716261,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.320620580279794],
              [-70.500024799247655, -33.3168672928239],
              [-70.494838371118433, -33.3168672928239],
              [-70.49224515705383, -33.320620580279794],
              [-70.494838371118433, -33.324373706111238],
              [-70.500024799247655, -33.324373706111238],
              [-70.502618013312244, -33.320620580279794],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1148,
        left: -7848315.5368345967,
        bottom: -3937438.7199716261,
        right: -7847160.8357578376,
        top: -3936438.7195053762,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.313113843752674],
              [-70.500024799247655, -33.309360233075296],
              [-70.494838371118433, -33.309360233075296],
              [-70.49224515705383, -33.313113843752674],
              [-70.494838371118433, -33.3168672928239],
              [-70.500024799247655, -33.3168672928239],
              [-70.502618013312244, -33.313113843752674],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1149,
        left: -7848315.5368345967,
        bottom: -3936438.7195053762,
        right: -7847160.8357578376,
        top: -3935438.7190391254,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.305606460800909],
              [-70.500024799247655, -33.30185252693866],
              [-70.494838371118433, -33.30185252693866],
              [-70.49224515705383, -33.305606460800909],
              [-70.494838371118433, -33.309360233075296],
              [-70.500024799247655, -33.309360233075296],
              [-70.502618013312244, -33.305606460800909],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1150,
        left: -7848315.5368345967,
        bottom: -3935438.7190391254,
        right: -7847160.8357578376,
        top: -3934438.718572875,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.298098431497721],
              [-70.500024799247655, -33.294344174487243],
              [-70.494838371118433, -33.294344174487243],
              [-70.49224515705383, -33.298098431497721],
              [-70.494838371118433, -33.30185252693866],
              [-70.500024799247655, -33.30185252693866],
              [-70.502618013312244, -33.298098431497721],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1151,
        left: -7848315.5368345967,
        bottom: -3934438.718572875,
        right: -7847160.8357578376,
        top: -3933438.7181066247,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.290589755916407],
              [-70.500024799247655, -33.286835175794366],
              [-70.494838371118433, -33.286835175794366],
              [-70.49224515705383, -33.290589755916407],
              [-70.494838371118433, -33.294344174487243],
              [-70.500024799247655, -33.294344174487243],
              [-70.502618013312244, -33.290589755916407],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1152,
        left: -7848315.5368345967,
        bottom: -3933438.7181066247,
        right: -7847160.8357578376,
        top: -3932438.7176403743,
        layer: "CapasPoligonos — temp2",
        path: "CapasPoligonos.gpkg|layername=temp2",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.502618013312244, -33.283080434130305],
              [-70.500024799247655, -33.279325530933384],
              [-70.494838371118433, -33.279325530933384],
              [-70.49224515705383, -33.283080434130305],
              [-70.494838371118433, -33.286835175794366],
              [-70.500024799247655, -33.286835175794366],
              [-70.502618013312244, -33.283080434130305],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1,
        left: -7868234.1304086847,
        bottom: -3980938.7402535151,
        right: -7867079.4293319257,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.639057545603663],
              [-70.865667959765034, -33.635318028521546],
              [-70.860481531635827, -33.635318028521546],
              [-70.857888317571224, -33.639057545603663],
              [-70.860481531635827, -33.642796900295409],
              [-70.865667959765034, -33.642796900295409],
              [-70.868261173829637, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 2,
        left: -7868234.1304086847,
        bottom: -3979938.7397872647,
        right: -7867079.4293319257,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.631584765688082],
              [-70.865667959765034, -33.627844924130478],
              [-70.860481531635827, -33.627844924130478],
              [-70.857888317571224, -33.631584765688082],
              [-70.860481531635827, -33.635324444873071],
              [-70.865667959765034, -33.635324444873071],
              [-70.868261173829637, -33.631584765688082],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 3,
        left: -7868234.1304086847,
        bottom: -3978938.7393210144,
        right: -7867079.4293319257,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.624098503021855],
              [-70.865667959765034, -33.620358336467127],
              [-70.860481531635827, -33.620358336467127],
              [-70.857888317571224, -33.624098503021855],
              [-70.860481531635827, -33.627838507221767],
              [-70.865667959765034, -33.627838507221767],
              [-70.868261173829637, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 4,
        left: -7868234.1304086847,
        bottom: -3977938.738854764,
        right: -7867079.4293319257,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.616618007566458],
              [-70.865667959765034, -33.612877516328737],
              [-70.860481531635827, -33.612877516328737],
              [-70.857888317571224, -33.616618007566458],
              [-70.860481531635827, -33.620358336467127],
              [-70.865667959765034, -33.620358336467127],
              [-70.868261173829637, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 5,
        left: -7868234.1304086847,
        bottom: -3976938.7383885137,
        right: -7867079.4293319257,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.609136862762881],
              [-70.865667959765034, -33.605396046877765],
              [-70.860481531635827, -33.605396046877765],
              [-70.857888317571224, -33.609136862762881],
              [-70.860481531635827, -33.612877516328737],
              [-70.865667959765034, -33.612877516328737],
              [-70.868261173829637, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 6,
        left: -7868234.1304086847,
        bottom: -3975938.7379222633,
        right: -7867079.4293319257,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.601655068682327],
              [-70.865667959765034, -33.597913928185434],
              [-70.860481531635827, -33.597913928185434],
              [-70.857888317571224, -33.601655068682327],
              [-70.860481531635827, -33.605396046877765],
              [-70.865667959765034, -33.605396046877765],
              [-70.868261173829637, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 7,
        left: -7868234.1304086847,
        bottom: -3974938.737456013,
        right: -7867079.4293319257,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.594172625396013],
              [-70.865667959765034, -33.590431160322979],
              [-70.860481531635827, -33.590431160322979],
              [-70.857888317571224, -33.594172625396013],
              [-70.860481531635827, -33.597913928185434],
              [-70.865667959765034, -33.597913928185434],
              [-70.868261173829637, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 8,
        left: -7868234.1304086847,
        bottom: -3973938.7369897626,
        right: -7867079.4293319257,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.586689532975264],
              [-70.865667959765034, -33.58294774336175],
              [-70.860481531635827, -33.58294774336175],
              [-70.857888317571224, -33.586689532975264],
              [-70.860481531635827, -33.590431160322979],
              [-70.865667959765034, -33.590431160322979],
              [-70.868261173829637, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 9,
        left: -7868234.1304086847,
        bottom: -3972938.7365235123,
        right: -7867079.4293319257,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.579205791491383],
              [-70.865667959765034, -33.575463677373087],
              [-70.860481531635827, -33.575463677373087],
              [-70.857888317571224, -33.579205791491383],
              [-70.860481531635827, -33.58294774336175],
              [-70.865667959765034, -33.58294774336175],
              [-70.868261173829637, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 10,
        left: -7868234.1304086847,
        bottom: -3971938.7360572619,
        right: -7867079.4293319257,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.571721401015779],
              [-70.865667959765034, -33.567978962428406],
              [-70.860481531635827, -33.567978962428406],
              [-70.857888317571224, -33.571721401015779],
              [-70.860481531635827, -33.575463677373087],
              [-70.865667959765034, -33.575463677373087],
              [-70.868261173829637, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 11,
        left: -7868234.1304086847,
        bottom: -3970938.7355910116,
        right: -7867079.4293319257,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.564236361619891],
              [-70.865667959765034, -33.56049359859918],
              [-70.860481531635827, -33.56049359859918],
              [-70.857888317571224, -33.564236361619891],
              [-70.860481531635827, -33.567978962428406],
              [-70.865667959765034, -33.567978962428406],
              [-70.868261173829637, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 12,
        left: -7868234.1304086847,
        bottom: -3969938.7351247617,
        right: -7867079.4293319257,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.556750673375213],
              [-70.865667959765034, -33.553007585956919],
              [-70.860481531635827, -33.553007585956919],
              [-70.857888317571224, -33.556750673375213],
              [-70.860481531635827, -33.56049359859918],
              [-70.865667959765034, -33.56049359859918],
              [-70.868261173829637, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 13,
        left: -7868234.1304086847,
        bottom: -3968938.7346585114,
        right: -7867079.4293319257,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.549264336353268],
              [-70.865667959765034, -33.545520924573204],
              [-70.860481531635827, -33.545520924573204],
              [-70.857888317571224, -33.549264336353268],
              [-70.860481531635827, -33.553007585956919],
              [-70.865667959765034, -33.553007585956919],
              [-70.868261173829637, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 14,
        left: -7868234.1304086847,
        bottom: -3967938.734192261,
        right: -7867079.4293319257,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.541777350625665],
              [-70.865667959765034, -33.538033614519627],
              [-70.860481531635827, -33.538033614519627],
              [-70.857888317571224, -33.541777350625665],
              [-70.860481531635827, -33.545520924573204],
              [-70.865667959765034, -33.545520924573204],
              [-70.868261173829637, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 15,
        left: -7868234.1304086847,
        bottom: -3966938.7337260107,
        right: -7867079.4293319257,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.534289716264041],
              [-70.865667959765034, -33.530545655867876],
              [-70.860481531635827, -33.530545655867876],
              [-70.857888317571224, -33.534289716264041],
              [-70.860481531635827, -33.538033614519627],
              [-70.865667959765034, -33.538033614519627],
              [-70.868261173829637, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 16,
        left: -7868234.1304086847,
        bottom: -3965938.7332597603,
        right: -7867079.4293319257,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.52680143334009],
              [-70.865667959765034, -33.523057048689658],
              [-70.860481531635827, -33.523057048689658],
              [-70.857888317571224, -33.52680143334009],
              [-70.860481531635827, -33.530545655867876],
              [-70.865667959765034, -33.530545655867876],
              [-70.868261173829637, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 17,
        left: -7868234.1304086847,
        bottom: -3964938.7327935095,
        right: -7867079.4293319257,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.519312501925548],
              [-70.865667959765034, -33.515567793056739],
              [-70.860481531635827, -33.515567793056739],
              [-70.857888317571224, -33.519312501925548],
              [-70.860481531635827, -33.523057048689658],
              [-70.865667959765034, -33.523057048689658],
              [-70.868261173829637, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 18,
        left: -7868234.1304086847,
        bottom: -3963938.7323272596,
        right: -7867079.4293319257,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.511822922092215],
              [-70.865667959765034, -33.508077889040948],
              [-70.860481531635827, -33.508077889040948],
              [-70.857888317571224, -33.511822922092215],
              [-70.860481531635827, -33.515567793056739],
              [-70.865667959765034, -33.515567793056739],
              [-70.868261173829637, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 19,
        left: -7868234.1304086847,
        bottom: -3962938.7318610097,
        right: -7867079.4293319257,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.504332693911934],
              [-70.865667959765034, -33.500587336714155],
              [-70.860481531635827, -33.500587336714155],
              [-70.857888317571224, -33.504332693911934],
              [-70.860481531635827, -33.508077889040948],
              [-70.865667959765034, -33.508077889040948],
              [-70.868261173829637, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 20,
        left: -7868234.1304086847,
        bottom: -3961938.7313947589,
        right: -7867079.4293319257,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.496841817456605],
              [-70.865667959765034, -33.493096136148274],
              [-70.860481531635827, -33.493096136148274],
              [-70.857888317571224, -33.496841817456605],
              [-70.860481531635827, -33.500587336714155],
              [-70.865667959765034, -33.500587336714155],
              [-70.868261173829637, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 21,
        left: -7868234.1304086847,
        bottom: -3960938.7309285086,
        right: -7867079.4293319257,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.489350292798164],
              [-70.865667959765034, -33.485604287415278],
              [-70.860481531635827, -33.485604287415278],
              [-70.857888317571224, -33.489350292798164],
              [-70.860481531635827, -33.493096136148274],
              [-70.865667959765034, -33.493096136148274],
              [-70.868261173829637, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 22,
        left: -7868234.1304086847,
        bottom: -3959938.7304622582,
        right: -7867079.4293319257,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.481858120008617],
              [-70.865667959765034, -33.478111790587178],
              [-70.860481531635827, -33.478111790587178],
              [-70.857888317571224, -33.481858120008617],
              [-70.860481531635827, -33.485604287415278],
              [-70.865667959765034, -33.485604287415278],
              [-70.868261173829637, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 23,
        left: -7868234.1304086847,
        bottom: -3958938.7299960079,
        right: -7867079.4293319257,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.474365299160006],
              [-70.865667959765034, -33.47061864573606],
              [-70.860481531635827, -33.47061864573606],
              [-70.857888317571224, -33.474365299160006],
              [-70.860481531635827, -33.478111790587178],
              [-70.865667959765034, -33.478111790587178],
              [-70.868261173829637, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 24,
        left: -7868234.1304086847,
        bottom: -3957938.7295297575,
        right: -7867079.4293319257,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.466871830324415],
              [-70.865667959765034, -33.463124852934051],
              [-70.860481531635827, -33.463124852934051],
              [-70.857888317571224, -33.466871830324415],
              [-70.860481531635827, -33.47061864573606],
              [-70.865667959765034, -33.47061864573606],
              [-70.868261173829637, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 25,
        left: -7868234.1304086847,
        bottom: -3956938.7290635072,
        right: -7867079.4293319257,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.459377713574014],
              [-70.865667959765034, -33.455630412253299],
              [-70.860481531635827, -33.455630412253299],
              [-70.857888317571224, -33.459377713574014],
              [-70.860481531635827, -33.463124852934051],
              [-70.865667959765034, -33.463124852934051],
              [-70.868261173829637, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 26,
        left: -7868234.1304086847,
        bottom: -3955938.7285972568,
        right: -7867079.4293319257,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.45188294898098],
              [-70.865667959765034, -33.448135323766053],
              [-70.860481531635827, -33.448135323766053],
              [-70.857888317571224, -33.45188294898098],
              [-70.860481531635827, -33.455630412253299],
              [-70.865667959765034, -33.455630412253299],
              [-70.868261173829637, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 27,
        left: -7868234.1304086847,
        bottom: -3954938.7281310065,
        right: -7867079.4293319257,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.444387536617576],
              [-70.865667959765034, -33.440639587544567],
              [-70.860481531635827, -33.440639587544567],
              [-70.857888317571224, -33.444387536617576],
              [-70.860481531635827, -33.448135323766053],
              [-70.865667959765034, -33.448135323766053],
              [-70.868261173829637, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 28,
        left: -7868234.1304086847,
        bottom: -3953938.7276647561,
        right: -7867079.4293319257,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.436891476556099],
              [-70.865667959765034, -33.433143203661182],
              [-70.860481531635827, -33.433143203661182],
              [-70.857888317571224, -33.436891476556099],
              [-70.860481531635827, -33.440639587544567],
              [-70.865667959765034, -33.440639587544567],
              [-70.868261173829637, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 29,
        left: -7868234.1304086847,
        bottom: -3952938.7271985058,
        right: -7867079.4293319257,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.429394768868896],
              [-70.865667959765034, -33.425646172188266],
              [-70.860481531635827, -33.425646172188266],
              [-70.857888317571224, -33.429394768868896],
              [-70.860481531635827, -33.433143203661182],
              [-70.865667959765034, -33.433143203661182],
              [-70.868261173829637, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 30,
        left: -7868234.1304086847,
        bottom: -3951938.7267322554,
        right: -7867079.4293319257,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.421897413628365],
              [-70.865667959765034, -33.418148493198238],
              [-70.860481531635827, -33.418148493198238],
              [-70.857888317571224, -33.421897413628365],
              [-70.860481531635827, -33.425646172188266],
              [-70.865667959765034, -33.425646172188266],
              [-70.868261173829637, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 31,
        left: -7868234.1304086847,
        bottom: -3950938.7262660051,
        right: -7867079.4293319257,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.414399410906967],
              [-70.865667959765034, -33.410650166763581],
              [-70.860481531635827, -33.410650166763581],
              [-70.857888317571224, -33.414399410906967],
              [-70.860481531635827, -33.418148493198238],
              [-70.865667959765034, -33.418148493198238],
              [-70.868261173829637, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 32,
        left: -7868234.1304086847,
        bottom: -3949938.7257997552,
        right: -7867079.4293319257,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.406900760777191],
              [-70.865667959765034, -33.403151192956834],
              [-70.860481531635827, -33.403151192956834],
              [-70.857888317571224, -33.406900760777191],
              [-70.860481531635827, -33.410650166763581],
              [-70.865667959765034, -33.410650166763581],
              [-70.868261173829637, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 33,
        left: -7868234.1304086847,
        bottom: -3948938.7253335048,
        right: -7867079.4293319257,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.399401463311598],
              [-70.865667959765034, -33.39565157185055],
              [-70.860481531635827, -33.39565157185055],
              [-70.857888317571224, -33.399401463311598],
              [-70.860481531635827, -33.403151192956834],
              [-70.865667959765034, -33.403151192956834],
              [-70.868261173829637, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 34,
        left: -7868234.1304086847,
        bottom: -3947938.724867254,
        right: -7867079.4293319257,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.391901518582785],
              [-70.865667959765034, -33.388151303517375],
              [-70.860481531635827, -33.388151303517375],
              [-70.857888317571224, -33.391901518582785],
              [-70.860481531635827, -33.39565157185055],
              [-70.865667959765034, -33.39565157185055],
              [-70.868261173829637, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 35,
        left: -7868234.1304086847,
        bottom: -3946938.7244010041,
        right: -7867079.4293319257,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.384400926663417],
              [-70.865667959765034, -33.380650388029984],
              [-70.860481531635827, -33.380650388029984],
              [-70.857888317571224, -33.384400926663417],
              [-70.860481531635827, -33.388151303517375],
              [-70.865667959765034, -33.388151303517375],
              [-70.868261173829637, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 36,
        left: -7868234.1304086847,
        bottom: -3945938.7239347543,
        right: -7867079.4293319257,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.376899687626185],
              [-70.865667959765034, -33.373148825461101],
              [-70.860481531635827, -33.373148825461101],
              [-70.857888317571224, -33.376899687626185],
              [-70.860481531635827, -33.380650388029984],
              [-70.865667959765034, -33.380650388029984],
              [-70.868261173829637, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 37,
        left: -7868234.1304086847,
        bottom: -3944938.7234685034,
        right: -7867079.4293319257,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.369397801543847],
              [-70.865667959765034, -33.365646615883513],
              [-70.860481531635827, -33.365646615883513],
              [-70.857888317571224, -33.369397801543847],
              [-70.860481531635827, -33.373148825461101],
              [-70.865667959765034, -33.373148825461101],
              [-70.868261173829637, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 38,
        left: -7868234.1304086847,
        bottom: -3943938.7230022531,
        right: -7867079.4293319257,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.361895268489214],
              [-70.865667959765034, -33.358143759370044],
              [-70.860481531635827, -33.358143759370044],
              [-70.857888317571224, -33.361895268489214],
              [-70.860481531635827, -33.365646615883513],
              [-70.865667959765034, -33.365646615883513],
              [-70.868261173829637, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 39,
        left: -7868234.1304086847,
        bottom: -3942938.7225360028,
        right: -7867079.4293319257,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.354392088535135],
              [-70.865667959765034, -33.350640255993589],
              [-70.860481531635827, -33.350640255993589],
              [-70.857888317571224, -33.354392088535135],
              [-70.860481531635827, -33.358143759370044],
              [-70.865667959765034, -33.358143759370044],
              [-70.868261173829637, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 40,
        left: -7868234.1304086847,
        bottom: -3941938.7220697524,
        right: -7867079.4293319257,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.346888261754529],
              [-70.865667959765034, -33.343136105827064],
              [-70.860481531635827, -33.343136105827064],
              [-70.857888317571224, -33.346888261754529],
              [-70.860481531635827, -33.350640255993589],
              [-70.865667959765034, -33.350640255993589],
              [-70.868261173829637, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 41,
        left: -7868234.1304086847,
        bottom: -3940938.7216035021,
        right: -7867079.4293319257,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.339383788220331],
              [-70.865667959765034, -33.335631308943462],
              [-70.860481531635827, -33.335631308943462],
              [-70.857888317571224, -33.339383788220331],
              [-70.860481531635827, -33.343136105827064],
              [-70.865667959765034, -33.343136105827064],
              [-70.868261173829637, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 42,
        left: -7868234.1304086847,
        bottom: -3939938.7211372517,
        right: -7867079.4293319257,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.331878668005565],
              [-70.865667959765034, -33.328125865415807],
              [-70.860481531635827, -33.328125865415807],
              [-70.857888317571224, -33.331878668005565],
              [-70.860481531635827, -33.335631308943462],
              [-70.865667959765034, -33.335631308943462],
              [-70.868261173829637, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 43,
        left: -7868234.1304086847,
        bottom: -3938938.7206710014,
        right: -7867079.4293319257,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.324372901183303],
              [-70.865667959765034, -33.320619775317184],
              [-70.860481531635827, -33.320619775317184],
              [-70.857888317571224, -33.324372901183303],
              [-70.860481531635827, -33.328125865415807],
              [-70.865667959765034, -33.328125865415807],
              [-70.868261173829637, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 44,
        left: -7868234.1304086847,
        bottom: -3937938.720204751,
        right: -7867079.4293319257,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.31686648782663],
              [-70.865667959765034, -33.313113038720743],
              [-70.860481531635827, -33.313113038720743],
              [-70.857888317571224, -33.31686648782663],
              [-70.860481531635827, -33.320619775317184],
              [-70.865667959765034, -33.320619775317184],
              [-70.868261173829637, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 45,
        left: -7868234.1304086847,
        bottom: -3936938.7197385007,
        right: -7867079.4293319257,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.309359428008712],
              [-70.865667959765034, -33.305605655699658],
              [-70.860481531635827, -33.305605655699658],
              [-70.857888317571224, -33.309359428008712],
              [-70.860481531635827, -33.313113038720743],
              [-70.865667959765034, -33.313113038720743],
              [-70.868261173829637, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 46,
        left: -7868234.1304086847,
        bottom: -3935938.7192722503,
        right: -7867079.4293319257,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.301851721802763],
              [-70.865667959765034, -33.298097626327156],
              [-70.860481531635827, -33.298097626327156],
              [-70.857888317571224, -33.301851721802763],
              [-70.860481531635827, -33.305605655699658],
              [-70.865667959765034, -33.305605655699658],
              [-70.868261173829637, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 47,
        left: -7868234.1304086847,
        bottom: -3934938.718806,
        right: -7867079.4293319257,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.294343369282039],
              [-70.865667959765034, -33.29058895067655],
              [-70.860481531635827, -33.29058895067655],
              [-70.857888317571224, -33.294343369282039],
              [-70.860481531635827, -33.298097626327156],
              [-70.865667959765034, -33.298097626327156],
              [-70.868261173829637, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 48,
        left: -7868234.1304086847,
        bottom: -3933938.7183397496,
        right: -7867079.4293319257,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.868261173829637, -33.286834370519863],
              [-70.865667959765034, -33.283079628821156],
              [-70.860481531635827, -33.283079628821156],
              [-70.857888317571224, -33.286834370519863],
              [-70.860481531635827, -33.29058895067655],
              [-70.865667959765034, -33.29058895067655],
              [-70.868261173829637, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 49,
        left: -7867368.1046011159,
        bottom: -3980438.7400203901,
        right: -7866213.4035243569,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.635318028521546],
              [-70.857888317571224, -33.631578349057953],
              [-70.852701889442002, -33.631578349057953],
              [-70.850108675377399, -33.635318028521546],
              [-70.852701889442002, -33.639057545603663],
              [-70.857888317571224, -33.639057545603663],
              [-70.860481531635827, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 50,
        left: -7867368.1046011159,
        bottom: -3979438.7395541398,
        right: -7866213.4035243569,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.627838507221767],
              [-70.857888317571224, -33.624098503021855],
              [-70.852701889442002, -33.624098503021855],
              [-70.850108675377399, -33.627838507221767],
              [-70.852701889442002, -33.631578349057953],
              [-70.857888317571224, -33.631578349057953],
              [-70.860481531635827, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 51,
        left: -7867368.1046011159,
        bottom: -3978438.7390878894,
        right: -7866213.4035243569,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.620358336467127],
              [-70.857888317571224, -33.616618007566458],
              [-70.852701889442002, -33.616618007566458],
              [-70.850108675377399, -33.620358336467127],
              [-70.852701889442002, -33.624098503021855],
              [-70.857888317571224, -33.624098503021855],
              [-70.860481531635827, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 52,
        left: -7867368.1046011159,
        bottom: -3977438.7386216391,
        right: -7866213.4035243569,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.612877516328737],
              [-70.857888317571224, -33.609136862762881],
              [-70.852701889442002, -33.609136862762881],
              [-70.850108675377399, -33.612877516328737],
              [-70.852701889442002, -33.616618007566458],
              [-70.857888317571224, -33.616618007566458],
              [-70.860481531635827, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 53,
        left: -7867368.1046011159,
        bottom: -3976438.7381553887,
        right: -7866213.4035243569,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.605396046877765],
              [-70.857888317571224, -33.601655068682327],
              [-70.852701889442002, -33.601655068682327],
              [-70.850108675377399, -33.605396046877765],
              [-70.852701889442002, -33.609136862762881],
              [-70.857888317571224, -33.609136862762881],
              [-70.860481531635827, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 54,
        left: -7867368.1046011159,
        bottom: -3975438.7376891384,
        right: -7866213.4035243569,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.597913928185434],
              [-70.857888317571224, -33.594172625396013],
              [-70.852701889442002, -33.594172625396013],
              [-70.850108675377399, -33.597913928185434],
              [-70.852701889442002, -33.601655068682327],
              [-70.857888317571224, -33.601655068682327],
              [-70.860481531635827, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 55,
        left: -7867368.1046011159,
        bottom: -3974438.7372228876,
        right: -7866213.4035243569,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.590431160322979],
              [-70.857888317571224, -33.586689532975264],
              [-70.852701889442002, -33.586689532975264],
              [-70.850108675377399, -33.590431160322979],
              [-70.852701889442002, -33.594172625396013],
              [-70.857888317571224, -33.594172625396013],
              [-70.860481531635827, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 56,
        left: -7867368.1046011159,
        bottom: -3973438.7367566377,
        right: -7866213.4035243569,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.58294774336175],
              [-70.857888317571224, -33.579205791491383],
              [-70.852701889442002, -33.579205791491383],
              [-70.850108675377399, -33.58294774336175],
              [-70.852701889442002, -33.586689532975264],
              [-70.857888317571224, -33.586689532975264],
              [-70.860481531635827, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 57,
        left: -7867368.1046011159,
        bottom: -3972438.7362903878,
        right: -7866213.4035243569,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.575463677373087],
              [-70.857888317571224, -33.571721401015779],
              [-70.852701889442002, -33.571721401015779],
              [-70.850108675377399, -33.575463677373087],
              [-70.852701889442002, -33.579205791491383],
              [-70.857888317571224, -33.579205791491383],
              [-70.860481531635827, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 58,
        left: -7867368.1046011159,
        bottom: -3971438.735824137,
        right: -7866213.4035243569,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.883786257403685, -33.631565558368521],
              [-70.881193043339096, -33.627825715977004],
              [-70.876006615209889, -33.627825715977004],
              [-70.873413401145271, -33.631565558368521],
              [-70.876006615209889, -33.635305238387474],
              [-70.881193043339096, -33.635305238387474],
              [-70.883786257403685, -33.631565558368521],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 59,
        left: -7867368.1046011159,
        bottom: -3970438.7353578866,
        right: -7866213.4035243569,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.56049359859918],
              [-70.857888317571224, -33.556750673375213],
              [-70.852701889442002, -33.556750673375213],
              [-70.850108675377399, -33.56049359859918],
              [-70.852701889442002, -33.564236361619891],
              [-70.857888317571224, -33.564236361619891],
              [-70.860481531635827, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 60,
        left: -7867368.1046011159,
        bottom: -3969438.7348916363,
        right: -7866213.4035243569,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.553007585956919],
              [-70.857888317571224, -33.549264336353268],
              [-70.852701889442002, -33.549264336353268],
              [-70.850108675377399, -33.553007585956919],
              [-70.852701889442002, -33.556750673375213],
              [-70.857888317571224, -33.556750673375213],
              [-70.860481531635827, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 61,
        left: -7867368.1046011159,
        bottom: -3968438.7344253859,
        right: -7866213.4035243569,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.545520924573204],
              [-70.857888317571224, -33.541777350625665],
              [-70.852701889442002, -33.541777350625665],
              [-70.850108675377399, -33.545520924573204],
              [-70.852701889442002, -33.549264336353268],
              [-70.857888317571224, -33.549264336353268],
              [-70.860481531635827, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 62,
        left: -7867368.1046011159,
        bottom: -3967438.7339591356,
        right: -7866213.4035243569,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.538033614519627],
              [-70.857888317571224, -33.534289716264041],
              [-70.852701889442002, -33.534289716264041],
              [-70.850108675377399, -33.538033614519627],
              [-70.852701889442002, -33.541777350625665],
              [-70.857888317571224, -33.541777350625665],
              [-70.860481531635827, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 63,
        left: -7867368.1046011159,
        bottom: -3966438.7334928853,
        right: -7866213.4035243569,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.530545655867876],
              [-70.857888317571224, -33.52680143334009],
              [-70.852701889442002, -33.52680143334009],
              [-70.850108675377399, -33.530545655867876],
              [-70.852701889442002, -33.534289716264041],
              [-70.857888317571224, -33.534289716264041],
              [-70.860481531635827, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 64,
        left: -7867368.1046011159,
        bottom: -3965438.7330266349,
        right: -7866213.4035243569,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.523057048689658],
              [-70.857888317571224, -33.519312501925548],
              [-70.852701889442002, -33.519312501925548],
              [-70.850108675377399, -33.523057048689658],
              [-70.852701889442002, -33.52680143334009],
              [-70.857888317571224, -33.52680143334009],
              [-70.860481531635827, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 65,
        left: -7867368.1046011159,
        bottom: -3964438.7325603846,
        right: -7866213.4035243569,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.515567793056739],
              [-70.857888317571224, -33.511822922092215],
              [-70.852701889442002, -33.511822922092215],
              [-70.850108675377399, -33.515567793056739],
              [-70.852701889442002, -33.519312501925548],
              [-70.857888317571224, -33.519312501925548],
              [-70.860481531635827, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 66,
        left: -7867368.1046011159,
        bottom: -3963438.7320941342,
        right: -7866213.4035243569,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.508077889040948],
              [-70.857888317571224, -33.504332693911934],
              [-70.852701889442002, -33.504332693911934],
              [-70.850108675377399, -33.508077889040948],
              [-70.852701889442002, -33.511822922092215],
              [-70.857888317571224, -33.511822922092215],
              [-70.860481531635827, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 67,
        left: -7867368.1046011159,
        bottom: -3962438.7316278839,
        right: -7866213.4035243569,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.500587336714155],
              [-70.857888317571224, -33.496841817456605],
              [-70.852701889442002, -33.496841817456605],
              [-70.850108675377399, -33.500587336714155],
              [-70.852701889442002, -33.504332693911934],
              [-70.857888317571224, -33.504332693911934],
              [-70.860481531635827, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 68,
        left: -7867368.1046011159,
        bottom: -3961438.7311616335,
        right: -7866213.4035243569,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.493096136148274],
              [-70.857888317571224, -33.489350292798164],
              [-70.852701889442002, -33.489350292798164],
              [-70.850108675377399, -33.493096136148274],
              [-70.852701889442002, -33.496841817456605],
              [-70.857888317571224, -33.496841817456605],
              [-70.860481531635827, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 69,
        left: -7867368.1046011159,
        bottom: -3960438.7306953832,
        right: -7866213.4035243569,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.485604287415278],
              [-70.857888317571224, -33.481858120008617],
              [-70.852701889442002, -33.481858120008617],
              [-70.850108675377399, -33.485604287415278],
              [-70.852701889442002, -33.489350292798164],
              [-70.857888317571224, -33.489350292798164],
              [-70.860481531635827, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 70,
        left: -7867368.1046011159,
        bottom: -3959438.7302291333,
        right: -7866213.4035243569,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.478111790587178],
              [-70.857888317571224, -33.474365299160006],
              [-70.852701889442002, -33.474365299160006],
              [-70.850108675377399, -33.478111790587178],
              [-70.852701889442002, -33.481858120008617],
              [-70.857888317571224, -33.481858120008617],
              [-70.860481531635827, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 71,
        left: -7867368.1046011159,
        bottom: -3958438.7297628829,
        right: -7866213.4035243569,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.47061864573606],
              [-70.857888317571224, -33.466871830324415],
              [-70.852701889442002, -33.466871830324415],
              [-70.850108675377399, -33.47061864573606],
              [-70.852701889442002, -33.474365299160006],
              [-70.857888317571224, -33.474365299160006],
              [-70.860481531635827, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 72,
        left: -7867368.1046011159,
        bottom: -3957438.7292966326,
        right: -7866213.4035243569,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.463124852934051],
              [-70.857888317571224, -33.459377713574014],
              [-70.852701889442002, -33.459377713574014],
              [-70.850108675377399, -33.463124852934051],
              [-70.852701889442002, -33.466871830324415],
              [-70.857888317571224, -33.466871830324415],
              [-70.860481531635827, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 73,
        left: -7867368.1046011159,
        bottom: -3956438.7288303822,
        right: -7866213.4035243569,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.455630412253299],
              [-70.857888317571224, -33.45188294898098],
              [-70.852701889442002, -33.45188294898098],
              [-70.850108675377399, -33.455630412253299],
              [-70.852701889442002, -33.459377713574014],
              [-70.857888317571224, -33.459377713574014],
              [-70.860481531635827, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 74,
        left: -7867368.1046011159,
        bottom: -3955438.7283641323,
        right: -7866213.4035243569,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.448135323766053],
              [-70.857888317571224, -33.444387536617576],
              [-70.852701889442002, -33.444387536617576],
              [-70.850108675377399, -33.448135323766053],
              [-70.852701889442002, -33.45188294898098],
              [-70.857888317571224, -33.45188294898098],
              [-70.860481531635827, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 75,
        left: -7867368.1046011159,
        bottom: -3954438.7278978815,
        right: -7866213.4035243569,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.440639587544567],
              [-70.857888317571224, -33.436891476556099],
              [-70.852701889442002, -33.436891476556099],
              [-70.850108675377399, -33.440639587544567],
              [-70.852701889442002, -33.444387536617576],
              [-70.857888317571224, -33.444387536617576],
              [-70.860481531635827, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 76,
        left: -7867368.1046011159,
        bottom: -3953438.7274316312,
        right: -7866213.4035243569,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.433143203661182],
              [-70.857888317571224, -33.429394768868896],
              [-70.852701889442002, -33.429394768868896],
              [-70.850108675377399, -33.433143203661182],
              [-70.852701889442002, -33.436891476556099],
              [-70.857888317571224, -33.436891476556099],
              [-70.860481531635827, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 77,
        left: -7867368.1046011159,
        bottom: -3952438.7269653808,
        right: -7866213.4035243569,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.425646172188266],
              [-70.857888317571224, -33.421897413628365],
              [-70.852701889442002, -33.421897413628365],
              [-70.850108675377399, -33.425646172188266],
              [-70.852701889442002, -33.429394768868896],
              [-70.857888317571224, -33.429394768868896],
              [-70.860481531635827, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 78,
        left: -7867368.1046011159,
        bottom: -3951438.7264991305,
        right: -7866213.4035243569,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.418148493198238],
              [-70.857888317571224, -33.414399410906967],
              [-70.852701889442002, -33.414399410906967],
              [-70.850108675377399, -33.418148493198238],
              [-70.852701889442002, -33.421897413628365],
              [-70.857888317571224, -33.421897413628365],
              [-70.860481531635827, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 79,
        left: -7867368.1046011159,
        bottom: -3950438.7260328801,
        right: -7866213.4035243569,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.410650166763581],
              [-70.857888317571224, -33.406900760777191],
              [-70.852701889442002, -33.406900760777191],
              [-70.850108675377399, -33.410650166763581],
              [-70.852701889442002, -33.414399410906967],
              [-70.857888317571224, -33.414399410906967],
              [-70.860481531635827, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 80,
        left: -7867368.1046011159,
        bottom: -3949438.7255666298,
        right: -7866213.4035243569,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.403151192956834],
              [-70.857888317571224, -33.399401463311598],
              [-70.852701889442002, -33.399401463311598],
              [-70.850108675377399, -33.403151192956834],
              [-70.852701889442002, -33.406900760777191],
              [-70.857888317571224, -33.406900760777191],
              [-70.860481531635827, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 81,
        left: -7867368.1046011159,
        bottom: -3948438.7251003794,
        right: -7866213.4035243569,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.39565157185055],
              [-70.857888317571224, -33.391901518582785],
              [-70.852701889442002, -33.391901518582785],
              [-70.850108675377399, -33.39565157185055],
              [-70.852701889442002, -33.399401463311598],
              [-70.857888317571224, -33.399401463311598],
              [-70.860481531635827, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 82,
        left: -7867368.1046011159,
        bottom: -3947438.7246341291,
        right: -7866213.4035243569,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.388151303517375],
              [-70.857888317571224, -33.384400926663417],
              [-70.852701889442002, -33.384400926663417],
              [-70.850108675377399, -33.388151303517375],
              [-70.852701889442002, -33.391901518582785],
              [-70.857888317571224, -33.391901518582785],
              [-70.860481531635827, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 83,
        left: -7867368.1046011159,
        bottom: -3946438.7241678787,
        right: -7866213.4035243569,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.380650388029984],
              [-70.857888317571224, -33.376899687626185],
              [-70.852701889442002, -33.376899687626185],
              [-70.850108675377399, -33.380650388029984],
              [-70.852701889442002, -33.384400926663417],
              [-70.857888317571224, -33.384400926663417],
              [-70.860481531635827, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 84,
        left: -7867368.1046011159,
        bottom: -3945438.7237016284,
        right: -7866213.4035243569,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.373148825461101],
              [-70.857888317571224, -33.369397801543847],
              [-70.852701889442002, -33.369397801543847],
              [-70.850108675377399, -33.373148825461101],
              [-70.852701889442002, -33.376899687626185],
              [-70.857888317571224, -33.376899687626185],
              [-70.860481531635827, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 85,
        left: -7867368.1046011159,
        bottom: -3944438.723235378,
        right: -7866213.4035243569,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.365646615883513],
              [-70.857888317571224, -33.361895268489214],
              [-70.852701889442002, -33.361895268489214],
              [-70.850108675377399, -33.365646615883513],
              [-70.852701889442002, -33.369397801543847],
              [-70.857888317571224, -33.369397801543847],
              [-70.860481531635827, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 86,
        left: -7867368.1046011159,
        bottom: -3943438.7227691277,
        right: -7866213.4035243569,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.358143759370044],
              [-70.857888317571224, -33.354392088535135],
              [-70.852701889442002, -33.354392088535135],
              [-70.850108675377399, -33.358143759370044],
              [-70.852701889442002, -33.361895268489214],
              [-70.857888317571224, -33.361895268489214],
              [-70.860481531635827, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 87,
        left: -7867368.1046011159,
        bottom: -3942438.7223028769,
        right: -7866213.4035243569,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.350640255993589],
              [-70.857888317571224, -33.346888261754529],
              [-70.852701889442002, -33.346888261754529],
              [-70.850108675377399, -33.350640255993589],
              [-70.852701889442002, -33.354392088535135],
              [-70.857888317571224, -33.354392088535135],
              [-70.860481531635827, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 88,
        left: -7867368.1046011159,
        bottom: -3941438.721836627,
        right: -7866213.4035243569,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.343136105827064],
              [-70.857888317571224, -33.339383788220331],
              [-70.852701889442002, -33.339383788220331],
              [-70.850108675377399, -33.343136105827064],
              [-70.852701889442002, -33.346888261754529],
              [-70.857888317571224, -33.346888261754529],
              [-70.860481531635827, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 89,
        left: -7867368.1046011159,
        bottom: -3940438.7213703771,
        right: -7866213.4035243569,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.335631308943462],
              [-70.857888317571224, -33.331878668005565],
              [-70.852701889442002, -33.331878668005565],
              [-70.850108675377399, -33.335631308943462],
              [-70.852701889442002, -33.339383788220331],
              [-70.857888317571224, -33.339383788220331],
              [-70.860481531635827, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 90,
        left: -7867368.1046011159,
        bottom: -3939438.7209041263,
        right: -7866213.4035243569,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.328125865415807],
              [-70.857888317571224, -33.324372901183303],
              [-70.852701889442002, -33.324372901183303],
              [-70.850108675377399, -33.328125865415807],
              [-70.852701889442002, -33.331878668005565],
              [-70.857888317571224, -33.331878668005565],
              [-70.860481531635827, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 91,
        left: -7867368.1046011159,
        bottom: -3938438.7204378759,
        right: -7866213.4035243569,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.320619775317184],
              [-70.857888317571224, -33.31686648782663],
              [-70.852701889442002, -33.31686648782663],
              [-70.850108675377399, -33.320619775317184],
              [-70.852701889442002, -33.324372901183303],
              [-70.857888317571224, -33.324372901183303],
              [-70.860481531635827, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 92,
        left: -7867368.1046011159,
        bottom: -3937438.7199716261,
        right: -7866213.4035243569,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.313113038720743],
              [-70.857888317571224, -33.309359428008712],
              [-70.852701889442002, -33.309359428008712],
              [-70.850108675377399, -33.313113038720743],
              [-70.852701889442002, -33.31686648782663],
              [-70.857888317571224, -33.31686648782663],
              [-70.860481531635827, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 93,
        left: -7867368.1046011159,
        bottom: -3936438.7195053762,
        right: -7866213.4035243569,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.305605655699658],
              [-70.857888317571224, -33.301851721802763],
              [-70.852701889442002, -33.301851721802763],
              [-70.850108675377399, -33.305605655699658],
              [-70.852701889442002, -33.309359428008712],
              [-70.857888317571224, -33.309359428008712],
              [-70.860481531635827, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 94,
        left: -7867368.1046011159,
        bottom: -3935438.7190391254,
        right: -7866213.4035243569,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.298097626327156],
              [-70.857888317571224, -33.294343369282039],
              [-70.852701889442002, -33.294343369282039],
              [-70.850108675377399, -33.298097626327156],
              [-70.852701889442002, -33.301851721802763],
              [-70.857888317571224, -33.301851721802763],
              [-70.860481531635827, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 95,
        left: -7867368.1046011159,
        bottom: -3934438.718572875,
        right: -7866213.4035243569,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.29058895067655],
              [-70.857888317571224, -33.286834370519863],
              [-70.852701889442002, -33.286834370519863],
              [-70.850108675377399, -33.29058895067655],
              [-70.852701889442002, -33.294343369282039],
              [-70.857888317571224, -33.294343369282039],
              [-70.860481531635827, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 96,
        left: -7867368.1046011159,
        bottom: -3933438.7181066247,
        right: -7866213.4035243569,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.860481531635827, -33.283079628821156],
              [-70.857888317571224, -33.279324725589596],
              [-70.852701889442002, -33.279324725589596],
              [-70.850108675377399, -33.283079628821156],
              [-70.852701889442002, -33.286834370519863],
              [-70.857888317571224, -33.286834370519863],
              [-70.860481531635827, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 97,
        left: -7866502.0787935462,
        bottom: -3980938.7402535151,
        right: -7865347.3777167881,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.639057545603663],
              [-70.850108675377399, -33.635318028521546],
              [-70.844922247248192, -33.635318028521546],
              [-70.842329033183603, -33.639057545603663],
              [-70.844922247248192, -33.642796900295409],
              [-70.850108675377399, -33.642796900295409],
              [-70.852701889442002, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 98,
        left: -7866502.0787935462,
        bottom: -3979938.7397872647,
        right: -7865347.3777167881,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.631578349057953],
              [-70.850108675377399, -33.627838507221767],
              [-70.844922247248192, -33.627838507221767],
              [-70.842329033183603, -33.631578349057953],
              [-70.844922247248192, -33.635318028521546],
              [-70.850108675377399, -33.635318028521546],
              [-70.852701889442002, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 99,
        left: -7866502.0787935462,
        bottom: -3978938.7393210144,
        right: -7865347.3777167881,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.624098503021855],
              [-70.850108675377399, -33.620358336467127],
              [-70.844922247248192, -33.620358336467127],
              [-70.842329033183603, -33.624098503021855],
              [-70.844922247248192, -33.627838507221767],
              [-70.850108675377399, -33.627838507221767],
              [-70.852701889442002, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 100,
        left: -7866502.0787935462,
        bottom: -3977938.738854764,
        right: -7865347.3777167881,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.616618007566458],
              [-70.850108675377399, -33.612877516328737],
              [-70.844922247248192, -33.612877516328737],
              [-70.842329033183603, -33.616618007566458],
              [-70.844922247248192, -33.620358336467127],
              [-70.850108675377399, -33.620358336467127],
              [-70.852701889442002, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 101,
        left: -7866502.0787935462,
        bottom: -3976938.7383885137,
        right: -7865347.3777167881,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.609136862762881],
              [-70.850108675377399, -33.605396046877765],
              [-70.844922247248192, -33.605396046877765],
              [-70.842329033183603, -33.609136862762881],
              [-70.844922247248192, -33.612877516328737],
              [-70.850108675377399, -33.612877516328737],
              [-70.852701889442002, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 102,
        left: -7866502.0787935462,
        bottom: -3975938.7379222633,
        right: -7865347.3777167881,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.601655068682327],
              [-70.850108675377399, -33.597913928185434],
              [-70.844922247248192, -33.597913928185434],
              [-70.842329033183603, -33.601655068682327],
              [-70.844922247248192, -33.605396046877765],
              [-70.850108675377399, -33.605396046877765],
              [-70.852701889442002, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 103,
        left: -7866502.0787935462,
        bottom: -3974938.737456013,
        right: -7865347.3777167881,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.594172625396013],
              [-70.850108675377399, -33.590431160322979],
              [-70.844922247248192, -33.590431160322979],
              [-70.842329033183603, -33.594172625396013],
              [-70.844922247248192, -33.597913928185434],
              [-70.850108675377399, -33.597913928185434],
              [-70.852701889442002, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 104,
        left: -7866502.0787935462,
        bottom: -3973938.7369897626,
        right: -7865347.3777167881,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.586689532975264],
              [-70.850108675377399, -33.58294774336175],
              [-70.844922247248192, -33.58294774336175],
              [-70.842329033183603, -33.586689532975264],
              [-70.844922247248192, -33.590431160322979],
              [-70.850108675377399, -33.590431160322979],
              [-70.852701889442002, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 105,
        left: -7866502.0787935462,
        bottom: -3972938.7365235123,
        right: -7865347.3777167881,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.579205791491383],
              [-70.850108675377399, -33.575463677373087],
              [-70.844922247248192, -33.575463677373087],
              [-70.842329033183603, -33.579205791491383],
              [-70.844922247248192, -33.58294774336175],
              [-70.850108675377399, -33.58294774336175],
              [-70.852701889442002, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 106,
        left: -7866502.0787935462,
        bottom: -3971938.7360572619,
        right: -7865347.3777167881,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.571721401015779],
              [-70.850108675377399, -33.567978962428406],
              [-70.844922247248192, -33.567978962428406],
              [-70.842329033183603, -33.571721401015779],
              [-70.844922247248192, -33.575463677373087],
              [-70.850108675377399, -33.575463677373087],
              [-70.852701889442002, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 107,
        left: -7866502.0787935462,
        bottom: -3970938.7355910116,
        right: -7865347.3777167881,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.564236361619891],
              [-70.850108675377399, -33.56049359859918],
              [-70.844922247248192, -33.56049359859918],
              [-70.842329033183603, -33.564236361619891],
              [-70.844922247248192, -33.567978962428406],
              [-70.850108675377399, -33.567978962428406],
              [-70.852701889442002, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 108,
        left: -7866502.0787935462,
        bottom: -3969938.7351247617,
        right: -7865347.3777167881,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.556750673375213],
              [-70.850108675377399, -33.553007585956919],
              [-70.844922247248192, -33.553007585956919],
              [-70.842329033183603, -33.556750673375213],
              [-70.844922247248192, -33.56049359859918],
              [-70.850108675377399, -33.56049359859918],
              [-70.852701889442002, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 109,
        left: -7866502.0787935462,
        bottom: -3968938.7346585114,
        right: -7865347.3777167881,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.549264336353268],
              [-70.850108675377399, -33.545520924573204],
              [-70.844922247248192, -33.545520924573204],
              [-70.842329033183603, -33.549264336353268],
              [-70.844922247248192, -33.553007585956919],
              [-70.850108675377399, -33.553007585956919],
              [-70.852701889442002, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 110,
        left: -7866502.0787935462,
        bottom: -3967938.734192261,
        right: -7865347.3777167881,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.541777350625665],
              [-70.850108675377399, -33.538033614519627],
              [-70.844922247248192, -33.538033614519627],
              [-70.842329033183603, -33.541777350625665],
              [-70.844922247248192, -33.545520924573204],
              [-70.850108675377399, -33.545520924573204],
              [-70.852701889442002, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 111,
        left: -7866502.0787935462,
        bottom: -3966938.7337260107,
        right: -7865347.3777167881,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.534289716264041],
              [-70.850108675377399, -33.530545655867876],
              [-70.844922247248192, -33.530545655867876],
              [-70.842329033183603, -33.534289716264041],
              [-70.844922247248192, -33.538033614519627],
              [-70.850108675377399, -33.538033614519627],
              [-70.852701889442002, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 112,
        left: -7866502.0787935462,
        bottom: -3965938.7332597603,
        right: -7865347.3777167881,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.52680143334009],
              [-70.850108675377399, -33.523057048689658],
              [-70.844922247248192, -33.523057048689658],
              [-70.842329033183603, -33.52680143334009],
              [-70.844922247248192, -33.530545655867876],
              [-70.850108675377399, -33.530545655867876],
              [-70.852701889442002, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 113,
        left: -7866502.0787935462,
        bottom: -3964938.7327935095,
        right: -7865347.3777167881,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.519312501925548],
              [-70.850108675377399, -33.515567793056739],
              [-70.844922247248192, -33.515567793056739],
              [-70.842329033183603, -33.519312501925548],
              [-70.844922247248192, -33.523057048689658],
              [-70.850108675377399, -33.523057048689658],
              [-70.852701889442002, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 114,
        left: -7866502.0787935462,
        bottom: -3963938.7323272596,
        right: -7865347.3777167881,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.511822922092215],
              [-70.850108675377399, -33.508077889040948],
              [-70.844922247248192, -33.508077889040948],
              [-70.842329033183603, -33.511822922092215],
              [-70.844922247248192, -33.515567793056739],
              [-70.850108675377399, -33.515567793056739],
              [-70.852701889442002, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 115,
        left: -7866502.0787935462,
        bottom: -3962938.7318610097,
        right: -7865347.3777167881,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.504332693911934],
              [-70.850108675377399, -33.500587336714155],
              [-70.844922247248192, -33.500587336714155],
              [-70.842329033183603, -33.504332693911934],
              [-70.844922247248192, -33.508077889040948],
              [-70.850108675377399, -33.508077889040948],
              [-70.852701889442002, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 116,
        left: -7866502.0787935462,
        bottom: -3961938.7313947589,
        right: -7865347.3777167881,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.496841817456605],
              [-70.850108675377399, -33.493096136148274],
              [-70.844922247248192, -33.493096136148274],
              [-70.842329033183603, -33.496841817456605],
              [-70.844922247248192, -33.500587336714155],
              [-70.850108675377399, -33.500587336714155],
              [-70.852701889442002, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 117,
        left: -7866502.0787935462,
        bottom: -3960938.7309285086,
        right: -7865347.3777167881,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.489350292798164],
              [-70.850108675377399, -33.485604287415278],
              [-70.844922247248192, -33.485604287415278],
              [-70.842329033183603, -33.489350292798164],
              [-70.844922247248192, -33.493096136148274],
              [-70.850108675377399, -33.493096136148274],
              [-70.852701889442002, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 118,
        left: -7866502.0787935462,
        bottom: -3959938.7304622582,
        right: -7865347.3777167881,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.481858120008617],
              [-70.850108675377399, -33.478111790587178],
              [-70.844922247248192, -33.478111790587178],
              [-70.842329033183603, -33.481858120008617],
              [-70.844922247248192, -33.485604287415278],
              [-70.850108675377399, -33.485604287415278],
              [-70.852701889442002, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 119,
        left: -7866502.0787935462,
        bottom: -3958938.7299960079,
        right: -7865347.3777167881,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.474365299160006],
              [-70.850108675377399, -33.47061864573606],
              [-70.844922247248192, -33.47061864573606],
              [-70.842329033183603, -33.474365299160006],
              [-70.844922247248192, -33.478111790587178],
              [-70.850108675377399, -33.478111790587178],
              [-70.852701889442002, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 120,
        left: -7866502.0787935462,
        bottom: -3957938.7295297575,
        right: -7865347.3777167881,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.466871830324415],
              [-70.850108675377399, -33.463124852934051],
              [-70.844922247248192, -33.463124852934051],
              [-70.842329033183603, -33.466871830324415],
              [-70.844922247248192, -33.47061864573606],
              [-70.850108675377399, -33.47061864573606],
              [-70.852701889442002, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 121,
        left: -7866502.0787935462,
        bottom: -3956938.7290635072,
        right: -7865347.3777167881,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.459377713574014],
              [-70.850108675377399, -33.455630412253299],
              [-70.844922247248192, -33.455630412253299],
              [-70.842329033183603, -33.459377713574014],
              [-70.844922247248192, -33.463124852934051],
              [-70.850108675377399, -33.463124852934051],
              [-70.852701889442002, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 122,
        left: -7866502.0787935462,
        bottom: -3955938.7285972568,
        right: -7865347.3777167881,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.45188294898098],
              [-70.850108675377399, -33.448135323766053],
              [-70.844922247248192, -33.448135323766053],
              [-70.842329033183603, -33.45188294898098],
              [-70.844922247248192, -33.455630412253299],
              [-70.850108675377399, -33.455630412253299],
              [-70.852701889442002, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 123,
        left: -7866502.0787935462,
        bottom: -3954938.7281310065,
        right: -7865347.3777167881,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.444387536617576],
              [-70.850108675377399, -33.440639587544567],
              [-70.844922247248192, -33.440639587544567],
              [-70.842329033183603, -33.444387536617576],
              [-70.844922247248192, -33.448135323766053],
              [-70.850108675377399, -33.448135323766053],
              [-70.852701889442002, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 124,
        left: -7866502.0787935462,
        bottom: -3953938.7276647561,
        right: -7865347.3777167881,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.436891476556099],
              [-70.850108675377399, -33.433143203661182],
              [-70.844922247248192, -33.433143203661182],
              [-70.842329033183603, -33.436891476556099],
              [-70.844922247248192, -33.440639587544567],
              [-70.850108675377399, -33.440639587544567],
              [-70.852701889442002, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 125,
        left: -7866502.0787935462,
        bottom: -3952938.7271985058,
        right: -7865347.3777167881,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.429394768868896],
              [-70.850108675377399, -33.425646172188266],
              [-70.844922247248192, -33.425646172188266],
              [-70.842329033183603, -33.429394768868896],
              [-70.844922247248192, -33.433143203661182],
              [-70.850108675377399, -33.433143203661182],
              [-70.852701889442002, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 126,
        left: -7866502.0787935462,
        bottom: -3951938.7267322554,
        right: -7865347.3777167881,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.421897413628365],
              [-70.850108675377399, -33.418148493198238],
              [-70.844922247248192, -33.418148493198238],
              [-70.842329033183603, -33.421897413628365],
              [-70.844922247248192, -33.425646172188266],
              [-70.850108675377399, -33.425646172188266],
              [-70.852701889442002, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 127,
        left: -7866502.0787935462,
        bottom: -3950938.7262660051,
        right: -7865347.3777167881,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.414399410906967],
              [-70.850108675377399, -33.410650166763581],
              [-70.844922247248192, -33.410650166763581],
              [-70.842329033183603, -33.414399410906967],
              [-70.844922247248192, -33.418148493198238],
              [-70.850108675377399, -33.418148493198238],
              [-70.852701889442002, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 128,
        left: -7866502.0787935462,
        bottom: -3949938.7257997552,
        right: -7865347.3777167881,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.406900760777191],
              [-70.850108675377399, -33.403151192956834],
              [-70.844922247248192, -33.403151192956834],
              [-70.842329033183603, -33.406900760777191],
              [-70.844922247248192, -33.410650166763581],
              [-70.850108675377399, -33.410650166763581],
              [-70.852701889442002, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 129,
        left: -7866502.0787935462,
        bottom: -3948938.7253335048,
        right: -7865347.3777167881,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.399401463311598],
              [-70.850108675377399, -33.39565157185055],
              [-70.844922247248192, -33.39565157185055],
              [-70.842329033183603, -33.399401463311598],
              [-70.844922247248192, -33.403151192956834],
              [-70.850108675377399, -33.403151192956834],
              [-70.852701889442002, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 130,
        left: -7866502.0787935462,
        bottom: -3947938.724867254,
        right: -7865347.3777167881,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.391901518582785],
              [-70.850108675377399, -33.388151303517375],
              [-70.844922247248192, -33.388151303517375],
              [-70.842329033183603, -33.391901518582785],
              [-70.844922247248192, -33.39565157185055],
              [-70.850108675377399, -33.39565157185055],
              [-70.852701889442002, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 131,
        left: -7866502.0787935462,
        bottom: -3946938.7244010041,
        right: -7865347.3777167881,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.384400926663417],
              [-70.850108675377399, -33.380650388029984],
              [-70.844922247248192, -33.380650388029984],
              [-70.842329033183603, -33.384400926663417],
              [-70.844922247248192, -33.388151303517375],
              [-70.850108675377399, -33.388151303517375],
              [-70.852701889442002, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 132,
        left: -7866502.0787935462,
        bottom: -3945938.7239347543,
        right: -7865347.3777167881,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.376899687626185],
              [-70.850108675377399, -33.373148825461101],
              [-70.844922247248192, -33.373148825461101],
              [-70.842329033183603, -33.376899687626185],
              [-70.844922247248192, -33.380650388029984],
              [-70.850108675377399, -33.380650388029984],
              [-70.852701889442002, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 133,
        left: -7866502.0787935462,
        bottom: -3944938.7234685034,
        right: -7865347.3777167881,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.369397801543847],
              [-70.850108675377399, -33.365646615883513],
              [-70.844922247248192, -33.365646615883513],
              [-70.842329033183603, -33.369397801543847],
              [-70.844922247248192, -33.373148825461101],
              [-70.850108675377399, -33.373148825461101],
              [-70.852701889442002, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 134,
        left: -7866502.0787935462,
        bottom: -3943938.7230022531,
        right: -7865347.3777167881,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.361895268489214],
              [-70.850108675377399, -33.358143759370044],
              [-70.844922247248192, -33.358143759370044],
              [-70.842329033183603, -33.361895268489214],
              [-70.844922247248192, -33.365646615883513],
              [-70.850108675377399, -33.365646615883513],
              [-70.852701889442002, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 135,
        left: -7866502.0787935462,
        bottom: -3942938.7225360028,
        right: -7865347.3777167881,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.354392088535135],
              [-70.850108675377399, -33.350640255993589],
              [-70.844922247248192, -33.350640255993589],
              [-70.842329033183603, -33.354392088535135],
              [-70.844922247248192, -33.358143759370044],
              [-70.850108675377399, -33.358143759370044],
              [-70.852701889442002, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 136,
        left: -7866502.0787935462,
        bottom: -3941938.7220697524,
        right: -7865347.3777167881,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.346888261754529],
              [-70.850108675377399, -33.343136105827064],
              [-70.844922247248192, -33.343136105827064],
              [-70.842329033183603, -33.346888261754529],
              [-70.844922247248192, -33.350640255993589],
              [-70.850108675377399, -33.350640255993589],
              [-70.852701889442002, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 137,
        left: -7866502.0787935462,
        bottom: -3940938.7216035021,
        right: -7865347.3777167881,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.339383788220331],
              [-70.850108675377399, -33.335631308943462],
              [-70.844922247248192, -33.335631308943462],
              [-70.842329033183603, -33.339383788220331],
              [-70.844922247248192, -33.343136105827064],
              [-70.850108675377399, -33.343136105827064],
              [-70.852701889442002, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 138,
        left: -7866502.0787935462,
        bottom: -3939938.7211372517,
        right: -7865347.3777167881,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.331878668005565],
              [-70.850108675377399, -33.328125865415807],
              [-70.844922247248192, -33.328125865415807],
              [-70.842329033183603, -33.331878668005565],
              [-70.844922247248192, -33.335631308943462],
              [-70.850108675377399, -33.335631308943462],
              [-70.852701889442002, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 139,
        left: -7866502.0787935462,
        bottom: -3938938.7206710014,
        right: -7865347.3777167881,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.324372901183303],
              [-70.850108675377399, -33.320619775317184],
              [-70.844922247248192, -33.320619775317184],
              [-70.842329033183603, -33.324372901183303],
              [-70.844922247248192, -33.328125865415807],
              [-70.850108675377399, -33.328125865415807],
              [-70.852701889442002, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 140,
        left: -7866502.0787935462,
        bottom: -3937938.720204751,
        right: -7865347.3777167881,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.31686648782663],
              [-70.850108675377399, -33.313113038720743],
              [-70.844922247248192, -33.313113038720743],
              [-70.842329033183603, -33.31686648782663],
              [-70.844922247248192, -33.320619775317184],
              [-70.850108675377399, -33.320619775317184],
              [-70.852701889442002, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 141,
        left: -7866502.0787935462,
        bottom: -3936938.7197385007,
        right: -7865347.3777167881,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.309359428008712],
              [-70.850108675377399, -33.305605655699658],
              [-70.844922247248192, -33.305605655699658],
              [-70.842329033183603, -33.309359428008712],
              [-70.844922247248192, -33.313113038720743],
              [-70.850108675377399, -33.313113038720743],
              [-70.852701889442002, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 142,
        left: -7866502.0787935462,
        bottom: -3935938.7192722503,
        right: -7865347.3777167881,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.301851721802763],
              [-70.850108675377399, -33.298097626327156],
              [-70.844922247248192, -33.298097626327156],
              [-70.842329033183603, -33.301851721802763],
              [-70.844922247248192, -33.305605655699658],
              [-70.850108675377399, -33.305605655699658],
              [-70.852701889442002, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 143,
        left: -7866502.0787935462,
        bottom: -3934938.718806,
        right: -7865347.3777167881,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.294343369282039],
              [-70.850108675377399, -33.29058895067655],
              [-70.844922247248192, -33.29058895067655],
              [-70.842329033183603, -33.294343369282039],
              [-70.844922247248192, -33.298097626327156],
              [-70.850108675377399, -33.298097626327156],
              [-70.852701889442002, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 144,
        left: -7866502.0787935462,
        bottom: -3933938.7183397496,
        right: -7865347.3777167881,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.852701889442002, -33.286834370519863],
              [-70.850108675377399, -33.283079628821156],
              [-70.844922247248192, -33.283079628821156],
              [-70.842329033183603, -33.286834370519863],
              [-70.844922247248192, -33.29058895067655],
              [-70.850108675377399, -33.29058895067655],
              [-70.852701889442002, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 145,
        left: -7865636.0529859774,
        bottom: -3980438.7400203901,
        right: -7864481.3519092202,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.635318028521546],
              [-70.842329033183603, -33.631578349057953],
              [-70.837142605054396, -33.631578349057953],
              [-70.834549390989793, -33.635318028521546],
              [-70.837142605054396, -33.639057545603663],
              [-70.842329033183603, -33.639057545603663],
              [-70.844922247248192, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 146,
        left: -7865636.0529859774,
        bottom: -3979438.7395541398,
        right: -7864481.3519092202,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.627838507221767],
              [-70.842329033183603, -33.624098503021855],
              [-70.837142605054396, -33.624098503021855],
              [-70.834549390989793, -33.627838507221767],
              [-70.837142605054396, -33.631578349057953],
              [-70.842329033183603, -33.631578349057953],
              [-70.844922247248192, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 147,
        left: -7865636.0529859774,
        bottom: -3978438.7390878894,
        right: -7864481.3519092202,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.620358336467127],
              [-70.842329033183603, -33.616618007566458],
              [-70.837142605054396, -33.616618007566458],
              [-70.834549390989793, -33.620358336467127],
              [-70.837142605054396, -33.624098503021855],
              [-70.842329033183603, -33.624098503021855],
              [-70.844922247248192, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 148,
        left: -7865636.0529859774,
        bottom: -3977438.7386216391,
        right: -7864481.3519092202,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.612877516328737],
              [-70.842329033183603, -33.609136862762881],
              [-70.837142605054396, -33.609136862762881],
              [-70.834549390989793, -33.612877516328737],
              [-70.837142605054396, -33.616618007566458],
              [-70.842329033183603, -33.616618007566458],
              [-70.844922247248192, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 149,
        left: -7865636.0529859774,
        bottom: -3976438.7381553887,
        right: -7864481.3519092202,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.605396046877765],
              [-70.842329033183603, -33.601655068682327],
              [-70.837142605054396, -33.601655068682327],
              [-70.834549390989793, -33.605396046877765],
              [-70.837142605054396, -33.609136862762881],
              [-70.842329033183603, -33.609136862762881],
              [-70.844922247248192, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 150,
        left: -7865636.0529859774,
        bottom: -3975438.7376891384,
        right: -7864481.3519092202,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.597913928185434],
              [-70.842329033183603, -33.594172625396013],
              [-70.837142605054396, -33.594172625396013],
              [-70.834549390989793, -33.597913928185434],
              [-70.837142605054396, -33.601655068682327],
              [-70.842329033183603, -33.601655068682327],
              [-70.844922247248192, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 151,
        left: -7865636.0529859774,
        bottom: -3974438.7372228876,
        right: -7864481.3519092202,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.590431160322979],
              [-70.842329033183603, -33.586689532975264],
              [-70.837142605054396, -33.586689532975264],
              [-70.834549390989793, -33.590431160322979],
              [-70.837142605054396, -33.594172625396013],
              [-70.842329033183603, -33.594172625396013],
              [-70.844922247248192, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 152,
        left: -7865636.0529859774,
        bottom: -3973438.7367566377,
        right: -7864481.3519092202,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.58294774336175],
              [-70.842329033183603, -33.579205791491383],
              [-70.837142605054396, -33.579205791491383],
              [-70.834549390989793, -33.58294774336175],
              [-70.837142605054396, -33.586689532975264],
              [-70.842329033183603, -33.586689532975264],
              [-70.844922247248192, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 153,
        left: -7865636.0529859774,
        bottom: -3972438.7362903878,
        right: -7864481.3519092202,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.575463677373087],
              [-70.842329033183603, -33.571721401015779],
              [-70.837142605054396, -33.571721401015779],
              [-70.834549390989793, -33.575463677373087],
              [-70.837142605054396, -33.579205791491383],
              [-70.842329033183603, -33.579205791491383],
              [-70.844922247248192, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 154,
        left: -7865636.0529859774,
        bottom: -3971438.735824137,
        right: -7864481.3519092202,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.567978962428406],
              [-70.842329033183603, -33.564236361619891],
              [-70.837142605054396, -33.564236361619891],
              [-70.834549390989793, -33.567978962428406],
              [-70.837142605054396, -33.571721401015779],
              [-70.842329033183603, -33.571721401015779],
              [-70.844922247248192, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 155,
        left: -7865636.0529859774,
        bottom: -3970438.7353578866,
        right: -7864481.3519092202,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.56049359859918],
              [-70.842329033183603, -33.556750673375213],
              [-70.837142605054396, -33.556750673375213],
              [-70.834549390989793, -33.56049359859918],
              [-70.837142605054396, -33.564236361619891],
              [-70.842329033183603, -33.564236361619891],
              [-70.844922247248192, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 156,
        left: -7865636.0529859774,
        bottom: -3969438.7348916363,
        right: -7864481.3519092202,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.553007585956919],
              [-70.842329033183603, -33.549264336353268],
              [-70.837142605054396, -33.549264336353268],
              [-70.834549390989793, -33.553007585956919],
              [-70.837142605054396, -33.556750673375213],
              [-70.842329033183603, -33.556750673375213],
              [-70.844922247248192, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 157,
        left: -7865636.0529859774,
        bottom: -3968438.7344253859,
        right: -7864481.3519092202,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.545520924573204],
              [-70.842329033183603, -33.541777350625665],
              [-70.837142605054396, -33.541777350625665],
              [-70.834549390989793, -33.545520924573204],
              [-70.837142605054396, -33.549264336353268],
              [-70.842329033183603, -33.549264336353268],
              [-70.844922247248192, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 158,
        left: -7865636.0529859774,
        bottom: -3967438.7339591356,
        right: -7864481.3519092202,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.538033614519627],
              [-70.842329033183603, -33.534289716264041],
              [-70.837142605054396, -33.534289716264041],
              [-70.834549390989793, -33.538033614519627],
              [-70.837142605054396, -33.541777350625665],
              [-70.842329033183603, -33.541777350625665],
              [-70.844922247248192, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 159,
        left: -7865636.0529859774,
        bottom: -3966438.7334928853,
        right: -7864481.3519092202,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.530545655867876],
              [-70.842329033183603, -33.52680143334009],
              [-70.837142605054396, -33.52680143334009],
              [-70.834549390989793, -33.530545655867876],
              [-70.837142605054396, -33.534289716264041],
              [-70.842329033183603, -33.534289716264041],
              [-70.844922247248192, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 160,
        left: -7865636.0529859774,
        bottom: -3965438.7330266349,
        right: -7864481.3519092202,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.523057048689658],
              [-70.842329033183603, -33.519312501925548],
              [-70.837142605054396, -33.519312501925548],
              [-70.834549390989793, -33.523057048689658],
              [-70.837142605054396, -33.52680143334009],
              [-70.842329033183603, -33.52680143334009],
              [-70.844922247248192, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 161,
        left: -7865636.0529859774,
        bottom: -3964438.7325603846,
        right: -7864481.3519092202,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.515567793056739],
              [-70.842329033183603, -33.511822922092215],
              [-70.837142605054396, -33.511822922092215],
              [-70.834549390989793, -33.515567793056739],
              [-70.837142605054396, -33.519312501925548],
              [-70.842329033183603, -33.519312501925548],
              [-70.844922247248192, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 162,
        left: -7865636.0529859774,
        bottom: -3963438.7320941342,
        right: -7864481.3519092202,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.508077889040948],
              [-70.842329033183603, -33.504332693911934],
              [-70.837142605054396, -33.504332693911934],
              [-70.834549390989793, -33.508077889040948],
              [-70.837142605054396, -33.511822922092215],
              [-70.842329033183603, -33.511822922092215],
              [-70.844922247248192, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 163,
        left: -7865636.0529859774,
        bottom: -3962438.7316278839,
        right: -7864481.3519092202,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.500587336714155],
              [-70.842329033183603, -33.496841817456605],
              [-70.837142605054396, -33.496841817456605],
              [-70.834549390989793, -33.500587336714155],
              [-70.837142605054396, -33.504332693911934],
              [-70.842329033183603, -33.504332693911934],
              [-70.844922247248192, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 164,
        left: -7865636.0529859774,
        bottom: -3961438.7311616335,
        right: -7864481.3519092202,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.493096136148274],
              [-70.842329033183603, -33.489350292798164],
              [-70.837142605054396, -33.489350292798164],
              [-70.834549390989793, -33.493096136148274],
              [-70.837142605054396, -33.496841817456605],
              [-70.842329033183603, -33.496841817456605],
              [-70.844922247248192, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 165,
        left: -7865636.0529859774,
        bottom: -3960438.7306953832,
        right: -7864481.3519092202,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.485604287415278],
              [-70.842329033183603, -33.481858120008617],
              [-70.837142605054396, -33.481858120008617],
              [-70.834549390989793, -33.485604287415278],
              [-70.837142605054396, -33.489350292798164],
              [-70.842329033183603, -33.489350292798164],
              [-70.844922247248192, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 166,
        left: -7865636.0529859774,
        bottom: -3959438.7302291333,
        right: -7864481.3519092202,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.478111790587178],
              [-70.842329033183603, -33.474365299160006],
              [-70.837142605054396, -33.474365299160006],
              [-70.834549390989793, -33.478111790587178],
              [-70.837142605054396, -33.481858120008617],
              [-70.842329033183603, -33.481858120008617],
              [-70.844922247248192, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 167,
        left: -7865636.0529859774,
        bottom: -3958438.7297628829,
        right: -7864481.3519092202,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.47061864573606],
              [-70.842329033183603, -33.466871830324415],
              [-70.837142605054396, -33.466871830324415],
              [-70.834549390989793, -33.47061864573606],
              [-70.837142605054396, -33.474365299160006],
              [-70.842329033183603, -33.474365299160006],
              [-70.844922247248192, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 168,
        left: -7865636.0529859774,
        bottom: -3957438.7292966326,
        right: -7864481.3519092202,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.463124852934051],
              [-70.842329033183603, -33.459377713574014],
              [-70.837142605054396, -33.459377713574014],
              [-70.834549390989793, -33.463124852934051],
              [-70.837142605054396, -33.466871830324415],
              [-70.842329033183603, -33.466871830324415],
              [-70.844922247248192, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 169,
        left: -7865636.0529859774,
        bottom: -3956438.7288303822,
        right: -7864481.3519092202,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.455630412253299],
              [-70.842329033183603, -33.45188294898098],
              [-70.837142605054396, -33.45188294898098],
              [-70.834549390989793, -33.455630412253299],
              [-70.837142605054396, -33.459377713574014],
              [-70.842329033183603, -33.459377713574014],
              [-70.844922247248192, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 170,
        left: -7865636.0529859774,
        bottom: -3955438.7283641323,
        right: -7864481.3519092202,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.448135323766053],
              [-70.842329033183603, -33.444387536617576],
              [-70.837142605054396, -33.444387536617576],
              [-70.834549390989793, -33.448135323766053],
              [-70.837142605054396, -33.45188294898098],
              [-70.842329033183603, -33.45188294898098],
              [-70.844922247248192, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 171,
        left: -7865636.0529859774,
        bottom: -3954438.7278978815,
        right: -7864481.3519092202,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.440639587544567],
              [-70.842329033183603, -33.436891476556099],
              [-70.837142605054396, -33.436891476556099],
              [-70.834549390989793, -33.440639587544567],
              [-70.837142605054396, -33.444387536617576],
              [-70.842329033183603, -33.444387536617576],
              [-70.844922247248192, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 172,
        left: -7865636.0529859774,
        bottom: -3953438.7274316312,
        right: -7864481.3519092202,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.433143203661182],
              [-70.842329033183603, -33.429394768868896],
              [-70.837142605054396, -33.429394768868896],
              [-70.834549390989793, -33.433143203661182],
              [-70.837142605054396, -33.436891476556099],
              [-70.842329033183603, -33.436891476556099],
              [-70.844922247248192, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 173,
        left: -7865636.0529859774,
        bottom: -3952438.7269653808,
        right: -7864481.3519092202,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.425646172188266],
              [-70.842329033183603, -33.421897413628365],
              [-70.837142605054396, -33.421897413628365],
              [-70.834549390989793, -33.425646172188266],
              [-70.837142605054396, -33.429394768868896],
              [-70.842329033183603, -33.429394768868896],
              [-70.844922247248192, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 174,
        left: -7865636.0529859774,
        bottom: -3951438.7264991305,
        right: -7864481.3519092202,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.418148493198238],
              [-70.842329033183603, -33.414399410906967],
              [-70.837142605054396, -33.414399410906967],
              [-70.834549390989793, -33.418148493198238],
              [-70.837142605054396, -33.421897413628365],
              [-70.842329033183603, -33.421897413628365],
              [-70.844922247248192, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 175,
        left: -7865636.0529859774,
        bottom: -3950438.7260328801,
        right: -7864481.3519092202,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.410650166763581],
              [-70.842329033183603, -33.406900760777191],
              [-70.837142605054396, -33.406900760777191],
              [-70.834549390989793, -33.410650166763581],
              [-70.837142605054396, -33.414399410906967],
              [-70.842329033183603, -33.414399410906967],
              [-70.844922247248192, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 176,
        left: -7865636.0529859774,
        bottom: -3949438.7255666298,
        right: -7864481.3519092202,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.403151192956834],
              [-70.842329033183603, -33.399401463311598],
              [-70.837142605054396, -33.399401463311598],
              [-70.834549390989793, -33.403151192956834],
              [-70.837142605054396, -33.406900760777191],
              [-70.842329033183603, -33.406900760777191],
              [-70.844922247248192, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 177,
        left: -7865636.0529859774,
        bottom: -3948438.7251003794,
        right: -7864481.3519092202,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.39565157185055],
              [-70.842329033183603, -33.391901518582785],
              [-70.837142605054396, -33.391901518582785],
              [-70.834549390989793, -33.39565157185055],
              [-70.837142605054396, -33.399401463311598],
              [-70.842329033183603, -33.399401463311598],
              [-70.844922247248192, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 178,
        left: -7865636.0529859774,
        bottom: -3947438.7246341291,
        right: -7864481.3519092202,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.388151303517375],
              [-70.842329033183603, -33.384400926663417],
              [-70.837142605054396, -33.384400926663417],
              [-70.834549390989793, -33.388151303517375],
              [-70.837142605054396, -33.391901518582785],
              [-70.842329033183603, -33.391901518582785],
              [-70.844922247248192, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 179,
        left: -7865636.0529859774,
        bottom: -3946438.7241678787,
        right: -7864481.3519092202,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.380650388029984],
              [-70.842329033183603, -33.376899687626185],
              [-70.837142605054396, -33.376899687626185],
              [-70.834549390989793, -33.380650388029984],
              [-70.837142605054396, -33.384400926663417],
              [-70.842329033183603, -33.384400926663417],
              [-70.844922247248192, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 180,
        left: -7865636.0529859774,
        bottom: -3945438.7237016284,
        right: -7864481.3519092202,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.373148825461101],
              [-70.842329033183603, -33.369397801543847],
              [-70.837142605054396, -33.369397801543847],
              [-70.834549390989793, -33.373148825461101],
              [-70.837142605054396, -33.376899687626185],
              [-70.842329033183603, -33.376899687626185],
              [-70.844922247248192, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 181,
        left: -7865636.0529859774,
        bottom: -3944438.723235378,
        right: -7864481.3519092202,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.365646615883513],
              [-70.842329033183603, -33.361895268489214],
              [-70.837142605054396, -33.361895268489214],
              [-70.834549390989793, -33.365646615883513],
              [-70.837142605054396, -33.369397801543847],
              [-70.842329033183603, -33.369397801543847],
              [-70.844922247248192, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 182,
        left: -7865636.0529859774,
        bottom: -3943438.7227691277,
        right: -7864481.3519092202,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.358143759370044],
              [-70.842329033183603, -33.354392088535135],
              [-70.837142605054396, -33.354392088535135],
              [-70.834549390989793, -33.358143759370044],
              [-70.837142605054396, -33.361895268489214],
              [-70.842329033183603, -33.361895268489214],
              [-70.844922247248192, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 183,
        left: -7865636.0529859774,
        bottom: -3942438.7223028769,
        right: -7864481.3519092202,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.350640255993589],
              [-70.842329033183603, -33.346888261754529],
              [-70.837142605054396, -33.346888261754529],
              [-70.834549390989793, -33.350640255993589],
              [-70.837142605054396, -33.354392088535135],
              [-70.842329033183603, -33.354392088535135],
              [-70.844922247248192, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 184,
        left: -7865636.0529859774,
        bottom: -3941438.721836627,
        right: -7864481.3519092202,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.343136105827064],
              [-70.842329033183603, -33.339383788220331],
              [-70.837142605054396, -33.339383788220331],
              [-70.834549390989793, -33.343136105827064],
              [-70.837142605054396, -33.346888261754529],
              [-70.842329033183603, -33.346888261754529],
              [-70.844922247248192, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 185,
        left: -7865636.0529859774,
        bottom: -3940438.7213703771,
        right: -7864481.3519092202,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.335631308943462],
              [-70.842329033183603, -33.331878668005565],
              [-70.837142605054396, -33.331878668005565],
              [-70.834549390989793, -33.335631308943462],
              [-70.837142605054396, -33.339383788220331],
              [-70.842329033183603, -33.339383788220331],
              [-70.844922247248192, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 186,
        left: -7865636.0529859774,
        bottom: -3939438.7209041263,
        right: -7864481.3519092202,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.328125865415807],
              [-70.842329033183603, -33.324372901183303],
              [-70.837142605054396, -33.324372901183303],
              [-70.834549390989793, -33.328125865415807],
              [-70.837142605054396, -33.331878668005565],
              [-70.842329033183603, -33.331878668005565],
              [-70.844922247248192, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 187,
        left: -7865636.0529859774,
        bottom: -3938438.7204378759,
        right: -7864481.3519092202,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.320619775317184],
              [-70.842329033183603, -33.31686648782663],
              [-70.837142605054396, -33.31686648782663],
              [-70.834549390989793, -33.320619775317184],
              [-70.837142605054396, -33.324372901183303],
              [-70.842329033183603, -33.324372901183303],
              [-70.844922247248192, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 188,
        left: -7865636.0529859774,
        bottom: -3937438.7199716261,
        right: -7864481.3519092202,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.313113038720743],
              [-70.842329033183603, -33.309359428008712],
              [-70.837142605054396, -33.309359428008712],
              [-70.834549390989793, -33.313113038720743],
              [-70.837142605054396, -33.31686648782663],
              [-70.842329033183603, -33.31686648782663],
              [-70.844922247248192, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 189,
        left: -7865636.0529859774,
        bottom: -3936438.7195053762,
        right: -7864481.3519092202,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.305605655699658],
              [-70.842329033183603, -33.301851721802763],
              [-70.837142605054396, -33.301851721802763],
              [-70.834549390989793, -33.305605655699658],
              [-70.837142605054396, -33.309359428008712],
              [-70.842329033183603, -33.309359428008712],
              [-70.844922247248192, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 190,
        left: -7865636.0529859774,
        bottom: -3935438.7190391254,
        right: -7864481.3519092202,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.298097626327156],
              [-70.842329033183603, -33.294343369282039],
              [-70.837142605054396, -33.294343369282039],
              [-70.834549390989793, -33.298097626327156],
              [-70.837142605054396, -33.301851721802763],
              [-70.842329033183603, -33.301851721802763],
              [-70.844922247248192, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 191,
        left: -7865636.0529859774,
        bottom: -3934438.718572875,
        right: -7864481.3519092202,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.29058895067655],
              [-70.842329033183603, -33.286834370519863],
              [-70.837142605054396, -33.286834370519863],
              [-70.834549390989793, -33.29058895067655],
              [-70.837142605054396, -33.294343369282039],
              [-70.842329033183603, -33.294343369282039],
              [-70.844922247248192, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 192,
        left: -7865636.0529859774,
        bottom: -3933438.7181066247,
        right: -7864481.3519092202,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.844922247248192, -33.283079628821156],
              [-70.842329033183603, -33.279324725589596],
              [-70.837142605054396, -33.279324725589596],
              [-70.834549390989793, -33.283079628821156],
              [-70.837142605054396, -33.286834370519863],
              [-70.842329033183603, -33.286834370519863],
              [-70.844922247248192, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 193,
        left: -7864770.0271784086,
        bottom: -3980938.7402535151,
        right: -7863615.3261016505,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.639057545603663],
              [-70.834549390989793, -33.635318028521546],
              [-70.829362962860571, -33.635318028521546],
              [-70.826769748795968, -33.639057545603663],
              [-70.829362962860571, -33.642796900295409],
              [-70.834549390989793, -33.642796900295409],
              [-70.837142605054396, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 194,
        left: -7864770.0271784086,
        bottom: -3979938.7397872647,
        right: -7863615.3261016505,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.631578349057953],
              [-70.834549390989793, -33.627838507221767],
              [-70.829362962860571, -33.627838507221767],
              [-70.826769748795968, -33.631578349057953],
              [-70.829362962860571, -33.635318028521546],
              [-70.834549390989793, -33.635318028521546],
              [-70.837142605054396, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 195,
        left: -7864770.0271784086,
        bottom: -3978938.7393210144,
        right: -7863615.3261016505,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.624098503021855],
              [-70.834549390989793, -33.620358336467127],
              [-70.829362962860571, -33.620358336467127],
              [-70.826769748795968, -33.624098503021855],
              [-70.829362962860571, -33.627838507221767],
              [-70.834549390989793, -33.627838507221767],
              [-70.837142605054396, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 196,
        left: -7864770.0271784086,
        bottom: -3977938.738854764,
        right: -7863615.3261016505,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.616618007566458],
              [-70.834549390989793, -33.612877516328737],
              [-70.829362962860571, -33.612877516328737],
              [-70.826769748795968, -33.616618007566458],
              [-70.829362962860571, -33.620358336467127],
              [-70.834549390989793, -33.620358336467127],
              [-70.837142605054396, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 197,
        left: -7864770.0271784086,
        bottom: -3976938.7383885137,
        right: -7863615.3261016505,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.609136862762881],
              [-70.834549390989793, -33.605396046877765],
              [-70.829362962860571, -33.605396046877765],
              [-70.826769748795968, -33.609136862762881],
              [-70.829362962860571, -33.612877516328737],
              [-70.834549390989793, -33.612877516328737],
              [-70.837142605054396, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 198,
        left: -7864770.0271784086,
        bottom: -3975938.7379222633,
        right: -7863615.3261016505,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.601655068682327],
              [-70.834549390989793, -33.597913928185434],
              [-70.829362962860571, -33.597913928185434],
              [-70.826769748795968, -33.601655068682327],
              [-70.829362962860571, -33.605396046877765],
              [-70.834549390989793, -33.605396046877765],
              [-70.837142605054396, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 199,
        left: -7864770.0271784086,
        bottom: -3974938.737456013,
        right: -7863615.3261016505,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.594172625396013],
              [-70.834549390989793, -33.590431160322979],
              [-70.829362962860571, -33.590431160322979],
              [-70.826769748795968, -33.594172625396013],
              [-70.829362962860571, -33.597913928185434],
              [-70.834549390989793, -33.597913928185434],
              [-70.837142605054396, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 200,
        left: -7864770.0271784086,
        bottom: -3973938.7369897626,
        right: -7863615.3261016505,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.586689532975264],
              [-70.834549390989793, -33.58294774336175],
              [-70.829362962860571, -33.58294774336175],
              [-70.826769748795968, -33.586689532975264],
              [-70.829362962860571, -33.590431160322979],
              [-70.834549390989793, -33.590431160322979],
              [-70.837142605054396, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 201,
        left: -7864770.0271784086,
        bottom: -3972938.7365235123,
        right: -7863615.3261016505,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.579205791491383],
              [-70.834549390989793, -33.575463677373087],
              [-70.829362962860571, -33.575463677373087],
              [-70.826769748795968, -33.579205791491383],
              [-70.829362962860571, -33.58294774336175],
              [-70.834549390989793, -33.58294774336175],
              [-70.837142605054396, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 202,
        left: -7864770.0271784086,
        bottom: -3971938.7360572619,
        right: -7863615.3261016505,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.571721401015779],
              [-70.834549390989793, -33.567978962428406],
              [-70.829362962860571, -33.567978962428406],
              [-70.826769748795968, -33.571721401015779],
              [-70.829362962860571, -33.575463677373087],
              [-70.834549390989793, -33.575463677373087],
              [-70.837142605054396, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 203,
        left: -7864770.0271784086,
        bottom: -3970938.7355910116,
        right: -7863615.3261016505,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.564236361619891],
              [-70.834549390989793, -33.56049359859918],
              [-70.829362962860571, -33.56049359859918],
              [-70.826769748795968, -33.564236361619891],
              [-70.829362962860571, -33.567978962428406],
              [-70.834549390989793, -33.567978962428406],
              [-70.837142605054396, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 204,
        left: -7864770.0271784086,
        bottom: -3969938.7351247617,
        right: -7863615.3261016505,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.556750673375213],
              [-70.834549390989793, -33.553007585956919],
              [-70.829362962860571, -33.553007585956919],
              [-70.826769748795968, -33.556750673375213],
              [-70.829362962860571, -33.56049359859918],
              [-70.834549390989793, -33.56049359859918],
              [-70.837142605054396, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 205,
        left: -7864770.0271784086,
        bottom: -3968938.7346585114,
        right: -7863615.3261016505,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.549264336353268],
              [-70.834549390989793, -33.545520924573204],
              [-70.829362962860571, -33.545520924573204],
              [-70.826769748795968, -33.549264336353268],
              [-70.829362962860571, -33.553007585956919],
              [-70.834549390989793, -33.553007585956919],
              [-70.837142605054396, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 206,
        left: -7864770.0271784086,
        bottom: -3967938.734192261,
        right: -7863615.3261016505,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.541777350625665],
              [-70.834549390989793, -33.538033614519627],
              [-70.829362962860571, -33.538033614519627],
              [-70.826769748795968, -33.541777350625665],
              [-70.829362962860571, -33.545520924573204],
              [-70.834549390989793, -33.545520924573204],
              [-70.837142605054396, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 207,
        left: -7864770.0271784086,
        bottom: -3966938.7337260107,
        right: -7863615.3261016505,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.534289716264041],
              [-70.834549390989793, -33.530545655867876],
              [-70.829362962860571, -33.530545655867876],
              [-70.826769748795968, -33.534289716264041],
              [-70.829362962860571, -33.538033614519627],
              [-70.834549390989793, -33.538033614519627],
              [-70.837142605054396, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 208,
        left: -7864770.0271784086,
        bottom: -3965938.7332597603,
        right: -7863615.3261016505,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.52680143334009],
              [-70.834549390989793, -33.523057048689658],
              [-70.829362962860571, -33.523057048689658],
              [-70.826769748795968, -33.52680143334009],
              [-70.829362962860571, -33.530545655867876],
              [-70.834549390989793, -33.530545655867876],
              [-70.837142605054396, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 209,
        left: -7864770.0271784086,
        bottom: -3964938.7327935095,
        right: -7863615.3261016505,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.519312501925548],
              [-70.834549390989793, -33.515567793056739],
              [-70.829362962860571, -33.515567793056739],
              [-70.826769748795968, -33.519312501925548],
              [-70.829362962860571, -33.523057048689658],
              [-70.834549390989793, -33.523057048689658],
              [-70.837142605054396, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 210,
        left: -7864770.0271784086,
        bottom: -3963938.7323272596,
        right: -7863615.3261016505,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.511822922092215],
              [-70.834549390989793, -33.508077889040948],
              [-70.829362962860571, -33.508077889040948],
              [-70.826769748795968, -33.511822922092215],
              [-70.829362962860571, -33.515567793056739],
              [-70.834549390989793, -33.515567793056739],
              [-70.837142605054396, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 211,
        left: -7864770.0271784086,
        bottom: -3962938.7318610097,
        right: -7863615.3261016505,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.504332693911934],
              [-70.834549390989793, -33.500587336714155],
              [-70.829362962860571, -33.500587336714155],
              [-70.826769748795968, -33.504332693911934],
              [-70.829362962860571, -33.508077889040948],
              [-70.834549390989793, -33.508077889040948],
              [-70.837142605054396, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 212,
        left: -7864770.0271784086,
        bottom: -3961938.7313947589,
        right: -7863615.3261016505,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.496841817456605],
              [-70.834549390989793, -33.493096136148274],
              [-70.829362962860571, -33.493096136148274],
              [-70.826769748795968, -33.496841817456605],
              [-70.829362962860571, -33.500587336714155],
              [-70.834549390989793, -33.500587336714155],
              [-70.837142605054396, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 213,
        left: -7864770.0271784086,
        bottom: -3960938.7309285086,
        right: -7863615.3261016505,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.489350292798164],
              [-70.834549390989793, -33.485604287415278],
              [-70.829362962860571, -33.485604287415278],
              [-70.826769748795968, -33.489350292798164],
              [-70.829362962860571, -33.493096136148274],
              [-70.834549390989793, -33.493096136148274],
              [-70.837142605054396, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 214,
        left: -7864770.0271784086,
        bottom: -3959938.7304622582,
        right: -7863615.3261016505,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.481858120008617],
              [-70.834549390989793, -33.478111790587178],
              [-70.829362962860571, -33.478111790587178],
              [-70.826769748795968, -33.481858120008617],
              [-70.829362962860571, -33.485604287415278],
              [-70.834549390989793, -33.485604287415278],
              [-70.837142605054396, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 215,
        left: -7864770.0271784086,
        bottom: -3958938.7299960079,
        right: -7863615.3261016505,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.474365299160006],
              [-70.834549390989793, -33.47061864573606],
              [-70.829362962860571, -33.47061864573606],
              [-70.826769748795968, -33.474365299160006],
              [-70.829362962860571, -33.478111790587178],
              [-70.834549390989793, -33.478111790587178],
              [-70.837142605054396, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 216,
        left: -7864770.0271784086,
        bottom: -3957938.7295297575,
        right: -7863615.3261016505,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.466871830324415],
              [-70.834549390989793, -33.463124852934051],
              [-70.829362962860571, -33.463124852934051],
              [-70.826769748795968, -33.466871830324415],
              [-70.829362962860571, -33.47061864573606],
              [-70.834549390989793, -33.47061864573606],
              [-70.837142605054396, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 217,
        left: -7864770.0271784086,
        bottom: -3956938.7290635072,
        right: -7863615.3261016505,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.459377713574014],
              [-70.834549390989793, -33.455630412253299],
              [-70.829362962860571, -33.455630412253299],
              [-70.826769748795968, -33.459377713574014],
              [-70.829362962860571, -33.463124852934051],
              [-70.834549390989793, -33.463124852934051],
              [-70.837142605054396, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 218,
        left: -7864770.0271784086,
        bottom: -3955938.7285972568,
        right: -7863615.3261016505,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.45188294898098],
              [-70.834549390989793, -33.448135323766053],
              [-70.829362962860571, -33.448135323766053],
              [-70.826769748795968, -33.45188294898098],
              [-70.829362962860571, -33.455630412253299],
              [-70.834549390989793, -33.455630412253299],
              [-70.837142605054396, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 219,
        left: -7864770.0271784086,
        bottom: -3954938.7281310065,
        right: -7863615.3261016505,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.444387536617576],
              [-70.834549390989793, -33.440639587544567],
              [-70.829362962860571, -33.440639587544567],
              [-70.826769748795968, -33.444387536617576],
              [-70.829362962860571, -33.448135323766053],
              [-70.834549390989793, -33.448135323766053],
              [-70.837142605054396, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 220,
        left: -7864770.0271784086,
        bottom: -3953938.7276647561,
        right: -7863615.3261016505,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.436891476556099],
              [-70.834549390989793, -33.433143203661182],
              [-70.829362962860571, -33.433143203661182],
              [-70.826769748795968, -33.436891476556099],
              [-70.829362962860571, -33.440639587544567],
              [-70.834549390989793, -33.440639587544567],
              [-70.837142605054396, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 221,
        left: -7864770.0271784086,
        bottom: -3952938.7271985058,
        right: -7863615.3261016505,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.429394768868896],
              [-70.834549390989793, -33.425646172188266],
              [-70.829362962860571, -33.425646172188266],
              [-70.826769748795968, -33.429394768868896],
              [-70.829362962860571, -33.433143203661182],
              [-70.834549390989793, -33.433143203661182],
              [-70.837142605054396, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 222,
        left: -7864770.0271784086,
        bottom: -3951938.7267322554,
        right: -7863615.3261016505,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.421897413628365],
              [-70.834549390989793, -33.418148493198238],
              [-70.829362962860571, -33.418148493198238],
              [-70.826769748795968, -33.421897413628365],
              [-70.829362962860571, -33.425646172188266],
              [-70.834549390989793, -33.425646172188266],
              [-70.837142605054396, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 223,
        left: -7864770.0271784086,
        bottom: -3950938.7262660051,
        right: -7863615.3261016505,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.414399410906967],
              [-70.834549390989793, -33.410650166763581],
              [-70.829362962860571, -33.410650166763581],
              [-70.826769748795968, -33.414399410906967],
              [-70.829362962860571, -33.418148493198238],
              [-70.834549390989793, -33.418148493198238],
              [-70.837142605054396, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 224,
        left: -7864770.0271784086,
        bottom: -3949938.7257997552,
        right: -7863615.3261016505,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.406900760777191],
              [-70.834549390989793, -33.403151192956834],
              [-70.829362962860571, -33.403151192956834],
              [-70.826769748795968, -33.406900760777191],
              [-70.829362962860571, -33.410650166763581],
              [-70.834549390989793, -33.410650166763581],
              [-70.837142605054396, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 225,
        left: -7864770.0271784086,
        bottom: -3948938.7253335048,
        right: -7863615.3261016505,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.399401463311598],
              [-70.834549390989793, -33.39565157185055],
              [-70.829362962860571, -33.39565157185055],
              [-70.826769748795968, -33.399401463311598],
              [-70.829362962860571, -33.403151192956834],
              [-70.834549390989793, -33.403151192956834],
              [-70.837142605054396, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 226,
        left: -7864770.0271784086,
        bottom: -3947938.724867254,
        right: -7863615.3261016505,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.391901518582785],
              [-70.834549390989793, -33.388151303517375],
              [-70.829362962860571, -33.388151303517375],
              [-70.826769748795968, -33.391901518582785],
              [-70.829362962860571, -33.39565157185055],
              [-70.834549390989793, -33.39565157185055],
              [-70.837142605054396, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 227,
        left: -7864770.0271784086,
        bottom: -3946938.7244010041,
        right: -7863615.3261016505,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.384400926663417],
              [-70.834549390989793, -33.380650388029984],
              [-70.829362962860571, -33.380650388029984],
              [-70.826769748795968, -33.384400926663417],
              [-70.829362962860571, -33.388151303517375],
              [-70.834549390989793, -33.388151303517375],
              [-70.837142605054396, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 228,
        left: -7864770.0271784086,
        bottom: -3945938.7239347543,
        right: -7863615.3261016505,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.376899687626185],
              [-70.834549390989793, -33.373148825461101],
              [-70.829362962860571, -33.373148825461101],
              [-70.826769748795968, -33.376899687626185],
              [-70.829362962860571, -33.380650388029984],
              [-70.834549390989793, -33.380650388029984],
              [-70.837142605054396, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 229,
        left: -7864770.0271784086,
        bottom: -3944938.7234685034,
        right: -7863615.3261016505,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.369397801543847],
              [-70.834549390989793, -33.365646615883513],
              [-70.829362962860571, -33.365646615883513],
              [-70.826769748795968, -33.369397801543847],
              [-70.829362962860571, -33.373148825461101],
              [-70.834549390989793, -33.373148825461101],
              [-70.837142605054396, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 230,
        left: -7864770.0271784086,
        bottom: -3943938.7230022531,
        right: -7863615.3261016505,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.361895268489214],
              [-70.834549390989793, -33.358143759370044],
              [-70.829362962860571, -33.358143759370044],
              [-70.826769748795968, -33.361895268489214],
              [-70.829362962860571, -33.365646615883513],
              [-70.834549390989793, -33.365646615883513],
              [-70.837142605054396, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 231,
        left: -7864770.0271784086,
        bottom: -3942938.7225360028,
        right: -7863615.3261016505,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.354392088535135],
              [-70.834549390989793, -33.350640255993589],
              [-70.829362962860571, -33.350640255993589],
              [-70.826769748795968, -33.354392088535135],
              [-70.829362962860571, -33.358143759370044],
              [-70.834549390989793, -33.358143759370044],
              [-70.837142605054396, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 232,
        left: -7864770.0271784086,
        bottom: -3941938.7220697524,
        right: -7863615.3261016505,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.346888261754529],
              [-70.834549390989793, -33.343136105827064],
              [-70.829362962860571, -33.343136105827064],
              [-70.826769748795968, -33.346888261754529],
              [-70.829362962860571, -33.350640255993589],
              [-70.834549390989793, -33.350640255993589],
              [-70.837142605054396, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 233,
        left: -7864770.0271784086,
        bottom: -3940938.7216035021,
        right: -7863615.3261016505,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.339383788220331],
              [-70.834549390989793, -33.335631308943462],
              [-70.829362962860571, -33.335631308943462],
              [-70.826769748795968, -33.339383788220331],
              [-70.829362962860571, -33.343136105827064],
              [-70.834549390989793, -33.343136105827064],
              [-70.837142605054396, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 234,
        left: -7864770.0271784086,
        bottom: -3939938.7211372517,
        right: -7863615.3261016505,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.331878668005565],
              [-70.834549390989793, -33.328125865415807],
              [-70.829362962860571, -33.328125865415807],
              [-70.826769748795968, -33.331878668005565],
              [-70.829362962860571, -33.335631308943462],
              [-70.834549390989793, -33.335631308943462],
              [-70.837142605054396, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 235,
        left: -7864770.0271784086,
        bottom: -3938938.7206710014,
        right: -7863615.3261016505,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.324372901183303],
              [-70.834549390989793, -33.320619775317184],
              [-70.829362962860571, -33.320619775317184],
              [-70.826769748795968, -33.324372901183303],
              [-70.829362962860571, -33.328125865415807],
              [-70.834549390989793, -33.328125865415807],
              [-70.837142605054396, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 236,
        left: -7864770.0271784086,
        bottom: -3937938.720204751,
        right: -7863615.3261016505,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.31686648782663],
              [-70.834549390989793, -33.313113038720743],
              [-70.829362962860571, -33.313113038720743],
              [-70.826769748795968, -33.31686648782663],
              [-70.829362962860571, -33.320619775317184],
              [-70.834549390989793, -33.320619775317184],
              [-70.837142605054396, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 237,
        left: -7864770.0271784086,
        bottom: -3936938.7197385007,
        right: -7863615.3261016505,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.309359428008712],
              [-70.834549390989793, -33.305605655699658],
              [-70.829362962860571, -33.305605655699658],
              [-70.826769748795968, -33.309359428008712],
              [-70.829362962860571, -33.313113038720743],
              [-70.834549390989793, -33.313113038720743],
              [-70.837142605054396, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 238,
        left: -7864770.0271784086,
        bottom: -3935938.7192722503,
        right: -7863615.3261016505,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.301851721802763],
              [-70.834549390989793, -33.298097626327156],
              [-70.829362962860571, -33.298097626327156],
              [-70.826769748795968, -33.301851721802763],
              [-70.829362962860571, -33.305605655699658],
              [-70.834549390989793, -33.305605655699658],
              [-70.837142605054396, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 239,
        left: -7864770.0271784086,
        bottom: -3934938.718806,
        right: -7863615.3261016505,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.294343369282039],
              [-70.834549390989793, -33.29058895067655],
              [-70.829362962860571, -33.29058895067655],
              [-70.826769748795968, -33.294343369282039],
              [-70.829362962860571, -33.298097626327156],
              [-70.834549390989793, -33.298097626327156],
              [-70.837142605054396, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 240,
        left: -7864770.0271784086,
        bottom: -3933938.7183397496,
        right: -7863615.3261016505,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.837142605054396, -33.286834370519863],
              [-70.834549390989793, -33.283079628821156],
              [-70.829362962860571, -33.283079628821156],
              [-70.826769748795968, -33.286834370519863],
              [-70.829362962860571, -33.29058895067655],
              [-70.834549390989793, -33.29058895067655],
              [-70.837142605054396, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 241,
        left: -7863904.0013708398,
        bottom: -3980438.7400203901,
        right: -7862749.3002940807,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.635318028521546],
              [-70.826769748795968, -33.631578349057953],
              [-70.821583320666761, -33.631578349057953],
              [-70.818990106602158, -33.635318028521546],
              [-70.821583320666761, -33.639057545603663],
              [-70.826769748795968, -33.639057545603663],
              [-70.829362962860571, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 242,
        left: -7863904.0013708398,
        bottom: -3979438.7395541398,
        right: -7862749.3002940807,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.627838507221767],
              [-70.826769748795968, -33.624098503021855],
              [-70.821583320666761, -33.624098503021855],
              [-70.818990106602158, -33.627838507221767],
              [-70.821583320666761, -33.631578349057953],
              [-70.826769748795968, -33.631578349057953],
              [-70.829362962860571, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 243,
        left: -7863904.0013708398,
        bottom: -3978438.7390878894,
        right: -7862749.3002940807,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.620358336467127],
              [-70.826769748795968, -33.616618007566458],
              [-70.821583320666761, -33.616618007566458],
              [-70.818990106602158, -33.620358336467127],
              [-70.821583320666761, -33.624098503021855],
              [-70.826769748795968, -33.624098503021855],
              [-70.829362962860571, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 244,
        left: -7863904.0013708398,
        bottom: -3977438.7386216391,
        right: -7862749.3002940807,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.612877516328737],
              [-70.826769748795968, -33.609136862762881],
              [-70.821583320666761, -33.609136862762881],
              [-70.818990106602158, -33.612877516328737],
              [-70.821583320666761, -33.616618007566458],
              [-70.826769748795968, -33.616618007566458],
              [-70.829362962860571, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 245,
        left: -7863904.0013708398,
        bottom: -3976438.7381553887,
        right: -7862749.3002940807,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.605396046877765],
              [-70.826769748795968, -33.601655068682327],
              [-70.821583320666761, -33.601655068682327],
              [-70.818990106602158, -33.605396046877765],
              [-70.821583320666761, -33.609136862762881],
              [-70.826769748795968, -33.609136862762881],
              [-70.829362962860571, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 246,
        left: -7863904.0013708398,
        bottom: -3975438.7376891384,
        right: -7862749.3002940807,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.597913928185434],
              [-70.826769748795968, -33.594172625396013],
              [-70.821583320666761, -33.594172625396013],
              [-70.818990106602158, -33.597913928185434],
              [-70.821583320666761, -33.601655068682327],
              [-70.826769748795968, -33.601655068682327],
              [-70.829362962860571, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 247,
        left: -7863904.0013708398,
        bottom: -3974438.7372228876,
        right: -7862749.3002940807,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.590431160322979],
              [-70.826769748795968, -33.586689532975264],
              [-70.821583320666761, -33.586689532975264],
              [-70.818990106602158, -33.590431160322979],
              [-70.821583320666761, -33.594172625396013],
              [-70.826769748795968, -33.594172625396013],
              [-70.829362962860571, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 248,
        left: -7863904.0013708398,
        bottom: -3973438.7367566377,
        right: -7862749.3002940807,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.58294774336175],
              [-70.826769748795968, -33.579205791491383],
              [-70.821583320666761, -33.579205791491383],
              [-70.818990106602158, -33.58294774336175],
              [-70.821583320666761, -33.586689532975264],
              [-70.826769748795968, -33.586689532975264],
              [-70.829362962860571, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 249,
        left: -7863904.0013708398,
        bottom: -3972438.7362903878,
        right: -7862749.3002940807,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.575463677373087],
              [-70.826769748795968, -33.571721401015779],
              [-70.821583320666761, -33.571721401015779],
              [-70.818990106602158, -33.575463677373087],
              [-70.821583320666761, -33.579205791491383],
              [-70.826769748795968, -33.579205791491383],
              [-70.829362962860571, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 250,
        left: -7863904.0013708398,
        bottom: -3971438.735824137,
        right: -7862749.3002940807,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.567978962428406],
              [-70.826769748795968, -33.564236361619891],
              [-70.821583320666761, -33.564236361619891],
              [-70.818990106602158, -33.567978962428406],
              [-70.821583320666761, -33.571721401015779],
              [-70.826769748795968, -33.571721401015779],
              [-70.829362962860571, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 251,
        left: -7863904.0013708398,
        bottom: -3970438.7353578866,
        right: -7862749.3002940807,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.56049359859918],
              [-70.826769748795968, -33.556750673375213],
              [-70.821583320666761, -33.556750673375213],
              [-70.818990106602158, -33.56049359859918],
              [-70.821583320666761, -33.564236361619891],
              [-70.826769748795968, -33.564236361619891],
              [-70.829362962860571, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 252,
        left: -7863904.0013708398,
        bottom: -3969438.7348916363,
        right: -7862749.3002940807,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.553007585956919],
              [-70.826769748795968, -33.549264336353268],
              [-70.821583320666761, -33.549264336353268],
              [-70.818990106602158, -33.553007585956919],
              [-70.821583320666761, -33.556750673375213],
              [-70.826769748795968, -33.556750673375213],
              [-70.829362962860571, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 253,
        left: -7863904.0013708398,
        bottom: -3968438.7344253859,
        right: -7862749.3002940807,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.545520924573204],
              [-70.826769748795968, -33.541777350625665],
              [-70.821583320666761, -33.541777350625665],
              [-70.818990106602158, -33.545520924573204],
              [-70.821583320666761, -33.549264336353268],
              [-70.826769748795968, -33.549264336353268],
              [-70.829362962860571, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 254,
        left: -7863904.0013708398,
        bottom: -3967438.7339591356,
        right: -7862749.3002940807,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.538033614519627],
              [-70.826769748795968, -33.534289716264041],
              [-70.821583320666761, -33.534289716264041],
              [-70.818990106602158, -33.538033614519627],
              [-70.821583320666761, -33.541777350625665],
              [-70.826769748795968, -33.541777350625665],
              [-70.829362962860571, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 255,
        left: -7863904.0013708398,
        bottom: -3966438.7334928853,
        right: -7862749.3002940807,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.530545655867876],
              [-70.826769748795968, -33.52680143334009],
              [-70.821583320666761, -33.52680143334009],
              [-70.818990106602158, -33.530545655867876],
              [-70.821583320666761, -33.534289716264041],
              [-70.826769748795968, -33.534289716264041],
              [-70.829362962860571, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 256,
        left: -7863904.0013708398,
        bottom: -3965438.7330266349,
        right: -7862749.3002940807,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.523057048689658],
              [-70.826769748795968, -33.519312501925548],
              [-70.821583320666761, -33.519312501925548],
              [-70.818990106602158, -33.523057048689658],
              [-70.821583320666761, -33.52680143334009],
              [-70.826769748795968, -33.52680143334009],
              [-70.829362962860571, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 257,
        left: -7863904.0013708398,
        bottom: -3964438.7325603846,
        right: -7862749.3002940807,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.515567793056739],
              [-70.826769748795968, -33.511822922092215],
              [-70.821583320666761, -33.511822922092215],
              [-70.818990106602158, -33.515567793056739],
              [-70.821583320666761, -33.519312501925548],
              [-70.826769748795968, -33.519312501925548],
              [-70.829362962860571, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 258,
        left: -7863904.0013708398,
        bottom: -3963438.7320941342,
        right: -7862749.3002940807,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.508077889040948],
              [-70.826769748795968, -33.504332693911934],
              [-70.821583320666761, -33.504332693911934],
              [-70.818990106602158, -33.508077889040948],
              [-70.821583320666761, -33.511822922092215],
              [-70.826769748795968, -33.511822922092215],
              [-70.829362962860571, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 259,
        left: -7863904.0013708398,
        bottom: -3962438.7316278839,
        right: -7862749.3002940807,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.500587336714155],
              [-70.826769748795968, -33.496841817456605],
              [-70.821583320666761, -33.496841817456605],
              [-70.818990106602158, -33.500587336714155],
              [-70.821583320666761, -33.504332693911934],
              [-70.826769748795968, -33.504332693911934],
              [-70.829362962860571, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 260,
        left: -7863904.0013708398,
        bottom: -3961438.7311616335,
        right: -7862749.3002940807,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.493096136148274],
              [-70.826769748795968, -33.489350292798164],
              [-70.821583320666761, -33.489350292798164],
              [-70.818990106602158, -33.493096136148274],
              [-70.821583320666761, -33.496841817456605],
              [-70.826769748795968, -33.496841817456605],
              [-70.829362962860571, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 261,
        left: -7863904.0013708398,
        bottom: -3960438.7306953832,
        right: -7862749.3002940807,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.485604287415278],
              [-70.826769748795968, -33.481858120008617],
              [-70.821583320666761, -33.481858120008617],
              [-70.818990106602158, -33.485604287415278],
              [-70.821583320666761, -33.489350292798164],
              [-70.826769748795968, -33.489350292798164],
              [-70.829362962860571, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 262,
        left: -7863904.0013708398,
        bottom: -3959438.7302291333,
        right: -7862749.3002940807,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.478111790587178],
              [-70.826769748795968, -33.474365299160006],
              [-70.821583320666761, -33.474365299160006],
              [-70.818990106602158, -33.478111790587178],
              [-70.821583320666761, -33.481858120008617],
              [-70.826769748795968, -33.481858120008617],
              [-70.829362962860571, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 263,
        left: -7863904.0013708398,
        bottom: -3958438.7297628829,
        right: -7862749.3002940807,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.47061864573606],
              [-70.826769748795968, -33.466871830324415],
              [-70.821583320666761, -33.466871830324415],
              [-70.818990106602158, -33.47061864573606],
              [-70.821583320666761, -33.474365299160006],
              [-70.826769748795968, -33.474365299160006],
              [-70.829362962860571, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 264,
        left: -7863904.0013708398,
        bottom: -3957438.7292966326,
        right: -7862749.3002940807,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.463124852934051],
              [-70.826769748795968, -33.459377713574014],
              [-70.821583320666761, -33.459377713574014],
              [-70.818990106602158, -33.463124852934051],
              [-70.821583320666761, -33.466871830324415],
              [-70.826769748795968, -33.466871830324415],
              [-70.829362962860571, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 265,
        left: -7863904.0013708398,
        bottom: -3956438.7288303822,
        right: -7862749.3002940807,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.455630412253299],
              [-70.826769748795968, -33.45188294898098],
              [-70.821583320666761, -33.45188294898098],
              [-70.818990106602158, -33.455630412253299],
              [-70.821583320666761, -33.459377713574014],
              [-70.826769748795968, -33.459377713574014],
              [-70.829362962860571, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 266,
        left: -7863904.0013708398,
        bottom: -3955438.7283641323,
        right: -7862749.3002940807,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.448135323766053],
              [-70.826769748795968, -33.444387536617576],
              [-70.821583320666761, -33.444387536617576],
              [-70.818990106602158, -33.448135323766053],
              [-70.821583320666761, -33.45188294898098],
              [-70.826769748795968, -33.45188294898098],
              [-70.829362962860571, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 267,
        left: -7863904.0013708398,
        bottom: -3954438.7278978815,
        right: -7862749.3002940807,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.440639587544567],
              [-70.826769748795968, -33.436891476556099],
              [-70.821583320666761, -33.436891476556099],
              [-70.818990106602158, -33.440639587544567],
              [-70.821583320666761, -33.444387536617576],
              [-70.826769748795968, -33.444387536617576],
              [-70.829362962860571, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 268,
        left: -7863904.0013708398,
        bottom: -3953438.7274316312,
        right: -7862749.3002940807,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.433143203661182],
              [-70.826769748795968, -33.429394768868896],
              [-70.821583320666761, -33.429394768868896],
              [-70.818990106602158, -33.433143203661182],
              [-70.821583320666761, -33.436891476556099],
              [-70.826769748795968, -33.436891476556099],
              [-70.829362962860571, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 269,
        left: -7863904.0013708398,
        bottom: -3952438.7269653808,
        right: -7862749.3002940807,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.425646172188266],
              [-70.826769748795968, -33.421897413628365],
              [-70.821583320666761, -33.421897413628365],
              [-70.818990106602158, -33.425646172188266],
              [-70.821583320666761, -33.429394768868896],
              [-70.826769748795968, -33.429394768868896],
              [-70.829362962860571, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 270,
        left: -7863904.0013708398,
        bottom: -3951438.7264991305,
        right: -7862749.3002940807,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.418148493198238],
              [-70.826769748795968, -33.414399410906967],
              [-70.821583320666761, -33.414399410906967],
              [-70.818990106602158, -33.418148493198238],
              [-70.821583320666761, -33.421897413628365],
              [-70.826769748795968, -33.421897413628365],
              [-70.829362962860571, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 271,
        left: -7863904.0013708398,
        bottom: -3950438.7260328801,
        right: -7862749.3002940807,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.410650166763581],
              [-70.826769748795968, -33.406900760777191],
              [-70.821583320666761, -33.406900760777191],
              [-70.818990106602158, -33.410650166763581],
              [-70.821583320666761, -33.414399410906967],
              [-70.826769748795968, -33.414399410906967],
              [-70.829362962860571, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 272,
        left: -7863904.0013708398,
        bottom: -3949438.7255666298,
        right: -7862749.3002940807,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.403151192956834],
              [-70.826769748795968, -33.399401463311598],
              [-70.821583320666761, -33.399401463311598],
              [-70.818990106602158, -33.403151192956834],
              [-70.821583320666761, -33.406900760777191],
              [-70.826769748795968, -33.406900760777191],
              [-70.829362962860571, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 273,
        left: -7863904.0013708398,
        bottom: -3948438.7251003794,
        right: -7862749.3002940807,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.39565157185055],
              [-70.826769748795968, -33.391901518582785],
              [-70.821583320666761, -33.391901518582785],
              [-70.818990106602158, -33.39565157185055],
              [-70.821583320666761, -33.399401463311598],
              [-70.826769748795968, -33.399401463311598],
              [-70.829362962860571, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 274,
        left: -7863904.0013708398,
        bottom: -3947438.7246341291,
        right: -7862749.3002940807,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.388151303517375],
              [-70.826769748795968, -33.384400926663417],
              [-70.821583320666761, -33.384400926663417],
              [-70.818990106602158, -33.388151303517375],
              [-70.821583320666761, -33.391901518582785],
              [-70.826769748795968, -33.391901518582785],
              [-70.829362962860571, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 275,
        left: -7863904.0013708398,
        bottom: -3946438.7241678787,
        right: -7862749.3002940807,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.380650388029984],
              [-70.826769748795968, -33.376899687626185],
              [-70.821583320666761, -33.376899687626185],
              [-70.818990106602158, -33.380650388029984],
              [-70.821583320666761, -33.384400926663417],
              [-70.826769748795968, -33.384400926663417],
              [-70.829362962860571, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 276,
        left: -7863904.0013708398,
        bottom: -3945438.7237016284,
        right: -7862749.3002940807,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.373148825461101],
              [-70.826769748795968, -33.369397801543847],
              [-70.821583320666761, -33.369397801543847],
              [-70.818990106602158, -33.373148825461101],
              [-70.821583320666761, -33.376899687626185],
              [-70.826769748795968, -33.376899687626185],
              [-70.829362962860571, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 277,
        left: -7863904.0013708398,
        bottom: -3944438.723235378,
        right: -7862749.3002940807,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.365646615883513],
              [-70.826769748795968, -33.361895268489214],
              [-70.821583320666761, -33.361895268489214],
              [-70.818990106602158, -33.365646615883513],
              [-70.821583320666761, -33.369397801543847],
              [-70.826769748795968, -33.369397801543847],
              [-70.829362962860571, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 278,
        left: -7863904.0013708398,
        bottom: -3943438.7227691277,
        right: -7862749.3002940807,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.358143759370044],
              [-70.826769748795968, -33.354392088535135],
              [-70.821583320666761, -33.354392088535135],
              [-70.818990106602158, -33.358143759370044],
              [-70.821583320666761, -33.361895268489214],
              [-70.826769748795968, -33.361895268489214],
              [-70.829362962860571, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 279,
        left: -7863904.0013708398,
        bottom: -3942438.7223028769,
        right: -7862749.3002940807,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.350640255993589],
              [-70.826769748795968, -33.346888261754529],
              [-70.821583320666761, -33.346888261754529],
              [-70.818990106602158, -33.350640255993589],
              [-70.821583320666761, -33.354392088535135],
              [-70.826769748795968, -33.354392088535135],
              [-70.829362962860571, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 280,
        left: -7863904.0013708398,
        bottom: -3941438.721836627,
        right: -7862749.3002940807,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.343136105827064],
              [-70.826769748795968, -33.339383788220331],
              [-70.821583320666761, -33.339383788220331],
              [-70.818990106602158, -33.343136105827064],
              [-70.821583320666761, -33.346888261754529],
              [-70.826769748795968, -33.346888261754529],
              [-70.829362962860571, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 281,
        left: -7863904.0013708398,
        bottom: -3940438.7213703771,
        right: -7862749.3002940807,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.335631308943462],
              [-70.826769748795968, -33.331878668005565],
              [-70.821583320666761, -33.331878668005565],
              [-70.818990106602158, -33.335631308943462],
              [-70.821583320666761, -33.339383788220331],
              [-70.826769748795968, -33.339383788220331],
              [-70.829362962860571, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 282,
        left: -7863904.0013708398,
        bottom: -3939438.7209041263,
        right: -7862749.3002940807,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.328125865415807],
              [-70.826769748795968, -33.324372901183303],
              [-70.821583320666761, -33.324372901183303],
              [-70.818990106602158, -33.328125865415807],
              [-70.821583320666761, -33.331878668005565],
              [-70.826769748795968, -33.331878668005565],
              [-70.829362962860571, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 283,
        left: -7863904.0013708398,
        bottom: -3938438.7204378759,
        right: -7862749.3002940807,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.320619775317184],
              [-70.826769748795968, -33.31686648782663],
              [-70.821583320666761, -33.31686648782663],
              [-70.818990106602158, -33.320619775317184],
              [-70.821583320666761, -33.324372901183303],
              [-70.826769748795968, -33.324372901183303],
              [-70.829362962860571, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 284,
        left: -7863904.0013708398,
        bottom: -3937438.7199716261,
        right: -7862749.3002940807,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.313113038720743],
              [-70.826769748795968, -33.309359428008712],
              [-70.821583320666761, -33.309359428008712],
              [-70.818990106602158, -33.313113038720743],
              [-70.821583320666761, -33.31686648782663],
              [-70.826769748795968, -33.31686648782663],
              [-70.829362962860571, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 285,
        left: -7863904.0013708398,
        bottom: -3936438.7195053762,
        right: -7862749.3002940807,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.305605655699658],
              [-70.826769748795968, -33.301851721802763],
              [-70.821583320666761, -33.301851721802763],
              [-70.818990106602158, -33.305605655699658],
              [-70.821583320666761, -33.309359428008712],
              [-70.826769748795968, -33.309359428008712],
              [-70.829362962860571, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 286,
        left: -7863904.0013708398,
        bottom: -3935438.7190391254,
        right: -7862749.3002940807,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.298097626327156],
              [-70.826769748795968, -33.294343369282039],
              [-70.821583320666761, -33.294343369282039],
              [-70.818990106602158, -33.298097626327156],
              [-70.821583320666761, -33.301851721802763],
              [-70.826769748795968, -33.301851721802763],
              [-70.829362962860571, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 287,
        left: -7863904.0013708398,
        bottom: -3934438.718572875,
        right: -7862749.3002940807,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.29058895067655],
              [-70.826769748795968, -33.286834370519863],
              [-70.821583320666761, -33.286834370519863],
              [-70.818990106602158, -33.29058895067655],
              [-70.821583320666761, -33.294343369282039],
              [-70.826769748795968, -33.294343369282039],
              [-70.829362962860571, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 288,
        left: -7863904.0013708398,
        bottom: -3933438.7181066247,
        right: -7862749.3002940807,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.829362962860571, -33.283079628821156],
              [-70.826769748795968, -33.279324725589596],
              [-70.821583320666761, -33.279324725589596],
              [-70.818990106602158, -33.283079628821156],
              [-70.821583320666761, -33.286834370519863],
              [-70.826769748795968, -33.286834370519863],
              [-70.829362962860571, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 289,
        left: -7863037.9755632691,
        bottom: -3980938.7402535151,
        right: -7861883.2744865119,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.639057545603663],
              [-70.818990106602158, -33.635318028521546],
              [-70.813803678472951, -33.635318028521546],
              [-70.811210464408347, -33.639057545603663],
              [-70.813803678472951, -33.642796900295409],
              [-70.818990106602158, -33.642796900295409],
              [-70.821583320666761, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 290,
        left: -7863037.9755632691,
        bottom: -3979938.7397872647,
        right: -7861883.2744865119,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.631578349057953],
              [-70.818990106602158, -33.627838507221767],
              [-70.813803678472951, -33.627838507221767],
              [-70.811210464408347, -33.631578349057953],
              [-70.813803678472951, -33.635318028521546],
              [-70.818990106602158, -33.635318028521546],
              [-70.821583320666761, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 291,
        left: -7863037.9755632691,
        bottom: -3978938.7393210144,
        right: -7861883.2744865119,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.624098503021855],
              [-70.818990106602158, -33.620358336467127],
              [-70.813803678472951, -33.620358336467127],
              [-70.811210464408347, -33.624098503021855],
              [-70.813803678472951, -33.627838507221767],
              [-70.818990106602158, -33.627838507221767],
              [-70.821583320666761, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 292,
        left: -7863037.9755632691,
        bottom: -3977938.738854764,
        right: -7861883.2744865119,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.616618007566458],
              [-70.818990106602158, -33.612877516328737],
              [-70.813803678472951, -33.612877516328737],
              [-70.811210464408347, -33.616618007566458],
              [-70.813803678472951, -33.620358336467127],
              [-70.818990106602158, -33.620358336467127],
              [-70.821583320666761, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 293,
        left: -7863037.9755632691,
        bottom: -3976938.7383885137,
        right: -7861883.2744865119,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.609136862762881],
              [-70.818990106602158, -33.605396046877765],
              [-70.813803678472951, -33.605396046877765],
              [-70.811210464408347, -33.609136862762881],
              [-70.813803678472951, -33.612877516328737],
              [-70.818990106602158, -33.612877516328737],
              [-70.821583320666761, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 294,
        left: -7863037.9755632691,
        bottom: -3975938.7379222633,
        right: -7861883.2744865119,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.601655068682327],
              [-70.818990106602158, -33.597913928185434],
              [-70.813803678472951, -33.597913928185434],
              [-70.811210464408347, -33.601655068682327],
              [-70.813803678472951, -33.605396046877765],
              [-70.818990106602158, -33.605396046877765],
              [-70.821583320666761, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 295,
        left: -7863037.9755632691,
        bottom: -3974938.737456013,
        right: -7861883.2744865119,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.594172625396013],
              [-70.818990106602158, -33.590431160322979],
              [-70.813803678472951, -33.590431160322979],
              [-70.811210464408347, -33.594172625396013],
              [-70.813803678472951, -33.597913928185434],
              [-70.818990106602158, -33.597913928185434],
              [-70.821583320666761, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 296,
        left: -7863037.9755632691,
        bottom: -3973938.7369897626,
        right: -7861883.2744865119,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.586689532975264],
              [-70.818990106602158, -33.58294774336175],
              [-70.813803678472951, -33.58294774336175],
              [-70.811210464408347, -33.586689532975264],
              [-70.813803678472951, -33.590431160322979],
              [-70.818990106602158, -33.590431160322979],
              [-70.821583320666761, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 297,
        left: -7863037.9755632691,
        bottom: -3972938.7365235123,
        right: -7861883.2744865119,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.579205791491383],
              [-70.818990106602158, -33.575463677373087],
              [-70.813803678472951, -33.575463677373087],
              [-70.811210464408347, -33.579205791491383],
              [-70.813803678472951, -33.58294774336175],
              [-70.818990106602158, -33.58294774336175],
              [-70.821583320666761, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 298,
        left: -7863037.9755632691,
        bottom: -3971938.7360572619,
        right: -7861883.2744865119,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.571721401015779],
              [-70.818990106602158, -33.567978962428406],
              [-70.813803678472951, -33.567978962428406],
              [-70.811210464408347, -33.571721401015779],
              [-70.813803678472951, -33.575463677373087],
              [-70.818990106602158, -33.575463677373087],
              [-70.821583320666761, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 299,
        left: -7863037.9755632691,
        bottom: -3970938.7355910116,
        right: -7861883.2744865119,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.564236361619891],
              [-70.818990106602158, -33.56049359859918],
              [-70.813803678472951, -33.56049359859918],
              [-70.811210464408347, -33.564236361619891],
              [-70.813803678472951, -33.567978962428406],
              [-70.818990106602158, -33.567978962428406],
              [-70.821583320666761, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 300,
        left: -7863037.9755632691,
        bottom: -3969938.7351247617,
        right: -7861883.2744865119,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.556750673375213],
              [-70.818990106602158, -33.553007585956919],
              [-70.813803678472951, -33.553007585956919],
              [-70.811210464408347, -33.556750673375213],
              [-70.813803678472951, -33.56049359859918],
              [-70.818990106602158, -33.56049359859918],
              [-70.821583320666761, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 301,
        left: -7863037.9755632691,
        bottom: -3968938.7346585114,
        right: -7861883.2744865119,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.549264336353268],
              [-70.818990106602158, -33.545520924573204],
              [-70.813803678472951, -33.545520924573204],
              [-70.811210464408347, -33.549264336353268],
              [-70.813803678472951, -33.553007585956919],
              [-70.818990106602158, -33.553007585956919],
              [-70.821583320666761, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 302,
        left: -7863037.9755632691,
        bottom: -3967938.734192261,
        right: -7861883.2744865119,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.541777350625665],
              [-70.818990106602158, -33.538033614519627],
              [-70.813803678472951, -33.538033614519627],
              [-70.811210464408347, -33.541777350625665],
              [-70.813803678472951, -33.545520924573204],
              [-70.818990106602158, -33.545520924573204],
              [-70.821583320666761, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 303,
        left: -7863037.9755632691,
        bottom: -3966938.7337260107,
        right: -7861883.2744865119,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.534289716264041],
              [-70.818990106602158, -33.530545655867876],
              [-70.813803678472951, -33.530545655867876],
              [-70.811210464408347, -33.534289716264041],
              [-70.813803678472951, -33.538033614519627],
              [-70.818990106602158, -33.538033614519627],
              [-70.821583320666761, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 304,
        left: -7863037.9755632691,
        bottom: -3965938.7332597603,
        right: -7861883.2744865119,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.52680143334009],
              [-70.818990106602158, -33.523057048689658],
              [-70.813803678472951, -33.523057048689658],
              [-70.811210464408347, -33.52680143334009],
              [-70.813803678472951, -33.530545655867876],
              [-70.818990106602158, -33.530545655867876],
              [-70.821583320666761, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 305,
        left: -7863037.9755632691,
        bottom: -3964938.7327935095,
        right: -7861883.2744865119,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.519312501925548],
              [-70.818990106602158, -33.515567793056739],
              [-70.813803678472951, -33.515567793056739],
              [-70.811210464408347, -33.519312501925548],
              [-70.813803678472951, -33.523057048689658],
              [-70.818990106602158, -33.523057048689658],
              [-70.821583320666761, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 306,
        left: -7863037.9755632691,
        bottom: -3963938.7323272596,
        right: -7861883.2744865119,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.511822922092215],
              [-70.818990106602158, -33.508077889040948],
              [-70.813803678472951, -33.508077889040948],
              [-70.811210464408347, -33.511822922092215],
              [-70.813803678472951, -33.515567793056739],
              [-70.818990106602158, -33.515567793056739],
              [-70.821583320666761, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 307,
        left: -7863037.9755632691,
        bottom: -3962938.7318610097,
        right: -7861883.2744865119,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.504332693911934],
              [-70.818990106602158, -33.500587336714155],
              [-70.813803678472951, -33.500587336714155],
              [-70.811210464408347, -33.504332693911934],
              [-70.813803678472951, -33.508077889040948],
              [-70.818990106602158, -33.508077889040948],
              [-70.821583320666761, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 308,
        left: -7863037.9755632691,
        bottom: -3961938.7313947589,
        right: -7861883.2744865119,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.496841817456605],
              [-70.818990106602158, -33.493096136148274],
              [-70.813803678472951, -33.493096136148274],
              [-70.811210464408347, -33.496841817456605],
              [-70.813803678472951, -33.500587336714155],
              [-70.818990106602158, -33.500587336714155],
              [-70.821583320666761, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 309,
        left: -7863037.9755632691,
        bottom: -3960938.7309285086,
        right: -7861883.2744865119,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.489350292798164],
              [-70.818990106602158, -33.485604287415278],
              [-70.813803678472951, -33.485604287415278],
              [-70.811210464408347, -33.489350292798164],
              [-70.813803678472951, -33.493096136148274],
              [-70.818990106602158, -33.493096136148274],
              [-70.821583320666761, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 310,
        left: -7863037.9755632691,
        bottom: -3959938.7304622582,
        right: -7861883.2744865119,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.481858120008617],
              [-70.818990106602158, -33.478111790587178],
              [-70.813803678472951, -33.478111790587178],
              [-70.811210464408347, -33.481858120008617],
              [-70.813803678472951, -33.485604287415278],
              [-70.818990106602158, -33.485604287415278],
              [-70.821583320666761, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 311,
        left: -7863037.9755632691,
        bottom: -3958938.7299960079,
        right: -7861883.2744865119,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.474365299160006],
              [-70.818990106602158, -33.47061864573606],
              [-70.813803678472951, -33.47061864573606],
              [-70.811210464408347, -33.474365299160006],
              [-70.813803678472951, -33.478111790587178],
              [-70.818990106602158, -33.478111790587178],
              [-70.821583320666761, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 312,
        left: -7863037.9755632691,
        bottom: -3957938.7295297575,
        right: -7861883.2744865119,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.466871830324415],
              [-70.818990106602158, -33.463124852934051],
              [-70.813803678472951, -33.463124852934051],
              [-70.811210464408347, -33.466871830324415],
              [-70.813803678472951, -33.47061864573606],
              [-70.818990106602158, -33.47061864573606],
              [-70.821583320666761, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 313,
        left: -7863037.9755632691,
        bottom: -3956938.7290635072,
        right: -7861883.2744865119,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.459377713574014],
              [-70.818990106602158, -33.455630412253299],
              [-70.813803678472951, -33.455630412253299],
              [-70.811210464408347, -33.459377713574014],
              [-70.813803678472951, -33.463124852934051],
              [-70.818990106602158, -33.463124852934051],
              [-70.821583320666761, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 314,
        left: -7863037.9755632691,
        bottom: -3955938.7285972568,
        right: -7861883.2744865119,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.45188294898098],
              [-70.818990106602158, -33.448135323766053],
              [-70.813803678472951, -33.448135323766053],
              [-70.811210464408347, -33.45188294898098],
              [-70.813803678472951, -33.455630412253299],
              [-70.818990106602158, -33.455630412253299],
              [-70.821583320666761, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 315,
        left: -7863037.9755632691,
        bottom: -3954938.7281310065,
        right: -7861883.2744865119,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.444387536617576],
              [-70.818990106602158, -33.440639587544567],
              [-70.813803678472951, -33.440639587544567],
              [-70.811210464408347, -33.444387536617576],
              [-70.813803678472951, -33.448135323766053],
              [-70.818990106602158, -33.448135323766053],
              [-70.821583320666761, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 316,
        left: -7863037.9755632691,
        bottom: -3953938.7276647561,
        right: -7861883.2744865119,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.436891476556099],
              [-70.818990106602158, -33.433143203661182],
              [-70.813803678472951, -33.433143203661182],
              [-70.811210464408347, -33.436891476556099],
              [-70.813803678472951, -33.440639587544567],
              [-70.818990106602158, -33.440639587544567],
              [-70.821583320666761, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 317,
        left: -7863037.9755632691,
        bottom: -3952938.7271985058,
        right: -7861883.2744865119,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.429394768868896],
              [-70.818990106602158, -33.425646172188266],
              [-70.813803678472951, -33.425646172188266],
              [-70.811210464408347, -33.429394768868896],
              [-70.813803678472951, -33.433143203661182],
              [-70.818990106602158, -33.433143203661182],
              [-70.821583320666761, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 318,
        left: -7863037.9755632691,
        bottom: -3951938.7267322554,
        right: -7861883.2744865119,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.421897413628365],
              [-70.818990106602158, -33.418148493198238],
              [-70.813803678472951, -33.418148493198238],
              [-70.811210464408347, -33.421897413628365],
              [-70.813803678472951, -33.425646172188266],
              [-70.818990106602158, -33.425646172188266],
              [-70.821583320666761, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 319,
        left: -7863037.9755632691,
        bottom: -3950938.7262660051,
        right: -7861883.2744865119,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.414399410906967],
              [-70.818990106602158, -33.410650166763581],
              [-70.813803678472951, -33.410650166763581],
              [-70.811210464408347, -33.414399410906967],
              [-70.813803678472951, -33.418148493198238],
              [-70.818990106602158, -33.418148493198238],
              [-70.821583320666761, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 320,
        left: -7863037.9755632691,
        bottom: -3949938.7257997552,
        right: -7861883.2744865119,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.406900760777191],
              [-70.818990106602158, -33.403151192956834],
              [-70.813803678472951, -33.403151192956834],
              [-70.811210464408347, -33.406900760777191],
              [-70.813803678472951, -33.410650166763581],
              [-70.818990106602158, -33.410650166763581],
              [-70.821583320666761, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 321,
        left: -7863037.9755632691,
        bottom: -3948938.7253335048,
        right: -7861883.2744865119,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.399401463311598],
              [-70.818990106602158, -33.39565157185055],
              [-70.813803678472951, -33.39565157185055],
              [-70.811210464408347, -33.399401463311598],
              [-70.813803678472951, -33.403151192956834],
              [-70.818990106602158, -33.403151192956834],
              [-70.821583320666761, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 322,
        left: -7863037.9755632691,
        bottom: -3947938.724867254,
        right: -7861883.2744865119,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.391901518582785],
              [-70.818990106602158, -33.388151303517375],
              [-70.813803678472951, -33.388151303517375],
              [-70.811210464408347, -33.391901518582785],
              [-70.813803678472951, -33.39565157185055],
              [-70.818990106602158, -33.39565157185055],
              [-70.821583320666761, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 323,
        left: -7863037.9755632691,
        bottom: -3946938.7244010041,
        right: -7861883.2744865119,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.384400926663417],
              [-70.818990106602158, -33.380650388029984],
              [-70.813803678472951, -33.380650388029984],
              [-70.811210464408347, -33.384400926663417],
              [-70.813803678472951, -33.388151303517375],
              [-70.818990106602158, -33.388151303517375],
              [-70.821583320666761, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 324,
        left: -7863037.9755632691,
        bottom: -3945938.7239347543,
        right: -7861883.2744865119,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.376899687626185],
              [-70.818990106602158, -33.373148825461101],
              [-70.813803678472951, -33.373148825461101],
              [-70.811210464408347, -33.376899687626185],
              [-70.813803678472951, -33.380650388029984],
              [-70.818990106602158, -33.380650388029984],
              [-70.821583320666761, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 325,
        left: -7863037.9755632691,
        bottom: -3944938.7234685034,
        right: -7861883.2744865119,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.369397801543847],
              [-70.818990106602158, -33.365646615883513],
              [-70.813803678472951, -33.365646615883513],
              [-70.811210464408347, -33.369397801543847],
              [-70.813803678472951, -33.373148825461101],
              [-70.818990106602158, -33.373148825461101],
              [-70.821583320666761, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 326,
        left: -7863037.9755632691,
        bottom: -3943938.7230022531,
        right: -7861883.2744865119,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.361895268489214],
              [-70.818990106602158, -33.358143759370044],
              [-70.813803678472951, -33.358143759370044],
              [-70.811210464408347, -33.361895268489214],
              [-70.813803678472951, -33.365646615883513],
              [-70.818990106602158, -33.365646615883513],
              [-70.821583320666761, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 327,
        left: -7863037.9755632691,
        bottom: -3942938.7225360028,
        right: -7861883.2744865119,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.354392088535135],
              [-70.818990106602158, -33.350640255993589],
              [-70.813803678472951, -33.350640255993589],
              [-70.811210464408347, -33.354392088535135],
              [-70.813803678472951, -33.358143759370044],
              [-70.818990106602158, -33.358143759370044],
              [-70.821583320666761, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 328,
        left: -7863037.9755632691,
        bottom: -3941938.7220697524,
        right: -7861883.2744865119,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.346888261754529],
              [-70.818990106602158, -33.343136105827064],
              [-70.813803678472951, -33.343136105827064],
              [-70.811210464408347, -33.346888261754529],
              [-70.813803678472951, -33.350640255993589],
              [-70.818990106602158, -33.350640255993589],
              [-70.821583320666761, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 329,
        left: -7863037.9755632691,
        bottom: -3940938.7216035021,
        right: -7861883.2744865119,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.339383788220331],
              [-70.818990106602158, -33.335631308943462],
              [-70.813803678472951, -33.335631308943462],
              [-70.811210464408347, -33.339383788220331],
              [-70.813803678472951, -33.343136105827064],
              [-70.818990106602158, -33.343136105827064],
              [-70.821583320666761, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 330,
        left: -7863037.9755632691,
        bottom: -3939938.7211372517,
        right: -7861883.2744865119,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.331878668005565],
              [-70.818990106602158, -33.328125865415807],
              [-70.813803678472951, -33.328125865415807],
              [-70.811210464408347, -33.331878668005565],
              [-70.813803678472951, -33.335631308943462],
              [-70.818990106602158, -33.335631308943462],
              [-70.821583320666761, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 331,
        left: -7863037.9755632691,
        bottom: -3938938.7206710014,
        right: -7861883.2744865119,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.324372901183303],
              [-70.818990106602158, -33.320619775317184],
              [-70.813803678472951, -33.320619775317184],
              [-70.811210464408347, -33.324372901183303],
              [-70.813803678472951, -33.328125865415807],
              [-70.818990106602158, -33.328125865415807],
              [-70.821583320666761, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 332,
        left: -7863037.9755632691,
        bottom: -3937938.720204751,
        right: -7861883.2744865119,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.31686648782663],
              [-70.818990106602158, -33.313113038720743],
              [-70.813803678472951, -33.313113038720743],
              [-70.811210464408347, -33.31686648782663],
              [-70.813803678472951, -33.320619775317184],
              [-70.818990106602158, -33.320619775317184],
              [-70.821583320666761, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 333,
        left: -7863037.9755632691,
        bottom: -3936938.7197385007,
        right: -7861883.2744865119,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.309359428008712],
              [-70.818990106602158, -33.305605655699658],
              [-70.813803678472951, -33.305605655699658],
              [-70.811210464408347, -33.309359428008712],
              [-70.813803678472951, -33.313113038720743],
              [-70.818990106602158, -33.313113038720743],
              [-70.821583320666761, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 334,
        left: -7863037.9755632691,
        bottom: -3935938.7192722503,
        right: -7861883.2744865119,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.301851721802763],
              [-70.818990106602158, -33.298097626327156],
              [-70.813803678472951, -33.298097626327156],
              [-70.811210464408347, -33.301851721802763],
              [-70.813803678472951, -33.305605655699658],
              [-70.818990106602158, -33.305605655699658],
              [-70.821583320666761, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 335,
        left: -7863037.9755632691,
        bottom: -3934938.718806,
        right: -7861883.2744865119,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.294343369282039],
              [-70.818990106602158, -33.29058895067655],
              [-70.813803678472951, -33.29058895067655],
              [-70.811210464408347, -33.294343369282039],
              [-70.813803678472951, -33.298097626327156],
              [-70.818990106602158, -33.298097626327156],
              [-70.821583320666761, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 336,
        left: -7863037.9755632691,
        bottom: -3933938.7183397496,
        right: -7861883.2744865119,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.821583320666761, -33.286834370519863],
              [-70.818990106602158, -33.283079628821156],
              [-70.813803678472951, -33.283079628821156],
              [-70.811210464408347, -33.286834370519863],
              [-70.813803678472951, -33.29058895067655],
              [-70.818990106602158, -33.29058895067655],
              [-70.821583320666761, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 337,
        left: -7862171.9497557012,
        bottom: -3980438.7400203901,
        right: -7861017.2486789431,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.635318028521546],
              [-70.811210464408347, -33.631578349057953],
              [-70.80602403627914, -33.631578349057953],
              [-70.803430822214537, -33.635318028521546],
              [-70.80602403627914, -33.639057545603663],
              [-70.811210464408347, -33.639057545603663],
              [-70.813803678472951, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 338,
        left: -7862171.9497557012,
        bottom: -3979438.7395541398,
        right: -7861017.2486789431,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.627838507221767],
              [-70.811210464408347, -33.624098503021855],
              [-70.80602403627914, -33.624098503021855],
              [-70.803430822214537, -33.627838507221767],
              [-70.80602403627914, -33.631578349057953],
              [-70.811210464408347, -33.631578349057953],
              [-70.813803678472951, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 339,
        left: -7862171.9497557012,
        bottom: -3978438.7390878894,
        right: -7861017.2486789431,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.620358336467127],
              [-70.811210464408347, -33.616618007566458],
              [-70.80602403627914, -33.616618007566458],
              [-70.803430822214537, -33.620358336467127],
              [-70.80602403627914, -33.624098503021855],
              [-70.811210464408347, -33.624098503021855],
              [-70.813803678472951, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 340,
        left: -7862171.9497557012,
        bottom: -3977438.7386216391,
        right: -7861017.2486789431,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.612877516328737],
              [-70.811210464408347, -33.609136862762881],
              [-70.80602403627914, -33.609136862762881],
              [-70.803430822214537, -33.612877516328737],
              [-70.80602403627914, -33.616618007566458],
              [-70.811210464408347, -33.616618007566458],
              [-70.813803678472951, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 341,
        left: -7862171.9497557012,
        bottom: -3976438.7381553887,
        right: -7861017.2486789431,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.605396046877765],
              [-70.811210464408347, -33.601655068682327],
              [-70.80602403627914, -33.601655068682327],
              [-70.803430822214537, -33.605396046877765],
              [-70.80602403627914, -33.609136862762881],
              [-70.811210464408347, -33.609136862762881],
              [-70.813803678472951, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 342,
        left: -7862171.9497557012,
        bottom: -3975438.7376891384,
        right: -7861017.2486789431,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.597913928185434],
              [-70.811210464408347, -33.594172625396013],
              [-70.80602403627914, -33.594172625396013],
              [-70.803430822214537, -33.597913928185434],
              [-70.80602403627914, -33.601655068682327],
              [-70.811210464408347, -33.601655068682327],
              [-70.813803678472951, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 343,
        left: -7862171.9497557012,
        bottom: -3974438.7372228876,
        right: -7861017.2486789431,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.590431160322979],
              [-70.811210464408347, -33.586689532975264],
              [-70.80602403627914, -33.586689532975264],
              [-70.803430822214537, -33.590431160322979],
              [-70.80602403627914, -33.594172625396013],
              [-70.811210464408347, -33.594172625396013],
              [-70.813803678472951, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 344,
        left: -7862171.9497557012,
        bottom: -3973438.7367566377,
        right: -7861017.2486789431,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.58294774336175],
              [-70.811210464408347, -33.579205791491383],
              [-70.80602403627914, -33.579205791491383],
              [-70.803430822214537, -33.58294774336175],
              [-70.80602403627914, -33.586689532975264],
              [-70.811210464408347, -33.586689532975264],
              [-70.813803678472951, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 345,
        left: -7862171.9497557012,
        bottom: -3972438.7362903878,
        right: -7861017.2486789431,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.575463677373087],
              [-70.811210464408347, -33.571721401015779],
              [-70.80602403627914, -33.571721401015779],
              [-70.803430822214537, -33.575463677373087],
              [-70.80602403627914, -33.579205791491383],
              [-70.811210464408347, -33.579205791491383],
              [-70.813803678472951, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 346,
        left: -7862171.9497557012,
        bottom: -3971438.735824137,
        right: -7861017.2486789431,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.567978962428406],
              [-70.811210464408347, -33.564236361619891],
              [-70.80602403627914, -33.564236361619891],
              [-70.803430822214537, -33.567978962428406],
              [-70.80602403627914, -33.571721401015779],
              [-70.811210464408347, -33.571721401015779],
              [-70.813803678472951, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 347,
        left: -7862171.9497557012,
        bottom: -3970438.7353578866,
        right: -7861017.2486789431,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.56049359859918],
              [-70.811210464408347, -33.556750673375213],
              [-70.80602403627914, -33.556750673375213],
              [-70.803430822214537, -33.56049359859918],
              [-70.80602403627914, -33.564236361619891],
              [-70.811210464408347, -33.564236361619891],
              [-70.813803678472951, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 348,
        left: -7862171.9497557012,
        bottom: -3969438.7348916363,
        right: -7861017.2486789431,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.553007585956919],
              [-70.811210464408347, -33.549264336353268],
              [-70.80602403627914, -33.549264336353268],
              [-70.803430822214537, -33.553007585956919],
              [-70.80602403627914, -33.556750673375213],
              [-70.811210464408347, -33.556750673375213],
              [-70.813803678472951, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 349,
        left: -7862171.9497557012,
        bottom: -3968438.7344253859,
        right: -7861017.2486789431,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.545520924573204],
              [-70.811210464408347, -33.541777350625665],
              [-70.80602403627914, -33.541777350625665],
              [-70.803430822214537, -33.545520924573204],
              [-70.80602403627914, -33.549264336353268],
              [-70.811210464408347, -33.549264336353268],
              [-70.813803678472951, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 350,
        left: -7862171.9497557012,
        bottom: -3967438.7339591356,
        right: -7861017.2486789431,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.538033614519627],
              [-70.811210464408347, -33.534289716264041],
              [-70.80602403627914, -33.534289716264041],
              [-70.803430822214537, -33.538033614519627],
              [-70.80602403627914, -33.541777350625665],
              [-70.811210464408347, -33.541777350625665],
              [-70.813803678472951, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 351,
        left: -7862171.9497557012,
        bottom: -3966438.7334928853,
        right: -7861017.2486789431,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.530545655867876],
              [-70.811210464408347, -33.52680143334009],
              [-70.80602403627914, -33.52680143334009],
              [-70.803430822214537, -33.530545655867876],
              [-70.80602403627914, -33.534289716264041],
              [-70.811210464408347, -33.534289716264041],
              [-70.813803678472951, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 352,
        left: -7862171.9497557012,
        bottom: -3965438.7330266349,
        right: -7861017.2486789431,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.523057048689658],
              [-70.811210464408347, -33.519312501925548],
              [-70.80602403627914, -33.519312501925548],
              [-70.803430822214537, -33.523057048689658],
              [-70.80602403627914, -33.52680143334009],
              [-70.811210464408347, -33.52680143334009],
              [-70.813803678472951, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 353,
        left: -7862171.9497557012,
        bottom: -3964438.7325603846,
        right: -7861017.2486789431,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.515567793056739],
              [-70.811210464408347, -33.511822922092215],
              [-70.80602403627914, -33.511822922092215],
              [-70.803430822214537, -33.515567793056739],
              [-70.80602403627914, -33.519312501925548],
              [-70.811210464408347, -33.519312501925548],
              [-70.813803678472951, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 354,
        left: -7862171.9497557012,
        bottom: -3963438.7320941342,
        right: -7861017.2486789431,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.508077889040948],
              [-70.811210464408347, -33.504332693911934],
              [-70.80602403627914, -33.504332693911934],
              [-70.803430822214537, -33.508077889040948],
              [-70.80602403627914, -33.511822922092215],
              [-70.811210464408347, -33.511822922092215],
              [-70.813803678472951, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 355,
        left: -7862171.9497557012,
        bottom: -3962438.7316278839,
        right: -7861017.2486789431,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.500587336714155],
              [-70.811210464408347, -33.496841817456605],
              [-70.80602403627914, -33.496841817456605],
              [-70.803430822214537, -33.500587336714155],
              [-70.80602403627914, -33.504332693911934],
              [-70.811210464408347, -33.504332693911934],
              [-70.813803678472951, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 356,
        left: -7862171.9497557012,
        bottom: -3961438.7311616335,
        right: -7861017.2486789431,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.493096136148274],
              [-70.811210464408347, -33.489350292798164],
              [-70.80602403627914, -33.489350292798164],
              [-70.803430822214537, -33.493096136148274],
              [-70.80602403627914, -33.496841817456605],
              [-70.811210464408347, -33.496841817456605],
              [-70.813803678472951, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 357,
        left: -7862171.9497557012,
        bottom: -3960438.7306953832,
        right: -7861017.2486789431,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.485604287415278],
              [-70.811210464408347, -33.481858120008617],
              [-70.80602403627914, -33.481858120008617],
              [-70.803430822214537, -33.485604287415278],
              [-70.80602403627914, -33.489350292798164],
              [-70.811210464408347, -33.489350292798164],
              [-70.813803678472951, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 358,
        left: -7862171.9497557012,
        bottom: -3959438.7302291333,
        right: -7861017.2486789431,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.478111790587178],
              [-70.811210464408347, -33.474365299160006],
              [-70.80602403627914, -33.474365299160006],
              [-70.803430822214537, -33.478111790587178],
              [-70.80602403627914, -33.481858120008617],
              [-70.811210464408347, -33.481858120008617],
              [-70.813803678472951, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 359,
        left: -7862171.9497557012,
        bottom: -3958438.7297628829,
        right: -7861017.2486789431,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.47061864573606],
              [-70.811210464408347, -33.466871830324415],
              [-70.80602403627914, -33.466871830324415],
              [-70.803430822214537, -33.47061864573606],
              [-70.80602403627914, -33.474365299160006],
              [-70.811210464408347, -33.474365299160006],
              [-70.813803678472951, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 360,
        left: -7862171.9497557012,
        bottom: -3957438.7292966326,
        right: -7861017.2486789431,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.463124852934051],
              [-70.811210464408347, -33.459377713574014],
              [-70.80602403627914, -33.459377713574014],
              [-70.803430822214537, -33.463124852934051],
              [-70.80602403627914, -33.466871830324415],
              [-70.811210464408347, -33.466871830324415],
              [-70.813803678472951, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 361,
        left: -7862171.9497557012,
        bottom: -3956438.7288303822,
        right: -7861017.2486789431,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.455630412253299],
              [-70.811210464408347, -33.45188294898098],
              [-70.80602403627914, -33.45188294898098],
              [-70.803430822214537, -33.455630412253299],
              [-70.80602403627914, -33.459377713574014],
              [-70.811210464408347, -33.459377713574014],
              [-70.813803678472951, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 362,
        left: -7862171.9497557012,
        bottom: -3955438.7283641323,
        right: -7861017.2486789431,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.448135323766053],
              [-70.811210464408347, -33.444387536617576],
              [-70.80602403627914, -33.444387536617576],
              [-70.803430822214537, -33.448135323766053],
              [-70.80602403627914, -33.45188294898098],
              [-70.811210464408347, -33.45188294898098],
              [-70.813803678472951, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 363,
        left: -7862171.9497557012,
        bottom: -3954438.7278978815,
        right: -7861017.2486789431,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.440639587544567],
              [-70.811210464408347, -33.436891476556099],
              [-70.80602403627914, -33.436891476556099],
              [-70.803430822214537, -33.440639587544567],
              [-70.80602403627914, -33.444387536617576],
              [-70.811210464408347, -33.444387536617576],
              [-70.813803678472951, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 364,
        left: -7862171.9497557012,
        bottom: -3953438.7274316312,
        right: -7861017.2486789431,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.433143203661182],
              [-70.811210464408347, -33.429394768868896],
              [-70.80602403627914, -33.429394768868896],
              [-70.803430822214537, -33.433143203661182],
              [-70.80602403627914, -33.436891476556099],
              [-70.811210464408347, -33.436891476556099],
              [-70.813803678472951, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 365,
        left: -7862171.9497557012,
        bottom: -3952438.7269653808,
        right: -7861017.2486789431,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.425646172188266],
              [-70.811210464408347, -33.421897413628365],
              [-70.80602403627914, -33.421897413628365],
              [-70.803430822214537, -33.425646172188266],
              [-70.80602403627914, -33.429394768868896],
              [-70.811210464408347, -33.429394768868896],
              [-70.813803678472951, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 366,
        left: -7862171.9497557012,
        bottom: -3951438.7264991305,
        right: -7861017.2486789431,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.418148493198238],
              [-70.811210464408347, -33.414399410906967],
              [-70.80602403627914, -33.414399410906967],
              [-70.803430822214537, -33.418148493198238],
              [-70.80602403627914, -33.421897413628365],
              [-70.811210464408347, -33.421897413628365],
              [-70.813803678472951, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 367,
        left: -7862171.9497557012,
        bottom: -3950438.7260328801,
        right: -7861017.2486789431,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.410650166763581],
              [-70.811210464408347, -33.406900760777191],
              [-70.80602403627914, -33.406900760777191],
              [-70.803430822214537, -33.410650166763581],
              [-70.80602403627914, -33.414399410906967],
              [-70.811210464408347, -33.414399410906967],
              [-70.813803678472951, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 368,
        left: -7862171.9497557012,
        bottom: -3949438.7255666298,
        right: -7861017.2486789431,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.403151192956834],
              [-70.811210464408347, -33.399401463311598],
              [-70.80602403627914, -33.399401463311598],
              [-70.803430822214537, -33.403151192956834],
              [-70.80602403627914, -33.406900760777191],
              [-70.811210464408347, -33.406900760777191],
              [-70.813803678472951, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 369,
        left: -7862171.9497557012,
        bottom: -3948438.7251003794,
        right: -7861017.2486789431,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.39565157185055],
              [-70.811210464408347, -33.391901518582785],
              [-70.80602403627914, -33.391901518582785],
              [-70.803430822214537, -33.39565157185055],
              [-70.80602403627914, -33.399401463311598],
              [-70.811210464408347, -33.399401463311598],
              [-70.813803678472951, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 370,
        left: -7862171.9497557012,
        bottom: -3947438.7246341291,
        right: -7861017.2486789431,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.388151303517375],
              [-70.811210464408347, -33.384400926663417],
              [-70.80602403627914, -33.384400926663417],
              [-70.803430822214537, -33.388151303517375],
              [-70.80602403627914, -33.391901518582785],
              [-70.811210464408347, -33.391901518582785],
              [-70.813803678472951, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 371,
        left: -7862171.9497557012,
        bottom: -3946438.7241678787,
        right: -7861017.2486789431,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.380650388029984],
              [-70.811210464408347, -33.376899687626185],
              [-70.80602403627914, -33.376899687626185],
              [-70.803430822214537, -33.380650388029984],
              [-70.80602403627914, -33.384400926663417],
              [-70.811210464408347, -33.384400926663417],
              [-70.813803678472951, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 372,
        left: -7862171.9497557012,
        bottom: -3945438.7237016284,
        right: -7861017.2486789431,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.373148825461101],
              [-70.811210464408347, -33.369397801543847],
              [-70.80602403627914, -33.369397801543847],
              [-70.803430822214537, -33.373148825461101],
              [-70.80602403627914, -33.376899687626185],
              [-70.811210464408347, -33.376899687626185],
              [-70.813803678472951, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 373,
        left: -7862171.9497557012,
        bottom: -3944438.723235378,
        right: -7861017.2486789431,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.365646615883513],
              [-70.811210464408347, -33.361895268489214],
              [-70.80602403627914, -33.361895268489214],
              [-70.803430822214537, -33.365646615883513],
              [-70.80602403627914, -33.369397801543847],
              [-70.811210464408347, -33.369397801543847],
              [-70.813803678472951, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 374,
        left: -7862171.9497557012,
        bottom: -3943438.7227691277,
        right: -7861017.2486789431,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.358143759370044],
              [-70.811210464408347, -33.354392088535135],
              [-70.80602403627914, -33.354392088535135],
              [-70.803430822214537, -33.358143759370044],
              [-70.80602403627914, -33.361895268489214],
              [-70.811210464408347, -33.361895268489214],
              [-70.813803678472951, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 375,
        left: -7862171.9497557012,
        bottom: -3942438.7223028769,
        right: -7861017.2486789431,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.350640255993589],
              [-70.811210464408347, -33.346888261754529],
              [-70.80602403627914, -33.346888261754529],
              [-70.803430822214537, -33.350640255993589],
              [-70.80602403627914, -33.354392088535135],
              [-70.811210464408347, -33.354392088535135],
              [-70.813803678472951, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 376,
        left: -7862171.9497557012,
        bottom: -3941438.721836627,
        right: -7861017.2486789431,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.343136105827064],
              [-70.811210464408347, -33.339383788220331],
              [-70.80602403627914, -33.339383788220331],
              [-70.803430822214537, -33.343136105827064],
              [-70.80602403627914, -33.346888261754529],
              [-70.811210464408347, -33.346888261754529],
              [-70.813803678472951, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 377,
        left: -7862171.9497557012,
        bottom: -3940438.7213703771,
        right: -7861017.2486789431,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.335631308943462],
              [-70.811210464408347, -33.331878668005565],
              [-70.80602403627914, -33.331878668005565],
              [-70.803430822214537, -33.335631308943462],
              [-70.80602403627914, -33.339383788220331],
              [-70.811210464408347, -33.339383788220331],
              [-70.813803678472951, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 378,
        left: -7862171.9497557012,
        bottom: -3939438.7209041263,
        right: -7861017.2486789431,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.328125865415807],
              [-70.811210464408347, -33.324372901183303],
              [-70.80602403627914, -33.324372901183303],
              [-70.803430822214537, -33.328125865415807],
              [-70.80602403627914, -33.331878668005565],
              [-70.811210464408347, -33.331878668005565],
              [-70.813803678472951, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 379,
        left: -7862171.9497557012,
        bottom: -3938438.7204378759,
        right: -7861017.2486789431,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.320619775317184],
              [-70.811210464408347, -33.31686648782663],
              [-70.80602403627914, -33.31686648782663],
              [-70.803430822214537, -33.320619775317184],
              [-70.80602403627914, -33.324372901183303],
              [-70.811210464408347, -33.324372901183303],
              [-70.813803678472951, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 380,
        left: -7862171.9497557012,
        bottom: -3937438.7199716261,
        right: -7861017.2486789431,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.313113038720743],
              [-70.811210464408347, -33.309359428008712],
              [-70.80602403627914, -33.309359428008712],
              [-70.803430822214537, -33.313113038720743],
              [-70.80602403627914, -33.31686648782663],
              [-70.811210464408347, -33.31686648782663],
              [-70.813803678472951, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 381,
        left: -7862171.9497557012,
        bottom: -3936438.7195053762,
        right: -7861017.2486789431,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.305605655699658],
              [-70.811210464408347, -33.301851721802763],
              [-70.80602403627914, -33.301851721802763],
              [-70.803430822214537, -33.305605655699658],
              [-70.80602403627914, -33.309359428008712],
              [-70.811210464408347, -33.309359428008712],
              [-70.813803678472951, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 382,
        left: -7862171.9497557012,
        bottom: -3935438.7190391254,
        right: -7861017.2486789431,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.298097626327156],
              [-70.811210464408347, -33.294343369282039],
              [-70.80602403627914, -33.294343369282039],
              [-70.803430822214537, -33.298097626327156],
              [-70.80602403627914, -33.301851721802763],
              [-70.811210464408347, -33.301851721802763],
              [-70.813803678472951, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 383,
        left: -7862171.9497557012,
        bottom: -3934438.718572875,
        right: -7861017.2486789431,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.29058895067655],
              [-70.811210464408347, -33.286834370519863],
              [-70.80602403627914, -33.286834370519863],
              [-70.803430822214537, -33.29058895067655],
              [-70.80602403627914, -33.294343369282039],
              [-70.811210464408347, -33.294343369282039],
              [-70.813803678472951, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 384,
        left: -7862171.9497557012,
        bottom: -3933438.7181066247,
        right: -7861017.2486789431,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.813803678472951, -33.283079628821156],
              [-70.811210464408347, -33.279324725589596],
              [-70.80602403627914, -33.279324725589596],
              [-70.803430822214537, -33.283079628821156],
              [-70.80602403627914, -33.286834370519863],
              [-70.811210464408347, -33.286834370519863],
              [-70.813803678472951, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 385,
        left: -7861305.9239481324,
        bottom: -3980938.7402535151,
        right: -7860151.2228713734,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.639057545603663],
              [-70.803430822214537, -33.635318028521546],
              [-70.798244394085316, -33.635318028521546],
              [-70.795651180020727, -33.639057545603663],
              [-70.798244394085316, -33.642796900295409],
              [-70.803430822214537, -33.642796900295409],
              [-70.80602403627914, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 386,
        left: -7861305.9239481324,
        bottom: -3979938.7397872647,
        right: -7860151.2228713734,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.631578349057953],
              [-70.803430822214537, -33.627838507221767],
              [-70.798244394085316, -33.627838507221767],
              [-70.795651180020727, -33.631578349057953],
              [-70.798244394085316, -33.635318028521546],
              [-70.803430822214537, -33.635318028521546],
              [-70.80602403627914, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 387,
        left: -7861305.9239481324,
        bottom: -3978938.7393210144,
        right: -7860151.2228713734,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.624098503021855],
              [-70.803430822214537, -33.620358336467127],
              [-70.798244394085316, -33.620358336467127],
              [-70.795651180020727, -33.624098503021855],
              [-70.798244394085316, -33.627838507221767],
              [-70.803430822214537, -33.627838507221767],
              [-70.80602403627914, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 388,
        left: -7861305.9239481324,
        bottom: -3977938.738854764,
        right: -7860151.2228713734,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.616618007566458],
              [-70.803430822214537, -33.612877516328737],
              [-70.798244394085316, -33.612877516328737],
              [-70.795651180020727, -33.616618007566458],
              [-70.798244394085316, -33.620358336467127],
              [-70.803430822214537, -33.620358336467127],
              [-70.80602403627914, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 389,
        left: -7861305.9239481324,
        bottom: -3976938.7383885137,
        right: -7860151.2228713734,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.609136862762881],
              [-70.803430822214537, -33.605396046877765],
              [-70.798244394085316, -33.605396046877765],
              [-70.795651180020727, -33.609136862762881],
              [-70.798244394085316, -33.612877516328737],
              [-70.803430822214537, -33.612877516328737],
              [-70.80602403627914, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 390,
        left: -7861305.9239481324,
        bottom: -3975938.7379222633,
        right: -7860151.2228713734,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.601655068682327],
              [-70.803430822214537, -33.597913928185434],
              [-70.798244394085316, -33.597913928185434],
              [-70.795651180020727, -33.601655068682327],
              [-70.798244394085316, -33.605396046877765],
              [-70.803430822214537, -33.605396046877765],
              [-70.80602403627914, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 391,
        left: -7861305.9239481324,
        bottom: -3974938.737456013,
        right: -7860151.2228713734,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.594172625396013],
              [-70.803430822214537, -33.590431160322979],
              [-70.798244394085316, -33.590431160322979],
              [-70.795651180020727, -33.594172625396013],
              [-70.798244394085316, -33.597913928185434],
              [-70.803430822214537, -33.597913928185434],
              [-70.80602403627914, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 392,
        left: -7861305.9239481324,
        bottom: -3973938.7369897626,
        right: -7860151.2228713734,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.586689532975264],
              [-70.803430822214537, -33.58294774336175],
              [-70.798244394085316, -33.58294774336175],
              [-70.795651180020727, -33.586689532975264],
              [-70.798244394085316, -33.590431160322979],
              [-70.803430822214537, -33.590431160322979],
              [-70.80602403627914, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 393,
        left: -7861305.9239481324,
        bottom: -3972938.7365235123,
        right: -7860151.2228713734,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.579205791491383],
              [-70.803430822214537, -33.575463677373087],
              [-70.798244394085316, -33.575463677373087],
              [-70.795651180020727, -33.579205791491383],
              [-70.798244394085316, -33.58294774336175],
              [-70.803430822214537, -33.58294774336175],
              [-70.80602403627914, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 394,
        left: -7861305.9239481324,
        bottom: -3971938.7360572619,
        right: -7860151.2228713734,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.571721401015779],
              [-70.803430822214537, -33.567978962428406],
              [-70.798244394085316, -33.567978962428406],
              [-70.795651180020727, -33.571721401015779],
              [-70.798244394085316, -33.575463677373087],
              [-70.803430822214537, -33.575463677373087],
              [-70.80602403627914, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 395,
        left: -7861305.9239481324,
        bottom: -3970938.7355910116,
        right: -7860151.2228713734,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.564236361619891],
              [-70.803430822214537, -33.56049359859918],
              [-70.798244394085316, -33.56049359859918],
              [-70.795651180020727, -33.564236361619891],
              [-70.798244394085316, -33.567978962428406],
              [-70.803430822214537, -33.567978962428406],
              [-70.80602403627914, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 396,
        left: -7861305.9239481324,
        bottom: -3969938.7351247617,
        right: -7860151.2228713734,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.556750673375213],
              [-70.803430822214537, -33.553007585956919],
              [-70.798244394085316, -33.553007585956919],
              [-70.795651180020727, -33.556750673375213],
              [-70.798244394085316, -33.56049359859918],
              [-70.803430822214537, -33.56049359859918],
              [-70.80602403627914, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 397,
        left: -7861305.9239481324,
        bottom: -3968938.7346585114,
        right: -7860151.2228713734,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.549264336353268],
              [-70.803430822214537, -33.545520924573204],
              [-70.798244394085316, -33.545520924573204],
              [-70.795651180020727, -33.549264336353268],
              [-70.798244394085316, -33.553007585956919],
              [-70.803430822214537, -33.553007585956919],
              [-70.80602403627914, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 398,
        left: -7861305.9239481324,
        bottom: -3967938.734192261,
        right: -7860151.2228713734,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.541777350625665],
              [-70.803430822214537, -33.538033614519627],
              [-70.798244394085316, -33.538033614519627],
              [-70.795651180020727, -33.541777350625665],
              [-70.798244394085316, -33.545520924573204],
              [-70.803430822214537, -33.545520924573204],
              [-70.80602403627914, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 399,
        left: -7861305.9239481324,
        bottom: -3966938.7337260107,
        right: -7860151.2228713734,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.534289716264041],
              [-70.803430822214537, -33.530545655867876],
              [-70.798244394085316, -33.530545655867876],
              [-70.795651180020727, -33.534289716264041],
              [-70.798244394085316, -33.538033614519627],
              [-70.803430822214537, -33.538033614519627],
              [-70.80602403627914, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 400,
        left: -7861305.9239481324,
        bottom: -3965938.7332597603,
        right: -7860151.2228713734,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.52680143334009],
              [-70.803430822214537, -33.523057048689658],
              [-70.798244394085316, -33.523057048689658],
              [-70.795651180020727, -33.52680143334009],
              [-70.798244394085316, -33.530545655867876],
              [-70.803430822214537, -33.530545655867876],
              [-70.80602403627914, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 401,
        left: -7861305.9239481324,
        bottom: -3964938.7327935095,
        right: -7860151.2228713734,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.519312501925548],
              [-70.803430822214537, -33.515567793056739],
              [-70.798244394085316, -33.515567793056739],
              [-70.795651180020727, -33.519312501925548],
              [-70.798244394085316, -33.523057048689658],
              [-70.803430822214537, -33.523057048689658],
              [-70.80602403627914, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 402,
        left: -7861305.9239481324,
        bottom: -3963938.7323272596,
        right: -7860151.2228713734,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.511822922092215],
              [-70.803430822214537, -33.508077889040948],
              [-70.798244394085316, -33.508077889040948],
              [-70.795651180020727, -33.511822922092215],
              [-70.798244394085316, -33.515567793056739],
              [-70.803430822214537, -33.515567793056739],
              [-70.80602403627914, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 403,
        left: -7861305.9239481324,
        bottom: -3962938.7318610097,
        right: -7860151.2228713734,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.504332693911934],
              [-70.803430822214537, -33.500587336714155],
              [-70.798244394085316, -33.500587336714155],
              [-70.795651180020727, -33.504332693911934],
              [-70.798244394085316, -33.508077889040948],
              [-70.803430822214537, -33.508077889040948],
              [-70.80602403627914, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 404,
        left: -7861305.9239481324,
        bottom: -3961938.7313947589,
        right: -7860151.2228713734,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.496841817456605],
              [-70.803430822214537, -33.493096136148274],
              [-70.798244394085316, -33.493096136148274],
              [-70.795651180020727, -33.496841817456605],
              [-70.798244394085316, -33.500587336714155],
              [-70.803430822214537, -33.500587336714155],
              [-70.80602403627914, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 405,
        left: -7861305.9239481324,
        bottom: -3960938.7309285086,
        right: -7860151.2228713734,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.489350292798164],
              [-70.803430822214537, -33.485604287415278],
              [-70.798244394085316, -33.485604287415278],
              [-70.795651180020727, -33.489350292798164],
              [-70.798244394085316, -33.493096136148274],
              [-70.803430822214537, -33.493096136148274],
              [-70.80602403627914, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 406,
        left: -7861305.9239481324,
        bottom: -3959938.7304622582,
        right: -7860151.2228713734,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.481858120008617],
              [-70.803430822214537, -33.478111790587178],
              [-70.798244394085316, -33.478111790587178],
              [-70.795651180020727, -33.481858120008617],
              [-70.798244394085316, -33.485604287415278],
              [-70.803430822214537, -33.485604287415278],
              [-70.80602403627914, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 407,
        left: -7861305.9239481324,
        bottom: -3958938.7299960079,
        right: -7860151.2228713734,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.474365299160006],
              [-70.803430822214537, -33.47061864573606],
              [-70.798244394085316, -33.47061864573606],
              [-70.795651180020727, -33.474365299160006],
              [-70.798244394085316, -33.478111790587178],
              [-70.803430822214537, -33.478111790587178],
              [-70.80602403627914, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 408,
        left: -7861305.9239481324,
        bottom: -3957938.7295297575,
        right: -7860151.2228713734,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.466871830324415],
              [-70.803430822214537, -33.463124852934051],
              [-70.798244394085316, -33.463124852934051],
              [-70.795651180020727, -33.466871830324415],
              [-70.798244394085316, -33.47061864573606],
              [-70.803430822214537, -33.47061864573606],
              [-70.80602403627914, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 409,
        left: -7861305.9239481324,
        bottom: -3956938.7290635072,
        right: -7860151.2228713734,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.459377713574014],
              [-70.803430822214537, -33.455630412253299],
              [-70.798244394085316, -33.455630412253299],
              [-70.795651180020727, -33.459377713574014],
              [-70.798244394085316, -33.463124852934051],
              [-70.803430822214537, -33.463124852934051],
              [-70.80602403627914, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 410,
        left: -7861305.9239481324,
        bottom: -3955938.7285972568,
        right: -7860151.2228713734,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.45188294898098],
              [-70.803430822214537, -33.448135323766053],
              [-70.798244394085316, -33.448135323766053],
              [-70.795651180020727, -33.45188294898098],
              [-70.798244394085316, -33.455630412253299],
              [-70.803430822214537, -33.455630412253299],
              [-70.80602403627914, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 411,
        left: -7861305.9239481324,
        bottom: -3954938.7281310065,
        right: -7860151.2228713734,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.444387536617576],
              [-70.803430822214537, -33.440639587544567],
              [-70.798244394085316, -33.440639587544567],
              [-70.795651180020727, -33.444387536617576],
              [-70.798244394085316, -33.448135323766053],
              [-70.803430822214537, -33.448135323766053],
              [-70.80602403627914, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 412,
        left: -7861305.9239481324,
        bottom: -3953938.7276647561,
        right: -7860151.2228713734,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.436891476556099],
              [-70.803430822214537, -33.433143203661182],
              [-70.798244394085316, -33.433143203661182],
              [-70.795651180020727, -33.436891476556099],
              [-70.798244394085316, -33.440639587544567],
              [-70.803430822214537, -33.440639587544567],
              [-70.80602403627914, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 413,
        left: -7861305.9239481324,
        bottom: -3952938.7271985058,
        right: -7860151.2228713734,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.429394768868896],
              [-70.803430822214537, -33.425646172188266],
              [-70.798244394085316, -33.425646172188266],
              [-70.795651180020727, -33.429394768868896],
              [-70.798244394085316, -33.433143203661182],
              [-70.803430822214537, -33.433143203661182],
              [-70.80602403627914, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 414,
        left: -7861305.9239481324,
        bottom: -3951938.7267322554,
        right: -7860151.2228713734,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.421897413628365],
              [-70.803430822214537, -33.418148493198238],
              [-70.798244394085316, -33.418148493198238],
              [-70.795651180020727, -33.421897413628365],
              [-70.798244394085316, -33.425646172188266],
              [-70.803430822214537, -33.425646172188266],
              [-70.80602403627914, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 415,
        left: -7861305.9239481324,
        bottom: -3950938.7262660051,
        right: -7860151.2228713734,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.414399410906967],
              [-70.803430822214537, -33.410650166763581],
              [-70.798244394085316, -33.410650166763581],
              [-70.795651180020727, -33.414399410906967],
              [-70.798244394085316, -33.418148493198238],
              [-70.803430822214537, -33.418148493198238],
              [-70.80602403627914, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 416,
        left: -7861305.9239481324,
        bottom: -3949938.7257997552,
        right: -7860151.2228713734,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.406900760777191],
              [-70.803430822214537, -33.403151192956834],
              [-70.798244394085316, -33.403151192956834],
              [-70.795651180020727, -33.406900760777191],
              [-70.798244394085316, -33.410650166763581],
              [-70.803430822214537, -33.410650166763581],
              [-70.80602403627914, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 417,
        left: -7861305.9239481324,
        bottom: -3948938.7253335048,
        right: -7860151.2228713734,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.399401463311598],
              [-70.803430822214537, -33.39565157185055],
              [-70.798244394085316, -33.39565157185055],
              [-70.795651180020727, -33.399401463311598],
              [-70.798244394085316, -33.403151192956834],
              [-70.803430822214537, -33.403151192956834],
              [-70.80602403627914, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 418,
        left: -7861305.9239481324,
        bottom: -3947938.724867254,
        right: -7860151.2228713734,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.391901518582785],
              [-70.803430822214537, -33.388151303517375],
              [-70.798244394085316, -33.388151303517375],
              [-70.795651180020727, -33.391901518582785],
              [-70.798244394085316, -33.39565157185055],
              [-70.803430822214537, -33.39565157185055],
              [-70.80602403627914, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 419,
        left: -7861305.9239481324,
        bottom: -3946938.7244010041,
        right: -7860151.2228713734,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.384400926663417],
              [-70.803430822214537, -33.380650388029984],
              [-70.798244394085316, -33.380650388029984],
              [-70.795651180020727, -33.384400926663417],
              [-70.798244394085316, -33.388151303517375],
              [-70.803430822214537, -33.388151303517375],
              [-70.80602403627914, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 420,
        left: -7861305.9239481324,
        bottom: -3945938.7239347543,
        right: -7860151.2228713734,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.376899687626185],
              [-70.803430822214537, -33.373148825461101],
              [-70.798244394085316, -33.373148825461101],
              [-70.795651180020727, -33.376899687626185],
              [-70.798244394085316, -33.380650388029984],
              [-70.803430822214537, -33.380650388029984],
              [-70.80602403627914, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 421,
        left: -7861305.9239481324,
        bottom: -3944938.7234685034,
        right: -7860151.2228713734,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.369397801543847],
              [-70.803430822214537, -33.365646615883513],
              [-70.798244394085316, -33.365646615883513],
              [-70.795651180020727, -33.369397801543847],
              [-70.798244394085316, -33.373148825461101],
              [-70.803430822214537, -33.373148825461101],
              [-70.80602403627914, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 422,
        left: -7861305.9239481324,
        bottom: -3943938.7230022531,
        right: -7860151.2228713734,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.361895268489214],
              [-70.803430822214537, -33.358143759370044],
              [-70.798244394085316, -33.358143759370044],
              [-70.795651180020727, -33.361895268489214],
              [-70.798244394085316, -33.365646615883513],
              [-70.803430822214537, -33.365646615883513],
              [-70.80602403627914, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 423,
        left: -7861305.9239481324,
        bottom: -3942938.7225360028,
        right: -7860151.2228713734,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.354392088535135],
              [-70.803430822214537, -33.350640255993589],
              [-70.798244394085316, -33.350640255993589],
              [-70.795651180020727, -33.354392088535135],
              [-70.798244394085316, -33.358143759370044],
              [-70.803430822214537, -33.358143759370044],
              [-70.80602403627914, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 424,
        left: -7861305.9239481324,
        bottom: -3941938.7220697524,
        right: -7860151.2228713734,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.346888261754529],
              [-70.803430822214537, -33.343136105827064],
              [-70.798244394085316, -33.343136105827064],
              [-70.795651180020727, -33.346888261754529],
              [-70.798244394085316, -33.350640255993589],
              [-70.803430822214537, -33.350640255993589],
              [-70.80602403627914, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 425,
        left: -7861305.9239481324,
        bottom: -3940938.7216035021,
        right: -7860151.2228713734,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.339383788220331],
              [-70.803430822214537, -33.335631308943462],
              [-70.798244394085316, -33.335631308943462],
              [-70.795651180020727, -33.339383788220331],
              [-70.798244394085316, -33.343136105827064],
              [-70.803430822214537, -33.343136105827064],
              [-70.80602403627914, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 426,
        left: -7861305.9239481324,
        bottom: -3939938.7211372517,
        right: -7860151.2228713734,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.331878668005565],
              [-70.803430822214537, -33.328125865415807],
              [-70.798244394085316, -33.328125865415807],
              [-70.795651180020727, -33.331878668005565],
              [-70.798244394085316, -33.335631308943462],
              [-70.803430822214537, -33.335631308943462],
              [-70.80602403627914, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 427,
        left: -7861305.9239481324,
        bottom: -3938938.7206710014,
        right: -7860151.2228713734,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.324372901183303],
              [-70.803430822214537, -33.320619775317184],
              [-70.798244394085316, -33.320619775317184],
              [-70.795651180020727, -33.324372901183303],
              [-70.798244394085316, -33.328125865415807],
              [-70.803430822214537, -33.328125865415807],
              [-70.80602403627914, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 428,
        left: -7861305.9239481324,
        bottom: -3937938.720204751,
        right: -7860151.2228713734,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.31686648782663],
              [-70.803430822214537, -33.313113038720743],
              [-70.798244394085316, -33.313113038720743],
              [-70.795651180020727, -33.31686648782663],
              [-70.798244394085316, -33.320619775317184],
              [-70.803430822214537, -33.320619775317184],
              [-70.80602403627914, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 429,
        left: -7861305.9239481324,
        bottom: -3936938.7197385007,
        right: -7860151.2228713734,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.309359428008712],
              [-70.803430822214537, -33.305605655699658],
              [-70.798244394085316, -33.305605655699658],
              [-70.795651180020727, -33.309359428008712],
              [-70.798244394085316, -33.313113038720743],
              [-70.803430822214537, -33.313113038720743],
              [-70.80602403627914, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 430,
        left: -7861305.9239481324,
        bottom: -3935938.7192722503,
        right: -7860151.2228713734,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.301851721802763],
              [-70.803430822214537, -33.298097626327156],
              [-70.798244394085316, -33.298097626327156],
              [-70.795651180020727, -33.301851721802763],
              [-70.798244394085316, -33.305605655699658],
              [-70.803430822214537, -33.305605655699658],
              [-70.80602403627914, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 431,
        left: -7861305.9239481324,
        bottom: -3934938.718806,
        right: -7860151.2228713734,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.294343369282039],
              [-70.803430822214537, -33.29058895067655],
              [-70.798244394085316, -33.29058895067655],
              [-70.795651180020727, -33.294343369282039],
              [-70.798244394085316, -33.298097626327156],
              [-70.803430822214537, -33.298097626327156],
              [-70.80602403627914, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 432,
        left: -7861305.9239481324,
        bottom: -3933938.7183397496,
        right: -7860151.2228713734,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.80602403627914, -33.286834370519863],
              [-70.803430822214537, -33.283079628821156],
              [-70.798244394085316, -33.283079628821156],
              [-70.795651180020727, -33.286834370519863],
              [-70.798244394085316, -33.29058895067655],
              [-70.803430822214537, -33.29058895067655],
              [-70.80602403627914, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 433,
        left: -7860439.8981405636,
        bottom: -3980438.7400203901,
        right: -7859285.1970638046,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.635318028521546],
              [-70.795651180020727, -33.631578349057953],
              [-70.790464751891506, -33.631578349057953],
              [-70.787871537826916, -33.635318028521546],
              [-70.790464751891506, -33.639057545603663],
              [-70.795651180020727, -33.639057545603663],
              [-70.798244394085316, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 434,
        left: -7860439.8981405636,
        bottom: -3979438.7395541398,
        right: -7859285.1970638046,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.627838507221767],
              [-70.795651180020727, -33.624098503021855],
              [-70.790464751891506, -33.624098503021855],
              [-70.787871537826916, -33.627838507221767],
              [-70.790464751891506, -33.631578349057953],
              [-70.795651180020727, -33.631578349057953],
              [-70.798244394085316, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 435,
        left: -7860439.8981405636,
        bottom: -3978438.7390878894,
        right: -7859285.1970638046,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.620358336467127],
              [-70.795651180020727, -33.616618007566458],
              [-70.790464751891506, -33.616618007566458],
              [-70.787871537826916, -33.620358336467127],
              [-70.790464751891506, -33.624098503021855],
              [-70.795651180020727, -33.624098503021855],
              [-70.798244394085316, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 436,
        left: -7860439.8981405636,
        bottom: -3977438.7386216391,
        right: -7859285.1970638046,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.612877516328737],
              [-70.795651180020727, -33.609136862762881],
              [-70.790464751891506, -33.609136862762881],
              [-70.787871537826916, -33.612877516328737],
              [-70.790464751891506, -33.616618007566458],
              [-70.795651180020727, -33.616618007566458],
              [-70.798244394085316, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 437,
        left: -7860439.8981405636,
        bottom: -3976438.7381553887,
        right: -7859285.1970638046,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.605396046877765],
              [-70.795651180020727, -33.601655068682327],
              [-70.790464751891506, -33.601655068682327],
              [-70.787871537826916, -33.605396046877765],
              [-70.790464751891506, -33.609136862762881],
              [-70.795651180020727, -33.609136862762881],
              [-70.798244394085316, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 438,
        left: -7860439.8981405636,
        bottom: -3975438.7376891384,
        right: -7859285.1970638046,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.597913928185434],
              [-70.795651180020727, -33.594172625396013],
              [-70.790464751891506, -33.594172625396013],
              [-70.787871537826916, -33.597913928185434],
              [-70.790464751891506, -33.601655068682327],
              [-70.795651180020727, -33.601655068682327],
              [-70.798244394085316, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 439,
        left: -7860439.8981405636,
        bottom: -3974438.7372228876,
        right: -7859285.1970638046,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.590431160322979],
              [-70.795651180020727, -33.586689532975264],
              [-70.790464751891506, -33.586689532975264],
              [-70.787871537826916, -33.590431160322979],
              [-70.790464751891506, -33.594172625396013],
              [-70.795651180020727, -33.594172625396013],
              [-70.798244394085316, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 440,
        left: -7860439.8981405636,
        bottom: -3973438.7367566377,
        right: -7859285.1970638046,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.58294774336175],
              [-70.795651180020727, -33.579205791491383],
              [-70.790464751891506, -33.579205791491383],
              [-70.787871537826916, -33.58294774336175],
              [-70.790464751891506, -33.586689532975264],
              [-70.795651180020727, -33.586689532975264],
              [-70.798244394085316, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 441,
        left: -7860439.8981405636,
        bottom: -3972438.7362903878,
        right: -7859285.1970638046,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.575463677373087],
              [-70.795651180020727, -33.571721401015779],
              [-70.790464751891506, -33.571721401015779],
              [-70.787871537826916, -33.575463677373087],
              [-70.790464751891506, -33.579205791491383],
              [-70.795651180020727, -33.579205791491383],
              [-70.798244394085316, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 442,
        left: -7860439.8981405636,
        bottom: -3971438.735824137,
        right: -7859285.1970638046,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.567978962428406],
              [-70.795651180020727, -33.564236361619891],
              [-70.790464751891506, -33.564236361619891],
              [-70.787871537826916, -33.567978962428406],
              [-70.790464751891506, -33.571721401015779],
              [-70.795651180020727, -33.571721401015779],
              [-70.798244394085316, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 443,
        left: -7860439.8981405636,
        bottom: -3970438.7353578866,
        right: -7859285.1970638046,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.56049359859918],
              [-70.795651180020727, -33.556750673375213],
              [-70.790464751891506, -33.556750673375213],
              [-70.787871537826916, -33.56049359859918],
              [-70.790464751891506, -33.564236361619891],
              [-70.795651180020727, -33.564236361619891],
              [-70.798244394085316, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 444,
        left: -7860439.8981405636,
        bottom: -3969438.7348916363,
        right: -7859285.1970638046,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.553007585956919],
              [-70.795651180020727, -33.549264336353268],
              [-70.790464751891506, -33.549264336353268],
              [-70.787871537826916, -33.553007585956919],
              [-70.790464751891506, -33.556750673375213],
              [-70.795651180020727, -33.556750673375213],
              [-70.798244394085316, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 445,
        left: -7860439.8981405636,
        bottom: -3968438.7344253859,
        right: -7859285.1970638046,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.545520924573204],
              [-70.795651180020727, -33.541777350625665],
              [-70.790464751891506, -33.541777350625665],
              [-70.787871537826916, -33.545520924573204],
              [-70.790464751891506, -33.549264336353268],
              [-70.795651180020727, -33.549264336353268],
              [-70.798244394085316, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 446,
        left: -7860439.8981405636,
        bottom: -3967438.7339591356,
        right: -7859285.1970638046,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.538033614519627],
              [-70.795651180020727, -33.534289716264041],
              [-70.790464751891506, -33.534289716264041],
              [-70.787871537826916, -33.538033614519627],
              [-70.790464751891506, -33.541777350625665],
              [-70.795651180020727, -33.541777350625665],
              [-70.798244394085316, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 447,
        left: -7860439.8981405636,
        bottom: -3966438.7334928853,
        right: -7859285.1970638046,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.530545655867876],
              [-70.795651180020727, -33.52680143334009],
              [-70.790464751891506, -33.52680143334009],
              [-70.787871537826916, -33.530545655867876],
              [-70.790464751891506, -33.534289716264041],
              [-70.795651180020727, -33.534289716264041],
              [-70.798244394085316, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 448,
        left: -7860439.8981405636,
        bottom: -3965438.7330266349,
        right: -7859285.1970638046,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.523057048689658],
              [-70.795651180020727, -33.519312501925548],
              [-70.790464751891506, -33.519312501925548],
              [-70.787871537826916, -33.523057048689658],
              [-70.790464751891506, -33.52680143334009],
              [-70.795651180020727, -33.52680143334009],
              [-70.798244394085316, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 449,
        left: -7860439.8981405636,
        bottom: -3964438.7325603846,
        right: -7859285.1970638046,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.515567793056739],
              [-70.795651180020727, -33.511822922092215],
              [-70.790464751891506, -33.511822922092215],
              [-70.787871537826916, -33.515567793056739],
              [-70.790464751891506, -33.519312501925548],
              [-70.795651180020727, -33.519312501925548],
              [-70.798244394085316, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 450,
        left: -7860439.8981405636,
        bottom: -3963438.7320941342,
        right: -7859285.1970638046,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.508077889040948],
              [-70.795651180020727, -33.504332693911934],
              [-70.790464751891506, -33.504332693911934],
              [-70.787871537826916, -33.508077889040948],
              [-70.790464751891506, -33.511822922092215],
              [-70.795651180020727, -33.511822922092215],
              [-70.798244394085316, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 451,
        left: -7860439.8981405636,
        bottom: -3962438.7316278839,
        right: -7859285.1970638046,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.500587336714155],
              [-70.795651180020727, -33.496841817456605],
              [-70.790464751891506, -33.496841817456605],
              [-70.787871537826916, -33.500587336714155],
              [-70.790464751891506, -33.504332693911934],
              [-70.795651180020727, -33.504332693911934],
              [-70.798244394085316, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 452,
        left: -7860439.8981405636,
        bottom: -3961438.7311616335,
        right: -7859285.1970638046,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.493096136148274],
              [-70.795651180020727, -33.489350292798164],
              [-70.790464751891506, -33.489350292798164],
              [-70.787871537826916, -33.493096136148274],
              [-70.790464751891506, -33.496841817456605],
              [-70.795651180020727, -33.496841817456605],
              [-70.798244394085316, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 453,
        left: -7860439.8981405636,
        bottom: -3960438.7306953832,
        right: -7859285.1970638046,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.485604287415278],
              [-70.795651180020727, -33.481858120008617],
              [-70.790464751891506, -33.481858120008617],
              [-70.787871537826916, -33.485604287415278],
              [-70.790464751891506, -33.489350292798164],
              [-70.795651180020727, -33.489350292798164],
              [-70.798244394085316, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 454,
        left: -7860439.8981405636,
        bottom: -3959438.7302291333,
        right: -7859285.1970638046,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.478111790587178],
              [-70.795651180020727, -33.474365299160006],
              [-70.790464751891506, -33.474365299160006],
              [-70.787871537826916, -33.478111790587178],
              [-70.790464751891506, -33.481858120008617],
              [-70.795651180020727, -33.481858120008617],
              [-70.798244394085316, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 455,
        left: -7860439.8981405636,
        bottom: -3958438.7297628829,
        right: -7859285.1970638046,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.47061864573606],
              [-70.795651180020727, -33.466871830324415],
              [-70.790464751891506, -33.466871830324415],
              [-70.787871537826916, -33.47061864573606],
              [-70.790464751891506, -33.474365299160006],
              [-70.795651180020727, -33.474365299160006],
              [-70.798244394085316, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 456,
        left: -7860439.8981405636,
        bottom: -3957438.7292966326,
        right: -7859285.1970638046,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.463124852934051],
              [-70.795651180020727, -33.459377713574014],
              [-70.790464751891506, -33.459377713574014],
              [-70.787871537826916, -33.463124852934051],
              [-70.790464751891506, -33.466871830324415],
              [-70.795651180020727, -33.466871830324415],
              [-70.798244394085316, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 457,
        left: -7860439.8981405636,
        bottom: -3956438.7288303822,
        right: -7859285.1970638046,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.455630412253299],
              [-70.795651180020727, -33.45188294898098],
              [-70.790464751891506, -33.45188294898098],
              [-70.787871537826916, -33.455630412253299],
              [-70.790464751891506, -33.459377713574014],
              [-70.795651180020727, -33.459377713574014],
              [-70.798244394085316, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 458,
        left: -7860439.8981405636,
        bottom: -3955438.7283641323,
        right: -7859285.1970638046,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.448135323766053],
              [-70.795651180020727, -33.444387536617576],
              [-70.790464751891506, -33.444387536617576],
              [-70.787871537826916, -33.448135323766053],
              [-70.790464751891506, -33.45188294898098],
              [-70.795651180020727, -33.45188294898098],
              [-70.798244394085316, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 459,
        left: -7860439.8981405636,
        bottom: -3954438.7278978815,
        right: -7859285.1970638046,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.440639587544567],
              [-70.795651180020727, -33.436891476556099],
              [-70.790464751891506, -33.436891476556099],
              [-70.787871537826916, -33.440639587544567],
              [-70.790464751891506, -33.444387536617576],
              [-70.795651180020727, -33.444387536617576],
              [-70.798244394085316, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 460,
        left: -7860439.8981405636,
        bottom: -3953438.7274316312,
        right: -7859285.1970638046,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.433143203661182],
              [-70.795651180020727, -33.429394768868896],
              [-70.790464751891506, -33.429394768868896],
              [-70.787871537826916, -33.433143203661182],
              [-70.790464751891506, -33.436891476556099],
              [-70.795651180020727, -33.436891476556099],
              [-70.798244394085316, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 461,
        left: -7860439.8981405636,
        bottom: -3952438.7269653808,
        right: -7859285.1970638046,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.425646172188266],
              [-70.795651180020727, -33.421897413628365],
              [-70.790464751891506, -33.421897413628365],
              [-70.787871537826916, -33.425646172188266],
              [-70.790464751891506, -33.429394768868896],
              [-70.795651180020727, -33.429394768868896],
              [-70.798244394085316, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 462,
        left: -7860439.8981405636,
        bottom: -3951438.7264991305,
        right: -7859285.1970638046,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.418148493198238],
              [-70.795651180020727, -33.414399410906967],
              [-70.790464751891506, -33.414399410906967],
              [-70.787871537826916, -33.418148493198238],
              [-70.790464751891506, -33.421897413628365],
              [-70.795651180020727, -33.421897413628365],
              [-70.798244394085316, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 463,
        left: -7860439.8981405636,
        bottom: -3950438.7260328801,
        right: -7859285.1970638046,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.410650166763581],
              [-70.795651180020727, -33.406900760777191],
              [-70.790464751891506, -33.406900760777191],
              [-70.787871537826916, -33.410650166763581],
              [-70.790464751891506, -33.414399410906967],
              [-70.795651180020727, -33.414399410906967],
              [-70.798244394085316, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 464,
        left: -7860439.8981405636,
        bottom: -3949438.7255666298,
        right: -7859285.1970638046,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.403151192956834],
              [-70.795651180020727, -33.399401463311598],
              [-70.790464751891506, -33.399401463311598],
              [-70.787871537826916, -33.403151192956834],
              [-70.790464751891506, -33.406900760777191],
              [-70.795651180020727, -33.406900760777191],
              [-70.798244394085316, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 465,
        left: -7860439.8981405636,
        bottom: -3948438.7251003794,
        right: -7859285.1970638046,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.39565157185055],
              [-70.795651180020727, -33.391901518582785],
              [-70.790464751891506, -33.391901518582785],
              [-70.787871537826916, -33.39565157185055],
              [-70.790464751891506, -33.399401463311598],
              [-70.795651180020727, -33.399401463311598],
              [-70.798244394085316, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 466,
        left: -7860439.8981405636,
        bottom: -3947438.7246341291,
        right: -7859285.1970638046,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.388151303517375],
              [-70.795651180020727, -33.384400926663417],
              [-70.790464751891506, -33.384400926663417],
              [-70.787871537826916, -33.388151303517375],
              [-70.790464751891506, -33.391901518582785],
              [-70.795651180020727, -33.391901518582785],
              [-70.798244394085316, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 467,
        left: -7860439.8981405636,
        bottom: -3946438.7241678787,
        right: -7859285.1970638046,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.380650388029984],
              [-70.795651180020727, -33.376899687626185],
              [-70.790464751891506, -33.376899687626185],
              [-70.787871537826916, -33.380650388029984],
              [-70.790464751891506, -33.384400926663417],
              [-70.795651180020727, -33.384400926663417],
              [-70.798244394085316, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 468,
        left: -7860439.8981405636,
        bottom: -3945438.7237016284,
        right: -7859285.1970638046,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.373148825461101],
              [-70.795651180020727, -33.369397801543847],
              [-70.790464751891506, -33.369397801543847],
              [-70.787871537826916, -33.373148825461101],
              [-70.790464751891506, -33.376899687626185],
              [-70.795651180020727, -33.376899687626185],
              [-70.798244394085316, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 469,
        left: -7860439.8981405636,
        bottom: -3944438.723235378,
        right: -7859285.1970638046,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.365646615883513],
              [-70.795651180020727, -33.361895268489214],
              [-70.790464751891506, -33.361895268489214],
              [-70.787871537826916, -33.365646615883513],
              [-70.790464751891506, -33.369397801543847],
              [-70.795651180020727, -33.369397801543847],
              [-70.798244394085316, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 470,
        left: -7860439.8981405636,
        bottom: -3943438.7227691277,
        right: -7859285.1970638046,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.358143759370044],
              [-70.795651180020727, -33.354392088535135],
              [-70.790464751891506, -33.354392088535135],
              [-70.787871537826916, -33.358143759370044],
              [-70.790464751891506, -33.361895268489214],
              [-70.795651180020727, -33.361895268489214],
              [-70.798244394085316, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 471,
        left: -7860439.8981405636,
        bottom: -3942438.7223028769,
        right: -7859285.1970638046,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.350640255993589],
              [-70.795651180020727, -33.346888261754529],
              [-70.790464751891506, -33.346888261754529],
              [-70.787871537826916, -33.350640255993589],
              [-70.790464751891506, -33.354392088535135],
              [-70.795651180020727, -33.354392088535135],
              [-70.798244394085316, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 472,
        left: -7860439.8981405636,
        bottom: -3941438.721836627,
        right: -7859285.1970638046,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.343136105827064],
              [-70.795651180020727, -33.339383788220331],
              [-70.790464751891506, -33.339383788220331],
              [-70.787871537826916, -33.343136105827064],
              [-70.790464751891506, -33.346888261754529],
              [-70.795651180020727, -33.346888261754529],
              [-70.798244394085316, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 473,
        left: -7860439.8981405636,
        bottom: -3940438.7213703771,
        right: -7859285.1970638046,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.335631308943462],
              [-70.795651180020727, -33.331878668005565],
              [-70.790464751891506, -33.331878668005565],
              [-70.787871537826916, -33.335631308943462],
              [-70.790464751891506, -33.339383788220331],
              [-70.795651180020727, -33.339383788220331],
              [-70.798244394085316, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 474,
        left: -7860439.8981405636,
        bottom: -3939438.7209041263,
        right: -7859285.1970638046,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.328125865415807],
              [-70.795651180020727, -33.324372901183303],
              [-70.790464751891506, -33.324372901183303],
              [-70.787871537826916, -33.328125865415807],
              [-70.790464751891506, -33.331878668005565],
              [-70.795651180020727, -33.331878668005565],
              [-70.798244394085316, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 475,
        left: -7860439.8981405636,
        bottom: -3938438.7204378759,
        right: -7859285.1970638046,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.320619775317184],
              [-70.795651180020727, -33.31686648782663],
              [-70.790464751891506, -33.31686648782663],
              [-70.787871537826916, -33.320619775317184],
              [-70.790464751891506, -33.324372901183303],
              [-70.795651180020727, -33.324372901183303],
              [-70.798244394085316, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 476,
        left: -7860439.8981405636,
        bottom: -3937438.7199716261,
        right: -7859285.1970638046,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.313113038720743],
              [-70.795651180020727, -33.309359428008712],
              [-70.790464751891506, -33.309359428008712],
              [-70.787871537826916, -33.313113038720743],
              [-70.790464751891506, -33.31686648782663],
              [-70.795651180020727, -33.31686648782663],
              [-70.798244394085316, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 477,
        left: -7860439.8981405636,
        bottom: -3936438.7195053762,
        right: -7859285.1970638046,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.305605655699658],
              [-70.795651180020727, -33.301851721802763],
              [-70.790464751891506, -33.301851721802763],
              [-70.787871537826916, -33.305605655699658],
              [-70.790464751891506, -33.309359428008712],
              [-70.795651180020727, -33.309359428008712],
              [-70.798244394085316, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 478,
        left: -7860439.8981405636,
        bottom: -3935438.7190391254,
        right: -7859285.1970638046,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.298097626327156],
              [-70.795651180020727, -33.294343369282039],
              [-70.790464751891506, -33.294343369282039],
              [-70.787871537826916, -33.298097626327156],
              [-70.790464751891506, -33.301851721802763],
              [-70.795651180020727, -33.301851721802763],
              [-70.798244394085316, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 479,
        left: -7860439.8981405636,
        bottom: -3934438.718572875,
        right: -7859285.1970638046,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.29058895067655],
              [-70.795651180020727, -33.286834370519863],
              [-70.790464751891506, -33.286834370519863],
              [-70.787871537826916, -33.29058895067655],
              [-70.790464751891506, -33.294343369282039],
              [-70.795651180020727, -33.294343369282039],
              [-70.798244394085316, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 480,
        left: -7860439.8981405636,
        bottom: -3933438.7181066247,
        right: -7859285.1970638046,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.798244394085316, -33.283079628821156],
              [-70.795651180020727, -33.279324725589596],
              [-70.790464751891506, -33.279324725589596],
              [-70.787871537826916, -33.283079628821156],
              [-70.790464751891506, -33.286834370519863],
              [-70.795651180020727, -33.286834370519863],
              [-70.798244394085316, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 481,
        left: -7859573.8723329939,
        bottom: -3980938.7402535151,
        right: -7858419.1712562358,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.639057545603663],
              [-70.787871537826916, -33.635318028521546],
              [-70.782685109697709, -33.635318028521546],
              [-70.780091895633092, -33.639057545603663],
              [-70.782685109697709, -33.642796900295409],
              [-70.787871537826916, -33.642796900295409],
              [-70.790464751891506, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 482,
        left: -7859573.8723329939,
        bottom: -3979938.7397872647,
        right: -7858419.1712562358,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.631578349057953],
              [-70.787871537826916, -33.627838507221767],
              [-70.782685109697709, -33.627838507221767],
              [-70.780091895633092, -33.631578349057953],
              [-70.782685109697709, -33.635318028521546],
              [-70.787871537826916, -33.635318028521546],
              [-70.790464751891506, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 483,
        left: -7859573.8723329939,
        bottom: -3978938.7393210144,
        right: -7858419.1712562358,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.624098503021855],
              [-70.787871537826916, -33.620358336467127],
              [-70.782685109697709, -33.620358336467127],
              [-70.780091895633092, -33.624098503021855],
              [-70.782685109697709, -33.627838507221767],
              [-70.787871537826916, -33.627838507221767],
              [-70.790464751891506, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 484,
        left: -7859573.8723329939,
        bottom: -3977938.738854764,
        right: -7858419.1712562358,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.616618007566458],
              [-70.787871537826916, -33.612877516328737],
              [-70.782685109697709, -33.612877516328737],
              [-70.780091895633092, -33.616618007566458],
              [-70.782685109697709, -33.620358336467127],
              [-70.787871537826916, -33.620358336467127],
              [-70.790464751891506, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 485,
        left: -7859573.8723329939,
        bottom: -3976938.7383885137,
        right: -7858419.1712562358,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.609136862762881],
              [-70.787871537826916, -33.605396046877765],
              [-70.782685109697709, -33.605396046877765],
              [-70.780091895633092, -33.609136862762881],
              [-70.782685109697709, -33.612877516328737],
              [-70.787871537826916, -33.612877516328737],
              [-70.790464751891506, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 486,
        left: -7859573.8723329939,
        bottom: -3975938.7379222633,
        right: -7858419.1712562358,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.601655068682327],
              [-70.787871537826916, -33.597913928185434],
              [-70.782685109697709, -33.597913928185434],
              [-70.780091895633092, -33.601655068682327],
              [-70.782685109697709, -33.605396046877765],
              [-70.787871537826916, -33.605396046877765],
              [-70.790464751891506, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 487,
        left: -7859573.8723329939,
        bottom: -3974938.737456013,
        right: -7858419.1712562358,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.594172625396013],
              [-70.787871537826916, -33.590431160322979],
              [-70.782685109697709, -33.590431160322979],
              [-70.780091895633092, -33.594172625396013],
              [-70.782685109697709, -33.597913928185434],
              [-70.787871537826916, -33.597913928185434],
              [-70.790464751891506, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 488,
        left: -7859573.8723329939,
        bottom: -3973938.7369897626,
        right: -7858419.1712562358,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.586689532975264],
              [-70.787871537826916, -33.58294774336175],
              [-70.782685109697709, -33.58294774336175],
              [-70.780091895633092, -33.586689532975264],
              [-70.782685109697709, -33.590431160322979],
              [-70.787871537826916, -33.590431160322979],
              [-70.790464751891506, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 489,
        left: -7859573.8723329939,
        bottom: -3972938.7365235123,
        right: -7858419.1712562358,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.579205791491383],
              [-70.787871537826916, -33.575463677373087],
              [-70.782685109697709, -33.575463677373087],
              [-70.780091895633092, -33.579205791491383],
              [-70.782685109697709, -33.58294774336175],
              [-70.787871537826916, -33.58294774336175],
              [-70.790464751891506, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 490,
        left: -7859573.8723329939,
        bottom: -3971938.7360572619,
        right: -7858419.1712562358,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.571721401015779],
              [-70.787871537826916, -33.567978962428406],
              [-70.782685109697709, -33.567978962428406],
              [-70.780091895633092, -33.571721401015779],
              [-70.782685109697709, -33.575463677373087],
              [-70.787871537826916, -33.575463677373087],
              [-70.790464751891506, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 491,
        left: -7859573.8723329939,
        bottom: -3970938.7355910116,
        right: -7858419.1712562358,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.564236361619891],
              [-70.787871537826916, -33.56049359859918],
              [-70.782685109697709, -33.56049359859918],
              [-70.780091895633092, -33.564236361619891],
              [-70.782685109697709, -33.567978962428406],
              [-70.787871537826916, -33.567978962428406],
              [-70.790464751891506, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 492,
        left: -7859573.8723329939,
        bottom: -3969938.7351247617,
        right: -7858419.1712562358,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.556750673375213],
              [-70.787871537826916, -33.553007585956919],
              [-70.782685109697709, -33.553007585956919],
              [-70.780091895633092, -33.556750673375213],
              [-70.782685109697709, -33.56049359859918],
              [-70.787871537826916, -33.56049359859918],
              [-70.790464751891506, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 493,
        left: -7859573.8723329939,
        bottom: -3968938.7346585114,
        right: -7858419.1712562358,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.549264336353268],
              [-70.787871537826916, -33.545520924573204],
              [-70.782685109697709, -33.545520924573204],
              [-70.780091895633092, -33.549264336353268],
              [-70.782685109697709, -33.553007585956919],
              [-70.787871537826916, -33.553007585956919],
              [-70.790464751891506, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 494,
        left: -7859573.8723329939,
        bottom: -3967938.734192261,
        right: -7858419.1712562358,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.541777350625665],
              [-70.787871537826916, -33.538033614519627],
              [-70.782685109697709, -33.538033614519627],
              [-70.780091895633092, -33.541777350625665],
              [-70.782685109697709, -33.545520924573204],
              [-70.787871537826916, -33.545520924573204],
              [-70.790464751891506, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 495,
        left: -7859573.8723329939,
        bottom: -3966938.7337260107,
        right: -7858419.1712562358,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.534289716264041],
              [-70.787871537826916, -33.530545655867876],
              [-70.782685109697709, -33.530545655867876],
              [-70.780091895633092, -33.534289716264041],
              [-70.782685109697709, -33.538033614519627],
              [-70.787871537826916, -33.538033614519627],
              [-70.790464751891506, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 496,
        left: -7859573.8723329939,
        bottom: -3965938.7332597603,
        right: -7858419.1712562358,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.52680143334009],
              [-70.787871537826916, -33.523057048689658],
              [-70.782685109697709, -33.523057048689658],
              [-70.780091895633092, -33.52680143334009],
              [-70.782685109697709, -33.530545655867876],
              [-70.787871537826916, -33.530545655867876],
              [-70.790464751891506, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 497,
        left: -7859573.8723329939,
        bottom: -3964938.7327935095,
        right: -7858419.1712562358,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.519312501925548],
              [-70.787871537826916, -33.515567793056739],
              [-70.782685109697709, -33.515567793056739],
              [-70.780091895633092, -33.519312501925548],
              [-70.782685109697709, -33.523057048689658],
              [-70.787871537826916, -33.523057048689658],
              [-70.790464751891506, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 498,
        left: -7859573.8723329939,
        bottom: -3963938.7323272596,
        right: -7858419.1712562358,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.511822922092215],
              [-70.787871537826916, -33.508077889040948],
              [-70.782685109697709, -33.508077889040948],
              [-70.780091895633092, -33.511822922092215],
              [-70.782685109697709, -33.515567793056739],
              [-70.787871537826916, -33.515567793056739],
              [-70.790464751891506, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 499,
        left: -7859573.8723329939,
        bottom: -3962938.7318610097,
        right: -7858419.1712562358,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.504332693911934],
              [-70.787871537826916, -33.500587336714155],
              [-70.782685109697709, -33.500587336714155],
              [-70.780091895633092, -33.504332693911934],
              [-70.782685109697709, -33.508077889040948],
              [-70.787871537826916, -33.508077889040948],
              [-70.790464751891506, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 500,
        left: -7859573.8723329939,
        bottom: -3961938.7313947589,
        right: -7858419.1712562358,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.496841817456605],
              [-70.787871537826916, -33.493096136148274],
              [-70.782685109697709, -33.493096136148274],
              [-70.780091895633092, -33.496841817456605],
              [-70.782685109697709, -33.500587336714155],
              [-70.787871537826916, -33.500587336714155],
              [-70.790464751891506, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 501,
        left: -7859573.8723329939,
        bottom: -3960938.7309285086,
        right: -7858419.1712562358,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.489350292798164],
              [-70.787871537826916, -33.485604287415278],
              [-70.782685109697709, -33.485604287415278],
              [-70.780091895633092, -33.489350292798164],
              [-70.782685109697709, -33.493096136148274],
              [-70.787871537826916, -33.493096136148274],
              [-70.790464751891506, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 502,
        left: -7859573.8723329939,
        bottom: -3959938.7304622582,
        right: -7858419.1712562358,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.481858120008617],
              [-70.787871537826916, -33.478111790587178],
              [-70.782685109697709, -33.478111790587178],
              [-70.780091895633092, -33.481858120008617],
              [-70.782685109697709, -33.485604287415278],
              [-70.787871537826916, -33.485604287415278],
              [-70.790464751891506, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 503,
        left: -7859573.8723329939,
        bottom: -3958938.7299960079,
        right: -7858419.1712562358,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.474365299160006],
              [-70.787871537826916, -33.47061864573606],
              [-70.782685109697709, -33.47061864573606],
              [-70.780091895633092, -33.474365299160006],
              [-70.782685109697709, -33.478111790587178],
              [-70.787871537826916, -33.478111790587178],
              [-70.790464751891506, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 504,
        left: -7859573.8723329939,
        bottom: -3957938.7295297575,
        right: -7858419.1712562358,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.466871830324415],
              [-70.787871537826916, -33.463124852934051],
              [-70.782685109697709, -33.463124852934051],
              [-70.780091895633092, -33.466871830324415],
              [-70.782685109697709, -33.47061864573606],
              [-70.787871537826916, -33.47061864573606],
              [-70.790464751891506, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 505,
        left: -7859573.8723329939,
        bottom: -3956938.7290635072,
        right: -7858419.1712562358,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.459377713574014],
              [-70.787871537826916, -33.455630412253299],
              [-70.782685109697709, -33.455630412253299],
              [-70.780091895633092, -33.459377713574014],
              [-70.782685109697709, -33.463124852934051],
              [-70.787871537826916, -33.463124852934051],
              [-70.790464751891506, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 506,
        left: -7859573.8723329939,
        bottom: -3955938.7285972568,
        right: -7858419.1712562358,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.45188294898098],
              [-70.787871537826916, -33.448135323766053],
              [-70.782685109697709, -33.448135323766053],
              [-70.780091895633092, -33.45188294898098],
              [-70.782685109697709, -33.455630412253299],
              [-70.787871537826916, -33.455630412253299],
              [-70.790464751891506, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 507,
        left: -7859573.8723329939,
        bottom: -3954938.7281310065,
        right: -7858419.1712562358,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.444387536617576],
              [-70.787871537826916, -33.440639587544567],
              [-70.782685109697709, -33.440639587544567],
              [-70.780091895633092, -33.444387536617576],
              [-70.782685109697709, -33.448135323766053],
              [-70.787871537826916, -33.448135323766053],
              [-70.790464751891506, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 508,
        left: -7859573.8723329939,
        bottom: -3953938.7276647561,
        right: -7858419.1712562358,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.436891476556099],
              [-70.787871537826916, -33.433143203661182],
              [-70.782685109697709, -33.433143203661182],
              [-70.780091895633092, -33.436891476556099],
              [-70.782685109697709, -33.440639587544567],
              [-70.787871537826916, -33.440639587544567],
              [-70.790464751891506, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 509,
        left: -7859573.8723329939,
        bottom: -3952938.7271985058,
        right: -7858419.1712562358,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.429394768868896],
              [-70.787871537826916, -33.425646172188266],
              [-70.782685109697709, -33.425646172188266],
              [-70.780091895633092, -33.429394768868896],
              [-70.782685109697709, -33.433143203661182],
              [-70.787871537826916, -33.433143203661182],
              [-70.790464751891506, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 510,
        left: -7859573.8723329939,
        bottom: -3951938.7267322554,
        right: -7858419.1712562358,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.421897413628365],
              [-70.787871537826916, -33.418148493198238],
              [-70.782685109697709, -33.418148493198238],
              [-70.780091895633092, -33.421897413628365],
              [-70.782685109697709, -33.425646172188266],
              [-70.787871537826916, -33.425646172188266],
              [-70.790464751891506, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 511,
        left: -7859573.8723329939,
        bottom: -3950938.7262660051,
        right: -7858419.1712562358,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.414399410906967],
              [-70.787871537826916, -33.410650166763581],
              [-70.782685109697709, -33.410650166763581],
              [-70.780091895633092, -33.414399410906967],
              [-70.782685109697709, -33.418148493198238],
              [-70.787871537826916, -33.418148493198238],
              [-70.790464751891506, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 512,
        left: -7859573.8723329939,
        bottom: -3949938.7257997552,
        right: -7858419.1712562358,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.406900760777191],
              [-70.787871537826916, -33.403151192956834],
              [-70.782685109697709, -33.403151192956834],
              [-70.780091895633092, -33.406900760777191],
              [-70.782685109697709, -33.410650166763581],
              [-70.787871537826916, -33.410650166763581],
              [-70.790464751891506, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 513,
        left: -7859573.8723329939,
        bottom: -3948938.7253335048,
        right: -7858419.1712562358,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.399401463311598],
              [-70.787871537826916, -33.39565157185055],
              [-70.782685109697709, -33.39565157185055],
              [-70.780091895633092, -33.399401463311598],
              [-70.782685109697709, -33.403151192956834],
              [-70.787871537826916, -33.403151192956834],
              [-70.790464751891506, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 514,
        left: -7859573.8723329939,
        bottom: -3947938.724867254,
        right: -7858419.1712562358,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.391901518582785],
              [-70.787871537826916, -33.388151303517375],
              [-70.782685109697709, -33.388151303517375],
              [-70.780091895633092, -33.391901518582785],
              [-70.782685109697709, -33.39565157185055],
              [-70.787871537826916, -33.39565157185055],
              [-70.790464751891506, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 515,
        left: -7859573.8723329939,
        bottom: -3946938.7244010041,
        right: -7858419.1712562358,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.384400926663417],
              [-70.787871537826916, -33.380650388029984],
              [-70.782685109697709, -33.380650388029984],
              [-70.780091895633092, -33.384400926663417],
              [-70.782685109697709, -33.388151303517375],
              [-70.787871537826916, -33.388151303517375],
              [-70.790464751891506, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 516,
        left: -7859573.8723329939,
        bottom: -3945938.7239347543,
        right: -7858419.1712562358,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.376899687626185],
              [-70.787871537826916, -33.373148825461101],
              [-70.782685109697709, -33.373148825461101],
              [-70.780091895633092, -33.376899687626185],
              [-70.782685109697709, -33.380650388029984],
              [-70.787871537826916, -33.380650388029984],
              [-70.790464751891506, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 517,
        left: -7859573.8723329939,
        bottom: -3944938.7234685034,
        right: -7858419.1712562358,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.369397801543847],
              [-70.787871537826916, -33.365646615883513],
              [-70.782685109697709, -33.365646615883513],
              [-70.780091895633092, -33.369397801543847],
              [-70.782685109697709, -33.373148825461101],
              [-70.787871537826916, -33.373148825461101],
              [-70.790464751891506, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 518,
        left: -7859573.8723329939,
        bottom: -3943938.7230022531,
        right: -7858419.1712562358,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.361895268489214],
              [-70.787871537826916, -33.358143759370044],
              [-70.782685109697709, -33.358143759370044],
              [-70.780091895633092, -33.361895268489214],
              [-70.782685109697709, -33.365646615883513],
              [-70.787871537826916, -33.365646615883513],
              [-70.790464751891506, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 519,
        left: -7859573.8723329939,
        bottom: -3942938.7225360028,
        right: -7858419.1712562358,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.354392088535135],
              [-70.787871537826916, -33.350640255993589],
              [-70.782685109697709, -33.350640255993589],
              [-70.780091895633092, -33.354392088535135],
              [-70.782685109697709, -33.358143759370044],
              [-70.787871537826916, -33.358143759370044],
              [-70.790464751891506, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 520,
        left: -7859573.8723329939,
        bottom: -3941938.7220697524,
        right: -7858419.1712562358,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.346888261754529],
              [-70.787871537826916, -33.343136105827064],
              [-70.782685109697709, -33.343136105827064],
              [-70.780091895633092, -33.346888261754529],
              [-70.782685109697709, -33.350640255993589],
              [-70.787871537826916, -33.350640255993589],
              [-70.790464751891506, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 521,
        left: -7859573.8723329939,
        bottom: -3940938.7216035021,
        right: -7858419.1712562358,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.339383788220331],
              [-70.787871537826916, -33.335631308943462],
              [-70.782685109697709, -33.335631308943462],
              [-70.780091895633092, -33.339383788220331],
              [-70.782685109697709, -33.343136105827064],
              [-70.787871537826916, -33.343136105827064],
              [-70.790464751891506, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 522,
        left: -7859573.8723329939,
        bottom: -3939938.7211372517,
        right: -7858419.1712562358,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.331878668005565],
              [-70.787871537826916, -33.328125865415807],
              [-70.782685109697709, -33.328125865415807],
              [-70.780091895633092, -33.331878668005565],
              [-70.782685109697709, -33.335631308943462],
              [-70.787871537826916, -33.335631308943462],
              [-70.790464751891506, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 523,
        left: -7859573.8723329939,
        bottom: -3938938.7206710014,
        right: -7858419.1712562358,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.324372901183303],
              [-70.787871537826916, -33.320619775317184],
              [-70.782685109697709, -33.320619775317184],
              [-70.780091895633092, -33.324372901183303],
              [-70.782685109697709, -33.328125865415807],
              [-70.787871537826916, -33.328125865415807],
              [-70.790464751891506, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 524,
        left: -7859573.8723329939,
        bottom: -3937938.720204751,
        right: -7858419.1712562358,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.31686648782663],
              [-70.787871537826916, -33.313113038720743],
              [-70.782685109697709, -33.313113038720743],
              [-70.780091895633092, -33.31686648782663],
              [-70.782685109697709, -33.320619775317184],
              [-70.787871537826916, -33.320619775317184],
              [-70.790464751891506, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 525,
        left: -7859573.8723329939,
        bottom: -3936938.7197385007,
        right: -7858419.1712562358,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.309359428008712],
              [-70.787871537826916, -33.305605655699658],
              [-70.782685109697709, -33.305605655699658],
              [-70.780091895633092, -33.309359428008712],
              [-70.782685109697709, -33.313113038720743],
              [-70.787871537826916, -33.313113038720743],
              [-70.790464751891506, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 526,
        left: -7859573.8723329939,
        bottom: -3935938.7192722503,
        right: -7858419.1712562358,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.301851721802763],
              [-70.787871537826916, -33.298097626327156],
              [-70.782685109697709, -33.298097626327156],
              [-70.780091895633092, -33.301851721802763],
              [-70.782685109697709, -33.305605655699658],
              [-70.787871537826916, -33.305605655699658],
              [-70.790464751891506, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 527,
        left: -7859573.8723329939,
        bottom: -3934938.718806,
        right: -7858419.1712562358,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.294343369282039],
              [-70.787871537826916, -33.29058895067655],
              [-70.782685109697709, -33.29058895067655],
              [-70.780091895633092, -33.294343369282039],
              [-70.782685109697709, -33.298097626327156],
              [-70.787871537826916, -33.298097626327156],
              [-70.790464751891506, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 528,
        left: -7859573.8723329939,
        bottom: -3933938.7183397496,
        right: -7858419.1712562358,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.790464751891506, -33.286834370519863],
              [-70.787871537826916, -33.283079628821156],
              [-70.782685109697709, -33.283079628821156],
              [-70.780091895633092, -33.286834370519863],
              [-70.782685109697709, -33.29058895067655],
              [-70.787871537826916, -33.29058895067655],
              [-70.790464751891506, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 529,
        left: -7858707.8465254251,
        bottom: -3980438.7400203901,
        right: -7857553.145448667,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.635318028521546],
              [-70.780091895633092, -33.631578349057953],
              [-70.774905467503885, -33.631578349057953],
              [-70.772312253439281, -33.635318028521546],
              [-70.774905467503885, -33.639057545603663],
              [-70.780091895633092, -33.639057545603663],
              [-70.782685109697709, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 530,
        left: -7858707.8465254251,
        bottom: -3979438.7395541398,
        right: -7857553.145448667,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.627838507221767],
              [-70.780091895633092, -33.624098503021855],
              [-70.774905467503885, -33.624098503021855],
              [-70.772312253439281, -33.627838507221767],
              [-70.774905467503885, -33.631578349057953],
              [-70.780091895633092, -33.631578349057953],
              [-70.782685109697709, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 531,
        left: -7858707.8465254251,
        bottom: -3978438.7390878894,
        right: -7857553.145448667,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.620358336467127],
              [-70.780091895633092, -33.616618007566458],
              [-70.774905467503885, -33.616618007566458],
              [-70.772312253439281, -33.620358336467127],
              [-70.774905467503885, -33.624098503021855],
              [-70.780091895633092, -33.624098503021855],
              [-70.782685109697709, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 532,
        left: -7858707.8465254251,
        bottom: -3977438.7386216391,
        right: -7857553.145448667,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.612877516328737],
              [-70.780091895633092, -33.609136862762881],
              [-70.774905467503885, -33.609136862762881],
              [-70.772312253439281, -33.612877516328737],
              [-70.774905467503885, -33.616618007566458],
              [-70.780091895633092, -33.616618007566458],
              [-70.782685109697709, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 533,
        left: -7858707.8465254251,
        bottom: -3976438.7381553887,
        right: -7857553.145448667,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.605396046877765],
              [-70.780091895633092, -33.601655068682327],
              [-70.774905467503885, -33.601655068682327],
              [-70.772312253439281, -33.605396046877765],
              [-70.774905467503885, -33.609136862762881],
              [-70.780091895633092, -33.609136862762881],
              [-70.782685109697709, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 534,
        left: -7858707.8465254251,
        bottom: -3975438.7376891384,
        right: -7857553.145448667,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.597913928185434],
              [-70.780091895633092, -33.594172625396013],
              [-70.774905467503885, -33.594172625396013],
              [-70.772312253439281, -33.597913928185434],
              [-70.774905467503885, -33.601655068682327],
              [-70.780091895633092, -33.601655068682327],
              [-70.782685109697709, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 535,
        left: -7858707.8465254251,
        bottom: -3974438.7372228876,
        right: -7857553.145448667,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.590431160322979],
              [-70.780091895633092, -33.586689532975264],
              [-70.774905467503885, -33.586689532975264],
              [-70.772312253439281, -33.590431160322979],
              [-70.774905467503885, -33.594172625396013],
              [-70.780091895633092, -33.594172625396013],
              [-70.782685109697709, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 536,
        left: -7858707.8465254251,
        bottom: -3973438.7367566377,
        right: -7857553.145448667,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.58294774336175],
              [-70.780091895633092, -33.579205791491383],
              [-70.774905467503885, -33.579205791491383],
              [-70.772312253439281, -33.58294774336175],
              [-70.774905467503885, -33.586689532975264],
              [-70.780091895633092, -33.586689532975264],
              [-70.782685109697709, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 537,
        left: -7858707.8465254251,
        bottom: -3972438.7362903878,
        right: -7857553.145448667,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.575463677373087],
              [-70.780091895633092, -33.571721401015779],
              [-70.774905467503885, -33.571721401015779],
              [-70.772312253439281, -33.575463677373087],
              [-70.774905467503885, -33.579205791491383],
              [-70.780091895633092, -33.579205791491383],
              [-70.782685109697709, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 538,
        left: -7858707.8465254251,
        bottom: -3971438.735824137,
        right: -7857553.145448667,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.567978962428406],
              [-70.780091895633092, -33.564236361619891],
              [-70.774905467503885, -33.564236361619891],
              [-70.772312253439281, -33.567978962428406],
              [-70.774905467503885, -33.571721401015779],
              [-70.780091895633092, -33.571721401015779],
              [-70.782685109697709, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 539,
        left: -7858707.8465254251,
        bottom: -3970438.7353578866,
        right: -7857553.145448667,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.56049359859918],
              [-70.780091895633092, -33.556750673375213],
              [-70.774905467503885, -33.556750673375213],
              [-70.772312253439281, -33.56049359859918],
              [-70.774905467503885, -33.564236361619891],
              [-70.780091895633092, -33.564236361619891],
              [-70.782685109697709, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 540,
        left: -7858707.8465254251,
        bottom: -3969438.7348916363,
        right: -7857553.145448667,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.553007585956919],
              [-70.780091895633092, -33.549264336353268],
              [-70.774905467503885, -33.549264336353268],
              [-70.772312253439281, -33.553007585956919],
              [-70.774905467503885, -33.556750673375213],
              [-70.780091895633092, -33.556750673375213],
              [-70.782685109697709, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 541,
        left: -7858707.8465254251,
        bottom: -3968438.7344253859,
        right: -7857553.145448667,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.545520924573204],
              [-70.780091895633092, -33.541777350625665],
              [-70.774905467503885, -33.541777350625665],
              [-70.772312253439281, -33.545520924573204],
              [-70.774905467503885, -33.549264336353268],
              [-70.780091895633092, -33.549264336353268],
              [-70.782685109697709, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 542,
        left: -7858707.8465254251,
        bottom: -3967438.7339591356,
        right: -7857553.145448667,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.538033614519627],
              [-70.780091895633092, -33.534289716264041],
              [-70.774905467503885, -33.534289716264041],
              [-70.772312253439281, -33.538033614519627],
              [-70.774905467503885, -33.541777350625665],
              [-70.780091895633092, -33.541777350625665],
              [-70.782685109697709, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 543,
        left: -7858707.8465254251,
        bottom: -3966438.7334928853,
        right: -7857553.145448667,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.530545655867876],
              [-70.780091895633092, -33.52680143334009],
              [-70.774905467503885, -33.52680143334009],
              [-70.772312253439281, -33.530545655867876],
              [-70.774905467503885, -33.534289716264041],
              [-70.780091895633092, -33.534289716264041],
              [-70.782685109697709, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 544,
        left: -7858707.8465254251,
        bottom: -3965438.7330266349,
        right: -7857553.145448667,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.523057048689658],
              [-70.780091895633092, -33.519312501925548],
              [-70.774905467503885, -33.519312501925548],
              [-70.772312253439281, -33.523057048689658],
              [-70.774905467503885, -33.52680143334009],
              [-70.780091895633092, -33.52680143334009],
              [-70.782685109697709, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 545,
        left: -7858707.8465254251,
        bottom: -3964438.7325603846,
        right: -7857553.145448667,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.515567793056739],
              [-70.780091895633092, -33.511822922092215],
              [-70.774905467503885, -33.511822922092215],
              [-70.772312253439281, -33.515567793056739],
              [-70.774905467503885, -33.519312501925548],
              [-70.780091895633092, -33.519312501925548],
              [-70.782685109697709, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 546,
        left: -7858707.8465254251,
        bottom: -3963438.7320941342,
        right: -7857553.145448667,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.508077889040948],
              [-70.780091895633092, -33.504332693911934],
              [-70.774905467503885, -33.504332693911934],
              [-70.772312253439281, -33.508077889040948],
              [-70.774905467503885, -33.511822922092215],
              [-70.780091895633092, -33.511822922092215],
              [-70.782685109697709, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 547,
        left: -7858707.8465254251,
        bottom: -3962438.7316278839,
        right: -7857553.145448667,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.500587336714155],
              [-70.780091895633092, -33.496841817456605],
              [-70.774905467503885, -33.496841817456605],
              [-70.772312253439281, -33.500587336714155],
              [-70.774905467503885, -33.504332693911934],
              [-70.780091895633092, -33.504332693911934],
              [-70.782685109697709, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 548,
        left: -7858707.8465254251,
        bottom: -3961438.7311616335,
        right: -7857553.145448667,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.493096136148274],
              [-70.780091895633092, -33.489350292798164],
              [-70.774905467503885, -33.489350292798164],
              [-70.772312253439281, -33.493096136148274],
              [-70.774905467503885, -33.496841817456605],
              [-70.780091895633092, -33.496841817456605],
              [-70.782685109697709, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 549,
        left: -7858707.8465254251,
        bottom: -3960438.7306953832,
        right: -7857553.145448667,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.485604287415278],
              [-70.780091895633092, -33.481858120008617],
              [-70.774905467503885, -33.481858120008617],
              [-70.772312253439281, -33.485604287415278],
              [-70.774905467503885, -33.489350292798164],
              [-70.780091895633092, -33.489350292798164],
              [-70.782685109697709, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 550,
        left: -7858707.8465254251,
        bottom: -3959438.7302291333,
        right: -7857553.145448667,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.478111790587178],
              [-70.780091895633092, -33.474365299160006],
              [-70.774905467503885, -33.474365299160006],
              [-70.772312253439281, -33.478111790587178],
              [-70.774905467503885, -33.481858120008617],
              [-70.780091895633092, -33.481858120008617],
              [-70.782685109697709, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 551,
        left: -7858707.8465254251,
        bottom: -3958438.7297628829,
        right: -7857553.145448667,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.47061864573606],
              [-70.780091895633092, -33.466871830324415],
              [-70.774905467503885, -33.466871830324415],
              [-70.772312253439281, -33.47061864573606],
              [-70.774905467503885, -33.474365299160006],
              [-70.780091895633092, -33.474365299160006],
              [-70.782685109697709, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 552,
        left: -7858707.8465254251,
        bottom: -3957438.7292966326,
        right: -7857553.145448667,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.463124852934051],
              [-70.780091895633092, -33.459377713574014],
              [-70.774905467503885, -33.459377713574014],
              [-70.772312253439281, -33.463124852934051],
              [-70.774905467503885, -33.466871830324415],
              [-70.780091895633092, -33.466871830324415],
              [-70.782685109697709, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 553,
        left: -7858707.8465254251,
        bottom: -3956438.7288303822,
        right: -7857553.145448667,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.455630412253299],
              [-70.780091895633092, -33.45188294898098],
              [-70.774905467503885, -33.45188294898098],
              [-70.772312253439281, -33.455630412253299],
              [-70.774905467503885, -33.459377713574014],
              [-70.780091895633092, -33.459377713574014],
              [-70.782685109697709, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 554,
        left: -7858707.8465254251,
        bottom: -3955438.7283641323,
        right: -7857553.145448667,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.448135323766053],
              [-70.780091895633092, -33.444387536617576],
              [-70.774905467503885, -33.444387536617576],
              [-70.772312253439281, -33.448135323766053],
              [-70.774905467503885, -33.45188294898098],
              [-70.780091895633092, -33.45188294898098],
              [-70.782685109697709, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 555,
        left: -7858707.8465254251,
        bottom: -3954438.7278978815,
        right: -7857553.145448667,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.440639587544567],
              [-70.780091895633092, -33.436891476556099],
              [-70.774905467503885, -33.436891476556099],
              [-70.772312253439281, -33.440639587544567],
              [-70.774905467503885, -33.444387536617576],
              [-70.780091895633092, -33.444387536617576],
              [-70.782685109697709, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 556,
        left: -7858707.8465254251,
        bottom: -3953438.7274316312,
        right: -7857553.145448667,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.433143203661182],
              [-70.780091895633092, -33.429394768868896],
              [-70.774905467503885, -33.429394768868896],
              [-70.772312253439281, -33.433143203661182],
              [-70.774905467503885, -33.436891476556099],
              [-70.780091895633092, -33.436891476556099],
              [-70.782685109697709, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 557,
        left: -7858707.8465254251,
        bottom: -3952438.7269653808,
        right: -7857553.145448667,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.425646172188266],
              [-70.780091895633092, -33.421897413628365],
              [-70.774905467503885, -33.421897413628365],
              [-70.772312253439281, -33.425646172188266],
              [-70.774905467503885, -33.429394768868896],
              [-70.780091895633092, -33.429394768868896],
              [-70.782685109697709, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 558,
        left: -7858707.8465254251,
        bottom: -3951438.7264991305,
        right: -7857553.145448667,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.418148493198238],
              [-70.780091895633092, -33.414399410906967],
              [-70.774905467503885, -33.414399410906967],
              [-70.772312253439281, -33.418148493198238],
              [-70.774905467503885, -33.421897413628365],
              [-70.780091895633092, -33.421897413628365],
              [-70.782685109697709, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 559,
        left: -7858707.8465254251,
        bottom: -3950438.7260328801,
        right: -7857553.145448667,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.410650166763581],
              [-70.780091895633092, -33.406900760777191],
              [-70.774905467503885, -33.406900760777191],
              [-70.772312253439281, -33.410650166763581],
              [-70.774905467503885, -33.414399410906967],
              [-70.780091895633092, -33.414399410906967],
              [-70.782685109697709, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 560,
        left: -7858707.8465254251,
        bottom: -3949438.7255666298,
        right: -7857553.145448667,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.403151192956834],
              [-70.780091895633092, -33.399401463311598],
              [-70.774905467503885, -33.399401463311598],
              [-70.772312253439281, -33.403151192956834],
              [-70.774905467503885, -33.406900760777191],
              [-70.780091895633092, -33.406900760777191],
              [-70.782685109697709, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 561,
        left: -7858707.8465254251,
        bottom: -3948438.7251003794,
        right: -7857553.145448667,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.39565157185055],
              [-70.780091895633092, -33.391901518582785],
              [-70.774905467503885, -33.391901518582785],
              [-70.772312253439281, -33.39565157185055],
              [-70.774905467503885, -33.399401463311598],
              [-70.780091895633092, -33.399401463311598],
              [-70.782685109697709, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 562,
        left: -7858707.8465254251,
        bottom: -3947438.7246341291,
        right: -7857553.145448667,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.388151303517375],
              [-70.780091895633092, -33.384400926663417],
              [-70.774905467503885, -33.384400926663417],
              [-70.772312253439281, -33.388151303517375],
              [-70.774905467503885, -33.391901518582785],
              [-70.780091895633092, -33.391901518582785],
              [-70.782685109697709, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 563,
        left: -7858707.8465254251,
        bottom: -3946438.7241678787,
        right: -7857553.145448667,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.380650388029984],
              [-70.780091895633092, -33.376899687626185],
              [-70.774905467503885, -33.376899687626185],
              [-70.772312253439281, -33.380650388029984],
              [-70.774905467503885, -33.384400926663417],
              [-70.780091895633092, -33.384400926663417],
              [-70.782685109697709, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 564,
        left: -7858707.8465254251,
        bottom: -3945438.7237016284,
        right: -7857553.145448667,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.373148825461101],
              [-70.780091895633092, -33.369397801543847],
              [-70.774905467503885, -33.369397801543847],
              [-70.772312253439281, -33.373148825461101],
              [-70.774905467503885, -33.376899687626185],
              [-70.780091895633092, -33.376899687626185],
              [-70.782685109697709, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 565,
        left: -7858707.8465254251,
        bottom: -3944438.723235378,
        right: -7857553.145448667,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.365646615883513],
              [-70.780091895633092, -33.361895268489214],
              [-70.774905467503885, -33.361895268489214],
              [-70.772312253439281, -33.365646615883513],
              [-70.774905467503885, -33.369397801543847],
              [-70.780091895633092, -33.369397801543847],
              [-70.782685109697709, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 566,
        left: -7858707.8465254251,
        bottom: -3943438.7227691277,
        right: -7857553.145448667,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.358143759370044],
              [-70.780091895633092, -33.354392088535135],
              [-70.774905467503885, -33.354392088535135],
              [-70.772312253439281, -33.358143759370044],
              [-70.774905467503885, -33.361895268489214],
              [-70.780091895633092, -33.361895268489214],
              [-70.782685109697709, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 567,
        left: -7858707.8465254251,
        bottom: -3942438.7223028769,
        right: -7857553.145448667,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.350640255993589],
              [-70.780091895633092, -33.346888261754529],
              [-70.774905467503885, -33.346888261754529],
              [-70.772312253439281, -33.350640255993589],
              [-70.774905467503885, -33.354392088535135],
              [-70.780091895633092, -33.354392088535135],
              [-70.782685109697709, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 568,
        left: -7858707.8465254251,
        bottom: -3941438.721836627,
        right: -7857553.145448667,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.343136105827064],
              [-70.780091895633092, -33.339383788220331],
              [-70.774905467503885, -33.339383788220331],
              [-70.772312253439281, -33.343136105827064],
              [-70.774905467503885, -33.346888261754529],
              [-70.780091895633092, -33.346888261754529],
              [-70.782685109697709, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 569,
        left: -7858707.8465254251,
        bottom: -3940438.7213703771,
        right: -7857553.145448667,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.335631308943462],
              [-70.780091895633092, -33.331878668005565],
              [-70.774905467503885, -33.331878668005565],
              [-70.772312253439281, -33.335631308943462],
              [-70.774905467503885, -33.339383788220331],
              [-70.780091895633092, -33.339383788220331],
              [-70.782685109697709, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 570,
        left: -7858707.8465254251,
        bottom: -3939438.7209041263,
        right: -7857553.145448667,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.328125865415807],
              [-70.780091895633092, -33.324372901183303],
              [-70.774905467503885, -33.324372901183303],
              [-70.772312253439281, -33.328125865415807],
              [-70.774905467503885, -33.331878668005565],
              [-70.780091895633092, -33.331878668005565],
              [-70.782685109697709, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 571,
        left: -7858707.8465254251,
        bottom: -3938438.7204378759,
        right: -7857553.145448667,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.320619775317184],
              [-70.780091895633092, -33.31686648782663],
              [-70.774905467503885, -33.31686648782663],
              [-70.772312253439281, -33.320619775317184],
              [-70.774905467503885, -33.324372901183303],
              [-70.780091895633092, -33.324372901183303],
              [-70.782685109697709, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 572,
        left: -7858707.8465254251,
        bottom: -3937438.7199716261,
        right: -7857553.145448667,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.313113038720743],
              [-70.780091895633092, -33.309359428008712],
              [-70.774905467503885, -33.309359428008712],
              [-70.772312253439281, -33.313113038720743],
              [-70.774905467503885, -33.31686648782663],
              [-70.780091895633092, -33.31686648782663],
              [-70.782685109697709, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 573,
        left: -7858707.8465254251,
        bottom: -3936438.7195053762,
        right: -7857553.145448667,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.305605655699658],
              [-70.780091895633092, -33.301851721802763],
              [-70.774905467503885, -33.301851721802763],
              [-70.772312253439281, -33.305605655699658],
              [-70.774905467503885, -33.309359428008712],
              [-70.780091895633092, -33.309359428008712],
              [-70.782685109697709, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 574,
        left: -7858707.8465254251,
        bottom: -3935438.7190391254,
        right: -7857553.145448667,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.298097626327156],
              [-70.780091895633092, -33.294343369282039],
              [-70.774905467503885, -33.294343369282039],
              [-70.772312253439281, -33.298097626327156],
              [-70.774905467503885, -33.301851721802763],
              [-70.780091895633092, -33.301851721802763],
              [-70.782685109697709, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 575,
        left: -7858707.8465254251,
        bottom: -3934438.718572875,
        right: -7857553.145448667,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.29058895067655],
              [-70.780091895633092, -33.286834370519863],
              [-70.774905467503885, -33.286834370519863],
              [-70.772312253439281, -33.29058895067655],
              [-70.774905467503885, -33.294343369282039],
              [-70.780091895633092, -33.294343369282039],
              [-70.782685109697709, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 576,
        left: -7858707.8465254251,
        bottom: -3933438.7181066247,
        right: -7857553.145448667,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.782685109697709, -33.283079628821156],
              [-70.780091895633092, -33.279324725589596],
              [-70.774905467503885, -33.279324725589596],
              [-70.772312253439281, -33.283079628821156],
              [-70.774905467503885, -33.286834370519863],
              [-70.780091895633092, -33.286834370519863],
              [-70.782685109697709, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 577,
        left: -7857841.8207178554,
        bottom: -3980938.7402535151,
        right: -7856687.1196410973,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.639057545603663],
              [-70.772312253439281, -33.635318028521546],
              [-70.76712582531006, -33.635318028521546],
              [-70.764532611245471, -33.639057545603663],
              [-70.76712582531006, -33.642796900295409],
              [-70.772312253439281, -33.642796900295409],
              [-70.774905467503885, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 578,
        left: -7857841.8207178554,
        bottom: -3979938.7397872647,
        right: -7856687.1196410973,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.631578349057953],
              [-70.772312253439281, -33.627838507221767],
              [-70.76712582531006, -33.627838507221767],
              [-70.764532611245471, -33.631578349057953],
              [-70.76712582531006, -33.635318028521546],
              [-70.772312253439281, -33.635318028521546],
              [-70.774905467503885, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 579,
        left: -7857841.8207178554,
        bottom: -3978938.7393210144,
        right: -7856687.1196410973,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.624098503021855],
              [-70.772312253439281, -33.620358336467127],
              [-70.76712582531006, -33.620358336467127],
              [-70.764532611245471, -33.624098503021855],
              [-70.76712582531006, -33.627838507221767],
              [-70.772312253439281, -33.627838507221767],
              [-70.774905467503885, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 580,
        left: -7857841.8207178554,
        bottom: -3977938.738854764,
        right: -7856687.1196410973,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.616618007566458],
              [-70.772312253439281, -33.612877516328737],
              [-70.76712582531006, -33.612877516328737],
              [-70.764532611245471, -33.616618007566458],
              [-70.76712582531006, -33.620358336467127],
              [-70.772312253439281, -33.620358336467127],
              [-70.774905467503885, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 581,
        left: -7857841.8207178554,
        bottom: -3976938.7383885137,
        right: -7856687.1196410973,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.609136862762881],
              [-70.772312253439281, -33.605396046877765],
              [-70.76712582531006, -33.605396046877765],
              [-70.764532611245471, -33.609136862762881],
              [-70.76712582531006, -33.612877516328737],
              [-70.772312253439281, -33.612877516328737],
              [-70.774905467503885, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 582,
        left: -7857841.8207178554,
        bottom: -3975938.7379222633,
        right: -7856687.1196410973,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.601655068682327],
              [-70.772312253439281, -33.597913928185434],
              [-70.76712582531006, -33.597913928185434],
              [-70.764532611245471, -33.601655068682327],
              [-70.76712582531006, -33.605396046877765],
              [-70.772312253439281, -33.605396046877765],
              [-70.774905467503885, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 583,
        left: -7857841.8207178554,
        bottom: -3974938.737456013,
        right: -7856687.1196410973,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.594172625396013],
              [-70.772312253439281, -33.590431160322979],
              [-70.76712582531006, -33.590431160322979],
              [-70.764532611245471, -33.594172625396013],
              [-70.76712582531006, -33.597913928185434],
              [-70.772312253439281, -33.597913928185434],
              [-70.774905467503885, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 584,
        left: -7857841.8207178554,
        bottom: -3973938.7369897626,
        right: -7856687.1196410973,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.586689532975264],
              [-70.772312253439281, -33.58294774336175],
              [-70.76712582531006, -33.58294774336175],
              [-70.764532611245471, -33.586689532975264],
              [-70.76712582531006, -33.590431160322979],
              [-70.772312253439281, -33.590431160322979],
              [-70.774905467503885, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 585,
        left: -7857841.8207178554,
        bottom: -3972938.7365235123,
        right: -7856687.1196410973,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.579205791491383],
              [-70.772312253439281, -33.575463677373087],
              [-70.76712582531006, -33.575463677373087],
              [-70.764532611245471, -33.579205791491383],
              [-70.76712582531006, -33.58294774336175],
              [-70.772312253439281, -33.58294774336175],
              [-70.774905467503885, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 586,
        left: -7857841.8207178554,
        bottom: -3971938.7360572619,
        right: -7856687.1196410973,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.571721401015779],
              [-70.772312253439281, -33.567978962428406],
              [-70.76712582531006, -33.567978962428406],
              [-70.764532611245471, -33.571721401015779],
              [-70.76712582531006, -33.575463677373087],
              [-70.772312253439281, -33.575463677373087],
              [-70.774905467503885, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 587,
        left: -7857841.8207178554,
        bottom: -3970938.7355910116,
        right: -7856687.1196410973,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.564236361619891],
              [-70.772312253439281, -33.56049359859918],
              [-70.76712582531006, -33.56049359859918],
              [-70.764532611245471, -33.564236361619891],
              [-70.76712582531006, -33.567978962428406],
              [-70.772312253439281, -33.567978962428406],
              [-70.774905467503885, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 588,
        left: -7857841.8207178554,
        bottom: -3969938.7351247617,
        right: -7856687.1196410973,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.556750673375213],
              [-70.772312253439281, -33.553007585956919],
              [-70.76712582531006, -33.553007585956919],
              [-70.764532611245471, -33.556750673375213],
              [-70.76712582531006, -33.56049359859918],
              [-70.772312253439281, -33.56049359859918],
              [-70.774905467503885, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 589,
        left: -7857841.8207178554,
        bottom: -3968938.7346585114,
        right: -7856687.1196410973,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.549264336353268],
              [-70.772312253439281, -33.545520924573204],
              [-70.76712582531006, -33.545520924573204],
              [-70.764532611245471, -33.549264336353268],
              [-70.76712582531006, -33.553007585956919],
              [-70.772312253439281, -33.553007585956919],
              [-70.774905467503885, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 590,
        left: -7857841.8207178554,
        bottom: -3967938.734192261,
        right: -7856687.1196410973,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.541777350625665],
              [-70.772312253439281, -33.538033614519627],
              [-70.76712582531006, -33.538033614519627],
              [-70.764532611245471, -33.541777350625665],
              [-70.76712582531006, -33.545520924573204],
              [-70.772312253439281, -33.545520924573204],
              [-70.774905467503885, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 591,
        left: -7857841.8207178554,
        bottom: -3966938.7337260107,
        right: -7856687.1196410973,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.534289716264041],
              [-70.772312253439281, -33.530545655867876],
              [-70.76712582531006, -33.530545655867876],
              [-70.764532611245471, -33.534289716264041],
              [-70.76712582531006, -33.538033614519627],
              [-70.772312253439281, -33.538033614519627],
              [-70.774905467503885, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 592,
        left: -7857841.8207178554,
        bottom: -3965938.7332597603,
        right: -7856687.1196410973,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.52680143334009],
              [-70.772312253439281, -33.523057048689658],
              [-70.76712582531006, -33.523057048689658],
              [-70.764532611245471, -33.52680143334009],
              [-70.76712582531006, -33.530545655867876],
              [-70.772312253439281, -33.530545655867876],
              [-70.774905467503885, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 593,
        left: -7857841.8207178554,
        bottom: -3964938.7327935095,
        right: -7856687.1196410973,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.519312501925548],
              [-70.772312253439281, -33.515567793056739],
              [-70.76712582531006, -33.515567793056739],
              [-70.764532611245471, -33.519312501925548],
              [-70.76712582531006, -33.523057048689658],
              [-70.772312253439281, -33.523057048689658],
              [-70.774905467503885, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 594,
        left: -7857841.8207178554,
        bottom: -3963938.7323272596,
        right: -7856687.1196410973,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.511822922092215],
              [-70.772312253439281, -33.508077889040948],
              [-70.76712582531006, -33.508077889040948],
              [-70.764532611245471, -33.511822922092215],
              [-70.76712582531006, -33.515567793056739],
              [-70.772312253439281, -33.515567793056739],
              [-70.774905467503885, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 595,
        left: -7857841.8207178554,
        bottom: -3962938.7318610097,
        right: -7856687.1196410973,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.504332693911934],
              [-70.772312253439281, -33.500587336714155],
              [-70.76712582531006, -33.500587336714155],
              [-70.764532611245471, -33.504332693911934],
              [-70.76712582531006, -33.508077889040948],
              [-70.772312253439281, -33.508077889040948],
              [-70.774905467503885, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 596,
        left: -7857841.8207178554,
        bottom: -3961938.7313947589,
        right: -7856687.1196410973,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.496841817456605],
              [-70.772312253439281, -33.493096136148274],
              [-70.76712582531006, -33.493096136148274],
              [-70.764532611245471, -33.496841817456605],
              [-70.76712582531006, -33.500587336714155],
              [-70.772312253439281, -33.500587336714155],
              [-70.774905467503885, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 597,
        left: -7857841.8207178554,
        bottom: -3960938.7309285086,
        right: -7856687.1196410973,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.489350292798164],
              [-70.772312253439281, -33.485604287415278],
              [-70.76712582531006, -33.485604287415278],
              [-70.764532611245471, -33.489350292798164],
              [-70.76712582531006, -33.493096136148274],
              [-70.772312253439281, -33.493096136148274],
              [-70.774905467503885, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 598,
        left: -7857841.8207178554,
        bottom: -3959938.7304622582,
        right: -7856687.1196410973,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.481858120008617],
              [-70.772312253439281, -33.478111790587178],
              [-70.76712582531006, -33.478111790587178],
              [-70.764532611245471, -33.481858120008617],
              [-70.76712582531006, -33.485604287415278],
              [-70.772312253439281, -33.485604287415278],
              [-70.774905467503885, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 599,
        left: -7857841.8207178554,
        bottom: -3958938.7299960079,
        right: -7856687.1196410973,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.474365299160006],
              [-70.772312253439281, -33.47061864573606],
              [-70.76712582531006, -33.47061864573606],
              [-70.764532611245471, -33.474365299160006],
              [-70.76712582531006, -33.478111790587178],
              [-70.772312253439281, -33.478111790587178],
              [-70.774905467503885, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 600,
        left: -7857841.8207178554,
        bottom: -3957938.7295297575,
        right: -7856687.1196410973,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.466871830324415],
              [-70.772312253439281, -33.463124852934051],
              [-70.76712582531006, -33.463124852934051],
              [-70.764532611245471, -33.466871830324415],
              [-70.76712582531006, -33.47061864573606],
              [-70.772312253439281, -33.47061864573606],
              [-70.774905467503885, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 601,
        left: -7857841.8207178554,
        bottom: -3956938.7290635072,
        right: -7856687.1196410973,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.459377713574014],
              [-70.772312253439281, -33.455630412253299],
              [-70.76712582531006, -33.455630412253299],
              [-70.764532611245471, -33.459377713574014],
              [-70.76712582531006, -33.463124852934051],
              [-70.772312253439281, -33.463124852934051],
              [-70.774905467503885, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 602,
        left: -7857841.8207178554,
        bottom: -3955938.7285972568,
        right: -7856687.1196410973,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.45188294898098],
              [-70.772312253439281, -33.448135323766053],
              [-70.76712582531006, -33.448135323766053],
              [-70.764532611245471, -33.45188294898098],
              [-70.76712582531006, -33.455630412253299],
              [-70.772312253439281, -33.455630412253299],
              [-70.774905467503885, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 603,
        left: -7857841.8207178554,
        bottom: -3954938.7281310065,
        right: -7856687.1196410973,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.444387536617576],
              [-70.772312253439281, -33.440639587544567],
              [-70.76712582531006, -33.440639587544567],
              [-70.764532611245471, -33.444387536617576],
              [-70.76712582531006, -33.448135323766053],
              [-70.772312253439281, -33.448135323766053],
              [-70.774905467503885, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 604,
        left: -7857841.8207178554,
        bottom: -3953938.7276647561,
        right: -7856687.1196410973,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.436891476556099],
              [-70.772312253439281, -33.433143203661182],
              [-70.76712582531006, -33.433143203661182],
              [-70.764532611245471, -33.436891476556099],
              [-70.76712582531006, -33.440639587544567],
              [-70.772312253439281, -33.440639587544567],
              [-70.774905467503885, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 605,
        left: -7857841.8207178554,
        bottom: -3952938.7271985058,
        right: -7856687.1196410973,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.429394768868896],
              [-70.772312253439281, -33.425646172188266],
              [-70.76712582531006, -33.425646172188266],
              [-70.764532611245471, -33.429394768868896],
              [-70.76712582531006, -33.433143203661182],
              [-70.772312253439281, -33.433143203661182],
              [-70.774905467503885, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 606,
        left: -7857841.8207178554,
        bottom: -3951938.7267322554,
        right: -7856687.1196410973,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.421897413628365],
              [-70.772312253439281, -33.418148493198238],
              [-70.76712582531006, -33.418148493198238],
              [-70.764532611245471, -33.421897413628365],
              [-70.76712582531006, -33.425646172188266],
              [-70.772312253439281, -33.425646172188266],
              [-70.774905467503885, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 607,
        left: -7857841.8207178554,
        bottom: -3950938.7262660051,
        right: -7856687.1196410973,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.414399410906967],
              [-70.772312253439281, -33.410650166763581],
              [-70.76712582531006, -33.410650166763581],
              [-70.764532611245471, -33.414399410906967],
              [-70.76712582531006, -33.418148493198238],
              [-70.772312253439281, -33.418148493198238],
              [-70.774905467503885, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 608,
        left: -7857841.8207178554,
        bottom: -3949938.7257997552,
        right: -7856687.1196410973,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.406900760777191],
              [-70.772312253439281, -33.403151192956834],
              [-70.76712582531006, -33.403151192956834],
              [-70.764532611245471, -33.406900760777191],
              [-70.76712582531006, -33.410650166763581],
              [-70.772312253439281, -33.410650166763581],
              [-70.774905467503885, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 609,
        left: -7857841.8207178554,
        bottom: -3948938.7253335048,
        right: -7856687.1196410973,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.399401463311598],
              [-70.772312253439281, -33.39565157185055],
              [-70.76712582531006, -33.39565157185055],
              [-70.764532611245471, -33.399401463311598],
              [-70.76712582531006, -33.403151192956834],
              [-70.772312253439281, -33.403151192956834],
              [-70.774905467503885, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 610,
        left: -7857841.8207178554,
        bottom: -3947938.724867254,
        right: -7856687.1196410973,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.391901518582785],
              [-70.772312253439281, -33.388151303517375],
              [-70.76712582531006, -33.388151303517375],
              [-70.764532611245471, -33.391901518582785],
              [-70.76712582531006, -33.39565157185055],
              [-70.772312253439281, -33.39565157185055],
              [-70.774905467503885, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 611,
        left: -7857841.8207178554,
        bottom: -3946938.7244010041,
        right: -7856687.1196410973,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.384400926663417],
              [-70.772312253439281, -33.380650388029984],
              [-70.76712582531006, -33.380650388029984],
              [-70.764532611245471, -33.384400926663417],
              [-70.76712582531006, -33.388151303517375],
              [-70.772312253439281, -33.388151303517375],
              [-70.774905467503885, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 612,
        left: -7857841.8207178554,
        bottom: -3945938.7239347543,
        right: -7856687.1196410973,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.376899687626185],
              [-70.772312253439281, -33.373148825461101],
              [-70.76712582531006, -33.373148825461101],
              [-70.764532611245471, -33.376899687626185],
              [-70.76712582531006, -33.380650388029984],
              [-70.772312253439281, -33.380650388029984],
              [-70.774905467503885, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 613,
        left: -7857841.8207178554,
        bottom: -3944938.7234685034,
        right: -7856687.1196410973,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.369397801543847],
              [-70.772312253439281, -33.365646615883513],
              [-70.76712582531006, -33.365646615883513],
              [-70.764532611245471, -33.369397801543847],
              [-70.76712582531006, -33.373148825461101],
              [-70.772312253439281, -33.373148825461101],
              [-70.774905467503885, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 614,
        left: -7857841.8207178554,
        bottom: -3943938.7230022531,
        right: -7856687.1196410973,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.361895268489214],
              [-70.772312253439281, -33.358143759370044],
              [-70.76712582531006, -33.358143759370044],
              [-70.764532611245471, -33.361895268489214],
              [-70.76712582531006, -33.365646615883513],
              [-70.772312253439281, -33.365646615883513],
              [-70.774905467503885, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 615,
        left: -7857841.8207178554,
        bottom: -3942938.7225360028,
        right: -7856687.1196410973,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.354392088535135],
              [-70.772312253439281, -33.350640255993589],
              [-70.76712582531006, -33.350640255993589],
              [-70.764532611245471, -33.354392088535135],
              [-70.76712582531006, -33.358143759370044],
              [-70.772312253439281, -33.358143759370044],
              [-70.774905467503885, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 616,
        left: -7857841.8207178554,
        bottom: -3941938.7220697524,
        right: -7856687.1196410973,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.346888261754529],
              [-70.772312253439281, -33.343136105827064],
              [-70.76712582531006, -33.343136105827064],
              [-70.764532611245471, -33.346888261754529],
              [-70.76712582531006, -33.350640255993589],
              [-70.772312253439281, -33.350640255993589],
              [-70.774905467503885, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 617,
        left: -7857841.8207178554,
        bottom: -3940938.7216035021,
        right: -7856687.1196410973,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.339383788220331],
              [-70.772312253439281, -33.335631308943462],
              [-70.76712582531006, -33.335631308943462],
              [-70.764532611245471, -33.339383788220331],
              [-70.76712582531006, -33.343136105827064],
              [-70.772312253439281, -33.343136105827064],
              [-70.774905467503885, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 618,
        left: -7857841.8207178554,
        bottom: -3939938.7211372517,
        right: -7856687.1196410973,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.331878668005565],
              [-70.772312253439281, -33.328125865415807],
              [-70.76712582531006, -33.328125865415807],
              [-70.764532611245471, -33.331878668005565],
              [-70.76712582531006, -33.335631308943462],
              [-70.772312253439281, -33.335631308943462],
              [-70.774905467503885, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 619,
        left: -7857841.8207178554,
        bottom: -3938938.7206710014,
        right: -7856687.1196410973,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.324372901183303],
              [-70.772312253439281, -33.320619775317184],
              [-70.76712582531006, -33.320619775317184],
              [-70.764532611245471, -33.324372901183303],
              [-70.76712582531006, -33.328125865415807],
              [-70.772312253439281, -33.328125865415807],
              [-70.774905467503885, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 620,
        left: -7857841.8207178554,
        bottom: -3937938.720204751,
        right: -7856687.1196410973,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.31686648782663],
              [-70.772312253439281, -33.313113038720743],
              [-70.76712582531006, -33.313113038720743],
              [-70.764532611245471, -33.31686648782663],
              [-70.76712582531006, -33.320619775317184],
              [-70.772312253439281, -33.320619775317184],
              [-70.774905467503885, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 621,
        left: -7857841.8207178554,
        bottom: -3936938.7197385007,
        right: -7856687.1196410973,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.309359428008712],
              [-70.772312253439281, -33.305605655699658],
              [-70.76712582531006, -33.305605655699658],
              [-70.764532611245471, -33.309359428008712],
              [-70.76712582531006, -33.313113038720743],
              [-70.772312253439281, -33.313113038720743],
              [-70.774905467503885, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 622,
        left: -7857841.8207178554,
        bottom: -3935938.7192722503,
        right: -7856687.1196410973,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.301851721802763],
              [-70.772312253439281, -33.298097626327156],
              [-70.76712582531006, -33.298097626327156],
              [-70.764532611245471, -33.301851721802763],
              [-70.76712582531006, -33.305605655699658],
              [-70.772312253439281, -33.305605655699658],
              [-70.774905467503885, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 623,
        left: -7857841.8207178554,
        bottom: -3934938.718806,
        right: -7856687.1196410973,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.294343369282039],
              [-70.772312253439281, -33.29058895067655],
              [-70.76712582531006, -33.29058895067655],
              [-70.764532611245471, -33.294343369282039],
              [-70.76712582531006, -33.298097626327156],
              [-70.772312253439281, -33.298097626327156],
              [-70.774905467503885, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 624,
        left: -7857841.8207178554,
        bottom: -3933938.7183397496,
        right: -7856687.1196410973,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.774905467503885, -33.286834370519863],
              [-70.772312253439281, -33.283079628821156],
              [-70.76712582531006, -33.283079628821156],
              [-70.764532611245471, -33.286834370519863],
              [-70.76712582531006, -33.29058895067655],
              [-70.772312253439281, -33.29058895067655],
              [-70.774905467503885, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 625,
        left: -7856975.7949102866,
        bottom: -3980438.7400203901,
        right: -7855821.0938335285,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.635318028521546],
              [-70.764532611245471, -33.631578349057953],
              [-70.759346183116264, -33.631578349057953],
              [-70.756752969051661, -33.635318028521546],
              [-70.759346183116264, -33.639057545603663],
              [-70.764532611245471, -33.639057545603663],
              [-70.76712582531006, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 626,
        left: -7856975.7949102866,
        bottom: -3979438.7395541398,
        right: -7855821.0938335285,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.627838507221767],
              [-70.764532611245471, -33.624098503021855],
              [-70.759346183116264, -33.624098503021855],
              [-70.756752969051661, -33.627838507221767],
              [-70.759346183116264, -33.631578349057953],
              [-70.764532611245471, -33.631578349057953],
              [-70.76712582531006, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 627,
        left: -7856975.7949102866,
        bottom: -3978438.7390878894,
        right: -7855821.0938335285,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.620358336467127],
              [-70.764532611245471, -33.616618007566458],
              [-70.759346183116264, -33.616618007566458],
              [-70.756752969051661, -33.620358336467127],
              [-70.759346183116264, -33.624098503021855],
              [-70.764532611245471, -33.624098503021855],
              [-70.76712582531006, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 628,
        left: -7856975.7949102866,
        bottom: -3977438.7386216391,
        right: -7855821.0938335285,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.612877516328737],
              [-70.764532611245471, -33.609136862762881],
              [-70.759346183116264, -33.609136862762881],
              [-70.756752969051661, -33.612877516328737],
              [-70.759346183116264, -33.616618007566458],
              [-70.764532611245471, -33.616618007566458],
              [-70.76712582531006, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 629,
        left: -7856975.7949102866,
        bottom: -3976438.7381553887,
        right: -7855821.0938335285,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.605396046877765],
              [-70.764532611245471, -33.601655068682327],
              [-70.759346183116264, -33.601655068682327],
              [-70.756752969051661, -33.605396046877765],
              [-70.759346183116264, -33.609136862762881],
              [-70.764532611245471, -33.609136862762881],
              [-70.76712582531006, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 630,
        left: -7856975.7949102866,
        bottom: -3975438.7376891384,
        right: -7855821.0938335285,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.597913928185434],
              [-70.764532611245471, -33.594172625396013],
              [-70.759346183116264, -33.594172625396013],
              [-70.756752969051661, -33.597913928185434],
              [-70.759346183116264, -33.601655068682327],
              [-70.764532611245471, -33.601655068682327],
              [-70.76712582531006, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 631,
        left: -7856975.7949102866,
        bottom: -3974438.7372228876,
        right: -7855821.0938335285,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.590431160322979],
              [-70.764532611245471, -33.586689532975264],
              [-70.759346183116264, -33.586689532975264],
              [-70.756752969051661, -33.590431160322979],
              [-70.759346183116264, -33.594172625396013],
              [-70.764532611245471, -33.594172625396013],
              [-70.76712582531006, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 632,
        left: -7856975.7949102866,
        bottom: -3973438.7367566377,
        right: -7855821.0938335285,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.58294774336175],
              [-70.764532611245471, -33.579205791491383],
              [-70.759346183116264, -33.579205791491383],
              [-70.756752969051661, -33.58294774336175],
              [-70.759346183116264, -33.586689532975264],
              [-70.764532611245471, -33.586689532975264],
              [-70.76712582531006, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 633,
        left: -7856975.7949102866,
        bottom: -3972438.7362903878,
        right: -7855821.0938335285,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.575463677373087],
              [-70.764532611245471, -33.571721401015779],
              [-70.759346183116264, -33.571721401015779],
              [-70.756752969051661, -33.575463677373087],
              [-70.759346183116264, -33.579205791491383],
              [-70.764532611245471, -33.579205791491383],
              [-70.76712582531006, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 634,
        left: -7856975.7949102866,
        bottom: -3971438.735824137,
        right: -7855821.0938335285,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.567978962428406],
              [-70.764532611245471, -33.564236361619891],
              [-70.759346183116264, -33.564236361619891],
              [-70.756752969051661, -33.567978962428406],
              [-70.759346183116264, -33.571721401015779],
              [-70.764532611245471, -33.571721401015779],
              [-70.76712582531006, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 635,
        left: -7856975.7949102866,
        bottom: -3970438.7353578866,
        right: -7855821.0938335285,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.56049359859918],
              [-70.764532611245471, -33.556750673375213],
              [-70.759346183116264, -33.556750673375213],
              [-70.756752969051661, -33.56049359859918],
              [-70.759346183116264, -33.564236361619891],
              [-70.764532611245471, -33.564236361619891],
              [-70.76712582531006, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 636,
        left: -7856975.7949102866,
        bottom: -3969438.7348916363,
        right: -7855821.0938335285,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.553007585956919],
              [-70.764532611245471, -33.549264336353268],
              [-70.759346183116264, -33.549264336353268],
              [-70.756752969051661, -33.553007585956919],
              [-70.759346183116264, -33.556750673375213],
              [-70.764532611245471, -33.556750673375213],
              [-70.76712582531006, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 637,
        left: -7856975.7949102866,
        bottom: -3968438.7344253859,
        right: -7855821.0938335285,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.545520924573204],
              [-70.764532611245471, -33.541777350625665],
              [-70.759346183116264, -33.541777350625665],
              [-70.756752969051661, -33.545520924573204],
              [-70.759346183116264, -33.549264336353268],
              [-70.764532611245471, -33.549264336353268],
              [-70.76712582531006, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 638,
        left: -7856975.7949102866,
        bottom: -3967438.7339591356,
        right: -7855821.0938335285,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.538033614519627],
              [-70.764532611245471, -33.534289716264041],
              [-70.759346183116264, -33.534289716264041],
              [-70.756752969051661, -33.538033614519627],
              [-70.759346183116264, -33.541777350625665],
              [-70.764532611245471, -33.541777350625665],
              [-70.76712582531006, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 639,
        left: -7856975.7949102866,
        bottom: -3966438.7334928853,
        right: -7855821.0938335285,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.530545655867876],
              [-70.764532611245471, -33.52680143334009],
              [-70.759346183116264, -33.52680143334009],
              [-70.756752969051661, -33.530545655867876],
              [-70.759346183116264, -33.534289716264041],
              [-70.764532611245471, -33.534289716264041],
              [-70.76712582531006, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 640,
        left: -7856975.7949102866,
        bottom: -3965438.7330266349,
        right: -7855821.0938335285,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.523057048689658],
              [-70.764532611245471, -33.519312501925548],
              [-70.759346183116264, -33.519312501925548],
              [-70.756752969051661, -33.523057048689658],
              [-70.759346183116264, -33.52680143334009],
              [-70.764532611245471, -33.52680143334009],
              [-70.76712582531006, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 641,
        left: -7856975.7949102866,
        bottom: -3964438.7325603846,
        right: -7855821.0938335285,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.515567793056739],
              [-70.764532611245471, -33.511822922092215],
              [-70.759346183116264, -33.511822922092215],
              [-70.756752969051661, -33.515567793056739],
              [-70.759346183116264, -33.519312501925548],
              [-70.764532611245471, -33.519312501925548],
              [-70.76712582531006, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 642,
        left: -7856975.7949102866,
        bottom: -3963438.7320941342,
        right: -7855821.0938335285,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.508077889040948],
              [-70.764532611245471, -33.504332693911934],
              [-70.759346183116264, -33.504332693911934],
              [-70.756752969051661, -33.508077889040948],
              [-70.759346183116264, -33.511822922092215],
              [-70.764532611245471, -33.511822922092215],
              [-70.76712582531006, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 643,
        left: -7856975.7949102866,
        bottom: -3962438.7316278839,
        right: -7855821.0938335285,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.500587336714155],
              [-70.764532611245471, -33.496841817456605],
              [-70.759346183116264, -33.496841817456605],
              [-70.756752969051661, -33.500587336714155],
              [-70.759346183116264, -33.504332693911934],
              [-70.764532611245471, -33.504332693911934],
              [-70.76712582531006, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 644,
        left: -7856975.7949102866,
        bottom: -3961438.7311616335,
        right: -7855821.0938335285,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.493096136148274],
              [-70.764532611245471, -33.489350292798164],
              [-70.759346183116264, -33.489350292798164],
              [-70.756752969051661, -33.493096136148274],
              [-70.759346183116264, -33.496841817456605],
              [-70.764532611245471, -33.496841817456605],
              [-70.76712582531006, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 645,
        left: -7856975.7949102866,
        bottom: -3960438.7306953832,
        right: -7855821.0938335285,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.485604287415278],
              [-70.764532611245471, -33.481858120008617],
              [-70.759346183116264, -33.481858120008617],
              [-70.756752969051661, -33.485604287415278],
              [-70.759346183116264, -33.489350292798164],
              [-70.764532611245471, -33.489350292798164],
              [-70.76712582531006, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 646,
        left: -7856975.7949102866,
        bottom: -3959438.7302291333,
        right: -7855821.0938335285,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.478111790587178],
              [-70.764532611245471, -33.474365299160006],
              [-70.759346183116264, -33.474365299160006],
              [-70.756752969051661, -33.478111790587178],
              [-70.759346183116264, -33.481858120008617],
              [-70.764532611245471, -33.481858120008617],
              [-70.76712582531006, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 647,
        left: -7856975.7949102866,
        bottom: -3958438.7297628829,
        right: -7855821.0938335285,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.47061864573606],
              [-70.764532611245471, -33.466871830324415],
              [-70.759346183116264, -33.466871830324415],
              [-70.756752969051661, -33.47061864573606],
              [-70.759346183116264, -33.474365299160006],
              [-70.764532611245471, -33.474365299160006],
              [-70.76712582531006, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 648,
        left: -7856975.7949102866,
        bottom: -3957438.7292966326,
        right: -7855821.0938335285,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.463124852934051],
              [-70.764532611245471, -33.459377713574014],
              [-70.759346183116264, -33.459377713574014],
              [-70.756752969051661, -33.463124852934051],
              [-70.759346183116264, -33.466871830324415],
              [-70.764532611245471, -33.466871830324415],
              [-70.76712582531006, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 649,
        left: -7856975.7949102866,
        bottom: -3956438.7288303822,
        right: -7855821.0938335285,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.455630412253299],
              [-70.764532611245471, -33.45188294898098],
              [-70.759346183116264, -33.45188294898098],
              [-70.756752969051661, -33.455630412253299],
              [-70.759346183116264, -33.459377713574014],
              [-70.764532611245471, -33.459377713574014],
              [-70.76712582531006, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 650,
        left: -7856975.7949102866,
        bottom: -3955438.7283641323,
        right: -7855821.0938335285,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.448135323766053],
              [-70.764532611245471, -33.444387536617576],
              [-70.759346183116264, -33.444387536617576],
              [-70.756752969051661, -33.448135323766053],
              [-70.759346183116264, -33.45188294898098],
              [-70.764532611245471, -33.45188294898098],
              [-70.76712582531006, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 651,
        left: -7856975.7949102866,
        bottom: -3954438.7278978815,
        right: -7855821.0938335285,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.440639587544567],
              [-70.764532611245471, -33.436891476556099],
              [-70.759346183116264, -33.436891476556099],
              [-70.756752969051661, -33.440639587544567],
              [-70.759346183116264, -33.444387536617576],
              [-70.764532611245471, -33.444387536617576],
              [-70.76712582531006, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 652,
        left: -7856975.7949102866,
        bottom: -3953438.7274316312,
        right: -7855821.0938335285,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.433143203661182],
              [-70.764532611245471, -33.429394768868896],
              [-70.759346183116264, -33.429394768868896],
              [-70.756752969051661, -33.433143203661182],
              [-70.759346183116264, -33.436891476556099],
              [-70.764532611245471, -33.436891476556099],
              [-70.76712582531006, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 653,
        left: -7856975.7949102866,
        bottom: -3952438.7269653808,
        right: -7855821.0938335285,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.425646172188266],
              [-70.764532611245471, -33.421897413628365],
              [-70.759346183116264, -33.421897413628365],
              [-70.756752969051661, -33.425646172188266],
              [-70.759346183116264, -33.429394768868896],
              [-70.764532611245471, -33.429394768868896],
              [-70.76712582531006, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 654,
        left: -7856975.7949102866,
        bottom: -3951438.7264991305,
        right: -7855821.0938335285,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.418148493198238],
              [-70.764532611245471, -33.414399410906967],
              [-70.759346183116264, -33.414399410906967],
              [-70.756752969051661, -33.418148493198238],
              [-70.759346183116264, -33.421897413628365],
              [-70.764532611245471, -33.421897413628365],
              [-70.76712582531006, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 655,
        left: -7856975.7949102866,
        bottom: -3950438.7260328801,
        right: -7855821.0938335285,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.410650166763581],
              [-70.764532611245471, -33.406900760777191],
              [-70.759346183116264, -33.406900760777191],
              [-70.756752969051661, -33.410650166763581],
              [-70.759346183116264, -33.414399410906967],
              [-70.764532611245471, -33.414399410906967],
              [-70.76712582531006, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 656,
        left: -7856975.7949102866,
        bottom: -3949438.7255666298,
        right: -7855821.0938335285,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.403151192956834],
              [-70.764532611245471, -33.399401463311598],
              [-70.759346183116264, -33.399401463311598],
              [-70.756752969051661, -33.403151192956834],
              [-70.759346183116264, -33.406900760777191],
              [-70.764532611245471, -33.406900760777191],
              [-70.76712582531006, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 657,
        left: -7856975.7949102866,
        bottom: -3948438.7251003794,
        right: -7855821.0938335285,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.39565157185055],
              [-70.764532611245471, -33.391901518582785],
              [-70.759346183116264, -33.391901518582785],
              [-70.756752969051661, -33.39565157185055],
              [-70.759346183116264, -33.399401463311598],
              [-70.764532611245471, -33.399401463311598],
              [-70.76712582531006, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 658,
        left: -7856975.7949102866,
        bottom: -3947438.7246341291,
        right: -7855821.0938335285,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.388151303517375],
              [-70.764532611245471, -33.384400926663417],
              [-70.759346183116264, -33.384400926663417],
              [-70.756752969051661, -33.388151303517375],
              [-70.759346183116264, -33.391901518582785],
              [-70.764532611245471, -33.391901518582785],
              [-70.76712582531006, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 659,
        left: -7856975.7949102866,
        bottom: -3946438.7241678787,
        right: -7855821.0938335285,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.380650388029984],
              [-70.764532611245471, -33.376899687626185],
              [-70.759346183116264, -33.376899687626185],
              [-70.756752969051661, -33.380650388029984],
              [-70.759346183116264, -33.384400926663417],
              [-70.764532611245471, -33.384400926663417],
              [-70.76712582531006, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 660,
        left: -7856975.7949102866,
        bottom: -3945438.7237016284,
        right: -7855821.0938335285,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.373148825461101],
              [-70.764532611245471, -33.369397801543847],
              [-70.759346183116264, -33.369397801543847],
              [-70.756752969051661, -33.373148825461101],
              [-70.759346183116264, -33.376899687626185],
              [-70.764532611245471, -33.376899687626185],
              [-70.76712582531006, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 661,
        left: -7856975.7949102866,
        bottom: -3944438.723235378,
        right: -7855821.0938335285,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.365646615883513],
              [-70.764532611245471, -33.361895268489214],
              [-70.759346183116264, -33.361895268489214],
              [-70.756752969051661, -33.365646615883513],
              [-70.759346183116264, -33.369397801543847],
              [-70.764532611245471, -33.369397801543847],
              [-70.76712582531006, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 662,
        left: -7856975.7949102866,
        bottom: -3943438.7227691277,
        right: -7855821.0938335285,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.358143759370044],
              [-70.764532611245471, -33.354392088535135],
              [-70.759346183116264, -33.354392088535135],
              [-70.756752969051661, -33.358143759370044],
              [-70.759346183116264, -33.361895268489214],
              [-70.764532611245471, -33.361895268489214],
              [-70.76712582531006, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 663,
        left: -7856975.7949102866,
        bottom: -3942438.7223028769,
        right: -7855821.0938335285,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.350640255993589],
              [-70.764532611245471, -33.346888261754529],
              [-70.759346183116264, -33.346888261754529],
              [-70.756752969051661, -33.350640255993589],
              [-70.759346183116264, -33.354392088535135],
              [-70.764532611245471, -33.354392088535135],
              [-70.76712582531006, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 664,
        left: -7856975.7949102866,
        bottom: -3941438.721836627,
        right: -7855821.0938335285,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.343136105827064],
              [-70.764532611245471, -33.339383788220331],
              [-70.759346183116264, -33.339383788220331],
              [-70.756752969051661, -33.343136105827064],
              [-70.759346183116264, -33.346888261754529],
              [-70.764532611245471, -33.346888261754529],
              [-70.76712582531006, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 665,
        left: -7856975.7949102866,
        bottom: -3940438.7213703771,
        right: -7855821.0938335285,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.335631308943462],
              [-70.764532611245471, -33.331878668005565],
              [-70.759346183116264, -33.331878668005565],
              [-70.756752969051661, -33.335631308943462],
              [-70.759346183116264, -33.339383788220331],
              [-70.764532611245471, -33.339383788220331],
              [-70.76712582531006, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 666,
        left: -7856975.7949102866,
        bottom: -3939438.7209041263,
        right: -7855821.0938335285,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.328125865415807],
              [-70.764532611245471, -33.324372901183303],
              [-70.759346183116264, -33.324372901183303],
              [-70.756752969051661, -33.328125865415807],
              [-70.759346183116264, -33.331878668005565],
              [-70.764532611245471, -33.331878668005565],
              [-70.76712582531006, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 667,
        left: -7856975.7949102866,
        bottom: -3938438.7204378759,
        right: -7855821.0938335285,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.320619775317184],
              [-70.764532611245471, -33.31686648782663],
              [-70.759346183116264, -33.31686648782663],
              [-70.756752969051661, -33.320619775317184],
              [-70.759346183116264, -33.324372901183303],
              [-70.764532611245471, -33.324372901183303],
              [-70.76712582531006, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 668,
        left: -7856975.7949102866,
        bottom: -3937438.7199716261,
        right: -7855821.0938335285,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.313113038720743],
              [-70.764532611245471, -33.309359428008712],
              [-70.759346183116264, -33.309359428008712],
              [-70.756752969051661, -33.313113038720743],
              [-70.759346183116264, -33.31686648782663],
              [-70.764532611245471, -33.31686648782663],
              [-70.76712582531006, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 669,
        left: -7856975.7949102866,
        bottom: -3936438.7195053762,
        right: -7855821.0938335285,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.305605655699658],
              [-70.764532611245471, -33.301851721802763],
              [-70.759346183116264, -33.301851721802763],
              [-70.756752969051661, -33.305605655699658],
              [-70.759346183116264, -33.309359428008712],
              [-70.764532611245471, -33.309359428008712],
              [-70.76712582531006, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 670,
        left: -7856975.7949102866,
        bottom: -3935438.7190391254,
        right: -7855821.0938335285,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.298097626327156],
              [-70.764532611245471, -33.294343369282039],
              [-70.759346183116264, -33.294343369282039],
              [-70.756752969051661, -33.298097626327156],
              [-70.759346183116264, -33.301851721802763],
              [-70.764532611245471, -33.301851721802763],
              [-70.76712582531006, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 671,
        left: -7856975.7949102866,
        bottom: -3934438.718572875,
        right: -7855821.0938335285,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.29058895067655],
              [-70.764532611245471, -33.286834370519863],
              [-70.759346183116264, -33.286834370519863],
              [-70.756752969051661, -33.29058895067655],
              [-70.759346183116264, -33.294343369282039],
              [-70.764532611245471, -33.294343369282039],
              [-70.76712582531006, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 672,
        left: -7856975.7949102866,
        bottom: -3933438.7181066247,
        right: -7855821.0938335285,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.76712582531006, -33.283079628821156],
              [-70.764532611245471, -33.279324725589596],
              [-70.759346183116264, -33.279324725589596],
              [-70.756752969051661, -33.283079628821156],
              [-70.759346183116264, -33.286834370519863],
              [-70.764532611245471, -33.286834370519863],
              [-70.76712582531006, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 673,
        left: -7856109.7691027177,
        bottom: -3980938.7402535151,
        right: -7854955.0680259597,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.639057545603663],
              [-70.756752969051661, -33.635318028521546],
              [-70.751566540922454, -33.635318028521546],
              [-70.74897332685785, -33.639057545603663],
              [-70.751566540922454, -33.642796900295409],
              [-70.756752969051661, -33.642796900295409],
              [-70.759346183116264, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 674,
        left: -7856109.7691027177,
        bottom: -3979938.7397872647,
        right: -7854955.0680259597,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.631578349057953],
              [-70.756752969051661, -33.627838507221767],
              [-70.751566540922454, -33.627838507221767],
              [-70.74897332685785, -33.631578349057953],
              [-70.751566540922454, -33.635318028521546],
              [-70.756752969051661, -33.635318028521546],
              [-70.759346183116264, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 675,
        left: -7856109.7691027177,
        bottom: -3978938.7393210144,
        right: -7854955.0680259597,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.624098503021855],
              [-70.756752969051661, -33.620358336467127],
              [-70.751566540922454, -33.620358336467127],
              [-70.74897332685785, -33.624098503021855],
              [-70.751566540922454, -33.627838507221767],
              [-70.756752969051661, -33.627838507221767],
              [-70.759346183116264, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 676,
        left: -7856109.7691027177,
        bottom: -3977938.738854764,
        right: -7854955.0680259597,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.616618007566458],
              [-70.756752969051661, -33.612877516328737],
              [-70.751566540922454, -33.612877516328737],
              [-70.74897332685785, -33.616618007566458],
              [-70.751566540922454, -33.620358336467127],
              [-70.756752969051661, -33.620358336467127],
              [-70.759346183116264, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 677,
        left: -7856109.7691027177,
        bottom: -3976938.7383885137,
        right: -7854955.0680259597,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.609136862762881],
              [-70.756752969051661, -33.605396046877765],
              [-70.751566540922454, -33.605396046877765],
              [-70.74897332685785, -33.609136862762881],
              [-70.751566540922454, -33.612877516328737],
              [-70.756752969051661, -33.612877516328737],
              [-70.759346183116264, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 678,
        left: -7856109.7691027177,
        bottom: -3975938.7379222633,
        right: -7854955.0680259597,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.601655068682327],
              [-70.756752969051661, -33.597913928185434],
              [-70.751566540922454, -33.597913928185434],
              [-70.74897332685785, -33.601655068682327],
              [-70.751566540922454, -33.605396046877765],
              [-70.756752969051661, -33.605396046877765],
              [-70.759346183116264, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 679,
        left: -7856109.7691027177,
        bottom: -3974938.737456013,
        right: -7854955.0680259597,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.594172625396013],
              [-70.756752969051661, -33.590431160322979],
              [-70.751566540922454, -33.590431160322979],
              [-70.74897332685785, -33.594172625396013],
              [-70.751566540922454, -33.597913928185434],
              [-70.756752969051661, -33.597913928185434],
              [-70.759346183116264, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 680,
        left: -7856109.7691027177,
        bottom: -3973938.7369897626,
        right: -7854955.0680259597,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.586689532975264],
              [-70.756752969051661, -33.58294774336175],
              [-70.751566540922454, -33.58294774336175],
              [-70.74897332685785, -33.586689532975264],
              [-70.751566540922454, -33.590431160322979],
              [-70.756752969051661, -33.590431160322979],
              [-70.759346183116264, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 681,
        left: -7856109.7691027177,
        bottom: -3972938.7365235123,
        right: -7854955.0680259597,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.579205791491383],
              [-70.756752969051661, -33.575463677373087],
              [-70.751566540922454, -33.575463677373087],
              [-70.74897332685785, -33.579205791491383],
              [-70.751566540922454, -33.58294774336175],
              [-70.756752969051661, -33.58294774336175],
              [-70.759346183116264, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 682,
        left: -7856109.7691027177,
        bottom: -3971938.7360572619,
        right: -7854955.0680259597,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.571721401015779],
              [-70.756752969051661, -33.567978962428406],
              [-70.751566540922454, -33.567978962428406],
              [-70.74897332685785, -33.571721401015779],
              [-70.751566540922454, -33.575463677373087],
              [-70.756752969051661, -33.575463677373087],
              [-70.759346183116264, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 683,
        left: -7856109.7691027177,
        bottom: -3970938.7355910116,
        right: -7854955.0680259597,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.564236361619891],
              [-70.756752969051661, -33.56049359859918],
              [-70.751566540922454, -33.56049359859918],
              [-70.74897332685785, -33.564236361619891],
              [-70.751566540922454, -33.567978962428406],
              [-70.756752969051661, -33.567978962428406],
              [-70.759346183116264, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 684,
        left: -7856109.7691027177,
        bottom: -3969938.7351247617,
        right: -7854955.0680259597,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.556750673375213],
              [-70.756752969051661, -33.553007585956919],
              [-70.751566540922454, -33.553007585956919],
              [-70.74897332685785, -33.556750673375213],
              [-70.751566540922454, -33.56049359859918],
              [-70.756752969051661, -33.56049359859918],
              [-70.759346183116264, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 685,
        left: -7856109.7691027177,
        bottom: -3968938.7346585114,
        right: -7854955.0680259597,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.549264336353268],
              [-70.756752969051661, -33.545520924573204],
              [-70.751566540922454, -33.545520924573204],
              [-70.74897332685785, -33.549264336353268],
              [-70.751566540922454, -33.553007585956919],
              [-70.756752969051661, -33.553007585956919],
              [-70.759346183116264, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 686,
        left: -7856109.7691027177,
        bottom: -3967938.734192261,
        right: -7854955.0680259597,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.541777350625665],
              [-70.756752969051661, -33.538033614519627],
              [-70.751566540922454, -33.538033614519627],
              [-70.74897332685785, -33.541777350625665],
              [-70.751566540922454, -33.545520924573204],
              [-70.756752969051661, -33.545520924573204],
              [-70.759346183116264, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 687,
        left: -7856109.7691027177,
        bottom: -3966938.7337260107,
        right: -7854955.0680259597,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.534289716264041],
              [-70.756752969051661, -33.530545655867876],
              [-70.751566540922454, -33.530545655867876],
              [-70.74897332685785, -33.534289716264041],
              [-70.751566540922454, -33.538033614519627],
              [-70.756752969051661, -33.538033614519627],
              [-70.759346183116264, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 688,
        left: -7856109.7691027177,
        bottom: -3965938.7332597603,
        right: -7854955.0680259597,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.52680143334009],
              [-70.756752969051661, -33.523057048689658],
              [-70.751566540922454, -33.523057048689658],
              [-70.74897332685785, -33.52680143334009],
              [-70.751566540922454, -33.530545655867876],
              [-70.756752969051661, -33.530545655867876],
              [-70.759346183116264, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 689,
        left: -7856109.7691027177,
        bottom: -3964938.7327935095,
        right: -7854955.0680259597,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.519312501925548],
              [-70.756752969051661, -33.515567793056739],
              [-70.751566540922454, -33.515567793056739],
              [-70.74897332685785, -33.519312501925548],
              [-70.751566540922454, -33.523057048689658],
              [-70.756752969051661, -33.523057048689658],
              [-70.759346183116264, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 690,
        left: -7856109.7691027177,
        bottom: -3963938.7323272596,
        right: -7854955.0680259597,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.511822922092215],
              [-70.756752969051661, -33.508077889040948],
              [-70.751566540922454, -33.508077889040948],
              [-70.74897332685785, -33.511822922092215],
              [-70.751566540922454, -33.515567793056739],
              [-70.756752969051661, -33.515567793056739],
              [-70.759346183116264, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 691,
        left: -7856109.7691027177,
        bottom: -3962938.7318610097,
        right: -7854955.0680259597,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.504332693911934],
              [-70.756752969051661, -33.500587336714155],
              [-70.751566540922454, -33.500587336714155],
              [-70.74897332685785, -33.504332693911934],
              [-70.751566540922454, -33.508077889040948],
              [-70.756752969051661, -33.508077889040948],
              [-70.759346183116264, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 692,
        left: -7856109.7691027177,
        bottom: -3961938.7313947589,
        right: -7854955.0680259597,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.496841817456605],
              [-70.756752969051661, -33.493096136148274],
              [-70.751566540922454, -33.493096136148274],
              [-70.74897332685785, -33.496841817456605],
              [-70.751566540922454, -33.500587336714155],
              [-70.756752969051661, -33.500587336714155],
              [-70.759346183116264, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 693,
        left: -7856109.7691027177,
        bottom: -3960938.7309285086,
        right: -7854955.0680259597,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.489350292798164],
              [-70.756752969051661, -33.485604287415278],
              [-70.751566540922454, -33.485604287415278],
              [-70.74897332685785, -33.489350292798164],
              [-70.751566540922454, -33.493096136148274],
              [-70.756752969051661, -33.493096136148274],
              [-70.759346183116264, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 694,
        left: -7856109.7691027177,
        bottom: -3959938.7304622582,
        right: -7854955.0680259597,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.481858120008617],
              [-70.756752969051661, -33.478111790587178],
              [-70.751566540922454, -33.478111790587178],
              [-70.74897332685785, -33.481858120008617],
              [-70.751566540922454, -33.485604287415278],
              [-70.756752969051661, -33.485604287415278],
              [-70.759346183116264, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 695,
        left: -7856109.7691027177,
        bottom: -3958938.7299960079,
        right: -7854955.0680259597,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.474365299160006],
              [-70.756752969051661, -33.47061864573606],
              [-70.751566540922454, -33.47061864573606],
              [-70.74897332685785, -33.474365299160006],
              [-70.751566540922454, -33.478111790587178],
              [-70.756752969051661, -33.478111790587178],
              [-70.759346183116264, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 696,
        left: -7856109.7691027177,
        bottom: -3957938.7295297575,
        right: -7854955.0680259597,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.466871830324415],
              [-70.756752969051661, -33.463124852934051],
              [-70.751566540922454, -33.463124852934051],
              [-70.74897332685785, -33.466871830324415],
              [-70.751566540922454, -33.47061864573606],
              [-70.756752969051661, -33.47061864573606],
              [-70.759346183116264, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 697,
        left: -7856109.7691027177,
        bottom: -3956938.7290635072,
        right: -7854955.0680259597,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.459377713574014],
              [-70.756752969051661, -33.455630412253299],
              [-70.751566540922454, -33.455630412253299],
              [-70.74897332685785, -33.459377713574014],
              [-70.751566540922454, -33.463124852934051],
              [-70.756752969051661, -33.463124852934051],
              [-70.759346183116264, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 698,
        left: -7856109.7691027177,
        bottom: -3955938.7285972568,
        right: -7854955.0680259597,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.45188294898098],
              [-70.756752969051661, -33.448135323766053],
              [-70.751566540922454, -33.448135323766053],
              [-70.74897332685785, -33.45188294898098],
              [-70.751566540922454, -33.455630412253299],
              [-70.756752969051661, -33.455630412253299],
              [-70.759346183116264, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 699,
        left: -7856109.7691027177,
        bottom: -3954938.7281310065,
        right: -7854955.0680259597,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.444387536617576],
              [-70.756752969051661, -33.440639587544567],
              [-70.751566540922454, -33.440639587544567],
              [-70.74897332685785, -33.444387536617576],
              [-70.751566540922454, -33.448135323766053],
              [-70.756752969051661, -33.448135323766053],
              [-70.759346183116264, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 700,
        left: -7856109.7691027177,
        bottom: -3953938.7276647561,
        right: -7854955.0680259597,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.436891476556099],
              [-70.756752969051661, -33.433143203661182],
              [-70.751566540922454, -33.433143203661182],
              [-70.74897332685785, -33.436891476556099],
              [-70.751566540922454, -33.440639587544567],
              [-70.756752969051661, -33.440639587544567],
              [-70.759346183116264, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 701,
        left: -7856109.7691027177,
        bottom: -3952938.7271985058,
        right: -7854955.0680259597,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.429394768868896],
              [-70.756752969051661, -33.425646172188266],
              [-70.751566540922454, -33.425646172188266],
              [-70.74897332685785, -33.429394768868896],
              [-70.751566540922454, -33.433143203661182],
              [-70.756752969051661, -33.433143203661182],
              [-70.759346183116264, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 702,
        left: -7856109.7691027177,
        bottom: -3951938.7267322554,
        right: -7854955.0680259597,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.421897413628365],
              [-70.756752969051661, -33.418148493198238],
              [-70.751566540922454, -33.418148493198238],
              [-70.74897332685785, -33.421897413628365],
              [-70.751566540922454, -33.425646172188266],
              [-70.756752969051661, -33.425646172188266],
              [-70.759346183116264, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 703,
        left: -7856109.7691027177,
        bottom: -3950938.7262660051,
        right: -7854955.0680259597,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.414399410906967],
              [-70.756752969051661, -33.410650166763581],
              [-70.751566540922454, -33.410650166763581],
              [-70.74897332685785, -33.414399410906967],
              [-70.751566540922454, -33.418148493198238],
              [-70.756752969051661, -33.418148493198238],
              [-70.759346183116264, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 704,
        left: -7856109.7691027177,
        bottom: -3949938.7257997552,
        right: -7854955.0680259597,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.406900760777191],
              [-70.756752969051661, -33.403151192956834],
              [-70.751566540922454, -33.403151192956834],
              [-70.74897332685785, -33.406900760777191],
              [-70.751566540922454, -33.410650166763581],
              [-70.756752969051661, -33.410650166763581],
              [-70.759346183116264, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 705,
        left: -7856109.7691027177,
        bottom: -3948938.7253335048,
        right: -7854955.0680259597,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.399401463311598],
              [-70.756752969051661, -33.39565157185055],
              [-70.751566540922454, -33.39565157185055],
              [-70.74897332685785, -33.399401463311598],
              [-70.751566540922454, -33.403151192956834],
              [-70.756752969051661, -33.403151192956834],
              [-70.759346183116264, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 706,
        left: -7856109.7691027177,
        bottom: -3947938.724867254,
        right: -7854955.0680259597,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.391901518582785],
              [-70.756752969051661, -33.388151303517375],
              [-70.751566540922454, -33.388151303517375],
              [-70.74897332685785, -33.391901518582785],
              [-70.751566540922454, -33.39565157185055],
              [-70.756752969051661, -33.39565157185055],
              [-70.759346183116264, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 707,
        left: -7856109.7691027177,
        bottom: -3946938.7244010041,
        right: -7854955.0680259597,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.384400926663417],
              [-70.756752969051661, -33.380650388029984],
              [-70.751566540922454, -33.380650388029984],
              [-70.74897332685785, -33.384400926663417],
              [-70.751566540922454, -33.388151303517375],
              [-70.756752969051661, -33.388151303517375],
              [-70.759346183116264, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 708,
        left: -7856109.7691027177,
        bottom: -3945938.7239347543,
        right: -7854955.0680259597,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.376899687626185],
              [-70.756752969051661, -33.373148825461101],
              [-70.751566540922454, -33.373148825461101],
              [-70.74897332685785, -33.376899687626185],
              [-70.751566540922454, -33.380650388029984],
              [-70.756752969051661, -33.380650388029984],
              [-70.759346183116264, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 709,
        left: -7856109.7691027177,
        bottom: -3944938.7234685034,
        right: -7854955.0680259597,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.369397801543847],
              [-70.756752969051661, -33.365646615883513],
              [-70.751566540922454, -33.365646615883513],
              [-70.74897332685785, -33.369397801543847],
              [-70.751566540922454, -33.373148825461101],
              [-70.756752969051661, -33.373148825461101],
              [-70.759346183116264, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 710,
        left: -7856109.7691027177,
        bottom: -3943938.7230022531,
        right: -7854955.0680259597,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.361895268489214],
              [-70.756752969051661, -33.358143759370044],
              [-70.751566540922454, -33.358143759370044],
              [-70.74897332685785, -33.361895268489214],
              [-70.751566540922454, -33.365646615883513],
              [-70.756752969051661, -33.365646615883513],
              [-70.759346183116264, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 711,
        left: -7856109.7691027177,
        bottom: -3942938.7225360028,
        right: -7854955.0680259597,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.354392088535135],
              [-70.756752969051661, -33.350640255993589],
              [-70.751566540922454, -33.350640255993589],
              [-70.74897332685785, -33.354392088535135],
              [-70.751566540922454, -33.358143759370044],
              [-70.756752969051661, -33.358143759370044],
              [-70.759346183116264, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 712,
        left: -7856109.7691027177,
        bottom: -3941938.7220697524,
        right: -7854955.0680259597,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.346888261754529],
              [-70.756752969051661, -33.343136105827064],
              [-70.751566540922454, -33.343136105827064],
              [-70.74897332685785, -33.346888261754529],
              [-70.751566540922454, -33.350640255993589],
              [-70.756752969051661, -33.350640255993589],
              [-70.759346183116264, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 713,
        left: -7856109.7691027177,
        bottom: -3940938.7216035021,
        right: -7854955.0680259597,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.339383788220331],
              [-70.756752969051661, -33.335631308943462],
              [-70.751566540922454, -33.335631308943462],
              [-70.74897332685785, -33.339383788220331],
              [-70.751566540922454, -33.343136105827064],
              [-70.756752969051661, -33.343136105827064],
              [-70.759346183116264, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 714,
        left: -7856109.7691027177,
        bottom: -3939938.7211372517,
        right: -7854955.0680259597,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.331878668005565],
              [-70.756752969051661, -33.328125865415807],
              [-70.751566540922454, -33.328125865415807],
              [-70.74897332685785, -33.331878668005565],
              [-70.751566540922454, -33.335631308943462],
              [-70.756752969051661, -33.335631308943462],
              [-70.759346183116264, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 715,
        left: -7856109.7691027177,
        bottom: -3938938.7206710014,
        right: -7854955.0680259597,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.324372901183303],
              [-70.756752969051661, -33.320619775317184],
              [-70.751566540922454, -33.320619775317184],
              [-70.74897332685785, -33.324372901183303],
              [-70.751566540922454, -33.328125865415807],
              [-70.756752969051661, -33.328125865415807],
              [-70.759346183116264, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 716,
        left: -7856109.7691027177,
        bottom: -3937938.720204751,
        right: -7854955.0680259597,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.31686648782663],
              [-70.756752969051661, -33.313113038720743],
              [-70.751566540922454, -33.313113038720743],
              [-70.74897332685785, -33.31686648782663],
              [-70.751566540922454, -33.320619775317184],
              [-70.756752969051661, -33.320619775317184],
              [-70.759346183116264, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 717,
        left: -7856109.7691027177,
        bottom: -3936938.7197385007,
        right: -7854955.0680259597,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.309359428008712],
              [-70.756752969051661, -33.305605655699658],
              [-70.751566540922454, -33.305605655699658],
              [-70.74897332685785, -33.309359428008712],
              [-70.751566540922454, -33.313113038720743],
              [-70.756752969051661, -33.313113038720743],
              [-70.759346183116264, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 718,
        left: -7856109.7691027177,
        bottom: -3935938.7192722503,
        right: -7854955.0680259597,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.301851721802763],
              [-70.756752969051661, -33.298097626327156],
              [-70.751566540922454, -33.298097626327156],
              [-70.74897332685785, -33.301851721802763],
              [-70.751566540922454, -33.305605655699658],
              [-70.756752969051661, -33.305605655699658],
              [-70.759346183116264, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 719,
        left: -7856109.7691027177,
        bottom: -3934938.718806,
        right: -7854955.0680259597,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.294343369282039],
              [-70.756752969051661, -33.29058895067655],
              [-70.751566540922454, -33.29058895067655],
              [-70.74897332685785, -33.294343369282039],
              [-70.751566540922454, -33.298097626327156],
              [-70.756752969051661, -33.298097626327156],
              [-70.759346183116264, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 720,
        left: -7856109.7691027177,
        bottom: -3933938.7183397496,
        right: -7854955.0680259597,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.759346183116264, -33.286834370519863],
              [-70.756752969051661, -33.283079628821156],
              [-70.751566540922454, -33.283079628821156],
              [-70.74897332685785, -33.286834370519863],
              [-70.751566540922454, -33.29058895067655],
              [-70.756752969051661, -33.29058895067655],
              [-70.759346183116264, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 721,
        left: -7855243.7432951499,
        bottom: -3980438.7400203901,
        right: -7854089.0422183899,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.635318028521546],
              [-70.74897332685785, -33.631578349057953],
              [-70.743786898728629, -33.631578349057953],
              [-70.74119368466404, -33.635318028521546],
              [-70.743786898728629, -33.639057545603663],
              [-70.74897332685785, -33.639057545603663],
              [-70.751566540922454, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 722,
        left: -7855243.7432951499,
        bottom: -3979438.7395541398,
        right: -7854089.0422183899,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.627838507221767],
              [-70.74897332685785, -33.624098503021855],
              [-70.743786898728629, -33.624098503021855],
              [-70.74119368466404, -33.627838507221767],
              [-70.743786898728629, -33.631578349057953],
              [-70.74897332685785, -33.631578349057953],
              [-70.751566540922454, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 723,
        left: -7855243.7432951499,
        bottom: -3978438.7390878894,
        right: -7854089.0422183899,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.620358336467127],
              [-70.74897332685785, -33.616618007566458],
              [-70.743786898728629, -33.616618007566458],
              [-70.74119368466404, -33.620358336467127],
              [-70.743786898728629, -33.624098503021855],
              [-70.74897332685785, -33.624098503021855],
              [-70.751566540922454, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 724,
        left: -7855243.7432951499,
        bottom: -3977438.7386216391,
        right: -7854089.0422183899,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.612877516328737],
              [-70.74897332685785, -33.609136862762881],
              [-70.743786898728629, -33.609136862762881],
              [-70.74119368466404, -33.612877516328737],
              [-70.743786898728629, -33.616618007566458],
              [-70.74897332685785, -33.616618007566458],
              [-70.751566540922454, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 725,
        left: -7855243.7432951499,
        bottom: -3976438.7381553887,
        right: -7854089.0422183899,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.605396046877765],
              [-70.74897332685785, -33.601655068682327],
              [-70.743786898728629, -33.601655068682327],
              [-70.74119368466404, -33.605396046877765],
              [-70.743786898728629, -33.609136862762881],
              [-70.74897332685785, -33.609136862762881],
              [-70.751566540922454, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 726,
        left: -7855243.7432951499,
        bottom: -3975438.7376891384,
        right: -7854089.0422183899,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.597913928185434],
              [-70.74897332685785, -33.594172625396013],
              [-70.743786898728629, -33.594172625396013],
              [-70.74119368466404, -33.597913928185434],
              [-70.743786898728629, -33.601655068682327],
              [-70.74897332685785, -33.601655068682327],
              [-70.751566540922454, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 727,
        left: -7855243.7432951499,
        bottom: -3974438.7372228876,
        right: -7854089.0422183899,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.590431160322979],
              [-70.74897332685785, -33.586689532975264],
              [-70.743786898728629, -33.586689532975264],
              [-70.74119368466404, -33.590431160322979],
              [-70.743786898728629, -33.594172625396013],
              [-70.74897332685785, -33.594172625396013],
              [-70.751566540922454, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 728,
        left: -7855243.7432951499,
        bottom: -3973438.7367566377,
        right: -7854089.0422183899,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.58294774336175],
              [-70.74897332685785, -33.579205791491383],
              [-70.743786898728629, -33.579205791491383],
              [-70.74119368466404, -33.58294774336175],
              [-70.743786898728629, -33.586689532975264],
              [-70.74897332685785, -33.586689532975264],
              [-70.751566540922454, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 729,
        left: -7855243.7432951499,
        bottom: -3972438.7362903878,
        right: -7854089.0422183899,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.575463677373087],
              [-70.74897332685785, -33.571721401015779],
              [-70.743786898728629, -33.571721401015779],
              [-70.74119368466404, -33.575463677373087],
              [-70.743786898728629, -33.579205791491383],
              [-70.74897332685785, -33.579205791491383],
              [-70.751566540922454, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 730,
        left: -7855243.7432951499,
        bottom: -3971438.735824137,
        right: -7854089.0422183899,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.567978962428406],
              [-70.74897332685785, -33.564236361619891],
              [-70.743786898728629, -33.564236361619891],
              [-70.74119368466404, -33.567978962428406],
              [-70.743786898728629, -33.571721401015779],
              [-70.74897332685785, -33.571721401015779],
              [-70.751566540922454, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 731,
        left: -7855243.7432951499,
        bottom: -3970438.7353578866,
        right: -7854089.0422183899,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.56049359859918],
              [-70.74897332685785, -33.556750673375213],
              [-70.743786898728629, -33.556750673375213],
              [-70.74119368466404, -33.56049359859918],
              [-70.743786898728629, -33.564236361619891],
              [-70.74897332685785, -33.564236361619891],
              [-70.751566540922454, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 732,
        left: -7855243.7432951499,
        bottom: -3969438.7348916363,
        right: -7854089.0422183899,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.553007585956919],
              [-70.74897332685785, -33.549264336353268],
              [-70.743786898728629, -33.549264336353268],
              [-70.74119368466404, -33.553007585956919],
              [-70.743786898728629, -33.556750673375213],
              [-70.74897332685785, -33.556750673375213],
              [-70.751566540922454, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 733,
        left: -7855243.7432951499,
        bottom: -3968438.7344253859,
        right: -7854089.0422183899,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.545520924573204],
              [-70.74897332685785, -33.541777350625665],
              [-70.743786898728629, -33.541777350625665],
              [-70.74119368466404, -33.545520924573204],
              [-70.743786898728629, -33.549264336353268],
              [-70.74897332685785, -33.549264336353268],
              [-70.751566540922454, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 734,
        left: -7855243.7432951499,
        bottom: -3967438.7339591356,
        right: -7854089.0422183899,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.538033614519627],
              [-70.74897332685785, -33.534289716264041],
              [-70.743786898728629, -33.534289716264041],
              [-70.74119368466404, -33.538033614519627],
              [-70.743786898728629, -33.541777350625665],
              [-70.74897332685785, -33.541777350625665],
              [-70.751566540922454, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 735,
        left: -7855243.7432951499,
        bottom: -3966438.7334928853,
        right: -7854089.0422183899,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.530545655867876],
              [-70.74897332685785, -33.52680143334009],
              [-70.743786898728629, -33.52680143334009],
              [-70.74119368466404, -33.530545655867876],
              [-70.743786898728629, -33.534289716264041],
              [-70.74897332685785, -33.534289716264041],
              [-70.751566540922454, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 736,
        left: -7855243.7432951499,
        bottom: -3965438.7330266349,
        right: -7854089.0422183899,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.523057048689658],
              [-70.74897332685785, -33.519312501925548],
              [-70.743786898728629, -33.519312501925548],
              [-70.74119368466404, -33.523057048689658],
              [-70.743786898728629, -33.52680143334009],
              [-70.74897332685785, -33.52680143334009],
              [-70.751566540922454, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 737,
        left: -7855243.7432951499,
        bottom: -3964438.7325603846,
        right: -7854089.0422183899,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.515567793056739],
              [-70.74897332685785, -33.511822922092215],
              [-70.743786898728629, -33.511822922092215],
              [-70.74119368466404, -33.515567793056739],
              [-70.743786898728629, -33.519312501925548],
              [-70.74897332685785, -33.519312501925548],
              [-70.751566540922454, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 738,
        left: -7855243.7432951499,
        bottom: -3963438.7320941342,
        right: -7854089.0422183899,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.508077889040948],
              [-70.74897332685785, -33.504332693911934],
              [-70.743786898728629, -33.504332693911934],
              [-70.74119368466404, -33.508077889040948],
              [-70.743786898728629, -33.511822922092215],
              [-70.74897332685785, -33.511822922092215],
              [-70.751566540922454, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 739,
        left: -7855243.7432951499,
        bottom: -3962438.7316278839,
        right: -7854089.0422183899,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.500587336714155],
              [-70.74897332685785, -33.496841817456605],
              [-70.743786898728629, -33.496841817456605],
              [-70.74119368466404, -33.500587336714155],
              [-70.743786898728629, -33.504332693911934],
              [-70.74897332685785, -33.504332693911934],
              [-70.751566540922454, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 740,
        left: -7855243.7432951499,
        bottom: -3961438.7311616335,
        right: -7854089.0422183899,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.493096136148274],
              [-70.74897332685785, -33.489350292798164],
              [-70.743786898728629, -33.489350292798164],
              [-70.74119368466404, -33.493096136148274],
              [-70.743786898728629, -33.496841817456605],
              [-70.74897332685785, -33.496841817456605],
              [-70.751566540922454, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 741,
        left: -7855243.7432951499,
        bottom: -3960438.7306953832,
        right: -7854089.0422183899,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.485604287415278],
              [-70.74897332685785, -33.481858120008617],
              [-70.743786898728629, -33.481858120008617],
              [-70.74119368466404, -33.485604287415278],
              [-70.743786898728629, -33.489350292798164],
              [-70.74897332685785, -33.489350292798164],
              [-70.751566540922454, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 742,
        left: -7855243.7432951499,
        bottom: -3959438.7302291333,
        right: -7854089.0422183899,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.478111790587178],
              [-70.74897332685785, -33.474365299160006],
              [-70.743786898728629, -33.474365299160006],
              [-70.74119368466404, -33.478111790587178],
              [-70.743786898728629, -33.481858120008617],
              [-70.74897332685785, -33.481858120008617],
              [-70.751566540922454, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 743,
        left: -7855243.7432951499,
        bottom: -3958438.7297628829,
        right: -7854089.0422183899,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.47061864573606],
              [-70.74897332685785, -33.466871830324415],
              [-70.743786898728629, -33.466871830324415],
              [-70.74119368466404, -33.47061864573606],
              [-70.743786898728629, -33.474365299160006],
              [-70.74897332685785, -33.474365299160006],
              [-70.751566540922454, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 744,
        left: -7855243.7432951499,
        bottom: -3957438.7292966326,
        right: -7854089.0422183899,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.463124852934051],
              [-70.74897332685785, -33.459377713574014],
              [-70.743786898728629, -33.459377713574014],
              [-70.74119368466404, -33.463124852934051],
              [-70.743786898728629, -33.466871830324415],
              [-70.74897332685785, -33.466871830324415],
              [-70.751566540922454, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 745,
        left: -7855243.7432951499,
        bottom: -3956438.7288303822,
        right: -7854089.0422183899,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.455630412253299],
              [-70.74897332685785, -33.45188294898098],
              [-70.743786898728629, -33.45188294898098],
              [-70.74119368466404, -33.455630412253299],
              [-70.743786898728629, -33.459377713574014],
              [-70.74897332685785, -33.459377713574014],
              [-70.751566540922454, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 746,
        left: -7855243.7432951499,
        bottom: -3955438.7283641323,
        right: -7854089.0422183899,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.448135323766053],
              [-70.74897332685785, -33.444387536617576],
              [-70.743786898728629, -33.444387536617576],
              [-70.74119368466404, -33.448135323766053],
              [-70.743786898728629, -33.45188294898098],
              [-70.74897332685785, -33.45188294898098],
              [-70.751566540922454, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 747,
        left: -7855243.7432951499,
        bottom: -3954438.7278978815,
        right: -7854089.0422183899,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.440639587544567],
              [-70.74897332685785, -33.436891476556099],
              [-70.743786898728629, -33.436891476556099],
              [-70.74119368466404, -33.440639587544567],
              [-70.743786898728629, -33.444387536617576],
              [-70.74897332685785, -33.444387536617576],
              [-70.751566540922454, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 748,
        left: -7855243.7432951499,
        bottom: -3953438.7274316312,
        right: -7854089.0422183899,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.433143203661182],
              [-70.74897332685785, -33.429394768868896],
              [-70.743786898728629, -33.429394768868896],
              [-70.74119368466404, -33.433143203661182],
              [-70.743786898728629, -33.436891476556099],
              [-70.74897332685785, -33.436891476556099],
              [-70.751566540922454, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 749,
        left: -7855243.7432951499,
        bottom: -3952438.7269653808,
        right: -7854089.0422183899,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.425646172188266],
              [-70.74897332685785, -33.421897413628365],
              [-70.743786898728629, -33.421897413628365],
              [-70.74119368466404, -33.425646172188266],
              [-70.743786898728629, -33.429394768868896],
              [-70.74897332685785, -33.429394768868896],
              [-70.751566540922454, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 750,
        left: -7855243.7432951499,
        bottom: -3951438.7264991305,
        right: -7854089.0422183899,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.418148493198238],
              [-70.74897332685785, -33.414399410906967],
              [-70.743786898728629, -33.414399410906967],
              [-70.74119368466404, -33.418148493198238],
              [-70.743786898728629, -33.421897413628365],
              [-70.74897332685785, -33.421897413628365],
              [-70.751566540922454, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 751,
        left: -7855243.7432951499,
        bottom: -3950438.7260328801,
        right: -7854089.0422183899,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.410650166763581],
              [-70.74897332685785, -33.406900760777191],
              [-70.743786898728629, -33.406900760777191],
              [-70.74119368466404, -33.410650166763581],
              [-70.743786898728629, -33.414399410906967],
              [-70.74897332685785, -33.414399410906967],
              [-70.751566540922454, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 752,
        left: -7855243.7432951499,
        bottom: -3949438.7255666298,
        right: -7854089.0422183899,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.403151192956834],
              [-70.74897332685785, -33.399401463311598],
              [-70.743786898728629, -33.399401463311598],
              [-70.74119368466404, -33.403151192956834],
              [-70.743786898728629, -33.406900760777191],
              [-70.74897332685785, -33.406900760777191],
              [-70.751566540922454, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 753,
        left: -7855243.7432951499,
        bottom: -3948438.7251003794,
        right: -7854089.0422183899,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.39565157185055],
              [-70.74897332685785, -33.391901518582785],
              [-70.743786898728629, -33.391901518582785],
              [-70.74119368466404, -33.39565157185055],
              [-70.743786898728629, -33.399401463311598],
              [-70.74897332685785, -33.399401463311598],
              [-70.751566540922454, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 754,
        left: -7855243.7432951499,
        bottom: -3947438.7246341291,
        right: -7854089.0422183899,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.388151303517375],
              [-70.74897332685785, -33.384400926663417],
              [-70.743786898728629, -33.384400926663417],
              [-70.74119368466404, -33.388151303517375],
              [-70.743786898728629, -33.391901518582785],
              [-70.74897332685785, -33.391901518582785],
              [-70.751566540922454, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 755,
        left: -7855243.7432951499,
        bottom: -3946438.7241678787,
        right: -7854089.0422183899,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.380650388029984],
              [-70.74897332685785, -33.376899687626185],
              [-70.743786898728629, -33.376899687626185],
              [-70.74119368466404, -33.380650388029984],
              [-70.743786898728629, -33.384400926663417],
              [-70.74897332685785, -33.384400926663417],
              [-70.751566540922454, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 756,
        left: -7855243.7432951499,
        bottom: -3945438.7237016284,
        right: -7854089.0422183899,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.373148825461101],
              [-70.74897332685785, -33.369397801543847],
              [-70.743786898728629, -33.369397801543847],
              [-70.74119368466404, -33.373148825461101],
              [-70.743786898728629, -33.376899687626185],
              [-70.74897332685785, -33.376899687626185],
              [-70.751566540922454, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 757,
        left: -7855243.7432951499,
        bottom: -3944438.723235378,
        right: -7854089.0422183899,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.365646615883513],
              [-70.74897332685785, -33.361895268489214],
              [-70.743786898728629, -33.361895268489214],
              [-70.74119368466404, -33.365646615883513],
              [-70.743786898728629, -33.369397801543847],
              [-70.74897332685785, -33.369397801543847],
              [-70.751566540922454, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 758,
        left: -7855243.7432951499,
        bottom: -3943438.7227691277,
        right: -7854089.0422183899,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.358143759370044],
              [-70.74897332685785, -33.354392088535135],
              [-70.743786898728629, -33.354392088535135],
              [-70.74119368466404, -33.358143759370044],
              [-70.743786898728629, -33.361895268489214],
              [-70.74897332685785, -33.361895268489214],
              [-70.751566540922454, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 759,
        left: -7855243.7432951499,
        bottom: -3942438.7223028769,
        right: -7854089.0422183899,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.350640255993589],
              [-70.74897332685785, -33.346888261754529],
              [-70.743786898728629, -33.346888261754529],
              [-70.74119368466404, -33.350640255993589],
              [-70.743786898728629, -33.354392088535135],
              [-70.74897332685785, -33.354392088535135],
              [-70.751566540922454, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 760,
        left: -7855243.7432951499,
        bottom: -3941438.721836627,
        right: -7854089.0422183899,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.343136105827064],
              [-70.74897332685785, -33.339383788220331],
              [-70.743786898728629, -33.339383788220331],
              [-70.74119368466404, -33.343136105827064],
              [-70.743786898728629, -33.346888261754529],
              [-70.74897332685785, -33.346888261754529],
              [-70.751566540922454, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 761,
        left: -7855243.7432951499,
        bottom: -3940438.7213703771,
        right: -7854089.0422183899,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.335631308943462],
              [-70.74897332685785, -33.331878668005565],
              [-70.743786898728629, -33.331878668005565],
              [-70.74119368466404, -33.335631308943462],
              [-70.743786898728629, -33.339383788220331],
              [-70.74897332685785, -33.339383788220331],
              [-70.751566540922454, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 762,
        left: -7855243.7432951499,
        bottom: -3939438.7209041263,
        right: -7854089.0422183899,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.328125865415807],
              [-70.74897332685785, -33.324372901183303],
              [-70.743786898728629, -33.324372901183303],
              [-70.74119368466404, -33.328125865415807],
              [-70.743786898728629, -33.331878668005565],
              [-70.74897332685785, -33.331878668005565],
              [-70.751566540922454, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 763,
        left: -7855243.7432951499,
        bottom: -3938438.7204378759,
        right: -7854089.0422183899,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.320619775317184],
              [-70.74897332685785, -33.31686648782663],
              [-70.743786898728629, -33.31686648782663],
              [-70.74119368466404, -33.320619775317184],
              [-70.743786898728629, -33.324372901183303],
              [-70.74897332685785, -33.324372901183303],
              [-70.751566540922454, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 764,
        left: -7855243.7432951499,
        bottom: -3937438.7199716261,
        right: -7854089.0422183899,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.313113038720743],
              [-70.74897332685785, -33.309359428008712],
              [-70.743786898728629, -33.309359428008712],
              [-70.74119368466404, -33.313113038720743],
              [-70.743786898728629, -33.31686648782663],
              [-70.74897332685785, -33.31686648782663],
              [-70.751566540922454, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 765,
        left: -7855243.7432951499,
        bottom: -3936438.7195053762,
        right: -7854089.0422183899,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.305605655699658],
              [-70.74897332685785, -33.301851721802763],
              [-70.743786898728629, -33.301851721802763],
              [-70.74119368466404, -33.305605655699658],
              [-70.743786898728629, -33.309359428008712],
              [-70.74897332685785, -33.309359428008712],
              [-70.751566540922454, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 766,
        left: -7855243.7432951499,
        bottom: -3935438.7190391254,
        right: -7854089.0422183899,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.298097626327156],
              [-70.74897332685785, -33.294343369282039],
              [-70.743786898728629, -33.294343369282039],
              [-70.74119368466404, -33.298097626327156],
              [-70.743786898728629, -33.301851721802763],
              [-70.74897332685785, -33.301851721802763],
              [-70.751566540922454, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 767,
        left: -7855243.7432951499,
        bottom: -3934438.718572875,
        right: -7854089.0422183899,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.29058895067655],
              [-70.74897332685785, -33.286834370519863],
              [-70.743786898728629, -33.286834370519863],
              [-70.74119368466404, -33.29058895067655],
              [-70.743786898728629, -33.294343369282039],
              [-70.74897332685785, -33.294343369282039],
              [-70.751566540922454, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 768,
        left: -7855243.7432951499,
        bottom: -3933438.7181066247,
        right: -7854089.0422183899,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.751566540922454, -33.283079628821156],
              [-70.74897332685785, -33.279324725589596],
              [-70.743786898728629, -33.279324725589596],
              [-70.74119368466404, -33.283079628821156],
              [-70.743786898728629, -33.286834370519863],
              [-70.74897332685785, -33.286834370519863],
              [-70.751566540922454, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 769,
        left: -7854377.7174875792,
        bottom: -3980938.7402535151,
        right: -7853223.0164108202,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.639057545603663],
              [-70.74119368466404, -33.635318028521546],
              [-70.736007256534833, -33.635318028521546],
              [-70.733414042470216, -33.639057545603663],
              [-70.736007256534833, -33.642796900295409],
              [-70.74119368466404, -33.642796900295409],
              [-70.743786898728629, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 770,
        left: -7854377.7174875792,
        bottom: -3979938.7397872647,
        right: -7853223.0164108202,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.631578349057953],
              [-70.74119368466404, -33.627838507221767],
              [-70.736007256534833, -33.627838507221767],
              [-70.733414042470216, -33.631578349057953],
              [-70.736007256534833, -33.635318028521546],
              [-70.74119368466404, -33.635318028521546],
              [-70.743786898728629, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 771,
        left: -7854377.7174875792,
        bottom: -3978938.7393210144,
        right: -7853223.0164108202,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.624098503021855],
              [-70.74119368466404, -33.620358336467127],
              [-70.736007256534833, -33.620358336467127],
              [-70.733414042470216, -33.624098503021855],
              [-70.736007256534833, -33.627838507221767],
              [-70.74119368466404, -33.627838507221767],
              [-70.743786898728629, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 772,
        left: -7854377.7174875792,
        bottom: -3977938.738854764,
        right: -7853223.0164108202,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.616618007566458],
              [-70.74119368466404, -33.612877516328737],
              [-70.736007256534833, -33.612877516328737],
              [-70.733414042470216, -33.616618007566458],
              [-70.736007256534833, -33.620358336467127],
              [-70.74119368466404, -33.620358336467127],
              [-70.743786898728629, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 773,
        left: -7854377.7174875792,
        bottom: -3976938.7383885137,
        right: -7853223.0164108202,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.609136862762881],
              [-70.74119368466404, -33.605396046877765],
              [-70.736007256534833, -33.605396046877765],
              [-70.733414042470216, -33.609136862762881],
              [-70.736007256534833, -33.612877516328737],
              [-70.74119368466404, -33.612877516328737],
              [-70.743786898728629, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 774,
        left: -7854377.7174875792,
        bottom: -3975938.7379222633,
        right: -7853223.0164108202,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.601655068682327],
              [-70.74119368466404, -33.597913928185434],
              [-70.736007256534833, -33.597913928185434],
              [-70.733414042470216, -33.601655068682327],
              [-70.736007256534833, -33.605396046877765],
              [-70.74119368466404, -33.605396046877765],
              [-70.743786898728629, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 775,
        left: -7854377.7174875792,
        bottom: -3974938.737456013,
        right: -7853223.0164108202,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.594172625396013],
              [-70.74119368466404, -33.590431160322979],
              [-70.736007256534833, -33.590431160322979],
              [-70.733414042470216, -33.594172625396013],
              [-70.736007256534833, -33.597913928185434],
              [-70.74119368466404, -33.597913928185434],
              [-70.743786898728629, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 776,
        left: -7854377.7174875792,
        bottom: -3973938.7369897626,
        right: -7853223.0164108202,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.586689532975264],
              [-70.74119368466404, -33.58294774336175],
              [-70.736007256534833, -33.58294774336175],
              [-70.733414042470216, -33.586689532975264],
              [-70.736007256534833, -33.590431160322979],
              [-70.74119368466404, -33.590431160322979],
              [-70.743786898728629, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 777,
        left: -7854377.7174875792,
        bottom: -3972938.7365235123,
        right: -7853223.0164108202,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.579205791491383],
              [-70.74119368466404, -33.575463677373087],
              [-70.736007256534833, -33.575463677373087],
              [-70.733414042470216, -33.579205791491383],
              [-70.736007256534833, -33.58294774336175],
              [-70.74119368466404, -33.58294774336175],
              [-70.743786898728629, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 778,
        left: -7854377.7174875792,
        bottom: -3971938.7360572619,
        right: -7853223.0164108202,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.571721401015779],
              [-70.74119368466404, -33.567978962428406],
              [-70.736007256534833, -33.567978962428406],
              [-70.733414042470216, -33.571721401015779],
              [-70.736007256534833, -33.575463677373087],
              [-70.74119368466404, -33.575463677373087],
              [-70.743786898728629, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 779,
        left: -7854377.7174875792,
        bottom: -3970938.7355910116,
        right: -7853223.0164108202,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.564236361619891],
              [-70.74119368466404, -33.56049359859918],
              [-70.736007256534833, -33.56049359859918],
              [-70.733414042470216, -33.564236361619891],
              [-70.736007256534833, -33.567978962428406],
              [-70.74119368466404, -33.567978962428406],
              [-70.743786898728629, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 780,
        left: -7854377.7174875792,
        bottom: -3969938.7351247617,
        right: -7853223.0164108202,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.556750673375213],
              [-70.74119368466404, -33.553007585956919],
              [-70.736007256534833, -33.553007585956919],
              [-70.733414042470216, -33.556750673375213],
              [-70.736007256534833, -33.56049359859918],
              [-70.74119368466404, -33.56049359859918],
              [-70.743786898728629, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 781,
        left: -7854377.7174875792,
        bottom: -3968938.7346585114,
        right: -7853223.0164108202,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.549264336353268],
              [-70.74119368466404, -33.545520924573204],
              [-70.736007256534833, -33.545520924573204],
              [-70.733414042470216, -33.549264336353268],
              [-70.736007256534833, -33.553007585956919],
              [-70.74119368466404, -33.553007585956919],
              [-70.743786898728629, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 782,
        left: -7854377.7174875792,
        bottom: -3967938.734192261,
        right: -7853223.0164108202,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.541777350625665],
              [-70.74119368466404, -33.538033614519627],
              [-70.736007256534833, -33.538033614519627],
              [-70.733414042470216, -33.541777350625665],
              [-70.736007256534833, -33.545520924573204],
              [-70.74119368466404, -33.545520924573204],
              [-70.743786898728629, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 783,
        left: -7854377.7174875792,
        bottom: -3966938.7337260107,
        right: -7853223.0164108202,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.534289716264041],
              [-70.74119368466404, -33.530545655867876],
              [-70.736007256534833, -33.530545655867876],
              [-70.733414042470216, -33.534289716264041],
              [-70.736007256534833, -33.538033614519627],
              [-70.74119368466404, -33.538033614519627],
              [-70.743786898728629, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 784,
        left: -7854377.7174875792,
        bottom: -3965938.7332597603,
        right: -7853223.0164108202,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.52680143334009],
              [-70.74119368466404, -33.523057048689658],
              [-70.736007256534833, -33.523057048689658],
              [-70.733414042470216, -33.52680143334009],
              [-70.736007256534833, -33.530545655867876],
              [-70.74119368466404, -33.530545655867876],
              [-70.743786898728629, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 785,
        left: -7854377.7174875792,
        bottom: -3964938.7327935095,
        right: -7853223.0164108202,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.519312501925548],
              [-70.74119368466404, -33.515567793056739],
              [-70.736007256534833, -33.515567793056739],
              [-70.733414042470216, -33.519312501925548],
              [-70.736007256534833, -33.523057048689658],
              [-70.74119368466404, -33.523057048689658],
              [-70.743786898728629, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 786,
        left: -7854377.7174875792,
        bottom: -3963938.7323272596,
        right: -7853223.0164108202,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.511822922092215],
              [-70.74119368466404, -33.508077889040948],
              [-70.736007256534833, -33.508077889040948],
              [-70.733414042470216, -33.511822922092215],
              [-70.736007256534833, -33.515567793056739],
              [-70.74119368466404, -33.515567793056739],
              [-70.743786898728629, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 787,
        left: -7854377.7174875792,
        bottom: -3962938.7318610097,
        right: -7853223.0164108202,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.504332693911934],
              [-70.74119368466404, -33.500587336714155],
              [-70.736007256534833, -33.500587336714155],
              [-70.733414042470216, -33.504332693911934],
              [-70.736007256534833, -33.508077889040948],
              [-70.74119368466404, -33.508077889040948],
              [-70.743786898728629, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 788,
        left: -7854377.7174875792,
        bottom: -3961938.7313947589,
        right: -7853223.0164108202,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.496841817456605],
              [-70.74119368466404, -33.493096136148274],
              [-70.736007256534833, -33.493096136148274],
              [-70.733414042470216, -33.496841817456605],
              [-70.736007256534833, -33.500587336714155],
              [-70.74119368466404, -33.500587336714155],
              [-70.743786898728629, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 789,
        left: -7854377.7174875792,
        bottom: -3960938.7309285086,
        right: -7853223.0164108202,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.489350292798164],
              [-70.74119368466404, -33.485604287415278],
              [-70.736007256534833, -33.485604287415278],
              [-70.733414042470216, -33.489350292798164],
              [-70.736007256534833, -33.493096136148274],
              [-70.74119368466404, -33.493096136148274],
              [-70.743786898728629, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 790,
        left: -7854377.7174875792,
        bottom: -3959938.7304622582,
        right: -7853223.0164108202,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.481858120008617],
              [-70.74119368466404, -33.478111790587178],
              [-70.736007256534833, -33.478111790587178],
              [-70.733414042470216, -33.481858120008617],
              [-70.736007256534833, -33.485604287415278],
              [-70.74119368466404, -33.485604287415278],
              [-70.743786898728629, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 791,
        left: -7854377.7174875792,
        bottom: -3958938.7299960079,
        right: -7853223.0164108202,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.474365299160006],
              [-70.74119368466404, -33.47061864573606],
              [-70.736007256534833, -33.47061864573606],
              [-70.733414042470216, -33.474365299160006],
              [-70.736007256534833, -33.478111790587178],
              [-70.74119368466404, -33.478111790587178],
              [-70.743786898728629, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 792,
        left: -7854377.7174875792,
        bottom: -3957938.7295297575,
        right: -7853223.0164108202,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.466871830324415],
              [-70.74119368466404, -33.463124852934051],
              [-70.736007256534833, -33.463124852934051],
              [-70.733414042470216, -33.466871830324415],
              [-70.736007256534833, -33.47061864573606],
              [-70.74119368466404, -33.47061864573606],
              [-70.743786898728629, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 793,
        left: -7854377.7174875792,
        bottom: -3956938.7290635072,
        right: -7853223.0164108202,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.459377713574014],
              [-70.74119368466404, -33.455630412253299],
              [-70.736007256534833, -33.455630412253299],
              [-70.733414042470216, -33.459377713574014],
              [-70.736007256534833, -33.463124852934051],
              [-70.74119368466404, -33.463124852934051],
              [-70.743786898728629, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 794,
        left: -7854377.7174875792,
        bottom: -3955938.7285972568,
        right: -7853223.0164108202,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.45188294898098],
              [-70.74119368466404, -33.448135323766053],
              [-70.736007256534833, -33.448135323766053],
              [-70.733414042470216, -33.45188294898098],
              [-70.736007256534833, -33.455630412253299],
              [-70.74119368466404, -33.455630412253299],
              [-70.743786898728629, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 795,
        left: -7854377.7174875792,
        bottom: -3954938.7281310065,
        right: -7853223.0164108202,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.444387536617576],
              [-70.74119368466404, -33.440639587544567],
              [-70.736007256534833, -33.440639587544567],
              [-70.733414042470216, -33.444387536617576],
              [-70.736007256534833, -33.448135323766053],
              [-70.74119368466404, -33.448135323766053],
              [-70.743786898728629, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 796,
        left: -7854377.7174875792,
        bottom: -3953938.7276647561,
        right: -7853223.0164108202,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.436891476556099],
              [-70.74119368466404, -33.433143203661182],
              [-70.736007256534833, -33.433143203661182],
              [-70.733414042470216, -33.436891476556099],
              [-70.736007256534833, -33.440639587544567],
              [-70.74119368466404, -33.440639587544567],
              [-70.743786898728629, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 797,
        left: -7854377.7174875792,
        bottom: -3952938.7271985058,
        right: -7853223.0164108202,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.429394768868896],
              [-70.74119368466404, -33.425646172188266],
              [-70.736007256534833, -33.425646172188266],
              [-70.733414042470216, -33.429394768868896],
              [-70.736007256534833, -33.433143203661182],
              [-70.74119368466404, -33.433143203661182],
              [-70.743786898728629, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 798,
        left: -7854377.7174875792,
        bottom: -3951938.7267322554,
        right: -7853223.0164108202,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.421897413628365],
              [-70.74119368466404, -33.418148493198238],
              [-70.736007256534833, -33.418148493198238],
              [-70.733414042470216, -33.421897413628365],
              [-70.736007256534833, -33.425646172188266],
              [-70.74119368466404, -33.425646172188266],
              [-70.743786898728629, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 799,
        left: -7854377.7174875792,
        bottom: -3950938.7262660051,
        right: -7853223.0164108202,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.414399410906967],
              [-70.74119368466404, -33.410650166763581],
              [-70.736007256534833, -33.410650166763581],
              [-70.733414042470216, -33.414399410906967],
              [-70.736007256534833, -33.418148493198238],
              [-70.74119368466404, -33.418148493198238],
              [-70.743786898728629, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 800,
        left: -7854377.7174875792,
        bottom: -3949938.7257997552,
        right: -7853223.0164108202,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.406900760777191],
              [-70.74119368466404, -33.403151192956834],
              [-70.736007256534833, -33.403151192956834],
              [-70.733414042470216, -33.406900760777191],
              [-70.736007256534833, -33.410650166763581],
              [-70.74119368466404, -33.410650166763581],
              [-70.743786898728629, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 801,
        left: -7854377.7174875792,
        bottom: -3948938.7253335048,
        right: -7853223.0164108202,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.399401463311598],
              [-70.74119368466404, -33.39565157185055],
              [-70.736007256534833, -33.39565157185055],
              [-70.733414042470216, -33.399401463311598],
              [-70.736007256534833, -33.403151192956834],
              [-70.74119368466404, -33.403151192956834],
              [-70.743786898728629, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 802,
        left: -7854377.7174875792,
        bottom: -3947938.724867254,
        right: -7853223.0164108202,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.391901518582785],
              [-70.74119368466404, -33.388151303517375],
              [-70.736007256534833, -33.388151303517375],
              [-70.733414042470216, -33.391901518582785],
              [-70.736007256534833, -33.39565157185055],
              [-70.74119368466404, -33.39565157185055],
              [-70.743786898728629, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 803,
        left: -7854377.7174875792,
        bottom: -3946938.7244010041,
        right: -7853223.0164108202,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.384400926663417],
              [-70.74119368466404, -33.380650388029984],
              [-70.736007256534833, -33.380650388029984],
              [-70.733414042470216, -33.384400926663417],
              [-70.736007256534833, -33.388151303517375],
              [-70.74119368466404, -33.388151303517375],
              [-70.743786898728629, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 804,
        left: -7854377.7174875792,
        bottom: -3945938.7239347543,
        right: -7853223.0164108202,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.376899687626185],
              [-70.74119368466404, -33.373148825461101],
              [-70.736007256534833, -33.373148825461101],
              [-70.733414042470216, -33.376899687626185],
              [-70.736007256534833, -33.380650388029984],
              [-70.74119368466404, -33.380650388029984],
              [-70.743786898728629, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 805,
        left: -7854377.7174875792,
        bottom: -3944938.7234685034,
        right: -7853223.0164108202,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.369397801543847],
              [-70.74119368466404, -33.365646615883513],
              [-70.736007256534833, -33.365646615883513],
              [-70.733414042470216, -33.369397801543847],
              [-70.736007256534833, -33.373148825461101],
              [-70.74119368466404, -33.373148825461101],
              [-70.743786898728629, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 806,
        left: -7854377.7174875792,
        bottom: -3943938.7230022531,
        right: -7853223.0164108202,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.361895268489214],
              [-70.74119368466404, -33.358143759370044],
              [-70.736007256534833, -33.358143759370044],
              [-70.733414042470216, -33.361895268489214],
              [-70.736007256534833, -33.365646615883513],
              [-70.74119368466404, -33.365646615883513],
              [-70.743786898728629, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 807,
        left: -7854377.7174875792,
        bottom: -3942938.7225360028,
        right: -7853223.0164108202,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.354392088535135],
              [-70.74119368466404, -33.350640255993589],
              [-70.736007256534833, -33.350640255993589],
              [-70.733414042470216, -33.354392088535135],
              [-70.736007256534833, -33.358143759370044],
              [-70.74119368466404, -33.358143759370044],
              [-70.743786898728629, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 808,
        left: -7854377.7174875792,
        bottom: -3941938.7220697524,
        right: -7853223.0164108202,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.346888261754529],
              [-70.74119368466404, -33.343136105827064],
              [-70.736007256534833, -33.343136105827064],
              [-70.733414042470216, -33.346888261754529],
              [-70.736007256534833, -33.350640255993589],
              [-70.74119368466404, -33.350640255993589],
              [-70.743786898728629, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 809,
        left: -7854377.7174875792,
        bottom: -3940938.7216035021,
        right: -7853223.0164108202,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.339383788220331],
              [-70.74119368466404, -33.335631308943462],
              [-70.736007256534833, -33.335631308943462],
              [-70.733414042470216, -33.339383788220331],
              [-70.736007256534833, -33.343136105827064],
              [-70.74119368466404, -33.343136105827064],
              [-70.743786898728629, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 810,
        left: -7854377.7174875792,
        bottom: -3939938.7211372517,
        right: -7853223.0164108202,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.331878668005565],
              [-70.74119368466404, -33.328125865415807],
              [-70.736007256534833, -33.328125865415807],
              [-70.733414042470216, -33.331878668005565],
              [-70.736007256534833, -33.335631308943462],
              [-70.74119368466404, -33.335631308943462],
              [-70.743786898728629, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 811,
        left: -7854377.7174875792,
        bottom: -3938938.7206710014,
        right: -7853223.0164108202,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.324372901183303],
              [-70.74119368466404, -33.320619775317184],
              [-70.736007256534833, -33.320619775317184],
              [-70.733414042470216, -33.324372901183303],
              [-70.736007256534833, -33.328125865415807],
              [-70.74119368466404, -33.328125865415807],
              [-70.743786898728629, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 812,
        left: -7854377.7174875792,
        bottom: -3937938.720204751,
        right: -7853223.0164108202,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.31686648782663],
              [-70.74119368466404, -33.313113038720743],
              [-70.736007256534833, -33.313113038720743],
              [-70.733414042470216, -33.31686648782663],
              [-70.736007256534833, -33.320619775317184],
              [-70.74119368466404, -33.320619775317184],
              [-70.743786898728629, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 813,
        left: -7854377.7174875792,
        bottom: -3936938.7197385007,
        right: -7853223.0164108202,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.309359428008712],
              [-70.74119368466404, -33.305605655699658],
              [-70.736007256534833, -33.305605655699658],
              [-70.733414042470216, -33.309359428008712],
              [-70.736007256534833, -33.313113038720743],
              [-70.74119368466404, -33.313113038720743],
              [-70.743786898728629, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 814,
        left: -7854377.7174875792,
        bottom: -3935938.7192722503,
        right: -7853223.0164108202,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.301851721802763],
              [-70.74119368466404, -33.298097626327156],
              [-70.736007256534833, -33.298097626327156],
              [-70.733414042470216, -33.301851721802763],
              [-70.736007256534833, -33.305605655699658],
              [-70.74119368466404, -33.305605655699658],
              [-70.743786898728629, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 815,
        left: -7854377.7174875792,
        bottom: -3934938.718806,
        right: -7853223.0164108202,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.294343369282039],
              [-70.74119368466404, -33.29058895067655],
              [-70.736007256534833, -33.29058895067655],
              [-70.733414042470216, -33.294343369282039],
              [-70.736007256534833, -33.298097626327156],
              [-70.74119368466404, -33.298097626327156],
              [-70.743786898728629, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 816,
        left: -7854377.7174875792,
        bottom: -3933938.7183397496,
        right: -7853223.0164108202,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.743786898728629, -33.286834370519863],
              [-70.74119368466404, -33.283079628821156],
              [-70.736007256534833, -33.283079628821156],
              [-70.733414042470216, -33.286834370519863],
              [-70.736007256534833, -33.29058895067655],
              [-70.74119368466404, -33.29058895067655],
              [-70.743786898728629, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 817,
        left: -7853511.6916800104,
        bottom: -3980438.7400203901,
        right: -7852356.9906032523,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.635318028521546],
              [-70.733414042470216, -33.631578349057953],
              [-70.728227614341009, -33.631578349057953],
              [-70.725634400276405, -33.635318028521546],
              [-70.728227614341009, -33.639057545603663],
              [-70.733414042470216, -33.639057545603663],
              [-70.736007256534833, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 818,
        left: -7853511.6916800104,
        bottom: -3979438.7395541398,
        right: -7852356.9906032523,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.627838507221767],
              [-70.733414042470216, -33.624098503021855],
              [-70.728227614341009, -33.624098503021855],
              [-70.725634400276405, -33.627838507221767],
              [-70.728227614341009, -33.631578349057953],
              [-70.733414042470216, -33.631578349057953],
              [-70.736007256534833, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 819,
        left: -7853511.6916800104,
        bottom: -3978438.7390878894,
        right: -7852356.9906032523,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.620358336467127],
              [-70.733414042470216, -33.616618007566458],
              [-70.728227614341009, -33.616618007566458],
              [-70.725634400276405, -33.620358336467127],
              [-70.728227614341009, -33.624098503021855],
              [-70.733414042470216, -33.624098503021855],
              [-70.736007256534833, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 820,
        left: -7853511.6916800104,
        bottom: -3977438.7386216391,
        right: -7852356.9906032523,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.612877516328737],
              [-70.733414042470216, -33.609136862762881],
              [-70.728227614341009, -33.609136862762881],
              [-70.725634400276405, -33.612877516328737],
              [-70.728227614341009, -33.616618007566458],
              [-70.733414042470216, -33.616618007566458],
              [-70.736007256534833, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 821,
        left: -7853511.6916800104,
        bottom: -3976438.7381553887,
        right: -7852356.9906032523,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.605396046877765],
              [-70.733414042470216, -33.601655068682327],
              [-70.728227614341009, -33.601655068682327],
              [-70.725634400276405, -33.605396046877765],
              [-70.728227614341009, -33.609136862762881],
              [-70.733414042470216, -33.609136862762881],
              [-70.736007256534833, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 822,
        left: -7853511.6916800104,
        bottom: -3975438.7376891384,
        right: -7852356.9906032523,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.597913928185434],
              [-70.733414042470216, -33.594172625396013],
              [-70.728227614341009, -33.594172625396013],
              [-70.725634400276405, -33.597913928185434],
              [-70.728227614341009, -33.601655068682327],
              [-70.733414042470216, -33.601655068682327],
              [-70.736007256534833, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 823,
        left: -7853511.6916800104,
        bottom: -3974438.7372228876,
        right: -7852356.9906032523,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.590431160322979],
              [-70.733414042470216, -33.586689532975264],
              [-70.728227614341009, -33.586689532975264],
              [-70.725634400276405, -33.590431160322979],
              [-70.728227614341009, -33.594172625396013],
              [-70.733414042470216, -33.594172625396013],
              [-70.736007256534833, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 824,
        left: -7853511.6916800104,
        bottom: -3973438.7367566377,
        right: -7852356.9906032523,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.58294774336175],
              [-70.733414042470216, -33.579205791491383],
              [-70.728227614341009, -33.579205791491383],
              [-70.725634400276405, -33.58294774336175],
              [-70.728227614341009, -33.586689532975264],
              [-70.733414042470216, -33.586689532975264],
              [-70.736007256534833, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 825,
        left: -7853511.6916800104,
        bottom: -3972438.7362903878,
        right: -7852356.9906032523,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.575463677373087],
              [-70.733414042470216, -33.571721401015779],
              [-70.728227614341009, -33.571721401015779],
              [-70.725634400276405, -33.575463677373087],
              [-70.728227614341009, -33.579205791491383],
              [-70.733414042470216, -33.579205791491383],
              [-70.736007256534833, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 826,
        left: -7853511.6916800104,
        bottom: -3971438.735824137,
        right: -7852356.9906032523,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.567978962428406],
              [-70.733414042470216, -33.564236361619891],
              [-70.728227614341009, -33.564236361619891],
              [-70.725634400276405, -33.567978962428406],
              [-70.728227614341009, -33.571721401015779],
              [-70.733414042470216, -33.571721401015779],
              [-70.736007256534833, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 827,
        left: -7853511.6916800104,
        bottom: -3970438.7353578866,
        right: -7852356.9906032523,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.56049359859918],
              [-70.733414042470216, -33.556750673375213],
              [-70.728227614341009, -33.556750673375213],
              [-70.725634400276405, -33.56049359859918],
              [-70.728227614341009, -33.564236361619891],
              [-70.733414042470216, -33.564236361619891],
              [-70.736007256534833, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 828,
        left: -7853511.6916800104,
        bottom: -3969438.7348916363,
        right: -7852356.9906032523,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.553007585956919],
              [-70.733414042470216, -33.549264336353268],
              [-70.728227614341009, -33.549264336353268],
              [-70.725634400276405, -33.553007585956919],
              [-70.728227614341009, -33.556750673375213],
              [-70.733414042470216, -33.556750673375213],
              [-70.736007256534833, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 829,
        left: -7853511.6916800104,
        bottom: -3968438.7344253859,
        right: -7852356.9906032523,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.545520924573204],
              [-70.733414042470216, -33.541777350625665],
              [-70.728227614341009, -33.541777350625665],
              [-70.725634400276405, -33.545520924573204],
              [-70.728227614341009, -33.549264336353268],
              [-70.733414042470216, -33.549264336353268],
              [-70.736007256534833, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 830,
        left: -7853511.6916800104,
        bottom: -3967438.7339591356,
        right: -7852356.9906032523,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.538033614519627],
              [-70.733414042470216, -33.534289716264041],
              [-70.728227614341009, -33.534289716264041],
              [-70.725634400276405, -33.538033614519627],
              [-70.728227614341009, -33.541777350625665],
              [-70.733414042470216, -33.541777350625665],
              [-70.736007256534833, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 831,
        left: -7853511.6916800104,
        bottom: -3966438.7334928853,
        right: -7852356.9906032523,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.530545655867876],
              [-70.733414042470216, -33.52680143334009],
              [-70.728227614341009, -33.52680143334009],
              [-70.725634400276405, -33.530545655867876],
              [-70.728227614341009, -33.534289716264041],
              [-70.733414042470216, -33.534289716264041],
              [-70.736007256534833, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 832,
        left: -7853511.6916800104,
        bottom: -3965438.7330266349,
        right: -7852356.9906032523,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.523057048689658],
              [-70.733414042470216, -33.519312501925548],
              [-70.728227614341009, -33.519312501925548],
              [-70.725634400276405, -33.523057048689658],
              [-70.728227614341009, -33.52680143334009],
              [-70.733414042470216, -33.52680143334009],
              [-70.736007256534833, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 833,
        left: -7853511.6916800104,
        bottom: -3964438.7325603846,
        right: -7852356.9906032523,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.515567793056739],
              [-70.733414042470216, -33.511822922092215],
              [-70.728227614341009, -33.511822922092215],
              [-70.725634400276405, -33.515567793056739],
              [-70.728227614341009, -33.519312501925548],
              [-70.733414042470216, -33.519312501925548],
              [-70.736007256534833, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 834,
        left: -7853511.6916800104,
        bottom: -3963438.7320941342,
        right: -7852356.9906032523,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.508077889040948],
              [-70.733414042470216, -33.504332693911934],
              [-70.728227614341009, -33.504332693911934],
              [-70.725634400276405, -33.508077889040948],
              [-70.728227614341009, -33.511822922092215],
              [-70.733414042470216, -33.511822922092215],
              [-70.736007256534833, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 835,
        left: -7853511.6916800104,
        bottom: -3962438.7316278839,
        right: -7852356.9906032523,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.500587336714155],
              [-70.733414042470216, -33.496841817456605],
              [-70.728227614341009, -33.496841817456605],
              [-70.725634400276405, -33.500587336714155],
              [-70.728227614341009, -33.504332693911934],
              [-70.733414042470216, -33.504332693911934],
              [-70.736007256534833, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 836,
        left: -7853511.6916800104,
        bottom: -3961438.7311616335,
        right: -7852356.9906032523,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.493096136148274],
              [-70.733414042470216, -33.489350292798164],
              [-70.728227614341009, -33.489350292798164],
              [-70.725634400276405, -33.493096136148274],
              [-70.728227614341009, -33.496841817456605],
              [-70.733414042470216, -33.496841817456605],
              [-70.736007256534833, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 837,
        left: -7853511.6916800104,
        bottom: -3960438.7306953832,
        right: -7852356.9906032523,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.485604287415278],
              [-70.733414042470216, -33.481858120008617],
              [-70.728227614341009, -33.481858120008617],
              [-70.725634400276405, -33.485604287415278],
              [-70.728227614341009, -33.489350292798164],
              [-70.733414042470216, -33.489350292798164],
              [-70.736007256534833, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 838,
        left: -7853511.6916800104,
        bottom: -3959438.7302291333,
        right: -7852356.9906032523,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.478111790587178],
              [-70.733414042470216, -33.474365299160006],
              [-70.728227614341009, -33.474365299160006],
              [-70.725634400276405, -33.478111790587178],
              [-70.728227614341009, -33.481858120008617],
              [-70.733414042470216, -33.481858120008617],
              [-70.736007256534833, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 839,
        left: -7853511.6916800104,
        bottom: -3958438.7297628829,
        right: -7852356.9906032523,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.47061864573606],
              [-70.733414042470216, -33.466871830324415],
              [-70.728227614341009, -33.466871830324415],
              [-70.725634400276405, -33.47061864573606],
              [-70.728227614341009, -33.474365299160006],
              [-70.733414042470216, -33.474365299160006],
              [-70.736007256534833, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 840,
        left: -7853511.6916800104,
        bottom: -3957438.7292966326,
        right: -7852356.9906032523,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.463124852934051],
              [-70.733414042470216, -33.459377713574014],
              [-70.728227614341009, -33.459377713574014],
              [-70.725634400276405, -33.463124852934051],
              [-70.728227614341009, -33.466871830324415],
              [-70.733414042470216, -33.466871830324415],
              [-70.736007256534833, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 841,
        left: -7853511.6916800104,
        bottom: -3956438.7288303822,
        right: -7852356.9906032523,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.455630412253299],
              [-70.733414042470216, -33.45188294898098],
              [-70.728227614341009, -33.45188294898098],
              [-70.725634400276405, -33.455630412253299],
              [-70.728227614341009, -33.459377713574014],
              [-70.733414042470216, -33.459377713574014],
              [-70.736007256534833, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 842,
        left: -7853511.6916800104,
        bottom: -3955438.7283641323,
        right: -7852356.9906032523,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.448135323766053],
              [-70.733414042470216, -33.444387536617576],
              [-70.728227614341009, -33.444387536617576],
              [-70.725634400276405, -33.448135323766053],
              [-70.728227614341009, -33.45188294898098],
              [-70.733414042470216, -33.45188294898098],
              [-70.736007256534833, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 843,
        left: -7853511.6916800104,
        bottom: -3954438.7278978815,
        right: -7852356.9906032523,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.440639587544567],
              [-70.733414042470216, -33.436891476556099],
              [-70.728227614341009, -33.436891476556099],
              [-70.725634400276405, -33.440639587544567],
              [-70.728227614341009, -33.444387536617576],
              [-70.733414042470216, -33.444387536617576],
              [-70.736007256534833, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 844,
        left: -7853511.6916800104,
        bottom: -3953438.7274316312,
        right: -7852356.9906032523,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.433143203661182],
              [-70.733414042470216, -33.429394768868896],
              [-70.728227614341009, -33.429394768868896],
              [-70.725634400276405, -33.433143203661182],
              [-70.728227614341009, -33.436891476556099],
              [-70.733414042470216, -33.436891476556099],
              [-70.736007256534833, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 845,
        left: -7853511.6916800104,
        bottom: -3952438.7269653808,
        right: -7852356.9906032523,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.425646172188266],
              [-70.733414042470216, -33.421897413628365],
              [-70.728227614341009, -33.421897413628365],
              [-70.725634400276405, -33.425646172188266],
              [-70.728227614341009, -33.429394768868896],
              [-70.733414042470216, -33.429394768868896],
              [-70.736007256534833, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 846,
        left: -7853511.6916800104,
        bottom: -3951438.7264991305,
        right: -7852356.9906032523,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.418148493198238],
              [-70.733414042470216, -33.414399410906967],
              [-70.728227614341009, -33.414399410906967],
              [-70.725634400276405, -33.418148493198238],
              [-70.728227614341009, -33.421897413628365],
              [-70.733414042470216, -33.421897413628365],
              [-70.736007256534833, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 847,
        left: -7853511.6916800104,
        bottom: -3950438.7260328801,
        right: -7852356.9906032523,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.410650166763581],
              [-70.733414042470216, -33.406900760777191],
              [-70.728227614341009, -33.406900760777191],
              [-70.725634400276405, -33.410650166763581],
              [-70.728227614341009, -33.414399410906967],
              [-70.733414042470216, -33.414399410906967],
              [-70.736007256534833, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 848,
        left: -7853511.6916800104,
        bottom: -3949438.7255666298,
        right: -7852356.9906032523,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.403151192956834],
              [-70.733414042470216, -33.399401463311598],
              [-70.728227614341009, -33.399401463311598],
              [-70.725634400276405, -33.403151192956834],
              [-70.728227614341009, -33.406900760777191],
              [-70.733414042470216, -33.406900760777191],
              [-70.736007256534833, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 849,
        left: -7853511.6916800104,
        bottom: -3948438.7251003794,
        right: -7852356.9906032523,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.39565157185055],
              [-70.733414042470216, -33.391901518582785],
              [-70.728227614341009, -33.391901518582785],
              [-70.725634400276405, -33.39565157185055],
              [-70.728227614341009, -33.399401463311598],
              [-70.733414042470216, -33.399401463311598],
              [-70.736007256534833, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 850,
        left: -7853511.6916800104,
        bottom: -3947438.7246341291,
        right: -7852356.9906032523,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.388151303517375],
              [-70.733414042470216, -33.384400926663417],
              [-70.728227614341009, -33.384400926663417],
              [-70.725634400276405, -33.388151303517375],
              [-70.728227614341009, -33.391901518582785],
              [-70.733414042470216, -33.391901518582785],
              [-70.736007256534833, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 851,
        left: -7853511.6916800104,
        bottom: -3946438.7241678787,
        right: -7852356.9906032523,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.380650388029984],
              [-70.733414042470216, -33.376899687626185],
              [-70.728227614341009, -33.376899687626185],
              [-70.725634400276405, -33.380650388029984],
              [-70.728227614341009, -33.384400926663417],
              [-70.733414042470216, -33.384400926663417],
              [-70.736007256534833, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 852,
        left: -7853511.6916800104,
        bottom: -3945438.7237016284,
        right: -7852356.9906032523,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.373148825461101],
              [-70.733414042470216, -33.369397801543847],
              [-70.728227614341009, -33.369397801543847],
              [-70.725634400276405, -33.373148825461101],
              [-70.728227614341009, -33.376899687626185],
              [-70.733414042470216, -33.376899687626185],
              [-70.736007256534833, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 853,
        left: -7853511.6916800104,
        bottom: -3944438.723235378,
        right: -7852356.9906032523,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.365646615883513],
              [-70.733414042470216, -33.361895268489214],
              [-70.728227614341009, -33.361895268489214],
              [-70.725634400276405, -33.365646615883513],
              [-70.728227614341009, -33.369397801543847],
              [-70.733414042470216, -33.369397801543847],
              [-70.736007256534833, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 854,
        left: -7853511.6916800104,
        bottom: -3943438.7227691277,
        right: -7852356.9906032523,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.358143759370044],
              [-70.733414042470216, -33.354392088535135],
              [-70.728227614341009, -33.354392088535135],
              [-70.725634400276405, -33.358143759370044],
              [-70.728227614341009, -33.361895268489214],
              [-70.733414042470216, -33.361895268489214],
              [-70.736007256534833, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 855,
        left: -7853511.6916800104,
        bottom: -3942438.7223028769,
        right: -7852356.9906032523,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.350640255993589],
              [-70.733414042470216, -33.346888261754529],
              [-70.728227614341009, -33.346888261754529],
              [-70.725634400276405, -33.350640255993589],
              [-70.728227614341009, -33.354392088535135],
              [-70.733414042470216, -33.354392088535135],
              [-70.736007256534833, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 856,
        left: -7853511.6916800104,
        bottom: -3941438.721836627,
        right: -7852356.9906032523,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.343136105827064],
              [-70.733414042470216, -33.339383788220331],
              [-70.728227614341009, -33.339383788220331],
              [-70.725634400276405, -33.343136105827064],
              [-70.728227614341009, -33.346888261754529],
              [-70.733414042470216, -33.346888261754529],
              [-70.736007256534833, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 857,
        left: -7853511.6916800104,
        bottom: -3940438.7213703771,
        right: -7852356.9906032523,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.335631308943462],
              [-70.733414042470216, -33.331878668005565],
              [-70.728227614341009, -33.331878668005565],
              [-70.725634400276405, -33.335631308943462],
              [-70.728227614341009, -33.339383788220331],
              [-70.733414042470216, -33.339383788220331],
              [-70.736007256534833, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 858,
        left: -7853511.6916800104,
        bottom: -3939438.7209041263,
        right: -7852356.9906032523,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.328125865415807],
              [-70.733414042470216, -33.324372901183303],
              [-70.728227614341009, -33.324372901183303],
              [-70.725634400276405, -33.328125865415807],
              [-70.728227614341009, -33.331878668005565],
              [-70.733414042470216, -33.331878668005565],
              [-70.736007256534833, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 859,
        left: -7853511.6916800104,
        bottom: -3938438.7204378759,
        right: -7852356.9906032523,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.320619775317184],
              [-70.733414042470216, -33.31686648782663],
              [-70.728227614341009, -33.31686648782663],
              [-70.725634400276405, -33.320619775317184],
              [-70.728227614341009, -33.324372901183303],
              [-70.733414042470216, -33.324372901183303],
              [-70.736007256534833, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 860,
        left: -7853511.6916800104,
        bottom: -3937438.7199716261,
        right: -7852356.9906032523,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.313113038720743],
              [-70.733414042470216, -33.309359428008712],
              [-70.728227614341009, -33.309359428008712],
              [-70.725634400276405, -33.313113038720743],
              [-70.728227614341009, -33.31686648782663],
              [-70.733414042470216, -33.31686648782663],
              [-70.736007256534833, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 861,
        left: -7853511.6916800104,
        bottom: -3936438.7195053762,
        right: -7852356.9906032523,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.305605655699658],
              [-70.733414042470216, -33.301851721802763],
              [-70.728227614341009, -33.301851721802763],
              [-70.725634400276405, -33.305605655699658],
              [-70.728227614341009, -33.309359428008712],
              [-70.733414042470216, -33.309359428008712],
              [-70.736007256534833, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 862,
        left: -7853511.6916800104,
        bottom: -3935438.7190391254,
        right: -7852356.9906032523,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.298097626327156],
              [-70.733414042470216, -33.294343369282039],
              [-70.728227614341009, -33.294343369282039],
              [-70.725634400276405, -33.298097626327156],
              [-70.728227614341009, -33.301851721802763],
              [-70.733414042470216, -33.301851721802763],
              [-70.736007256534833, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 863,
        left: -7853511.6916800104,
        bottom: -3934438.718572875,
        right: -7852356.9906032523,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.29058895067655],
              [-70.733414042470216, -33.286834370519863],
              [-70.728227614341009, -33.286834370519863],
              [-70.725634400276405, -33.29058895067655],
              [-70.728227614341009, -33.294343369282039],
              [-70.733414042470216, -33.294343369282039],
              [-70.736007256534833, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 864,
        left: -7853511.6916800104,
        bottom: -3933438.7181066247,
        right: -7852356.9906032523,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.736007256534833, -33.283079628821156],
              [-70.733414042470216, -33.279324725589596],
              [-70.728227614341009, -33.279324725589596],
              [-70.725634400276405, -33.283079628821156],
              [-70.728227614341009, -33.286834370519863],
              [-70.733414042470216, -33.286834370519863],
              [-70.736007256534833, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 865,
        left: -7852645.6658724416,
        bottom: -3980938.7402535151,
        right: -7851490.9647956835,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.639057545603663],
              [-70.725634400276405, -33.635318028521546],
              [-70.720447972147198, -33.635318028521546],
              [-70.717854758082609, -33.639057545603663],
              [-70.720447972147198, -33.642796900295409],
              [-70.725634400276405, -33.642796900295409],
              [-70.728227614341009, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 866,
        left: -7852645.6658724416,
        bottom: -3979938.7397872647,
        right: -7851490.9647956835,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.631578349057953],
              [-70.725634400276405, -33.627838507221767],
              [-70.720447972147198, -33.627838507221767],
              [-70.717854758082609, -33.631578349057953],
              [-70.720447972147198, -33.635318028521546],
              [-70.725634400276405, -33.635318028521546],
              [-70.728227614341009, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 867,
        left: -7852645.6658724416,
        bottom: -3978938.7393210144,
        right: -7851490.9647956835,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.624098503021855],
              [-70.725634400276405, -33.620358336467127],
              [-70.720447972147198, -33.620358336467127],
              [-70.717854758082609, -33.624098503021855],
              [-70.720447972147198, -33.627838507221767],
              [-70.725634400276405, -33.627838507221767],
              [-70.728227614341009, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 868,
        left: -7852645.6658724416,
        bottom: -3977938.738854764,
        right: -7851490.9647956835,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.616618007566458],
              [-70.725634400276405, -33.612877516328737],
              [-70.720447972147198, -33.612877516328737],
              [-70.717854758082609, -33.616618007566458],
              [-70.720447972147198, -33.620358336467127],
              [-70.725634400276405, -33.620358336467127],
              [-70.728227614341009, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 869,
        left: -7852645.6658724416,
        bottom: -3976938.7383885137,
        right: -7851490.9647956835,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.609136862762881],
              [-70.725634400276405, -33.605396046877765],
              [-70.720447972147198, -33.605396046877765],
              [-70.717854758082609, -33.609136862762881],
              [-70.720447972147198, -33.612877516328737],
              [-70.725634400276405, -33.612877516328737],
              [-70.728227614341009, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 870,
        left: -7852645.6658724416,
        bottom: -3975938.7379222633,
        right: -7851490.9647956835,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.601655068682327],
              [-70.725634400276405, -33.597913928185434],
              [-70.720447972147198, -33.597913928185434],
              [-70.717854758082609, -33.601655068682327],
              [-70.720447972147198, -33.605396046877765],
              [-70.725634400276405, -33.605396046877765],
              [-70.728227614341009, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 871,
        left: -7852645.6658724416,
        bottom: -3974938.737456013,
        right: -7851490.9647956835,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.594172625396013],
              [-70.725634400276405, -33.590431160322979],
              [-70.720447972147198, -33.590431160322979],
              [-70.717854758082609, -33.594172625396013],
              [-70.720447972147198, -33.597913928185434],
              [-70.725634400276405, -33.597913928185434],
              [-70.728227614341009, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 872,
        left: -7852645.6658724416,
        bottom: -3973938.7369897626,
        right: -7851490.9647956835,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.586689532975264],
              [-70.725634400276405, -33.58294774336175],
              [-70.720447972147198, -33.58294774336175],
              [-70.717854758082609, -33.586689532975264],
              [-70.720447972147198, -33.590431160322979],
              [-70.725634400276405, -33.590431160322979],
              [-70.728227614341009, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 873,
        left: -7852645.6658724416,
        bottom: -3972938.7365235123,
        right: -7851490.9647956835,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.579205791491383],
              [-70.725634400276405, -33.575463677373087],
              [-70.720447972147198, -33.575463677373087],
              [-70.717854758082609, -33.579205791491383],
              [-70.720447972147198, -33.58294774336175],
              [-70.725634400276405, -33.58294774336175],
              [-70.728227614341009, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 874,
        left: -7852645.6658724416,
        bottom: -3971938.7360572619,
        right: -7851490.9647956835,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.571721401015779],
              [-70.725634400276405, -33.567978962428406],
              [-70.720447972147198, -33.567978962428406],
              [-70.717854758082609, -33.571721401015779],
              [-70.720447972147198, -33.575463677373087],
              [-70.725634400276405, -33.575463677373087],
              [-70.728227614341009, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 875,
        left: -7852645.6658724416,
        bottom: -3970938.7355910116,
        right: -7851490.9647956835,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.564236361619891],
              [-70.725634400276405, -33.56049359859918],
              [-70.720447972147198, -33.56049359859918],
              [-70.717854758082609, -33.564236361619891],
              [-70.720447972147198, -33.567978962428406],
              [-70.725634400276405, -33.567978962428406],
              [-70.728227614341009, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 876,
        left: -7852645.6658724416,
        bottom: -3969938.7351247617,
        right: -7851490.9647956835,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.556750673375213],
              [-70.725634400276405, -33.553007585956919],
              [-70.720447972147198, -33.553007585956919],
              [-70.717854758082609, -33.556750673375213],
              [-70.720447972147198, -33.56049359859918],
              [-70.725634400276405, -33.56049359859918],
              [-70.728227614341009, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 877,
        left: -7852645.6658724416,
        bottom: -3968938.7346585114,
        right: -7851490.9647956835,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.549264336353268],
              [-70.725634400276405, -33.545520924573204],
              [-70.720447972147198, -33.545520924573204],
              [-70.717854758082609, -33.549264336353268],
              [-70.720447972147198, -33.553007585956919],
              [-70.725634400276405, -33.553007585956919],
              [-70.728227614341009, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 878,
        left: -7852645.6658724416,
        bottom: -3967938.734192261,
        right: -7851490.9647956835,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.541777350625665],
              [-70.725634400276405, -33.538033614519627],
              [-70.720447972147198, -33.538033614519627],
              [-70.717854758082609, -33.541777350625665],
              [-70.720447972147198, -33.545520924573204],
              [-70.725634400276405, -33.545520924573204],
              [-70.728227614341009, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 879,
        left: -7852645.6658724416,
        bottom: -3966938.7337260107,
        right: -7851490.9647956835,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.534289716264041],
              [-70.725634400276405, -33.530545655867876],
              [-70.720447972147198, -33.530545655867876],
              [-70.717854758082609, -33.534289716264041],
              [-70.720447972147198, -33.538033614519627],
              [-70.725634400276405, -33.538033614519627],
              [-70.728227614341009, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 880,
        left: -7852645.6658724416,
        bottom: -3965938.7332597603,
        right: -7851490.9647956835,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.52680143334009],
              [-70.725634400276405, -33.523057048689658],
              [-70.720447972147198, -33.523057048689658],
              [-70.717854758082609, -33.52680143334009],
              [-70.720447972147198, -33.530545655867876],
              [-70.725634400276405, -33.530545655867876],
              [-70.728227614341009, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 881,
        left: -7852645.6658724416,
        bottom: -3964938.7327935095,
        right: -7851490.9647956835,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.519312501925548],
              [-70.725634400276405, -33.515567793056739],
              [-70.720447972147198, -33.515567793056739],
              [-70.717854758082609, -33.519312501925548],
              [-70.720447972147198, -33.523057048689658],
              [-70.725634400276405, -33.523057048689658],
              [-70.728227614341009, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 882,
        left: -7852645.6658724416,
        bottom: -3963938.7323272596,
        right: -7851490.9647956835,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.511822922092215],
              [-70.725634400276405, -33.508077889040948],
              [-70.720447972147198, -33.508077889040948],
              [-70.717854758082609, -33.511822922092215],
              [-70.720447972147198, -33.515567793056739],
              [-70.725634400276405, -33.515567793056739],
              [-70.728227614341009, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 883,
        left: -7852645.6658724416,
        bottom: -3962938.7318610097,
        right: -7851490.9647956835,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.504332693911934],
              [-70.725634400276405, -33.500587336714155],
              [-70.720447972147198, -33.500587336714155],
              [-70.717854758082609, -33.504332693911934],
              [-70.720447972147198, -33.508077889040948],
              [-70.725634400276405, -33.508077889040948],
              [-70.728227614341009, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 884,
        left: -7852645.6658724416,
        bottom: -3961938.7313947589,
        right: -7851490.9647956835,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.496841817456605],
              [-70.725634400276405, -33.493096136148274],
              [-70.720447972147198, -33.493096136148274],
              [-70.717854758082609, -33.496841817456605],
              [-70.720447972147198, -33.500587336714155],
              [-70.725634400276405, -33.500587336714155],
              [-70.728227614341009, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 885,
        left: -7852645.6658724416,
        bottom: -3960938.7309285086,
        right: -7851490.9647956835,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.489350292798164],
              [-70.725634400276405, -33.485604287415278],
              [-70.720447972147198, -33.485604287415278],
              [-70.717854758082609, -33.489350292798164],
              [-70.720447972147198, -33.493096136148274],
              [-70.725634400276405, -33.493096136148274],
              [-70.728227614341009, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 886,
        left: -7852645.6658724416,
        bottom: -3959938.7304622582,
        right: -7851490.9647956835,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.481858120008617],
              [-70.725634400276405, -33.478111790587178],
              [-70.720447972147198, -33.478111790587178],
              [-70.717854758082609, -33.481858120008617],
              [-70.720447972147198, -33.485604287415278],
              [-70.725634400276405, -33.485604287415278],
              [-70.728227614341009, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 887,
        left: -7852645.6658724416,
        bottom: -3958938.7299960079,
        right: -7851490.9647956835,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.474365299160006],
              [-70.725634400276405, -33.47061864573606],
              [-70.720447972147198, -33.47061864573606],
              [-70.717854758082609, -33.474365299160006],
              [-70.720447972147198, -33.478111790587178],
              [-70.725634400276405, -33.478111790587178],
              [-70.728227614341009, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 888,
        left: -7852645.6658724416,
        bottom: -3957938.7295297575,
        right: -7851490.9647956835,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.466871830324415],
              [-70.725634400276405, -33.463124852934051],
              [-70.720447972147198, -33.463124852934051],
              [-70.717854758082609, -33.466871830324415],
              [-70.720447972147198, -33.47061864573606],
              [-70.725634400276405, -33.47061864573606],
              [-70.728227614341009, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 889,
        left: -7852645.6658724416,
        bottom: -3956938.7290635072,
        right: -7851490.9647956835,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.459377713574014],
              [-70.725634400276405, -33.455630412253299],
              [-70.720447972147198, -33.455630412253299],
              [-70.717854758082609, -33.459377713574014],
              [-70.720447972147198, -33.463124852934051],
              [-70.725634400276405, -33.463124852934051],
              [-70.728227614341009, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 890,
        left: -7852645.6658724416,
        bottom: -3955938.7285972568,
        right: -7851490.9647956835,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.45188294898098],
              [-70.725634400276405, -33.448135323766053],
              [-70.720447972147198, -33.448135323766053],
              [-70.717854758082609, -33.45188294898098],
              [-70.720447972147198, -33.455630412253299],
              [-70.725634400276405, -33.455630412253299],
              [-70.728227614341009, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 891,
        left: -7852645.6658724416,
        bottom: -3954938.7281310065,
        right: -7851490.9647956835,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.444387536617576],
              [-70.725634400276405, -33.440639587544567],
              [-70.720447972147198, -33.440639587544567],
              [-70.717854758082609, -33.444387536617576],
              [-70.720447972147198, -33.448135323766053],
              [-70.725634400276405, -33.448135323766053],
              [-70.728227614341009, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 892,
        left: -7852645.6658724416,
        bottom: -3953938.7276647561,
        right: -7851490.9647956835,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.436891476556099],
              [-70.725634400276405, -33.433143203661182],
              [-70.720447972147198, -33.433143203661182],
              [-70.717854758082609, -33.436891476556099],
              [-70.720447972147198, -33.440639587544567],
              [-70.725634400276405, -33.440639587544567],
              [-70.728227614341009, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 893,
        left: -7852645.6658724416,
        bottom: -3952938.7271985058,
        right: -7851490.9647956835,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.429394768868896],
              [-70.725634400276405, -33.425646172188266],
              [-70.720447972147198, -33.425646172188266],
              [-70.717854758082609, -33.429394768868896],
              [-70.720447972147198, -33.433143203661182],
              [-70.725634400276405, -33.433143203661182],
              [-70.728227614341009, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 894,
        left: -7852645.6658724416,
        bottom: -3951938.7267322554,
        right: -7851490.9647956835,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.421897413628365],
              [-70.725634400276405, -33.418148493198238],
              [-70.720447972147198, -33.418148493198238],
              [-70.717854758082609, -33.421897413628365],
              [-70.720447972147198, -33.425646172188266],
              [-70.725634400276405, -33.425646172188266],
              [-70.728227614341009, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 895,
        left: -7852645.6658724416,
        bottom: -3950938.7262660051,
        right: -7851490.9647956835,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.414399410906967],
              [-70.725634400276405, -33.410650166763581],
              [-70.720447972147198, -33.410650166763581],
              [-70.717854758082609, -33.414399410906967],
              [-70.720447972147198, -33.418148493198238],
              [-70.725634400276405, -33.418148493198238],
              [-70.728227614341009, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 896,
        left: -7852645.6658724416,
        bottom: -3949938.7257997552,
        right: -7851490.9647956835,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.406900760777191],
              [-70.725634400276405, -33.403151192956834],
              [-70.720447972147198, -33.403151192956834],
              [-70.717854758082609, -33.406900760777191],
              [-70.720447972147198, -33.410650166763581],
              [-70.725634400276405, -33.410650166763581],
              [-70.728227614341009, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 897,
        left: -7852645.6658724416,
        bottom: -3948938.7253335048,
        right: -7851490.9647956835,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.399401463311598],
              [-70.725634400276405, -33.39565157185055],
              [-70.720447972147198, -33.39565157185055],
              [-70.717854758082609, -33.399401463311598],
              [-70.720447972147198, -33.403151192956834],
              [-70.725634400276405, -33.403151192956834],
              [-70.728227614341009, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 898,
        left: -7852645.6658724416,
        bottom: -3947938.724867254,
        right: -7851490.9647956835,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.391901518582785],
              [-70.725634400276405, -33.388151303517375],
              [-70.720447972147198, -33.388151303517375],
              [-70.717854758082609, -33.391901518582785],
              [-70.720447972147198, -33.39565157185055],
              [-70.725634400276405, -33.39565157185055],
              [-70.728227614341009, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 899,
        left: -7852645.6658724416,
        bottom: -3946938.7244010041,
        right: -7851490.9647956835,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.384400926663417],
              [-70.725634400276405, -33.380650388029984],
              [-70.720447972147198, -33.380650388029984],
              [-70.717854758082609, -33.384400926663417],
              [-70.720447972147198, -33.388151303517375],
              [-70.725634400276405, -33.388151303517375],
              [-70.728227614341009, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 900,
        left: -7852645.6658724416,
        bottom: -3945938.7239347543,
        right: -7851490.9647956835,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.376899687626185],
              [-70.725634400276405, -33.373148825461101],
              [-70.720447972147198, -33.373148825461101],
              [-70.717854758082609, -33.376899687626185],
              [-70.720447972147198, -33.380650388029984],
              [-70.725634400276405, -33.380650388029984],
              [-70.728227614341009, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 901,
        left: -7852645.6658724416,
        bottom: -3944938.7234685034,
        right: -7851490.9647956835,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.369397801543847],
              [-70.725634400276405, -33.365646615883513],
              [-70.720447972147198, -33.365646615883513],
              [-70.717854758082609, -33.369397801543847],
              [-70.720447972147198, -33.373148825461101],
              [-70.725634400276405, -33.373148825461101],
              [-70.728227614341009, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 902,
        left: -7852645.6658724416,
        bottom: -3943938.7230022531,
        right: -7851490.9647956835,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.361895268489214],
              [-70.725634400276405, -33.358143759370044],
              [-70.720447972147198, -33.358143759370044],
              [-70.717854758082609, -33.361895268489214],
              [-70.720447972147198, -33.365646615883513],
              [-70.725634400276405, -33.365646615883513],
              [-70.728227614341009, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 903,
        left: -7852645.6658724416,
        bottom: -3942938.7225360028,
        right: -7851490.9647956835,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.354392088535135],
              [-70.725634400276405, -33.350640255993589],
              [-70.720447972147198, -33.350640255993589],
              [-70.717854758082609, -33.354392088535135],
              [-70.720447972147198, -33.358143759370044],
              [-70.725634400276405, -33.358143759370044],
              [-70.728227614341009, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 904,
        left: -7852645.6658724416,
        bottom: -3941938.7220697524,
        right: -7851490.9647956835,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.346888261754529],
              [-70.725634400276405, -33.343136105827064],
              [-70.720447972147198, -33.343136105827064],
              [-70.717854758082609, -33.346888261754529],
              [-70.720447972147198, -33.350640255993589],
              [-70.725634400276405, -33.350640255993589],
              [-70.728227614341009, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 905,
        left: -7852645.6658724416,
        bottom: -3940938.7216035021,
        right: -7851490.9647956835,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.339383788220331],
              [-70.725634400276405, -33.335631308943462],
              [-70.720447972147198, -33.335631308943462],
              [-70.717854758082609, -33.339383788220331],
              [-70.720447972147198, -33.343136105827064],
              [-70.725634400276405, -33.343136105827064],
              [-70.728227614341009, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 906,
        left: -7852645.6658724416,
        bottom: -3939938.7211372517,
        right: -7851490.9647956835,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.331878668005565],
              [-70.725634400276405, -33.328125865415807],
              [-70.720447972147198, -33.328125865415807],
              [-70.717854758082609, -33.331878668005565],
              [-70.720447972147198, -33.335631308943462],
              [-70.725634400276405, -33.335631308943462],
              [-70.728227614341009, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 907,
        left: -7852645.6658724416,
        bottom: -3938938.7206710014,
        right: -7851490.9647956835,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.324372901183303],
              [-70.725634400276405, -33.320619775317184],
              [-70.720447972147198, -33.320619775317184],
              [-70.717854758082609, -33.324372901183303],
              [-70.720447972147198, -33.328125865415807],
              [-70.725634400276405, -33.328125865415807],
              [-70.728227614341009, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 908,
        left: -7852645.6658724416,
        bottom: -3937938.720204751,
        right: -7851490.9647956835,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.31686648782663],
              [-70.725634400276405, -33.313113038720743],
              [-70.720447972147198, -33.313113038720743],
              [-70.717854758082609, -33.31686648782663],
              [-70.720447972147198, -33.320619775317184],
              [-70.725634400276405, -33.320619775317184],
              [-70.728227614341009, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 909,
        left: -7852645.6658724416,
        bottom: -3936938.7197385007,
        right: -7851490.9647956835,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.309359428008712],
              [-70.725634400276405, -33.305605655699658],
              [-70.720447972147198, -33.305605655699658],
              [-70.717854758082609, -33.309359428008712],
              [-70.720447972147198, -33.313113038720743],
              [-70.725634400276405, -33.313113038720743],
              [-70.728227614341009, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 910,
        left: -7852645.6658724416,
        bottom: -3935938.7192722503,
        right: -7851490.9647956835,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.301851721802763],
              [-70.725634400276405, -33.298097626327156],
              [-70.720447972147198, -33.298097626327156],
              [-70.717854758082609, -33.301851721802763],
              [-70.720447972147198, -33.305605655699658],
              [-70.725634400276405, -33.305605655699658],
              [-70.728227614341009, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 911,
        left: -7852645.6658724416,
        bottom: -3934938.718806,
        right: -7851490.9647956835,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.294343369282039],
              [-70.725634400276405, -33.29058895067655],
              [-70.720447972147198, -33.29058895067655],
              [-70.717854758082609, -33.294343369282039],
              [-70.720447972147198, -33.298097626327156],
              [-70.725634400276405, -33.298097626327156],
              [-70.728227614341009, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 912,
        left: -7852645.6658724416,
        bottom: -3933938.7183397496,
        right: -7851490.9647956835,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.728227614341009, -33.286834370519863],
              [-70.725634400276405, -33.283079628821156],
              [-70.720447972147198, -33.283079628821156],
              [-70.717854758082609, -33.286834370519863],
              [-70.720447972147198, -33.29058895067655],
              [-70.725634400276405, -33.29058895067655],
              [-70.728227614341009, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 913,
        left: -7851779.6400648728,
        bottom: -3980438.7400203901,
        right: -7850624.9389881147,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.635318028521546],
              [-70.717854758082609, -33.631578349057953],
              [-70.712668329953388, -33.631578349057953],
              [-70.710075115888785, -33.635318028521546],
              [-70.712668329953388, -33.639057545603663],
              [-70.717854758082609, -33.639057545603663],
              [-70.720447972147198, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 914,
        left: -7851779.6400648728,
        bottom: -3979438.7395541398,
        right: -7850624.9389881147,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.627838507221767],
              [-70.717854758082609, -33.624098503021855],
              [-70.712668329953388, -33.624098503021855],
              [-70.710075115888785, -33.627838507221767],
              [-70.712668329953388, -33.631578349057953],
              [-70.717854758082609, -33.631578349057953],
              [-70.720447972147198, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 915,
        left: -7851779.6400648728,
        bottom: -3978438.7390878894,
        right: -7850624.9389881147,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.620358336467127],
              [-70.717854758082609, -33.616618007566458],
              [-70.712668329953388, -33.616618007566458],
              [-70.710075115888785, -33.620358336467127],
              [-70.712668329953388, -33.624098503021855],
              [-70.717854758082609, -33.624098503021855],
              [-70.720447972147198, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 916,
        left: -7851779.6400648728,
        bottom: -3977438.7386216391,
        right: -7850624.9389881147,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.612877516328737],
              [-70.717854758082609, -33.609136862762881],
              [-70.712668329953388, -33.609136862762881],
              [-70.710075115888785, -33.612877516328737],
              [-70.712668329953388, -33.616618007566458],
              [-70.717854758082609, -33.616618007566458],
              [-70.720447972147198, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 917,
        left: -7851779.6400648728,
        bottom: -3976438.7381553887,
        right: -7850624.9389881147,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.605396046877765],
              [-70.717854758082609, -33.601655068682327],
              [-70.712668329953388, -33.601655068682327],
              [-70.710075115888785, -33.605396046877765],
              [-70.712668329953388, -33.609136862762881],
              [-70.717854758082609, -33.609136862762881],
              [-70.720447972147198, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 918,
        left: -7851779.6400648728,
        bottom: -3975438.7376891384,
        right: -7850624.9389881147,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.597913928185434],
              [-70.717854758082609, -33.594172625396013],
              [-70.712668329953388, -33.594172625396013],
              [-70.710075115888785, -33.597913928185434],
              [-70.712668329953388, -33.601655068682327],
              [-70.717854758082609, -33.601655068682327],
              [-70.720447972147198, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 919,
        left: -7851779.6400648728,
        bottom: -3974438.7372228876,
        right: -7850624.9389881147,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.590431160322979],
              [-70.717854758082609, -33.586689532975264],
              [-70.712668329953388, -33.586689532975264],
              [-70.710075115888785, -33.590431160322979],
              [-70.712668329953388, -33.594172625396013],
              [-70.717854758082609, -33.594172625396013],
              [-70.720447972147198, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 920,
        left: -7851779.6400648728,
        bottom: -3973438.7367566377,
        right: -7850624.9389881147,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.58294774336175],
              [-70.717854758082609, -33.579205791491383],
              [-70.712668329953388, -33.579205791491383],
              [-70.710075115888785, -33.58294774336175],
              [-70.712668329953388, -33.586689532975264],
              [-70.717854758082609, -33.586689532975264],
              [-70.720447972147198, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 921,
        left: -7851779.6400648728,
        bottom: -3972438.7362903878,
        right: -7850624.9389881147,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.575463677373087],
              [-70.717854758082609, -33.571721401015779],
              [-70.712668329953388, -33.571721401015779],
              [-70.710075115888785, -33.575463677373087],
              [-70.712668329953388, -33.579205791491383],
              [-70.717854758082609, -33.579205791491383],
              [-70.720447972147198, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 922,
        left: -7851779.6400648728,
        bottom: -3971438.735824137,
        right: -7850624.9389881147,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.567978962428406],
              [-70.717854758082609, -33.564236361619891],
              [-70.712668329953388, -33.564236361619891],
              [-70.710075115888785, -33.567978962428406],
              [-70.712668329953388, -33.571721401015779],
              [-70.717854758082609, -33.571721401015779],
              [-70.720447972147198, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 923,
        left: -7851779.6400648728,
        bottom: -3970438.7353578866,
        right: -7850624.9389881147,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.56049359859918],
              [-70.717854758082609, -33.556750673375213],
              [-70.712668329953388, -33.556750673375213],
              [-70.710075115888785, -33.56049359859918],
              [-70.712668329953388, -33.564236361619891],
              [-70.717854758082609, -33.564236361619891],
              [-70.720447972147198, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 924,
        left: -7851779.6400648728,
        bottom: -3969438.7348916363,
        right: -7850624.9389881147,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.553007585956919],
              [-70.717854758082609, -33.549264336353268],
              [-70.712668329953388, -33.549264336353268],
              [-70.710075115888785, -33.553007585956919],
              [-70.712668329953388, -33.556750673375213],
              [-70.717854758082609, -33.556750673375213],
              [-70.720447972147198, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 925,
        left: -7851779.6400648728,
        bottom: -3968438.7344253859,
        right: -7850624.9389881147,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.545520924573204],
              [-70.717854758082609, -33.541777350625665],
              [-70.712668329953388, -33.541777350625665],
              [-70.710075115888785, -33.545520924573204],
              [-70.712668329953388, -33.549264336353268],
              [-70.717854758082609, -33.549264336353268],
              [-70.720447972147198, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 926,
        left: -7851779.6400648728,
        bottom: -3967438.7339591356,
        right: -7850624.9389881147,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.538033614519627],
              [-70.717854758082609, -33.534289716264041],
              [-70.712668329953388, -33.534289716264041],
              [-70.710075115888785, -33.538033614519627],
              [-70.712668329953388, -33.541777350625665],
              [-70.717854758082609, -33.541777350625665],
              [-70.720447972147198, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 927,
        left: -7851779.6400648728,
        bottom: -3966438.7334928853,
        right: -7850624.9389881147,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.530545655867876],
              [-70.717854758082609, -33.52680143334009],
              [-70.712668329953388, -33.52680143334009],
              [-70.710075115888785, -33.530545655867876],
              [-70.712668329953388, -33.534289716264041],
              [-70.717854758082609, -33.534289716264041],
              [-70.720447972147198, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 928,
        left: -7851779.6400648728,
        bottom: -3965438.7330266349,
        right: -7850624.9389881147,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.523057048689658],
              [-70.717854758082609, -33.519312501925548],
              [-70.712668329953388, -33.519312501925548],
              [-70.710075115888785, -33.523057048689658],
              [-70.712668329953388, -33.52680143334009],
              [-70.717854758082609, -33.52680143334009],
              [-70.720447972147198, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 929,
        left: -7851779.6400648728,
        bottom: -3964438.7325603846,
        right: -7850624.9389881147,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.515567793056739],
              [-70.717854758082609, -33.511822922092215],
              [-70.712668329953388, -33.511822922092215],
              [-70.710075115888785, -33.515567793056739],
              [-70.712668329953388, -33.519312501925548],
              [-70.717854758082609, -33.519312501925548],
              [-70.720447972147198, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 930,
        left: -7851779.6400648728,
        bottom: -3963438.7320941342,
        right: -7850624.9389881147,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.508077889040948],
              [-70.717854758082609, -33.504332693911934],
              [-70.712668329953388, -33.504332693911934],
              [-70.710075115888785, -33.508077889040948],
              [-70.712668329953388, -33.511822922092215],
              [-70.717854758082609, -33.511822922092215],
              [-70.720447972147198, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 931,
        left: -7851779.6400648728,
        bottom: -3962438.7316278839,
        right: -7850624.9389881147,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.500587336714155],
              [-70.717854758082609, -33.496841817456605],
              [-70.712668329953388, -33.496841817456605],
              [-70.710075115888785, -33.500587336714155],
              [-70.712668329953388, -33.504332693911934],
              [-70.717854758082609, -33.504332693911934],
              [-70.720447972147198, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 932,
        left: -7851779.6400648728,
        bottom: -3961438.7311616335,
        right: -7850624.9389881147,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.493096136148274],
              [-70.717854758082609, -33.489350292798164],
              [-70.712668329953388, -33.489350292798164],
              [-70.710075115888785, -33.493096136148274],
              [-70.712668329953388, -33.496841817456605],
              [-70.717854758082609, -33.496841817456605],
              [-70.720447972147198, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 933,
        left: -7851779.6400648728,
        bottom: -3960438.7306953832,
        right: -7850624.9389881147,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.485604287415278],
              [-70.717854758082609, -33.481858120008617],
              [-70.712668329953388, -33.481858120008617],
              [-70.710075115888785, -33.485604287415278],
              [-70.712668329953388, -33.489350292798164],
              [-70.717854758082609, -33.489350292798164],
              [-70.720447972147198, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 934,
        left: -7851779.6400648728,
        bottom: -3959438.7302291333,
        right: -7850624.9389881147,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.478111790587178],
              [-70.717854758082609, -33.474365299160006],
              [-70.712668329953388, -33.474365299160006],
              [-70.710075115888785, -33.478111790587178],
              [-70.712668329953388, -33.481858120008617],
              [-70.717854758082609, -33.481858120008617],
              [-70.720447972147198, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 935,
        left: -7851779.6400648728,
        bottom: -3958438.7297628829,
        right: -7850624.9389881147,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.47061864573606],
              [-70.717854758082609, -33.466871830324415],
              [-70.712668329953388, -33.466871830324415],
              [-70.710075115888785, -33.47061864573606],
              [-70.712668329953388, -33.474365299160006],
              [-70.717854758082609, -33.474365299160006],
              [-70.720447972147198, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 936,
        left: -7851779.6400648728,
        bottom: -3957438.7292966326,
        right: -7850624.9389881147,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.463124852934051],
              [-70.717854758082609, -33.459377713574014],
              [-70.712668329953388, -33.459377713574014],
              [-70.710075115888785, -33.463124852934051],
              [-70.712668329953388, -33.466871830324415],
              [-70.717854758082609, -33.466871830324415],
              [-70.720447972147198, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 937,
        left: -7851779.6400648728,
        bottom: -3956438.7288303822,
        right: -7850624.9389881147,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.455630412253299],
              [-70.717854758082609, -33.45188294898098],
              [-70.712668329953388, -33.45188294898098],
              [-70.710075115888785, -33.455630412253299],
              [-70.712668329953388, -33.459377713574014],
              [-70.717854758082609, -33.459377713574014],
              [-70.720447972147198, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 938,
        left: -7851779.6400648728,
        bottom: -3955438.7283641323,
        right: -7850624.9389881147,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.448135323766053],
              [-70.717854758082609, -33.444387536617576],
              [-70.712668329953388, -33.444387536617576],
              [-70.710075115888785, -33.448135323766053],
              [-70.712668329953388, -33.45188294898098],
              [-70.717854758082609, -33.45188294898098],
              [-70.720447972147198, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 939,
        left: -7851779.6400648728,
        bottom: -3954438.7278978815,
        right: -7850624.9389881147,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.440639587544567],
              [-70.717854758082609, -33.436891476556099],
              [-70.712668329953388, -33.436891476556099],
              [-70.710075115888785, -33.440639587544567],
              [-70.712668329953388, -33.444387536617576],
              [-70.717854758082609, -33.444387536617576],
              [-70.720447972147198, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 940,
        left: -7851779.6400648728,
        bottom: -3953438.7274316312,
        right: -7850624.9389881147,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.433143203661182],
              [-70.717854758082609, -33.429394768868896],
              [-70.712668329953388, -33.429394768868896],
              [-70.710075115888785, -33.433143203661182],
              [-70.712668329953388, -33.436891476556099],
              [-70.717854758082609, -33.436891476556099],
              [-70.720447972147198, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 941,
        left: -7851779.6400648728,
        bottom: -3952438.7269653808,
        right: -7850624.9389881147,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.425646172188266],
              [-70.717854758082609, -33.421897413628365],
              [-70.712668329953388, -33.421897413628365],
              [-70.710075115888785, -33.425646172188266],
              [-70.712668329953388, -33.429394768868896],
              [-70.717854758082609, -33.429394768868896],
              [-70.720447972147198, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 942,
        left: -7851779.6400648728,
        bottom: -3951438.7264991305,
        right: -7850624.9389881147,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.418148493198238],
              [-70.717854758082609, -33.414399410906967],
              [-70.712668329953388, -33.414399410906967],
              [-70.710075115888785, -33.418148493198238],
              [-70.712668329953388, -33.421897413628365],
              [-70.717854758082609, -33.421897413628365],
              [-70.720447972147198, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 943,
        left: -7851779.6400648728,
        bottom: -3950438.7260328801,
        right: -7850624.9389881147,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.410650166763581],
              [-70.717854758082609, -33.406900760777191],
              [-70.712668329953388, -33.406900760777191],
              [-70.710075115888785, -33.410650166763581],
              [-70.712668329953388, -33.414399410906967],
              [-70.717854758082609, -33.414399410906967],
              [-70.720447972147198, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 944,
        left: -7851779.6400648728,
        bottom: -3949438.7255666298,
        right: -7850624.9389881147,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.403151192956834],
              [-70.717854758082609, -33.399401463311598],
              [-70.712668329953388, -33.399401463311598],
              [-70.710075115888785, -33.403151192956834],
              [-70.712668329953388, -33.406900760777191],
              [-70.717854758082609, -33.406900760777191],
              [-70.720447972147198, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 945,
        left: -7851779.6400648728,
        bottom: -3948438.7251003794,
        right: -7850624.9389881147,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.39565157185055],
              [-70.717854758082609, -33.391901518582785],
              [-70.712668329953388, -33.391901518582785],
              [-70.710075115888785, -33.39565157185055],
              [-70.712668329953388, -33.399401463311598],
              [-70.717854758082609, -33.399401463311598],
              [-70.720447972147198, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 946,
        left: -7851779.6400648728,
        bottom: -3947438.7246341291,
        right: -7850624.9389881147,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.388151303517375],
              [-70.717854758082609, -33.384400926663417],
              [-70.712668329953388, -33.384400926663417],
              [-70.710075115888785, -33.388151303517375],
              [-70.712668329953388, -33.391901518582785],
              [-70.717854758082609, -33.391901518582785],
              [-70.720447972147198, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 947,
        left: -7851779.6400648728,
        bottom: -3946438.7241678787,
        right: -7850624.9389881147,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.380650388029984],
              [-70.717854758082609, -33.376899687626185],
              [-70.712668329953388, -33.376899687626185],
              [-70.710075115888785, -33.380650388029984],
              [-70.712668329953388, -33.384400926663417],
              [-70.717854758082609, -33.384400926663417],
              [-70.720447972147198, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 948,
        left: -7851779.6400648728,
        bottom: -3945438.7237016284,
        right: -7850624.9389881147,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.373148825461101],
              [-70.717854758082609, -33.369397801543847],
              [-70.712668329953388, -33.369397801543847],
              [-70.710075115888785, -33.373148825461101],
              [-70.712668329953388, -33.376899687626185],
              [-70.717854758082609, -33.376899687626185],
              [-70.720447972147198, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 949,
        left: -7851779.6400648728,
        bottom: -3944438.723235378,
        right: -7850624.9389881147,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.365646615883513],
              [-70.717854758082609, -33.361895268489214],
              [-70.712668329953388, -33.361895268489214],
              [-70.710075115888785, -33.365646615883513],
              [-70.712668329953388, -33.369397801543847],
              [-70.717854758082609, -33.369397801543847],
              [-70.720447972147198, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 950,
        left: -7851779.6400648728,
        bottom: -3943438.7227691277,
        right: -7850624.9389881147,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.358143759370044],
              [-70.717854758082609, -33.354392088535135],
              [-70.712668329953388, -33.354392088535135],
              [-70.710075115888785, -33.358143759370044],
              [-70.712668329953388, -33.361895268489214],
              [-70.717854758082609, -33.361895268489214],
              [-70.720447972147198, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 951,
        left: -7851779.6400648728,
        bottom: -3942438.7223028769,
        right: -7850624.9389881147,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.350640255993589],
              [-70.717854758082609, -33.346888261754529],
              [-70.712668329953388, -33.346888261754529],
              [-70.710075115888785, -33.350640255993589],
              [-70.712668329953388, -33.354392088535135],
              [-70.717854758082609, -33.354392088535135],
              [-70.720447972147198, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 952,
        left: -7851779.6400648728,
        bottom: -3941438.721836627,
        right: -7850624.9389881147,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.343136105827064],
              [-70.717854758082609, -33.339383788220331],
              [-70.712668329953388, -33.339383788220331],
              [-70.710075115888785, -33.343136105827064],
              [-70.712668329953388, -33.346888261754529],
              [-70.717854758082609, -33.346888261754529],
              [-70.720447972147198, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 953,
        left: -7851779.6400648728,
        bottom: -3940438.7213703771,
        right: -7850624.9389881147,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.335631308943462],
              [-70.717854758082609, -33.331878668005565],
              [-70.712668329953388, -33.331878668005565],
              [-70.710075115888785, -33.335631308943462],
              [-70.712668329953388, -33.339383788220331],
              [-70.717854758082609, -33.339383788220331],
              [-70.720447972147198, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 954,
        left: -7851779.6400648728,
        bottom: -3939438.7209041263,
        right: -7850624.9389881147,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.328125865415807],
              [-70.717854758082609, -33.324372901183303],
              [-70.712668329953388, -33.324372901183303],
              [-70.710075115888785, -33.328125865415807],
              [-70.712668329953388, -33.331878668005565],
              [-70.717854758082609, -33.331878668005565],
              [-70.720447972147198, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 955,
        left: -7851779.6400648728,
        bottom: -3938438.7204378759,
        right: -7850624.9389881147,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.320619775317184],
              [-70.717854758082609, -33.31686648782663],
              [-70.712668329953388, -33.31686648782663],
              [-70.710075115888785, -33.320619775317184],
              [-70.712668329953388, -33.324372901183303],
              [-70.717854758082609, -33.324372901183303],
              [-70.720447972147198, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 956,
        left: -7851779.6400648728,
        bottom: -3937438.7199716261,
        right: -7850624.9389881147,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.313113038720743],
              [-70.717854758082609, -33.309359428008712],
              [-70.712668329953388, -33.309359428008712],
              [-70.710075115888785, -33.313113038720743],
              [-70.712668329953388, -33.31686648782663],
              [-70.717854758082609, -33.31686648782663],
              [-70.720447972147198, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 957,
        left: -7851779.6400648728,
        bottom: -3936438.7195053762,
        right: -7850624.9389881147,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.305605655699658],
              [-70.717854758082609, -33.301851721802763],
              [-70.712668329953388, -33.301851721802763],
              [-70.710075115888785, -33.305605655699658],
              [-70.712668329953388, -33.309359428008712],
              [-70.717854758082609, -33.309359428008712],
              [-70.720447972147198, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 958,
        left: -7851779.6400648728,
        bottom: -3935438.7190391254,
        right: -7850624.9389881147,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.298097626327156],
              [-70.717854758082609, -33.294343369282039],
              [-70.712668329953388, -33.294343369282039],
              [-70.710075115888785, -33.298097626327156],
              [-70.712668329953388, -33.301851721802763],
              [-70.717854758082609, -33.301851721802763],
              [-70.720447972147198, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 959,
        left: -7851779.6400648728,
        bottom: -3934438.718572875,
        right: -7850624.9389881147,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.29058895067655],
              [-70.717854758082609, -33.286834370519863],
              [-70.712668329953388, -33.286834370519863],
              [-70.710075115888785, -33.29058895067655],
              [-70.712668329953388, -33.294343369282039],
              [-70.717854758082609, -33.294343369282039],
              [-70.720447972147198, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 960,
        left: -7851779.6400648728,
        bottom: -3933438.7181066247,
        right: -7850624.9389881147,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.720447972147198, -33.283079628821156],
              [-70.717854758082609, -33.279324725589596],
              [-70.712668329953388, -33.279324725589596],
              [-70.710075115888785, -33.283079628821156],
              [-70.712668329953388, -33.286834370519863],
              [-70.717854758082609, -33.286834370519863],
              [-70.720447972147198, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 961,
        left: -7850913.6142573031,
        bottom: -3980938.7402535151,
        right: -7849758.913180545,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.639057545603663],
              [-70.710075115888785, -33.635318028521546],
              [-70.704888687759578, -33.635318028521546],
              [-70.702295473694974, -33.639057545603663],
              [-70.704888687759578, -33.642796900295409],
              [-70.710075115888785, -33.642796900295409],
              [-70.712668329953388, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 962,
        left: -7850913.6142573031,
        bottom: -3979938.7397872647,
        right: -7849758.913180545,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.631578349057953],
              [-70.710075115888785, -33.627838507221767],
              [-70.704888687759578, -33.627838507221767],
              [-70.702295473694974, -33.631578349057953],
              [-70.704888687759578, -33.635318028521546],
              [-70.710075115888785, -33.635318028521546],
              [-70.712668329953388, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 963,
        left: -7850913.6142573031,
        bottom: -3978938.7393210144,
        right: -7849758.913180545,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.624098503021855],
              [-70.710075115888785, -33.620358336467127],
              [-70.704888687759578, -33.620358336467127],
              [-70.702295473694974, -33.624098503021855],
              [-70.704888687759578, -33.627838507221767],
              [-70.710075115888785, -33.627838507221767],
              [-70.712668329953388, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 964,
        left: -7850913.6142573031,
        bottom: -3977938.738854764,
        right: -7849758.913180545,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.616618007566458],
              [-70.710075115888785, -33.612877516328737],
              [-70.704888687759578, -33.612877516328737],
              [-70.702295473694974, -33.616618007566458],
              [-70.704888687759578, -33.620358336467127],
              [-70.710075115888785, -33.620358336467127],
              [-70.712668329953388, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 965,
        left: -7850913.6142573031,
        bottom: -3976938.7383885137,
        right: -7849758.913180545,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.609136862762881],
              [-70.710075115888785, -33.605396046877765],
              [-70.704888687759578, -33.605396046877765],
              [-70.702295473694974, -33.609136862762881],
              [-70.704888687759578, -33.612877516328737],
              [-70.710075115888785, -33.612877516328737],
              [-70.712668329953388, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 966,
        left: -7850913.6142573031,
        bottom: -3975938.7379222633,
        right: -7849758.913180545,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.601655068682327],
              [-70.710075115888785, -33.597913928185434],
              [-70.704888687759578, -33.597913928185434],
              [-70.702295473694974, -33.601655068682327],
              [-70.704888687759578, -33.605396046877765],
              [-70.710075115888785, -33.605396046877765],
              [-70.712668329953388, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 967,
        left: -7850913.6142573031,
        bottom: -3974938.737456013,
        right: -7849758.913180545,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.594172625396013],
              [-70.710075115888785, -33.590431160322979],
              [-70.704888687759578, -33.590431160322979],
              [-70.702295473694974, -33.594172625396013],
              [-70.704888687759578, -33.597913928185434],
              [-70.710075115888785, -33.597913928185434],
              [-70.712668329953388, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 968,
        left: -7850913.6142573031,
        bottom: -3973938.7369897626,
        right: -7849758.913180545,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.586689532975264],
              [-70.710075115888785, -33.58294774336175],
              [-70.704888687759578, -33.58294774336175],
              [-70.702295473694974, -33.586689532975264],
              [-70.704888687759578, -33.590431160322979],
              [-70.710075115888785, -33.590431160322979],
              [-70.712668329953388, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 969,
        left: -7850913.6142573031,
        bottom: -3972938.7365235123,
        right: -7849758.913180545,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.579205791491383],
              [-70.710075115888785, -33.575463677373087],
              [-70.704888687759578, -33.575463677373087],
              [-70.702295473694974, -33.579205791491383],
              [-70.704888687759578, -33.58294774336175],
              [-70.710075115888785, -33.58294774336175],
              [-70.712668329953388, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 970,
        left: -7850913.6142573031,
        bottom: -3971938.7360572619,
        right: -7849758.913180545,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.571721401015779],
              [-70.710075115888785, -33.567978962428406],
              [-70.704888687759578, -33.567978962428406],
              [-70.702295473694974, -33.571721401015779],
              [-70.704888687759578, -33.575463677373087],
              [-70.710075115888785, -33.575463677373087],
              [-70.712668329953388, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 971,
        left: -7850913.6142573031,
        bottom: -3970938.7355910116,
        right: -7849758.913180545,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.564236361619891],
              [-70.710075115888785, -33.56049359859918],
              [-70.704888687759578, -33.56049359859918],
              [-70.702295473694974, -33.564236361619891],
              [-70.704888687759578, -33.567978962428406],
              [-70.710075115888785, -33.567978962428406],
              [-70.712668329953388, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 972,
        left: -7850913.6142573031,
        bottom: -3969938.7351247617,
        right: -7849758.913180545,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.556750673375213],
              [-70.710075115888785, -33.553007585956919],
              [-70.704888687759578, -33.553007585956919],
              [-70.702295473694974, -33.556750673375213],
              [-70.704888687759578, -33.56049359859918],
              [-70.710075115888785, -33.56049359859918],
              [-70.712668329953388, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 973,
        left: -7850913.6142573031,
        bottom: -3968938.7346585114,
        right: -7849758.913180545,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.549264336353268],
              [-70.710075115888785, -33.545520924573204],
              [-70.704888687759578, -33.545520924573204],
              [-70.702295473694974, -33.549264336353268],
              [-70.704888687759578, -33.553007585956919],
              [-70.710075115888785, -33.553007585956919],
              [-70.712668329953388, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 974,
        left: -7850913.6142573031,
        bottom: -3967938.734192261,
        right: -7849758.913180545,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.541777350625665],
              [-70.710075115888785, -33.538033614519627],
              [-70.704888687759578, -33.538033614519627],
              [-70.702295473694974, -33.541777350625665],
              [-70.704888687759578, -33.545520924573204],
              [-70.710075115888785, -33.545520924573204],
              [-70.712668329953388, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 975,
        left: -7850913.6142573031,
        bottom: -3966938.7337260107,
        right: -7849758.913180545,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.534289716264041],
              [-70.710075115888785, -33.530545655867876],
              [-70.704888687759578, -33.530545655867876],
              [-70.702295473694974, -33.534289716264041],
              [-70.704888687759578, -33.538033614519627],
              [-70.710075115888785, -33.538033614519627],
              [-70.712668329953388, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 976,
        left: -7850913.6142573031,
        bottom: -3965938.7332597603,
        right: -7849758.913180545,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.52680143334009],
              [-70.710075115888785, -33.523057048689658],
              [-70.704888687759578, -33.523057048689658],
              [-70.702295473694974, -33.52680143334009],
              [-70.704888687759578, -33.530545655867876],
              [-70.710075115888785, -33.530545655867876],
              [-70.712668329953388, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 977,
        left: -7850913.6142573031,
        bottom: -3964938.7327935095,
        right: -7849758.913180545,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.519312501925548],
              [-70.710075115888785, -33.515567793056739],
              [-70.704888687759578, -33.515567793056739],
              [-70.702295473694974, -33.519312501925548],
              [-70.704888687759578, -33.523057048689658],
              [-70.710075115888785, -33.523057048689658],
              [-70.712668329953388, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 978,
        left: -7850913.6142573031,
        bottom: -3963938.7323272596,
        right: -7849758.913180545,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.511822922092215],
              [-70.710075115888785, -33.508077889040948],
              [-70.704888687759578, -33.508077889040948],
              [-70.702295473694974, -33.511822922092215],
              [-70.704888687759578, -33.515567793056739],
              [-70.710075115888785, -33.515567793056739],
              [-70.712668329953388, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 979,
        left: -7850913.6142573031,
        bottom: -3962938.7318610097,
        right: -7849758.913180545,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.504332693911934],
              [-70.710075115888785, -33.500587336714155],
              [-70.704888687759578, -33.500587336714155],
              [-70.702295473694974, -33.504332693911934],
              [-70.704888687759578, -33.508077889040948],
              [-70.710075115888785, -33.508077889040948],
              [-70.712668329953388, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 980,
        left: -7850913.6142573031,
        bottom: -3961938.7313947589,
        right: -7849758.913180545,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.496841817456605],
              [-70.710075115888785, -33.493096136148274],
              [-70.704888687759578, -33.493096136148274],
              [-70.702295473694974, -33.496841817456605],
              [-70.704888687759578, -33.500587336714155],
              [-70.710075115888785, -33.500587336714155],
              [-70.712668329953388, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 981,
        left: -7850913.6142573031,
        bottom: -3960938.7309285086,
        right: -7849758.913180545,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.489350292798164],
              [-70.710075115888785, -33.485604287415278],
              [-70.704888687759578, -33.485604287415278],
              [-70.702295473694974, -33.489350292798164],
              [-70.704888687759578, -33.493096136148274],
              [-70.710075115888785, -33.493096136148274],
              [-70.712668329953388, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 982,
        left: -7850913.6142573031,
        bottom: -3959938.7304622582,
        right: -7849758.913180545,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.481858120008617],
              [-70.710075115888785, -33.478111790587178],
              [-70.704888687759578, -33.478111790587178],
              [-70.702295473694974, -33.481858120008617],
              [-70.704888687759578, -33.485604287415278],
              [-70.710075115888785, -33.485604287415278],
              [-70.712668329953388, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 983,
        left: -7850913.6142573031,
        bottom: -3958938.7299960079,
        right: -7849758.913180545,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.474365299160006],
              [-70.710075115888785, -33.47061864573606],
              [-70.704888687759578, -33.47061864573606],
              [-70.702295473694974, -33.474365299160006],
              [-70.704888687759578, -33.478111790587178],
              [-70.710075115888785, -33.478111790587178],
              [-70.712668329953388, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 984,
        left: -7850913.6142573031,
        bottom: -3957938.7295297575,
        right: -7849758.913180545,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.466871830324415],
              [-70.710075115888785, -33.463124852934051],
              [-70.704888687759578, -33.463124852934051],
              [-70.702295473694974, -33.466871830324415],
              [-70.704888687759578, -33.47061864573606],
              [-70.710075115888785, -33.47061864573606],
              [-70.712668329953388, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 985,
        left: -7850913.6142573031,
        bottom: -3956938.7290635072,
        right: -7849758.913180545,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.459377713574014],
              [-70.710075115888785, -33.455630412253299],
              [-70.704888687759578, -33.455630412253299],
              [-70.702295473694974, -33.459377713574014],
              [-70.704888687759578, -33.463124852934051],
              [-70.710075115888785, -33.463124852934051],
              [-70.712668329953388, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 986,
        left: -7850913.6142573031,
        bottom: -3955938.7285972568,
        right: -7849758.913180545,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.45188294898098],
              [-70.710075115888785, -33.448135323766053],
              [-70.704888687759578, -33.448135323766053],
              [-70.702295473694974, -33.45188294898098],
              [-70.704888687759578, -33.455630412253299],
              [-70.710075115888785, -33.455630412253299],
              [-70.712668329953388, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 987,
        left: -7850913.6142573031,
        bottom: -3954938.7281310065,
        right: -7849758.913180545,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.444387536617576],
              [-70.710075115888785, -33.440639587544567],
              [-70.704888687759578, -33.440639587544567],
              [-70.702295473694974, -33.444387536617576],
              [-70.704888687759578, -33.448135323766053],
              [-70.710075115888785, -33.448135323766053],
              [-70.712668329953388, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 988,
        left: -7850913.6142573031,
        bottom: -3953938.7276647561,
        right: -7849758.913180545,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.436891476556099],
              [-70.710075115888785, -33.433143203661182],
              [-70.704888687759578, -33.433143203661182],
              [-70.702295473694974, -33.436891476556099],
              [-70.704888687759578, -33.440639587544567],
              [-70.710075115888785, -33.440639587544567],
              [-70.712668329953388, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 989,
        left: -7850913.6142573031,
        bottom: -3952938.7271985058,
        right: -7849758.913180545,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.429394768868896],
              [-70.710075115888785, -33.425646172188266],
              [-70.704888687759578, -33.425646172188266],
              [-70.702295473694974, -33.429394768868896],
              [-70.704888687759578, -33.433143203661182],
              [-70.710075115888785, -33.433143203661182],
              [-70.712668329953388, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 990,
        left: -7850913.6142573031,
        bottom: -3951938.7267322554,
        right: -7849758.913180545,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.421897413628365],
              [-70.710075115888785, -33.418148493198238],
              [-70.704888687759578, -33.418148493198238],
              [-70.702295473694974, -33.421897413628365],
              [-70.704888687759578, -33.425646172188266],
              [-70.710075115888785, -33.425646172188266],
              [-70.712668329953388, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 991,
        left: -7850913.6142573031,
        bottom: -3950938.7262660051,
        right: -7849758.913180545,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.414399410906967],
              [-70.710075115888785, -33.410650166763581],
              [-70.704888687759578, -33.410650166763581],
              [-70.702295473694974, -33.414399410906967],
              [-70.704888687759578, -33.418148493198238],
              [-70.710075115888785, -33.418148493198238],
              [-70.712668329953388, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 992,
        left: -7850913.6142573031,
        bottom: -3949938.7257997552,
        right: -7849758.913180545,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.406900760777191],
              [-70.710075115888785, -33.403151192956834],
              [-70.704888687759578, -33.403151192956834],
              [-70.702295473694974, -33.406900760777191],
              [-70.704888687759578, -33.410650166763581],
              [-70.710075115888785, -33.410650166763581],
              [-70.712668329953388, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 993,
        left: -7850913.6142573031,
        bottom: -3948938.7253335048,
        right: -7849758.913180545,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.399401463311598],
              [-70.710075115888785, -33.39565157185055],
              [-70.704888687759578, -33.39565157185055],
              [-70.702295473694974, -33.399401463311598],
              [-70.704888687759578, -33.403151192956834],
              [-70.710075115888785, -33.403151192956834],
              [-70.712668329953388, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 994,
        left: -7850913.6142573031,
        bottom: -3947938.724867254,
        right: -7849758.913180545,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.391901518582785],
              [-70.710075115888785, -33.388151303517375],
              [-70.704888687759578, -33.388151303517375],
              [-70.702295473694974, -33.391901518582785],
              [-70.704888687759578, -33.39565157185055],
              [-70.710075115888785, -33.39565157185055],
              [-70.712668329953388, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 995,
        left: -7850913.6142573031,
        bottom: -3946938.7244010041,
        right: -7849758.913180545,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.384400926663417],
              [-70.710075115888785, -33.380650388029984],
              [-70.704888687759578, -33.380650388029984],
              [-70.702295473694974, -33.384400926663417],
              [-70.704888687759578, -33.388151303517375],
              [-70.710075115888785, -33.388151303517375],
              [-70.712668329953388, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 996,
        left: -7850913.6142573031,
        bottom: -3945938.7239347543,
        right: -7849758.913180545,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.376899687626185],
              [-70.710075115888785, -33.373148825461101],
              [-70.704888687759578, -33.373148825461101],
              [-70.702295473694974, -33.376899687626185],
              [-70.704888687759578, -33.380650388029984],
              [-70.710075115888785, -33.380650388029984],
              [-70.712668329953388, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 997,
        left: -7850913.6142573031,
        bottom: -3944938.7234685034,
        right: -7849758.913180545,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.369397801543847],
              [-70.710075115888785, -33.365646615883513],
              [-70.704888687759578, -33.365646615883513],
              [-70.702295473694974, -33.369397801543847],
              [-70.704888687759578, -33.373148825461101],
              [-70.710075115888785, -33.373148825461101],
              [-70.712668329953388, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 998,
        left: -7850913.6142573031,
        bottom: -3943938.7230022531,
        right: -7849758.913180545,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.361895268489214],
              [-70.710075115888785, -33.358143759370044],
              [-70.704888687759578, -33.358143759370044],
              [-70.702295473694974, -33.361895268489214],
              [-70.704888687759578, -33.365646615883513],
              [-70.710075115888785, -33.365646615883513],
              [-70.712668329953388, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 999,
        left: -7850913.6142573031,
        bottom: -3942938.7225360028,
        right: -7849758.913180545,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.354392088535135],
              [-70.710075115888785, -33.350640255993589],
              [-70.704888687759578, -33.350640255993589],
              [-70.702295473694974, -33.354392088535135],
              [-70.704888687759578, -33.358143759370044],
              [-70.710075115888785, -33.358143759370044],
              [-70.712668329953388, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1000,
        left: -7850913.6142573031,
        bottom: -3941938.7220697524,
        right: -7849758.913180545,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.346888261754529],
              [-70.710075115888785, -33.343136105827064],
              [-70.704888687759578, -33.343136105827064],
              [-70.702295473694974, -33.346888261754529],
              [-70.704888687759578, -33.350640255993589],
              [-70.710075115888785, -33.350640255993589],
              [-70.712668329953388, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1001,
        left: -7850913.6142573031,
        bottom: -3940938.7216035021,
        right: -7849758.913180545,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.339383788220331],
              [-70.710075115888785, -33.335631308943462],
              [-70.704888687759578, -33.335631308943462],
              [-70.702295473694974, -33.339383788220331],
              [-70.704888687759578, -33.343136105827064],
              [-70.710075115888785, -33.343136105827064],
              [-70.712668329953388, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1002,
        left: -7850913.6142573031,
        bottom: -3939938.7211372517,
        right: -7849758.913180545,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.331878668005565],
              [-70.710075115888785, -33.328125865415807],
              [-70.704888687759578, -33.328125865415807],
              [-70.702295473694974, -33.331878668005565],
              [-70.704888687759578, -33.335631308943462],
              [-70.710075115888785, -33.335631308943462],
              [-70.712668329953388, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1003,
        left: -7850913.6142573031,
        bottom: -3938938.7206710014,
        right: -7849758.913180545,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.324372901183303],
              [-70.710075115888785, -33.320619775317184],
              [-70.704888687759578, -33.320619775317184],
              [-70.702295473694974, -33.324372901183303],
              [-70.704888687759578, -33.328125865415807],
              [-70.710075115888785, -33.328125865415807],
              [-70.712668329953388, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1004,
        left: -7850913.6142573031,
        bottom: -3937938.720204751,
        right: -7849758.913180545,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.31686648782663],
              [-70.710075115888785, -33.313113038720743],
              [-70.704888687759578, -33.313113038720743],
              [-70.702295473694974, -33.31686648782663],
              [-70.704888687759578, -33.320619775317184],
              [-70.710075115888785, -33.320619775317184],
              [-70.712668329953388, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1005,
        left: -7850913.6142573031,
        bottom: -3936938.7197385007,
        right: -7849758.913180545,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.309359428008712],
              [-70.710075115888785, -33.305605655699658],
              [-70.704888687759578, -33.305605655699658],
              [-70.702295473694974, -33.309359428008712],
              [-70.704888687759578, -33.313113038720743],
              [-70.710075115888785, -33.313113038720743],
              [-70.712668329953388, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1006,
        left: -7850913.6142573031,
        bottom: -3935938.7192722503,
        right: -7849758.913180545,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.301851721802763],
              [-70.710075115888785, -33.298097626327156],
              [-70.704888687759578, -33.298097626327156],
              [-70.702295473694974, -33.301851721802763],
              [-70.704888687759578, -33.305605655699658],
              [-70.710075115888785, -33.305605655699658],
              [-70.712668329953388, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1007,
        left: -7850913.6142573031,
        bottom: -3934938.718806,
        right: -7849758.913180545,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.294343369282039],
              [-70.710075115888785, -33.29058895067655],
              [-70.704888687759578, -33.29058895067655],
              [-70.702295473694974, -33.294343369282039],
              [-70.704888687759578, -33.298097626327156],
              [-70.710075115888785, -33.298097626327156],
              [-70.712668329953388, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1008,
        left: -7850913.6142573031,
        bottom: -3933938.7183397496,
        right: -7849758.913180545,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.712668329953388, -33.286834370519863],
              [-70.710075115888785, -33.283079628821156],
              [-70.704888687759578, -33.283079628821156],
              [-70.702295473694974, -33.286834370519863],
              [-70.704888687759578, -33.29058895067655],
              [-70.710075115888785, -33.29058895067655],
              [-70.712668329953388, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1009,
        left: -7850047.5884497343,
        bottom: -3980438.7400203901,
        right: -7848892.8873729762,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.635318028521546],
              [-70.702295473694974, -33.631578349057953],
              [-70.697109045565767, -33.631578349057953],
              [-70.694515831501164, -33.635318028521546],
              [-70.697109045565767, -33.639057545603663],
              [-70.702295473694974, -33.639057545603663],
              [-70.704888687759578, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1010,
        left: -7850047.5884497343,
        bottom: -3979438.7395541398,
        right: -7848892.8873729762,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.627838507221767],
              [-70.702295473694974, -33.624098503021855],
              [-70.697109045565767, -33.624098503021855],
              [-70.694515831501164, -33.627838507221767],
              [-70.697109045565767, -33.631578349057953],
              [-70.702295473694974, -33.631578349057953],
              [-70.704888687759578, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1011,
        left: -7850047.5884497343,
        bottom: -3978438.7390878894,
        right: -7848892.8873729762,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.620358336467127],
              [-70.702295473694974, -33.616618007566458],
              [-70.697109045565767, -33.616618007566458],
              [-70.694515831501164, -33.620358336467127],
              [-70.697109045565767, -33.624098503021855],
              [-70.702295473694974, -33.624098503021855],
              [-70.704888687759578, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1012,
        left: -7850047.5884497343,
        bottom: -3977438.7386216391,
        right: -7848892.8873729762,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.612877516328737],
              [-70.702295473694974, -33.609136862762881],
              [-70.697109045565767, -33.609136862762881],
              [-70.694515831501164, -33.612877516328737],
              [-70.697109045565767, -33.616618007566458],
              [-70.702295473694974, -33.616618007566458],
              [-70.704888687759578, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1013,
        left: -7850047.5884497343,
        bottom: -3976438.7381553887,
        right: -7848892.8873729762,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.605396046877765],
              [-70.702295473694974, -33.601655068682327],
              [-70.697109045565767, -33.601655068682327],
              [-70.694515831501164, -33.605396046877765],
              [-70.697109045565767, -33.609136862762881],
              [-70.702295473694974, -33.609136862762881],
              [-70.704888687759578, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1014,
        left: -7850047.5884497343,
        bottom: -3975438.7376891384,
        right: -7848892.8873729762,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.597913928185434],
              [-70.702295473694974, -33.594172625396013],
              [-70.697109045565767, -33.594172625396013],
              [-70.694515831501164, -33.597913928185434],
              [-70.697109045565767, -33.601655068682327],
              [-70.702295473694974, -33.601655068682327],
              [-70.704888687759578, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1015,
        left: -7850047.5884497343,
        bottom: -3974438.7372228876,
        right: -7848892.8873729762,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.590431160322979],
              [-70.702295473694974, -33.586689532975264],
              [-70.697109045565767, -33.586689532975264],
              [-70.694515831501164, -33.590431160322979],
              [-70.697109045565767, -33.594172625396013],
              [-70.702295473694974, -33.594172625396013],
              [-70.704888687759578, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1016,
        left: -7850047.5884497343,
        bottom: -3973438.7367566377,
        right: -7848892.8873729762,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.58294774336175],
              [-70.702295473694974, -33.579205791491383],
              [-70.697109045565767, -33.579205791491383],
              [-70.694515831501164, -33.58294774336175],
              [-70.697109045565767, -33.586689532975264],
              [-70.702295473694974, -33.586689532975264],
              [-70.704888687759578, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1017,
        left: -7850047.5884497343,
        bottom: -3972438.7362903878,
        right: -7848892.8873729762,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.575463677373087],
              [-70.702295473694974, -33.571721401015779],
              [-70.697109045565767, -33.571721401015779],
              [-70.694515831501164, -33.575463677373087],
              [-70.697109045565767, -33.579205791491383],
              [-70.702295473694974, -33.579205791491383],
              [-70.704888687759578, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1018,
        left: -7850047.5884497343,
        bottom: -3971438.735824137,
        right: -7848892.8873729762,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.567978962428406],
              [-70.702295473694974, -33.564236361619891],
              [-70.697109045565767, -33.564236361619891],
              [-70.694515831501164, -33.567978962428406],
              [-70.697109045565767, -33.571721401015779],
              [-70.702295473694974, -33.571721401015779],
              [-70.704888687759578, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1019,
        left: -7850047.5884497343,
        bottom: -3970438.7353578866,
        right: -7848892.8873729762,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.56049359859918],
              [-70.702295473694974, -33.556750673375213],
              [-70.697109045565767, -33.556750673375213],
              [-70.694515831501164, -33.56049359859918],
              [-70.697109045565767, -33.564236361619891],
              [-70.702295473694974, -33.564236361619891],
              [-70.704888687759578, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1020,
        left: -7850047.5884497343,
        bottom: -3969438.7348916363,
        right: -7848892.8873729762,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.553007585956919],
              [-70.702295473694974, -33.549264336353268],
              [-70.697109045565767, -33.549264336353268],
              [-70.694515831501164, -33.553007585956919],
              [-70.697109045565767, -33.556750673375213],
              [-70.702295473694974, -33.556750673375213],
              [-70.704888687759578, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1021,
        left: -7850047.5884497343,
        bottom: -3968438.7344253859,
        right: -7848892.8873729762,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.545520924573204],
              [-70.702295473694974, -33.541777350625665],
              [-70.697109045565767, -33.541777350625665],
              [-70.694515831501164, -33.545520924573204],
              [-70.697109045565767, -33.549264336353268],
              [-70.702295473694974, -33.549264336353268],
              [-70.704888687759578, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1022,
        left: -7850047.5884497343,
        bottom: -3967438.7339591356,
        right: -7848892.8873729762,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.538033614519627],
              [-70.702295473694974, -33.534289716264041],
              [-70.697109045565767, -33.534289716264041],
              [-70.694515831501164, -33.538033614519627],
              [-70.697109045565767, -33.541777350625665],
              [-70.702295473694974, -33.541777350625665],
              [-70.704888687759578, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1023,
        left: -7850047.5884497343,
        bottom: -3966438.7334928853,
        right: -7848892.8873729762,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.530545655867876],
              [-70.702295473694974, -33.52680143334009],
              [-70.697109045565767, -33.52680143334009],
              [-70.694515831501164, -33.530545655867876],
              [-70.697109045565767, -33.534289716264041],
              [-70.702295473694974, -33.534289716264041],
              [-70.704888687759578, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1024,
        left: -7850047.5884497343,
        bottom: -3965438.7330266349,
        right: -7848892.8873729762,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.523057048689658],
              [-70.702295473694974, -33.519312501925548],
              [-70.697109045565767, -33.519312501925548],
              [-70.694515831501164, -33.523057048689658],
              [-70.697109045565767, -33.52680143334009],
              [-70.702295473694974, -33.52680143334009],
              [-70.704888687759578, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1025,
        left: -7850047.5884497343,
        bottom: -3964438.7325603846,
        right: -7848892.8873729762,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.515567793056739],
              [-70.702295473694974, -33.511822922092215],
              [-70.697109045565767, -33.511822922092215],
              [-70.694515831501164, -33.515567793056739],
              [-70.697109045565767, -33.519312501925548],
              [-70.702295473694974, -33.519312501925548],
              [-70.704888687759578, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1026,
        left: -7850047.5884497343,
        bottom: -3963438.7320941342,
        right: -7848892.8873729762,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.508077889040948],
              [-70.702295473694974, -33.504332693911934],
              [-70.697109045565767, -33.504332693911934],
              [-70.694515831501164, -33.508077889040948],
              [-70.697109045565767, -33.511822922092215],
              [-70.702295473694974, -33.511822922092215],
              [-70.704888687759578, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1027,
        left: -7850047.5884497343,
        bottom: -3962438.7316278839,
        right: -7848892.8873729762,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.500587336714155],
              [-70.702295473694974, -33.496841817456605],
              [-70.697109045565767, -33.496841817456605],
              [-70.694515831501164, -33.500587336714155],
              [-70.697109045565767, -33.504332693911934],
              [-70.702295473694974, -33.504332693911934],
              [-70.704888687759578, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1028,
        left: -7850047.5884497343,
        bottom: -3961438.7311616335,
        right: -7848892.8873729762,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.493096136148274],
              [-70.702295473694974, -33.489350292798164],
              [-70.697109045565767, -33.489350292798164],
              [-70.694515831501164, -33.493096136148274],
              [-70.697109045565767, -33.496841817456605],
              [-70.702295473694974, -33.496841817456605],
              [-70.704888687759578, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1029,
        left: -7850047.5884497343,
        bottom: -3960438.7306953832,
        right: -7848892.8873729762,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.485604287415278],
              [-70.702295473694974, -33.481858120008617],
              [-70.697109045565767, -33.481858120008617],
              [-70.694515831501164, -33.485604287415278],
              [-70.697109045565767, -33.489350292798164],
              [-70.702295473694974, -33.489350292798164],
              [-70.704888687759578, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1030,
        left: -7850047.5884497343,
        bottom: -3959438.7302291333,
        right: -7848892.8873729762,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.478111790587178],
              [-70.702295473694974, -33.474365299160006],
              [-70.697109045565767, -33.474365299160006],
              [-70.694515831501164, -33.478111790587178],
              [-70.697109045565767, -33.481858120008617],
              [-70.702295473694974, -33.481858120008617],
              [-70.704888687759578, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1031,
        left: -7850047.5884497343,
        bottom: -3958438.7297628829,
        right: -7848892.8873729762,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.47061864573606],
              [-70.702295473694974, -33.466871830324415],
              [-70.697109045565767, -33.466871830324415],
              [-70.694515831501164, -33.47061864573606],
              [-70.697109045565767, -33.474365299160006],
              [-70.702295473694974, -33.474365299160006],
              [-70.704888687759578, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1032,
        left: -7850047.5884497343,
        bottom: -3957438.7292966326,
        right: -7848892.8873729762,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.463124852934051],
              [-70.702295473694974, -33.459377713574014],
              [-70.697109045565767, -33.459377713574014],
              [-70.694515831501164, -33.463124852934051],
              [-70.697109045565767, -33.466871830324415],
              [-70.702295473694974, -33.466871830324415],
              [-70.704888687759578, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1033,
        left: -7850047.5884497343,
        bottom: -3956438.7288303822,
        right: -7848892.8873729762,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.455630412253299],
              [-70.702295473694974, -33.45188294898098],
              [-70.697109045565767, -33.45188294898098],
              [-70.694515831501164, -33.455630412253299],
              [-70.697109045565767, -33.459377713574014],
              [-70.702295473694974, -33.459377713574014],
              [-70.704888687759578, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1034,
        left: -7850047.5884497343,
        bottom: -3955438.7283641323,
        right: -7848892.8873729762,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.448135323766053],
              [-70.702295473694974, -33.444387536617576],
              [-70.697109045565767, -33.444387536617576],
              [-70.694515831501164, -33.448135323766053],
              [-70.697109045565767, -33.45188294898098],
              [-70.702295473694974, -33.45188294898098],
              [-70.704888687759578, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1035,
        left: -7850047.5884497343,
        bottom: -3954438.7278978815,
        right: -7848892.8873729762,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.440639587544567],
              [-70.702295473694974, -33.436891476556099],
              [-70.697109045565767, -33.436891476556099],
              [-70.694515831501164, -33.440639587544567],
              [-70.697109045565767, -33.444387536617576],
              [-70.702295473694974, -33.444387536617576],
              [-70.704888687759578, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1036,
        left: -7850047.5884497343,
        bottom: -3953438.7274316312,
        right: -7848892.8873729762,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.433143203661182],
              [-70.702295473694974, -33.429394768868896],
              [-70.697109045565767, -33.429394768868896],
              [-70.694515831501164, -33.433143203661182],
              [-70.697109045565767, -33.436891476556099],
              [-70.702295473694974, -33.436891476556099],
              [-70.704888687759578, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1037,
        left: -7850047.5884497343,
        bottom: -3952438.7269653808,
        right: -7848892.8873729762,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.425646172188266],
              [-70.702295473694974, -33.421897413628365],
              [-70.697109045565767, -33.421897413628365],
              [-70.694515831501164, -33.425646172188266],
              [-70.697109045565767, -33.429394768868896],
              [-70.702295473694974, -33.429394768868896],
              [-70.704888687759578, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1038,
        left: -7850047.5884497343,
        bottom: -3951438.7264991305,
        right: -7848892.8873729762,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.418148493198238],
              [-70.702295473694974, -33.414399410906967],
              [-70.697109045565767, -33.414399410906967],
              [-70.694515831501164, -33.418148493198238],
              [-70.697109045565767, -33.421897413628365],
              [-70.702295473694974, -33.421897413628365],
              [-70.704888687759578, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1039,
        left: -7850047.5884497343,
        bottom: -3950438.7260328801,
        right: -7848892.8873729762,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.410650166763581],
              [-70.702295473694974, -33.406900760777191],
              [-70.697109045565767, -33.406900760777191],
              [-70.694515831501164, -33.410650166763581],
              [-70.697109045565767, -33.414399410906967],
              [-70.702295473694974, -33.414399410906967],
              [-70.704888687759578, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1040,
        left: -7850047.5884497343,
        bottom: -3949438.7255666298,
        right: -7848892.8873729762,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.403151192956834],
              [-70.702295473694974, -33.399401463311598],
              [-70.697109045565767, -33.399401463311598],
              [-70.694515831501164, -33.403151192956834],
              [-70.697109045565767, -33.406900760777191],
              [-70.702295473694974, -33.406900760777191],
              [-70.704888687759578, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1041,
        left: -7850047.5884497343,
        bottom: -3948438.7251003794,
        right: -7848892.8873729762,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.39565157185055],
              [-70.702295473694974, -33.391901518582785],
              [-70.697109045565767, -33.391901518582785],
              [-70.694515831501164, -33.39565157185055],
              [-70.697109045565767, -33.399401463311598],
              [-70.702295473694974, -33.399401463311598],
              [-70.704888687759578, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1042,
        left: -7850047.5884497343,
        bottom: -3947438.7246341291,
        right: -7848892.8873729762,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.388151303517375],
              [-70.702295473694974, -33.384400926663417],
              [-70.697109045565767, -33.384400926663417],
              [-70.694515831501164, -33.388151303517375],
              [-70.697109045565767, -33.391901518582785],
              [-70.702295473694974, -33.391901518582785],
              [-70.704888687759578, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1043,
        left: -7850047.5884497343,
        bottom: -3946438.7241678787,
        right: -7848892.8873729762,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.380650388029984],
              [-70.702295473694974, -33.376899687626185],
              [-70.697109045565767, -33.376899687626185],
              [-70.694515831501164, -33.380650388029984],
              [-70.697109045565767, -33.384400926663417],
              [-70.702295473694974, -33.384400926663417],
              [-70.704888687759578, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1044,
        left: -7850047.5884497343,
        bottom: -3945438.7237016284,
        right: -7848892.8873729762,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.373148825461101],
              [-70.702295473694974, -33.369397801543847],
              [-70.697109045565767, -33.369397801543847],
              [-70.694515831501164, -33.373148825461101],
              [-70.697109045565767, -33.376899687626185],
              [-70.702295473694974, -33.376899687626185],
              [-70.704888687759578, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1045,
        left: -7850047.5884497343,
        bottom: -3944438.723235378,
        right: -7848892.8873729762,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.365646615883513],
              [-70.702295473694974, -33.361895268489214],
              [-70.697109045565767, -33.361895268489214],
              [-70.694515831501164, -33.365646615883513],
              [-70.697109045565767, -33.369397801543847],
              [-70.702295473694974, -33.369397801543847],
              [-70.704888687759578, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1046,
        left: -7850047.5884497343,
        bottom: -3943438.7227691277,
        right: -7848892.8873729762,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.358143759370044],
              [-70.702295473694974, -33.354392088535135],
              [-70.697109045565767, -33.354392088535135],
              [-70.694515831501164, -33.358143759370044],
              [-70.697109045565767, -33.361895268489214],
              [-70.702295473694974, -33.361895268489214],
              [-70.704888687759578, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1047,
        left: -7850047.5884497343,
        bottom: -3942438.7223028769,
        right: -7848892.8873729762,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.350640255993589],
              [-70.702295473694974, -33.346888261754529],
              [-70.697109045565767, -33.346888261754529],
              [-70.694515831501164, -33.350640255993589],
              [-70.697109045565767, -33.354392088535135],
              [-70.702295473694974, -33.354392088535135],
              [-70.704888687759578, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1048,
        left: -7850047.5884497343,
        bottom: -3941438.721836627,
        right: -7848892.8873729762,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.343136105827064],
              [-70.702295473694974, -33.339383788220331],
              [-70.697109045565767, -33.339383788220331],
              [-70.694515831501164, -33.343136105827064],
              [-70.697109045565767, -33.346888261754529],
              [-70.702295473694974, -33.346888261754529],
              [-70.704888687759578, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1049,
        left: -7850047.5884497343,
        bottom: -3940438.7213703771,
        right: -7848892.8873729762,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.335631308943462],
              [-70.702295473694974, -33.331878668005565],
              [-70.697109045565767, -33.331878668005565],
              [-70.694515831501164, -33.335631308943462],
              [-70.697109045565767, -33.339383788220331],
              [-70.702295473694974, -33.339383788220331],
              [-70.704888687759578, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1050,
        left: -7850047.5884497343,
        bottom: -3939438.7209041263,
        right: -7848892.8873729762,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.328125865415807],
              [-70.702295473694974, -33.324372901183303],
              [-70.697109045565767, -33.324372901183303],
              [-70.694515831501164, -33.328125865415807],
              [-70.697109045565767, -33.331878668005565],
              [-70.702295473694974, -33.331878668005565],
              [-70.704888687759578, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1051,
        left: -7850047.5884497343,
        bottom: -3938438.7204378759,
        right: -7848892.8873729762,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.320619775317184],
              [-70.702295473694974, -33.31686648782663],
              [-70.697109045565767, -33.31686648782663],
              [-70.694515831501164, -33.320619775317184],
              [-70.697109045565767, -33.324372901183303],
              [-70.702295473694974, -33.324372901183303],
              [-70.704888687759578, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1052,
        left: -7850047.5884497343,
        bottom: -3937438.7199716261,
        right: -7848892.8873729762,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.313113038720743],
              [-70.702295473694974, -33.309359428008712],
              [-70.697109045565767, -33.309359428008712],
              [-70.694515831501164, -33.313113038720743],
              [-70.697109045565767, -33.31686648782663],
              [-70.702295473694974, -33.31686648782663],
              [-70.704888687759578, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1053,
        left: -7850047.5884497343,
        bottom: -3936438.7195053762,
        right: -7848892.8873729762,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.305605655699658],
              [-70.702295473694974, -33.301851721802763],
              [-70.697109045565767, -33.301851721802763],
              [-70.694515831501164, -33.305605655699658],
              [-70.697109045565767, -33.309359428008712],
              [-70.702295473694974, -33.309359428008712],
              [-70.704888687759578, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1054,
        left: -7850047.5884497343,
        bottom: -3935438.7190391254,
        right: -7848892.8873729762,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.298097626327156],
              [-70.702295473694974, -33.294343369282039],
              [-70.697109045565767, -33.294343369282039],
              [-70.694515831501164, -33.298097626327156],
              [-70.697109045565767, -33.301851721802763],
              [-70.702295473694974, -33.301851721802763],
              [-70.704888687759578, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1055,
        left: -7850047.5884497343,
        bottom: -3934438.718572875,
        right: -7848892.8873729762,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.29058895067655],
              [-70.702295473694974, -33.286834370519863],
              [-70.697109045565767, -33.286834370519863],
              [-70.694515831501164, -33.29058895067655],
              [-70.697109045565767, -33.294343369282039],
              [-70.702295473694974, -33.294343369282039],
              [-70.704888687759578, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1056,
        left: -7850047.5884497343,
        bottom: -3933438.7181066247,
        right: -7848892.8873729762,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.704888687759578, -33.283079628821156],
              [-70.702295473694974, -33.279324725589596],
              [-70.697109045565767, -33.279324725589596],
              [-70.694515831501164, -33.283079628821156],
              [-70.697109045565767, -33.286834370519863],
              [-70.702295473694974, -33.286834370519863],
              [-70.704888687759578, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1057,
        left: -7849181.5626421655,
        bottom: -3980938.7402535151,
        right: -7848026.8615654064,
        top: -3979938.7397872647,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.639057545603663],
              [-70.694515831501164, -33.635318028521546],
              [-70.689329403371943, -33.635318028521546],
              [-70.686736189307354, -33.639057545603663],
              [-70.689329403371943, -33.642796900295409],
              [-70.694515831501164, -33.642796900295409],
              [-70.697109045565767, -33.639057545603663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1058,
        left: -7849181.5626421655,
        bottom: -3979938.7397872647,
        right: -7848026.8615654064,
        top: -3978938.7393210144,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.631578349057953],
              [-70.694515831501164, -33.627838507221767],
              [-70.689329403371943, -33.627838507221767],
              [-70.686736189307354, -33.631578349057953],
              [-70.689329403371943, -33.635318028521546],
              [-70.694515831501164, -33.635318028521546],
              [-70.697109045565767, -33.631578349057953],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1059,
        left: -7849181.5626421655,
        bottom: -3978938.7393210144,
        right: -7848026.8615654064,
        top: -3977938.738854764,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.624098503021855],
              [-70.694515831501164, -33.620358336467127],
              [-70.689329403371943, -33.620358336467127],
              [-70.686736189307354, -33.624098503021855],
              [-70.689329403371943, -33.627838507221767],
              [-70.694515831501164, -33.627838507221767],
              [-70.697109045565767, -33.624098503021855],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1060,
        left: -7849181.5626421655,
        bottom: -3977938.738854764,
        right: -7848026.8615654064,
        top: -3976938.7383885137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.616618007566458],
              [-70.694515831501164, -33.612877516328737],
              [-70.689329403371943, -33.612877516328737],
              [-70.686736189307354, -33.616618007566458],
              [-70.689329403371943, -33.620358336467127],
              [-70.694515831501164, -33.620358336467127],
              [-70.697109045565767, -33.616618007566458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1061,
        left: -7849181.5626421655,
        bottom: -3976938.7383885137,
        right: -7848026.8615654064,
        top: -3975938.7379222633,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.609136862762881],
              [-70.694515831501164, -33.605396046877765],
              [-70.689329403371943, -33.605396046877765],
              [-70.686736189307354, -33.609136862762881],
              [-70.689329403371943, -33.612877516328737],
              [-70.694515831501164, -33.612877516328737],
              [-70.697109045565767, -33.609136862762881],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1062,
        left: -7849181.5626421655,
        bottom: -3975938.7379222633,
        right: -7848026.8615654064,
        top: -3974938.737456013,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.601655068682327],
              [-70.694515831501164, -33.597913928185434],
              [-70.689329403371943, -33.597913928185434],
              [-70.686736189307354, -33.601655068682327],
              [-70.689329403371943, -33.605396046877765],
              [-70.694515831501164, -33.605396046877765],
              [-70.697109045565767, -33.601655068682327],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1063,
        left: -7849181.5626421655,
        bottom: -3974938.737456013,
        right: -7848026.8615654064,
        top: -3973938.7369897626,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.594172625396013],
              [-70.694515831501164, -33.590431160322979],
              [-70.689329403371943, -33.590431160322979],
              [-70.686736189307354, -33.594172625396013],
              [-70.689329403371943, -33.597913928185434],
              [-70.694515831501164, -33.597913928185434],
              [-70.697109045565767, -33.594172625396013],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1064,
        left: -7849181.5626421655,
        bottom: -3973938.7369897626,
        right: -7848026.8615654064,
        top: -3972938.7365235123,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.586689532975264],
              [-70.694515831501164, -33.58294774336175],
              [-70.689329403371943, -33.58294774336175],
              [-70.686736189307354, -33.586689532975264],
              [-70.689329403371943, -33.590431160322979],
              [-70.694515831501164, -33.590431160322979],
              [-70.697109045565767, -33.586689532975264],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1065,
        left: -7849181.5626421655,
        bottom: -3972938.7365235123,
        right: -7848026.8615654064,
        top: -3971938.7360572619,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.579205791491383],
              [-70.694515831501164, -33.575463677373087],
              [-70.689329403371943, -33.575463677373087],
              [-70.686736189307354, -33.579205791491383],
              [-70.689329403371943, -33.58294774336175],
              [-70.694515831501164, -33.58294774336175],
              [-70.697109045565767, -33.579205791491383],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1066,
        left: -7849181.5626421655,
        bottom: -3971938.7360572619,
        right: -7848026.8615654064,
        top: -3970938.7355910116,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.571721401015779],
              [-70.694515831501164, -33.567978962428406],
              [-70.689329403371943, -33.567978962428406],
              [-70.686736189307354, -33.571721401015779],
              [-70.689329403371943, -33.575463677373087],
              [-70.694515831501164, -33.575463677373087],
              [-70.697109045565767, -33.571721401015779],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1067,
        left: -7849181.5626421655,
        bottom: -3970938.7355910116,
        right: -7848026.8615654064,
        top: -3969938.7351247617,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.564236361619891],
              [-70.694515831501164, -33.56049359859918],
              [-70.689329403371943, -33.56049359859918],
              [-70.686736189307354, -33.564236361619891],
              [-70.689329403371943, -33.567978962428406],
              [-70.694515831501164, -33.567978962428406],
              [-70.697109045565767, -33.564236361619891],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1068,
        left: -7849181.5626421655,
        bottom: -3969938.7351247617,
        right: -7848026.8615654064,
        top: -3968938.7346585114,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.556750673375213],
              [-70.694515831501164, -33.553007585956919],
              [-70.689329403371943, -33.553007585956919],
              [-70.686736189307354, -33.556750673375213],
              [-70.689329403371943, -33.56049359859918],
              [-70.694515831501164, -33.56049359859918],
              [-70.697109045565767, -33.556750673375213],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1069,
        left: -7849181.5626421655,
        bottom: -3968938.7346585114,
        right: -7848026.8615654064,
        top: -3967938.734192261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.549264336353268],
              [-70.694515831501164, -33.545520924573204],
              [-70.689329403371943, -33.545520924573204],
              [-70.686736189307354, -33.549264336353268],
              [-70.689329403371943, -33.553007585956919],
              [-70.694515831501164, -33.553007585956919],
              [-70.697109045565767, -33.549264336353268],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1070,
        left: -7849181.5626421655,
        bottom: -3967938.734192261,
        right: -7848026.8615654064,
        top: -3966938.7337260107,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.541777350625665],
              [-70.694515831501164, -33.538033614519627],
              [-70.689329403371943, -33.538033614519627],
              [-70.686736189307354, -33.541777350625665],
              [-70.689329403371943, -33.545520924573204],
              [-70.694515831501164, -33.545520924573204],
              [-70.697109045565767, -33.541777350625665],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1071,
        left: -7849181.5626421655,
        bottom: -3966938.7337260107,
        right: -7848026.8615654064,
        top: -3965938.7332597603,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.534289716264041],
              [-70.694515831501164, -33.530545655867876],
              [-70.689329403371943, -33.530545655867876],
              [-70.686736189307354, -33.534289716264041],
              [-70.689329403371943, -33.538033614519627],
              [-70.694515831501164, -33.538033614519627],
              [-70.697109045565767, -33.534289716264041],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1072,
        left: -7849181.5626421655,
        bottom: -3965938.7332597603,
        right: -7848026.8615654064,
        top: -3964938.7327935095,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.52680143334009],
              [-70.694515831501164, -33.523057048689658],
              [-70.689329403371943, -33.523057048689658],
              [-70.686736189307354, -33.52680143334009],
              [-70.689329403371943, -33.530545655867876],
              [-70.694515831501164, -33.530545655867876],
              [-70.697109045565767, -33.52680143334009],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1073,
        left: -7849181.5626421655,
        bottom: -3964938.7327935095,
        right: -7848026.8615654064,
        top: -3963938.7323272596,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.519312501925548],
              [-70.694515831501164, -33.515567793056739],
              [-70.689329403371943, -33.515567793056739],
              [-70.686736189307354, -33.519312501925548],
              [-70.689329403371943, -33.523057048689658],
              [-70.694515831501164, -33.523057048689658],
              [-70.697109045565767, -33.519312501925548],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1074,
        left: -7849181.5626421655,
        bottom: -3963938.7323272596,
        right: -7848026.8615654064,
        top: -3962938.7318610097,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.511822922092215],
              [-70.694515831501164, -33.508077889040948],
              [-70.689329403371943, -33.508077889040948],
              [-70.686736189307354, -33.511822922092215],
              [-70.689329403371943, -33.515567793056739],
              [-70.694515831501164, -33.515567793056739],
              [-70.697109045565767, -33.511822922092215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1075,
        left: -7849181.5626421655,
        bottom: -3962938.7318610097,
        right: -7848026.8615654064,
        top: -3961938.7313947589,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.504332693911934],
              [-70.694515831501164, -33.500587336714155],
              [-70.689329403371943, -33.500587336714155],
              [-70.686736189307354, -33.504332693911934],
              [-70.689329403371943, -33.508077889040948],
              [-70.694515831501164, -33.508077889040948],
              [-70.697109045565767, -33.504332693911934],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1076,
        left: -7849181.5626421655,
        bottom: -3961938.7313947589,
        right: -7848026.8615654064,
        top: -3960938.7309285086,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.496841817456605],
              [-70.694515831501164, -33.493096136148274],
              [-70.689329403371943, -33.493096136148274],
              [-70.686736189307354, -33.496841817456605],
              [-70.689329403371943, -33.500587336714155],
              [-70.694515831501164, -33.500587336714155],
              [-70.697109045565767, -33.496841817456605],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1077,
        left: -7849181.5626421655,
        bottom: -3960938.7309285086,
        right: -7848026.8615654064,
        top: -3959938.7304622582,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.489350292798164],
              [-70.694515831501164, -33.485604287415278],
              [-70.689329403371943, -33.485604287415278],
              [-70.686736189307354, -33.489350292798164],
              [-70.689329403371943, -33.493096136148274],
              [-70.694515831501164, -33.493096136148274],
              [-70.697109045565767, -33.489350292798164],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1078,
        left: -7849181.5626421655,
        bottom: -3959938.7304622582,
        right: -7848026.8615654064,
        top: -3958938.7299960079,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.481858120008617],
              [-70.694515831501164, -33.478111790587178],
              [-70.689329403371943, -33.478111790587178],
              [-70.686736189307354, -33.481858120008617],
              [-70.689329403371943, -33.485604287415278],
              [-70.694515831501164, -33.485604287415278],
              [-70.697109045565767, -33.481858120008617],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1079,
        left: -7849181.5626421655,
        bottom: -3958938.7299960079,
        right: -7848026.8615654064,
        top: -3957938.7295297575,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.474365299160006],
              [-70.694515831501164, -33.47061864573606],
              [-70.689329403371943, -33.47061864573606],
              [-70.686736189307354, -33.474365299160006],
              [-70.689329403371943, -33.478111790587178],
              [-70.694515831501164, -33.478111790587178],
              [-70.697109045565767, -33.474365299160006],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1080,
        left: -7849181.5626421655,
        bottom: -3957938.7295297575,
        right: -7848026.8615654064,
        top: -3956938.7290635072,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.466871830324415],
              [-70.694515831501164, -33.463124852934051],
              [-70.689329403371943, -33.463124852934051],
              [-70.686736189307354, -33.466871830324415],
              [-70.689329403371943, -33.47061864573606],
              [-70.694515831501164, -33.47061864573606],
              [-70.697109045565767, -33.466871830324415],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1081,
        left: -7849181.5626421655,
        bottom: -3956938.7290635072,
        right: -7848026.8615654064,
        top: -3955938.7285972568,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.459377713574014],
              [-70.694515831501164, -33.455630412253299],
              [-70.689329403371943, -33.455630412253299],
              [-70.686736189307354, -33.459377713574014],
              [-70.689329403371943, -33.463124852934051],
              [-70.694515831501164, -33.463124852934051],
              [-70.697109045565767, -33.459377713574014],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1082,
        left: -7849181.5626421655,
        bottom: -3955938.7285972568,
        right: -7848026.8615654064,
        top: -3954938.7281310065,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.45188294898098],
              [-70.694515831501164, -33.448135323766053],
              [-70.689329403371943, -33.448135323766053],
              [-70.686736189307354, -33.45188294898098],
              [-70.689329403371943, -33.455630412253299],
              [-70.694515831501164, -33.455630412253299],
              [-70.697109045565767, -33.45188294898098],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1083,
        left: -7849181.5626421655,
        bottom: -3954938.7281310065,
        right: -7848026.8615654064,
        top: -3953938.7276647561,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.444387536617576],
              [-70.694515831501164, -33.440639587544567],
              [-70.689329403371943, -33.440639587544567],
              [-70.686736189307354, -33.444387536617576],
              [-70.689329403371943, -33.448135323766053],
              [-70.694515831501164, -33.448135323766053],
              [-70.697109045565767, -33.444387536617576],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1084,
        left: -7849181.5626421655,
        bottom: -3953938.7276647561,
        right: -7848026.8615654064,
        top: -3952938.7271985058,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.436891476556099],
              [-70.694515831501164, -33.433143203661182],
              [-70.689329403371943, -33.433143203661182],
              [-70.686736189307354, -33.436891476556099],
              [-70.689329403371943, -33.440639587544567],
              [-70.694515831501164, -33.440639587544567],
              [-70.697109045565767, -33.436891476556099],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1085,
        left: -7849181.5626421655,
        bottom: -3952938.7271985058,
        right: -7848026.8615654064,
        top: -3951938.7267322554,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.429394768868896],
              [-70.694515831501164, -33.425646172188266],
              [-70.689329403371943, -33.425646172188266],
              [-70.686736189307354, -33.429394768868896],
              [-70.689329403371943, -33.433143203661182],
              [-70.694515831501164, -33.433143203661182],
              [-70.697109045565767, -33.429394768868896],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1086,
        left: -7849181.5626421655,
        bottom: -3951938.7267322554,
        right: -7848026.8615654064,
        top: -3950938.7262660051,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.421897413628365],
              [-70.694515831501164, -33.418148493198238],
              [-70.689329403371943, -33.418148493198238],
              [-70.686736189307354, -33.421897413628365],
              [-70.689329403371943, -33.425646172188266],
              [-70.694515831501164, -33.425646172188266],
              [-70.697109045565767, -33.421897413628365],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1087,
        left: -7849181.5626421655,
        bottom: -3950938.7262660051,
        right: -7848026.8615654064,
        top: -3949938.7257997552,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.414399410906967],
              [-70.694515831501164, -33.410650166763581],
              [-70.689329403371943, -33.410650166763581],
              [-70.686736189307354, -33.414399410906967],
              [-70.689329403371943, -33.418148493198238],
              [-70.694515831501164, -33.418148493198238],
              [-70.697109045565767, -33.414399410906967],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1088,
        left: -7849181.5626421655,
        bottom: -3949938.7257997552,
        right: -7848026.8615654064,
        top: -3948938.7253335048,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.406900760777191],
              [-70.694515831501164, -33.403151192956834],
              [-70.689329403371943, -33.403151192956834],
              [-70.686736189307354, -33.406900760777191],
              [-70.689329403371943, -33.410650166763581],
              [-70.694515831501164, -33.410650166763581],
              [-70.697109045565767, -33.406900760777191],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1089,
        left: -7849181.5626421655,
        bottom: -3948938.7253335048,
        right: -7848026.8615654064,
        top: -3947938.724867254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.399401463311598],
              [-70.694515831501164, -33.39565157185055],
              [-70.689329403371943, -33.39565157185055],
              [-70.686736189307354, -33.399401463311598],
              [-70.689329403371943, -33.403151192956834],
              [-70.694515831501164, -33.403151192956834],
              [-70.697109045565767, -33.399401463311598],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1090,
        left: -7849181.5626421655,
        bottom: -3947938.724867254,
        right: -7848026.8615654064,
        top: -3946938.7244010041,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.391901518582785],
              [-70.694515831501164, -33.388151303517375],
              [-70.689329403371943, -33.388151303517375],
              [-70.686736189307354, -33.391901518582785],
              [-70.689329403371943, -33.39565157185055],
              [-70.694515831501164, -33.39565157185055],
              [-70.697109045565767, -33.391901518582785],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1091,
        left: -7849181.5626421655,
        bottom: -3946938.7244010041,
        right: -7848026.8615654064,
        top: -3945938.7239347543,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.384400926663417],
              [-70.694515831501164, -33.380650388029984],
              [-70.689329403371943, -33.380650388029984],
              [-70.686736189307354, -33.384400926663417],
              [-70.689329403371943, -33.388151303517375],
              [-70.694515831501164, -33.388151303517375],
              [-70.697109045565767, -33.384400926663417],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1092,
        left: -7849181.5626421655,
        bottom: -3945938.7239347543,
        right: -7848026.8615654064,
        top: -3944938.7234685034,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.376899687626185],
              [-70.694515831501164, -33.373148825461101],
              [-70.689329403371943, -33.373148825461101],
              [-70.686736189307354, -33.376899687626185],
              [-70.689329403371943, -33.380650388029984],
              [-70.694515831501164, -33.380650388029984],
              [-70.697109045565767, -33.376899687626185],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1093,
        left: -7849181.5626421655,
        bottom: -3944938.7234685034,
        right: -7848026.8615654064,
        top: -3943938.7230022531,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.369397801543847],
              [-70.694515831501164, -33.365646615883513],
              [-70.689329403371943, -33.365646615883513],
              [-70.686736189307354, -33.369397801543847],
              [-70.689329403371943, -33.373148825461101],
              [-70.694515831501164, -33.373148825461101],
              [-70.697109045565767, -33.369397801543847],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1094,
        left: -7849181.5626421655,
        bottom: -3943938.7230022531,
        right: -7848026.8615654064,
        top: -3942938.7225360028,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.361895268489214],
              [-70.694515831501164, -33.358143759370044],
              [-70.689329403371943, -33.358143759370044],
              [-70.686736189307354, -33.361895268489214],
              [-70.689329403371943, -33.365646615883513],
              [-70.694515831501164, -33.365646615883513],
              [-70.697109045565767, -33.361895268489214],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1095,
        left: -7849181.5626421655,
        bottom: -3942938.7225360028,
        right: -7848026.8615654064,
        top: -3941938.7220697524,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.354392088535135],
              [-70.694515831501164, -33.350640255993589],
              [-70.689329403371943, -33.350640255993589],
              [-70.686736189307354, -33.354392088535135],
              [-70.689329403371943, -33.358143759370044],
              [-70.694515831501164, -33.358143759370044],
              [-70.697109045565767, -33.354392088535135],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1096,
        left: -7849181.5626421655,
        bottom: -3941938.7220697524,
        right: -7848026.8615654064,
        top: -3940938.7216035021,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.346888261754529],
              [-70.694515831501164, -33.343136105827064],
              [-70.689329403371943, -33.343136105827064],
              [-70.686736189307354, -33.346888261754529],
              [-70.689329403371943, -33.350640255993589],
              [-70.694515831501164, -33.350640255993589],
              [-70.697109045565767, -33.346888261754529],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1097,
        left: -7849181.5626421655,
        bottom: -3940938.7216035021,
        right: -7848026.8615654064,
        top: -3939938.7211372517,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.339383788220331],
              [-70.694515831501164, -33.335631308943462],
              [-70.689329403371943, -33.335631308943462],
              [-70.686736189307354, -33.339383788220331],
              [-70.689329403371943, -33.343136105827064],
              [-70.694515831501164, -33.343136105827064],
              [-70.697109045565767, -33.339383788220331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1098,
        left: -7849181.5626421655,
        bottom: -3939938.7211372517,
        right: -7848026.8615654064,
        top: -3938938.7206710014,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.331878668005565],
              [-70.694515831501164, -33.328125865415807],
              [-70.689329403371943, -33.328125865415807],
              [-70.686736189307354, -33.331878668005565],
              [-70.689329403371943, -33.335631308943462],
              [-70.694515831501164, -33.335631308943462],
              [-70.697109045565767, -33.331878668005565],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1099,
        left: -7849181.5626421655,
        bottom: -3938938.7206710014,
        right: -7848026.8615654064,
        top: -3937938.720204751,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.324372901183303],
              [-70.694515831501164, -33.320619775317184],
              [-70.689329403371943, -33.320619775317184],
              [-70.686736189307354, -33.324372901183303],
              [-70.689329403371943, -33.328125865415807],
              [-70.694515831501164, -33.328125865415807],
              [-70.697109045565767, -33.324372901183303],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1100,
        left: -7849181.5626421655,
        bottom: -3937938.720204751,
        right: -7848026.8615654064,
        top: -3936938.7197385007,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.31686648782663],
              [-70.694515831501164, -33.313113038720743],
              [-70.689329403371943, -33.313113038720743],
              [-70.686736189307354, -33.31686648782663],
              [-70.689329403371943, -33.320619775317184],
              [-70.694515831501164, -33.320619775317184],
              [-70.697109045565767, -33.31686648782663],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1101,
        left: -7849181.5626421655,
        bottom: -3936938.7197385007,
        right: -7848026.8615654064,
        top: -3935938.7192722503,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.309359428008712],
              [-70.694515831501164, -33.305605655699658],
              [-70.689329403371943, -33.305605655699658],
              [-70.686736189307354, -33.309359428008712],
              [-70.689329403371943, -33.313113038720743],
              [-70.694515831501164, -33.313113038720743],
              [-70.697109045565767, -33.309359428008712],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1102,
        left: -7849181.5626421655,
        bottom: -3935938.7192722503,
        right: -7848026.8615654064,
        top: -3934938.718806,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.301851721802763],
              [-70.694515831501164, -33.298097626327156],
              [-70.689329403371943, -33.298097626327156],
              [-70.686736189307354, -33.301851721802763],
              [-70.689329403371943, -33.305605655699658],
              [-70.694515831501164, -33.305605655699658],
              [-70.697109045565767, -33.301851721802763],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1103,
        left: -7849181.5626421655,
        bottom: -3934938.718806,
        right: -7848026.8615654064,
        top: -3933938.7183397496,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.294343369282039],
              [-70.694515831501164, -33.29058895067655],
              [-70.689329403371943, -33.29058895067655],
              [-70.686736189307354, -33.294343369282039],
              [-70.689329403371943, -33.298097626327156],
              [-70.694515831501164, -33.298097626327156],
              [-70.697109045565767, -33.294343369282039],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1104,
        left: -7849181.5626421655,
        bottom: -3933938.7183397496,
        right: -7848026.8615654064,
        top: -3932938.7178734988,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.697109045565767, -33.286834370519863],
              [-70.694515831501164, -33.283079628821156],
              [-70.689329403371943, -33.283079628821156],
              [-70.686736189307354, -33.286834370519863],
              [-70.689329403371943, -33.29058895067655],
              [-70.694515831501164, -33.29058895067655],
              [-70.697109045565767, -33.286834370519863],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1105,
        left: -7848315.5368345967,
        bottom: -3980438.7400203901,
        right: -7847160.8357578376,
        top: -3979438.7395541398,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.635318028521546],
              [-70.686736189307354, -33.631578349057953],
              [-70.681549761178132, -33.631578349057953],
              [-70.678956547113529, -33.635318028521546],
              [-70.681549761178132, -33.639057545603663],
              [-70.686736189307354, -33.639057545603663],
              [-70.689329403371943, -33.635318028521546],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1106,
        left: -7848315.5368345967,
        bottom: -3979438.7395541398,
        right: -7847160.8357578376,
        top: -3978438.7390878894,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.627838507221767],
              [-70.686736189307354, -33.624098503021855],
              [-70.681549761178132, -33.624098503021855],
              [-70.678956547113529, -33.627838507221767],
              [-70.681549761178132, -33.631578349057953],
              [-70.686736189307354, -33.631578349057953],
              [-70.689329403371943, -33.627838507221767],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1107,
        left: -7848315.5368345967,
        bottom: -3978438.7390878894,
        right: -7847160.8357578376,
        top: -3977438.7386216391,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.620358336467127],
              [-70.686736189307354, -33.616618007566458],
              [-70.681549761178132, -33.616618007566458],
              [-70.678956547113529, -33.620358336467127],
              [-70.681549761178132, -33.624098503021855],
              [-70.686736189307354, -33.624098503021855],
              [-70.689329403371943, -33.620358336467127],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1108,
        left: -7848315.5368345967,
        bottom: -3977438.7386216391,
        right: -7847160.8357578376,
        top: -3976438.7381553887,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.612877516328737],
              [-70.686736189307354, -33.609136862762881],
              [-70.681549761178132, -33.609136862762881],
              [-70.678956547113529, -33.612877516328737],
              [-70.681549761178132, -33.616618007566458],
              [-70.686736189307354, -33.616618007566458],
              [-70.689329403371943, -33.612877516328737],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1109,
        left: -7848315.5368345967,
        bottom: -3976438.7381553887,
        right: -7847160.8357578376,
        top: -3975438.7376891384,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.605396046877765],
              [-70.686736189307354, -33.601655068682327],
              [-70.681549761178132, -33.601655068682327],
              [-70.678956547113529, -33.605396046877765],
              [-70.681549761178132, -33.609136862762881],
              [-70.686736189307354, -33.609136862762881],
              [-70.689329403371943, -33.605396046877765],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1110,
        left: -7848315.5368345967,
        bottom: -3975438.7376891384,
        right: -7847160.8357578376,
        top: -3974438.7372228876,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.597913928185434],
              [-70.686736189307354, -33.594172625396013],
              [-70.681549761178132, -33.594172625396013],
              [-70.678956547113529, -33.597913928185434],
              [-70.681549761178132, -33.601655068682327],
              [-70.686736189307354, -33.601655068682327],
              [-70.689329403371943, -33.597913928185434],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1111,
        left: -7848315.5368345967,
        bottom: -3974438.7372228876,
        right: -7847160.8357578376,
        top: -3973438.7367566377,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.590431160322979],
              [-70.686736189307354, -33.586689532975264],
              [-70.681549761178132, -33.586689532975264],
              [-70.678956547113529, -33.590431160322979],
              [-70.681549761178132, -33.594172625396013],
              [-70.686736189307354, -33.594172625396013],
              [-70.689329403371943, -33.590431160322979],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1112,
        left: -7848315.5368345967,
        bottom: -3973438.7367566377,
        right: -7847160.8357578376,
        top: -3972438.7362903878,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.58294774336175],
              [-70.686736189307354, -33.579205791491383],
              [-70.681549761178132, -33.579205791491383],
              [-70.678956547113529, -33.58294774336175],
              [-70.681549761178132, -33.586689532975264],
              [-70.686736189307354, -33.586689532975264],
              [-70.689329403371943, -33.58294774336175],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1113,
        left: -7848315.5368345967,
        bottom: -3972438.7362903878,
        right: -7847160.8357578376,
        top: -3971438.735824137,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.575463677373087],
              [-70.686736189307354, -33.571721401015779],
              [-70.681549761178132, -33.571721401015779],
              [-70.678956547113529, -33.575463677373087],
              [-70.681549761178132, -33.579205791491383],
              [-70.686736189307354, -33.579205791491383],
              [-70.689329403371943, -33.575463677373087],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1114,
        left: -7848315.5368345967,
        bottom: -3971438.735824137,
        right: -7847160.8357578376,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.567978962428406],
              [-70.686736189307354, -33.564236361619891],
              [-70.681549761178132, -33.564236361619891],
              [-70.678956547113529, -33.567978962428406],
              [-70.681549761178132, -33.571721401015779],
              [-70.686736189307354, -33.571721401015779],
              [-70.689329403371943, -33.567978962428406],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1115,
        left: -7848315.5368345967,
        bottom: -3970438.7353578866,
        right: -7847160.8357578376,
        top: -3969438.7348916363,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.56049359859918],
              [-70.686736189307354, -33.556750673375213],
              [-70.681549761178132, -33.556750673375213],
              [-70.678956547113529, -33.56049359859918],
              [-70.681549761178132, -33.564236361619891],
              [-70.686736189307354, -33.564236361619891],
              [-70.689329403371943, -33.56049359859918],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1116,
        left: -7848315.5368345967,
        bottom: -3969438.7348916363,
        right: -7847160.8357578376,
        top: -3968438.7344253859,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.553007585956919],
              [-70.686736189307354, -33.549264336353268],
              [-70.681549761178132, -33.549264336353268],
              [-70.678956547113529, -33.553007585956919],
              [-70.681549761178132, -33.556750673375213],
              [-70.686736189307354, -33.556750673375213],
              [-70.689329403371943, -33.553007585956919],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1117,
        left: -7848315.5368345967,
        bottom: -3968438.7344253859,
        right: -7847160.8357578376,
        top: -3967438.7339591356,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.545520924573204],
              [-70.686736189307354, -33.541777350625665],
              [-70.681549761178132, -33.541777350625665],
              [-70.678956547113529, -33.545520924573204],
              [-70.681549761178132, -33.549264336353268],
              [-70.686736189307354, -33.549264336353268],
              [-70.689329403371943, -33.545520924573204],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1118,
        left: -7848315.5368345967,
        bottom: -3967438.7339591356,
        right: -7847160.8357578376,
        top: -3966438.7334928853,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.538033614519627],
              [-70.686736189307354, -33.534289716264041],
              [-70.681549761178132, -33.534289716264041],
              [-70.678956547113529, -33.538033614519627],
              [-70.681549761178132, -33.541777350625665],
              [-70.686736189307354, -33.541777350625665],
              [-70.689329403371943, -33.538033614519627],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1119,
        left: -7848315.5368345967,
        bottom: -3966438.7334928853,
        right: -7847160.8357578376,
        top: -3965438.7330266349,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.530545655867876],
              [-70.686736189307354, -33.52680143334009],
              [-70.681549761178132, -33.52680143334009],
              [-70.678956547113529, -33.530545655867876],
              [-70.681549761178132, -33.534289716264041],
              [-70.686736189307354, -33.534289716264041],
              [-70.689329403371943, -33.530545655867876],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1120,
        left: -7848315.5368345967,
        bottom: -3965438.7330266349,
        right: -7847160.8357578376,
        top: -3964438.7325603846,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.523057048689658],
              [-70.686736189307354, -33.519312501925548],
              [-70.681549761178132, -33.519312501925548],
              [-70.678956547113529, -33.523057048689658],
              [-70.681549761178132, -33.52680143334009],
              [-70.686736189307354, -33.52680143334009],
              [-70.689329403371943, -33.523057048689658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1121,
        left: -7848315.5368345967,
        bottom: -3964438.7325603846,
        right: -7847160.8357578376,
        top: -3963438.7320941342,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.515567793056739],
              [-70.686736189307354, -33.511822922092215],
              [-70.681549761178132, -33.511822922092215],
              [-70.678956547113529, -33.515567793056739],
              [-70.681549761178132, -33.519312501925548],
              [-70.686736189307354, -33.519312501925548],
              [-70.689329403371943, -33.515567793056739],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1122,
        left: -7848315.5368345967,
        bottom: -3963438.7320941342,
        right: -7847160.8357578376,
        top: -3962438.7316278839,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.508077889040948],
              [-70.686736189307354, -33.504332693911934],
              [-70.681549761178132, -33.504332693911934],
              [-70.678956547113529, -33.508077889040948],
              [-70.681549761178132, -33.511822922092215],
              [-70.686736189307354, -33.511822922092215],
              [-70.689329403371943, -33.508077889040948],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1123,
        left: -7848315.5368345967,
        bottom: -3962438.7316278839,
        right: -7847160.8357578376,
        top: -3961438.7311616335,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.500587336714155],
              [-70.686736189307354, -33.496841817456605],
              [-70.681549761178132, -33.496841817456605],
              [-70.678956547113529, -33.500587336714155],
              [-70.681549761178132, -33.504332693911934],
              [-70.686736189307354, -33.504332693911934],
              [-70.689329403371943, -33.500587336714155],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1124,
        left: -7848315.5368345967,
        bottom: -3961438.7311616335,
        right: -7847160.8357578376,
        top: -3960438.7306953832,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.493096136148274],
              [-70.686736189307354, -33.489350292798164],
              [-70.681549761178132, -33.489350292798164],
              [-70.678956547113529, -33.493096136148274],
              [-70.681549761178132, -33.496841817456605],
              [-70.686736189307354, -33.496841817456605],
              [-70.689329403371943, -33.493096136148274],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1125,
        left: -7848315.5368345967,
        bottom: -3960438.7306953832,
        right: -7847160.8357578376,
        top: -3959438.7302291333,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.485604287415278],
              [-70.686736189307354, -33.481858120008617],
              [-70.681549761178132, -33.481858120008617],
              [-70.678956547113529, -33.485604287415278],
              [-70.681549761178132, -33.489350292798164],
              [-70.686736189307354, -33.489350292798164],
              [-70.689329403371943, -33.485604287415278],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1126,
        left: -7848315.5368345967,
        bottom: -3959438.7302291333,
        right: -7847160.8357578376,
        top: -3958438.7297628829,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.478111790587178],
              [-70.686736189307354, -33.474365299160006],
              [-70.681549761178132, -33.474365299160006],
              [-70.678956547113529, -33.478111790587178],
              [-70.681549761178132, -33.481858120008617],
              [-70.686736189307354, -33.481858120008617],
              [-70.689329403371943, -33.478111790587178],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1127,
        left: -7848315.5368345967,
        bottom: -3958438.7297628829,
        right: -7847160.8357578376,
        top: -3957438.7292966326,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.47061864573606],
              [-70.686736189307354, -33.466871830324415],
              [-70.681549761178132, -33.466871830324415],
              [-70.678956547113529, -33.47061864573606],
              [-70.681549761178132, -33.474365299160006],
              [-70.686736189307354, -33.474365299160006],
              [-70.689329403371943, -33.47061864573606],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1128,
        left: -7848315.5368345967,
        bottom: -3957438.7292966326,
        right: -7847160.8357578376,
        top: -3956438.7288303822,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.463124852934051],
              [-70.686736189307354, -33.459377713574014],
              [-70.681549761178132, -33.459377713574014],
              [-70.678956547113529, -33.463124852934051],
              [-70.681549761178132, -33.466871830324415],
              [-70.686736189307354, -33.466871830324415],
              [-70.689329403371943, -33.463124852934051],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1129,
        left: -7848315.5368345967,
        bottom: -3956438.7288303822,
        right: -7847160.8357578376,
        top: -3955438.7283641323,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.455630412253299],
              [-70.686736189307354, -33.45188294898098],
              [-70.681549761178132, -33.45188294898098],
              [-70.678956547113529, -33.455630412253299],
              [-70.681549761178132, -33.459377713574014],
              [-70.686736189307354, -33.459377713574014],
              [-70.689329403371943, -33.455630412253299],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1130,
        left: -7848315.5368345967,
        bottom: -3955438.7283641323,
        right: -7847160.8357578376,
        top: -3954438.7278978815,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.448135323766053],
              [-70.686736189307354, -33.444387536617576],
              [-70.681549761178132, -33.444387536617576],
              [-70.678956547113529, -33.448135323766053],
              [-70.681549761178132, -33.45188294898098],
              [-70.686736189307354, -33.45188294898098],
              [-70.689329403371943, -33.448135323766053],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1131,
        left: -7848315.5368345967,
        bottom: -3954438.7278978815,
        right: -7847160.8357578376,
        top: -3953438.7274316312,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.440639587544567],
              [-70.686736189307354, -33.436891476556099],
              [-70.681549761178132, -33.436891476556099],
              [-70.678956547113529, -33.440639587544567],
              [-70.681549761178132, -33.444387536617576],
              [-70.686736189307354, -33.444387536617576],
              [-70.689329403371943, -33.440639587544567],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1132,
        left: -7848315.5368345967,
        bottom: -3953438.7274316312,
        right: -7847160.8357578376,
        top: -3952438.7269653808,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.433143203661182],
              [-70.686736189307354, -33.429394768868896],
              [-70.681549761178132, -33.429394768868896],
              [-70.678956547113529, -33.433143203661182],
              [-70.681549761178132, -33.436891476556099],
              [-70.686736189307354, -33.436891476556099],
              [-70.689329403371943, -33.433143203661182],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1133,
        left: -7848315.5368345967,
        bottom: -3952438.7269653808,
        right: -7847160.8357578376,
        top: -3951438.7264991305,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.425646172188266],
              [-70.686736189307354, -33.421897413628365],
              [-70.681549761178132, -33.421897413628365],
              [-70.678956547113529, -33.425646172188266],
              [-70.681549761178132, -33.429394768868896],
              [-70.686736189307354, -33.429394768868896],
              [-70.689329403371943, -33.425646172188266],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1134,
        left: -7848315.5368345967,
        bottom: -3951438.7264991305,
        right: -7847160.8357578376,
        top: -3950438.7260328801,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.418148493198238],
              [-70.686736189307354, -33.414399410906967],
              [-70.681549761178132, -33.414399410906967],
              [-70.678956547113529, -33.418148493198238],
              [-70.681549761178132, -33.421897413628365],
              [-70.686736189307354, -33.421897413628365],
              [-70.689329403371943, -33.418148493198238],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1135,
        left: -7848315.5368345967,
        bottom: -3950438.7260328801,
        right: -7847160.8357578376,
        top: -3949438.7255666298,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.410650166763581],
              [-70.686736189307354, -33.406900760777191],
              [-70.681549761178132, -33.406900760777191],
              [-70.678956547113529, -33.410650166763581],
              [-70.681549761178132, -33.414399410906967],
              [-70.686736189307354, -33.414399410906967],
              [-70.689329403371943, -33.410650166763581],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1136,
        left: -7848315.5368345967,
        bottom: -3949438.7255666298,
        right: -7847160.8357578376,
        top: -3948438.7251003794,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.403151192956834],
              [-70.686736189307354, -33.399401463311598],
              [-70.681549761178132, -33.399401463311598],
              [-70.678956547113529, -33.403151192956834],
              [-70.681549761178132, -33.406900760777191],
              [-70.686736189307354, -33.406900760777191],
              [-70.689329403371943, -33.403151192956834],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1137,
        left: -7848315.5368345967,
        bottom: -3948438.7251003794,
        right: -7847160.8357578376,
        top: -3947438.7246341291,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.39565157185055],
              [-70.686736189307354, -33.391901518582785],
              [-70.681549761178132, -33.391901518582785],
              [-70.678956547113529, -33.39565157185055],
              [-70.681549761178132, -33.399401463311598],
              [-70.686736189307354, -33.399401463311598],
              [-70.689329403371943, -33.39565157185055],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1138,
        left: -7848315.5368345967,
        bottom: -3947438.7246341291,
        right: -7847160.8357578376,
        top: -3946438.7241678787,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.388151303517375],
              [-70.686736189307354, -33.384400926663417],
              [-70.681549761178132, -33.384400926663417],
              [-70.678956547113529, -33.388151303517375],
              [-70.681549761178132, -33.391901518582785],
              [-70.686736189307354, -33.391901518582785],
              [-70.689329403371943, -33.388151303517375],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1139,
        left: -7848315.5368345967,
        bottom: -3946438.7241678787,
        right: -7847160.8357578376,
        top: -3945438.7237016284,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.380650388029984],
              [-70.686736189307354, -33.376899687626185],
              [-70.681549761178132, -33.376899687626185],
              [-70.678956547113529, -33.380650388029984],
              [-70.681549761178132, -33.384400926663417],
              [-70.686736189307354, -33.384400926663417],
              [-70.689329403371943, -33.380650388029984],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1140,
        left: -7848315.5368345967,
        bottom: -3945438.7237016284,
        right: -7847160.8357578376,
        top: -3944438.723235378,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.373148825461101],
              [-70.686736189307354, -33.369397801543847],
              [-70.681549761178132, -33.369397801543847],
              [-70.678956547113529, -33.373148825461101],
              [-70.681549761178132, -33.376899687626185],
              [-70.686736189307354, -33.376899687626185],
              [-70.689329403371943, -33.373148825461101],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1141,
        left: -7848315.5368345967,
        bottom: -3944438.723235378,
        right: -7847160.8357578376,
        top: -3943438.7227691277,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.365646615883513],
              [-70.686736189307354, -33.361895268489214],
              [-70.681549761178132, -33.361895268489214],
              [-70.678956547113529, -33.365646615883513],
              [-70.681549761178132, -33.369397801543847],
              [-70.686736189307354, -33.369397801543847],
              [-70.689329403371943, -33.365646615883513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1142,
        left: -7848315.5368345967,
        bottom: -3943438.7227691277,
        right: -7847160.8357578376,
        top: -3942438.7223028769,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.358143759370044],
              [-70.686736189307354, -33.354392088535135],
              [-70.681549761178132, -33.354392088535135],
              [-70.678956547113529, -33.358143759370044],
              [-70.681549761178132, -33.361895268489214],
              [-70.686736189307354, -33.361895268489214],
              [-70.689329403371943, -33.358143759370044],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1143,
        left: -7848315.5368345967,
        bottom: -3942438.7223028769,
        right: -7847160.8357578376,
        top: -3941438.721836627,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.350640255993589],
              [-70.686736189307354, -33.346888261754529],
              [-70.681549761178132, -33.346888261754529],
              [-70.678956547113529, -33.350640255993589],
              [-70.681549761178132, -33.354392088535135],
              [-70.686736189307354, -33.354392088535135],
              [-70.689329403371943, -33.350640255993589],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1144,
        left: -7848315.5368345967,
        bottom: -3941438.721836627,
        right: -7847160.8357578376,
        top: -3940438.7213703771,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.343136105827064],
              [-70.686736189307354, -33.339383788220331],
              [-70.681549761178132, -33.339383788220331],
              [-70.678956547113529, -33.343136105827064],
              [-70.681549761178132, -33.346888261754529],
              [-70.686736189307354, -33.346888261754529],
              [-70.689329403371943, -33.343136105827064],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1145,
        left: -7848315.5368345967,
        bottom: -3940438.7213703771,
        right: -7847160.8357578376,
        top: -3939438.7209041263,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.335631308943462],
              [-70.686736189307354, -33.331878668005565],
              [-70.681549761178132, -33.331878668005565],
              [-70.678956547113529, -33.335631308943462],
              [-70.681549761178132, -33.339383788220331],
              [-70.686736189307354, -33.339383788220331],
              [-70.689329403371943, -33.335631308943462],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1146,
        left: -7848315.5368345967,
        bottom: -3939438.7209041263,
        right: -7847160.8357578376,
        top: -3938438.7204378759,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.328125865415807],
              [-70.686736189307354, -33.324372901183303],
              [-70.681549761178132, -33.324372901183303],
              [-70.678956547113529, -33.328125865415807],
              [-70.681549761178132, -33.331878668005565],
              [-70.686736189307354, -33.331878668005565],
              [-70.689329403371943, -33.328125865415807],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1147,
        left: -7848315.5368345967,
        bottom: -3938438.7204378759,
        right: -7847160.8357578376,
        top: -3937438.7199716261,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.320619775317184],
              [-70.686736189307354, -33.31686648782663],
              [-70.681549761178132, -33.31686648782663],
              [-70.678956547113529, -33.320619775317184],
              [-70.681549761178132, -33.324372901183303],
              [-70.686736189307354, -33.324372901183303],
              [-70.689329403371943, -33.320619775317184],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1148,
        left: -7848315.5368345967,
        bottom: -3937438.7199716261,
        right: -7847160.8357578376,
        top: -3936438.7195053762,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.313113038720743],
              [-70.686736189307354, -33.309359428008712],
              [-70.681549761178132, -33.309359428008712],
              [-70.678956547113529, -33.313113038720743],
              [-70.681549761178132, -33.31686648782663],
              [-70.686736189307354, -33.31686648782663],
              [-70.689329403371943, -33.313113038720743],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1149,
        left: -7848315.5368345967,
        bottom: -3936438.7195053762,
        right: -7847160.8357578376,
        top: -3935438.7190391254,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.305605655699658],
              [-70.686736189307354, -33.301851721802763],
              [-70.681549761178132, -33.301851721802763],
              [-70.678956547113529, -33.305605655699658],
              [-70.681549761178132, -33.309359428008712],
              [-70.686736189307354, -33.309359428008712],
              [-70.689329403371943, -33.305605655699658],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1150,
        left: -7848315.5368345967,
        bottom: -3935438.7190391254,
        right: -7847160.8357578376,
        top: -3934438.718572875,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.298097626327156],
              [-70.686736189307354, -33.294343369282039],
              [-70.681549761178132, -33.294343369282039],
              [-70.678956547113529, -33.298097626327156],
              [-70.681549761178132, -33.301851721802763],
              [-70.686736189307354, -33.301851721802763],
              [-70.689329403371943, -33.298097626327156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1151,
        left: -7848315.5368345967,
        bottom: -3934438.718572875,
        right: -7847160.8357578376,
        top: -3933438.7181066247,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.29058895067655],
              [-70.686736189307354, -33.286834370519863],
              [-70.681549761178132, -33.286834370519863],
              [-70.678956547113529, -33.29058895067655],
              [-70.681549761178132, -33.294343369282039],
              [-70.686736189307354, -33.294343369282039],
              [-70.689329403371943, -33.29058895067655],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1152,
        left: -7848315.5368345967,
        bottom: -3933438.7181066247,
        right: -7847160.8357578376,
        top: -3932438.7176403743,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.689329403371943, -33.283079628821156],
              [-70.686736189307354, -33.279324725589596],
              [-70.681549761178132, -33.279324725589596],
              [-70.678956547113529, -33.283079628821156],
              [-70.681549761178132, -33.286834370519863],
              [-70.686736189307354, -33.286834370519863],
              [-70.689329403371943, -33.283079628821156],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1153,
        left: -7867368.1046011159,
        bottom: -3971438.735824137,
        right: -7866213.4035243569,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.876002602302663, -33.635338454946307],
              [-70.873409388238059, -33.63159877636965],
              [-70.868222960108838, -33.63159877636965],
              [-70.865629746044235, -33.635338454946307],
              [-70.868222960108838, -33.639077971141432],
              [-70.873409388238059, -33.639077971141432],
              [-70.876002602302663, -33.635338454946307],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        fid: 1154,
        left: -7867368.1046011159,
        bottom: -3971438.735824137,
        right: -7866213.4035243569,
        top: -3970438.7353578866,
        layer: "capaspoligonos__temp1",
        path: "capaspoligonos__temp1.gpkg|layername=capaspoligonos__temp1",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-70.875987189124245, -33.627882333247321],
              [-70.873393975059642, -33.624142330950008],
              [-70.868207546930421, -33.624142330950008],
              [-70.865614332865832, -33.627882333247321],
              [-70.868207546930421, -33.631622173180808],
              [-70.873393975059642, -33.631622173180808],
              [-70.875987189124245, -33.627882333247321],
            ],
          ],
        ],
      },
    },
  ],
};
