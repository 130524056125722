import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { format } from "date-fns";
import { makeStyles } from "@mui/styles";
import { translateStatesfalabbela } from "../../utils/objects/translateStates";
import { pdf } from "@react-pdf/renderer";
import { generateBarcodeBase64 } from "../DocumentoPDFShippingLabelPrint/generateBarcodeBase64.js";
import { generateQRBase64 } from "../DocumentoPDFShippingLabelPrint/generateQRBase64.js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DocumentoPDFShippingLabelPrintBicci from "../DocumentoPDFShippingLabelPrint/DocumentoPDFShippingLabelPrintBicci.jsx";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});

const DataGridoOders = ({ dataOrdesRequets, UpdateOrderForAcctions }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [columns, setColum] = useState([]);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (dataOrdesRequets) {
      console.log(dataOrdesRequets);
      upDateTable(dataOrdesRequets);
    }
  }, [dataOrdesRequets]);

  const handlePrint = async (order) => {
    let orderSelect = dataOrdesRequets.filter((item) => item.id === order.id);

    const barcodeImage = await generateBarcodeBase64(orderSelect[0].id);

    const barcodeImageLpn = orderSelect[0].soc
      ? await generateBarcodeBase64(orderSelect[0].soc)
      : "";

    const QRImage = await generateQRBase64(orderSelect[0].id);

    console.log(barcodeImage);
    console.log(barcodeImageLpn);
    console.log(QRImage);

    const blob = await pdf(
      <DocumentoPDFShippingLabelPrintBicci
        order={orderSelect[0]}
        barcodeImage={barcodeImage}
        barcodeImageLpn={barcodeImageLpn}
        qrImage={QRImage}
      />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleAssingDriver = () => {
    console.log("handleAssingDriver");
  };

  const handleEdithNavigateRows = (dataRow) => {
    const url = `/dashboard/dispatch/edithDetails/F${dataRow.id}`;
    window.open(url, "_blank");
  };

  const handleNavigateRows = (dataRow) => {
    const url = `/dashboard/dispatch/details/F${dataRow.id}`;
    window.open(url, "_blank");
  };

  const upDateTable = () => {
    const renderPiking = (params) => {
      if (params.row.isPickedUp === true) {
        return <CheckCircleIcon sx={{ color: "#4FFF33" }}></CheckCircleIcon>;
      } else {
        return (
          <ReportGmailerrorredIcon
            sx={{ color: "#D80B0B" }}
          ></ReportGmailerrorredIcon>
        );
      }
    };

    const renderActionButtons = (params) => {
      if (params.row.id !== undefined && params.row.id !== "") {
        return (
          <Box
            sx={{
              display: "flex", // Arrange the buttons in a row
              flexDirection: "row",
              justifyContent: "space-between", // Ensure there's space between buttons
              gap: "8px", // Add some space between buttons
            }}
          >
            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Etiqueta"
              onClick={() => handlePrint(params.row)}
            >
              <QrCode2Icon />
            </IconButton>

            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="ver"
              onClick={() => handleNavigateRows(params.row)}
            >
              <VisibilityIcon />
            </IconButton>

            <IconButton
              sx={{
                width: "30px !important",
                color: "#1e2158 !important",
              }}
              aria-label="Editar"
              onClick={() => handleEdithNavigateRows(params.row)}
            >
              <ModeEditIcon />
            </IconButton>
          </Box>
        );
      }
    };
    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
      },
      {
        field: "placeName",
        headerName: "ORIGEN",
        width: 150,
      },
      {
        field: "driverName",
        headerName: "RIDER",
        width: 150,
      },
      {
        field: "createdAt",
        headerName: "CREADO",
        width: 200,
      },
      {
        field: "toAddress",
        headerName: "DESTINO",
        width: 200,
      },
      {
        field: "isPickedUp",
        headerName: "PICKING",
        width: 20,
        renderCell: renderPiking,
        sortable: true,
        filterable: true,
      },
      {
        field: "driverLicensePlate",
        headerName: "RUTA",
        width: 100,
      },
      {
        field: "status",
        headerName: "ESTADO",
        width: 100,
      },
      {
        field: "actions",
        headerName: "ACCIONES",
        width: 150,
        renderCell: renderActionButtons,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < dataOrdesRequets.length; i++) {
      rows.push({
        id: dataOrdesRequets[i].id,
        placeName: dataOrdesRequets[i].placeName,
        driverName: dataOrdesRequets[i].driverName,
        createdAt: format(
          dataOrdesRequets[i].createdAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        ),
        toAddress: `${dataOrdesRequets[i].address} ${dataOrdesRequets[i].commune2}`,
        isPickedUp: dataOrdesRequets[i].isPickedUp,
        driverLicensePlate: dataOrdesRequets[i].driverLicensePlate,
        status: translateStatesfalabbela[dataOrdesRequets[i].falabella_status],
      });
    }

    setRows(rows);
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={5}
      //   className={classes.root}
      rowsPerPage={[50, 100, 1000]}
      pagination
      slots={{
        toolbar: GridToolbar, // Incluye la barra de herramientas para opciones adicionales
      }}
      groupingModel={["driverName"]}
    ></DataGrid>
  );
};

export default DataGridoOders;
