import React, { useContext, useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import GuiasByCompany from "./GuiasByCompany";
import { Box, Button, Toolbar } from "@mui/material";
import { UserContext } from "../../../context/CurrentUserProvider";
import CreateOrderExpress from "../CreateOrderExpress/CreateOrderExpress";
import CreateOrderMasive from "../CreateOrderMasive/CreateOrderMasive";
import useGetOrdersDataByCompany from "../../../hooks/getOrdersDataContextByCompany";
import Moment from "moment";

const Guias = (props) => {
  const [selectorMode, setSelectorMode] = useState({
    express: false,
    masive: false,
    view: true,
  });

  const { userDataContext } = useContext(UserContext);

  let initDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  let endDateInitial = Moment(Date.now()).format("YYYY-MM-DD");
  const {
    mergedOrders,
    loadingContext,
    setInitDate,
    setEndDate,
    initDate,
    endDate,
  } = useGetOrdersDataByCompany(initDateInitial, endDateInitial);

  const userRoll = userDataContext.roll;
  const userdata = userDataContext.userData;

  const handleModeChange = (mode) => {
    setSelectorMode((prev) => ({
      express: mode === "express",
      masive: mode === "masive",
      view: mode === "view",
    }));
  };

  useEffect(() => {
    handleViewAll();
  }, [mergedOrders]);

  const handleViewAll = () => {
    function flattenData(data) {
      let flattenedArray = [];
      for (const key in data) {
        flattenedArray = flattenedArray.concat(data[key]);
      }
      return flattenedArray;
    }
    const combinedArray = flattenData(mergedOrders);
    let validarPendientes = combinedArray.filter(
      (item) => item.int_bicciStatus !== 8
    );
    console.log("validarPendientes:", validarPendientes);
  };

  return (
    <>
      {userRoll && userdata ? (
        <Box sx={{ backgroundColor: "#f8f8f8" }}>
          <Toolbar sx={{ height: "50px" }}>
            <Button onClick={() => handleModeChange("view")}>Mis envíos</Button>
            <Button onClick={() => handleModeChange("express")}>
              Crear Envío Express
            </Button>
            <Button onClick={() => handleModeChange("masive")}>
              Subir archivo
            </Button>
          </Toolbar>
          {selectorMode.view ? (
            <GuiasByCompany
              handleModeChange={handleModeChange}
              userdata={userdata}
              firebaseUser={props.firebaseUser}
              usuarioStorage={props.usuarioStorage}
              userDataContext={userDataContext}
              mergedOrders={mergedOrders}
              loadingContext={loadingContext}
              setInitDate={setInitDate}
              setEndDate={setEndDate}
              initDate={initDate}
              endDate={endDate}
            ></GuiasByCompany>
          ) : null}
          {selectorMode.express ? (
            <Box sx={{ width: "100%" }}>
              <CreateOrderExpress
                userDataContext={userDataContext}
              ></CreateOrderExpress>
            </Box>
          ) : null}
          {selectorMode.masive ? (
            <Box sx={{ width: "100%" }}>
              <CreateOrderMasive
                companyData={userDataContext.companyData}
                userDataContext={userDataContext}
                prefix={userDataContext.companyData.prefix}
              ></CreateOrderMasive>
            </Box>
          ) : null}
        </Box>
      ) : (
        <LinearProgress />
      )}
    </>
  );
};

export default Guias;
