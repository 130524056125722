import PropTypes from "prop-types";
import { useCallback } from "react";
// @mui
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
// components
import Iconify from "../../../components/Iconify/Iconify";
import CustomPopover, { usePopover } from "../../../components/CustomPopover";

// ----------------------------------------------------------------------

export default function UserDriversTableToolbar({
  filters,
  onFilters,
  //
  roleOptions,
  serviceOptions,
  origen,
  onlineOptions,
  activeOptions,
  vehicleOptions,
  online,
  active,
}) {
  // console.log(onFilters);

  const popover = usePopover();

  const handleFilterName = useCallback(
    (event) => {
      onFilters("name", event.target.value);
    },
    [onFilters]
  );

  const handleFilterRole = useCallback(
    (event) => {
      onFilters(
        "role",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );
  const handleFilteronline = useCallback(
    (event) => {
      onFilters(
        "online",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );
  const handleFilterActive = useCallback(
    (event) => {
      onFilters(
        "active",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterVehicle = useCallback(
    (event) => {
      onFilters(
        "vehicle",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterService = useCallback(
    (event) => {
      onFilters(
        "service",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: "flex-end", md: "center" }}
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {roleOptions && roleOptions.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Role</InputLabel>

            <Select
              multiple
              value={filters.role}
              onChange={handleFilterRole}
              input={<OutlinedInput label="Role" />}
              renderValue={(selected) =>
                selected.map((value) => value).join(", ")
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {roleOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.role.includes(option)}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {onlineOptions && onlineOptions.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>OnLine</InputLabel>

            <Select
              multiple
              value={filters.online}
              onChange={handleFilteronline}
              input={<OutlinedInput label="Online" />}
              renderValue={(selected) =>
                selected.map((value) => value).join(", ")
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {onlineOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.online.includes(option)}
                  />
                  {option.toString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {activeOptions && activeOptions.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Activo</InputLabel>

            <Select
              multiple
              value={filters.active}
              onChange={handleFilterActive}
              input={<OutlinedInput label="Activo" />}
              renderValue={(selected) =>
                selected.map((value) => value).join(", ")
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {activeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.active.includes(option)}
                  />
                  {option.toString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {vehicleOptions && vehicleOptions.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Vehículos</InputLabel>

            <Select
              multiple
              value={filters.vehicle}
              onChange={handleFilterVehicle}
              input={<OutlinedInput label="Vehículos" />}
              renderValue={(selected) =>
                selected.map((value) => value).join(", ")
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {vehicleOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.vehicle.includes(option)}
                  />
                  {option.toString()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {serviceOptions && serviceOptions.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>Service</InputLabel>

            <Select
              multiple
              value={filters.service}
              onChange={handleFilterService}
              input={<OutlinedInput label="Sevice" />}
              renderValue={(selected) =>
                selected.map((value) => value).join(", ")
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {serviceOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.service.includes(option)}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {origen && origen.length > 0 && (
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <InputLabel>origen</InputLabel>

            <Select
              multiple
              value={filters.role}
              onChange={handleFilterRole}
              input={<OutlinedInput label="origen" />}
              renderValue={(selected) =>
                selected.map((value) => value).join(", ")
              }
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 240 },
                },
              }}
            >
              {origen.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={filters.origen.includes(option)}
                  />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}

UserDriversTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  roleOptions: PropTypes.array,
  serviceOptions: PropTypes.array,
  origen: PropTypes.array,
  onlineOptions: PropTypes.array,
  activeOptions: PropTypes.array,
  vehicleOptions: PropTypes.array,
  online: PropTypes.array,
  active: PropTypes.array,
};
