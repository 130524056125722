import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  collection,
  getDoc,
  query,
  where,
  getDocs,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";
import { MapVectoriales } from "../../utils/Polygons/VectorialSatelite";

const ReusableFormPiking = ({
  open,
  handleClose,
  handleAddItem,
  setOpen,
  companyData,
  prefix,
  dataOrigin,
  resultsErros,
  setResultsItems,
  setResultsErros,
  setResultsCant,
  setLoading,
  setDisplay,
}) => {
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);
  const [listId, setListId] = useState("");
  const collRegidterRef = collection(
    db,
    process.env.REACT_APP_COL_PICKER_REGISTER
  );
  const geoJson = MapVectoriales;

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0].replace(/-/g, "");
    setListId(today.toString());
  }, []);

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]);
  };

  const formFieldsEdit = [
    {
      variable: "BICCIID",
      label: "BICCIID",
      type: "string",
      require: true,
    },
  ];

  const handleFileSave = async () => {
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const isValidData = (data, formFields) => {
      for (let item of data) {
        for (let field of formFields) {
          if (field.require === true) {
            if (
              field.variable !== "id" &&
              (!item.hasOwnProperty(field.label) || item[field.label] === "")
            ) {
              setErroMensaje(
                `Falta un valor para la columna ${field.label} o está vacío en uno de los registros.`
              );
              return false;
            }
          }
        }
      }
      return true;
    };

    if (isValidData(jsonData, formFieldsEdit)) {
      handleSave(jsonData);
      handleClose();
    } else {
      console.log("La validación de los datos falló.");
    }
  };

  const handleSave = async (formData) => {
    const handleFilePiking = async (jsonData) => {
      try {
        if (jsonData && jsonData.length > 0) {
          setDisplay(true);
          setLoading(true);
          for (let n = 0; n < jsonData.length; n++) {
            const element = jsonData[n];
            let companyIdOrder = "F" + element.BICCIID.toString();
            let createPickingRegites = element.CREATE;
            let pickedUp = true;

            const orderRef = doc(
              db,
              process.env.REACT_APP_COL_ORDERS,
              companyIdOrder
            );

            const dataallOrdersfind5 = collection(
              db,
              process.env.REACT_APP_COL_ORDERS
            );

            function isPointInPolygon(point, polygon) {
              var x = point[0],
                y = point[1];
              var inside = false;
              for (
                var i = 0, j = polygon.length - 1;
                i < polygon.length;
                j = i++
              ) {
                var xi = polygon[i][0],
                  yi = polygon[i][1];
                var xj = polygon[j][0],
                  yj = polygon[j][1];

                var intersect =
                  yi > y != yj > y &&
                  x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
                if (intersect) inside = !inside;
              }
              return inside;
            }

            function findPolygonForPoint(point, geoJson) {
              for (var feature of geoJson.features) {
                if (isPointInPolygon(point, feature.geometry.coordinates[0])) {
                  return feature.properties.Name;
                }
              }
              return null; // No contiene ningún polígono
            }

            const docSnapshot = await getDoc(orderRef);

            if (docSnapshot.exists()) {
              setResultsItems(element.BICCIID);
              await updateDoc(orderRef, { isPickedUp: pickedUp });

              let dataSearch = docSnapshot.data();

              if (createPickingRegites) {
                let updateToDoc = {
                  //   id: idWithF,
                  orderID: element.BICCIID,
                  sobrante: false,
                  listId: listId,
                  deleted: false,
                };
                updateTodayPicking(updateToDoc);
              }

              console.log("PIKKING actualizados con éxito.");
            } else {
              const q = query(
                dataallOrdersfind5,
                where("int_lpn", "==", element.BICCIID.toString())
              );
              const querySnapshot5 = await getDocs(q);
              var dataOrdes = querySnapshot5.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              if (dataOrdes.length > 0 && dataOrdes[0].key) {
                let companyKeyOrder = dataOrdes[0].key;
                const orderRefKey = doc(
                  db,
                  process.env.REACT_APP_COL_ORDERS,
                  companyKeyOrder
                );
                const docSnapshotKey = await getDoc(orderRefKey);
                if (docSnapshotKey.exists()) {
                  setResultsItems(element.BICCIID);
                  await updateDoc(orderRefKey, { isPickedUp: pickedUp });
                  if (createPickingRegites) {
                    let updateToDoc = {
                      //   id: idWithF,
                      orderID: element.BICCIID,
                      sobrante: false,
                      listId: listId,
                      deleted: false,
                      date: new Date(),
                    };
                    updateTodayPicking(updateToDoc);
                  }
                } else {
                  let errorMjs = resultsErros;
                  errorMjs.push(
                    `CONFIRMAR DATA PUEDE SER SOBRANTE: ${element.BICCIID}`
                  );
                  if (createPickingRegites) {
                    let updateToDoc = {
                      //   id: idWithF,
                      orderID: element.BICCIID,
                      sobrante: true,
                      listId: listId,
                      deleted: false,
                    };
                    updateTodayPicking(updateToDoc);
                  }
                  setResultsErros(errorMjs);
                }
              } else {
                let errorMjs = resultsErros;
                errorMjs.push(
                  `CONFIRMAR DATA PUEDE SER SOBRANTE: ${element.BICCIID}`
                );
                setResultsErros(errorMjs);
                if (createPickingRegites) {
                  let updateDoc = {
                    //   id: idWithF,
                    orderID: element.BICCIID,
                    sobrante: true,
                    listId: listId,
                    deleted: false,
                    date: new Date(),
                  };
                  updateTodayPicking(updateDoc);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error al actualizar PIKKING:", error);
      }
    };

    const updateTodayPicking = async (updateToDoc) => {
      try {
        const docRef = await addDoc(collRegidterRef, updateToDoc);
        await setDoc(
          docRef,
          {
            ...updateToDoc,
            id: docRef.id,
          },
          { merge: true }
        ); // Actualiza el documento con el ID
      } catch (error) {
        console.log(error);
      }
    };

    await handleFilePiking(formData);

    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/*, application/*",
    maxFiles: 1,
    maxSize: 5000000,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Subir documento de PIKING
      </DialogTitle>
      <DialogContent>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", p: 2 }}>
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta algunos archivos aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>LISTO PARA SUBIR: {file.name}</p>}
        <div>
          {erroMensaje ? (
            <Typography sx={{ m: 1, color: "red" }}>{erroMensaje}</Typography>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormPiking;
