import React, { useState } from "react";
import { doc, updateDoc, collection, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";
import { es } from "date-fns/esm/locale";

const ReusableFormImportOrdersByDataSDD = ({
  open,
  handleClose,
  handleAddItem,
  setOpen,
  companyData,
  prefix,
  dataOrigin,
}) => {
  const [erroMensaje, setErroMensaje] = useState(null);
  const [file, setFile] = useState(null);
  const [openDropzone, setOpenDropzone] = useState(false);

  const handleSaveDropzone = (files) => {
    setErroMensaje(null);
    setFile(files[0]);
    setOpenDropzone(false);
  };

  const handleSave = async (formData) => {
    const upsertDocument = async (docData) => {
      let order = {};
      order.key = prefix + docData.SOC;

      let time = ExcelDateToJSDate(docData.RANGOFECHAPACTADA);
      let timeTravelDate = ExcelDateToJSDate(docData.FECHAVIAJE);

      let toAddress = getToAdressDirection(
        docData.DIRECCION1,
        docData.DIRECCION2,
        docData.DIRECCION3,
        docData.COMUNA,
        docData.COMUNA2
      );
      order.dataOrigin = dataOrigin ? dataOrigin : "DATA";
      order.key = prefix + docData.SOC;
      order.orderID = prefix + docData.SOC.toString();
      order.id = docData.SOC.toString();
      order.company = companyData.companyName;
      order.companyId = companyData.companyID;
      order.commerce = companyData.companyName;
      order.int_warehouseCode = docData.BODEGACODIGO;
      order.int_warehouseName = docData.BODEGANOMBRE;
      order.int_placeName = docData.BODEGANOMBRE;
      order.int_travelDate = timeTravelDate.fechaMasUnoFormat;
      order.int_f12 = docData.F12;
      order.int_client_soc = docData.SOC;
      order.int_lpn = docData.LPN;
      order.int_serviceLevel = docData.SERVICELEVEL;
      order.int_serviceType = docData.TIPODELIVERY
        ? docData.TIPODELIVERY
        : "All";
      order.userPhone = docData.CLIENTETELEFONO ? docData.CLIENTETELEFONO : 0;
      order.clientNames = docData.CLIENTENOMBRES;
      order.clientRut = docData.CLIENTERUT;
      order.clientEmail = docData.CLIENTECORREO
        ? docData.CLIENTECORREO
        : "NO INFORMA";
      order.toAddress = toAddress.a1;
      order.direccion1 = docData.DIRECCION1;
      order.direccion2 = docData.DIRECCION2 ? docData.DIRECCION2 : "";
      order.direccion3 = docData.DIRECCION3 ? docData.DIRECCION3 : "";
      order.commune = docData.COMUNA;
      order.commune2 = docData.COMUNA2 ? docData.COMUNA2 : "";
      order.region = docData.COMUNA ? docData.COMUNA : "";
      order.serviceType = docData.SERVICELEVEL;
      order.deliveryDate = timeTravelDate.fechaMasUnoFormat;
      order.timeStamp = time.fechaMasUno;
      order.createdAt = new Date();
      order.description = docData.DESCRIPTION
        ? docData.DESCRIPTION
        : "NO INFORMA";
      order.zonePoligon = "";
      order.pickedUp = docData.RECOGIDO;
      order.productLength = docData.LARGO;
      order.productHeight = docData.ALTO;
      order.productWidth = docData.ANCHO;
      order.productWeight = docData.PESO;
      order.productName = docData.PRODUCTO ? docData.PRODUCTO : "NO INFORMA";
      order.productSku = docData.SKU ? docData.SKU : "NO INFORMA";
      order.productSize = docData.TAMANOPRODUCTO;
      order.productUnits = docData.UNIDADES ? docData.UNIDADES : 1;
      order.productValue = docData.VALOR;
      order.productVolume = docData.VOLUMEN;
      order.productMultiDelivery = docData.MULTIBULTO;
      order.transportOrderSize = docData.TAMANOPRODUCTO;
      order.routeEstimatedDate = time.fechaMasUnoFormat;
      order.routeStartDate = time.fechaMasUnoFormat;
      order.routeStartTime = docData.VENTANAINICIO;
      order.routeEndTime = docData.VENTANATERMINO;
      order.routeLocality = docData.COMUNA2;
      //console.log("CREACION DE ORDENES NUEVO METODO :", order.key);

      const collRef = collection(db, process.env.REACT_APP_COL_ORDERS);
      const docRef = doc(collRef, order.key);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, order);
        //console.log("Documento actualizado con éxito.", order);
      } else {
        await setDoc(docRef, order);
        //console.log("Documento creado con éxito.", order);
      }
    };
    if (Array.isArray(formData)) {
      // Si formData es un arreglo, procesa cada objeto individualmente
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
  };

  const handleOpenDropzone = () => {
    setErroMensaje(null);
    setOpenDropzone(true);
  };

  const onDrop = (acceptedFiles) => {
    setErroMensaje(null);
    setFile(acceptedFiles[0]);
  };

  const formFieldsEdit = [
    {
      variable: "int_serviceType",
      label: "TIPODELIVERY",
      type: "string",
      require: true,
    },
    { variable: "productHeight", label: "ALTO", type: "string", require: true },
    { variable: "productWidth", label: "ANCHO", type: "string", require: true },
    {
      variable: "int_warehouseCode",
      label: "BODEGACODIGO",
      type: "string",
      require: true,
    },
    {
      variable: "int_warehouseName",
      label: "BODEGANOMBRE",
      type: "boolean",
      require: true,
    },
    {
      variable: "clientEmail",
      label: "CLIENTECORREO",
      type: "string",
      require: true,
    },
    {
      variable: "clientNames",
      label: "CLIENTENOMBRES",
      type: "string",
      require: true,
    },
    {
      variable: "clientRut",
      label: "CLIENTERUT",
      type: "string",
      require: true,
    },
    {
      variable: "clientPhone",
      label: "CLIENTETELEFONO",
      type: "string",
      require: true,
    },
    { variable: "commune", label: "COMUNA", type: "string", require: true },
    { variable: "commune2", label: "COMUNA2", type: "string", require: true },
    {
      variable: "direccion1",
      label: "DIRECCION1",
      type: "string",
      require: true,
    },
    {
      variable: "direccion2",
      label: "DIRECCION2",
      type: "string",
      require: false,
    },
    {
      variable: "direccion3",
      label: "DIRECCION3",
      type: "string",
      require: false,
    },
    { variable: "int_f12", label: "F12", type: "string", require: false },
    {
      variable: "deliveryDate",
      label: "FECHAVIAJE",
      type: "string",
      require: true,
    },
    {
      variable: "productLength",
      label: "LARGO",
      type: "string",
      require: true,
    },
    { variable: "int_lpn", label: "LPN", type: "string", require: true },
    {
      variable: "productMultiDelivery",
      label: "MULTIBULTO",
      type: "string",
      require: false,
    },
    { variable: "productWeight", label: "PESO", type: "string", require: true },
    {
      variable: "int_rangeDate",
      label: "RANGOFECHAPACTADA",
      type: "string",
      require: true,
    },
    { variable: "pickedUp", label: "RECOGIDO", type: "string", require: true },
    {
      variable: "int_serviceLevel",
      label: "SERVICELEVEL",
      type: "string",
      require: true,
    },
    { variable: "int_soc", label: "SOC", type: "string", require: true },
    {
      variable: "int_productSize",
      label: "MULTIBULTO",
      type: "string",
      require: true,
    },
    { variable: "productValue", label: "VALOR", type: "string", require: true },
    {
      variable: "routeStartTime",
      label: "VENTANAINICIO",
      type: "string",
      require: true,
    },
    {
      variable: "routeEndTime",
      label: "VENTANATERMINO",
      type: "string",
      require: true,
    },
  ];

  const handleFileSave = async () => {
    if (!file) return;
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    const isValidData = (data, formFields) => {
      for (let item of data) {
        for (let field of formFields) {
          if (field.require === true) {
            if (
              field.variable !== "id" &&
              (!item.hasOwnProperty(field.label) || item[field.label] === "")
            ) {
              setErroMensaje(
                `Falta un valor para la columna ${field.label} o está vacío en uno de los registros.`
              );
              return false;
            }
          }
        }
      }
      return true;
    };

    if (isValidData(jsonData, formFieldsEdit)) {
      handleSave(jsonData);
      handleClose();
    } else {
      console.log("La validación de los datos falló.");
    }
  };

  function getToAdressDirection(adress1, adress2, adress3, adress4, adress5) {
    let a1 = adress1 ? adress1.toString() : null;
    a1 += adress2 ? " " + adress2.toString() : "";
    a1 += adress3 ? ", " + adress3.toString() : "";
    a1 += adress4 ? ", " + adress4.toString() : "";
    a1 += adress5 ? ", " + adress5.toString() : "";

    return { a1 };
  }

  function ExcelDateToJSDate(serial) {
    try {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);

      var fractional_day = serial - Math.floor(serial) + 0.0000001;

      var total_seconds = Math.floor(86400 * fractional_day);

      var seconds = total_seconds % 60;

      total_seconds -= seconds;

      var hours = Math.floor(total_seconds / (60 * 60));
      var minutes = Math.floor(total_seconds / 60) % 60;

      let fechaMasUno = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );
      fechaMasUno.setDate(fechaMasUno.getDate() + 1);

      let fechaMasUnoFormat = format(fechaMasUno, "dd/MM/yy HH:mm:ss", {
        locale: es,
      });

      let fechaActual = new Date(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      );

      return { fechaMasUnoFormat, fechaMasUno, fechaActual };
    } catch (error) {
      return { fechaMasUnoFormat: "", fechaMasUno: "", fechaActual: "" };
    }
  }

  const handleSaveData = async (formData) => {
    const upsertDocument = async (docData) => {
      let order = {};
      order.key = prefix + docData.SOC;

      let time = ExcelDateToJSDate(docData.RANGOFECHAPACTADA);
      let timeTravelDate = ExcelDateToJSDate(docData.FECHAVIAJE);

      let toAddress = getToAdressDirection(
        docData.DIRECCION1,
        docData.DIRECCION2,
        docData.DIRECCION3,
        docData.COMUNA,
        docData.COMUNA2
      );

      order.dataOrigin = dataOrigin ? dataOrigin : "DATA";
      order.key = prefix + docData.SOC;
      order.orderID = prefix + docData.SOC.toString();
      order.id = docData.SOC.toString();
      order.company = companyData.companyName;
      order.companyId = companyData.companyID;
      order.commerce = companyData.companyName;
      order.int_warehouseCode = docData.BODEGACODIGO;
      order.int_warehouseName = docData.BODEGANOMBRE;
      order.int_placeName = docData.BODEGANOMBRE;
      order.int_travelDate = timeTravelDate.fechaMasUnoFormat;
      order.int_f12 = docData.F12;
      order.int_client_soc = docData.SOC;
      order.int_lpn = docData.LPN;
      order.int_serviceLevel = docData.SERVICELEVEL;
      order.int_serviceType = docData.TIPODELIVERY
        ? docData.TIPODELIVERY
        : "All";
      order.userPhone = docData.CLIENTETELEFONO ? docData.CLIENTETELEFONO : 0;
      order.clientNames = docData.CLIENTENOMBRES;
      order.clientRut = docData.CLIENTERUT;
      order.clientEmail = docData.CLIENTECORREO
        ? docData.CLIENTECORREO
        : "NO INFORMA";
      order.toAddress = toAddress.a1;
      order.direccion1 = docData.DIRECCION1;
      order.direccion2 = docData.DIRECCION2 ? docData.DIRECCION2 : "";
      order.direccion3 = docData.DIRECCION3 ? docData.DIRECCION3 : "";
      order.commune = docData.COMUNA;
      order.commune2 = docData.COMUNA2 ? docData.COMUNA2 : "";
      order.region = docData.COMUNA ? docData.COMUNA : "";
      order.serviceType = docData.SERVICELEVEL;
      order.deliveryDate = timeTravelDate.fechaMasUnoFormat;
      order.timeStamp = time.fechaMasUno;
      order.createdAt = new Date();
      order.description = docData.DESCRIPTION
        ? docData.DESCRIPTION
        : "NO INFORMA";
      order.zonePoligon = "";
      order.pickedUp = docData.RECOGIDO;
      order.productLength = docData.LARGO;
      order.productHeight = docData.ALTO;
      order.productWidth = docData.ANCHO;
      order.productWeight = docData.PESO;
      order.productName = docData.PRODUCTO ? docData.PRODUCTO : "NO INFORMA";
      order.productSku = docData.SKU ? docData.SKU : "NO INFORMA";
      order.productSize = docData.TAMANOPRODUCTO;
      order.productUnits = docData.UNIDADES ? docData.UNIDADES : 1;
      order.productValue = docData.VALOR;
      order.productVolume = docData.VOLUMEN;
      order.productMultiDelivery = docData.MULTIBULTO;
      order.transportOrderSize = docData.TAMANOPRODUCTO;
      order.routeEstimatedDate = time.fechaMasUnoFormat;
      order.routeStartDate = time.fechaMasUnoFormat;
      order.routeStartTime = docData.VENTANAINICIO;
      order.routeEndTime = docData.VENTANATERMINO;
      order.routeLocality = docData.COMUNA2;

      const collRef = collection(db, process.env.REACT_APP_COL_ORDERS);
      const docRef = doc(collRef, order.key);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        await updateDoc(docRef, order);
      } else {
        await setDoc(docRef, order);
      }
    };

    if (Array.isArray(formData)) {
      for (const item of formData) {
        await upsertDocument(item);
      }
    } else {
      await upsertDocument(formData);
    }

    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "text/*, application/*",
    maxFiles: 1,
    maxSize: 5000000,
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Crear Viajes con documento SDD
      </DialogTitle>
      <DialogContent>
        <Button onClick={handleOpenDropzone} color="primary">
          Subir archivo SDD
        </Button>
        <Paper variant="outlined" sx={{ p: 2, textAlign: "center", mt: 2 }}>
          <Box {...getRootProps()} sx={{ border: "2px dashed #ccc", p: 2 }}>
            <input {...getInputProps()} />
            <Typography>
              Arrastra y suelta algunos archivos aquí, o haz clic para
              seleccionar archivos
            </Typography>
          </Box>
        </Paper>
        {file && <p>File ready to be saved: {file.name}</p>}
        <div>
          {erroMensaje ? (
            <Typography sx={{ m: 1, color: "red" }}>{erroMensaje}</Typography>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setErroMensaje(null);
            setFile(null);
            handleClose();
          }}
          color="primary"
        >
          Cancelar
        </Button>
        <Button onClick={handleFileSave} color="primary" disabled={!file}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormImportOrdersByDataSDD;
