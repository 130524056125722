import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import { Avatar, LinearProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import { purple, grey, green } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Form, Input } from "semantic-ui-react";
import { TextField } from "@mui/material";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import SyncIcon from "@mui/icons-material/Sync";
import { Card } from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useEffect } from "react";
import SwipeableSearcherDrawer from "../../../../pages/Dashboard/Guias/SwipeableSearcherDrawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import Grid from "@mui/material/Grid";
import GraficasChartLineDaysMonths from "../../../GraficasChartLineDaysMonths/GraficasChartLineDaysMonths";
import ToolDashUpdatePaymentsByDocAntsMethod from "../ToolDashUpdatePayments/ToolDashUpdatePaymentsByDocAntsMethod";
import ToolDashUpdatePayments from "../ToolDashUpdatePayments/ToolDashUpdatePayments";
import ToolDashUpdateDevolutions from "../ToolDashUpdatePayments/ToolDashUpdateDevolutions";

import ToolDashUpdateReportGeosort from "../ToolDashUpdatePayments/ToolDashUpdateReportGeosort";
import generarReporte from "../../../../utils/Reports/generarReporte";
import ToolDashUpdateDocPreGrilla from "../ToolDashUpdatePayments/ToolDashUpdateDocPreGrilla";
import CustomDateRangePicker from "../../../CustomDateRangePicker";
import { fDate } from "../../../../utils/objects/FormatTime";
import { useDateRangePicker } from "../../../CustomDateRangePicker";
import { LoadingButton } from "@mui/lab";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import getOrdesrDispachtByAny from "../../../../utils/getOrdesrDispachtByAny";

export default function ToolDashAppBarBilling(props) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [viewCreateRoutes, setViewCreateRoutes] = React.useState(false);
  const [viewSelectTimes, setViewSelectTimes] = React.useState(false);
  const [viewViewGrasphs, setViewViewGrasphs] = React.useState(true);
  const [viewRegisterIems, setViewRegisterIems] = React.useState(false);
  const [viewRegisterPayment, setViewRegisterPayment] = React.useState(false);
  const [viewSelectorDatesRang, setViewSelectorDatesRang] =
    React.useState(false);
  const [viewFindAnSelectorOrdes, setViewFindAnSelectorOrdes] =
    React.useState(false);

  const [viewViewSelectorOrders, setViewSelectorOrders] = React.useState(false);
  const [viewFinFindOrdes, setViewFindOrdes] = React.useState(false);
  const [viewViewSearchOrder, setViewSearchOrder] = React.useState(false);
  const [viewViewSearchOrderData, setViewSearchOrderData] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);

  const [listIDs, setListIDs] = React.useState("");

  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);

  const [dataSearch, setDataSearch] = React.useState("");
  const [arrayDataSearch, setArrayDataSearch] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());
  const rangeCalendarPicker = useDateRangePicker(new Date(), null);

  useEffect(() => {
    if (props.dataOrdersFilter) {
    }
    setDataMergeLocalOrder(props.dataOrdersFilter);
  }, [viewCreateRoutes, props, viewFinFindOrdes, props.dataOrdersFilter]);

  const handleChangeListToSelect = (e) => {
    console.log(e.target.value);
    setListIDs(e.target.value);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (dataSearch) {
      filterData(dataSearch);
    }
  }, [dataSearch]);

  useEffect(() => {}, [arrayDataSearch]);

  const filterData = async (dataSearch) => {
    const filterFunction = (obj, searchTerm) => {
      for (let key in obj) {
        const value = obj[key];
        if (typeof value === "string" && value.includes(searchTerm)) {
          return true;
        }
        if (typeof value === "object") {
          if (filterFunction(value, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };

    const filteredData = dataMergeLocalOrder.filter((item) =>
      filterFunction(item, dataSearch)
    );

    const rowsResult = filteredData.map((item) => {
      // Aquí puedes mapear los campos del objeto filtrado a la estructura que necesites
      return createData(
        item["orderID"],
        item["CLIENTENOMBRES"],
        item["CLIENTETELEFONO"],
        item["CLIENTECORREO"],
        item["driverName"],
        item["upDatedAtText"],
        item["address"],
        item["commune1"],
        item["orderID"],
        item["driverAvatar"],
        item["companyId"],
        item["estiatedDate"],
        item["COMUNA"]
      );
    });

    setRows(rowsResult);
  };

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
  };

  function createData(
    OrderID,
    Cliente,
    ClienteTelefono,
    ClienteEmail,
    Conductor,
    Fecha,
    Adress,
    Zona,
    Detalles,
    driverAvatar
  ) {
    return {
      OrderID,
      Cliente,
      ClienteTelefono,
      ClienteEmail,
      Conductor,
      Fecha,
      Adress,
      Zona,
      Detalles,
      driverAvatar,
    };
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const handleSelectBtnSearchInterfaz = async (e) => {
    await handleCloseBtnSearchInterfaz();
    switch (e.target.name) {
      case "viewFinFindOrdes":
        setViewFindOrdes(true);
        break;
      case "viewViewSelectorOrders":
        setViewSelectorOrders(true);
        break;
      case "viewViewSearchOrder":
        setViewSearchOrder(true);
        break;
      default:
        break;
    }
  };

  const handleSelectBtnSearchInAll = async (e) => {
    setViewSearchOrderData(!viewViewSearchOrderData);
  };

  const processRequest = async () => {
    setIsSubmitting(true);
    const callFindEspecialRequestData = async (especialRequest) => {
      let falabellaOrdersRequest = collection(
        db,
        process.env.REACT_APP_COL_ORDERS
      );

      const fetchData = async (collection, idPrefix = "F") => {
        return Promise.all(
          especialRequest.map(async (request) => {
            try {
              let id = idPrefix + request.toString();
              //  console.log(id);
              const documentRef = doc(collection, id);
              const data = await getDoc(documentRef);
              //  console.log(data.data());
              if (data.data()) {
                return data.data();
              } else {
                console.error("Error fetching data for ID:");
                const data = await getOrdesrDispachtByAny(request);
                console.log(request);
                console.log(data);

                return data;
              }
            } catch (error) {
              console.error("Error fetching data for ID:", error);
              console.log(request);

              return null; // o manejar el error de otra manera
            }
          })
        ).then((results) => results.filter((result) => result !== null));
      };

      const Orders = await fetchData(falabellaOrdersRequest);

      const nonNullOrders = Orders.filter((order) => order !== null);

      //console.log(nonNullOrders);

      props.setDataOrdersFilter(nonNullOrders);
      setIsSubmitting(false);
    };

    // Eliminar la última coma si existe
    let string = arrayDataSearch.replace(/[\n\r]+/g, "");

    // Dividir el string en un array
    let array = string.split(",");

    console.log(array);

    await callFindEspecialRequestData(array);

    // setTimeout(() => {
    //   setIsSubmitting(false);
    // }, 3000);
  };

  const handleCloseBtnSearchInterfaz = async () => {
    setViewFindOrdes(false);
    setViewSelectorOrders(false);
    setViewSearchOrder(false);
  };

  const handleMenuReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={16}>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "40%",
            },
          }}
        ></Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "60%",
            },
          }}
        ></Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          borderBlockColor: grey[200],
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewRegisterIems(!viewRegisterIems)}
                sx={{ color: "#591e8f" }}
              >
                <PostAddIcon
                  sx={{ color: viewRegisterIems ? green[500] : "#591e8f" }}
                />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewRegisterPayment(!viewRegisterPayment)}
                sx={{ color: "#591e8f" }}
              >
                <PriceCheckIcon
                  sx={{ color: viewRegisterPayment ? green[500] : "#591e8f" }}
                />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewSelectTimes(!viewSelectTimes)}
                sx={{ color: "#591e8f" }}
              >
                <CalendarMonthIcon
                  sx={{ color: viewSelectTimes ? green[500] : "#591e8f" }}
                />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() =>
                  setViewFindAnSelectorOrdes(!viewFindAnSelectorOrdes)
                }
                sx={{ color: "#591e8f" }}
              >
                <SearchIcon
                  sx={{
                    color: viewFindAnSelectorOrdes ? green[500] : "#591e8f",
                  }}
                />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewViewGrasphs(!viewViewGrasphs)}
                sx={{ color: "#591e8f" }}
              >
                <SsidChartIcon
                  sx={{
                    color: viewViewGrasphs ? green[500] : "#591e8f",
                  }}
                />
              </IconButton>
              Grafícos
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setViewViewGrasphs(!viewViewGrasphs)}
                sx={{ color: "#591e8f" }}
              >
                <SyncIcon
                  sx={{
                    color: viewViewGrasphs ? green[500] : "#591e8f",
                  }}
                />
              </IconButton>
              Sincronizar
            </div>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{ color: "#591e8f" }}
              >
                <FilterListIcon sx={{ color: "#591e8f" }} />
              </IconButton>{" "}
              Filtrar
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} sx={{ color: "#591e8f" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.CheckedChangeOE}
                        onChange={props.handleChangeOE} // Filtros
                        aria-label="handleChangeOE switch"
                        sx={{
                          color: props.CheckedChangeOE
                            ? green[500]
                            : purple[500],
                        }}
                      />
                    }
                    label={
                      props.CheckedChangeOE
                        ? "Ocultar incidencias activas"
                        : "Mostrar incidencias activas"
                    }
                    sx={{
                      color: props.CheckedChangeOE ? green[500] : purple[500],
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ color: "#591e8f" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.typeRequets.typeRequetsCreate}
                        onChange={props.handleChangetypeRequets} // Filtros
                        aria-label="typeRequetsCreate switch"
                        name={"typeRequetsCreate"}
                        sx={{
                          color: props.typeRequets.typeRequetsCreate
                            ? green[500]
                            : purple[500],
                        }}
                      />
                    }
                    label={
                      props.typeRequets.typeRequetsCreate
                        ? "Mostrar Por F. Creacion"
                        : "Ocultar Por F. Ultima Act"
                    }
                    sx={{
                      color: props.typeRequets.typeRequetsCreate
                        ? green[500]
                        : purple[500],
                    }}
                  />
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ color: "#591e8f" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={props.typeRequets.typeRequetsUpdate}
                        onChange={props.handleChangetypeRequets} // Filtros
                        name={"typeRequetsUpdate"}
                        aria-label="typeRequetsUpdate switch"
                        sx={{
                          color: props.typeRequets.typeRequetsUpdate
                            ? green[500]
                            : purple[500],
                        }}
                      />
                    }
                    label={
                      props.typeRequets.typeRequetsUpdate
                        ? "Mostrar Por F. Creacion"
                        : "Ocultar Por F. Ultima Act"
                    }
                    sx={{
                      color: props.typeRequets.typeRequetsUpdate
                        ? green[500]
                        : purple[500],
                    }}
                  />
                </MenuItem>
              </Menu>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuReport}
                sx={{ color: "#591e8f" }}
              >
                <SaveAltIcon sx={{ color: "#591e8f" }} />
              </IconButton>
              Reportes
              <Menu
                id="menu-appbar"
                anchorEl={anchorElReport}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElReport)}
                onClose={handleCloseMenuReport}
              >
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <>
                      <button
                        onClick={() => generarReporte(props.dataOrdersFilter)}
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Pagos Riders
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteTurno(props.dataOrdersFilter)
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Cierre Turno Salida directas
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <button
                      onClick={() =>
                        props.exportartReportePagos(props.dataOrdersFilter)
                      }
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Data facturación
                    </button>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <button
                      onClick={() =>
                        props.exportartPicking(props.dataOrdersFilter)
                      }
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Reporte Picking
                    </button>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length &&
                  props.dataOrdersForAccions &&
                  props.dataOrdersForAccions.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteEstados(
                            props.dataOrdersForAccions
                          )
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Estados
                      </button>
                    </>
                  ) : (
                    <div>
                      Seleccione Órdenes para, exportar un reporte de Estados
                    </div>
                  )}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilte &&
                  props.dataOrdersFilter.length > 0 ? (
                    <button
                      onClick={() => props.findHistorials()}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Ver Historiales de Órdenes
                    </button>
                  ) : null}
                </MenuItem>

                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <button
                      onClick={() => props.setWiewPrePdf(!props.wiewPrePdf)}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {props.wiewPrePdf === true
                        ? "Cerrar vista previa de reporte"
                        : "Crear reporte Cierre Diario"}
                    </button>
                  ) : null}
                </MenuItem>
              </Menu>
            </div>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        >
          <div>
            <h3>{props.cantDataOrdersFilter} Ordenes en la consulta actual</h3>
          </div>
        </Grid>
      </Grid>
      {viewSelectTimes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                borderBlockColor: grey[200],
              }}
            >
              <Grid container spacing={2} columns={16}>
                <Toolbar
                  sx={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Grid container columns={16}>
                    <Button
                      variant="contained"
                      onClick={rangeInputPicker.onOpen}
                      sx={{
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        backgroundColor: "white", // Fondo blanco
                        borderColor: "purple", // Bordes morados
                        color: "#080808",
                        border: "1px solid #e1e1e1", // Define un borde
                        display: "flex", // Estilo flex para centrar los contenidos
                        justifyContent: "center", // Centra horizontalmente
                        alignItems: "center", // Centra verticalmente
                        "&:hover": {
                          // Efecto hover
                          backgroundColor: "#e1e1e1", // gris claro
                        },
                      }}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <div sx={{ typography: "body2" }}>
                          <strong>Desde:</strong>{" "}
                          {fDate(rangeInputPicker.startDate)}
                          <EventIcon color="primary" />{" "}
                          {/* Ícono de calendario */}
                        </div>
                        <div sx={{ typography: "body2" }}>
                          <strong>Hasta:</strong>{" "}
                          {fDate(rangeInputPicker.endDate)}
                          <EventIcon color="primary" />{" "}
                          {/* Ícono de calendario */}
                        </div>
                      </Stack>
                    </Button>

                    <CustomDateRangePicker
                      variant="calendar" // input
                      open={rangeInputPicker.open}
                      startDate={rangeInputPicker.startDate}
                      endDate={rangeInputPicker.endDate}
                      onChangeStartDate={rangeInputPicker.onChangeStartDate}
                      onChangeEndDate={rangeInputPicker.onChangeEndDate}
                      onClose={rangeInputPicker.onClose}
                      error={rangeInputPicker.error}
                      setNewDate={setNewDate}
                    />
                  </Grid>
                </Toolbar>
              </Grid>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
              >
                {props.dataType === "request" ? (
                  <Button
                    className="btn-tool-small "
                    name="viewFinFindOrdes"
                    onClick={(e) => handleSelectBtnSearchInterfaz(e)} //</ButtonGroup>setViewFindOrdes(!viewFinFindOrdes)}
                  >
                    Encontrar en el rango actual
                  </Button>
                ) : null}
                {props.dataType === "request" ? (
                  <Button
                    className="btn-tool-small "
                    name="viewFinFindOrdes"
                    onClick={(e) => handleSelectBtnSearchInAll(e)} //</ButtonGroup>setViewFindOrdes(!viewFinFindOrdes)}
                  >
                    Buscar en todo el sistema
                  </Button>
                ) : null}

                <SwipeableSearcherDrawer
                  companyId={props.companyId}
                  iconColor={"#FFF"}
                  styleClassName={"btn-tool-small"}
                ></SwipeableSearcherDrawer>
              </ButtonGroup>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewFinFindOrdes === true && viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <div>
                <TextField
                  multiline
                  minRows={4} // Número de líneas visibles
                  maxRows={4}
                  placeholder="Busque ordenes relacionadas con algun dato, ejemplo nombre del cliente"
                  variant="outlined" // Puedes elegir entre 'standard', 'filled', o 'outlined'
                  onChange={(e) => setDataSearch(e.target.value)}
                  value={dataSearch}
                  // Aplica cualquier estilo adicional que necesites
                  sx={{
                    backgroundColor: "transparent",
                    border: "0.1",
                    borderRadius: "15px",
                    color: "#000",
                    fontSize: "inherit",
                    height: "auto",
                    width: "100px",
                    outline: "none",
                    paddingInlineEnd: "44px",
                    position: "relative",
                    textAlign: "left",
                    appearance: "menulist-button",
                    backgroundImage: "none",
                    maxWidth: "250px",
                    display: "inline-block",
                    margin: "3px",
                  }}
                />
              </div>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewViewSearchOrderData === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <div>
                <TextField
                  multiline
                  minRows={4} // Número de líneas visibles
                  maxRows={4}
                  placeholder="Busque ordenes separados, por comas, F14001, F14002..."
                  variant="outlined" // Puedes elegir entre 'standard', 'filled', o 'outlined'
                  onChange={(e) => setArrayDataSearch(e.target.value)}
                  value={arrayDataSearch}
                  // Aplica cualquier estilo adicional que necesites
                  sx={{
                    backgroundColor: "transparent",
                    border: "0.1",
                    borderRadius: "15px",
                    color: "#000",
                    fontSize: "inherit",
                    width: "450px",
                    height: "auto",
                    outline: "none",
                    paddingInlineEnd: "44px",
                    position: "relative",
                    textAlign: "left",
                    appearance: "menulist-button",
                    backgroundImage: "none",
                    maxWidth: "250px",
                    display: "inline-block",
                    margin: "3px",
                  }}
                />
              </div>
              <LoadingButton
                onClick={() => processRequest()}
                variant="contained"
                loading={isSubmitting}
                sx={{ m: 1, backgroundColor: "green" }}
              >
                Procesar busqueda
              </LoadingButton>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewViewGrasphs === true ? (
        <Grid container spacing={3} columns={16}>
          <Grid
            item
            sx={{
              width: "100%",
              maxHeight: "500px",
            }}
          >
            <Card
              sx={{
                borderRadius: "3px",
                maxHeight: "500px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  marginBottom: "16px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Ver paquetes mensuales</span>{" "}
                <Form.Field
                  id="FinalDate"
                  max={Moment(Date.now()).format("YYYY-MM")}
                  control={Input}
                  format={"YYYY-MM"}
                  type="month"
                  name="rangoFinal"
                  onChange={(e) => props.onChageRangeMonths(e)}
                  value={props.dateMonths}
                />
              </Box>

              <Box
                sx={{
                  flex: "1 1 auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "16px",
                }}
              >
                {props.dataCurrentMohstDelivery ? (
                  <Box sx={{ width: "100%", height: "100%" }}>
                    <GraficasChartLineDaysMonths
                      props={props}
                      name={"Paquetes por día"}
                      dataHistorial={props.dataCurrentMohstDelivery}
                      color={"rgb(118, 27, 204)"}
                    ></GraficasChartLineDaysMonths>
                  </Box>
                ) : (
                  <LinearProgress />
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      ) : null}

      {viewRegisterIems === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <ToolDashUpdatePaymentsByDocAntsMethod
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdatePaymentsByDocAntsMethod>

            <ToolDashUpdateDocPreGrilla
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdateDocPreGrilla>

            <ToolDashUpdateReportGeosort
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdateReportGeosort>

            <ToolDashUpdatePayments
              setAlertMensajes={props.setAlertMensajes}
              setDocumentData={props.setDocumentData}
              setDataType={props.setDataType}
              remder={props.remder}
              setRender={props.setRender}
              usuarioStorage={props.usuarioStorage}
            ></ToolDashUpdatePayments>

            <ToolDashUpdateDevolutions
              setAlertMensajes={props.setAlertMensajes}
            ></ToolDashUpdateDevolutions>

            <Card
              className="typeMiniformsBarsAccionsItemsMinimal"
              sx={{ borderRadius: "3px" }}
            >
              <div className="m-2">
                <div className="labelupload">
                  <label htmlFor="file-upload1">
                    Buscar Cruce de data con ordenes creadas
                  </label>

                  <button
                    onClick={() =>
                      props.verifiqueOrdesStatus(props.dataOrdersFilter)
                    }
                    className="btn-add-guide-small m-2"
                  >
                    Buscar Cambios
                  </button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      ) : null}

      {viewRegisterPayment === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          >
            <div className="typeMiniformsBarsAccionsItemsMinimal">
              {" "}
              Validate payments viewRegisterPayment
            </div>
            <div className="typeMiniformsBarsAccionsItemsMinimal">
              {" "}
              Validate payments viewRegisterPayment
            </div>
            <div className="typeMiniformsBarsAccionsItemsMinimal">
              {" "}
              Validate payments viewRegisterPayment
            </div>
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2} columns={16}>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "50%",
            },
          }}
        ></Grid>
      </Grid>
      <div>
        <Box sx={{ width: "100%" }}>
          {dataSearch.length > 0 &&
          viewFinFindOrdes === true &&
          viewFindAnSelectorOrdes === true ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell align="right">Conductor</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Zona</TableCell>
                    <TableCell align="right">Ver&nbsp;Detalles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div>
                          <div>
                            <strong>{row.OrderID}</strong>
                          </div>
                          <div>{row.Cliente}</div>
                          <div>9{row.ClienteTelefono}</div>
                          <div>{row.ClienteEmail}</div>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div>
                          <div>
                            <Avatar
                              src={row.driverAvatar}
                              sx={{ bgcolor: "#f2f2f2" }}
                              aria-label="recipe"
                            />
                          </div>
                          <div>
                            {row.Conductor ? row.Conductor : "No Asignado"}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.Fecha}</TableCell>
                      <TableCell align="right">{row.Zona}</TableCell>
                      <TableCell align="right">
                        <NavLink
                          to={{
                            pathname: `/dashboard/dispatch/details/${row.OrderID}`,
                          }}
                          color="primary"
                          variant="contained"
                        >
                          {" "}
                          ver{" "}
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>
      </div>
    </Box>
  );
}
