import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Moment from "moment";
import * as XLSX from "xlsx";
import { GoogleMap } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { assingMultipleDriver } from "../../redux/accionesDrivers";
import { Icon } from "semantic-ui-react";
import { Tooltip, IconButton, LinearProgress } from "@mui/material";
import { CardHeader, Avatar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import getResolveValidationAdressGeoRef from "../../utils/getResolveValidationAdressGeoRef";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "90vw",
  backgroundColor: "#FFFFFF",
  border: "1px solid #000",
  boxShadow: "0px 25px 50px rgba(100, 116, 139, 0.25)",
  padding: "32px",
};
const btnBicci = {
  paddingtop: 20,
  bgcolor: "#7d0ee6",
  display: "inlineblock",
  padding: 1,
  borderRadius: 4,
};

//const libraries = ["drawing"];
export default function ModalAccionsDispacthDirectionsResolve(props) {
  ////console.log("props ModalAccionsDispacth");
  ////console.log(props);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(props.opened);
  const [awaitCarge, setAwaitCarge] = React.useState(false);
  const [localDataOrdersForAccions, setDataOrdersForAccions] = React.useState(
    []
  );
  const [ordersForAccions, setOrdersForAccions] = React.useState(
    props.ordersForAccions
  );
  const [adressOrigin, setadressOrigin] = React.useState(props.adressOrigin);
  const [origenGeoCode, setorigenGeoCode] = React.useState(props.origenGeoCode);

  const [adressDestiny, setadressDestiny] = React.useState(props.adressDestiny);
  const [destinyGeoCode, setDestinyGeoCode] = React.useState(
    props.destinyGeoCode
  );

  const [linkUrlGoogleMaps, setLinkUrlGoogleMaps] = React.useState("");
  const [ridersRows, setRidersRows] = React.useState(props.ridersDataByCompany);
  const [setectedRider, setSetectedRider] = React.useState(
    props.ridersDataByCompany
  );
  const [setectedRiderName, setSetectedRiderName] = React.useState();
  const [setectedRiderAvatar, setSetectedRiderAvatar] = React.useState();

  const [loadingRoute, setLoadingRoute] = React.useState(null);
  const [loadingMensaje, setloadingMensaje] = React.useState(null);
  const google = window.google;

  React.useEffect(() => {}, [setectedRider]);

  React.useEffect(() => {
    //findOriginalAdres(props.dataOrdersForAccions, props.ordersForAccions);
    if (props.dataOrdersForAccions && props.dataOrdersForAccions.length > 0) {
      resolveDireccions(props.dataOrdersForAccions, props.ordersForAccions);
    }
  }, [props.dataOrdersForAccions && props.ordersForAccions]);

  const resolveDireccions = async (orders, lisOrders) => {
    setAwaitCarge(true);
    for (let n = 0; n < orders.length; n++) {
      try {
        const direcctioToValidate = `${orders[n].address}, ${orders[n].commune2}, Región Metropolitana, chile`; // dirección con error se debe forzar el pais.
        // console.log(orders[n].id); Pdte. Riesco 4680, Dpto 804, 7560977 Las Condes,
        // console.log(orders[n]);
        // console.log(direcctioToValidate);
        let rest = await getResolveValidationAdressGeoRef(direcctioToValidate);

        if (rest.response === "OK") {
          orders[n].alertAddresError = false;
          orders[n].region = rest.Validarobjeto.administrative_area_level_1;
          orders[n].city = rest.Validarobjeto.administrative_area_level_2;
          orders[n].comuna = rest.Validarobjeto.administrative_area_level_3;
          orders[n].country = rest.Validarobjeto.country;
          orders[n].formatted_add = rest.Validarobjeto.formatted_address;
          orders[n].lngLat = rest.Validarobjeto.lngLat;
          orders[n].comuna2 = rest.Validarobjeto.locality;
          orders[n].placeId = rest.Validarobjeto.place_id;
          orders[n].calle = rest.Validarobjeto.route;
          orders[n].street_number = rest.Validarobjeto.street_number;
          orders[n].newGeoRef = rest.newGeoRef;
        } else {
          console.log("Error");
          console.log(rest);
          orders[n].alertAddresError = true;
          orders[n].region = undefined;
          orders[n].city = undefined;
          orders[n].comuna = undefined;
          orders[n].country = undefined;
          orders[n].formatted_add = undefined;
          orders[n].lngLat = undefined;
          orders[n].comuna2 = undefined;
          orders[n].placeId = undefined;
          orders[n].calle = undefined;
          orders[n].street_number = undefined;
          orders[n].newGeoRef = undefined;
        }
      } catch (error) {
        console.error(error);

        orders[n].alertAddresError = true;
        orders[n].region = undefined;
        orders[n].city = undefined;
        orders[n].comuna = undefined;
        orders[n].country = undefined;
        orders[n].formatted_add = undefined;
        orders[n].lngLat = undefined;
        orders[n].comuna2 = undefined;
        orders[n].placeId = undefined;
        orders[n].calle = undefined;
        orders[n].street_number = undefined;
        orders[n].newGeoRef = undefined;
      }
    }
    setDataOrdersForAccions(orders);
    setAwaitCarge(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const crearLinkGoogle = () => {
    //constructorGoogleLink();
  };

  const crearLinkGoogleLive = () => {
    //constructorGoogleLinkLive();
    constructorGoogleLinkLiveResolve();
  };

  const constructorGoogleLinkLive = async () => {
    setLoadingRoute(true);
    ////console.log("BOTON 1");
    function espera() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          ////console.log("Procesando...");
          resolve();
        }, 1000);
      });
    }

    const apiDireccionlive = async (address, country) => {
      try {
        const results = await getGeocode(address, country);
        ////console.log(address);
        if (results) {
          // Loop through the address components and log them to the console
          for (let i = 0; i < results[0].address_components.length; i++) {
            let elementAdress = results[0].address_components[i];

            switch (elementAdress["types"][0]) {
              case "street_number":
                ////console.log(elementAdress.long_name);
                break;
              case "route":
                ////console.log(elementAdress.long_name);
                break;
              case "locality":
                ////console.log(elementAdress.long_name);
                break;
              case "administrative_area_level_3":
                //////console.log(elementAdress.long_name);
                break;
              case "administrative_area_level_2":
                //////console.log(elementAdress.long_name);
                //////console.log(elementAdress.long_name);
                break;
              case "administrative_area_level_1":
                //////console.log(elementAdress.long_name);
                //////console.log(elementAdress.long_name);
                break;
              case "country":
                ////console.log(elementAdress.long_name);
                //////console.log(elementAdress.long_name);
                break;
              case "postal_code":
                ////////console.log(elementAdress.long_name);
                break;
              default:
                break;
            }
          }
        } else {
          ////console.log("error en la dirección :", address);
        }

        const { lat, lng } = await getLatLng(results[0]);
        ////console.log(lat + "  " + lng);
        return { lat, lng };
      } catch (error) {
        ////console.log(error);
        return { lat: 0, lng: 0 };
      }
    };

    const getGeocode = (address, country) =>
      new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            address: address,
            componentRestrictions: { country: country },
          },
          (results, status) => {
            ////console.log(results);
            if (status === "OK") {
              resolve(results);
            } else {
              reject(status);
            }
          }
        );
      });

    if (localDataOrdersForAccions.length <= 25) {
      // console.log("localDataOrdersForAccions", localDataOrdersForAccions);
      var directionsService = new google.maps.DirectionsService();

      let routeZonesWaitPoints = [];
      let routeAddresFormartText = [];
      let serachData = localDataOrdersForAccions;
      for (let n = 0; n < serachData.length; n++) {
        routeZonesWaitPoints.push({
          location: serachData[n].newGeoRef,
          stopover: true,
        });
      }

      var startPoint = new google.maps.LatLng(origenGeoCode);
      var endPoint = new google.maps.LatLng(destinyGeoCode);
      var bounds = new google.maps.LatLngBounds();

      ////console.log(routeZonesWaitPoints);
      await calculateSimpleRoute(
        directionsService,
        startPoint,
        endPoint,
        routeZonesWaitPoints,
        bounds
      );

      async function calculateSimpleRoute(
        directionsService,
        startPoint,
        endPoint,
        wayPointsArray,
        bounds,
        ZoneName
      ) {
        var resps;
        directionsService.route(
          {
            origin: startPoint,
            destination: endPoint,
            travelMode: "BICYCLING",
            optimizeWaypoints: true,
            waypoints: wayPointsArray,
          },
          function (response, status) {
            if (status === "OK") {
              ////console.log("response ROUTE");
              resps = "ok";
              ////console.log(response);
              var routesLegs = response.routes[0];
              var placesIDs = response.geocoded_waypoints;
              var urlMapsApiRoute;
              var stringPointsGoogleMaps = "";
              var stringWaitPointsGoogleMaps = "";

              var mapsApiData_origin = "";

              var mapsApiData_destination = "";
              var mapsApiData_travelmode = "&travelmode=bicycling";

              // marca edicion
              let mapsApiData_waypoints = [];
              let mapsApiData_waypoint_place_ids = [];
              // console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
              //console.log("Evaluar si es necesario");
              // console.log("destination");
              // console.log(origin);
              // console.log("Evaluar si es necesario ");
              for (let i = 0; i < routesLegs.legs.length; i++) {
                const { start_address, end_address } = routesLegs.legs[i];

                const formattedStartAddress = formatAddress(start_address);
                const formattedEndAddress = formatAddress(end_address);

                if (i === 0) {
                  mapsApiData_origin = formattedStartAddress;
                  mapsApiData_origin = `/${mapsApiData_origin}`;

                  mapsApiData_waypoints.push(formattedEndAddress);
                } else if (i === routesLegs.legs.length - 1) {
                  mapsApiData_destination = formattedEndAddress;
                  mapsApiData_destination = `/${mapsApiData_destination}`;
                } else {
                  const formattedWaypoint = formatAddress(end_address, ",");
                  mapsApiData_waypoints.push(formattedWaypoint);
                }
              }

              stringPointsGoogleMaps = `/${mapsApiData_waypoints.join("/")}`;

              placesIDs.forEach((place) => {
                mapsApiData_waypoint_place_ids.push(place.place_id);
              });

              stringWaitPointsGoogleMaps = `&waypoint_place_ids=${mapsApiData_waypoint_place_ids.join(
                "%7C"
              )}`;

              urlMapsApiRoute = `https://www.google.com/maps/dir${mapsApiData_origin}${stringPointsGoogleMaps}/@-33.4363776,-70.6336738,12z`;
              setLinkUrlGoogleMaps(urlMapsApiRoute);

              // Reset variables
              stringPointsGoogleMaps = "";
              stringWaitPointsGoogleMaps = "";
              mapsApiData_origin = "";
              mapsApiData_waypoints = [];
              mapsApiData_destination = "";
              mapsApiData_travelmode = "&travelmode=bicycling";
              mapsApiData_waypoint_place_ids = [];

              // Helper function to format address
              function formatAddress(address, separator = "+") {
                let formattedAddress = address.replace(/,+/g, "");
                formattedAddress = formattedAddress.replace(/\s+/g, separator);
                return formattedAddress;
              }
            } else {
            }
            setLoadingRoute(null);
            setloadingMensaje(null);
          }
        );
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(resps);
          }, 2000);
        });
      }
    }
  };

  const constructorGoogleLinkLiveResolve = async () => {
    setLoadingRoute(true);
    //console.log("BOTON Nuevo Link");
    function espera() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          ////console.log("Procesando...");
          resolve();
        }, 1000);
      });
    }
    if (localDataOrdersForAccions.length <= 25) {
      // console.log("constructorGoogleLinkLiveResolve",localDataOrdersForAccions );
      let routeZonesWaitPoints = [];
      let routeAddresFormartText = [];
      let serachData = localDataOrdersForAccions;
      for (let n = 0; n < serachData.length; n++) {
        if (!serachData[n].formatted_add) {
          console.log(
            `Valida ${serachData[n].id} la dirección posee ${serachData[n].address}, ${serachData[n].commune2} genera errores`
          );
        }
        routeZonesWaitPoints.push({
          location: serachData[n].newGeoRef // newGeoRef
            ? serachData[n].newGeoRef
            : {
                lat: parseFloat(serachData[n].toAddressLocation.latitude),
                lng: parseFloat(serachData[n].toAddressLocation.longitude),
              },
          stopover: true,
        });
        routeAddresFormartText.push(
          serachData[n].formatted_add
            ? serachData[n].formatted_add
            : `${serachData[n].address}, ${serachData[n].commune2}`
        );
        // routeAddresFormartText.push(`${serachData[n].address}, ${serachData[n].commune2}, ${serachData[n].commune1}, ${serachData[n].country} `);
      }

      var startPoint = new google.maps.LatLng(origenGeoCode);
      var endPoint = new google.maps.LatLng(destinyGeoCode);

      //console.log(localDataOrdersForAccions);
      await calculateSimpleRoute(
        startPoint,
        endPoint,
        routeZonesWaitPoints,
        routeAddresFormartText
      );

      async function calculateSimpleRoute(
        startPoint,
        endPoint,
        wayPointsArray,
        routeAddresFormartText
      ) {
        var respsOrder;
        var directionsService = new google.maps.DirectionsService();

        directionsService.route(
          {
            origin: startPoint,
            destination: endPoint,
            travelMode: "BICYCLING",
            optimizeWaypoints: true,
            waypoints: wayPointsArray,
          },

          function (response, status) {
            if (status === "OK") {
              // console.log(`%c CONSUMO $$`, "color: red; font-size: 18px");
              // console.log("Evaluar si es necesario");
              // console.log("destination");
              // console.log(origin);
              // console.log("Evaluar si es necesario ");

              ////console.log("response ROUTE");
              var varOrderAdrresResponse = response.routes[0].waypoint_order;
              //console.log(response);

              routeAddresFormartText.sort((a, b) => {
                const indexA = varOrderAdrresResponse.indexOf(
                  routeAddresFormartText.indexOf(a)
                );
                const indexB = varOrderAdrresResponse.indexOf(
                  routeAddresFormartText.indexOf(b)
                );
                return indexA - indexB;
              });
              let mapsApiData_waypoints = [];
              for (let n = 0; n < routeAddresFormartText.length; n++) {
                // console.log(
                //   "routeAddresFormartText[n]",
                //   routeAddresFormartText[n]
                // );
                const waypoint = routeAddresFormartText[n];
                mapsApiData_waypoints.push(formatAddress(waypoint));
              }

              var urlMapsApiRoute;
              var stringPointsGoogleMaps = "";
              var stringWaitPointsGoogleMaps = "";

              var mapsApiData_origin = formatAddress(adressOrigin);

              var mapsApiData_destination = formatAddress(adressDestiny);

              var mapsApiData_travelmode = "&travelmode=bicycling";

              // marca edicion

              let mapsApiData_waypoint_place_ids = [];

              stringPointsGoogleMaps = `/${mapsApiData_waypoints.join("/")}`;

              stringWaitPointsGoogleMaps = `&waypoint_place_ids=${mapsApiData_waypoint_place_ids.join(
                "%7C"
              )}`;

              urlMapsApiRoute = `https://www.google.com/maps/dir/${mapsApiData_origin}/${stringPointsGoogleMaps}/${mapsApiData_destination}/@-33.4363776,-70.6336738,12z?entry=ttu`;
              setLinkUrlGoogleMaps(urlMapsApiRoute);

              // Reset variables
              stringPointsGoogleMaps = "";
              stringWaitPointsGoogleMaps = "";
              mapsApiData_origin = "";
              mapsApiData_waypoints = [];
              mapsApiData_destination = "";
              mapsApiData_travelmode = "&travelmode=bicycling";
              mapsApiData_waypoint_place_ids = [];

              // Helper function to format address
              function formatAddress(address, separator = "+") {
                // console.log("address", address);
                let formattedAddress = address.replace(/,+/g, "");
                formattedAddress = formattedAddress.replace(/\s+/g, separator);
                return formattedAddress;
              }
            } else {
              console.log("erro");
              // console.log(status);
            }
            setLoadingRoute(null);
            setloadingMensaje(null);
          }
        );
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(respsOrder);
          }, 2000);
        });
      }
    }
  };

  const asignarConductor = () => {
    var dataAsid = {
      data: {
        driverID: setectedRider,
        orderIDs: ordersForAccions,
      },
    };
    //////console.log(dataAsid);
    dispatch(assingMultipleDriver(dataAsid));
    setOpen(false);
  };

  const columnsRiders = [
    {
      field: "selector",
      headerName: "",
      width: 20,
      renderCell: (ridersRows) => {
        // ////////console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <Tooltip title="Seleccionar">
              <IconButton
                id={ridersRows.id}
                drivername={ridersRows.row.name}
                data={ridersRows.row.avatar}
                onClick={handleGoPropsRidersRows2}
                size="small"
                sx={{ ml: 2, color: "#1e2158" }}
              >
                {/* <ArrowForwardIosIcon   onClick={handleGoPropsRidersRows3} ></ArrowForwardIosIcon> */}

                <Icon name="angle right" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Seleccione un conductor",
      width: 250,
      minHeight: 70,
      height: 70,
      renderCell: (ridersRows) => {
        //      ////////console.log(ridersRows)
        return (
          <>
            <CardHeader
              avatar={
                <Avatar
                  src={ridersRows.row.avatar}
                  sx={{ bgcolor: "#f2f2f2" }}
                  //onClick={handleGoPropsRidersRows3}
                  aria-label="recipe"
                ></Avatar>
              }
              title={ridersRows.row.name}
              subheader={ridersRows.row.bicciNumber}
            />
          </>
        );
      },
    },
  ];

  const handleGoPropsRidersRows2 = (e) => {
    setSetectedRider(e.target.parentNode.id);
    setSetectedRiderAvatar(
      e.target.parentNode.attributes.getNamedItem("data").value
    );
    setSetectedRiderName(
      e.target.parentNode.attributes.getNamedItem("drivername").value
    );

    // setRender(!render)
    //  newRoute.driverID = e.target.parentNode.id
    //  newRoute.driverName =  e.target.parentNode.value
  };

  const exporExcelFileCircuit = (data) => {
    for (let n = 0; n < data.length; n++) {
      if (data[n].calle === undefined) {
        data[n].calle = "Error";
      }
      if (data[n].street_number === undefined) {
        data[n].street_number = "Error";
      }
      if (data[n].region === undefined) {
        data[n].region = "Error";
      }
      if (data[n].comuna !== undefined || data[n].comuna2 !== undefined) {
        data[n].comuna = () => {
          if (data[n].comuna) {
            return data[n].comuna;
          } else {
            return data[n].comuna2;
          }
        };
      }
    }

    const matrizReporte = [
      [
        "Unit/Flat/Unit Name",
        "Address Line 1",
        "Address Line 2",
        "City",
        "State Zip",
        "Country",
        "Earliest Arrival Time",
        "Latest Arrival Time",
        "Notes",
        "Size",
        "Recipient Name",
        "Type of stop",
        "Recipient Email Address",
        "Recipient Phone Number",
        "Id",
        "Package Count",
        "Products",
        "Seller website",
        "Seller Name",
        "Driver (email or phone number)",
      ],
      ...data.map((dato) => [
        "",
        dato.calle + " " + dato.street_number,
        dato.commune2,
        dato.commune1,

        "",
        "CL",
        "",

        "",
        dato.address,
        dato.size,
        dato.customer_name,
        "",
        "",
        `+569${dato.customer_phone}`,
        dato.id,
        dato.quantity,
        "",
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `${data[0].driverName} Circuit ${Moment(Date.now()).format(
        "YYYY-MM-DD"
      )}.xlsx`
    );
  };

  const exporExcelFileCircuitfromOps = (data) => {
    console.log(data);
    const matrizReporte = [
      [
        "Unit/Flat/Unit Name",
        "Address Line 1",
        "Address Line 2",
        "City",
        "State Zip",
        "Country",
        "Earliest Arrival Time",
        "Latest Arrival Time",
        "Notes",
        "Size",
        "Recipient Name",
        "Type of stop",
        "Recipient Email Address",
        "Recipient Phone Number",
        "Id",
        "Package Count",
        "Products",
        "Seller website",
        "Seller Name",
        "Driver (email or phone number)",
      ],
      ...data.map((dato) => [
        "",
        dato.calle ? dato.calle + " " + dato.street_number : dato.toAddress,
        dato.commune2,
        dato.commune1,

        "",
        "CL",
        "",

        "",
        dato.address,
        dato.size,
        dato.customer_name ? dato.customer_name : dato.clientNames,
        "",
        "",
        `+569${dato.customer_phone}`,
        dato.id,
        dato.quantity ? dato.quantity : dato.productUnits,
        "",
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `${
        data[0].driverName === "isRequire"
          ? data[0].driverID
          : data[0].driverName
      } Circuit ${Moment(Date.now()).format("YYYY-MM-DD")}.xlsx`
    );
  };

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        id="modalserv"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {!awaitCarge ? (
          <Fade in={open}>
            <Box sx={style}>
              {props.interfaceOrigen === "facturationOrdes" ? (
                <>
                  {" "}
                  {props.popUpAccion === "RutaBicciexpress" ? (
                    <>En desarrollo</>
                  ) : null}
                  {props.popUpAccion === "GoogleMaps" ? (
                    <div>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Link {props.popUpAccion}
                      </Typography>
                      <div id="transition-modal-description" sx={{ mt: 2 }}>
                        <div className="textModalContent">
                          Paradas : {localDataOrdersForAccions.length}
                        </div>
                        <div className="textModalContent">
                          Origen : {props.adressOrigin}
                        </div>
                        <div className="textModalContent">
                          Destino : {props.adressDestiny}
                        </div>
                        <div>
                          {loadingMensaje === null ? null : (
                            <span>{loadingMensaje}</span>
                          )}
                        </div>
                        <div>
                          {loadingRoute ? null : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                        {/* <div>{linkUrlGoogleMaps}</div>      */}
                        {linkUrlGoogleMaps ? (
                          <>
                            <button
                              className="btn-edit-guide"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  linkUrlGoogleMaps
                                );
                              }}
                            >
                              {" "}
                              Copiar Link de Ruta
                            </button>

                            <a
                              className="btn-edit-guide"
                              href={linkUrlGoogleMaps}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Abrir en navegador
                            </a>
                          </>
                        ) : null}

                        <GoogleMap></GoogleMap>

                        {localDataOrdersForAccions.length <= 23 ? (
                          <>
                            {" "}
                            <div></div>
                            <div className="positionSelector"></div>
                            <div>
                              <button
                                className="btn-edit-guide"
                                onClick={crearLinkGoogle}
                              >
                                {" "}
                                Generar link
                              </button>
                            </div>
                            <div></div>
                          </>
                        ) : (
                          <div className="textModalContent">
                            {" "}
                            Excede los 25 Puntos, es un limite de paradas de api
                            de Google Maps
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {props.popUpAccion === "Asignacion" ? (
                    <div>
                      <Box
                        sx={{
                          width: "100%",
                          height: 350,
                          backgroundColor: "#fafafa",
                        }}
                      >
                        {ridersRows ? (
                          <DataGrid
                            className="DataGridStyle"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                            rows={ridersRows}
                            columns={columnsRiders}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                      <div>
                        {setectedRider ? (
                          <>
                            <CardHeader
                              avatar={
                                <Avatar
                                  src={setectedRiderAvatar}
                                  sx={{ bgcolor: "#f2f2f2" }}
                                  aria-label="recipe"
                                ></Avatar>
                              }
                              title={setectedRiderName}
                            />
                          </>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-ebiex extentButton"
                        onClick={asignarConductor}
                      >
                        {" "}
                        Asignar conductor{" "}
                      </button>
                    </div>
                  ) : null}
                  {props.popUpAccion === "ExcelFile" ? (
                    <>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Crear {props.popUpAccion} de ruta para Circuit
                      </Typography>
                      <div className="textModalContent">
                        <div className="textModalContent">
                          {" "}
                          Listo para descargar:
                        </div>
                        <div className="textModalContent">
                          {" "}
                          contenido : {props.dataOrdersForAccions.length}
                        </div>
                        <button
                          onClick={() =>
                            exporExcelFileCircuit(props.dataOrdersForAccions)
                          }
                          className="btn btn-ebiex m-2 btn-reporte-excel"
                        >
                          {" "}
                          Descargar Ruta
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
              {props.interfaceOrigen === "liveOrders" ? (
                <>
                  {" "}
                  {props.popUpAccion === "RutaBicciexpress" ? (
                    <>En desarrollo</>
                  ) : null}
                  {props.popUpAccion === "GoogleMaps" ? (
                    <div>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Link {props.popUpAccion}
                      </Typography>
                      <div id="transition-modal-description" sx={{ mt: 2 }}>
                        <div className="textModalContent">
                          Paradas : {localDataOrdersForAccions.length}
                        </div>
                        <div className="textModalContent">
                          Origen : {props.adressOrigin}
                        </div>
                        <div className="textModalContent">
                          Destino : {props.adressDestiny}
                        </div>
                        <div>
                          {loadingMensaje === null ? null : (
                            <span>{loadingMensaje}</span>
                          )}
                        </div>
                        <div>
                          {!loadingRoute ? null : (
                            <LinearProgress></LinearProgress>
                          )}
                        </div>
                        {/* <div>{linkUrlGoogleMaps}</div>      */}
                        {linkUrlGoogleMaps ? (
                          <>
                            <button
                              className="btn-edit-guide"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  linkUrlGoogleMaps
                                );
                              }}
                            >
                              {" "}
                              Copiar Link de Ruta
                            </button>

                            <a
                              className="btn-edit-guide"
                              href={linkUrlGoogleMaps}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Abrir en navegador
                            </a>
                          </>
                        ) : null}

                        <GoogleMap></GoogleMap>

                        {localDataOrdersForAccions.length <= 23 ? (
                          <>
                            {" "}
                            <div></div>
                            <div className="positionSelector"></div>
                            <div>
                              <button
                                className="btn-edit-guide"
                                onClick={crearLinkGoogleLive}
                              >
                                {" "}
                                Generar link
                              </button>
                            </div>
                            <div></div>
                          </>
                        ) : (
                          <div className="textModalContent">
                            {" "}
                            Excede los 25 Puntos, es un limite de paradas de api
                            de Google Maps
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {props.popUpAccion === "Asignacion" ? (
                    <div>
                      <Box
                        sx={{
                          width: "100%",
                          height: 350,
                          backgroundColor: "#fafafa",
                        }}
                      >
                        {ridersRows ? (
                          <DataGrid
                            className="DataGridStyle"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                            rows={ridersRows}
                            columns={columnsRiders}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                      <div>
                        {setectedRider ? (
                          <>
                            <CardHeader
                              avatar={
                                <Avatar
                                  src={setectedRiderAvatar}
                                  sx={{ bgcolor: "#f2f2f2" }}
                                  aria-label="recipe"
                                ></Avatar>
                              }
                              title={setectedRiderName}
                            />
                          </>
                        ) : null}
                      </div>
                      <button
                        className="btn btn-ebiex extentButton"
                        onClick={asignarConductor}
                      >
                        {" "}
                        Asignar conductor{" "}
                      </button>
                    </div>
                  ) : null}
                  {props.popUpAccion === "ExcelFile" ? (
                    <>
                      <Typography
                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                        id="transition-modal-title"
                        variant="h3"
                        component="h3"
                      >
                        Crear {props.popUpAccion} de ruta para Circuit
                      </Typography>
                      <div className="textModalContent">
                        <div className="textModalContent">
                          {" "}
                          Listo para descargar:
                        </div>
                        <div className="textModalContent">
                          {" "}
                          contenido : {localDataOrdersForAccions.length}
                        </div>
                        <button
                          onClick={() =>
                            exporExcelFileCircuitfromOps(
                              localDataOrdersForAccions
                            )
                          }
                          className="btn btn-ebiex m-2 btn-reporte-excel"
                        >
                          {" "}
                          Descargar Ruta
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </Box>
          </Fade>
        ) : (
          <LinearProgress></LinearProgress>
        )}
      </Modal>
    </div>
  );
}
