import * as XLSX from "xlsx";
import Moment from "moment";
import { format } from "date-fns";
import { translateStatesfalabbela } from "../objects/translateStates";

const generarArchivoCargaSimpliRoute = async (data) => {
  console.log(data);

  async function createArchivoCargaSimpliRoute(data) {
    // Realizar una copia profunda de data para no modificar el original
    console.log("Simpliroute", data);
    const newData = JSON.parse(JSON.stringify(data));

    const composSimpliRoute = [
      [
        "Título*   Requerido",
        "Dirección completa*   Requerida",
        "Carga",
        "Hora inicial",
        "Hora final",
        "Tiempo de servicio",
        "Notas",
        "Latitud",
        "Longitud",
        "ID de referencia",
        "Habilidades requeridas",
        "Habilidades opcionales",
        "Persona de contacto",
        "Teléfono de contacto",
        "Hora inicial 2",
        "Hora final 2",
        "Carga 2",
        "Carga 3",
        "Prioridad",
        "SMS",
        "Correo electrónico de contacto",
        "Carga pick",
        "Carga pick 2",
        "Carga pick 3",
        "Fecha programada",
        "Tipo de visita",
        "Repite",
      ],
      ...newData.map((dato) => [
        dato.id,
        dato.toAddress
          ? dato.toAddress
          : `${dato.address}, ${dato.commune2}, ${dato.commune1}`,
        dato.productVolume
          ? dato.productVolume === 0
            ? 10
            : dato.productVolume * 1000
          : dato.width === 0
          ? 10
          : dato.width * dato.height * dato.length * 1000,
        "",
        "",
        3,
        "",
        dato.toAddressLocation?.latitude ? dato.toAddressLocation.latitude : 0,
        dato.toAddressLocation?.longitude
          ? dato.toAddressLocation.longitude
          : 0,
        dato.id,
        "",
        "",
        dato.clientNames ? dato.clientNames : dato.customer_name,
        dato.userPhone ? dato.userPhone : dato.customer_phone,
        "",
        "",
        "",
        "",
        "",
        "",
        dato.clientEmail ? dato.clientEmail : dato.customer_email,
        "",
        "",
        "",
        dato.routeEstimatedDate
          ? Moment(Date.now(dato.routeEstimatedDate)).format("DD-MM-YYYY")
          : Moment(Date.now(dato.createdAt)).format("DD-MM-YYYY"),
        dato.delivery_type ? dato.delivery_type : "HD",
        dato.driverLicensePlate ? dato.driverLicensePlate : "",
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(composSimpliRoute);
    XLSX.utils.book_append_sheet(libro, hoja, "VisitExcel");

    XLSX.writeFile(
      libro,
      `DataReportSimpli${Moment(Date.now()).format("YYYYMMDDhhmmss")}.xlsx`
    );
  }

  await createArchivoCargaSimpliRoute(data);
};

export default generarArchivoCargaSimpliRoute;
