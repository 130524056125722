import React, { useState } from "react";
import { fDate } from "../../../../utils/objects/FormatTime";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  LinearProgress,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import GuiasViewOrders from "./GuiasViewOrders";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ReusableFormImportOpcionalElements from "../../../../components/ReusableFormImportOpcionalElements/ReusableFormImportOpcionalElements";
import { useDispatch, useSelector } from "react-redux";
import { createBicciexpressOrderIssuresDocument } from "../../../../redux/accionesOrders";
const GuiasViewBody = ({
  initDate,
  endDate,
  dataOrders,
  loading,
  dataOrdersPending,
  loadingPendints,
  viewOrder,
  setViewOrder,
  onClikBuscarCambios,
  handleFileProforma,
  handleFileStates,
  handleFileApelacion,
  loadDataPayment,
  loadDataState,
  loadDataApe,
  modulesView,
  exportartReportePagos,
  dataApelaciones,
  donwsLoadRegister,
  ...props
}) => {
  const [value, setValue] = useState(0);
  const [openImport, setOpenImport] = useState(false);
  const dispatch = useDispatch();
  const userUid = useSelector((store) => store.usuario.user.uid);
  const companyId = useSelector((store) => store.usuario.user.company);
  const handleReportClick = () => {
    // Handle the report click action
    console.log("Report button clicked");
  };
  const onButtonClick = () => {
    // Handle the report click action
    console.log("onButtonClick button clicked");
  };

  const formFieldsEdit = [
    { variable: "ID", label: "ID", type: "string", required: true },
    { variable: "ESTADO", label: "ESTADO", type: "string", required: false },
    { variable: "TALLA", label: "TALLA", type: "string", required: true },
    { variable: "ALTO", label: "ALTO", type: "string", required: false },
    { variable: "ANCHO", label: "ANCHO", type: "string", required: false },
    { variable: "LARGO", label: "LARGO", type: "string", required: false },
    { variable: "M3", label: "M3", type: "string", required: false },
  ];

  const handleChange = (event, newValue) => {
    //setValue(newValue);
    switch (newValue) {
      case 0:
        // console.log("/catalogo");
        break;
      case 1:
        // console.log("/tienda/categorias");
        break;
      case 2:
        // console.log(); //navigate("/tienda/my-account");
        break;
      case 3:
        // console.log("/tienda/checkout");
        break;
      default:
        break;
    }
  };

  function descargarDocumentoProforma() {
    const elemento = document.createElement("a");
    elemento.href =
      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FEjemplo%20Documento%20proforma.xlsx?alt=media&token=803dee18-513a-4b5e-8f40-330102415568";
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }

  function descargarDocumentoSSD() {
    const elemento = document.createElement("a");
    elemento.href =
      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FEjemplo%20de%20Carga%20SSD%20Integracion%20Bicci.xlsx?alt=media&token=0630a256-2861-4ea5-a48b-f3a7beda96d7";
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }

  function descargarDocumentoEstados() {
    const elemento = document.createElement("A");
    elemento.href =
      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FEjemplo%20Cierre%20de%20Estados%20Facturacion%20bicciexpresss.xlsx?alt=media&token=08549e06-bdb0-4c95-89ed-28746809b838";
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }

  function descargarDocumentoApelaciones() {
    const elemento = document.createElement("a");
    elemento.href =
      "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FEjemplo%20Apelaciones%20Facturacion%20%20bicciexpresss.xlsx?alt=media&token=64c095b1-c2cc-415d-83f1-52a70bee5811";
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }

  const UploadDocumentsByData = () => {
    console.log("Importador de documentos");
    setOpenImport(true);
  };

  const handleSave = (data) => {
    let type = "billingTicket";
    for (let i = 0; i < data.length; i++) {
      let dataFolder = data[i];
      dispatch(
        createBicciexpressOrderIssuresDocument({
          dataFolder,
          userUid,
          type,
          companyId,
        })
      );
    }
  };

  return (
    <div>
      {/* Seleccione un rango de busqueda
      <div> {fDate(initDate)}</div>
      <div> {fDate(endDate)}</div>
      <div>{dataOrders ? "dataOrders :" + dataOrders.length : "null"}</div>
      <div> {loading ? <LinearProgress>:</LinearProgress> : null}</div>
      <div>
        {dataOrdersPending
          ? "dataOrdersPending :" + dataOrdersPending.length
          : "null"}
      </div> */}
      <Box sx={{ minHeight: "15px", height: "15px" }}>
        {" "}
        {loadingPendints ? <LinearProgress>:</LinearProgress> : null}
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {modulesView === "all" || modulesView === "crearregistro" ? (
          <Card sx={{ minWidth: 245, maxWidth: 245, m: 1 }}>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fbuscar.png?alt=media&token=d00f7972-1869-423a-9326-e6654625456a"
              }
              alt={"busqueda en rango de fecha"}
            />
            <CardContent sx={{ flexGrow: 1, padding: "10px", minHeight: 150 }}>
              <Typography
                sx={{ fontSize: "1.2em", fontWeight: 800, minHeight: "20px" }}
              >
                {dataOrders
                  ? `${dataOrders.length} Registros Encontrados`
                  : null}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {dataOrders
                  ? "Ordenes encontradas, en fecha seleccionada"
                  : "Seleccione un rango de fecha a consultar, las órdenes encontradas se distribuirán en su estado de facturación. Puede consultar periodos de tiempo"}
              </Typography>
            </CardContent>
            <BottomNavigation
              value={value}
              onChange={handleChange}
              showLabels
              sx={{
                mt: "auto", // Añade automáticamente margen superior para empujarlo hacia abajo
                width: "100%",
                backgroundColor: "#101e5a",
                zIndex: 900,
                height: "100px",
              }}
            >
              <BottomNavigationAction
                label="Ver"
                icon={
                  <IconButton
                    onClick={() => setViewOrder(dataOrders)}
                    aria-label="ViewListIcon"
                    sx={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      minWidth: "50px",
                      color: "#F4F4F4",
                      "&.Mui-selected": {
                        color: "#F6F6F6",
                        borderColor: "#F6F6F6",
                      },
                      "&:focus": {
                        outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                      },
                    }}
                  >
                    <ViewListIcon />
                  </IconButton>
                }
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />

              <BottomNavigationAction
                label="Exportar"
                icon={
                  <IconButton
                    onClick={() => exportartReportePagos(dataOrders)}
                    aria-label="ArrowOutwardIcon"
                    sx={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      minWidth: "50px",
                      color: "#F4F4F4",
                      "&.Mui-selected": {
                        color: "#F6F6F6",
                        borderColor: "#F6F6F6",
                      },
                      "&:focus": {
                        outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                      },
                    }}
                  >
                    <ArrowOutwardIcon />
                  </IconButton>
                }
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
              {/* <BottomNavigationAction
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F8F8F8",
                  "&.Mui-selected": {
                    color: "#F8F8F8",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6",
                  },
                }}
                label="Subir Archivo"
                icon={
                  <>
                    <input
                      id="file-upload1"
                      name="file-upload1"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileProforma}
                    />
                    <label htmlFor="file-upload1" style={{ cursor: "pointer" }}>
                      {" "}
                      <UploadFileIcon />
                    </label>
                  </>
                }
              /> */}
              <BottomNavigationAction
                label="Descargar Ejemplo"
                onClick={descargarDocumentoSSD}
                icon={<FileDownloadIcon />}
                sx={{
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
            </BottomNavigation>
          </Card>
        ) : null}

        {modulesView === "all" || modulesView === "facturacion" ? (
          <Card sx={{ minWidth: 245, maxWidth: 245, m: 1 }}>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fpendiente_pago.png?alt=media&token=3fd450c4-cb4b-4037-8fbf-56421e388961"
              }
              alt={
                "Ordenes Pendientes por pago, valide el estado de las ordenes, estan deben poseer un estado finalizado. "
              }
            />
            <CardContent sx={{ flexGrow: 1, padding: "10px", minHeight: 150 }}>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.2em", fontWeight: 800, minHeight: "20px" }}
              >
                {dataOrdersPending
                  ? `${dataOrdersPending.length} Registros pendientes por pago`
                  : null}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {
                  "Órdenes Pendientes por pago, valide el estado de las órdenes, están deben poseer un estado finalizado."
                }
              </Typography>
            </CardContent>
            <BottomNavigation
              value={value}
              onChange={handleChange}
              showLabels
              sx={{
                mt: "auto", // Añade automáticamente margen superior para empujarlo hacia abajo
                width: "100%",
                backgroundColor: "#101e5a",
                zIndex: 900,
                height: "100px",
              }}
            >
              <BottomNavigationAction
                label="Ver"
                icon={
                  <IconButton
                    onClick={() => setViewOrder(dataOrdersPending)}
                    aria-label="viewOrder"
                    sx={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      minWidth: "50px",
                      color: "#F4F4F4",
                      "&.Mui-selected": {
                        color: "#F6F6F6",
                        borderColor: "#F6F6F6",
                      },
                      "&:focus": {
                        outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                      },
                    }}
                  >
                    <ViewListIcon />
                  </IconButton>
                }
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />

              <BottomNavigationAction
                label="Exportar"
                icon={
                  <IconButton
                    onClick={() => exportartReportePagos(dataOrdersPending)}
                    aria-label="Exportar"
                    sx={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      minWidth: "50px",
                      color: "#F4F4F4",
                      "&.Mui-selected": {
                        color: "#F6F6F6",
                        borderColor: "#F6F6F6",
                      },
                      "&:focus": {
                        outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                      },
                    }}
                  >
                    <ArrowOutwardIcon />
                  </IconButton>
                }
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />

              <BottomNavigationAction
                label="Buscar cambios.."
                icon={
                  <IconButton
                    onClick={() => onClikBuscarCambios(dataOrdersPending)}
                    aria-label="Buscar cambios"
                    sx={{
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      minWidth: "50px",
                      color: "#F4F4F4",
                      "&.Mui-selected": {
                        color: "#F6F6F6",
                        borderColor: "#F6F6F6",
                      },
                      "&:focus": {
                        outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                      },
                    }}
                  >
                    <CloudSyncIcon />
                  </IconButton>
                }
                sx={{
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
            </BottomNavigation>
          </Card>
        ) : null}

        {modulesView === "all" || modulesView === "facturacion" ? (
          <Card sx={{ minWidth: 245, maxWidth: 245, m: 1 }}>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fproccess.png?alt=media&token=1333a202-7cf2-4c52-8217-3e8e6d321860"
              }
              alt={"Proforma, documento comercial preliminar, registro de pago"}
            />
            <CardContent sx={{ flexGrow: 1, padding: "10px", minHeight: 150 }}>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.2em", fontWeight: 800, minHeight: "20px" }}
                component="div"
              >
                {"Proformas"}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {"Subir Proforma de pagos. Recuerde ajustar el documento"}
              </Typography>
              <Divider></Divider>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => loadDataPayment()}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon className="btn-add-guide-small" />
              </IconButton>
            </CardContent>

            <BottomNavigation
              value={value}
              onChange={handleChange}
              showLabels
              sx={{
                mt: "auto", // Añade automáticamente margen superior para empujarlo hacia abajo
                width: "100%",
                backgroundColor: "#101e5a",
                zIndex: 900,
                height: "100px",
              }}
            >
              <BottomNavigationAction
                sx={{
                  color: "#F8F8F8",
                  "&.Mui-selected": {
                    color: "#F8F8F8",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6",
                  },
                }}
                label="Subir Archivo" // Esto es opcional, dependiendo si quieres mostrar un texto
                icon={
                  <>
                    <input
                      id="file-upload1"
                      name="file-upload1"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileProforma}
                    />
                    <label htmlFor="file-upload1" style={{ cursor: "pointer" }}>
                      {" "}
                      <UploadFileIcon />
                    </label>
                  </>
                }
              />

              <BottomNavigationAction
                label="Exportar"
                icon={<ArrowOutwardIcon />}
                sx={{
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
              <BottomNavigationAction
                label="Descargar Ejemplo"
                onClick={descargarDocumentoProforma}
                icon={<FileDownloadIcon />}
                sx={{
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
            </BottomNavigation>
          </Card>
        ) : null}

        {modulesView === "all" ||
        modulesView === "devoluciones" ||
        modulesView === "perdidas" ? (
          <Card sx={{ minWidth: 245, maxWidth: 245, m: 1 }}>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fregistodevolucion.png?alt=media&token=35084878-a551-43fa-afd7-1133e0fff4c7"
              }
              alt={"Subir Apeciones de items a proforma"}
            />
            <CardContent sx={{ flexGrow: 1, padding: "10px", minHeight: 150 }}>
              <Typography
                gutterBottom
                sx={{ fontSize: "1.2em", fontWeight: 800, minHeight: "20px" }}
              >
                {"Actualizar estados"}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {
                  "Cierra estados por devoluciones o cobro. Dendro de BCCIEXPRESS." //, esto no afecta integraciones, es registros contables a folios pendientes"
                }
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => loadDataState()}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon className="btn-add-guide-small" />
              </IconButton>
            </CardContent>
            <BottomNavigation
              value={value}
              onChange={handleChange}
              showLabels
              sx={{
                mt: "auto", // Añade automáticamente margen superior para empujarlo hacia abajo
                width: "100%",
                backgroundColor: "#101e5a",
                zIndex: 900,
                height: "100px",
              }}
            >
              {/* <BottomNavigationAction
                label="Ver"
                icon={<ViewListIcon />}
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              /> */}

              <BottomNavigationAction
                sx={{
                  color: "#F8F8F8",
                  "&.Mui-selected": {
                    color: "#F8F8F8",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6",
                  },
                }}
                label="Subir Archivo" // Esto es opcional, dependiendo si quieres mostrar un texto
                icon={
                  <>
                    <input
                      id="file-upload2"
                      name="file-upload2"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileStates}
                    />
                    <label htmlFor="file-upload2" style={{ cursor: "pointer" }}>
                      {" "}
                      <UploadFileIcon />
                    </label>
                  </>
                }
              />

              <BottomNavigationAction
                label="Descargar Ejemplo"
                onClick={descargarDocumentoEstados}
                icon={<FileDownloadIcon />}
                sx={{
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
            </BottomNavigation>
          </Card>
        ) : null}

        {modulesView === "all" || modulesView === "apelaciones" ? (
          <Card sx={{ minWidth: 245, maxWidth: 245, m: 1 }}>
            <CardMedia
              component="img"
              height="140"
              image={
                "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2Fapelaciones.png?alt=media&token=c356edb5-bbb4-4f60-961e-51fca0681766"
              }
              alt={"Apeciones en curso."}
            />
            <CardContent sx={{ flexGrow: 1, padding: "10px", minHeight: 150 }}>
              <Typography
                sx={{ fontSize: "1.2em", fontWeight: 800, minHeight: "20px" }}
              >
                {`${dataApelaciones?.length} Registros de Apelaciones`}
              </Typography>
              <Typography variant="body" color="text.secondary">
                {"Apelaciones, Discrepancias, Casos en seguimiento en curso."}
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => donwsLoadRegister(dataApelaciones)}
                sx={{ color: "#591e8f" }}
              >
                <PlayCircleIcon className="btn-add-guide-small" />
              </IconButton>
            </CardContent>
            <BottomNavigation
              value={value}
              onChange={handleChange}
              showLabels
              sx={{
                mt: "auto", // Añade automáticamente margen superior para empujarlo hacia abajo
                width: "100%",
                backgroundColor: "#101e5a",
                zIndex: 900,
                height: "100px",
              }}
            >
              <BottomNavigationAction
                label="Ver"
                onClick={() => setViewOrder(dataApelaciones)}
                icon={<ViewListIcon />}
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
              {/* <BottomNavigationAction
                sx={{
                  color: "#F8F8F8",
                  "&.Mui-selected": {
                    color: "#F8F8F8",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6",
                  },
                }}
                label="Subir Archivo" // Esto es opcional, dependiendo si quieres mostrar un texto
                icon={
                  <>
                    <input
                      id="file-upload3"
                      name="file-upload3"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileApelacion}
                    />
                    <label htmlFor="file-upload3" style={{ cursor: "pointer" }}>
                      {" "}
                      <UploadFileIcon />
                    </label>
                  </>
                }
              /> */}
              <BottomNavigationAction
                label="Subir documento"
                icon={<UploadFileIcon />}
                onClick={() => UploadDocumentsByData(true)}
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />

              <BottomNavigationAction
                label="Exportar"
                icon={<ArrowOutwardIcon />}
                sx={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  minWidth: "50px",
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
              <BottomNavigationAction
                label="Descargar Ejemplo"
                onClick={descargarDocumentoApelaciones}
                icon={<FileDownloadIcon />}
                sx={{
                  color: "#F4F4F4",
                  "&.Mui-selected": {
                    color: "#F6F6F6",
                    borderColor: "#F6F6F6",
                  },
                  "&:focus": {
                    outline: "2px solid #F6F6F6", // Cambia 'colorDeseado' por el color que quieras
                  },
                }}
              />
            </BottomNavigation>
            <ReusableFormImportOpcionalElements
              open={openImport}
              handleClose={() => setOpenImport(false)}
              formFields={formFieldsEdit}
              handleSave={handleSave}
              type={process.env.REACT_APP_COL_SUPPORT}
              formFieldsEdit={formFieldsEdit}
            />
          </Card>
        ) : null}
      </Box>
      <Divider></Divider>
      <Box>
        {viewOrder && viewOrder.length > 0 ? (
          <GuiasViewOrders
            exportartReport={exportartReportePagos}
            viewOrder={viewOrder}
            setViewOrder={setViewOrder}
          ></GuiasViewOrders>
        ) : null}
      </Box>
    </div>
  );
};

export default GuiasViewBody;
