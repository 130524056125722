import React from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Barcode from "react-barcode";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: "10px",
    border: "1px solid #000",
  },
  barcodeSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 0",
  },
  qrSection: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
  },
  textSection: {
    margin: "1px 0",
  },
  header: {
    fontSize: 14,
    marginBottom: 5,
    textAlign: "center",
  },
  infoText: {
    fontSize: 8,
    margin: "1px 0",
  },
  infoTextCenter: {
    fontSize: 8,
    margin: "1px 0",
    textAlign: "center",
  },
  image: {
    width: 60,
    height: 60,
  },
  imageLogo: {
    width: 120,
    height: 60,
  },
  imageBarcode: {
    width: "100%",
    height: 150,
    textAlign: "center",
  },
  imageBarcodeLpn: {
    width: "100%",
    height: 50,
    textAlign: "center",
  },
});
const DocumentoPDFShippingLabelPrintMultiple = ({ ordersData }) => {
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/bicci-7ed2f.appspot.com/o/webfrontend%2Fweb-bicciexpress%2FLogoBicci01x04.png?alt=media&token=0d36671d-c7df-43e8-97c2-1be128037b00";
  return (
    <Document>
      {ordersData.map(
        ({ order, barcodeImage, qrImage, barcodeImageLpn }, index) => (
          <Page size={[4 * 72, 6 * 72]} style={styles.page} key={index}>
            <View>
              <Text style={styles.header}>BICCI EXPRESS</Text>
              <View style={styles.barcodeSection}>
                <Image src={qrImage} style={styles.image} />
                <Image
                  src={"/static/images/bicciexpress.png"}
                  alt=""
                  style={styles.imageLogo}
                ></Image>
              </View>
              <View>
                <Image src={barcodeImage} style={styles.imageBarcode} />
              </View>
              <Text style={styles.infoTextCenter}> ID: {order.orderID}</Text>
              <Text style={styles.infoText}>
                COD LPN:{" "}
                {order.int_lpn && order.int_lpn !== "isRequire"
                  ? order.int_lpn
                  : "N/A"}
              </Text>
              <Text style={styles.infoText}>
                COD F12:{" "}
                {order.int_f12 && order.int_f12 !== "isRequire"
                  ? order.int_f12
                  : "N/A"}
              </Text>
              <Text style={styles.infoText}>Cliente: {order.clientNames}</Text>
              <Text style={styles.infoText}>Teléfono: {order.userPhone}</Text>
              <Text style={styles.infoText}>Dirección: </Text>
              <Text style={styles.infoText}>{order.toAddress}</Text>
              <Text style={styles.infoText}>Dirección 1: {order.commune2}</Text>
              <Text style={styles.infoText}>Dirección 2: {order.commune}</Text>
              <Text style={styles.infoText}>TIPO: {order.int_serviceType}</Text>
              <Text style={styles.infoText}>
                Fecha de viaje {order.int_travelDate}
              </Text>
              <View>
                {barcodeImageLpn !== "" ? (
                  <Image src={barcodeImageLpn} style={styles.imageBarcodeLpn} />
                ) : null}
              </View>
              <Text style={styles.infoTextCenter}>LPN</Text>
            </View>
          </Page>
        )
      )}
    </Document>
  );
};

export default DocumentoPDFShippingLabelPrintMultiple;
