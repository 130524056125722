import React, { useEffect, useState, useRef } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import * as XLSX from "xlsx";
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { green } from "@mui/material/colors";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Grid,
  LinearProgress,
  Switch,
  Typography,
  SvgIcon,
  Tooltip,
  CircularProgress,
  Chip,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import MapIcon from "@mui/icons-material/Map";
import RouteIcon from "@mui/icons-material/Route";
import { registerPickerActions } from "../../redux/accionesOrders";
import { MapVectoriales } from "../../utils/Polygons/VectorialSatelite";
import DownloadIcon from "@mui/icons-material/Download";
import { list } from "firebase/storage";
import { addDoc } from "firebase/firestore";
import { updateDoc } from "firebase/firestore";

const ScannerDistributionV2 = (props) => {
  const dispacth = useDispatch();
  const [botonSeleccionado, setBotonSeleccionado] = useState(null);
  //const [currentUser, setCurrentUser] = useState(props.usuarioStorage.uid);
  const [barcodeDataImput, setBarcodeDataInput] = useState("");
  const [serahrCodeDataImput, setSearchBarcodeDataInput] = useState("");
  const [waitingForScan, setWaitingForScan] = useState(false);
  const [dataSearch, setDataSearch] = React.useState([]);
  const [inputDataMethod, setInputDataMethod] = React.useState(true);
  const [mute, setMute] = React.useState(true);
  const [muteDriver, setMuteDriver] = React.useState(true);
  const [muteRoute, setMuteRoute] = React.useState(true);
  const [muteZone, setMuteZone] = React.useState(true);
  const synth = window.speechSynthesis;
  const miColeccionRef = collection(db, process.env.REACT_APP_COL_ORDERS);
  const [progressValue, setProgressValue] = React.useState(0);

  const [orderList, setOrderList] = useState([]);
  const [listId, setListId] = useState("");
  const [localSize, setLocalSize] = React.useState({
    productSize: "",
    productHeight: "",
    productLength: "",
    productWidth: "",
  });
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const inputRef = useRef(null);
  const miCampo = "key";

  const collRegidterRef = collection(
    db,
    process.env.REACT_APP_COL_PICKER_REGISTER
  );

  const input1Ref = useRef(null);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses son 0-indexados
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}${month}${day}`;
    console.log(formattedDate);
    setListId(formattedDate);
  }, []);

  useEffect(() => {
    if (listId) {
      loadInitialOrders(listId);
    }
  }, [listId]);

  useEffect(() => {
    if (serahrCodeDataImput.length > 3 && listId) {
      findOrdesrDispachtScanner(serahrCodeDataImput, listId);
    }
  }, [serahrCodeDataImput]);

  useEffect(() => {
    if (dataSearch) {
      if (!mute) {
        if (!muteDriver) {
          dataSearch.driverName && dataSearch.driverName !== "isRequire"
            ? speakText(dataSearch.driverName)
            : speakText("No Asignado");
        }

        if (!muteRoute) {
          dataSearch.driverLicensePlate &&
          dataSearch.driverLicensePlate !== "isRequire"
            ? speakText(`Vehiculo ${dataSearch.driverLicensePlate}`)
            : speakText("Sin Vehiculo");
        }
      }
    }
  }, []);
  useEffect(() => {
    // console.log("----------------")
    // console.log(barcodeDataImput)
    // console.log(serahrCodeDataImput)
    // console.log(serachResults)
    // console.log(dataSearch.toAddressLocation.latitude);
    // console.log(dataSearch.toAddressLocation.longitude);
    // console.log(MapVectoriales);
    function isPointInPolygon(point, polygon) {
      var x = point[0],
        y = point[1];
      var inside = false;
      for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0],
          yi = polygon[i][1];
        var xj = polygon[j][0],
          yj = polygon[j][1];

        var intersect =
          yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    }

    function findPolygonForPoint(point, geoJson) {
      for (var feature of geoJson.features) {
        if (isPointInPolygon(point, feature.geometry.coordinates[0])) {
          return feature.properties.Name;
        }
      }
      return null; // No contiene ningún polígono
    }

    var geoJson = MapVectoriales;

    if (dataSearch) {
      setBotonSeleccionado(dataSearch.productSize);
      setLocalSize({
        ...localSize,
        productHeight: dataSearch.productHeight,
        productLength: dataSearch.productLength,
        productWidth: dataSearch.productWidth,
        productSize: dataSearch.productSize,
        productVolume: dataSearch.productVolume,
      });
      if (props.usuarioStorage) {
        dispacth(registerPickerActions(dataSearch, props.usuarioStorage));
      }
    }
    if (dataSearch) {
      if (dataSearch.toAddressLocation) {
        var point = [
          dataSearch.toAddressLocation.longitude
            ? dataSearch.toAddressLocation.longitude
            : 0,
          dataSearch.toAddressLocation.latitude
            ? dataSearch.toAddressLocation.latitude
            : 0,
        ];
        var polygonName = findPolygonForPoint(point, geoJson);
        console.log(polygonName);
        if (!muteZone) {
          speakText(`ruta ${polygonName}`);
        }

        dataSearch.BicciZone = polygonName;

        let updateDocData = {
          //   id: idWithF,
          orderID: dataSearch.id,
          sobrante: false,
          listId: listId,
          deleted: false,
          BicciZone: dataSearch.BicciZone ? dataSearch.BicciZone : "",
          date: new Date(),
        };
        // llamar a funcion que no retrase el flujo de la interfaz
        console.log(updateDocData);
        updateTodayPicking(updateDocData);

        if (props.usuarioStorage) {
          let data = { id: serahrCodeDataImput };
        }
      }
    }
  }, [dataSearch]);

  useEffect(() => {
    // console.log(localSize);
  }, [localSize]);

  useEffect(() => {
    // Enfocar el campo de entrada al cargar la página
    setWaitingForScan(true);
    input1Ref.current.focus();
    // Agregar un manejador de eventos para hacer clic en el cuerpo
    document.body.addEventListener("click", handleBodyClick);

    // Limpia el manejador de eventos al desmontar el componente
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleFocus = () => {
    setWaitingForScan(true);
  };

  const handleBlur = () => {
    setWaitingForScan(false);
  };

  const findOrdesrDispachtScanner = async (serahrCodeDataImput, listId) => {
    setDataSearch(null);
    const collections = [
      process.env.REACT_APP_COL_USERS_ORDERS,
      process.env.REACT_APP_COL_ORDERS,
      process.env.REACT_APP_COL_FALABELLA_ORDERS,
    ];

    let idWithF = "F" + serahrCodeDataImput;

    const tryGetDoc = async (collection, id) => {
      const orderRef = doc(db, collection, id);
      const docSnapshot = await getDoc(orderRef);
      return docSnapshot.exists() ? docSnapshot.data() : null;
    };

    const tryQueryByAttributes = async (collectionName, attribute, value) => {
      const colRef = collection(db, collectionName);
      const q = query(colRef, where(attribute, "==", value));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        return querySnapshot.docs[0].data();
      }
      return null;
    };

    try {
      //149124187276
      //  console.log("set NULL")
      //setDataSearch(null);

      setProgressValue(10);
      let foundDocumentData = null;

      for (let collection of collections) {
        let documentData;

        documentData = await tryGetDoc(collection, idWithF);
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(20);

        documentData = await tryGetDoc(collection, serahrCodeDataImput);
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(30);

        documentData = await tryQueryByAttributes(
          collection,
          "id",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(40);

        documentData = await tryQueryByAttributes(
          collection,
          "int_client_soc",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        documentData = await tryQueryByAttributes(
          collection,
          "int_f12",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        documentData = await tryQueryByAttributes(
          collection,
          "int_lpn",
          serahrCodeDataImput
        );
        if (documentData) {
          foundDocumentData = documentData;
          break;
        }
        setProgressValue(40);

        // ... (rest of your code for other attribute checks)

        if (foundDocumentData) {
          break;
        }
      }

      if (foundDocumentData) {
        setDataSearch(foundDocumentData);
        console.log(foundDocumentData);
        console.log(listId);
        //serahrCodeDataImput

        setProgressValue(100);
      } else {
        // No se encontró el documento en ninguna colección ni por los atributos específicos.
        let updateDocData = {
          //   id: idWithF,
          orderID: idWithF,
          sobrante: true,
          listId: listId,
          deleted: false,
          BicciZone: "Data no registrada",
          date: new Date(),
        };
        setDataSearch(updateDocData);
        // llamar a funcion que no retrase el flujo de la interfaz
        updateTodayPicking(updateDocData);
        if (!muteZone) {
          speakText(`Sin Data, Sobrante registrado`);
        }

        setProgressValue(100);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateTodayPicking = async (updateDocData) => {
    console.log("updateTodayPicking");
    try {
      const docRef = await addDoc(collRegidterRef, updateDocData);
      await setDoc(
        docRef,
        { ...updateDocData, id: docRef.id, BicciZone: updateDocData.BicciZone },
        { merge: true }
      ); // Actualiza el documento con el ID
      setOrderList((prevOrderList) => {
        const updatedOrderList = [
          { id: docRef.id, ...updateDocData },
          ...prevOrderList,
        ];
        const sortedOrderList = updatedOrderList.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        return sortedOrderList;
      });

      const orderRef = doc(
        db,
        process.env.REACT_APP_COL_USERS_ORDERS,
        updateDocData.orderID
      );
      const docSnapshot = await getDoc(orderRef);
      //console.log(companyIdOrder);
      if (docSnapshot.exists()) {
        try {
          await updateDoc(orderRef, { isPickedUp: true });
          //console.log("Documento BICCI Actualizado:", updateDocData.orderID);
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 140111000003088807

  const loadInitialOrders = async (listId) => {
    const q = query(
      collRegidterRef,
      where("listId", "==", listId),
      where("deleted", "==", false)
    );
    const querySnapshot = await getDocs(q);
    const orders = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id, // Añadiendo la propiedad key con el valor de documentName
        ...data,
      };
    });

    console.log(orders);
    const sortedOrders = orders.sort((a, b) => b.date - a.date);

    setOrderList(sortedOrders);
  };

  const handleDeleteOrder = async (id) => {
    console.log(id);

    try {
      let docReft = doc(collRegidterRef, id);
      await updateDoc(docReft, { deleted: true });
      setOrderList((prevOrderList) =>
        prevOrderList.filter((order) => order.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const listenscanner = () => {
    input1Ref.current.focus();
  };

  const handleBodyClick = (e) => {
    // Verifica si el clic se realizó fuera del campo de entrada
    if (!input1Ref.current.contains(e.target)) {
      // Si el clic fue fuera del campo, vuelve a enfocar el campo de entrada
      input1Ref.current.focus();
    }
  };
  const typingTimer = useRef(null);
  const doneTypingInterval = 700; // tiempo en milisegundos para esperar después de la última entrada

  const handleBarcodeScan = (data) => {
    //console.log(data);
    setBarcodeDataInput(data);

    clearTimeout(typingTimer.current); // Reinicia el temporizador cada vez que se detecta una nueva entrada
    typingTimer.current = setTimeout(() => {
      setSearchBarcodeDataInput(data); // Solo se ejecuta si no hay nuevas entradas después de 500ms
    }, doneTypingInterval);

    if (inputDataMethod === true) {
      setTimeout(() => {
        //console.log("Procesando...");
        setBarcodeDataInput("");
        input1Ref.current.focus();
      }, 500);
    }
  };

  const handleChange = (event) => {
    setInputDataMethod(event.target.checked);
    setBarcodeDataInput("");
    setSearchBarcodeDataInput("");
  };

  const speakText = (text) => {
    if (synth) {
      const utterance = new SpeechSynthesisUtterance(text);
      synth.speak(utterance);
    }
  };
  const handleClikDowLoad = () => {
    console.log("descargar reporte");
    const matrizReporte = [
      ["Código", "sobrante", "fecha"],
      ...orderList.map((dato) => [dato.orderID, dato.sobrante, dato.listId]),
    ];
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(libro, `Picking ${listId}.xlsx`);
  };

  return (
    <Grid sx={{ width: "100%", flex: "row" }}>
      <Grid sx={{ width: "100%", backgroundColor: "#f8f8f8", display: "flex" }}>
        <Box
          sx={{
            objectFit: "contain",
            width: "30%",
            height: "100%",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          <Card
            sx={{
              maxWidth: 900,
              height: "90%",
              maxHeight: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2px",
              marginBottom: "5%",
            }}
          >
            {" "}
            <CardHeader
              title={
                <Typography variant={"h5"}>
                  {" "}
                  Folios escaneados hoy : {orderList.length}
                </Typography>
              }
              subheader={
                <div>
                  <IconButton
                    onClick={() => handleClikDowLoad()}
                    style={{ m: 1 }}
                    sx={{ fontSize: "13px", borderRadius: "3px" }}
                  >
                    <SvgIcon style={{ fontSize: 18 }}>
                      <DownloadIcon />
                    </SvgIcon>

                    <Typography variant="body">Descargar reporte</Typography>
                  </IconButton>
                </div>
              }
            ></CardHeader>
            <CardContent
              sx={{
                padding: "1px",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {orderList ? (
                <>
                  {orderList.map((item) => (
                    <Card
                      key={item.id}
                      sx={{
                        marginTop: "10px",
                        padding: "10px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <span className="bigText">{item.orderID}</span>
                        </Box>
                        <Box>
                          {" "}
                          <IconButton
                            sx={{ ml: 1, color: "red" }}
                            onClick={() => handleDeleteOrder(item.id)}
                          >
                            x
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {item.sobrante == false ? (
                          <Chip
                            label="verificado"
                            variant="outlined"
                            style={{
                              m: "1px",
                              color: "#F8F8F8",
                              backgroundColor: "green",
                            }}
                          />
                        ) : (
                          <Chip
                            label="Sobrante"
                            variant="outlined"
                            style={{
                              color: "#F8F8F8",
                              backgroundColor: "red",
                            }}
                          />
                        )}
                        | Ruta :
                        {item.BicciZone ? (
                          <Typography sx={{ fontSize: "1em", fontWeight: 900 }}>
                            {item.BicciZone}
                          </Typography>
                        ) : (
                          " Sin data para validación"
                        )}
                      </Box>
                    </Card>
                  ))}
                </>
              ) : (
                <CircularProgress></CircularProgress>
              )}
            </CardContent>
          </Card>
        </Box>
        <Box
          alt="bicciexpress"
          sx={{
            objectFit: "contain",
            width: "70%",
            height: "100%",
            //backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
            //backgroundSize: "100% auto", // Escala horizontal completa
            //backgroundRepeat: "no-repeat", // No se repite
            // backgroundColor: "#e58888", //"#aef3c3",
            padding: "1em",
            marginBottom: "0px",
          }}
        >
          <Card
            sx={{
              maxWidth: 900,
              height: "90%",
              maxHeight: "90%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2px",
              marginBottom: "5%",
            }}
          >
            <CardHeader
              title={
                <Typography sx={{ fontSize: "2em", fontWeight: 600 }}>
                  {" "}
                  Escáner de Distribución V2{" "}
                </Typography>
              }
              subheader={
                <div>
                  <Switch
                    checked={inputDataMethod}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: waitingForScan ? "#aef3c3" : "#e58888",
                    }}
                  />
                  <Typography
                    fontFamily="Lato, Arial, Helvetica, sans-serif"
                    color="textPrimary"
                    variant="body"
                  >
                    {inputDataMethod ? (
                      <span>Escaner seleccionado</span>
                    ) : (
                      <span>Teclado seleccionado</span>
                    )}
                  </Typography>
                </div>
              }
              sx={{ padding: "1px", margin: "0px" }}
            />
            {/* <CardMedia
          component="img"
          height="100"
          image="/static/images/asset/entradateclado.png"
          alt="bicciexpress"
          sx={{
            objectFit: "cover",
            width: "100%",
            height: "50%",
            backgroundColor: "#aef3c3",
          }}
        /> */}
            <CardContent sx={{ padding: "1px" }}>
              <Box
                sx={{
                  objectFit: "contain",
                  width: "100%",
                  height: "99%",
                  //   backgroundImage: `url('/static/images/asset/escanner.png')`, //`url('/static/images/asset/entradateclado.png')`,
                  //   backgroundSize: "100% auto", // Escala horizontal completa
                  //   backgroundRepeat: "no-repeat", // No se repite
                  backgroundColor: waitingForScan ? "#aef3c3" : "#f1eeee", //,
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: "space-between",
                    padding: "1px",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "30%",
                      },

                      overflowY: "auto",
                      maxHeight: "100vh",
                      padding: "2px",
                      maxWidth: "100%",
                    }}
                  >
                    <AppBar
                      position="static"
                      sx={{
                        color: "#8F8F8",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "100%",
                          lg: "100%",
                        },
                        backgroundColor: "#e3e3e3",
                        overflowY: "auto",
                        maxHeight: "100vh",
                      }}
                    >
                      <Toolbar variant="dense" disableGutters>
                        {/* Título */}
                        <Typography
                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                          color="textPrimary"
                          variant="body"
                          sx={{
                            fontSize: {
                              xs: "1rem",
                              sm: "1rem",
                              md: "1rem",
                              lg: "1rem",
                            },
                            flexGrow: 1,
                            color: "#591e8f",
                          }}
                        >
                          Escáner de distribución
                        </Typography>

                        {/* Avatar e ícono de estado */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: 2,
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={
                              inputDataMethod
                                ? "/static/images/asset/escanner.png"
                                : "/static/images/asset/teclado.png"
                            }
                            sx={{
                              backgroundColor: waitingForScan
                                ? "#aef3c3"
                                : "#f1eeee",
                              height: 50,
                              width: 50,
                              marginRight: 1,
                            }}
                          />
                          {waitingForScan ? (
                            <ChangeCircleIcon
                              sx={{ color: green[500], marginRight: 1 }}
                            />
                          ) : (
                            <CachedIcon
                              sx={{ color: "#591e8f", marginRight: 1 }}
                            />
                          )}
                          <Typography variant="body2" sx={{ color: "#591e8f" }}>
                            {waitingForScan ? "On" : "Off"}
                          </Typography>
                        </Box>

                        {/* Íconos de sonido y configuración */}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => setMute(!mute)}
                            sx={{ color: "#591e8f", marginRight: 1 }}
                          >
                            {!mute ? (
                              <VolumeUpIcon sx={{ color: green[500] }} />
                            ) : (
                              <VolumeOffIcon sx={{ color: "#591e8f" }} />
                            )}
                          </IconButton>

                          <IconButton
                            size="large"
                            aria-label="mute driver"
                            onClick={() => setMuteDriver(!muteDriver)}
                            sx={{ color: "#591e8f", marginRight: 1 }}
                          >
                            <DirectionsBikeIcon
                              sx={{
                                color: !muteDriver ? green[500] : "#4f4f4f",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            size="large"
                            aria-label="mute zone"
                            onClick={() => setMuteZone(!muteZone)}
                            sx={{ color: "#591e8f", marginRight: 1 }}
                          >
                            <MapIcon
                              sx={{ color: !muteZone ? green[500] : "#4f4f4f" }}
                            />
                          </IconButton>

                          <IconButton
                            size="large"
                            aria-label="mute route"
                            onClick={() => setMuteRoute(!muteRoute)}
                            sx={{ color: "#591e8f" }}
                          >
                            <RouteIcon
                              sx={{
                                color: !muteRoute ? green[500] : "#4f4f4f",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Toolbar>
                    </AppBar>

                    <Card
                      style={{
                        margin: "2px",
                      }}
                    >
                      <div
                        style={{
                          margin: "2px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productHeight}
                        </Typography>
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productLength}
                        </Typography>
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productWidth}
                        </Typography>
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {localSize.productVolume
                            ? `Vol: ${localSize.productVolume}`
                            : null}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "70%",
                        padding: "1px",
                      },
                      height: "100%",
                      bottom: "0px",
                    }}
                  >
                    <Typography
                      fontFamily="Lato, Arial, Helvetica, sans-serif"
                      color="textPrimary"
                      variant="body"
                    >
                      {waitingForScan ? (
                        <div style={{ padding: "5px" }}>
                          Esperando{" "}
                          {inputDataMethod
                            ? "lecturas por escanner..."
                            : "digitación de codigo..."}{" "}
                        </div>
                      ) : (
                        <div
                          style={{
                            padding: "5px",
                            backgroundColor: "red", //"#e58888",
                            color: "#fff",
                          }}
                        >
                          {inputDataMethod
                            ? "Mantenga la pagina activa para escanear..."
                            : "Mantenga la pagina activa para buscar..."}
                        </div>
                      )}
                    </Typography>
                    <LinearProgress
                      value={progressValue}
                      variant="determinate"
                    ></LinearProgress>
                    <div className="searchtiketcontainers">
                      <Card
                        sx={{
                          maxWidth: "450px",
                          width: "100%",
                          marginLeft: "auto",
                          marginRight: "auto",
                          padding: "1px",
                          textAlign: "center",
                        }}
                      >
                        <div className="inputcode">
                          <input
                            type="text"
                            id="codigoBarras"
                            className={
                              inputDataMethod
                                ? "barcode-input"
                                : "barcode-input-keyboar"
                            }
                            ref={input1Ref}
                            autoFocus
                            placeholder={
                              inputDataMethod
                                ? "|||||||| ||| |||| |||||"
                                : "Digite el codígo abuscar"
                            }
                            value={barcodeDataImput}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={(e) => handleBarcodeScan(e.target.value)}

                            //   sx={{
                            //     ...styles.inputBase,
                            //     "::before": {
                            //       ...styles.beforeContent,
                            //     },
                            //   }}
                          />

                          {/* Resto de tu interfaz y lógica aquí */}
                        </div>
                        {serahrCodeDataImput ? (
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            variant="h2"
                          >
                            {serahrCodeDataImput}
                          </Typography>
                        ) : null}
                      </Card>

                      {dataSearch ? (
                        <div className="tiketcontainers">
                          <div>
                            {dataSearch &&
                            dataSearch.length !== 0 &&
                            !dataSearch.sobrante ? (
                              <div className="tiketcontainers">
                                <Card
                                  className="m-4 b-1"
                                  key={dataSearch.id}
                                  sx={{
                                    boxShadow: "#F8F8F8",
                                  }}
                                >
                                  <CardHeader
                                    title={
                                      <div>
                                        <div>
                                          {dataSearch.dataOrigin === "SDDATA"
                                            ? "Same Day ( SD )"
                                            : "Home Delivery ( HD )"}
                                        </div>
                                        <div>{dataSearch.id}</div>
                                      </div>
                                    }
                                  />
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      {dataSearch.int_alert ? (
                                        <Typography
                                          fontFamily="Lato, Arial, Helvetica, sans-serif"
                                          color="textPrimary"
                                          variant="h4"
                                          sx={{
                                            padding: "2px",
                                          }}
                                        >
                                          <span className="alertRed bigText">
                                            DISCREPANCIA REGISTRADA
                                          </span>
                                        </Typography>
                                      ) : null}
                                      <Typography>RIDER</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="body"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div>
                                          {!dataSearch.driverName ||
                                          dataSearch.driverName ===
                                            "isRequire" ||
                                          dataSearch.driverName === "" ? (
                                            <span className="alertRed bigText">
                                              No asignado
                                            </span>
                                          ) : (
                                            <span className="alertcolor ">
                                              {dataSearch.driverName}
                                            </span>
                                          )}
                                        </div>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        spacing={3}
                                      >
                                        <Grid item xs>
                                          <Box>
                                            <Typography>
                                              VEHÍCULO SIMPLI
                                            </Typography>
                                            <Typography
                                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                                              color="textPrimary"
                                              variant="h4"
                                              sx={{
                                                padding: "2px",
                                              }}
                                            >
                                              <div>
                                                {!dataSearch.driverLicensePlate ||
                                                dataSearch.driverLicensePlate ===
                                                  "isRequire" ||
                                                dataSearch.driverLicensePlate ===
                                                  "null" ? (
                                                  <span className="alertRed bigText">
                                                    -
                                                  </span>
                                                ) : (
                                                  <span className="alertcolor bigText">
                                                    {
                                                      dataSearch.driverLicensePlate
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                            </Typography>
                                          </Box>
                                        </Grid>
                                        <Grid item xs>
                                          <Box>
                                            <Typography>
                                              Ruta / ZONA BICCI
                                            </Typography>
                                            <Typography
                                              fontFamily="Lato, Arial, Helvetica, sans-serif"
                                              color="textPrimary"
                                              variant="h4"
                                              sx={{
                                                padding: "2px",
                                              }}
                                            >
                                              <div>
                                                {dataSearch.BicciZone
                                                  ? dataSearch.BicciZone
                                                  : "No se Encuentra georeferencia"}
                                              </div>
                                            </Typography>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Typography>DESTINO:</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="h4"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div>
                                          {dataSearch.routeLocality
                                            ? dataSearch.routeLocality
                                            : dataSearch.toAddress}
                                        </div>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                  <Card
                                    variant="solid"
                                    sx={{
                                      backgroundColor: "#F8F8F8",
                                      padding: "2px",
                                      margin: "2px",
                                    }}
                                  >
                                    <CardContent
                                      sx={{
                                        backgroundColor: "#F8F8F8",
                                        padding: "2px",
                                      }}
                                    >
                                      <Typography>PUNTO RUTA</Typography>
                                      <Typography
                                        fontFamily="Lato, Arial, Helvetica, sans-serif"
                                        color="textPrimary"
                                        variant="h4"
                                        sx={{
                                          padding: "2px",
                                        }}
                                      >
                                        <div> {dataSearch.orderNumber}</div>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </Card>
                              </div>
                            ) : (
                              <Card
                                sx={{
                                  marginTop: "50px",
                                  padding: "20px",
                                  marginLeft: "20px",
                                  marginRight: "20px",
                                }}
                              >
                                <div>
                                  <span className="bigText">
                                    Sin resultados, ingrese nuevos datos para
                                    iniciar la busqueda.
                                  </span>
                                </div>
                                {dataSearch.sobrante ? (
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "red" }}
                                  >
                                    "SOBRANTE REGISTRADO"
                                  </Typography>
                                ) : null}
                              </Card>
                            )}{" "}
                          </div>
                        </div>
                      ) : (
                        <Card
                          sx={{
                            marginTop: "50px",
                            padding: "20px",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <div className="bigText"> "buscando..."</div>
                        </Card>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>{" "}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScannerDistributionV2;
