import React from "react";
import { useEffect } from "react";
import { Container, Form, Input } from "semantic-ui-react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tooltip, Typography, IconButton } from "@mui/material";
import useGetRoutesExpress from "../../../hooks/getRoutesExpress";
import useObtenerDatosdeCliente from "../../../hooks/obtenerDatosdeCliente";
import Moment from "moment";
import { db } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const PlannerManagerRoutes = (props) => {
  const navigate = useNavigate();
  const usuarioStorage = JSON.parse(localStorage.getItem("usuario"));
  const usuarioLocal = usuarioStorage.uid;
  const LocalRol = usuarioStorage.roll;

  //let companyId = null
  const [companyId, setCompanyId] = React.useState(null);
  const [userRoll, setUserRoll] = React.useState(null);
  const [routeDate, setRouteDate] = React.useState("");
  const [userRider, setUserRiderr] = React.useState("");
  const [userVehicle, setUserVehicle] = React.useState("");
  const [routeRows, setRouteRows] = React.useState(null);
  const { userdata } = useObtenerDatosdeCliente(usuarioLocal);

  const {
    compayRoutesExpress,
    bicciRoutesExpress,
    loadRoutesExpress,
    errorRoutesExpress,
  } = useGetRoutesExpress(
    companyId,
    userRoll,
    routeDate,
    userRider,
    userVehicle
  );

  const columns = [
    {
      field: "vehicle",
      headerName: "Veículo",
      width: 120,
      editable: true,
    },
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "userRiders",
      headerName: "Usuario Rider",
      width: 120,
      editable: true,
    },
    {
      field: "dicpachts",
      headerName: "Despachos",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 50,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "dicpachtsDate",
      headerName: "Fecha de despacho",
      width: 120,
      editable: true,
    },
    {
      field: "dicpachtsHour",
      headerName: "Hora de inicio",
      width: 100,
      editable: true,
    },
    {
      field: "createDate",
      headerName: "Fecha de creacion",
      width: 150,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 400,
      renderCell: (routeRows) => {
        // // console.log(arrayelement)
        // var id = arrayelement.row.id
        return (
          <>
            <button
              className="btn-crearplan-ruta m-2"
              variant="contained"
              color="primary"
              size="small"
              id={routeRows.id}
              style={{ marginLeft: 5 }}
              onClick={(e) => handleGoProps(e.target.id)}
            >
              EDITAR DETALLES
            </button>
            <button
              className="btn-crearplan-ruta m-2"
              variant="contained"
              color="primary"
              size="small"
              id={routeRows.id}
              style={{ marginLeft: 5 }}
              onClick={(e) => handleGoDispatches(e.target.id)}
            >
              GESTIÓN DE RUTA
            </button>

            <button
              className="btn-clearplan-ruta m-2"
              variant="contained"
              color="primary"
              size="small"
              id={routeRows.id}
              style={{ marginLeft: 5 }}
              onClick={handleeletesPlan}
            >
              Eliminar
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (userdata) {
      setCompanyId(userdata.userCompanyID);
      setUserRoll(LocalRol);
    }
  }, [userdata]);

  useEffect(() => {
    getBicciRoutesExpressData();
  }, [bicciRoutesExpress]);

  useEffect(() => {}, [compayRoutesExpress]);

  const getBicciRoutesExpressData = () => {
    if (bicciRoutesExpress) {
      console.log(bicciRoutesExpress);

      let bicciRoutesExpressNoDeletted = bicciRoutesExpress.filter(
        (item) => item.isDelete !== true
      );

      let bicciRoutesExpressfinishing = bicciRoutesExpressNoDeletted.filter(
        (item) => item.finishing !== true
      );

      let bicciRoutesExpressNoActives = bicciRoutesExpressfinishing.filter(
        (item) => item.isActive !== false
      );

      var rows = [];

      // for (let i = 0; i < bicciRoutesExpressNoActives.length; i++) {
      //   rows.push({
      //     id: bicciRoutesExpressNoActives[i].id,
      //     vehicle: bicciRoutesExpressNoActives[i].vehicleID,
      //     userRiders: bicciRoutesExpressNoActives[i].driverName,
      //     dicpachts: Object.entries(
      //       bicciRoutesExpressNoActives[i].currentOrderList
      //     ).length,
      //     dicpachtsDate: Moment(
      //       bicciRoutesExpressNoActives[i].dateDeliveryText
      //     ).format("DD MMMM YYYY, h:mm:ss a"),
      //     dicpachtsHour:
      //       bicciRoutesExpressNoActives[i].startTimeDelivery + " hr",
      //     createDate: Moment(bicciRoutesExpressNoActives[i].createDate).format(
      //       "DD MMMM YYYY"
      //     ),
      //   });
      // }
      setRouteRows(rows);
    } else {
      // console.log('Sin data')
    }
  };
  function handleClickNew(e) {
    navigate("/dashboard/routes/new");
  }

  const handleGoProps = (eID) => {
    navigate(`/dashboard/route/${eID}/edit`);
  };
  const handleGoDispatches = (eID) => {
    navigate(`/dashboard/route/${eID}/dispatches`);
  };

  const handleeletesPlan = async (e) => {
    console.log(e.target.id);
    let id = e.target.id;
    try {
      await updateDoc(
        doc(db, `${process.env.REACT_APP_COL_ROUTES}`, id.toString()),
        {
          isDelete: true,
          isActive: false,
        }
      );
    } catch (error) {
      console.log(error);
    }
    getBicciRoutesExpressData();
  };

  const filtrarRutas = (e) => {
    e.preventDefault();
    // console.log('Buscar')
  };

  function checkboxSelection(e) {
    // console.log(e.targat)
  }

  function handleClick() {
    // console.log('handleClick')
  }

  return (
    <div>
      <Container className="containerroutesdiv">
        <h2 className="h2-withe">RUTAS ACTIVAS </h2>

        <div>
          <Box
            className="containerroutesdivbox"
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "#fafafa",

              p: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "space-between",
              }}
            >
              <Tooltip title="Nueva Planeación">
                <IconButton
                  onClick={(e) => handleClickNew(e.target.id)}
                  size="small"
                  sx={{ ml: 2, color: "#008000" }}
                >
                  <AddCircleIcon sx={{ width: 15, height: 15 }}></AddCircleIcon>
                  Cear nueva ruta
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box
            sx={{
              height: 750,
              width: "100%",
              backgroundColor: "#fafafa",
            }}
          >
            {routeRows ? (
              <DataGrid
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                rows={routeRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                onClick={checkboxSelection}
              />
            ) : (
              <></>
            )}
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default PlannerManagerRoutes;
