import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import mapStyleMinimap from "../MapsGoogle/mapStyleMinimap";
import { useLoadScript } from "@react-google-maps/api";
import { useEffect } from "react";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import ReactDOMServer from "react-dom/server";
const libraries = ["places", "geometry"];

const options = {
  styles: mapStyleMinimap,
  fullscreenControl: true,
  disableDefaultUI: true,
  zoomControl: true,
  zoom: 18,
};

const mapContainerStyle = {
  height: "350px",
  width: "350px",
};

export default function MapDeliveryPosition(props) {
  console.log(props);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const [markersPending, setMarkersPending] = React.useState(null);
  const [markerDelivery, setMarkerDelivery] = React.useState(null);
  const [markerRder, setMarkerRider] = React.useState(null);
  const [center, setCenter] = React.useState(null);

  const mapRef = React.useRef();
  const google = window.google;

  useEffect(() => {
    if (
      props &&
      props.markersPending &&
      props.markersPending.fromAddressLocation
    ) {
      setMarkersPending(props.markersPending);
      setCenter({
        lat: props.markersPending.toAddressLocation.latitude
          ? props.markersPending.toAddressLocation.latitude
          : props.markersPending.toAddressLocation.lat,
        lng: props.markersPending.toAddressLocation.longitude
          ? props.markersPending.toAddressLocation.longitude
          : props.markersPending.toAddressLocation.lng,
      });

      if (props.markersPending.status !== 8) {
        if (props.riderDestails && props.riderDestails.online === true) {
          setMarkerRider(props.riderDestails.driverLocation);
        }
      }
    }
    if (props && props.dispachtHistory) {
      let positionSuccess = props.dispachtHistory.find(
        (item) => item.status == "99"
      );
      if (positionSuccess) {
        setMarkerDelivery(positionSuccess);
      }
    }
    if (props && props.markersPending) {
      if (props.markersPending.deliveryGeoref) {
        let positionSucces = {
          eventGps: {
            latitude: props.markersPending.deliveryGeoref.lat,
            longitude: props.markersPending.deliveryGeoref.lng,
          },
        };
        setMarkerDelivery(positionSucces);
      }
    }
  }, [props.markersPending, props.dispachtHistory, props.riderDestails]);

  useEffect(() => {
    if (google && markerDelivery && markerDelivery.eventGps) {
      var circle = new google.maps.Circle({
        strokeColor: "#0af079",
        strokeOpacity: 0.3,
        strokeWeight: 0.3,
        fillColor: "#187d49",
        fillOpacity: 0.12,
        map: mapRef.current,
        center: {
          lat: parseFloat(markerDelivery.eventGps.latitude),
          lng: parseFloat(markerDelivery.eventGps.longitude),
        },
        radius: 1000,
      });
    }
  }, [google, markerDelivery && markersPending]);

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) {
    return "Error";
  }

  if (!isLoaded) {
    return "Loading...";
  }
  const iconHtml = ReactDOMServer.renderToString(<CrisisAlertIcon />);

  return markersPending ? (
    <div>
      {markerDelivery && markerDelivery.eventGps ? (
        <div>
          lat: {markerDelivery.eventGps.latitude}, lng:
          {markerDelivery.eventGps.longitude}{" "}
        </div>
      ) : null}

      <div className="mapControlStyle">
        <GoogleMap
          id="mapControl2"
          mapContainerStyle={mapContainerStyle}
          zoom={18}
          center={center}
          options={options}
          onLoad={onMapLoad}
        >
          {markersPending && markersPending.toAddressLocation ? (
            <>
              <Marker
                key={"markersPendingid"}
                position={{
                  lat: markersPending.toAddressLocation.latitude
                    ? markersPending.toAddressLocation.latitude
                    : markersPending.toAddressLocation.lat,
                  lng: markersPending.toAddressLocation.longitude
                    ? markersPending.toAddressLocation.longitude
                    : markersPending.toAddressLocation.lng,
                }}
                onLoad={(markerInstance) => {
                  const customIcon = {
                    url: "/static/images/casaDelivery.png",
                    scaledSize: new window.google.maps.Size(35, 35),
                    anchor: new window.google.maps.Point(17.5, 17.5),
                  };
                  markerInstance.setIcon(customIcon);
                }}
              />
            </>
          ) : (
            <></>
          )}
          {markerRder ? (
            <>
              <Marker
                key={"markersPendingid"}
                position={{
                  lat: markerRder.latitude,
                  lng: markerRder.longitude,
                }}
                onLoad={(markerInstance) => {
                  const customIcon = {
                    url: "/static/images/conductor.png",
                    scaledSize: new window.google.maps.Size(45, 45),
                    anchor: new window.google.maps.Point(17.5, 17.5),
                  };
                  markerInstance.setIcon(customIcon);
                }}
              />
            </>
          ) : (
            <></>
          )}
          {markersPending && markersPending.exceptionGeoRef ? (
            <>
              <Marker
                key={"exceptionGeoRef"}
                position={{
                  lat: markersPending.exceptionGeoRef.latitude
                    ? markersPending.exceptionGeoRef.latitude
                    : markersPending.exceptionGeoRef.lat,
                  lng: markersPending.exceptionGeoRef.longitude
                    ? markersPending.exceptionGeoRef.longitude
                    : markersPending.exceptionGeoRef.lng,
                }}
                label={{
                  text: `Excepción GeoRef`,
                  color: "#F8F8F8",
                  className: "my-custom-label-ping",
                }}
              />
            </>
          ) : (
            <></>
          )}
          {markerDelivery && markerDelivery.eventGps ? (
            <>
              <Marker
                position={{
                  lat: parseFloat(markerDelivery.eventGps.latitude),
                  lng: parseFloat(markerDelivery.eventGps.longitude),
                }}
                // label={{
                //   text: "🏁",
                //   className: "labelMarker-pin",
                // }}
                onLoad={(markerInstance) => {
                  const customIcon = {
                    url: "/static/images/driver.png", // URL de la imagen que quieres usar
                    scaledSize: new window.google.maps.Size(35, 35), // Tamaño al que se escalará la imagen
                    anchor: new window.google.maps.Point(17.5, 17.5), // Ancla para la imagen (generalmente el centro o el punto en el que quieres que se ancle en el mapa)
                  };
                  markerInstance.setIcon(customIcon);
                }}
              />
            </>
          ) : (
            <></>
          )}
        </GoogleMap>
      </div>
    </div>
  ) : null;
}
