import React, { useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { actualizarDatosdeCompany } from "../../../redux/accionesCompanies";

import { Form, Input, Container } from "semantic-ui-react";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CardHeader, Divider } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FadeInOut from "../../animations/FadeInOut";

const libraries = ["places"];
const Fromwiewcompany = (props) => {
  const companyData = {
    companyId: props.company.companyID,
    createbyAdd: props.company.createByUid,
    createdate: props.company.createdate,
    createdateAdd: props.company.createdateAdd,
    propietary: props.company.propietary,
    companyName: props.company.companyName,
    companyRut: props.company.companyRut,
    companyAddress: props.company.companyAddress,
    companyNumAddress: props.company.companyNumAddress,
    companyRegion: props.company.companyRegion,
    companyCity: props.company.companyCity,
    companyComuna: props.company.companyComuna,
    companyCountry: props.company.companyCountry,
    companyCountryCode: props.company.companyCountryCode,
    companyActividad: props.company.companyActividad,
    companyContacName: props.company.companyContacName,
    companyContacEmail: props.company.companyContacEmail,
    companyAdressPhone: props.company.companyAdressPhone,
    statusDocsRut: props.company.companyImgRut,
    statusDocsComercio: props.company.companyImgDocumentos,
    statusDocsDocsii: props.company.companyImgDocSii,
    statusDocsAcuerdo: props.company.statusDocsAcuerdo,
    statusDocsPatente: props.company.companyImgPatente,
    statusDocsLogo: props.company.statusDocsLogo,
    stausDocument: props.company.stausDocument,
    adressGeoNumAddressLat: props.company.adressGeoNumAddressLat,
    adressGeoNumAddressLng: props.company.adressGeoNumAddressLng,
    estatusCRM: props.company.estatusCRM,
    relateCRM: props.company.relateCRM,
    isActive: props.company.isActive,
    servicie: props.company.servicie,
    servicetype: props.company.servicetype,
    stausService: props.company.stausService,
    currentPayMethods: props.company.currentPayMethods,
    payPendings: props.company.payPendings,
    suportTikets: props.company.suportTikets,
    companyCodefolder: props.company.companyCodefolder,
  };

  const dispatch = useDispatch();

  // States
  const resperror = useSelector((store) => store.usuario.error);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  // User Typing
  const [rutvalida, setrutvalida] = React.useState("");
  const [validatorAlert, setValidatorAlert] = React.useState("");
  const [validatorSuccess, setValidatorSuccess] = React.useState("");

  //const[assigned,setAssigned] = React.useState("")
  //const[propietary,setPropietary] = React.useState("")
  const [companyName, setCompanyName] = React.useState("");
  // const[companyRut,setCompanyRut] = React.useState("")

  const [companyAddress, setCompanyAddress] = React.useState("");
  const [companyNumAddress, setCompanyNumAddress] = React.useState("");
  const [companyRegion, setCompanyRegion] = React.useState("");
  const [companyCity, setCompanyCity] = React.useState("");
  const [companyComuna, setCompanyComuna] = React.useState("");
  const [companyCountry, setCompanyCountry] = React.useState("CHILE");
  const [companyCountryCode, setCompanyCountryCode] = React.useState("CL");
  const [companyAdressLat, setCompanyAdressLat] = React.useState("");
  const [companyAdressLng, setCompanyAdressLng] = React.useState("");
  const [companyAdressNumAddressTemp, setCompanyAdressNumAddressTemp] =
    React.useState("");

  const [companyActividad, setCompanyActividad] = React.useState("");
  const [companyContacName, setCompanyContacName] = React.useState("");
  const [companyContacEmail, setCompanyContacEmail] = React.useState("");

  const [companyAdressPhone, setCompanyAdressPhone] = React.useState(
    companyData["companyAdressPhone"]
  );

  const [companyCodeFolder, setCompanyCodeFolder] = React.useState(
    companyData["companyCodefolder"]
  );

  //Animaciones
  const [showEdit, setShowEdit] = useState(true);
  const toggleShowEdit = () => {
    setShowEdit(!showEdit);
    setrutvalida(companyData["companyRut"]);
    setCompanyName(companyData["companyName"]);
    setCompanyActividad(companyData["companyActividad"]);
    setCompanyAddress(companyData["companyAddress"]);
    setCompanyNumAddress(companyData["companyNumAddress"]);
    setCompanyCity(companyData["companyCity"]);
    setCompanyComuna(companyData["companyComuna"]);
    setCompanyRegion(companyData["companyRegion"]);
    setCompanyCountry(companyData["companyCountry"]);
    setCompanyAddress(companyData["companyAddress"]);
    setCompanyCountryCode(companyData["companyCountryCode"]);
    setCompanyContacName(companyData["companyContacName"]);
    setCompanyContacEmail(companyData["companyContacEmail"]);
    setCompanyAdressPhone(companyData["companyAdressPhone"]);
    setCompanyAdressLat(companyData["adressGeoNumAddressLat"]);
    setCompanyAdressLng(companyData["adressGeoNumAddressLng"]);

    setCompanyCodeFolder(companyData["companyCodefolder"]);
  };

  const crearEmpresa = (e) => {
    e.preventDefault();
    if (!companyName.trim()) {
      setError("Ingrese Nombre de empresa");
      return;
    }
    if (!rutvalida.trim()) {
      setError("Ingrese Rut de empresa");
      return;
    }
    if (!companyActividad.trim()) {
      setError("Ingrese actividad economica de empresa");
      return;
    }
    if (!companyAddress.trim()) {
      setError("Ingrese dirección de empresa");
      return;
    }
    if (!companyNumAddress.trim()) {
      setError("Ingrese numero de dirección si no aplica escriba NA");
      return;
    }
    if (!companyRegion.trim()) {
      setError("Ingrese region de empresa");
      return;
    }
    if (!companyCity.trim()) {
      setError("Ingrese ciudad donde se ubica la empresa");
      return;
    }
    if (!companyComuna.trim()) {
      setError("Ingrese comuna donde se ubica  empresa");
      return;
    }
    if (!companyRegion.trim()) {
      setError("Ingrese region donde se ubica  empresa");
      return;
    }
    if (!companyCountry.trim()) {
      setError("Ingrese país donde se ubica  empresa");
      return;
    }

    if (!companyContacName.trim()) {
      setError("Ingrese nombre de contacto de la empresa");
      return;
    }
    if (!companyAdressPhone.trim()) {
      setError("Ingrese telefono de contacto de la  empresa");
      return;
    }
    if (companyAdressPhone.length < 12) {
      // console.log('En el campo teléfono faltan numeros')
      setError("En el campo teléfono faltan numeros");
      return;
    }
    if (companyAdressPhone.length > 12) {
      // console.log('En el campo teléfono existen numeros extra')
      setError("El campo teléfono existen numeros extra");
      return;
    }
    setSuccess("Guardando Datos...");
    actualizarNewCompany();
  };

  // const limpiarFormulario = e =>{
  //     setError(null)
  //     setSuccess(null)
  //     setWarning(null)
  //     return
  // }
  const actualizarNewCompany = React.useCallback(async () => {
    const data = {
      companyID: companyCodeFolder,
      companyCodefolder: companyCodeFolder,
      companyName: companyName,
      companyRut: rutvalida,
      companyAddress: companyAddress,
      companyNumAddress: companyNumAddress,
      companyRegion: companyRegion,
      companyCity: companyCity,
      companyComuna: companyComuna,
      companyCountry: companyCountry,
      companyCountryCode: companyCountryCode,
      companyActividad: companyActividad,
      companyContacName: companyContacName,
      companyContacEmail: companyContacEmail,
      companyAdressPhone: companyAdressPhone,
      adressGeoNumAddressLat: companyAdressLat,
      adressGeoNumAddressLng: companyAdressLng,
    };
    setError(null);
    try {
      //console.log('guardar')
      dispatch(actualizarDatosdeCompany(data));

      function delay() {
        dispatch(toggleShowEdit);
        dispatch(props.toggleChildTrigres);

        //  limpiarFormulario()
        setSuccess(null);
      }
      setTimeout(delay, 1000);
    } catch (error) {
      // console.log(error)
    }
  });
  function rutvalidator(e) {
    var valor;
    var cuerpo;
    var dv;
    var rut;
    if (e.length <= 0) {
      setValidatorAlert("Campo es obligatorio");
      setValidatorSuccess(null);
      setrutvalida(e);
      return;
    }
    valor = clean(e);

    // console.log(e)

    cuerpo = valor.slice(0, -1);
    dv = valor.slice(-1).toUpperCase();

    rut = format(e);

    // console.log(rut)

    if (cuerpo.length < 7) {
      setValidatorAlert("Campo debe ser mayor a 7 Caracteres");
      setValidatorSuccess(null);
      setrutvalida(rut);
      return;
    }
    var suma = 0;
    var multiplo = 2;
    var i;
    var index;
    for (i = 1; i <= cuerpo.length; i++) {
      index = multiplo * valor.charAt(cuerpo.length - i);
      suma = suma + index;
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    var dvEsperado;
    dvEsperado = 11 - (suma % 11);
    dv = dv === "K" ? 10 : dv;
    dv = dv === 0 ? 11 : dv;
    if (dvEsperado !== dv) {
      // console.log('Rut incorrecto')
      setValidatorAlert("Rut incorrecto");
      setValidatorSuccess(null);
      //  return false;
    } else {
      // console.log('Rut correcto')
      setValidatorSuccess("Rut correcto");
      setValidatorAlert(null);
      function delay() {
        setValidatorSuccess(null);
      }
      setTimeout(delay, 5000);
      //   return true;
    }

    setrutvalida(rut);
    var clear = replaceAll(rut, ".", "");
    setCompanyCodeFolder(clear);

    //console.log(clear)
    return;
  }
  function replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) !== -1)
      text = text.toString().replace(busca, reemplaza);
    return text;
  }
  function format(rut) {
    rut = clean(rut);
    var result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (var i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }
    return result;
  }
  function clean(rut) {
    return typeof rut === "string"
      ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      : "";
  }

  return (
    <>
      <div className="usa-fieldset">
        <h2>¿Como deseas la facturación?</h2>
        <div className="usa-radio">
          <input
            className="usa-radio__input usa-radio__input--tile"
            type="radio"
            id="boleta"
            name="typebilling"
            value="Boleta"
            onChange={props.handleSelectTypebilling}
          />
          <label className="usa-radio__label" htmlFor="boleta">
            Boleta
          </label>
        </div>
      </div>
      <div className="usa-fieldset">
        <div className="usa-radio">
          <input
            className="usa-radio__input usa-radio__input--tile"
            type="radio"
            id="factura"
            name="typebilling"
            value="Factura"
            onChange={props.handleSelectTypebilling}
          />
          <label className="usa-radio__label" htmlFor="factura">
            Factura
          </label>
        </div>
      </div>
      <div>
        <div className="color-box ui-pdp-container-ebiex ui-pdp-container-ebiex-checout mt-16 ">
          <div className="ui-pdp-container__row ui-pdp-container__row--seller-info">
            <CardHeader
              subheader={
                <div>
                  <span> Informacion de empresa</span>
                </div>
              }
              title={
                <div>
                  {props.session.userCompanyRoll === "company_admin" ? (
                    <button
                      className="btn btn-ebiex-btn-sm btn-globalhover"
                      onClick={toggleShowEdit}
                    >
                      {showEdit ? (
                        <>
                          <EditOutlinedIcon> </EditOutlinedIcon>Editar
                        </>
                      ) : (
                        "Atras"
                      )}{" "}
                    </button>
                  ) : (
                    <></>
                  )}
                  <span className="H5">{companyData["companyName"]}</span>{" "}
                </div>
              }
            />
            <Divider />
            <Container className="formcontent-checkout" id="micuentafromIni">
              <FadeInOut show={showEdit} duration={500}>
                <div>
                  <div>
                    <h5>Datos de la empresa</h5>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Nombre: </td>
                          <td> {companyData["companyName"]}</td>
                        </tr>
                        <tr>
                          <td>Rut: </td>
                          <td> {companyData["companyRut"]}</td>
                        </tr>
                        <tr>
                          <td>Actividad: </td>
                          <td> {companyData["companyActividad"]}</td>
                        </tr>
                        <tr>
                          <td>Fecha de ingreso: </td>
                          <td> {companyData["createdateAdd"]}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div></div>
                  </div>
                  <div>
                    <Divider />
                    <h5>Dirección</h5>
                    {/* <div>{  companyData['propietary']}</div> */}
                    <div>
                      {companyData["companyAddress"]},
                      {companyData["companyNumAddress"]}{" "}
                    </div>
                    <div></div>
                    <div>
                      {companyData["companyComuna"]},{" "}
                      {companyData["companyCity"]}
                    </div>
                    <div></div>
                    <div>{companyData["companyRegion"]}</div>
                    <div>{companyData["companyCountry"]}</div>
                    {/* <div>{  companyData['companyCountryCode']}</div> */}
                  </div>
                  <div>
                    <Divider />
                    <h5>Contacto</h5>
                    <div>{companyData["companyContacName"]}</div>
                    <div>{companyData["companyContacEmail"]}</div>
                    <div>{companyData["companyAdressPhone"]}</div>
                  </div>
                </div>
                {/* 
                        <div>{  companyData['companyId']}</div>
                        <div>{  companyData['createbyAdd']}</div>

                        <div>{  companyData['statusDocsRut']}</div>
                        <div>{  companyData['statusDocsComercio']}</div>
                        <div>{  companyData['statusDocsDocsii']}</div>
                        <div>{  companyData['statusDocsAcuerdo']}</div>
                        <div>{  companyData['statusDocsPatente']}</div>
                        <div>{  companyData['statusDocsLogo']}</div>

                        <div>{  companyData['stausDocument']}</div>
                        <div>{  companyData['adressGeoNumAddressLat']}</div>
                        <div>{  companyData['adressGeoNumAddressLng']}</div>
                        <div>{  companyData['estatusCRM']}</div>
                        <div>{  companyData['relateCRM']}</div>
                        <div>{  companyData['servicie']}</div>
                        <div>{  companyData['servicetype']}</div>
                        <div>{  companyData['stausService']}</div>
                        <div>{  companyData['currentPayMethods']}</div>
                        <div>{  companyData['payPendings']}</div>
                        <div>{  companyData['suportTikets']}</div> */}
                <Divider />
              </FadeInOut>
              <FadeInOut show={!showEdit} duration={500}>
                <Form onSubmit={crearEmpresa}>
                  {success ? (
                    <div className="alert alert-success">{success}</div>
                  ) : null}
                  {error ? (
                    <div className="alert alert-danger">{error}</div>
                  ) : null}

                  {resperror ? (
                    <div className="alert alert-danger">{resperror}</div>
                  ) : null}
                  <span>
                    {validatorSuccess ? (
                      <div className="alert alert-success">
                        {validatorSuccess}
                      </div>
                    ) : null}
                    {validatorAlert ? (
                      <div className="alert alert-danger">{validatorAlert}</div>
                    ) : null}
                  </span>

                  <Form.Group widths="equal">
                    <Form.Field
                      className="formGroup2"
                      control={Input}
                      label="Razón social o nombre de Empresa"
                      placeholder="Razón social o nombre de Empresa"
                      type="text"
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                    />
                    <Form.Field
                      className="formGroup2 disabled field disabledEstilo"
                      control={Input}
                      label="Rut"
                      placeholder="Rut de la empresa"
                      type="text"
                      onChange={(e) => rutvalidator(e.target.value)}
                      value={companyData["companyRut"]}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      label="Giro"
                      placeholder="Actividad economica"
                      type="TextArea"
                      onChange={(e) => setCompanyActividad(e.target.value)}
                      value={companyActividad}
                    />
                  </Form.Group>
                  <Divider />
                  <h5>Dirección</h5>
                  <Form.Group widths="equal">
                    <Search
                      setCompanyAddress={setCompanyAddress}
                      setCompanyNumAddress={setCompanyNumAddress}
                      setCompanyRegion={setCompanyRegion}
                      setCompanyCity={setCompanyCity}
                      setCompanyComuna={setCompanyComuna}
                      setCompanyCountry={setCompanyCountry}
                      setCompanyCountryCode={setCompanyCountryCode}
                      setCompanyAdressLat={setCompanyAdressLat}
                      setCompanyAdressLng={setCompanyAdressLng}
                      setCompanyAdressNumAddressTemp={
                        setCompanyAdressNumAddressTemp
                      }
                      id="imputSearchFromOrigen"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      className="formGroup2"
                      control={Input}
                      placeholder="Número"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyNumAddress(e.target.value)}
                      value={companyNumAddress}
                      icon="angle right"
                    />
                    <Form.Field
                      className="formGroup2"
                      control={Input}
                      placeholder="Comuna"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyComuna(e.target.value)}
                      value={companyComuna}
                      icon="angle right"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Regíon"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyRegion(e.target.value)}
                      value={companyRegion}
                      icon="angle right"
                    />
                    <Form.Field
                      className="formGroup2"
                      control={Input}
                      placeholder="Ciudad"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyCity(e.target.value)}
                      value={companyCity}
                      icon="angle right"
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      className="disabled field disabledEstilo formGroup4"
                      control={Input}
                      placeholder="Pais"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyCountry(e.target.value)}
                      value={companyCountry}
                      icon="globe"
                    />
                  </Form.Group>

                  <h5>Contacto</h5>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Nombre de Contacto"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyContacName(e.target.value)}
                      value={companyContacName}
                      icon="user"
                    />
                    <Form.Field
                      control={Input}
                      placeholder="Teléfono de contacto"
                      iconPosition="left"
                      icon="phone"
                      type="text"
                      onChange={(e) => setCompanyAdressPhone(e.target.value)}
                      value={companyAdressPhone}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      placeholder="Email de contacto"
                      iconPosition="left"
                      type="text"
                      onChange={(e) => setCompanyContacEmail(e.target.value)}
                      value={companyContacEmail}
                      icon="at"
                    />
                  </Form.Group>
                  <Form.Field
                    control={Input}
                    type="text"
                    id="ocultarImput"
                    onChange={(e) => setCompanyAddress(e.target.value)}
                    value={companyAddress + " " + companyAdressNumAddressTemp}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      p: 2,
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: "#591E8F",
                        border: "none",
                        borderRadius: "3px",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "14px",
                        marginTop: "30px",
                        outline: "none",
                        padding: "5px",
                        minWidth: "50px",
                        fontWeight: 400,
                        margin: "0em 1em 0em 0em",
                        ":hover": {
                          backgroundColor: "#25b2f4 !important",
                          color: "#591e8f",
                        },
                      }}
                      color="primary"
                      variant="contained"
                      type="sutmit"
                    >
                      Guardar
                    </Button>
                  </Box>
                </Form>
              </FadeInOut>
            </Container>

            <Divider />
          </div>
        </div>
      </div>
    </>
  );
};

function Search({
  setCompanyAddress,
  setCompanyNumAddress,
  setCompanyRegion,
  setCompanyCity,
  setCompanyComuna,
  setCompanyCountry,
  setCompanyCountryCode,
  setCompanyAdressLat,
  setCompanyAdressLng,
  setCompanyAdressNumAddressTemp,
}) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 10 * 1000,
      componentRestrictions: {
        country: "Cl",
      },
    },
  });
  const handleInput = (e) => {
    setValue(e.target.value);
  };
  const handleSelect = async (address) => {
    // console.log(address)
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      // console.log(results[0])
      // console.log(results[0].address_components.length)
      // console.log(results[0].address_components)

      for (let i = 0; i < results[0].address_components.length; i++) {
        let elementAdress = results[0].address_components[i];
        // console.log(elementAdress['types'][0])
        switch (elementAdress["types"][0]) {
          case "street_number":
            //// console.log(elementAdress.long_name)
            setCompanyAdressNumAddressTemp(elementAdress.long_name);
            break;
          case "route":
            //// console.log(elementAdress.long_name)
            setCompanyAddress(elementAdress.long_name);
            break;
          case "locality":
            //// console.log(elementAdress.long_name)
            setCompanyComuna(elementAdress.long_name);
            break;
          case "administrative_area_level_3":
            //// console.log(elementAdress.long_name)
            break;
          case "administrative_area_level_2":
            //// console.log(elementAdress.long_name)
            setCompanyCity(elementAdress.long_name);
            break;
          case "administrative_area_level_1":
            //// console.log(elementAdress.long_name)
            setCompanyRegion(elementAdress.long_name);
            break;
          case "country":
            //// console.log(elementAdress.long_name)
            setCompanyCountry(elementAdress.long_name);

            break;
          case "postal_code":
            // // console.log(elementAdress.long_name)
            break;

          default:
            break;
        }
      }

      const { lat, lng } = await getLatLng(results[0]);
      // console.log(lat);
      // console.log(lng);
      setCompanyAdressLat(lat);
      setCompanyAdressLng(lng);
    } catch (error) {
      // console.log("😱 Error: ", error);
      // console.log(element)
    }
  };

  return (
    <div className="search-box-ebiex">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="input-group mb-2"
          id="imputSearchFromTo2"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Busca tu ubicación"
        />

        <ComboboxPopover className="comboboxList-ebiex">
          <ComboboxList key={Math.random().toString()}>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  key={Math.random().toString()}
                  value={description}
                  className="dropdown-item dropdown-ebiex"
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default Fromwiewcompany;
