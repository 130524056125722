import { ContactSupportOutlined } from "@mui/icons-material";
import { useState, useCallback, useEffect } from "react";

// ----------------------------------------------------------------------

export default function UseTable(props) {
  // console.log(props);
  const [dense, setDense] = useState(!!props?.defaultDense);

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || "name");

  const [ordersForAccions, setOrdersForAccions] = useState(
    props?.ordersForAccions || []
  );

  const [selectedData, setSelectedData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(
    props?.defaultRowsPerPage || 5
  );

  const [order, setOrder] = useState(props?.defaultOrder || "asc");

  const [selected, setSelected] = useState(props?.defaultSelected || []);

  const onSort = useCallback(
    (timeStamp) => {
      const isAsc = orderBy === timeStamp && order === "asc";
      if (timeStamp !== "") {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(timeStamp);
      }
    },
    [order, orderBy]
  );

  const onSelectRow = useCallback(
    (inputRow) => {
      console.log(selected);
      console.log(inputRow);

      const exists = selected.includes(inputRow.id);

      // Actualiza el array de IDs
      const newSelected = exists
        ? selected.filter((id) => id !== inputRow.id)
        : [...selected, inputRow.id];

      // Actualiza el array de objetos
      const newSelectedData = exists
        ? selectedData.filter((row) => row.id !== inputRow.id)
        : [...selectedData, inputRow];

      setSelected(newSelected);
      setSelectedData(newSelectedData);
      console.log(newSelected);
      console.log(newSelectedData);
    },
    [selected, selectedData]
  );

  useEffect(() => {
    if (ordersForAccions) {
      if (ordersForAccions.length > 0) {
        console.log(ordersForAccions);
        setSelected(ordersForAccions);
      }
    }
  }, [ordersForAccions]);

  useEffect(() => {
    console.log(ordersForAccions);
    setOrdersForAccions(props?.ordersForAccions);
  }, [props?.ordersForAccions]);

  const onChangeRowsPerPage = useCallback((event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);

  const onChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, []);

  const onSelectAllRows = useCallback((checked, inputValue) => {
    if (checked) {
      setSelected(inputValue);
      return;
    }
    setSelected([]);
  }, []);

  const onChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const onResetPage = useCallback(() => {
    setPage(0);
  }, []);

  const onUpdatePageDeleteRow = useCallback(
    (totalRowsInPage) => {
      setSelected([]);
      if (page) {
        if (totalRowsInPage < 2) {
          setPage(page - 1);
        }
      }
    },
    [page]
  );

  const onUpdatePageDeleteRows = useCallback(
    ({ totalRows, totalRowsInPage, totalRowsFiltered }) => {
      const totalSelected = selected.length;

      setSelected([]);

      if (page) {
        if (totalSelected === totalRowsInPage) {
          setPage(page - 1);
        } else if (totalSelected === totalRowsFiltered) {
          setPage(0);
        } else if (totalSelected > totalRowsInPage) {
          const newPage =
            Math.ceil((totalRows - totalSelected) / rowsPerPage) - 1;
          setPage(newPage);
        }
      }
    },
    [page, rowsPerPage, selected.length]
  );

  return {
    dense,
    order,
    page,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onResetPage,
    onChangeRowsPerPage,
    onUpdatePageDeleteRow,
    onUpdatePageDeleteRows,
    //
    setPage,
    setDense,
    setOrder,
    setOrderBy,
    setSelected,
    setRowsPerPage,
  };
}
