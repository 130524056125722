import React from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { Form, Input, Grid, Container } from "semantic-ui-react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import { format } from "date-fns";
import useGlobalConfig from "../../hooks/globalConfig";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import EditOrderFrom from "./EditOrderFrom";
import ReusableFormExceptions from "../ReusableFormExceptions/ReusableFormExceptions";

var history = [];

const EditDispatchdetails = (props) => {
  const historyNav = useNavigate();
  const { id } = useParams();
  function handleClick() {
    historyNav.goBack();
  }

  const [orderDetails, setOrderDetails] = React.useState([]);
  const [orderDetailsCliente, setOrderDetailsClient] = React.useState(null);
  const [dispachtHistory, setDispachtHistory] = React.useState([]);
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = React.useState([]);
  const [orderId, setOrderId] = React.useState("");
  const [newComent, setNewComent] = React.useState("");
  const [riderDestails, setRiderDestails] = React.useState({});
  const [userAdminComent, setUserAdminComent] = React.useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);

  const { translateStateBicciFalabella, styleStateBicciFalabella } =
    useGlobalConfig();
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  React.useEffect(() => {
    if (id) {
      setOrderId(id);
    }
  }, [id]);

  React.useEffect(() => {
    if (orderId) {
      findOrdesrDispacht(orderId);
    }
  }, [orderId]);

  React.useEffect(() => {}, [dispachtHistory]);

  const findOrdesrDispacht = async (id) => {
    try {
      const ordesDispacht = await getDoc(
        doc(db, `${process.env.REACT_APP_COL_USERS_ORDERS}/${id}`)
      );

      const Orden = ordesDispacht.data();
      setOrderDetails(Orden);
    } catch (error) {
      setOrderDetails({ error: "no found" });
    }

    try {
      const ordesBicciexpres = await getDocs(
        query(
          collection(db, process.env.REACT_APP_COL_ORDERS),
          where("orderID", "==", id)
        )
      );
      var dataOrdes = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const dataOrdesBicciExpress = dataOrdes;
      setDataOrdesBicciExpress(dataOrdesBicciExpress);
    } catch (error) {
      setDataOrdesBicciExpress({ error: "no found" });
    }

    try {
      const clearID = id.replace(/F/g, "");

      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );

      const falabellaOrderData = await getDoc(doc(falabellaOrderRef, clearID));

      if (falabellaOrderData.exists()) {
        const orderDataSoc = falabellaOrderData.data();
        setOrderDetailsClient(orderDataSoc);
      } else {
        setOrderDetailsClient({ error: "Pedido no encontrado" });
      }
    } catch (error) {
      console.log(error);
      setOrderDetailsClient({
        error: "Ocurrió un error al buscar el pedido",
      });
    }

    try {
      const clearID = id.replace(/F/g, "");
      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );

      const statusRef = doc(falabellaOrderRef, clearID.toString());

      const statusDocs = collection(statusRef, "status");

      const dataallOrdersfind5 = await getDocs(statusDocs);
      const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      datadocuments.sort((a, b) =>
        a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
      );
      setDispachtHistory(datadocuments);
    } catch (error) {
      console.log(error);
      setDispachtHistory([]);
    }
  };

  React.useEffect(() => {
    const finRiderDestails = async (id) => {
      try {
        const collectionRider = collection(
          db,
          process.env.REACT_APP_COL_USERS_DRIVERS
        );
        const docRef = doc(collectionRider, id);
        const riderRef = await getDoc(docRef);

        const rider = riderRef.data();
        setRiderDestails(rider);
      } catch (error) {
        //setRiderDestails({ error: "no found" });
      }
    };
    if (orderDetails) {
      finRiderDestails(orderDetails.driverID);
    }
  }, [orderDetails]);

  const saveComentAdmin = async () => {
    let mergeComents = [];
    if (newComent && newComent.trim()) {
      mergeComents.push(...userAdminComent);
      mergeComents.push(newComent);
      const orderRef3 = doc(collectionRef, `${orderId}`);
      await updateDoc(orderRef3, { observationAdmin: mergeComents });
      orderDetails.observationAdmin = mergeComents;
      setUserAdminComent(mergeComents);
      setNewComent("");
    } else {
    }
  };

  function delay(seconds) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  const onSave = async () => {
    await delay(2);
    findOrdesrDispacht(id);
    setOpenEdit(false);
  };

  return (
    <div>
      {orderDetails ? (
        <>
          {" "}
          <div id="containerDetails">
            <div>
              <h2 className="h2-withe">
                Editar Orden Emergencias y problemas de sistema
              </h2>
            </div>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={10} mobile={16} tablet={16}>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader
                      title={
                        <div>
                          <h1>{orderDetails.id}</h1>
                          {orderDetailsCliente ? (
                            <span
                              className={
                                styleStateBicciFalabella[
                                  orderDetailsCliente.falabella_status
                                ]
                              }
                            >
                              {
                                translateStateBicciFalabella[
                                  orderDetailsCliente.falabella_status
                                ]
                              }
                            </span>
                          ) : null}
                        </div>
                      }
                    />

                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <div>
                          <h1>Resumen</h1>
                        </div>
                        <Container className="formcontent">
                          <EditOrderFrom
                            props={props}
                            orderDetails={orderDetails}
                            orderDetailsCliente={orderDetailsCliente}
                            dataOrdesBicciExpress={dataOrdesBicciExpress}
                            orderid={orderId}
                          />
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid.Column>

                <Grid.Column computer={6} mobile={16} tablet={16}>
                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h2>Historial</h2>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Container className="formcontent" id="micuentafromIni">
                          <div className="alert alert-info">
                            Historial
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Fecha</th>
                                  <th scope="col">Estado</th>
                                </tr>
                              </thead>

                              {dispachtHistory ? (
                                <tbody>
                                  {dispachtHistory.map((item, index) => (
                                    <tr key={index}>
                                      <th scope="col">
                                        {format(
                                          item.createdAt.seconds * 1000,
                                          "dd/MM/yy HH:mm:ss"
                                        ).toString()}
                                      </th>
                                      <th scope="col">
                                        {
                                          translateStateBicciFalabella[
                                            item.status
                                          ]
                                        }
                                      </th>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : null}
                            </table>
                          </div>
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>

                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader
                      title={
                        <h3>Comentarios visibles en manifiestos de rutas</h3>
                      }
                    />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "150",
                        }}
                      >
                        <Container className="formcontent">
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Rider:{" "}
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {orderDetails.observationReceptor ? (
                              <div className="color-blue">
                                {orderDetails.observationReceptor}
                              </div>
                            ) : (
                              "Sin Comentarios"
                            )}
                          </Typography>
                          <hr></hr>
                          Admin:
                          {orderDetails.observationAdmin &&
                          orderDetails.observationAdmin.length > 0
                            ? orderDetails.observationAdmin.map(
                                (item, index) => (
                                  <div key={index} className="color-blue">
                                    <hr></hr>
                                    <div>{item}</div>
                                  </div>
                                )
                              )
                            : " Sin Comentarios"}
                          <hr></hr>
                          Agregar comentario a la Orden
                          <div>
                            <Form onSubmit={saveComentAdmin}>
                              {" "}
                              <Form.Field
                                control={Input}
                                placeholder="Agregar comentario"
                                iconPosition="left"
                                type="text"
                                onChange={(e) => setNewComent(e.target.value)}
                                value={newComent}
                                icon="edit"
                              />
                              <Button
                                sx={{
                                  backgroundColor: "#591E8F",
                                  border: "none",
                                  borderRadius: "3px",
                                  color: "#fff",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                  marginTop: "30px",
                                  outline: "none",
                                  padding: "5px",
                                  minWidth: "50px",
                                  fontWeight: 400,
                                  margin: "0em 1em 0em 0em",
                                  ":hover": {
                                    backgroundColor: "#25b2f4 !important",
                                    color: "#591e8f",
                                  },
                                }}
                                color="primary"
                                variant="contained"
                                type="sutmit"
                              >
                                Guardar Nota de Orden
                              </Button>
                            </Form>
                          </div>
                          <hr></hr>
                          <Button
                            sx={{
                              backgroundColor: "#591E8F",
                              border: "none",
                              borderRadius: "3px",
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: "14px",
                              marginTop: "30px",
                              outline: "none",
                              padding: "5px",
                              minWidth: "50px",
                              fontWeight: 400,
                              margin: "0em 1em 0em 0em",
                              ":hover": {
                                backgroundColor: "#25b2f4 !important",
                                color: "#591e8f",
                              },
                            }}
                            onClick={() => setOpenEdit(true)}
                            color="primary"
                            variant="contained"
                            type="sutmit"
                          >
                            Enviar Excepcion
                          </Button>
                          <ReusableFormExceptions
                            open={openEdit}
                            handleClose={() => setOpenEdit(false)}
                            onSave={() => onSave()}
                            orderID={orderDetails.id}
                            riderDestails={
                              riderDestails?.driverLocation
                                ? riderDestails.driverLocation
                                : null
                            }
                          ></ReusableFormExceptions>
                          <hr></hr>
                          {/* <Button
                            sx={{
                              backgroundColor: "#591E8F",
                              border: "none",
                              borderRadius: "3px",
                              color: "#fff",
                              cursor: "pointer",
                              fontSize: "14px",
                              marginTop: "30px",
                              outline: "none",
                              padding: "5px",
                              minWidth: "50px",
                              fontWeight: 400,
                              margin: "0em 1em 0em 0em",
                              ":hover": {
                                backgroundColor: "#25b2f4 !important",
                                color: "#591e8f",
                              },
                            }}
                            onClick={() =>
                              (window.location.href = `https://bicci-7ed2f.firebaseapp.com/management/orders/${orderDetails.id}/summary`)
                            }
                            color="primary"
                            variant="contained"
                            type="sutmit"
                          >
                            Agregar Excepcion
                          </Button> */}
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>

                  <Card className="card-ultimasOrdenes typeMiniforms">
                    <CardHeader title={<h2>Facturación</h2>} />
                    <CardContent>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "350",
                        }}
                      >
                        <Container className="formcontent">
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            ID:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            {orderDetails.id}
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Nombre servicio:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            _________
                          </Typography>
                          <hr></hr>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            Costo de servicio:
                          </Typography>
                          <Typography
                            fontFamily="Lato, Arial, Helvetica, sans-serif"
                            color="textPrimary"
                            gutterBottom
                            variant="body"
                          >
                            _________
                          </Typography>
                          <hr></hr>
                        </Container>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Orden No Existe</h5>
              <p className="card-text">
                No se encontraron resultados{" "}
                <span role="img" aria-label="Nerd face" className="bigFace">
                  😕
                </span>{" "}
                que coicidan con su busqueda, verifique el codigo. o cunsulte si
                la orden fue creada correctamente <br />
                <span role="img" aria-label="Nerd face" className="bigFace">
                  🤓
                </span>
              </p>
              <button
                type="button"
                className="btn btn-ebiex m-1"
                onClick={handleClick}
              >
                Regresar
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditDispatchdetails;
