import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Avatar,
  CardHeader,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { assingMultipleDriver } from "../../redux/accionesDrivers";

const PopUpAsignament = ({
  ordersForAccions,
  ridersDataByCompany,
  opemPopUpAsignament,
  setOpemPopUpAsignament,
}) => {
  const [selectedRider, setSelectedRider] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {}, [opemPopUpAsignament]);

  const asignarConductor = () => {
    if (selectedRider) {
      var dataAsid = {
        data: {
          driverID: selectedRider.id,
          orderIDs: ordersForAccions,
        },
      };

      dispatch(assingMultipleDriver(dataAsid));
      setOpemPopUpAsignament(false);
    }
  };

  const handleClose = () => setOpemPopUpAsignament(false);

  const handleRowClick = (params) => {
    setSelectedRider({
      id: params.id,
      name: params.row.name,
      avatar: params.row.avatar,
    });
  };

  const columnsRiders = [
    {
      field: "conductor",
      headerName: "Seleccione un conductor",
      width: 300,
      renderCell: (params) => (
        <CardHeader
          avatar={<Avatar src={params.row.avatar} />}
          title={params.row.name}
          subheader={params.row.bicciNumber}
        />
      ),
    },
  ];

  // console.log(ordersForAccions);
  return (
    <Dialog
      open={opemPopUpAsignament}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Asignar conductor</DialogTitle>
      <DialogContent>
        <Box
          sx={{ width: "100%", height: 20, p: 1, backgroundColor: "#fafafa" }}
        >
          Envíos seleccionados para asignar : {ordersForAccions.length}
        </Box>
        <Box sx={{ width: "100%", height: 350, backgroundColor: "#fafafa" }}>
          {ridersDataByCompany && (
            <DataGrid
              rows={ridersDataByCompany}
              columns={columnsRiders}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowClick={handleRowClick}
            />
          )}
        </Box>
        {selectedRider && (
          <CardHeader
            avatar={<Avatar src={selectedRider.avatar} />}
            title={selectedRider.name}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        {ordersForAccions.length > 0 ? (
          <Button onClick={asignarConductor} color="primary">
            Asignar conductor
          </Button>
        ) : (
          <Button color="primary">0 envíos seleccionados</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PopUpAsignament;
