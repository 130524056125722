import React from "react";
import { Tab } from "semantic-ui-react";
import { Typography, Card } from "@mui/material";
import { format } from "date-fns";
import { Form, Input } from "semantic-ui-react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  obtnerCambioEnOrden,
  registrarCambioEnOrden,
} from "../../redux/accionsRegister";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, updateDoc, getDoc } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";
import { db, storage } from "../../firebase";
import SelectorGeoRecf from "./SelectorGeoRecf";
import { translateStatesfalabbela } from "../../utils/objects/translateStates";

var data = "Sin contenido";
var dataContenido = "No se muestran datos";
var dataDriver = "No se muestran datos";
var actionsOrders = "no se muestran datos";
var logDeCambios = "no se muestran datos";

const panes = [
  {
    menuItem: "Datos de Orden",
    render: () => <Tab.Pane attached={false}>{data}</Tab.Pane>,
  },
  {
    menuItem: "Contenido de Orden",
    render: () => <Tab.Pane attached={false}>{dataContenido}</Tab.Pane>,
  },
  {
    menuItem: "Datos de driver",
    render: () => <Tab.Pane attached={false}>{dataDriver}</Tab.Pane>,
  },
  {
    menuItem: "Acciones",
    render: () => <Tab.Pane attached={false}>{actionsOrders}</Tab.Pane>,
  },
  {
    menuItem: "Registro de acciones",
    render: () => <Tab.Pane attached={false}>{logDeCambios}</Tab.Pane>,
  },
];

const TabSecondary = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storePayload = useSelector((store) => store.accionsRegisterLogs);
  // data = props.orderDetails.originUserName
  const [foto, setFoto] = React.useState([]);
  const [date, setDate] = React.useState([]);
  const [comment, setComment] = React.useState("");
  const [dirección, setNewDirection] = React.useState("");
  const [confirmDireccion, setConfirmDirection] = React.useState("");
  const [newLocation, setNewLocation] = React.useState(null);
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  React.useEffect(() => {}, [foto, newLocation]);

  React.useEffect(() => {
    if (props.orderDetails.pics) {
      var arraysPics = [];
      for (let i = 0; i < props.orderDetails.pics.length; i++) {
        arraysPics.push(props.orderDetails.pics[i]);
      }
      setFoto(arraysPics);
    }
    if (
      props.orderDetails.deliveryPictures &&
      props.orderDetails.deliveryPictures !== "isRequire"
    ) {
      var arraysPics = [];

      arraysPics.push(props.orderDetails.deliveryPictures);

      setFoto(arraysPics);
    }

    if (
      props.orderDetails &&
      props.orderDetails.date &&
      props.orderDetailsCliente &&
      props.orderDetailsCliente.updatedAt
    ) {
      setDate(
        format(
          props.orderDetailsCliente.updatedAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        ).toString()
      );
    }
    if (props.orderDetails.id) {
      dispatch(obtnerCambioEnOrden(props.orderDetails.id));
    }
  }, [props.orderDetails && props.orderDetailsCliente]);

  const addNewAddres = async () => {
    let data = {
      date: new Date(),
      lastValue: props.orderDetails.toAddress,
      newValue: dirección,
      orderId: props.orderDetails.id,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "toAddress",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${props.orderDetails.id}`);
    try {
      await updateDoc(orderRef1, {
        toAddress: dirección,
      });
    } catch (error) {}
    setNewDirection("");
  };

  const addNewGeoPiont = async () => {
    let newGeo = new GeoPoint(
      parseFloat(newLocation.lat),
      parseFloat(newLocation.lng)
    );
    let data = {
      date: new Date(),
      lastValue: props.orderDetails.toAddressLocation,
      newValue: newGeo,
      orderId: props.orderDetails.id,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "toAddressLocation",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${props.orderDetails.id}`);
    try {
      await updateDoc(orderRef1, {
        toAddressLocation: newGeo,
      });
    } catch (error) {}

    setNewLocation(null);
  };

  const createEmailclient = (e) => {
    e.preventDefault();
    const uniqueId = e.target.elements.uniqueId.value;

    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: "send",
      orderId: props.orderDetails.id,
      type: uniqueId,
      userId: props.props.usuarioStorage.uid,
      var: "email",
    };

    dispatch(registrarCambioEnOrden(data));

    const propsData = {
      acction: uniqueId,
      dirección: confirmDireccion,
      data: props.orderDetails,
      data2: props.orderDetailsCliente,
      data3: props.dataOrdesBicciExpress,
    };
    navigate(
      `/dashboard/dispatch/details/${props.orderDetails.id}/prewiew_email`,
      {
        state: propsData,
      }
    );
  };

  const createTicketSuport = () => {
    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: comment,
      orderId: props.orderDetails.id,
      type: "ticket",
      userId: props.props.usuarioStorage.uid,
      var: "ticket",
    };

    dispatch(registrarCambioEnOrden(data));
  };

  data = (
    <>
      <h3>Datos Origen</h3>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre de Usuario:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.placeName
          ? props.orderDetails.placeName
          : props.orderDetails.commerce}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Dirección:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.fromAddress &&
        props.orderDetails.fromAddress !== "isRequire"
          ? props.orderDetails.fromAddress
          : props.orderDetails.int_warehouseName}
      </Typography>
      <hr></hr>
      <h3>Datos Destino</h3>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails ? (
          <>
            {props.orderDetails.userName &&
            props.orderDetails.userName !== "isRequire"
              ? props.orderDetails.userName
              : props.orderDetails.clientNames}
          </>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Destino:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.toAddress}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Email Cliente:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.userEmail &&
        props.orderDetails.userEmail !== "isRequire"
          ? props.orderDetails.userEmail
          : props.orderDetails.clientEmail}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Teléfono:
      </Typography>{" "}
      +569
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.userPhone} |
        <a target="blank" href={`callto:+569${props.orderDetails.userPhone}`}>
          llamar
        </a>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Localidad:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>
            {props.orderDetailsCliente.commune1
              ? props.orderDetailsCliente.commune1
              : props.orderDetails.commune2}
          </>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Locacion destino:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails && props.orderDetails.toAddressLocation ? (
          <a
            target="blank"
            href={`https://www.google.com/maps/search/?api=1&query=${
              props.orderDetails.toAddressLocation.latitude
                ? props.orderDetails.toAddressLocation.latitude
                : props.orderDetails.toAddressLocation.lat
            },${
              props.orderDetails.toAddressLocation.longitude
                ? props.orderDetails.toAddressLocation.longitude
                : props.orderDetails.toAddressLocation.lng
            }`}
          >
            Ver en mapa
          </a>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        SOC:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>
            {props.orderDetailsCliente.soc
              ? props.orderDetailsCliente.soc
              : props.orderDetails.orderID}
          </>
        ) : null}
      </Typography>
      <hr></hr>
      {props.dataOrdesBicciExpress && props.dataOrdesBicciExpress.length > 0 ? (
        <>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            F12:
          </Typography>{" "}
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].F12
              ? props.dataOrdesBicciExpress[0].F12
              : props.dataOrdesBicciExpress[0].int_f12}
          </Typography>
        </>
      ) : (
        "Sin F12 Registrado"
      )}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Tamaño de Paquete:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>{props.orderDetailsCliente.size}</>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Medidas:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>
            {" "}
            height:{" "}
            {props.orderDetailsCliente.height
              ? props.orderDetailsCliente.height
              : props.orderDetails.productHeight}
            | weight:{" "}
            {props.orderDetailsCliente.weight
              ? props.orderDetailsCliente.weight
              : props.orderDetails.productLength}{" "}
            | width:{" "}
            {props.orderDetailsCliente.width
              ? props.orderDetailsCliente.width
              : props.orderDetails.productWidth}
          </>
        ) : null}
      </Typography>
      <h3>Datos De Entrega (Respuestas a Reclamos)</h3>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto tomada:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.picsTaken ? "Si" : foto.length > 0 ? "Si" : "No"}
      </Typography>
      <hr></hr>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre Receptor:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.userNameReceptor
          ? props.orderDetails.userNameReceptor
          : props.orderDetails.deliveryReceptor}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        RUT:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.userDniReceptor
          ? props.orderDetails.userDniReceptor
          : props.orderDetails.deliveryRut}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Fecha Ultima Actualización:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {date}
        {props.dataOrdesBicciExpress?.[0]
          ? props.dataOrdesBicciExpress[0].deliveryRealDeliveryDate !==
            "isRequire"
            ? props.dataOrdesBicciExpress[0].deliveryRealDeliveryDate
            : null
          : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto:
      </Typography>{" "}
      <br></br>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {foto.length > 0 ? (
          <>
            {" "}
            <hr></hr>
            {foto.map((item, index) => (
              <img key={index} id="imgavatar" src={item} alt={""}></img>
            ))}
            {foto.map((item, index) => (
              <div key={index}>
                <div>{item}</div>
                <hr></hr>
              </div>
            ))}
          </>
        ) : (
          <span className="alert alert-danger">'Sin Foto'</span>
        )}
      </Typography>
    </>
  );

  dataContenido = (
    <>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Estatus:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <span>
            {
              translateStatesfalabbela[
                props.orderDetailsCliente.falabella_status
              ]
            }
          </span>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Curier:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        BICCI
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Contenido:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props && props.dataOrdesBicciExpress ? (
          <>
            <ul>
              {props.dataOrdesBicciExpress.map((item, index) => (
                <li key={index}>
                  {" "}
                  {item.PRODUCTO ? item.PRODUCTO : item.productName} | SKU{" "}
                  {item.SKU ? item.SKU : item.productSku}{" "}
                </li>
              ))}
            </ul>
          </>
        ) : (
          "No data"
        )}
      </Typography>
      {props.dataOrdesBicciExpress && props.dataOrdesBicciExpress.length > 0 ? (
        <>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Código Producto:
          </Typography>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].SKU
              ? props.dataOrdesBicciExpress[0].SKU
              : props.dataOrdesBicciExpress[0].productSku}
          </Typography>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Descripción:
          </Typography>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].PRODUCTO
              ? props.dataOrdesBicciExpress[0].PRODUCTO
              : props.dataOrdesBicciExpress[0].productName}
          </Typography>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Valor : ${" "}
          </Typography>

          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].VALOR
              ? props.dataOrdesBicciExpress[0].VALOR
              : props.dataOrdesBicciExpress[0].productValue}
          </Typography>
        </>
      ) : (
        <>No data...</>
      )}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Cantidad de Bultos:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente
          ? props.orderDetailsCliente.quantity_packages
          : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Cantidad Unidades:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente
          ? props.orderDetailsCliente.quantity
          : props.orderDetails.productUnits}
      </Typography>
    </>
  );

  dataDriver = (
    <>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        ID:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.driverID &&
        props.orderDetails.driverID !== "isRequire"
          ? props.orderDetails.driverID
          : props.orderDetails.driverRut}
      </Typography>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.email}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        <img
          id="imgavatar"
          src={props.orderDetails.driverAvatar}
          alt={""}
        ></img>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.driverName}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Teléfono:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.driverPhone}
      </Typography>
    </>
  );

  actionsOrders = (
    <>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Crear un registro interno de soporte, Casos de error sistema,
        incumplimentos de protocolos o reclamos del cliente.
        <Form onSubmit={createTicketSuport}>
          {" "}
          <Form.Field
            control={Input}
            placeholder="Agregar descripción del caso"
            iconPosition="left"
            type="text"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            icon="edit"
          />
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            color="primary"
            variant="contained"
            type="sutmit"
          >
            Agregar nota de seguimiento
          </Button>
        </Form>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de primer intento fallido solicitud de contacto.
        <div>
          <Form onSubmit={createEmailclient}>
            {" "}
            <input type="hidden" name="uniqueId" value={"primerintento"} />
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de primer intento
            </Button>
          </Form>
        </div>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de segundo intento fallido solicitud de contacto.
        <div>
          <Form onSubmit={createEmailclient}>
            {" "}
            <input type="hidden" name="uniqueId" value={"segundointento"} />
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de segundo intento
            </Button>
          </Form>
        </div>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de tercer intento fallido solicitud de contacto.
        <div>
          <Form onSubmit={createEmailclient}>
            <input type="hidden" name="uniqueId" value={"tercerintento"} />{" "}
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de tercer intento
            </Button>
          </Form>
        </div>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de confirmación de dirección destino - Paquetes
        con dirección no legible.
        <div>
          <Form onSubmit={createEmailclient}>
            <input type="hidden" name="uniqueId" value={"confirmacion1"} />{" "}
            {/* <Form.Field
              control={Input}
              placeholder="Dirección en el cuerpo del mensaje"
              iconPosition="left"
              type="text"
              onChange={(e) => setConfirmDirection(e.target.value)}
              value={confirmDireccion}
              icon="edit"
            /> */}
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de confirmación de nuevo destino
            </Button>
          </Form>
        </div>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar Autorización de cambio de domicilio.
        <div>
          <Form onSubmit={createEmailclient}>
            <input type="hidden" name="uniqueId" value={"confirmacion2"} />{" "}
            <Form.Field
              control={Input}
              placeholder="Dirección en el cuerpo del mensaje"
              iconPosition="left"
              type="text"
              onChange={(e) => setConfirmDirection(e.target.value)}
              value={confirmDireccion}
              icon="edit"
            />
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email Autorización de cambio de domicilio.
            </Button>
          </Form>
        </div>
      </Typography>{" "}
      <hr></hr>
      {!props.orderDetails.int_falabella_status ? (
        <Typography
          fontFamily="Lato, Arial, Helvetica, sans-serif"
          color="textPrimary"
          gutterBottom
          variant="body"
        >
          Editar dirección con error.
          <div>Dirección actual:</div>
          <div>{props.orderDetails.toAddress}</div>
          <Form onSubmit={addNewAddres}>
            {" "}
            <Form.Field
              control={Input}
              placeholder="Agregar nueva dirección"
              iconPosition="left"
              type="text"
              onChange={(e) => setNewDirection(e.target.value)}
              value={dirección}
              icon="edit"
            />
            {dirección && dirección.length > 9 ? (
              <>
                {" "}
                <Button
                  sx={{
                    backgroundColor: "#591E8F",
                    border: "none",
                    borderRadius: "3px",
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "14px",
                    marginTop: "30px",
                    outline: "none",
                    padding: "5px",
                    minWidth: "50px",
                    fontWeight: 400,
                    margin: "0em 1em 0em 0em",
                    ":hover": {
                      backgroundColor: "#25b2f4 !important",
                      color: "#591e8f",
                    },
                  }}
                  color="primary"
                  variant="contained"
                  type="sutmit"
                >
                  Guardar nueva dirección
                </Button>
              </>
            ) : null}
          </Form>
        </Typography>
      ) : null}
      <hr></hr>
      {props.bloqueaGoogle !== true ? (
        <Form onSubmit={addNewGeoPiont}>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Ajustar Geo referencia de entrega.
            <div>Geo referencia actual:</div>
            {/* {props.orderDetails &&
            props.orderDetails.toAddressLocation &&
            props.orderDetails.toAddressLocation !== "isRequire"
              ? console.log(props.orderDetails.toAddressLocation)
              : null} */}
            <div>
              Lat:{" "}
              {props.orderDetails?.toAddressLocation?.latitude
                ? props.orderDetails.toAddressLocation.latitude.toString()
                : props.orderDetails?.toAddressLocation?.lat
                ? props.orderDetails.toAddressLocation.lat.toString()
                : "N/A"}
              , Lng:{" "}
              {props.orderDetails?.toAddressLocation?.longitude
                ? props.orderDetails.toAddressLocation.longitude.toString()
                : props.orderDetails?.toAddressLocation?.lng
                ? props.orderDetails.toAddressLocation.lng.toString()
                : "N/A"}
            </div>
            {newLocation && newLocation.lat ? (
              <div>
                <div>Nueva Geo referencia</div>
                <div>
                  <div>
                    Lat: {newLocation.lat.toString()}, Lng:{" "}
                    {newLocation.lng.toString()}
                  </div>
                </div>

                <Button
                  sx={{
                    backgroundColor: "#591E8F",
                    border: "none",
                    borderRadius: "3px",
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: "14px",
                    marginTop: "30px",
                    outline: "none",
                    padding: "5px",
                    minWidth: "50px",
                    fontWeight: 400,
                    margin: "0em 1em 0em 0em",
                    ":hover": {
                      backgroundColor: "#25b2f4 !important",
                      color: "#591e8f",
                    },
                  }}
                  color="primary"
                  variant="contained"
                  type="sutmit"
                >
                  Guardar Geo Referencia
                </Button>
              </div>
            ) : null}
          </Typography>{" "}
          <br></br>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props && props.orderDetails ? (
              <SelectorGeoRecf
                markersPending={props.orderDetails.toAddressLocation}
                setNewLocation={setNewLocation}
              ></SelectorGeoRecf>
            ) : null}
          </Typography>{" "}
        </Form>
      ) : null}
      <hr></hr>
    </>
  );

  logDeCambios = (
    <>
      {storePayload && storePayload.upload === true ? (
        <>
          {storePayload.logs.map((item, index) => (
            <Card
              key={index}
              sx={{ margin: "3px", padding: "3px" }}
              variant="outlined"
            >
              <div>{format(item.date.seconds * 1000, "dd/MM/yy HH:mm:ss")}</div>
              <div>Usuario </div>
              <div>{item.userId}</div>
              <div>Accion: {item.var}</div>
              <div>
                {typeof item.lastValue === "object" &&
                item.lastValue !== null ? (
                  <>Geopunto</>
                ) : (
                  <>
                    {" "}
                    <div>valor anterior: {item.lastValue} </div>
                    <div>nuevo valor {item.newValue}</div>
                  </>
                )}
              </div>
            </Card>
          ))}
        </>
      ) : (
        <>Sin cambios</>
      )}
    </>
  );

  return <Tab menu={{ secondary: true }} panes={panes}></Tab>;
};

export default TabSecondary;
