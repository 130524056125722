import React, { useState } from "react";

//import NextLink from 'next/link';
import { NavLink, useLocation } from "react-router-dom";
import { Box, Button, ButtonBase, Chip, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Image } from "semantic-ui-react";
import useGlobalConfig from "../../../hooks/globalConfig";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import useTitle from "../../Seo/useTitle";
import MenuGeneralEmpresa from "./IntegratiosMenus/MenuGeneralEmpresa";
import MenuGeneralOperacion from "./IntegratiosMenus/MenuGeneralOperacion";
import MenuGeneralBodega from "./IntegratiosMenus/MenuGeneralBodega";
import MenuGeneralMyAcount from "./IntegratiosMenus/MenuGeneralMyAcount";
import MenuFalabella from "./IntegratiosMenus/MenuFalabella";
import SuperAdminMenu from "./AsministratorsMenus/SuperAdminMenu";
import MenuAdministartosFinance from "./AsministratorsMenus/MenuAdministartosFinance";
export const DashboardSidebar = (props) => {
  const { img5 } = useGlobalConfig();

  const { siteName } = useGlobalConfig();
  useTitle({ title: `${siteName} Dashboard` });

  console.log("userDataContext", props.userDataContext);
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  return (
    <div className="sidebar-body">
      <div id="brandsLogo">
        <Image className="brands brands-dash" src={img5} />
      </div>
      <h3 className="btn-sidebarLink alignItemscentertitle">
        Super Administrador Ebiex
      </h3>
      {props.userDataContext.uid === process.env.REACT_APP_SUPERADMIN ? (
        <SuperAdminMenu onNavigate={props.onNavigate}></SuperAdminMenu>
      ) : null}

      {props.userDataContext.uid === process.env.REACT_APP_SUPERADMIN ? (
        <MenuAdministartosFinance
          companyCollaborator={props.companyCollaborator}
          onNavigate={props.onNavigate}
        ></MenuAdministartosFinance>
      ) : null}

      <MenuGeneralEmpresa onNavigate={props.onNavigate}></MenuGeneralEmpresa>
      <MenuGeneralOperacion
        onNavigate={props.onNavigate}
      ></MenuGeneralOperacion>
      {props.companyCollaborator ? (
        <>
          {Array.isArray(props.companyCollaborator) &&
          props.companyCollaborator.length > 0
            ? props.companyCollaborator.map((item) => {
                if (item !== "falabella") {
                  return (
                    <MenuGeneralOperacion
                      onNavigate={props.onNavigate}
                    ></MenuGeneralOperacion>
                  );
                }
                return null;
              })
            : props.companyCollaborator !== "falabella" && (
                <MenuGeneralOperacion
                  onNavigate={props.onNavigate}
                ></MenuGeneralOperacion>
              )}
        </>
      ) : null}

      <MenuGeneralBodega onNavigate={props.onNavigate}></MenuGeneralBodega>
      {props.companyCollaborator ? (
        <>
          {Array.isArray(props.companyCollaborator) &&
          props.companyCollaborator.length > 0
            ? props.companyCollaborator.map((item) => {
                if (item === "falabella") {
                  return (
                    <MenuFalabella
                      key={item}
                      companyCollaborator={item}
                      onNavigate={props.onNavigate}
                    ></MenuFalabella>
                  );
                }
                return null;
              })
            : props.companyCollaborator === "falabella" && (
                <MenuFalabella
                  key={props.companyCollaborator}
                  companyCollaborator={props.companyCollaborator}
                  onNavigate={props.onNavigate}
                ></MenuFalabella>
              )}
        </>
      ) : null}

      <MenuGeneralMyAcount onNavigate={props.onNavigate}></MenuGeneralMyAcount>
    </div>
  );
};
