import React, { useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { UserContext } from "../../../context/CurrentUserProvider";
import { useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];
const ContextOrderCompany = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const { userDataContext } = useContext(UserContext);

  const [selectorMode, setSelectorMode] = useState({
    express: false,
    masive: false,
    view: true,
  });

  const userRoll = userDataContext.roll;
  const userdata = userDataContext.userData;

  if (loadError) {
    return "Error";
  }

  if (!isLoaded) {
    return "Loading...";
  }

  return (
    userDataContext && (
      <Outlet
        userDataContext={userDataContext}
        userRoll={userRoll}
        userdata={userdata}
        usuarioStorage={props.usuarioStorage}
        firebaseUser={props.firebaseUser}
      />
    )
  );
};

export default ContextOrderCompany;
