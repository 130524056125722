import React from "react";
import { GeoPoint } from "firebase/firestore";
import { Tab } from "semantic-ui-react";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { Form, Input } from "semantic-ui-react";
import { Button, Card } from "@mui/material";
import Resizer from "react-image-file-resizer";
import { collection, doc, updateDoc, getDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectorGeoRecf from "./SelectorGeoRecf";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { LinearProgress } from "@mui/material";
import { registrarCambioEnOrden } from "../../redux/accionsRegister";
import { useLoadScript } from "@react-google-maps/api";
var data = "Sin contenido";
var dataContenido = "No se muestran datos";
var dataDriver = "No se muestran datos";
var actionsOrders = "no se muestran datos";
var logDeCambios = "no se muestran datos";

const panes = [
  {
    menuItem: "Datos de Orden",
    render: () => <Tab.Pane attached={false}>{data}</Tab.Pane>,
  },
  {
    menuItem: "Contenido de Orden",
    render: () => <Tab.Pane attached={false}>{dataContenido}</Tab.Pane>,
  },
  {
    menuItem: "Datos de driver",
    render: () => <Tab.Pane attached={false}>{dataDriver}</Tab.Pane>,
  },
  {
    menuItem: "Acciones",
    render: () => <Tab.Pane attached={false}>{actionsOrders}</Tab.Pane>,
  },
  {
    menuItem: "Registro de acciones",
    render: () => <Tab.Pane attached={false}>{logDeCambios}</Tab.Pane>,
  },
];

// console.log(orderDetails)
const translateStateBicciFalabella = {
  1: "SIN MORADORES",
  99: "Entregado",
  1058: "Creado",
  1059: "Sin Asignar",
  1060: "En Ruta",
  1061: "En Ruta",
  1002: "DOMICILIO NO CORRESPONDE",
  1005: "PROD NO ES LO COMPRADO",
  1006: "PROD INCOMPLETO",
  1007: "PROD FALTANTE",
  1008: "PROD DANADO",
  1009: "FUERA DE HORARIO",
  1017: "NO RETIRA NO CUMPL FORMAT",
  1019: "FUERA DE COBERTURA",
  1022: "CLIENTE REALIZARA N/C",
  1023: "PRODUCTO ROBADO",
  1027: "NO RETIRADO",
  1029: "DEVOLUCION",
  1031: "PROD N ENTREGADO X TIEMPO",
  1030: "INCIDENCIA EN LA CALLE",
  1032: "FALTA DE INFORMACIÓN",
  1034: "PROD YA FUE RECIBIDO",
  1036: "DIFICULTAD ACCESO DESTINO",
  1038: "CLIENTE CAMBIA DIRCCION",
  1041: "CLIENTE RECHAZA ENVIO",
  1018: "NO CONOCEN AL CLIENTE",
};

const libraries = ["geometry", "places"];

const EditOrderFrom = (props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
    libraries,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [foto, setFoto] = React.useState([]);
  const [date, setDate] = React.useState([]);
  const [comment, setComment] = React.useState("");
  const [picsTaken, setPicsTaken] = React.useState(false);
  const [confirmDireccion, setConfirmDirection] = React.useState("");
  const [userNameReceptor, setUserNameReceptor] = React.useState("");
  const [userDniReceptor, setUserDniReceptor] = React.useState("");
  const [tokensPics, setTokensPics] = React.useState([]);
  const [estatus, setEstatus] = React.useState(props.orderDetails.status);
  const [dirección, setNewDirection] = React.useState("");
  const [newLocation, setNewLocation] = React.useState(null);

  const [orderID, setOrderId] = React.useState(props.orderid);
  const storePayload = useSelector((store) => store.accionsRegisterLogs);
  // REACT_APP_COL_ORDERS  EXPRESS
  // REACT_APP_COL_USERS_ORDERS Bicci
  const collectionRef = collection(
    db,
    `${process.env.REACT_APP_COL_USERS_ORDERS}`
  );

  React.useEffect(() => {}, [foto, orderID]);

  React.useEffect(() => {
    setOrderId(props.orderid);
  }, [props.orderid]);

  React.useEffect(() => {
    if (props.orderDetails.pics) {
      var arraysPics = [];
      for (let i = 0; i < props.orderDetails.pics.length; i++) {
        arraysPics.push(props.orderDetails.pics[i]);
      }
      setFoto(arraysPics);
    }
    if (props.orderDetails.date && props.orderDetailsCliente.updatedAt) {
      setDate(
        format(
          props.orderDetailsCliente.updatedAt.seconds * 1000,
          "dd/MM/yy HH:mm:ss"
        ).toString()
      );
    }
    if (props.orderDetails.status) {
      setEstatus(props.orderDetails.status);
      setUserNameReceptor(props.orderDetails.userNameReceptor);
      setUserDniReceptor(props.orderDetails.userDniReceptor);
    }
  }, [props.orderDetails && props.orderDetailsCliente]);

  const guardarUserNameReceptor = async (e) => {
    let userNameReceptorClear = userNameReceptor.trim();
    let userNameReceptorClearRemove = userNameReceptorClear
      .normalize("NFD") // Descomposición de caracteres a Unicode
      .replace(/[\u0300-\u036f]/g, "") // Eliminación de acentos
      .replace(/[^\w\s]/gi, ""); // Reemplazo de caracteres especiales por espacios en blanco

    //register Logs
    let data = {
      date: new Date(),
      lastValue: props.orderDetails.userNameReceptor,
      newValue: userNameReceptorClearRemove,
      orderId: orderID,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "userNameReceptor",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${orderID}`);
    try {
      await updateDoc(orderRef1, {
        userNameReceptor: userNameReceptorClearRemove,
      });
      setUserNameReceptor(userNameReceptorClearRemove);
    } catch (error) {}
  };

  const nextState = async (e) => {
    let data = {
      date: new Date(),
      lastValue: props.orderDetails.status,
      newValue: 8,
      orderId: orderID,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "status",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef2 = doc(collectionRef, `${orderID}`);

    await updateDoc(orderRef2, { status: 8 });
    setEstatus(8);
  };

  const desblodSatusApi = async (e) => {
    // let data = {
    //   date: new Date(),
    //   lastValue: props.orderDetails.status,
    //   newValue: 8,
    //   orderId: orderID,
    //   type: "infoAdd",
    //   userId: props.props.usuarioStorage.uid,
    //   var: "status",
    // };

    // dispatch(registrarCambioEnOrden(data));
    if (props.orderDetails.status === 4 || props.orderDetails.status === 7) {
      const orderRef2 = doc(collectionRef, `${orderID}`);
      await updateDoc(orderRef2, { status: 5 });
      setEstatus(5);
    }
  };

  const desblodSatusApi6 = async (e) => {
    // let data = {
    //   date: new Date(),
    //   lastValue: props.orderDetails.status,
    //   newValue: 8,
    //   orderId: orderID,
    //   type: "infoAdd",
    //   userId: props.props.usuarioStorage.uid,
    //   var: "status",
    // };

    // dispatch(registrarCambioEnOrden(data));
    if (props.orderDetails.status === 5) {
      const orderRef2 = doc(collectionRef, `${orderID}`);
      await updateDoc(orderRef2, { status: 6 });
      setEstatus(6);
    }
  };

  const reverseState = async (e) => {
    const orderRef2 = doc(collectionRef, `${orderID}`);

    let data = {
      date: new Date(),
      lastValue: props.orderDetails.status,
      newValue: 7,
      orderId: orderID,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "status",
    };

    dispatch(registrarCambioEnOrden(data));

    await updateDoc(orderRef2, { status: 7 });
    setEstatus(7);
  };

  const saveUserDniReceptor = async (e) => {
    const orderRef3 = doc(collectionRef, `${orderID}`);

    let userDniReceptorClear = userDniReceptor.trim();
    let userDniReceptorClearRemove = userDniReceptorClear
      .replace(/[^\w\s]/gi, "") // Remover caracteres especiales
      .replace(/K/g, "0"); // Reemplazar "K" por cero

    let data = {
      date: new Date(),
      lastValue: props.orderDetails.userDniReceptor,
      newValue: userDniReceptorClearRemove,
      orderId: orderID,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "userDniReceptor",
    };

    dispatch(registrarCambioEnOrden(data));

    await updateDoc(orderRef3, { userDniReceptor: userDniReceptorClearRemove });

    setUserDniReceptor(userDniReceptorClearRemove);
  };

  async function handleImageLoad(event) {
    const file = event.target.files[0];
    const metadata = {
      contentType: file.type,
    };
    let newFoto = foto.length + 1;

    Resizer.imageFileResizer(
      file,
      480, // Aquí puedes definir el nuevo ancho que desees
      640, // Aquí puedes definir la nueva altura que desees
      "JPEG",
      100,
      0,
      (uri) => {
        cropImage(uri, newFoto, metadata);
      },

      "base64",
      480, // Nuevo ancho máximo (opcional)
      640 // Nuevo alto máximo (opcional)
    );
  }

  function upLoadImage(cropInageResult, newFoto, metadata) {
    try {
      const dataUrl = cropInageResult.split(",")[1];
      uploadString(
        ref(storage, `orders/${orderID}/image${newFoto}.jpg`),
        dataUrl,
        "base64",
        metadata
      )
        .then(async (snapshot) => {
          const downloadURL = await getDownloadURL(snapshot.ref);
          setTokensPics((prevState) => [...prevState, downloadURL]);
          savePics(downloadURL);
        })
        .catch((error) => {
          console.error("Error al cargar la imagen:", error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const cropImage = async (imageDataUrl, newFoto, metadata) => {
    try {
      const img = new Image();
      img.src = imageDataUrl; // Carga la imagen en el elemento img

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const targetWidth = 480;
        const targetHeight = 640;
        const aspectRatio = targetWidth / targetHeight;

        // Ajuste de alto sin afectar el ancho
        let width = img.width;
        let height = Math.floor(width / aspectRatio);
        canvas.width = width;
        canvas.height = height;
        context.drawImage(img, 0, 0, width, height);

        // Ajuste de ancho sin afectar el alto
        width = targetWidth;
        height = Math.floor(width / aspectRatio);
        const finalCanvas = document.createElement("canvas");
        const finalContext = finalCanvas.getContext("2d");
        finalCanvas.width = targetWidth;
        finalCanvas.height = targetHeight;
        finalContext.drawImage(
          canvas,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        const stretchedImageDataUrl = finalCanvas.toDataURL("image/jpeg", 1.0);
        upLoadImage(stretchedImageDataUrl, newFoto, metadata);
      };
    } catch (error) {
      console.log(error);
    }
  };

  async function savePics(newToken) {
    let data = {
      date: new Date(),
      lastValue: "image",
      newValue: newToken,
      orderId: orderID,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "pics",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef = doc(collectionRef, orderID);

    // Obtener el documento actual
    const orderSnapshot = await getDoc(orderRef);
    const orderData = orderSnapshot.data();
    const tokensPics = orderData.pics || []; // Si no hay pics existentes, establecer un array vacío

    let arrayPics = [...tokensPics, newToken];
    await updateDoc(orderRef, { pics: arrayPics, picsTaken: true });
    setFoto(arrayPics);
  }

  const actualizarTodos = () => {
    guardarUserNameReceptor();
    saveUserDniReceptor();
  };

  const createTicketSuport = () => {
    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: comment,
      orderId: props.orderDetails.id,
      type: "ticket",
      userId: props.props.usuarioStorage.uid,
      var: "ticket",
    };

    dispatch(registrarCambioEnOrden(data));
  };
  const createEmailclient = (e) => {
    e.preventDefault();
    const uniqueId = e.target.elements.uniqueId.value;

    let data = {
      date: new Date(),
      lastValue: "0",
      newValue: "send",
      orderId: props.orderDetails.id,
      type: uniqueId,
      userId: props.props.usuarioStorage.uid,
      var: "email",
    };

    dispatch(registrarCambioEnOrden(data));

    const propsData = {
      acction: uniqueId,
      dirección: confirmDireccion,
      data: props.orderDetails,
      data2: props.orderDetailsCliente,
      data3: props.dataOrdesBicciExpress,
    };
    navigate(
      `/dashboard/dispatch/details/${props.orderDetails.id}/prewiew_email`,
      {
        state: propsData,
      }
    );
  };

  const addNewGeoPiont = async () => {
    let newGeo = new GeoPoint(
      parseFloat(newLocation.lat),
      parseFloat(newLocation.lng)
    );
    let data = {
      date: new Date(),
      lastValue: props.orderDetails.toAddressLocation,
      newValue: newGeo,
      orderId: props.orderDetails.id,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "toAddressLocation",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${props.orderDetails.id}`);
    try {
      await updateDoc(orderRef1, {
        toAddressLocation: newGeo,
      });
    } catch (error) {}

    setNewLocation(null);
  };

  const addNewAddres = async () => {
    let data = {
      date: new Date(),
      lastValue: props.orderDetails.toAddress,
      newValue: dirección,
      orderId: props.orderDetails.id,
      type: "infoAdd",
      userId: props.props.usuarioStorage.uid,
      var: "toAddress",
    };

    dispatch(registrarCambioEnOrden(data));

    const orderRef1 = doc(collectionRef, `${props.orderDetails.id}`);
    try {
      await updateDoc(orderRef1, {
        toAddress: dirección,
      });
    } catch (error) {}
    setNewDirection("");
  };

  data = (
    <>
      <h3>Datos Origen</h3>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre de Usuario:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.placeName}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Dirección:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.fromAddress}
      </Typography>
      <hr></hr>
      {/*   <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Ciudad:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressCity}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Comuna:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressComuna}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Dirección:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressName}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Número:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressNumAddress}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Teléfono:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressPhone}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Región:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressRegion}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Compañia:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.placeName}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">ID Propietario:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originPropietary}</Typography>
  <hr></hr>
    <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">Comentario de dirección:</Typography>     <Typography fontFamily="Lato, Arial, Helvetica, sans-serif"  color="textPrimary" gutterBottom variant="body">{props.orderDetails.originAdressComent}</Typography>
   */}
      <h3>Datos Destino</h3>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails ? <>{props.orderDetails.userName}</> : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Destino:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.toAddress}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Email Cliente:
      </Typography>
      {"    "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.userEmail}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Teléfono:
      </Typography>{" "}
      +569
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.userPhone} |
        <a target="blank" href={`callto:+569${props.orderDetails.userPhone}`}>
          llamar
        </a>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Localidad:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>{props.orderDetailsCliente.commune1}</>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Locacion destino:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails && props.orderDetails.toAddressLocation ? (
          <a
            target="blank"
            href={`https://www.google.com/maps/search/?api=1&query=${props.orderDetails.toAddressLocation.latitude},${props.orderDetails.toAddressLocation.longitude}`}
          >
            Ver en mapa
          </a>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        SOC:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>{props.orderDetailsCliente.soc}</>
        ) : null}
      </Typography>
      <hr></hr>
      {props.dataOrdesBicciExpress.length > 0 ? (
        <>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            F12:
          </Typography>{" "}
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].F12
              ? props.dataOrdesBicciExpress[0].F12
              : props.dataOrdesBicciExpress[0].int_f12}
          </Typography>
        </>
      ) : (
        "Sin F12 Registrado"
      )}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Tamaño de Paquete:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>{props.orderDetailsCliente.size}</>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Medidas:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <>
            {" "}
            height: {props.orderDetailsCliente.height}| weight:{" "}
            {props.orderDetailsCliente.weight} | width:{" "}
            {props.orderDetailsCliente.width}
          </>
        ) : null}
      </Typography>
      <h3>Datos De Entrega MODO EDICION</h3>
      <Button
        sx={{
          backgroundColor: "#591E8F",
          border: "none",
          borderRadius: "3px",
          color: "#fff",
          cursor: "pointer",
          fontSize: "14px",
          marginTop: "30px",
          outline: "none",
          padding: "5px",
          minWidth: "50px",
          fontWeight: 400,
          margin: "0em 1em 0em 0em",
          ":hover": {
            backgroundColor: "#25b2f4 !important",
            color: "#591e8f",
          },
        }}
        color="primary"
        variant="contained"
        onClick={actualizarTodos}
      >
        GUARDAR TODOS
      </Button>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto tomada:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.picsTaken ? "Si" : "No"}
      </Typography>
      <hr></hr>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre Receptor: {userNameReceptor}
      </Typography>{" "}
      <div>
        <Form onSubmit={guardarUserNameReceptor}>
          {" "}
          <Form.Field
            control={Input}
            placeholder="userNameReceptor"
            iconPosition="left"
            type="text"
            onChange={(e) => setUserNameReceptor(e.target.value)}
            value={userNameReceptor}
            icon="user"
          />
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            color="primary"
            variant="contained"
            type="sutmit"
          >
            Guardar userNameReceptor
          </Button>
        </Form>
      </div>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      ></Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        RUT: {userDniReceptor}
      </Typography>{" "}
      <div>
        <Form onSubmit={saveUserDniReceptor}>
          {" "}
          <Form.Field
            control={Input}
            placeholder="userDniReceptor"
            iconPosition="left"
            type="text"
            onChange={(e) => setUserDniReceptor(e.target.value)}
            value={userDniReceptor}
            icon="user"
          />
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            color="primary"
            variant="contained"
            type="sutmit"
          >
            Guardar userDniReceptor
          </Button>
        </Form>
      </div>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      ></Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Fecha Ultima Actualización:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {date}
      </Typography>
      <div>
        {/* <Button
          sx={{
            backgroundColor: "#591E8F",
            border: "none",
            borderRadius: "3px",
            color: "#fff",
            cursor: "pointer",
            fontSize: "14px",
            marginTop: "30px",
            outline: "none",
            padding: "5px",
            minWidth: "50px",
            fontWeight: 400,
            margin: "0em 1em 0em 0em",
            ":hover": {
              backgroundColor: "#25b2f4 !important",
              color: "#591e8f",
            },
          }}
          color="primary"
          variant="contained"
          type="sutmit"
          onClick={desblodSatusApi}
        >
          Move to Route
        </Button>

        <Button
          sx={{
            backgroundColor: "#591E8F",
            border: "none",
            borderRadius: "3px",
            color: "#fff",
            cursor: "pointer",
            fontSize: "14px",
            marginTop: "30px",
            outline: "none",
            padding: "5px",
            minWidth: "50px",
            fontWeight: 400,
            margin: "0em 1em 0em 0em",
            ":hover": {
              backgroundColor: "#25b2f4 !important",
              color: "#591e8f",
            },
          }}
          color="primary"
          variant="contained"
          type="sutmit"
          onClick={desblodSatusApi6}
        >
          Move to Route (6)
        </Button> */}
        <Button
          sx={{
            backgroundColor: "#591E8F",
            border: "none",
            borderRadius: "3px",
            color: "#fff",
            cursor: "pointer",
            fontSize: "14px",
            marginTop: "30px",
            outline: "none",
            padding: "5px",
            minWidth: "50px",
            fontWeight: 400,
            margin: "0em 1em 0em 0em",
            ":hover": {
              backgroundColor: "#25b2f4 !important",
              color: "#591e8f",
            },
          }}
          color="primary"
          variant="contained"
          type="sutmit"
          onClick={reverseState}
        >
          Reverse
        </Button>
        <Button
          sx={{
            backgroundColor: "#591E8F",
            border: "none",
            borderRadius: "3px",
            color: "#fff",
            cursor: "pointer",
            fontSize: "14px",
            marginTop: "30px",
            outline: "none",
            padding: "5px",
            minWidth: "50px",
            fontWeight: 400,
            margin: "0em 1em 0em 0em",
            ":hover": {
              backgroundColor: "#25b2f4 !important",
              color: "#591e8f",
            },
          }}
          color="primary"
          variant="contained"
          type="sutmit"
          onClick={nextState}
        >
          Next State
        </Button>
      </div>
      <hr></hr>
      <div>{`Estatus BICCI: ${estatus}`}</div>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto:
      </Typography>{" "}
      {/* <div>
        <Form onSubmit={savePics}>
          {" "}
          <Form.Field
            control={Input}
            placeholder="SubirFotodDesarrollas"
            iconPosition="left"
            type="text"
            value={userDniReceptor}
            icon="user"
          />
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            color="primary"
            variant="contained"
            type="sutmit"
          >
            Guardar fotos
          </Button>
        </Form>
      </div> */}
      <input type="file" onChange={handleImageLoad} />
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {foto.length > 0 ? (
          <>
            {" "}
            {foto.map((item, index) => {
              return (
                <div key={index}>
                  <div>
                    <img key={index} id="imgavatar" src={item} alt={""}></img>
                  </div>
                  <div>
                    <span>{item}</span>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <span className="alert alert-danger">'Sin Foto'</span>
        )}
      </Typography>
    </>
  );

  dataContenido = (
    <>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Estatus:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente ? (
          <span>
            {
              translateStateBicciFalabella[
                props.orderDetailsCliente.falabella_status
              ]
            }
          </span>
        ) : null}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Curier:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        BICCI
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Contenido:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.dataOrdesBicciExpress ? (
          <>
            <ul>
              {props.dataOrdesBicciExpress.map((item, index) => (
                <li key={index}>
                  {" "}
                  {item.PRODUCTO ? item.PRODUCTO : item.productName} | SKU{" "}
                  {item.SKU ? item.SKU : item.productSku}{" "}
                </li>
              ))}
            </ul>
          </>
        ) : (
          "No data"
        )}
      </Typography>
      {props.dataOrdesBicciExpress.length > 0 ? (
        <>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Código Producto:
          </Typography>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].SKU
              ? props.dataOrdesBicciExpress[0].SKU
              : props.dataOrdesBicciExpress[0].productSku}
          </Typography>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Descripción:
          </Typography>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].PRODUCTO
              ? props.dataOrdesBicciExpress[0].PRODUCTO
              : props.dataOrdesBicciExpress[0].productName}
          </Typography>
          <hr></hr>
          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            Valor : ${" "}
          </Typography>

          <Typography
            fontFamily="Lato, Arial, Helvetica, sans-serif"
            color="textPrimary"
            gutterBottom
            variant="body"
          >
            {props.dataOrdesBicciExpress[0].VALOR
              ? props.dataOrdesBicciExpress[0].VALOR
              : props.dataOrdesBicciExpress[0].productValue}
          </Typography>
        </>
      ) : (
        <>No data...</>
      )}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Cantidad:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetailsCliente
          ? props.orderDetailsCliente.quantity_packages
          : null}
      </Typography>
    </>
  );

  dataDriver = (
    <>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        ID:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.driverID}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Foto:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        <img
          id="imgavatar"
          src={props.orderDetails.driverAvatar}
          alt={""}
        ></img>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Nombre:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.driverName}
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Teléfono:
      </Typography>{" "}
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        {props.orderDetails.driverPhone}
      </Typography>
    </>
  );

  actionsOrders = (
    <>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Crear un registro interno de soporte, Casos de error sistema,
        incumplimentos de protocolos o reclamos del cliente.
        <Form onSubmit={createTicketSuport}>
          {" "}
          <Form.Field
            control={Input}
            placeholder="Agregar descripción del caso"
            iconPosition="left"
            type="text"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            icon="edit"
          />
          <Button
            sx={{
              backgroundColor: "#591E8F",
              border: "none",
              borderRadius: "3px",
              color: "#fff",
              cursor: "pointer",
              fontSize: "14px",
              marginTop: "30px",
              outline: "none",
              padding: "5px",
              minWidth: "50px",
              fontWeight: 400,
              margin: "0em 1em 0em 0em",
              ":hover": {
                backgroundColor: "#25b2f4 !important",
                color: "#591e8f",
              },
            }}
            color="primary"
            variant="contained"
            type="sutmit"
          >
            Agregar nota de seguimiento
          </Button>
        </Form>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de primer intento fallido solicitud de contacto.
        <div>
          <Form onSubmit={createEmailclient}>
            {" "}
            <input type="hidden" name="uniqueId" value={"primerintento"} />
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de primer intento
            </Button>
          </Form>
        </div>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de segundo intento fallido solicitud de contacto.
        <div>
          <Form onSubmit={createEmailclient}>
            {" "}
            <input type="hidden" name="uniqueId" value={"segundointento"} />
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de segundo intento
            </Button>
          </Form>
        </div>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de tercer intento fallido solicitud de contacto.
        <div>
          <Form onSubmit={createEmailclient}>
            <input type="hidden" name="uniqueId" value={"tercerintento"} />{" "}
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de tercer intento
            </Button>
          </Form>
        </div>
      </Typography>
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar maqueta email de confirmación de dirección destino - Paquetes
        con dirección no legible.
        <div>
          <Form onSubmit={createEmailclient}>
            <input type="hidden" name="uniqueId" value={"confirmacion1"} />{" "}
            {/* <Form.Field
              control={Input}
              placeholder="Dirección en el cuerpo del mensaje"
              iconPosition="left"
              type="text"
              onChange={(e) => setConfirmDirection(e.target.value)}
              value={confirmDireccion}
              icon="edit"
            /> */}
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email de confirmación de nuevo destino
            </Button>
          </Form>
        </div>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Generar Autorización de cambio de domicilio.
        <div>
          <Form onSubmit={createEmailclient}>
            <input type="hidden" name="uniqueId" value={"confirmacion2"} />{" "}
            <Form.Field
              control={Input}
              placeholder="Dirección en el cuerpo del mensaje"
              iconPosition="left"
              type="text"
              onChange={(e) => setConfirmDirection(e.target.value)}
              value={confirmDireccion}
              icon="edit"
            />
            <Button
              sx={{
                backgroundColor: "#591E8F",
                border: "none",
                borderRadius: "3px",
                color: "#fff",
                cursor: "pointer",
                fontSize: "14px",
                marginTop: "30px",
                outline: "none",
                padding: "5px",
                minWidth: "50px",
                fontWeight: 400,
                margin: "0em 1em 0em 0em",
                ":hover": {
                  backgroundColor: "#25b2f4 !important",
                  color: "#591e8f",
                },
              }}
              color="primary"
              variant="contained"
              type="sutmit"
            >
              crear email Autorización de cambio de domicilio.
            </Button>
          </Form>
        </div>
      </Typography>{" "}
      <hr></hr>
      <Typography
        fontFamily="Lato, Arial, Helvetica, sans-serif"
        color="textPrimary"
        gutterBottom
        variant="body"
      >
        Editar dirección con error.
        <div>Dirección actual:</div>
        <div>{props.orderDetails.toAddress}</div>
        <Form onSubmit={addNewAddres}>
          {" "}
          <Form.Field
            control={Input}
            placeholder="Agregar nueva dirección"
            iconPosition="left"
            type="text"
            onChange={(e) => setNewDirection(e.target.value)}
            value={dirección}
            icon="edit"
          />
          {dirección && dirección.length > 9 ? (
            <>
              {" "}
              <Button
                sx={{
                  backgroundColor: "#591E8F",
                  border: "none",
                  borderRadius: "3px",
                  color: "#fff",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginTop: "30px",
                  outline: "none",
                  padding: "5px",
                  minWidth: "50px",
                  fontWeight: 400,
                  margin: "0em 1em 0em 0em",
                  ":hover": {
                    backgroundColor: "#25b2f4 !important",
                    color: "#591e8f",
                  },
                }}
                color="primary"
                variant="contained"
                type="sutmit"
              >
                Guardar nueva dirección
              </Button>
            </>
          ) : null}
        </Form>
      </Typography>{" "}
      <hr></hr>
      <Form onSubmit={addNewGeoPiont}>
        <Typography
          fontFamily="Lato, Arial, Helvetica, sans-serif"
          color="textPrimary"
          gutterBottom
          variant="body"
        >
          Ajustar Geo referencia de entrega.
          <div>Geo referencia actual:</div>
          {props.orderDetails && props.orderDetails.toAddressLocation ? (
            <div>
              Lat: {props.orderDetails.toAddressLocation.latitude.toString()},
              Lng: {props.orderDetails.toAddressLocation.longitude.toString()}
            </div>
          ) : null}
          {newLocation && newLocation.lat ? (
            <div>
              <div>Nueva Geo referencia</div>
              <div>
                <div>
                  Lat: {newLocation.lat.toString()}, Lng:{" "}
                  {newLocation.lng.toString()}
                </div>
              </div>

              <Button
                sx={{
                  backgroundColor: "#591E8F",
                  border: "none",
                  borderRadius: "3px",
                  color: "#fff",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginTop: "30px",
                  outline: "none",
                  padding: "5px",
                  minWidth: "50px",
                  fontWeight: 400,
                  margin: "0em 1em 0em 0em",
                  ":hover": {
                    backgroundColor: "#25b2f4 !important",
                    color: "#591e8f",
                  },
                }}
                color="primary"
                variant="contained"
                type="sutmit"
              >
                Guardar Geo Referencia
              </Button>
            </div>
          ) : null}
        </Typography>{" "}
        <br></br>
        <Typography
          fontFamily="Lato, Arial, Helvetica, sans-serif"
          color="textPrimary"
          gutterBottom
          variant="body"
        >
          {props && props.orderDetails ? (
            <SelectorGeoRecf
              markersPending={props.orderDetails.toAddressLocation}
              setNewLocation={setNewLocation}
            ></SelectorGeoRecf>
          ) : null}
        </Typography>{" "}
      </Form>
      <hr></hr>
    </>
  );

  logDeCambios = (
    <>
      {storePayload && storePayload.upload === true ? (
        <>
          {storePayload.logs.map((item, index) => (
            <Card
              key={index}
              sx={{ margin: "3px", padding: "3px" }}
              variant="outlined"
            >
              <div>{format(item.date.seconds * 1000, "dd-MM-yy")}</div>
              <div>Usuario </div>
              <div>{item.userId}</div>
              <div>Accion: {item.var}</div>
              <div>
                {typeof item.lastValue === "object" &&
                item.lastValue !== null ? (
                  <>Geopunto</>
                ) : (
                  <>
                    {" "}
                    <div>valor anterior: {item.lastValue} </div>
                    <div>nuevo valor {item.newValue}</div>
                  </>
                )}
              </div>
            </Card>
          ))}
        </>
      ) : (
        <>Sin cambios</>
      )}
    </>
  );

  if (loadError) {
    console.log("Maps Error");
    return "Error";
  }
  if (!isLoaded) {
    // console.log("Loading OK");
    return "Loading...";
  }

  return orderID !== null ? (
    <Tab menu={{ secondary: true }} panes={panes}></Tab>
  ) : (
    <LinearProgress></LinearProgress>
  );
};

export default EditOrderFrom;
