export const order = {
  id: "isRequire",
  companyId: "isRequire",
  company: "isRequire",
  commerce: "isRequire",
  int_warehouseCode: "isRequire",
  int_warehouseName: "isRequire",
  int_placeName: "isRequire",
  int_travelDate: "isRequire",
  int_windowStart: "isRequire",
  int_windowEnd: "isRequire",
  int_serviceLevel: "isRequire",
  int_f12: "isRequire",
  int_travelNumber: "isRequire",
  int_client_soc: "isRequire",
  int_serviceType: "isRequire",
  int_clientDescriptor: "isRequire",
  int_simpliRouteId: "isRequire",
  int_bicciStatus: "isRequire",
  int_falabella_status: "isRequire",
  int_falabella_status_descriptor: "isRequire",
  int_scheduledDateRange: "isRequire",
  int_lpn: "isRequire",
  fromAddress: "isRequire",
  fromAddressLocation: "isRequire",
  userEmail: "isRequire",
  userID: "isRequire",
  userName: "isRequire",
  userPhone: "isRequire",
  clientNames: "isRequire",
  clientRut: "isRequire",
  clientEmail: "isRequire",
  toAddress: "isRequire",
  toAddressLocation: "isRequire",
  direccion1: "isRequire",
  direccion2: "isRequire",
  direccion3: "isRequire",
  commune: "isRequire",
  commune2: "isRequire",
  region: "isRequire",
  serviceType: "isRequire",
  deliveryDate: "isRequire",
  paymentRequestId: "isRequire",
  origin: "isRequire",
  createdAt: "isRequire",
  statusCreate: "isRequire",
  status: "isRequire",
  description: "isRequire",
  zonePoligon: "isRequire",
  pickedUp: "isRequire",
  serviceLevel: "isRequire",
  polygonID: "isRequire",
  productLength: "isRequire",
  productHeight: "isRequire",
  productWidth: "isRequire",
  productWeight: "isRequire",
  productName: "isRequire",
  productSku: "isRequire",
  productSize: "isRequire",
  productUnits: "isRequire",
  productValue: "isRequire",
  productVolume: "isRequire",
  productMultiDelivery: "isRequire",
  driverTruckDescription: "isRequire",
  driverAvatar: "isRequire",
  driverEmail: "isRequire",
  driverName: "isRequire",
  driverRut: "isRequire",
  driverID: "isRequire",
  driverPhone: "isRequire",
  driverLocation: "isRequire",
  driverLicensePlate: "isRequire",
  driverAmountDriver: "isRequire",
  driverAmountPromotions: "isRequire",
  driverAmountOtherEarnings: "isRequire",
  driverVehicle: "isRequire",
  routeRanceTotalization: "isRequire",
  routeEstimatedDate: "isRequire",
  routeStartDate: "isRequire",
  routeScheduledDate: "isRequire",
  routeEndTime: "isRequire",
  routeStartTime: "isRequire",
  routeId: "isRequire",
  routePosition: "isRequire",
  routeLocality: "isRequire",
  deliveryMethod: "isRequire",
  deliveryFailureReason: "isRequire",
  deliveryPictures: "isRequire",
  deliveryRealDeliveryDate: "isRequire",
  deliveryComment: "isRequire",
  deliveryReceptor: "isRequire",
  deliveryRut: "isRequire",
  deliveryGeoref: "isRequire",
  billingCurrencyCode: "isRequire",
  billingCurrencySymbol: "isRequire",
  billingPaymentRequestType: "isRequire",
  billingPaymentProforma: "isRequire",
  billingPaymentValor: "isRequire",
  billingPaymentType_service: "isRequire",
};

export const newOrderValue = [
  {
    variable: "id",
    label: "Id",
    type: "string",
    require: true,
  },
  {
    variable: "companyId",
    label: "Company Id",
    type: "string",
    require: true,
  },
  {
    variable: "company",
    label: "Company",
    type: "string",
    require: true,
  },
  {
    variable: "commerce",
    label: "Commerce",
    type: "string",
    require: true,
  },
  {
    variable: "int_warehouseCode",
    label: "Int_warehouse Code",
    type: "string",
    require: true,
  },
  {
    variable: "int_warehouseName",
    label: "Int_warehouse Name",
    type: "string",
    require: true,
  },
  {
    variable: "int_placeName",
    label: "Int_place Name",
    type: "string",
    require: true,
  },
  {
    variable: "int_travelDate",
    label: "Int_travel Date",
    type: "string",
    require: true,
  },
  {
    variable: "int_windowStart",
    label: "Int_window Start",
    type: "string",
    require: true,
  },
  {
    variable: "int_windowEnd",
    label: "Int_window End",
    type: "string",
    require: true,
  },
  {
    variable: "int_serviceLevel",
    label: "Int_service Level",
    type: "string",
    require: true,
  },
  {
    variable: "int_f12",
    label: "Int_f12",
    type: "string",
    require: true,
  },
  {
    variable: "int_travelNumber",
    label: "Int_travel Number",
    type: "string",
    require: true,
  },
  {
    variable: "int_client_soc",
    label: "Int_client_soc",
    type: "string",
    require: true,
  },
  {
    variable: "int_serviceType",
    label: "Int_service Type",
    type: "string",
    require: true,
  },
  {
    variable: "int_clientDescriptor",
    label: "Int_client Descriptor",
    type: "string",
    require: true,
  },
  {
    variable: "int_simpliRouteId",
    label: "Int_simpli Route Id",
    type: "string",
    require: true,
  },
  {
    variable: "int_bicciStatus",
    label: "Int_bicci Status",
    type: "string",
    require: true,
  },
  {
    variable: "int_falabella_status",
    label: "Int_falabella_status",
    type: "string",
    require: true,
  },
  {
    variable: "int_falabella_status_descriptor",
    label: "Int_falabella_status_descriptor",
    type: "string",
    require: true,
  },
  {
    variable: "int_scheduledDateRange",
    label: "Int_scheduled Date Range",
    type: "string",
    require: true,
  },
  {
    variable: "int_lpn",
    label: "Int_lpn",
    type: "string",
    require: true,
  },
  {
    variable: "fromAddress",
    label: "From Address",
    type: "string",
    require: true,
  },
  {
    variable: "fromAddressLocation",
    label: "From Address Location",
    type: "string",
    require: true,
  },
  {
    variable: "userEmail",
    label: "User Email",
    type: "string",
    require: true,
  },
  {
    variable: "userID",
    label: "User ID",
    type: "string",
    require: true,
  },
  {
    variable: "userName",
    label: "User Name",
    type: "string",
    require: true,
  },
  {
    variable: "userPhone",
    label: "User Phone",
    type: "string",
    require: true,
  },
  {
    variable: "clientNames",
    label: "Client Names",
    type: "string",
    require: true,
  },
  {
    variable: "clientRut",
    label: "Client Rut",
    type: "string",
    require: true,
  },
  {
    variable: "clientEmail",
    label: "Client Email",
    type: "string",
    require: true,
  },
  {
    variable: "toAddress",
    label: "To Address",
    type: "string",
    require: true,
  },
  {
    variable: "toAddressLocation",
    label: "To Address Location",
    type: "string",
    require: true,
  },
  {
    variable: "direccion1",
    label: "Direccion1",
    type: "string",
    require: true,
  },
  {
    variable: "direccion2",
    label: "Direccion2",
    type: "string",
    require: true,
  },
  {
    variable: "direccion3",
    label: "Direccion3",
    type: "string",
    require: true,
  },
  {
    variable: "commune",
    label: "Commune",
    type: "string",
    require: true,
  },
  {
    variable: "commune2",
    label: "Commune2",
    type: "string",
    require: true,
  },
  {
    variable: "region",
    label: "Region",
    type: "string",
    require: true,
  },
  {
    variable: "serviceType",
    label: "Service Type",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryDate",
    label: "Delivery Date",
    type: "string",
    require: true,
  },
  {
    variable: "paymentRequestId",
    label: "Payment Request Id",
    type: "string",
    require: true,
  },
  {
    variable: "origin",
    label: "Origin",
    type: "string",
    require: true,
  },
  {
    variable: "createdAt",
    label: "Created At",
    type: "string",
    require: true,
  },
  {
    variable: "statusCreate",
    label: "Status Create",
    type: "string",
    require: true,
  },
  {
    variable: "status",
    label: "Status",
    type: "string",
    require: true,
  },
  {
    variable: "description",
    label: "Description",
    type: "string",
    require: true,
  },
  {
    variable: "zonePoligon",
    label: "Zone Poligon",
    type: "string",
    require: true,
  },
  {
    variable: "pickedUp",
    label: "Picked Up",
    type: "string",
    require: true,
  },
  {
    variable: "serviceLevel",
    label: "Service Level",
    type: "string",
    require: true,
  },
  {
    variable: "polygonID",
    label: "Polygon ID",
    type: "string",
    require: true,
  },
  {
    variable: "productLength",
    label: "Product Length",
    type: "string",
    require: true,
  },
  {
    variable: "productHeight",
    label: "Product Height",
    type: "string",
    require: true,
  },
  {
    variable: "productWidth",
    label: "Product Width",
    type: "string",
    require: true,
  },
  {
    variable: "productWeight",
    label: "Product Weight",
    type: "string",
    require: true,
  },
  {
    variable: "productName",
    label: "Product Name",
    type: "string",
    require: true,
  },
  {
    variable: "productSku",
    label: "Product Sku",
    type: "string",
    require: true,
  },
  {
    variable: "productSize",
    label: "Product Size",
    type: "string",
    require: true,
  },
  {
    variable: "productUnits",
    label: "Product Units",
    type: "string",
    require: true,
  },
  {
    variable: "productValue",
    label: "Product Value",
    type: "string",
    require: true,
  },
  {
    variable: "productVolume",
    label: "Product Volume",
    type: "string",
    require: true,
  },
  {
    variable: "productMultiDelivery",
    label: "Product Multi Delivery",
    type: "string",
    require: true,
  },
  {
    variable: "driverTruckDescription",
    label: "Driver Truck Description",
    type: "string",
    require: true,
  },
  {
    variable: "driverAvatar",
    label: "Driver Avatar",
    type: "string",
    require: true,
  },
  {
    variable: "driverEmail",
    label: "Driver Email",
    type: "string",
    require: true,
  },
  {
    variable: "driverName",
    label: "Driver Name",
    type: "string",
    require: true,
  },
  {
    variable: "driverRut",
    label: "Driver Rut",
    type: "string",
    require: true,
  },
  {
    variable: "driverID",
    label: "Driver ID",
    type: "string",
    require: true,
  },
  {
    variable: "driverPhone",
    label: "Driver Phone",
    type: "string",
    require: true,
  },
  {
    variable: "driverLocation",
    label: "Driver Location",
    type: "string",
    require: true,
  },
  {
    variable: "driverLicensePlate",
    label: "Driver License Plate",
    type: "string",
    require: true,
  },
  {
    variable: "driverAmountDriver",
    label: "Driver Amount Driver",
    type: "string",
    require: true,
  },
  {
    variable: "driverAmountPromotions",
    label: "Driver Amount Promotions",
    type: "string",
    require: true,
  },
  {
    variable: "driverAmountOtherEarnings",
    label: "Driver Amount Other Earnings",
    type: "string",
    require: true,
  },
  {
    variable: "driverVehicle",
    label: "Driver Vehicle",
    type: "string",
    require: true,
  },
  {
    variable: "routeRanceTotalization",
    label: "Route Rance Totalization",
    type: "string",
    require: true,
  },
  {
    variable: "routeEstimatedDate",
    label: "Route Estimated Date",
    type: "string",
    require: true,
  },
  {
    variable: "routeStartDate",
    label: "Route Start Date",
    type: "string",
    require: true,
  },
  {
    variable: "routeScheduledDate",
    label: "Route Scheduled Date",
    type: "string",
    require: true,
  },
  {
    variable: "routeEndTime",
    label: "Route End Time",
    type: "string",
    require: true,
  },
  {
    variable: "routeStartTime",
    label: "Route Start Time",
    type: "string",
    require: true,
  },
  {
    variable: "routeId",
    label: "Route Id",
    type: "string",
    require: true,
  },
  {
    variable: "routePosition",
    label: "Route Position",
    type: "string",
    require: true,
  },
  {
    variable: "routeLocality",
    label: "Route Locality",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryMethod",
    label: "Delivery Method",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryFailureReason",
    label: "Delivery Failure Reason",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryPictures",
    label: "Delivery Pictures",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryRealDeliveryDate",
    label: "Delivery Real Delivery Date",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryComment",
    label: "Delivery Comment",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryReceptor",
    label: "Delivery Receptor",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryRut",
    label: "Delivery Rut",
    type: "string",
    require: true,
  },
  {
    variable: "deliveryGeoref",
    label: "Delivery Georef",
    type: "string",
    require: true,
  },
  {
    variable: "billingCurrencyCode",
    label: "Billing Currency Code",
    type: "string",
    require: true,
  },
  {
    variable: "billingCurrencySymbol",
    label: "Billing Currency Symbol",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentRequestType",
    label: "Billing Payment Request Type",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentProforma",
    label: "Billing Payment Proforma",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentValor",
    label: "Billing Payment Valor",
    type: "string",
    require: true,
  },
  {
    variable: "billingPaymentType_service",
    label: "Billing Payment Type_service",
    type: "string",
    require: true,
  },
];
