import { db } from "../firebase";
import axios from "axios";
import moment from "moment";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";

// data inicial
const dataInicial = {
  loading: false,
  activo: false,
};

const LOADING_MOMENT = "LOADING_MOMENT";
const EDIT_COMPANY_EBIEX = "EDIT_COMPANY_EBIEX";
const EDIT_COMPANY_ERROR = "EDIT_COMPANY_ERROR";
const EDIT_COMPANY_EXITO = "EDIT_COMPANY_EXITO";
// const SUPPRESS_USERUSER_EBIEX = 'SUPPRESS_USERUSER_EBIEX'
const FIND_INFORMATION_COMPANY_ERROR = "FIND_INFORMATION_COMPANY_ERROR";
const CREATE_PURCHARSE_ERROR = "CREATE_PURCHARSE_ERROR";
const CREATE_PURCHARSE_SUCCES = "CREATE_PURCHARSE_SUCCES";

export default function usuarioClienteCompanyEbiex(
  state = dataInicial,
  action
) {
  switch (action.type) {
    case LOADING_MOMENT:
      return { ...state, loading: true };
    case EDIT_COMPANY_EBIEX:
      return { ...state, user: action.payload };
    case EDIT_COMPANY_EXITO:
      return { ...state };
    case EDIT_COMPANY_ERROR:
      return { loading: false };
    // case SUPPRESS_USERUSER_EBIEX:
    //     return {...state, error:action.error}
    case CREATE_PURCHARSE_SUCCES:
      return { ...state, succesPurcharse: action.payload };
    case CREATE_PURCHARSE_ERROR:
      return { ...state, errorPurcharse: action.errorPurcharse };
    case FIND_INFORMATION_COMPANY_ERROR:
      return { error: action.mjsstatus };
    default:
      return state;
  }
}

export const createNewCompany = (data) => async (dispatch) => {
  console.log("createNewCompany", data);

  try {
    const companyCode = data.companyCodefolder;

    // Crea el documento de la empresa
    const docRef = doc(
      collection(db, process.env.REACT_APP_COL_COMPANIES),
      companyCode
    ); // Usar companyCode como ID del documento si es necesario

    await setDoc(docRef, {
      createByUid: data.uid,
      propietary: data.propietary,
      companyID: data.companyID,
      companyName: data.companyName,
      companyRut: data.companyRut,
      companyAddress: data.companyAddress,
      companyNumAddress: data.companyNumAddress,
      companyRegion: data.companyRegion,
      companyCity: data.companyCity,
      companyComuna: data.companyComuna,
      companyCountry: data.companyCountry,
      companyCountryCode: data.companyCountryCode,
      companyActividad: data.companyActividad,
      companyContacName: data.companyContacName,
      companyContacEmail: data.companyContacEmail,
      companyAdressPhone: data.companyAdressPhone,
      adressGeoNumAddressLat: data.adressGeoNumAddressLat,
      adressGeoNumAddressLng: data.adressGeoNumAddressLng,
      companyCodefolder: data.companyCodefolder,
      stausDocument: data.stausDocument,
      estatusCRM: data.estatusCRM,
      isActive: data.isActive,
      servicie: data.servicie,
      servicetype: data.servicetype,
      stausService: data.stausService,
      currentPayMethods: data.currentPayMethods,
      payPendings: data.payPendings,
      suportTikets: data.suportTikets,
      createdate: data.createdate,
      createdateAdd: data.createdateAdd,
      relateCRM: "",
      companyImgLogo: "",
      companyImgRut: "",
      companyImgDocsComercio: "",
      companyImgDocsDocsii: "",
      companyImgDocsAcuerdo: "",
      companyImgDocsPatente: "",
      statusDocumentos: false,
      statusDocsLogo: false,
      statusDocsRut: false,
      statusDocsComercio: false,
      statusDocsDocsii: false,
      statusDocsAcuerdo: false,
      statusDocsPatente: false,
    });

    // Actualiza el usuario con la información de la empresa
    await updateDoc(
      doc(db, `${process.env.REACT_APP_COL_USERS}/${data.propietary}`),
      {
        userCompany: data.companyName,
        userCompanyID: companyCode,
        userCompanyRoll: process.env.REACT_APP_ADMIN_COMPANY,
        roll: process.env.REACT_APP_ADMIN_COMPANY,
      }
    );

    // Crea el documento de facturación
    const aviableServ = await getDocs(
      collection(db, process.env.REACT_APP_COL_AVIABLE_SERV)
    );

    const dataResult = aviableServ.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const nextSuspensionDate = new Date();
    nextSuspensionDate.setMonth(nextSuspensionDate.getMonth() + 1);

    const model17 = {
      companyUid: companyCode,
      payOptions: "prepago",
      isActiveBilling: true,
      MaxCredit: 5,
      maxCoverage: 2,
      lastRecharge: data.createdateAdd,
      TimeStamp: data.createdate,
      shippingQuotas: 5,
      currentPlan: "",
      currentPack: "",
      pendingPayment: 0,
      payMethod: "directTransfer",
      companyName: data.companyName,
      companyRut: data.companyID,
      companyPropietary: data.propietary,
      accountExecutive: "userID",
      shipmentsAvailable: 0,
      suspensionDays: 0,
      dayBilling: 0,
      expirationDate: 0,
      giftDays: 0,
      nextSuspensionDate: nextSuspensionDate,
      availableservices: dataResult,
    };

    await addDoc(collection(db, process.env.REACT_APP_COL_BILLING), model17);

    // Crea el documento de balance
    const model16 = {
      companyUid: companyCode,
      isActiveWallet: true,
      totalSend: 0,
      totalCompletes: 0,
      totalSendAviables: 0,
      balance: 0,
      MaxCredit: 0,
      maxCoverage: 2,
      lastRecharge: new Date(),
      TimeStamp: Date.now(),
      shippingQuotas: 5,
      pendingPayment: 0,
      currentPlan: "",
      currentPack: "",
    };
    await addDoc(collection(db, process.env.REACT_APP_COL_BALANCE), model16);
  } catch (error) {
    console.error("Error al crear la empresa:", error);
    dispatch({
      type: EDIT_COMPANY_ERROR,
    });
  }
};

export const actualizarNewCompany = (data) => async (dispatch) => {
  console.log("actualizarNewCompany", data);
  return;
  try {
    console.log(data);
    var name = data.companyCodefolder;
    const collRef = collection(db, `${process.env.REACT_APP_COL_COMPANIES}`);
    const docRef = doc(collRef, data.companyID);
    const geopoint = new GeoPoint(
      data.addressGeoreft.lat,
      data.addressGeoreft.lng
    );

    await updateDoc(docRef, {
      companyName: data.companyName,
      companyRut: data.companyRut,
      companyCodefolder: data.companyID,
      companyAddress: data.companyAddress,
      direccion1: data.direccion1,
      direccion2: data.direccion2,
      companyNumAddress: data.companyNumAddress,
      companyRegion: data.companyRegion,
      companyCity: data.companyCity,
      companyComuna: data.companyComuna,
      companyCountry: data.companyCountry,
      companyCountryCode: data.companyCountryCode,
      companyActividad: data.companyActividad,
      companyContacName: data.companyContacName,
      companyContacEmail: data.companyContacEmail,
      companyAdressPhone: data.companyAdressPhone,
      addressGeoreft: geopoint,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: EDIT_COMPANY_ERROR,
    });
  }
  /*
  try {
    const aviableServ = await getDocs(
      collection(db, process.env.REACT_APP_COL_AVIABLE_SERV)
    );

    var dataResult = aviableServ.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    var wallet = data.companyCodefolder;
    var nextSuspensionDate = new Date();
    nextSuspensionDate.setMonth(nextSuspensionDate.getMonth() + 1);
    var model17 = {
      companyUid: name,
      payOptions: "prepago",
      isActiveBilling: true,
      MaxCredit: 5,
      maxCoverage: 2, // cal
      lastRecharge: data.createdateAdd,
      TimeStamp: data.createdate,
      shippingQuotas: 5, // cal
      currentPlan: "",
      currentPack: "",
      pendingPayment: 0, // cal
      payMethod: "directTransfer",
      companyName: data.companyName,
      companyRut: data.companyID,
      companyPropietary: data.propietary,
      accountExecutive: "userID",
      shipmentsAvailable: 0, // cal
      suspensionDays: 0,
      dayBilling: 0,
      expirationDate: 0,
      giftDays: 0,
      nextSuspensionDate: nextSuspensionDate, // cal
      t001holidaydelivery3000: false,
      t001outoftime3000: false,
      t001radioof3000: false,
      t001pack10x203000: false,
      t001pack20x203000: false,
      t001pack30x303000: false,
      t001pack40x403000: false,
      t002holidaydelivery3000: false,
      t002outoftime3000: false,
      t002radioof3000: false,
      t002pack10x203000: false,
      t002pack20x203000: false,
      t002pack30x303000: false,
      t002pack40x403000: false,
      t003holidaydelivery3000: false,
      t003outoftime3000: false,
      t003radioof3000: false,
      t003pack10x203000: false,
      t003pack20x203000: false,
      t003pack30x303000: false,
      t003pack40x403000: false,
      t004holidaydelivery3000: false,
      t004outoftime3000: false,
      t004radioof3000: false,
      t004pack10x203000: false,
      t004pack20x203000: false,
      t004pack30x303000: false,
      t004pack40x403000: false,
      availableservices: dataResult,
    };
    await addDoc(collection(db, `${process.env.REACT_APP_COL_BILLING}`), {
      id: wallet,
      ...model17,
    });
  } catch (error) {
    console.log(error);
  }

  try {
    var balanceCode = data.companyCodefolder;
    var dateBalance = new Date();
    nextSuspensionDate.setMonth(nextSuspensionDate.getMonth() + 1);
    // console.log(nextSuspensionDate)
    // console.log(moment(nextSuspensionDate).format('DD MMMM YYYY, h:mm:ss a'))

    var model16 = {
      companyUid: balanceCode,
      isActiveWallet: true,
      totalSend: 0,
      totalCompletes: 0,
      totalSendAviables: 0,
      balance: 0,
      MaxCredit: 0,
      maxCoverage: 2,
      lastRecharge: dateBalance,
      TimeStamp: Date.now(),
      shippingQuotas: 5,
      pendingPayment: 0,
      currentPlan: "",
      currentPack: "",
    };
    await addDoc(collection(db, `${process.env.REACT_APP_COL_BALANCE}`), {
      id: balanceCode,
      ...model16,
    });
  } catch (error) {
    console.log(error);
  }
*/
};

export const actualizarDatosdeCompany = (data) => async (dispatch) => {
  // console.log(data);
  console.log("actualizarDatosdeCompany", data);
  return;
  try {
    var name = data.companyCodefolder;

    await updateDoc(doc(db, `${process.env.REACT_APP_COL_COMPANIES}/${name}`), {
      companyName: data.companyName,
      companyRut: data.companyRut,
      companyAddress: data.companyAddress,
      direccion1: data.direccion1,
      direccion2: data.direccion2,
      companyNumAddress: data.companyNumAddress,
      companyRegion: data.companyRegion,
      companyCity: data.companyCity,
      companyComuna: data.companyComuna,
      companyCountry: data.companyCountry,
      companyCountryCode: data.companyCountryCode,
      companyActividad: data.companyActividad,
      companyContacName: data.companyContacName,
      companyContacEmail: data.companyContacEmail,
      companyAdressPhone: data.companyAdressPhone,
      adressGeoNumAddressLat: data.adressGeoNumAddressLat,
      adressGeoNumAddressLng: data.adressGeoNumAddressLng,
    });
    // console.log(respon);
  } catch (error) {
    // console.log(error);
    dispatch({
      type: EDIT_COMPANY_ERROR,
    });
  }
};

export const deletePointADireccionForCompany = (data) => async (dispatch) => {
  console.log("its Fine deletePointADireccionForCompany");
  console.log(data);
  try {
    // await db.collection(`${process.env.REACT_APP_COL_COMPANIES}`).doc(data.companyUid).collection('direcciones').doc(data.id).delete()
    await updateDoc(
      doc(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${data.companyID}/direcciones/${data.id}`
      ),
      { delete: true }
    );

    console.log("it delete");
  } catch (error) {
    console.log(error);
  }
};

export const crearPointADireccionForCompany = (data) => async (dispatch) => {
  try {
    const docRef = doc(
      collection(
        db,
        `${process.env.REACT_APP_COL_COMPANIES}/${data.companyID}/direcciones`
      )
    );
    await setDoc(docRef, { ...data, id: docRef.id, delete: false });
    console.log("documentocreado");
  } catch (error) {
    console.error("Error al crear :", error);
  }
};

export const updatePointADireccionForCompany = (data) => async (dispatch) => {
  try {
    const directionCollRef = collection(
      db,
      `${process.env.REACT_APP_COL_COMPANIES}/${data.companyID}/direcciones`
    );
    if (data.id) {
      const documentRef = doc(directionCollRef, data.id);
      const documentSnap = await getDoc(documentRef);
      if (documentSnap.exists()) {
        console.log("documento existe");
        await updateDoc(documentRef, data);
      } else {
        console.log("documento NO existe, creando uno nuevo");
        await setDoc(documentRef, { ...data, id: documentRef.id });
      }
    } else {
      console.log("Falta el ID del documento para actualizar o crear");
      const docRef = doc(
        collection(
          db,
          `${process.env.REACT_APP_COL_COMPANIES}/${data.companyID}/direcciones`
        )
      );
      await setDoc(docRef, { ...data, id: docRef.id });
      console.log("documentocreado");
    }
  } catch (error) {
    console.log("Error al actualizar o crear el documento:", error);
  }
};

export const purchaseOrderEbiex = (data) => async (dispatch) => {
  let ebixeOrder = {
    purchaseOrderId: "",
    get bixeOrderID() {
      return this.purchaseOrderId;
    },
    set bixeOrderID(id) {
      this.purchaseOrderId = id;
    },
  };

  //   dispatch({
  //     type: LOADING_MOMENT,
  //   });

  //xpressPurchasesummaries
  //xpressGlobalsBillings

  //xpressshippingWalletBalance
  //xpressCompanybillingAccount

  const productoDetails = data.productoDetails;
  const typebilling = data.typebilling;
  const billimgAdress = data.billimgAdress;
  const statusOrder = data.status;
  const statusLabel = data.statusLabel;
  const createdate = data.createdate;
  const createdateAdd = data.createdateAdd;
  const payMethodsId = data.payMethodsId;
  const payMethods = data.payMethods;
  const user = data.user.uid;
  const carContent = data.carContent.article;
  const buyOrder = "";
  const companyUid = data.assignCompany.companyID;
  const isParcial = false;
  const pendingPayment = 0;
  const cartArticleItem = data.carContent.idCart;
  const tranzascionID = "";
  const CodeTransfer = "";
  const urlTransferEvidence = "";
  const webpay_rest_response = "";
  const webpay_transaction_id = "";
  const transactionResponse = "";
  const transactionDate = "";
  const authorizationCode = "";
  const purchaseState = 0;
  const BillingClientCompany = data.assignCompany.companyName;
  const BillingClientName = data.assignCompany.companyContacName;
  const BillingClientLastName = data.assignCompany.companyContacName;
  const BillingDir1 = data.assignCompany.companyAddress;
  const BillingDir2 = data.assignCompany.companyNumAddress;
  const BillingDir3 = data.assignCompany.companyComuna;
  const BillingCity = data.assignCompany.companyRegion;
  //const BillingZip = data.assignCompany.BillingZip
  const BillingCountry = data.assignCompany.companyCountry;
  const BillingRegion = data.assignCompany.companyRegion;
  const BillingEmail = data.assignCompany.companyContacEmail;
  const BillingPhone = data.assignCompany.companyAdressPhone;
  const subtotal = data.carContent.subTotalItem;
  const totalShipping = 0;
  const discount = data.carContent.totalDiscount;
  const discountType = "data.carContent.totalDiscount type";
  const TotalOrder = data.carContent.cartArticlePrice;
  const paidAmount = data.carContent.totalAmount;
  const shippimgClientName = "shippimgClientName";
  const shippimgClientLastName = "shippimgClientLastName";
  const shippimgClientCompany = data.assignCompany.companyName;
  const shippimgDir1 = data.assignCompany.companyAddress;
  const shippimgDir2 = data.assignCompany.companyNumAddress;
  const shippimgDir3 = data.assignCompany.companyComuna;
  const shippimgCity = data.assignCompany.companyRegion;
  // const shippimgZip = data.assignCompany.BillingZip
  const shippimgCountry = data.assignCompany.companyCountry;
  const shippimgRegion = data.assignCompany.companyRegion;
  const shippimgEmail = data.assignCompany.companyContacEmail;
  const shippimgPhone = data.assignCompany.companyAdressPhone;
  const shippingNotes = data.shippingNotes;
  const ChargeShipments = data.carContent.TotalChargeShipments;

  const payObj = {
    productoDetails: productoDetails,
    typebilling: typebilling,
    billimgAdress: billimgAdress,
    statusOrder: statusOrder,
    statusLabel: statusLabel,
    createdate: createdate,
    createdateAdd: createdateAdd,
    payMethods: payMethods,
    payMethodsId: payMethodsId,
    user: user,
    carContent: carContent,
    delete: false,
    isBilling: false,
    buyOrder: buyOrder,
    companyUid: companyUid,
    isParcial: isParcial,
    pendingPayment: paidAmount,
    cartArticleItem: cartArticleItem,
    tranzascionID: tranzascionID,
    CodeTransfer: CodeTransfer,
    urlTransferEvidence: urlTransferEvidence,
    webpay_rest_response: webpay_rest_response,
    webpay_transaction_id: webpay_transaction_id,
    transactionResponse: transactionResponse,
    transactionDate: transactionDate,
    authorizationCode: authorizationCode,
    purchaseState: purchaseState,
    BillingClientCompany: BillingClientCompany,
    BillingClientName: BillingClientName,
    BillingClientLastName: BillingClientLastName,
    BillingDir1: BillingDir1,
    BillingDir2: BillingDir2,
    BillingDir3: BillingDir3,
    BillingCity: BillingCity,
    // "BillingZip": BillingZip,
    BillingCountry: BillingCountry,
    BillingRegion: BillingRegion,
    BillingEmail: BillingEmail,
    BillingPhone: BillingPhone,
    subtotal: subtotal,
    totalShipping: totalShipping,
    discount: discount,
    discountType: discountType,
    TotalOrder: TotalOrder,
    paidAmount: paidAmount,
    shippimgClientName: shippimgClientName,
    shippimgClientLastName: shippimgClientLastName,
    shippimgClientCompany: shippimgClientCompany,
    shippimgDir1: shippimgDir1,
    shippimgDir2: shippimgDir2,
    shippimgDir3: shippimgDir3,
    shippimgCity: shippimgCity,
    // "shippimgZip": shippimgZip,
    shippimgCountry: shippimgCountry,
    shippimgRegion: shippimgRegion,
    shippimgEmail: shippimgEmail,
    shippimgPhone: shippimgPhone,
    shippingNotes: shippingNotes,
    buyOrderId: "",
    ChargeShipments: ChargeShipments,
  };

  // console.log(payObj)
  try {
    const accountants = await getDoc(
      doc(db, `${process.env.REACT_APP_COL_ACCOUNTANS}/purchaseSummaries`)
    );
    const documenCont = accountants.data().count + 1;

    ebixeOrder.purchaseOrderId = String(documenCont);
    //const purchaseId = String(documenCont);
  } catch (error) {}

  try {
    const purchaseId = ebixeOrder.purchaseOrderId;

    await setDoc(
      doc(db, `${process.env.REACT_APP_COL_SUMARIES}/${purchaseId}`),
      payObj
    );
    await updateDoc(
      doc(db, `${process.env.REACT_APP_COL_SUMARIES}/${purchaseId}`),
      { buyOrderId: purchaseId }
    );

    const response = await axios.post(
      process.env.REACT_APP_BICCIXPRESS_API_DEV_TEST,
      payObj,
      {
        // Desarrollo
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(response)

    if (response.data.code === 200) {
      // console.log('dispach succesPurcharse')
      localStorage.removeItem("itemCard");
      dispatch({
        type: CREATE_PURCHARSE_SUCCES,
        payload: {
          succesPurcharse: true,
          purchaseId: purchaseId,
          statusPayment: "En espera",
        },
      });
    }
    await setDoc(
      doc(db, `${process.env.REACT_APP_COL_ACCOUNTANS}`, "purchaseSummaries"),
      { count: parseInt(purchaseId) }
    );
  } catch (error) {
    // console.log(error);
  }
};

export const confirmationBilling = (data) => async (dispatch) => {
  dispatch(updateGobalBilling(data));
  dispatch(updateshippingWalletBalance(data));
  dispatch(updateCompanybillingAccount(data));
};

export const updateGobalBilling = (data) => async (dispatch) => {
  // console.log("updateGobalBilling");
  // console.log(data)
  /*
  await db.collection(`${process.env.REACT_APP_COL_GLOBALBILLING }`).doc(purchaseId).set(data);
 
*/
};

export const updateshippingWalletBalance = (data) => async (dispatch) => {
  // console.log("updateshippingWalletBalance");
  // console.log(data)
  /*
  await db.collection(`${process.env.REACT_APP_COL_BILLING }`).doc(purchaseId).set(data);

*/
};

export const updateCompanybillingAccount = (data) => async (dispatch) => {
  // console.log("updateCompanybillingAccount");
  // console.log(data)
  const response = await axios.post(
    "http://localhost:5000/bicci-dev-c842f/us-central1/updateBillinCompany/",
    data,
    {
      // Desarrollo
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // console.log('Respuesta')
  // console.log(response)
};
