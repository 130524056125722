import PropTypes from "prop-types";
// @mui
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
// hooks
import { useBoolean } from "../../../hooks/use-boolean";
// components
import Label from "../../../components/Label/Label";
import Iconify from "../../../components/Iconify/Iconify";
import CustomPopover, { usePopover } from "../../../components/CustomPopover";
import { ConfirmDialog } from "../../../components/CustomDialog";
import UserDriversQuickEditForm from "./UserDriversQuickEditForm";
import { useNavigate } from "react-router-dom";

//import UserDriversQuickEditForm from "./UserDriversQuickEditForm";

// ----------------------------------------------------------------------

export default function UserDriverTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}) {
  const navigate = useNavigate();
  const handleNavigateRows = (dataRow) => {
    console.log(dataRow);
    navigate(`/dashboard/drivers/${dataRow.id}`);
  };

  const {
    name,
    avatar,
    company,
    bicciNumber,
    id,
    email,
    phone,
    online,
    sdkVersion,
  } = row;

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <Avatar alt={name} src={avatar} sx={{ mr: 2 }} />

          <ListItemText
            primary={name}
            secondary={bicciNumber}
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: "text.disabled",
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <ListItemText
            primary={phone}
            secondary={email}
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: "text.disabled",
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {" "}
          <ListItemText
            primary={id}
            secondary={<>sdkVersion: {sdkVersion}</>}
            primaryTypographyProps={{ typography: "body2" }}
            secondaryTypographyProps={{
              component: "span",
              color: "text.disabled",
            }}
          />
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (online === true && "success") ||
              (online === false && "warning") ||
              "default"
            }
          >
            {online ? "On Line" : "Of Line"}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: "nowrap" }}>
          <Tooltip title="Ver detalle" placement="top" arrow>
            <IconButton
              color={quickEdit.value ? "inherit" : "default"}
              //onClick={quickEdit.onTrue}
              onClick={() => handleNavigateRows(row)}
            >
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>

          {/* <IconButton
            color={popover.open ? "inherit" : "default"}
            onClick={popover.onOpen}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
        </TableCell>
      </TableRow>

      <UserDriversQuickEditForm
        currentUser={row}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserDriverTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
