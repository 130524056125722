import isEqual from "lodash/isEqual";
import { useState, useCallback } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import { useBoolean } from "../../hooks/use-boolean.js";
import Label from "../Label/Label.jsx";
import Iconify from "../Iconify/Iconify.jsx";
import Scrollbar from "../Scrollbar/Scrollbar.jsx";
import { ConfirmDialog } from "../CustomDialog/index.jsx";
import {
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../Table/index.jsx";
import UseTableOrdersBicci from "../Table/UseTableOrdersBicci.jsx";
//
import OrdersTableRow from "../Dashboard/dashboard/ComponentOrdersContainer/OrdersTableRow.jsx";
import OrdersTableToolbar from "../Dashboard/dashboard/ComponentOrdersContainer/OrdersTableToolbar.jsx";
import OrdersTableFiltersResult from "../Dashboard/dashboard/ComponentOrdersContainer/OrdersTableFiltersResult.jsx";
import { translateStatesSelectorBasic } from "../../utils/objects/translateStates.jsx";
import { useEffect } from "react";

// ----------------------------------------------------------------------

export default function UserListViewOrders(props) {
  const defaultRowsPerPage = 50;

  const [configTable, setConfigTable] = useState({
    defaultRowsPerPage: defaultRowsPerPage,
    defaultDense: true,
    ordersForAccions: props.ordersForAccions,
  });

  useEffect(() => {
    setConfigTable({
      defaultRowsPerPage: defaultRowsPerPage,
      defaultDense: true,
      ordersForAccions: props.ordersForAccions,
    });
  }, [props.ordersForAccions]);

  const table = UseTableOrdersBicci(configTable);

  const [userList, setUserList] = useState(props.dataOrdesRequets);

  const _roles = [];

  const _service = Array.isArray(props.dataOrdesRequests)
    ? props.dataOrdesRequests
        .map((obj) => obj?.int_serviceType || "DefaultServiceType") // Usar el operador opcional y un valor por defecto
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];

  const _rider = Array.isArray(props.dataOrdesRequests)
    ? props.dataOrdesRequests
        .map((obj) => obj?.driverName) // El operador ?. evitará errores si obj es undefined
        .filter((value, index, self) => value && self.indexOf(value) === index)
    : []; // Devolver un array vacío si dataOrdesRequests no está definido o no es un array

  const USER_STATUS_OPTIONS = translateStatesSelectorBasic;

  const USER_OPTIONS_OTERS = [
    { value: "create", label: "Creados" },
    { value: "assing", label: "Asignados" },
    { value: "route", label: "Enruta" },
    { value: "delivery", label: "Entregados" },
    { value: "reject", label: "Devolución" },
  ];
  // ----------------------------------------------------------------------

  const STATUS_OPTIONS = [
    { value: "all", label: "All" },
    ...USER_STATUS_OPTIONS,
  ];

  const TABLE_HEAD = [
    { id: "order", label: "datos de orden", width: "20%" },
    { id: "client", label: "Destino", width: "20%" },
    { id: "conductor", label: "rider", width: "20%" },
    { id: "estatus", label: "estatus", width: "10%" },
    props.visibleBilling
      ? { id: "facturacion", label: "facturacion", width: "20%" }
      : null,
    { id: "acciones", label: "acciones", width: "10%" },
  ];

  const defaultFilters = {
    name: "",
    role: [],
    rider: [],
    status: "all",
    origen: "",
    service: [],
    orderStatus: "allOrders",
    generalSearch: "",
    int_placeName: "",
    id: "",
    userEmail: "",
    deliveryReceptor: "",
    commerce: "",
    direccion1: "",
    deliveryFailureReason: "",
    commune2: "",
    clientNames: "",
    userID: "",
    companyId: "",
    userName: "",
    int_client_soc: "",
    int_lpn: "",
    toAddress: "",
    commune: "",
    key: "",
    driverVehicle: "",
    polygonID: "",
    dataOrigin: "",
    company: "",
    driverLocation: "",
    driverLicensePlate: "",
    origin: "",
    region: "",
    deliveryPictures: "",
    int_falabella_status: "",
    routeStartDate: "",
    orderID: "",
    int_placeName: "",
    int_travelDate: "",
    driverID: "",
    deliveryMethod: "",
    routeLocality: "",
    paymentRequestId: "",
    billingPaymentType_service: "",
    fromAddress: "",
    int_falabella_status_descriptor: "",
    int_serviceLevel: "",
    driverTruckDescription: "",
    serviceType: "",
    description: "",
    fromAddressLocation: "",
    int_travelNumber: "",
    driverRut: "",
    productName: "",
    driverEmail: "",
    productSku: "",
    driverPhone: "",
    userPhone: "",
    int_warehouseName: "",
    int_bicciStatus: "",
    billingPaymentRequestType: "",
    int_f12: "",
    driverName: "",
    int_clientDescriptor: "",
    transportOrderSize: "",
    clientEmail: "",
    clientRut: "",
    deliveryRut: "",
    deliveryComment: "",
    direccion2: "",
    name: "",
  };

  const defaultFilters2 = {
    clientNames: "",
    clientRut: "",
    toAddress: "",
  };

  //const settings = useSettingsContext();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(userList);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    setUserList(props.dataOrdesRequets);
    setTableData(props.dataOrdesRequets);
  }, [props.dataOrdesRequets]);

  useEffect(() => {
    props.UpdateOrderForAcctions(table.selected);
  }, [table.selected]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter(
      (row) => !table.selected.includes(row.id)
    );
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  // const handleEditRow = useCallback(
  //   (id) => {
  //     router.push(paths.dashboard.user.edit(id));
  //   },
  //   [router]
  // );
  const handleEditRow = (id) => {
    console.log(id);
  };

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters("status", newValue);
    },
    [handleFilters]
  );

  const handleFilterOrdersStatus = useCallback(
    (event, newValue) => {
      handleFilters("orderStatus", newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  console.log(dataFiltered);

  return (
    <>
      <Container maxWidth={"100%"} sx={{ maxWidth: "100%", width: "100%" }}>
        {/*         
        <CustomBreadcrumbs
          heading="List"
          links={[
            { name: "Dashboard", href: paths.dashboard.root },
            { name: "User", href: paths.dashboard.user.root },
            { name: "List" },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.dashboard.user.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New User
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        /> 
        */}

        <Card sx={{ maxWidth: "100%", width: "100%" }}>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) =>
                `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === "all" || tab.value === filters.status) &&
                        "filled") ||
                      "soft"
                    }
                    color={
                      (tab.value === "pendiente" && "warning") ||
                      (tab.value === "creado" && "warning") ||
                      (tab.value === "enruta" && "success") ||
                      (tab.value === "excepcion" && "error") ||
                      (tab.value === "terminado" && "success") ||
                      (tab.value === "devolucion" && "error") ||
                      "default"
                    }
                  >
                    {tab.value === "all" && userList.length}
                    {tab.value === "terminado" &&
                      userList.filter(
                        (user) =>
                          user.int_falabella_status === "Terminado" ||
                          user.int_falabella_status === "99"
                      ).length}

                    {tab.value === "pendiente" &&
                      userList.filter(
                        (user) =>
                          user.int_falabella_status === "Pendiente" ||
                          user.int_falabella_status === "En ruta" ||
                          user.int_falabella_status === "1058" ||
                          user.int_falabella_status === "1059" ||
                          user.int_falabella_status === "1060" ||
                          user.int_falabella_status === "1061"
                      ).length}
                    {tab.value === "devolucion" &&
                      userList.filter(
                        (user) => user.int_falabella_status === "Devolucion"
                      ).length}
                    {tab.value === "excepcion" &&
                      userList.filter(
                        (user) =>
                          user.int_falabella_status === "Pendiente" ||
                          user.int_falabella_status === "Incidencia" ||
                          user.int_falabella_status === "1002" ||
                          user.int_falabella_status === "1005" ||
                          user.int_falabella_status === "1007" ||
                          user.int_falabella_status === "1008" ||
                          user.int_falabella_status === "1009" ||
                          user.int_falabella_status === "1017" ||
                          user.int_falabella_status === "1019" ||
                          user.int_falabella_status === "1022" ||
                          user.int_falabella_status === "1023" ||
                          user.int_falabella_status === "1027" ||
                          user.int_falabella_status === "1031" ||
                          user.int_falabella_status === "1032" ||
                          user.int_falabella_status === "1030" ||
                          user.int_falabella_status === "1034" ||
                          user.int_falabella_status === "1036" ||
                          user.int_falabella_status === "1041" ||
                          user.int_falabella_status === "1081" ||
                          user.int_falabella_status === "1038" ||
                          user.int_falabella_status === "1046"
                      ).length}
                    {tab.value === "update" &&
                      userList.filter(
                        (user) => user.int_falabella_status === "isRequire"
                      ).length}
                  </Label>
                }
              />
            ))}
          </Tabs>
          {/* 
          <Tabs
            value={filters.orderStatus}
            onChange={handleFilterOrdersStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) =>
                `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_USER_OPTIONS_OTERS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === "allOrders" ||
                        tab.value === filters.orderStatus) &&
                        "filled") ||
                      "soft"
                    }
                    color={
                      (tab.value === "create" && "warning") ||
                      (tab.value === "assing" && "success") ||
                      (tab.value === "route" && "success") ||
                      (tab.value === "delivery" && "success") ||
                      (tab.value === "pending" && "warning") ||
                      (tab.value === "reject" && "error") ||
                      "default"
                    }
                  >
                    {tab.value === "allOrders" && userList.length}
                    {tab.value === "create" &&
                      userList.filter((user) => user.orderStatus === "create")
                        .length}

                    {tab.value === "assing" &&
                      userList.filter((user) => user.orderStatus === "assing")
                        .length}
                    {tab.value === "route" &&
                      userList.filter((user) => user.orderStatus === "route")
                        .length}
                    {tab.value === "delivery" &&
                      userList.filter((user) => user.orderStatus === "delivery")
                        .length}
                    {tab.value === "pending" &&
                      userList.filter((user) => user.orderStatus === "pending")
                        .length}
                    {tab.value === "reject" &&
                      userList.filter((user) => user.orderStatus === "reject")
                        .length}
                  </Label>
                }
              />
            ))}
          </Tabs>
 */}
          <OrdersTableToolbar
            filters={filters}
            onFilters={handleFilters}
            roleOptions={_roles}
            serviceOptions={_service}
            ridersOptions={_rider}
          />
          {canReset && (
            <OrdersTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
              orderDataFiltered={dataFiltered}
            />
          )}
          <div>
            <TableContainer sx={{ position: "unset", overflow: "unset" }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table
                  size={table.dense ? "small" : "medium"}
                  sx={{ minWidth: 960 }}
                >
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={dataFiltered.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        checked,
                        dataFiltered.map((row) => row.id)
                      )
                    }
                  />

                  <TableBody>
                    {/* {dataFiltered
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <OrdersTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row)}
                          onDeleteRow={() => handleDeleteRow("F" + row.id)}
                          onEditRow={() => handleEditRow("F" + row.id)}
                          visibleBilling={props.visibleBilling}
                          companyId={props.companyId}
                        />
                      ))} */}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(
                        table.page,
                        table.rowsPerPage,
                        tableData.length
                      )}
                    />

                    <TableNoData
                      notFound={notFound}
                      mensajeBanner={`${configTable.ordersForAccions.length} Docmentos encontrados`}
                      bannerActive={
                        configTable.ordersForAccions.length ? true : false
                      }
                    />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          </div>
          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete{" "}
            <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const {
    generalSearch,
    status,
    orderStatus,
    role,
    orige,
    service,
    rider,
    int_serviceType,
    id,
    userEmail,
    deliveryReceptor,
    commerce,
    direccion1,
    deliveryFailureReason,
    commune2,
    clientNames,
    userID,
    companyId,
    userName,
    int_client_soc,
    int_lpn,
    toAddress,
    commune,
    key,
    driverVehicle,
    polygonID,
    dataOrigin,
    company,
    driverLocation,
    driverLicensePlate,
    origin,
    region,
    deliveryPictures,
    int_falabella_status,
    routeStartDate,
    orderID,
    int_placeName,
    int_travelDate,
    driverID,
    deliveryMethod,
    routeLocality,
    paymentRequestId,
    billingPaymentType_service,
    fromAddress,
    int_falabella_status_descriptor,
    int_serviceLevel,
    driverTruckDescription,
    serviceType,
    description,
    fromAddressLocation,
    int_travelNumber,
    driverRut,
    productName,
    driverEmail,
    productSku,
    driverPhone,
    userPhone,
    int_warehouseName,
    int_bicciStatus,
    billingPaymentRequestType,
    int_f12,
    driverName,
    int_clientDescriptor,
    transportOrderSize,
    clientEmail,
    clientRut,
    deliveryRut,
    deliveryComment,
    direccion2,
    //especilal
    name,
  } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (clientNames) {
    inputData = inputData.filter(
      (user) =>
        user.clientNames.toLowerCase().indexOf(clientNames.toLowerCase()) !== -1
    );
  }

  if (status !== "all") {
    if (status === "terminado") {
      inputData = inputData.filter(
        (user) =>
          user.int_falabella_status === "Terminado" ||
          user.int_falabella_status === 99
      );
    }
    if (status === "pendiente") {
      inputData = inputData.filter(
        (user) =>
          user.int_falabella_status === "Pendiente" ||
          user.int_falabella_status === "En ruta" ||
          user.int_falabella_status === "1058" ||
          user.int_falabella_status === "1059" ||
          user.int_falabella_status === "1060" ||
          user.int_falabella_status === "1061" ||
          user.int_falabella_status === "1"
      );
    }
    if (status === "devolucion") {
      inputData = inputData.filter(
        (user) =>
          user.int_falabella_status === "Devolucion" ||
          user.int_falabella_status === "devolucion" ||
          user.int_falabella_status === "1029"
      );
    }
    if (status === "excepcion") {
      inputData = inputData.filter(
        (user) =>
          user.int_falabella_status === "Pendiente" ||
          user.int_falabella_status === "1002" ||
          user.int_falabella_status === "1005" ||
          user.int_falabella_status === "1007" ||
          user.int_falabella_status === "1008" ||
          user.int_falabella_status === "1009" ||
          user.int_falabella_status === "1017" ||
          user.int_falabella_status === "1019" ||
          user.int_falabella_status === "1022" ||
          user.int_falabella_status === "1023" ||
          user.int_falabella_status === "1027" ||
          user.int_falabella_status === "1031" ||
          user.int_falabella_status === "1032" ||
          user.int_falabella_status === "1030" ||
          user.int_falabella_status === "1034" ||
          user.int_falabella_status === "1036" ||
          user.int_falabella_status === "1041" ||
          user.int_falabella_status === "1081" ||
          user.int_falabella_status === "1038" ||
          user.int_falabella_status === "1046"
      );
    }
  }
  if (status === "update") {
    inputData = inputData.filter(
      (user) => user.int_falabella_status === "isRequire"
    );
  }
  if (orderStatus !== "allOrders") {
    inputData = inputData.filter((user) => user.orderStatus === orderStatus);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  if (orige) {
    inputData = inputData.filter(
      (user) =>
        user.orige &&
        user.orige.toLowerCase().indexOf(orige.toLowerCase()) !== -1
    );
  }

  if (service.length) {
    inputData = inputData.filter((user) =>
      service.includes(user.int_serviceType)
    );
  }
  if (rider.length) {
    inputData = inputData.filter((user) => rider.includes(user.driverName));
  }

  if (generalSearch) {
    const searchValue = generalSearch.toLowerCase();
    inputData = inputData.filter(
      (user) =>
        (user.id && id.toLowerCase().includes(searchValue)) ||
        (user.userEmail &&
          user.userEmail.toLowerCase().includes(searchValue)) ||
        (user.deliveryReceptor &&
          user.deliveryReceptor.toLowerCase().includes(searchValue)) ||
        (user.commerce && user.commerce.toLowerCase().includes(searchValue)) ||
        (user.direccion1 &&
          user.direccion1?.toLowerCase().includes(searchValue)) ||
        (user.deliveryFailureReason &&
          user.deliveryFailureReason.toLowerCase().includes(searchValue)) ||
        (user.commune2 && user.commune2.toLowerCase().includes(searchValue)) ||
        (user.clientNames &&
          user.clientNames.toLowerCase().includes(searchValue)) ||
        (user.userID && user.userID.toLowerCase().includes(searchValue)) ||
        (user.companyId &&
          user.companyId.toLowerCase().includes(searchValue)) ||
        (user.userName && user.userName.toLowerCase().includes(searchValue)) ||
        (user.int_client_soc &&
          user.int_client_soc.toString().toLowerCase().includes(searchValue)) ||
        (user.int_lpn &&
          user.int_lpn.toString().toLowerCase().includes(searchValue)) ||
        (user.toAddress &&
          user.toAddress.toLowerCase().includes(searchValue)) ||
        (user.commune && user.commune.toLowerCase().includes(searchValue)) ||
        (user.key && user.key.toLowerCase().includes(searchValue)) ||
        (user.driverVehicle &&
          user.driverVehicle.toLowerCase().includes(searchValue)) ||
        (user.polygonID &&
          user.polygonID.toLowerCase().includes(searchValue)) ||
        (user.dataOrigin &&
          user.dataOrigin.toLowerCase().includes(searchValue)) ||
        (user.company && user.company.toLowerCase().includes(searchValue)) ||
        (user.driverLocation &&
          user.driverLocation.toLowerCase().includes(searchValue)) ||
        (user.driverLicensePlate &&
          user.driverLicensePlate.toLowerCase().includes(searchValue)) ||
        (user.origin && user.origin.toLowerCase().includes(searchValue)) ||
        (user.region && user.region.toLowerCase().includes(searchValue)) ||
        (user.deliveryPictures &&
          user.deliveryPictures.toLowerCase().includes(searchValue)) ||
        (user.int_falabella_status &&
          user.int_falabella_status.toLowerCase().includes(searchValue)) ||
        (user.routeStartDate &&
          user.routeStartDate.toLowerCase().includes(searchValue)) ||
        (user.orderID && user.orderID.toLowerCase().includes(searchValue)) ||
        (user.int_placeName &&
          user.int_placeName.toLowerCase().includes(searchValue)) ||
        (user.int_travelDate &&
          user.int_travelDate.toLowerCase().includes(searchValue)) ||
        (user.driverID && user.driverID.toLowerCase().includes(searchValue)) ||
        (user.deliveryMethod &&
          user.deliveryMethod.toLowerCase().includes(searchValue)) ||
        (user.routeLocality &&
          user.routeLocality.toLowerCase().includes(searchValue)) ||
        (user.paymentRequestId &&
          user.paymentRequestId.toLowerCase().includes(searchValue)) ||
        (user.billingPaymentType_service &&
          user.billingPaymentType_service
            .toLowerCase()
            .includes(searchValue)) ||
        (user.fromAddress &&
          user.fromAddress.toLowerCase().includes(searchValue)) ||
        (user.int_falabella_status_descriptor &&
          user.int_falabella_status_descriptor
            .toLowerCase()
            .includes(searchValue)) ||
        (user.int_serviceLevel &&
          user.int_serviceLevel.toLowerCase().includes(searchValue)) ||
        (user.driverTruckDescription &&
          user.driverTruckDescription.toLowerCase().includes(searchValue)) ||
        (user.serviceType &&
          user.serviceType.toLowerCase().includes(searchValue)) ||
        (user.description &&
          user.description.toLowerCase().includes(searchValue)) ||
        (user.fromAddressLocation &&
          user.fromAddressLocation.toLowerCase().includes(searchValue)) ||
        (user.int_travelNumber &&
          user.int_travelNumber
            .toString()
            .toLowerCase()
            .includes(searchValue)) ||
        (user.driverRut &&
          user.driverRut.toString().toLowerCase().includes(searchValue)) ||
        (user.productName &&
          user.productName.toLowerCase().includes(searchValue)) ||
        (user.driverEmail &&
          user.driverEmail.toLowerCase().includes(searchValue)) ||
        (user.productSku &&
          user.productSku.toString().toLowerCase().includes(searchValue)) ||
        (user.driverPhone &&
          user.driverPhone.toString().toLowerCase().includes(searchValue)) ||
        (user.userPhone &&
          user.userPhone.toString().toLowerCase().includes(searchValue)) ||
        (user.int_warehouseName &&
          user.int_warehouseName
            .toString()
            .toLowerCase()
            .includes(searchValue)) ||
        (user.int_bicciStatus &&
          user.int_bicciStatus
            .toString()
            .toLowerCase()
            .includes(searchValue)) ||
        (user.billingPaymentRequestType &&
          user.billingPaymentRequestType.toLowerCase().includes(searchValue)) ||
        (user.int_f12 &&
          user.int_f12.toString().toLowerCase().includes(searchValue)) ||
        (user.driverName &&
          user.driverName.toLowerCase().includes(searchValue)) ||
        (user.int_clientDescriptor &&
          user.int_clientDescriptor.toLowerCase().includes(searchValue)) ||
        (user.transportOrderSize &&
          user.transportOrderSize.toLowerCase().includes(searchValue)) ||
        (user.clientEmail &&
          user.clientEmail.toLowerCase().includes(searchValue)) ||
        (user.clientRut &&
          user.clientRut.toString().toLowerCase().includes(searchValue)) ||
        (user.deliveryRut &&
          user.deliveryRut.toString().includes(searchValue)) ||
        (user.deliveryComment &&
          user.deliveryComment.toLowerCase().includes(searchValue)) ||
        (user.direccion2 &&
          user.direccion2.toString().toLowerCase().includes(searchValue))
    );
  }

  return inputData;
}
