import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";

export const fetcherUserdriver = async (id) => {
  try {
    const refscollection = collection(
      db,
      process.env.REACT_APP_COL_USERS_DRIVERS
    );

    const documentRef = doc(refscollection, id);

    const document = await getDoc(documentRef);

    let data = document.data();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

export const fetcherOrdersUserdriver = async (id) => {
  try {
    const refscollection = collection(
      db,
      process.env.REACT_APP_COL_USERS_ORDERS
    );

    const documentRef = query(refscollection, where("driverID", "==", id));

    const documents = await getDocs(documentRef);

    let data = documents.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const sortedItems = data.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return sortedItems;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

export const fetcherUsers = async (args) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_USERS);
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

// ----------------------------------------------------------------------

export const fetcherRoles = async (args) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_ROLES);
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

// ----------------------------------------------------------------------

export const fetcherPermisosDeRoles = async (args) => {
  try {
    const refscollection = collection(
      db,
      process.env.REACT_APP_COL_ROLES_PERMISOS
    );
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

// ----------------------------------------------------------------------

export const fetcherPermision = async (args) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_PERMISOS);
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};
// ----------------------------------------------------------------------

export const fetcherServices = async (args) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_SERVICIOS);
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};
// ----------------------------------------------------------------------

export const fetcherRolesPermisos = async (args) => {
  try {
    const refscollection = collection(
      db,
      process.env.REACT_APP_COL_ROLES_PERMISOS
    );
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};
// ----------------------------------------------------------------------

export const fetcherCompanies = async (args) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_COMPANIES);
    const q = query(refscollection);
    const querySnapShot = await getDocs(q);

    let data = querySnapShot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

export const fetcherSingleCompany = async (id) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_COMPANIES);

    const documentRef = doc(refscollection, id);

    const document = await getDoc(documentRef);

    let data = document.data();

    return data;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};

export const fetcherOrdersSingleCompany = async (id) => {
  try {
    const refscollection = collection(db, process.env.REACT_APP_COL_ORDERS);

    // Crear una fecha que represente 30 días atrás desde hoy.
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    // Convertir la fecha a un objeto Timestamp de Firestore.
    const thirtyDaysAgoTimestamp = Timestamp.fromDate(thirtyDaysAgo);

    // Actualizar la consulta para incluir la condición de fecha.
    const documentRef = query(
      refscollection,
      where("companyId", "==", id),
      where("createdAt", ">=", thirtyDaysAgoTimestamp)
    );

    const documents = await getDocs(documentRef);

    let data = documents.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Los elementos ya deben estar filtrados por la fecha, pero aún puedes querer ordenarlos.
    const sortedItems = data.sort((a, b) => {
      return (
        new Date(b.createdAt.seconds * 1000) -
        new Date(a.createdAt.seconds * 1000)
      );
    });

    return sortedItems;
  } catch (error) {
    console.error("Error fetching :", error);
    return [];
  }
};
