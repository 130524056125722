import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { NavLink } from "react-router-dom";
import { Avatar, LinearProgress, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { grey, green } from "@mui/material/colors";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Form, Input } from "semantic-ui-react";
import Moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LaunchIcon from "@mui/icons-material/Launch";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import { Card } from "@mui/material";
import { useEffect } from "react";
import SwipeableSearcherDrawer from "../../../../pages/Dashboard/Guias/SwipeableSearcherDrawer";
import Grid from "@mui/material/Grid";
import GraficasChartLineDaysMonths from "../../../GraficasChartLineDaysMonths/GraficasChartLineDaysMonths";
import generarReporte from "../../../../utils/Reports/generarReporte";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CustomDateRangePicker, {
  useDateRangePicker,
} from "../../../CustomDateRangePicker";
import { fDate } from "../../../../utils/objects/FormatTime";
import EventIcon from "@mui/icons-material/Event";
import ReusableFormImportOrders from "../../../ReusableFormImportOrders/ReusableFormImportOrders";

export default function ToolDashAppBarCompanyOps(props) {
  const [viewCreateRoutes, setViewCreateRoutes] = React.useState(false);
  const [viewSelectTimes, setViewSelectTimes] = React.useState(true);
  const [viewViewGrasphs, setViewViewGrasphs] = React.useState(false);
  const [viewRegisterIems, setViewRegisterIems] = React.useState(true);

  const [viewSelectorDatesRang, setViewSelectorDatesRang] =
    React.useState(false);
  const [viewFindAnSelectorOrdes, setViewFindAnSelectorOrdes] =
    React.useState(false);

  const [viewViewSelectorOrders, setViewSelectorOrders] = React.useState(false);
  const [viewFinFindOrdes, setViewFindOrdes] = React.useState(false);
  const [viewViewSearchOrder, setViewSearchOrder] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);

  const [listIDs, setListIDs] = React.useState("");

  const [dataMergeLocalOrder, setDataMergeLocalOrder] = React.useState([]);

  const [dataSearch, setDataSearch] = React.useState("");

  const [rows, setRows] = React.useState([]);

  const rangeInputPicker = useDateRangePicker(new Date(), new Date());

  const [openImport, setOpenImport] = React.useState(false);

  useEffect(() => {
    if (props.dataOrdersFilter) {
    }
    setDataMergeLocalOrder(props.dataOrdersFilter);
  }, [viewCreateRoutes, props, viewFinFindOrdes, props.dataOrdersFilter]);

  useEffect(() => {
    if (dataSearch) {
      filterData(dataSearch);
    }
  }, [dataSearch]);

  const filterData = async (dataSearch) => {
    const filterFunction = (obj, searchTerm) => {
      for (let key in obj) {
        const value = obj[key];
        if (typeof value === "string" && value.includes(searchTerm)) {
          return true;
        }
        if (typeof value === "object") {
          if (filterFunction(value, searchTerm)) {
            return true;
          }
        }
      }
      return false;
    };

    const filteredData = dataMergeLocalOrder.filter((item) =>
      filterFunction(item, dataSearch)
    );

    const rowsResult = filteredData.map((item) => {
      // Aquí puedes mapear los campos del objeto filtrado a la estructura que necesites
      return createData(
        item["orderID"],
        item["CLIENTENOMBRES"],
        item["CLIENTETELEFONO"],
        item["CLIENTECORREO"],
        item["driverName"],
        item["upDatedAtText"],
        item["address"],
        item["commune1"],
        item["orderID"],
        item["driverAvatar"],
        item["companyId"],
        item["estiatedDate"],
        item["COMUNA"]
      );
    });

    setRows(rowsResult);
  };

  function createData(
    OrderID,
    Cliente,
    ClienteTelefono,
    ClienteEmail,
    Conductor,
    Fecha,
    Adress,
    Zona,
    Detalles,
    driverAvatar
  ) {
    return {
      OrderID,
      Cliente,
      ClienteTelefono,
      ClienteEmail,
      Conductor,
      Fecha,
      Adress,
      Zona,
      Detalles,
      driverAvatar,
    };
  }

  const setNewDate = () => {
    let createDate = {
      name: "createDate",
      value: rangeInputPicker.startDate,
    };
    let FinalDate = { name: "FinalDate", value: rangeInputPicker.endDate };
    rangeInputPicker.onClose();
    props.onChageRangePiker(createDate, FinalDate);
  };

  const handleMenuReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleCloseMenuReport = () => {
    setAnchorElReport(null);
  };

  const UploadDocumentsByData = () => {
    console.log("Importador de documentos");
    setOpenImport(true);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        columns={16}
        sx={{
          borderBlockColor: grey[200],
        }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "70%",
            },
          }}
        >
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <div>
              <Button
                variant="contained"
                onClick={rangeInputPicker.onOpen}
                sx={{
                  marginTop: "5px",
                  marginBottom: "5px",
                  minWidth: "300px",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                  backgroundColor: "white", // Fondo blanco
                  borderColor: "purple", // Bordes morados
                  color: "#080808",
                  border: "1px solid #e1e1e1", // Define un borde
                  display: "flex", // Estilo flex para centrar los contenidos
                  justifyContent: "center", // Centra horizontalmente
                  alignItems: "center", // Centra verticalmente
                  "&:hover": {
                    // Efecto hover
                    backgroundColor: "#e1e1e1", // gris claro
                  },
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <div sx={{ typography: "body2" }}>
                    <strong>Desde:</strong> {fDate(rangeInputPicker.startDate)}
                    <EventIcon color="primary" /> {/* Ícono de calendario */}
                  </div>
                  <div sx={{ typography: "body2" }}>
                    <strong>Hasta:</strong> {fDate(rangeInputPicker.endDate)}
                    <EventIcon color="primary" /> {/* Ícono de calendario */}
                  </div>
                </Stack>
              </Button>

              <CustomDateRangePicker
                variant="calendar" // input
                open={rangeInputPicker.open}
                startDate={rangeInputPicker.startDate}
                endDate={rangeInputPicker.endDate}
                onChangeStartDate={rangeInputPicker.onChangeStartDate}
                onChangeEndDate={rangeInputPicker.onChangeEndDate}
                onClose={rangeInputPicker.onClose}
                error={rangeInputPicker.error}
                setNewDate={setNewDate}
              />
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => console.log("fijar buscador a utlizar")}
                sx={{ color: "#591e8f" }}
              >
                <SearchIcon
                  sx={{
                    color: viewFindAnSelectorOrdes ? green[500] : "#591e8f",
                  }}
                />
              </IconButton>
            </div>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuReport}
                sx={{ color: "#591e8f" }}
              >
                <SaveAltIcon sx={{ color: "#591e8f" }} />
              </IconButton>
              Reportes
              <Menu
                id="menu-appbar"
                anchorEl={anchorElReport}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElReport)}
                onClose={handleCloseMenuReport}
              >
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <>
                      <button
                        onClick={() => generarReporte(props.dataOrdersFilter)}
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Pagos Riders
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteTurno(props.dataOrdersFilter)
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Cierre Turno Salida directas
                      </button>
                    </>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilter &&
                  props.dataOrdersFilter.length &&
                  props.dataOrdersForAccions &&
                  props.dataOrdersForAccions.length > 0 ? (
                    <>
                      <button
                        onClick={() =>
                          props.generarReporteEstados(
                            props.dataOrdersForAccions
                          )
                        }
                        className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                      >
                        {" "}
                        Reporte Estados
                      </button>
                    </>
                  ) : (
                    <div>
                      Seleccione Órdenes para, exportar un reporte de Estados
                    </div>
                  )}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataOrdersFilte &&
                  props.dataOrdersFilter.length > 0 ? (
                    <button
                      onClick={() => props.findHistorials()}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {" "}
                      Ver Historiales de Órdenes
                    </button>
                  ) : null}
                </MenuItem>
                <MenuItem
                  onClick={handleCloseMenuReport}
                  sx={{ color: "#591e8f" }}
                >
                  {props.dataMerge && props.dataMerge.length > 0 ? (
                    <button
                      onClick={() => props.setWiewPrePdf(!props.wiewPrePdf)}
                      className="btn btn-ebiex m-2 btn-reporte-excel btn-fullCover"
                    >
                      {props.wiewPrePdf === true
                        ? "Cerrar vista previa de reporte"
                        : "Crear reporte Cierre Diario"}
                    </button>
                  ) : null}
                </MenuItem>
              </Menu>
            </div>
            <div>
              <Button
                variant="outline"
                startIcon={<LaunchIcon />}
                onClick={() => props.handleModeChange("express")}
                sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
              >
                Nuevo Envío
              </Button>
            </div>
            <div>
              <Button
                variant="outline"
                startIcon={<FileUploadIcon />}
                onClick={() => props.handleModeChange("masive")}
                sx={{ mb: 2, color: "#030303" }} // margen inferior para separar del DataGrid
              >
                Importar documento
              </Button>

              <ReusableFormImportOrders
                open={openImport}
                setOpen={setOpenImport}
                handleClose={() => setOpenImport(false)}
                companyData={props.companyData}
              />
            </div>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "30%",
              lg: "30%",
            },
          }}
        >
          <div>
            <h3>{props.cantDataOrdersFilter} Items en la consulta actual</h3>
          </div>
        </Grid>
      </Grid>

      {viewFinFindOrdes === true && viewFindAnSelectorOrdes === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            <Toolbar
              sx={{
                border: 0.5,
                borderColor: grey[200],
              }}
            >
              <div>
                <Form.Field
                  sx={{
                    backgroundColor: "transparent",
                    border: "0.1",
                    borderRadius: "15px",
                    color: "#000",
                    fontSize: "inherit",
                    height: "auto",
                    width: "100px",
                    outline: "none",
                    paddingInlineEnd: "44px",
                    position: "relative",
                    textAlign: "left",
                    appearance: "menulist-button",
                    backgroundImage: "none",
                    maxWidth: "250px",
                    display: "inline-block",
                    margin: "3px",
                  }}
                  control={Input}
                  placeholder="Buscar..."
                  iconPosition="left"
                  type="text"
                  onChange={(e) => setDataSearch(e.target.value)}
                  value={dataSearch}
                />
              </div>
            </Toolbar>
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      {viewViewGrasphs === true ? (
        <Grid container spacing={3} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
              },
            }}
          >
            {" "}
            <Card className="ajustGrafic" xs={{ borderRadius: "3px" }}>
              <div className="m-2">
                <div>
                  <span>Ver paquetes mensuales</span>{" "}
                  <Form.Field
                    id="FinalDate"
                    max={Moment(Date.now()).format("YYYY-MM")}
                    control={Input}
                    format={"YYYY-MM"}
                    type="month"
                    name="rangoFinal"
                    onChange={(e) => props.onChageRangeMonths(e)}
                    value={props.dateMonths}
                  />
                </div>

                {props.dataCurrentMohstDelivery ? (
                  <GraficasChartLineDaysMonths
                    props={props}
                    name={"Paquetes por día"}
                    dataHistorial={props.dataCurrentMohstDelivery}
                    color={"rgb(118, 27, 204)"}
                  ></GraficasChartLineDaysMonths>
                ) : (
                  <LinearProgress></LinearProgress>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      ) : null}

      {viewRegisterIems === true ? (
        <Grid container spacing={2} columns={16}>
          <Grid
            item
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
              },
            }}
          ></Grid>
        </Grid>
      ) : null}

      <div>
        <Box sx={{ width: "100%" }}>
          {dataSearch.length > 0 &&
          viewFinFindOrdes === true &&
          viewFindAnSelectorOrdes === true ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell align="right">Conductor</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Zona</TableCell>
                    <TableCell align="right">Ver&nbsp;Detalles</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div>
                          <div>
                            <strong>{row.OrderID}</strong>
                          </div>
                          <div>{row.Cliente}</div>
                          <div>9{row.ClienteTelefono}</div>
                          <div>{row.ClienteEmail}</div>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <div>
                          <div>
                            <Avatar
                              src={row.driverAvatar}
                              sx={{ bgcolor: "#f2f2f2" }}
                              aria-label="recipe"
                            />
                          </div>
                          <div>
                            {row.Conductor ? row.Conductor : "No Asignado"}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="right">{row.Fecha}</TableCell>
                      <TableCell align="right">{row.Zona}</TableCell>
                      <TableCell align="right">
                        <NavLink
                          to={{
                            pathname: `/dashboard/dispatch/details/${row.OrderID}`,
                          }}
                          color="primary"
                          variant="contained"
                        >
                          {" "}
                          ver{" "}
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>
      </div>
    </Box>
  );
}
