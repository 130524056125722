import React, { useState, useContext, useMemo, useRef } from "react";
import useGlobalConfig from "../../../hooks/globalConfig";
import { useSelector } from "react-redux";
import useGetDataVehiclesByCompany from "../../../hooks/getOrdersDataContextByCompany";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import Moment from "moment";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import { format } from "date-fns";
import { db } from "../../../firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { retiredPodArray } from "../../../utils/objects/retiredPodArray";

const FechtDataMapsOnlyCompany = (stateUser, vertical) => {
  const companyId = useMemo(() => {
    if (stateUser && stateUser.companyCollaborator) {
      if (stateUser.companyCollaborator === "falabella") {
        return `${process.env.REACT_APP_BXTOKETN}`;
      }
      return stateUser.userCompanyID;
    }
    return "";
  }, [stateUser]);

  const { VehiclesDataByCompany } = useGetDataVehiclesByCompany(companyId);

  const [BicciOrders, setDataOrdersFilter] = useState([]);

  const initDate = Moment(Date.now()).format("YYYY-MM-DD");

  const endDate = Moment(Date.now()).format("YYYY-MM-DD");

  const { ridersDataByCompany } = useGetDataRiderByCompany(companyId);

  React.useEffect(() => {
    if (companyId && initDate && endDate) {
      findOrdesrDispacht(companyId, initDate, endDate);
    }
  }, [companyId, initDate, endDate, vertical]);

  const findOrdesrDispacht = async (companyId, initDate, endDate) => {
    try {
      const i = new Date(initDate);
      const e = new Date(endDate);

      function initformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() - 10); // dessarrollo produccion +1
        res.setHours(0);
        res.setMinutes(0);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }
      function finalformatDate(fecha) {
        var res = new Date(fecha);
        res.setDate(res.getDate() + 1);
        res.setHours(23);
        res.setMinutes(59);
        res.setSeconds(0);
        res.setMilliseconds(0);
        return res;
      }

      var rangInit = initformatDate(i);
      var rangFinal = finalformatDate(e);

      if (initDate !== endDate) {
        callNormal();
      }
      if (initDate === endDate) {
        callIgual();
      }
      async function callNormal() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.orderID]) {
              uniqueObjects[obj.orderID] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));

          setDataOrdersFilter(filteredArray);
        } catch (error) {
          console.log("error 345");
        }
      }

      async function callIgual() {
        try {
          const ordersDispachtRef = collection(
            db,
            process.env.REACT_APP_COL_ORDERS
          );
          const q = query(
            ordersDispachtRef,
            where("companyId", "==", companyId),
            where("timeStamp", ">=", rangInit),
            where("timeStamp", "<=", rangFinal)
          );

          const querySnapshot = await getDocs(q);

          const ordersPropietary = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const uniqueObjects = {};

          for (const obj of ordersPropietary) {
            if (!uniqueObjects[obj.SOC]) {
              uniqueObjects[obj.SOC] = obj;
            }
          }

          const filteredArray = Object.values(uniqueObjects);

          filteredArray.sort((a, b) => (a.timeStamp < b.timeStamp ? 1 : -1));
          setDataOrdersFilter(ordersPropietary);
        } catch (error) {
          console.log("error 346");
          //console.log(error);
        }
      }
    } catch (error) {}
  };

  return { BicciOrders, ridersDataByCompany };
};

export default FechtDataMapsOnlyCompany;
