import React, { useState, useContext, useEffect } from "react";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";
import { Grid } from "@mui/material";
import {
  Card,
  Divider,
  CardHeader,
  LinearProgress,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import { db } from "../../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { doc, deleteDoc } from "firebase/firestore";
import Moment from "moment";
import useGetDataRiderByCompany from "../../../hooks/getDataRiderByCompany";
import generarReporteTurnoCierrediario from "../../../utils/Reports/generarReporteTurno";
import { createOrderBicciClientCompanyBicciexpress } from "../../../redux/accionesOrders";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { useDispatch } from "react-redux";
import ToolDashAppBarCompanyOps from "../../../components/Dashboard/dashboard/ToolDashAppBarCompanyOps/ToolDashAppBarCompanyOps";
import generarReporteProformas from "../../../utils/Reports/generarReporteProformas";
import SendIcon from "@mui/icons-material/Send";
import DocumentoPDFShippingLabelPrint from "../../../components/DocumentoPDFShippingLabelPrint/DocumentoPDFShippingLabelPrint";
import { generateBarcodeBase64 } from "../../../components/DocumentoPDFShippingLabelPrint/generateBarcodeBase64";
import { generateQRBase64 } from "../../../components/DocumentoPDFShippingLabelPrint/generateQRBase64";
import { Toolbar } from "@mui/material";
import { translateStatesBicciFilter } from "../../../utils/objects/translateStates";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import UpdateIcon from "@mui/icons-material/Update";
import { pdf } from "@react-pdf/renderer";
import generarReportePicking from "../../../utils/Reports/generarReportePicking";
const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#1e2158",
      color: "white",
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "lightgray",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.8)", // Ajusta la opacidad para filas impares en hover
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "rgba(255, 255, 255, 0.9) !important",
    },
  },
});

var dataOrdersForAccions = [];
var listadeItemsNoMach = [];

const GuiasByCompany = ({
  handleModeChange,
  userdata,
  firebaseUser,
  usuarioStorage,
  userDataContext,
  mergedOrders,
  loadingContext,
  setInitDate,
  setEndDate,
  initDate,
  endDate,
}) => {
  const dispacth = useDispatch();

  const { widthHeight, movilActive } = useContext(ScreenSizeContext);

  const { ridersDataByCompany } = useGetDataRiderByCompany(
    userDataContext.companyID
  );

  const [ordersView, setOrderView] = React.useState([]);

  const [toolsData, setToolsData] = React.useState(null);

  const [dataType, setDataType] = React.useState("request");

  const [documentData, setDocumentData] = React.useState(null);

  const [remder, setRender] = React.useState(true);

  const [alertMensajes, setAlertMensajes] = React.useState(null);

  const [columns, setColum] = useState([]);

  const [rows, setRows] = useState([]);

  const [dataCurrentMohstDelivery, setCurrentMohstDelivery] =
    React.useState(null);

  const [staticDateBillingFilter, setStaticDateBillingFilter] = React.useState(
    Moment(Date.now()).format("YYYY-MM-DD")
  );

  const Menu = Array.from(new Set(Object.values(translateStatesBicciFilter)));
  const displayMenu = {
    creado: "Creados",
    confirmado: "Procesando",
    qsignado: "Asignado al conductor",
    enruta: "En Ruta",
    visiado: "En Punto de entrega",
    entregado: "Entregado",
    nocofirmacion: "Requiere Actualización",
  };

  useEffect(() => {}, [Menu]);

  useEffect(() => {
    if (ordersView) {
      upDateTable(ordersView);
    }
  }, [ordersView]);

  const classes = useStyles();

  const [wiewPrePdf, setWiewPrePdf] = React.useState(false);

  const [Buttondisable, setButtondisable] = React.useState(true);

  const [dateMonths, setDateMonths] = React.useState(
    Moment(Date.now()).format("YYYY-MM")
  );

  const onChageRangePiker = (createDate, FinalDate) => {
    setInitDate(Moment(new Date(createDate.value)).format("YYYY-MM-DD"));
    setEndDate(Moment(new Date(FinalDate.value)).format("YYYY-MM-DD"));
  };

  const onChageRangeMonths = (e) => {
    setDateMonths(e.target.value);
  };

  const generarReporteTurno = () => {
    generarReporteTurnoCierrediario(ordersView, ridersDataByCompany, initDate);
  };

  const deletedItems = async (data) => {
    for (let index = 0; index < data.length; index++) {
      let docRef = doc(db, process.env.REACT_APP_COL_ORDERS, data[index].id);
      await deleteDoc(docRef)
        .then(() => {
          console.log("Documento borrado exitosamente." + data[index].key);
        })
        .catch((error) => {
          console.error("Error al borrar el documento:", error);
        });
    }
  };

  const exportartReportePagos = (userOrders) => {
    console.log(userOrders);
    generarReporteProformas(userOrders);
  };

  const exportartPicking = (userOrders) => {
    console.log("exportartPicking");
    generarReportePicking(userOrders);
  };

  const handleCreate = (order) => {
    let orderSelect = ordersView.filter((item) => item.id === order.id);
    dispacth(createOrderBicciClientCompanyBicciexpress(orderSelect[0]));
  };

  const handlePrint = async (order) => {
    let orderSelect = ordersView.filter((item) => item.id === order.id);

    const barcodeImage = await generateBarcodeBase64(orderSelect[0].orderID);

    const barcodeImageLpn = orderSelect[0].int_lpn
      ? await generateBarcodeBase64(orderSelect[0].int_lpn)
      : "";

    const QRImage = await generateQRBase64(orderSelect[0].orderID);

    console.log(barcodeImage);
    console.log(barcodeImageLpn);
    console.log(QRImage);

    const blob = await pdf(
      <DocumentoPDFShippingLabelPrint
        order={orderSelect[0]}
        barcodeImage={barcodeImage}
        barcodeImageLpn={barcodeImageLpn}
        qrImage={QRImage}
      />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const handleViewAll = () => {
    function flattenData(data) {
      let flattenedArray = [];
      for (const key in data) {
        flattenedArray = flattenedArray.concat(data[key]);
      }
      return flattenedArray;
    }
    const combinedArray = flattenData(mergedOrders);

    setOrderView(combinedArray);
  };

  const handleAssingDriver = (order) => {
    console.log(order);
    let orderSelect = ordersView.filter((item) => item.id === order.id);
    console.log(orderSelect);
  };

  const handleUpdateOrdes = (order) => {
    console.log(order);
    let orderSelect = ordersView.filter((item) => item.id === order.id);
    console.log(orderSelect);
  };

  const upDateTable = () => {
    const renderPiking = (params) => {
      if (params.row.isPickedUp === true) {
        return <CheckCircleIcon sx={{ color: "#4FFF33" }}></CheckCircleIcon>;
      } else {
        return (
          <ReportGmailerrorredIcon
            sx={{ color: "#D80B0B" }}
          ></ReportGmailerrorredIcon>
        );
      }
    };

    const renderActionButtons = (params) => {
      if (params.roworderID === undefined) {
        if (params.row.orderID === "") {
          return (
            <>
              <Box sx={{ minWidth: "200px" }}>
                <IconButton
                  sx={{ width: "30px !important", color: "#1e2158 !important" }}
                  aria-label="Crear Orden"
                  onClick={() => handleCreate(params.row)}
                >
                  <SendIcon />
                </IconButton>{" "}
                Solcitar Retiro
              </Box>
              <Box sx={{ minWidth: "200px" }}>
                <IconButton
                  sx={{ width: "30px !important", color: "#1e2158 !important" }}
                  aria-label="Crear Orden"
                  onClick={() => handleUpdateOrdes(params.row)}
                >
                  <UpdateIcon />
                </IconButton>{" "}
                Consultar Estatus
              </Box>
            </>
          );
        }
      }

      if (
        params.row.orderID !== undefined &&
        params.row.orderID !== "" &&
        params.row.int_bicciStatus !== 8
      ) {
        return (
          <>
            <Box sx={{ minWidth: "200px" }}>
              <IconButton
                sx={{ width: "30px !important", color: "#1e2158 !important" }}
                aria-label="Crear Orden"
                onClick={() => handlePrint(params.row)}
              >
                <QrCode2Icon />{" "}
              </IconButton>
              Iprimir Etiqueta
            </Box>
            <Box sx={{ minWidth: "200px" }}>
              <IconButton
                sx={{ width: "30px !important", color: "#1e2158 !important" }}
                aria-label="Crear Orden"
                onClick={() => handleAssingDriver(params.row)}
              >
                <DirectionsBikeIcon />
              </IconButton>{" "}
              Asignar Conductor
            </Box>
            <Box sx={{ minWidth: "200px" }}>
              <IconButton
                sx={{ width: "30px !important", color: "#1e2158 !important" }}
                aria-label="Crear Orden"
                onClick={() => handleUpdateOrdes(params.row)}
              >
                <UpdateIcon />
              </IconButton>{" "}
              Consultar Estatus
            </Box>
          </>
        );
      }
    };

    const columnsTable = [
      {
        field: "id",
        headerName: "ID",
        width: 150,
        editable: true,
      },
      {
        field: "commerce",
        headerName: "commerce",
        width: 200,
        editable: true,
      },
      {
        field: "deliveryDate",
        headerName: "deliveryDate",
        width: 200,
        editable: true,
      },
      {
        field: "commune2",
        headerName: "commune2",
        width: 200,
        editable: true,
      },
      {
        field: "int_serviceType",
        headerName: "int_serviceType",
        width: 30,
        editable: true,
      },
      {
        field: "orderID",
        headerName: "orderID",
        width: 200,
        editable: true,
      },
      {
        field: "driverName",
        headerName: "Conductor",
        width: 200,
        editable: true,
      },
      {
        field: "actions",
        headerName: "Acciones",
        width: 600,
        renderCell: renderActionButtons,
        sortable: false,
        filterable: false,
      },
      {
        field: "isPickedUp",
        headerName: "PICKING",
        width: 20,
        renderCell: renderPiking,
        sortable: true,
        filterable: true,
      },
    ];

    setColum(columnsTable);

    var rows = [];

    for (var i = 0; i < ordersView.length; i++) {
      rows.push({
        id: ordersView[i].id,
        commerce: ordersView[i].commerce,
        deliveryDate: ordersView[i].deliveryDate,
        commune2: ordersView[i].commune2,
        int_serviceType: ordersView[i].int_serviceType,
        orderID: ordersView[i].orderID,
        isPickedUp: ordersView[i].isPickedUp,
        driverName: ordersView[i].driverName,
        int_bicciStatus: ordersView[i].int_bicciStatus,
      });
    }

    setRows(rows);
  };

  const handleViewChange = (item) => {
    if (mergedOrders && mergedOrders[item]) {
      setOrderView(mergedOrders[item]);
    } else {
      setOrderView([]);
    }
  };

  return (
    <Box>
      <Grid
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "100%",
          },
        }}
      >
        <Grid
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
            },
          }}
        ></Grid>

        <Card
          sx={{
            width: {
              xs: "98%",
              sm: "98%",
              md: "98%",
              lg: "98%",
            },
          }}
        >
          <CardHeader
            subheader={
              <div>
                <ToolDashAppBarCompanyOps
                  handleModeChange={handleModeChange}
                  movil={widthHeight.width < 950 ? true : false}
                  cantDataOrdersFilter={
                    mergedOrders
                      ? Object.values(mergedOrders).reduce(
                          (total, currentArray) => total + currentArray.length,
                          0
                        )
                      : 0
                  }
                  dataOrdersFilter={mergedOrders}
                  dataCurrentMohstDelivery={dataCurrentMohstDelivery}
                  Buttondisable={Buttondisable}
                  setButtondisable={setButtondisable}
                  dataOrdersForAccions={dataOrdersForAccions}
                  setWiewPrePdf={setWiewPrePdf}
                  wiewPrePdf={wiewPrePdf}
                  listadeItemsNoMach={listadeItemsNoMach}
                  onChageRangeMonths={onChageRangeMonths}
                  dateMonths={dateMonths}
                  staticDateBillingFilter={staticDateBillingFilter}
                  setAlertMensajes={setAlertMensajes}
                  toolsData={toolsData}
                  setToolsData={setToolsData}
                  documentData={documentData}
                  generarReporteTurno={generarReporteTurno}
                  dataType={dataType}
                  setDocumentData={setDocumentData}
                  setDataType={setDataType}
                  remder={remder}
                  setRender={setRender}
                  onChageRangePiker={onChageRangePiker}
                  companyData={userDataContext.companyData}
                  exportartReportePagos={exportartReportePagos}
                  exportartPicking={exportartPicking}
                ></ToolDashAppBarCompanyOps>
              </div>
            }
          />
          <Divider />

          <Box>
            <div>{alertMensajes}</div>
            <Grid
              sx={{
                width: {
                  xs: "98%",
                  sm: "98%",
                  md: "98%",
                  lg: "98%",
                },
              }}
            >
              {" "}
              <Button key={"all"} onClick={() => handleViewAll()}>
                Ver todos
              </Button>
              {Menu.map((item) => {
                return (
                  <Button key={item} onClick={() => handleViewChange(item)}>
                    {displayMenu[item]}{" "}
                    {mergedOrders && mergedOrders[item]
                      ? mergedOrders[item].length
                      : null}
                  </Button>
                );
              })}
            </Grid>
            <Grid>
              {ordersView && widthHeight.width > 950 ? (
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  className={classes.root}
                  groupingModel={["int_serviceType"]}
                ></DataGrid>
              ) : (
                <LinearProgress></LinearProgress>
              )}
            </Grid>
          </Box>
          <Divider />
        </Card>
      </Grid>
    </Box>
  );
};

export default GuiasByCompany;
