// ReusableForm.js

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { translateStatesSendsFalabbelaSelector } from "../../utils/objects/translateStates";
import { Height } from "@mui/icons-material";
import { FormControl, MenuItem } from "@mui/material";
import { TempRegisterGeorefcExeptions } from "../../redux/accionesOrders";
import { useDispatch } from "react-redux";

const ReusableFormExceptions = ({
  open,
  handleClose,
  orderID,
  riderDestails,
  onSave,
}) => {
  const dispatch = useDispatch();
  const [data, setFormData] = useState({
    id: orderID,
    status: "",
  });

  const handleSave = async (data) => {
    let requets = {
      data: data,
      riderDestailsLocation: riderDestails,
    };

    dispatch(TempRegisterGeorefcExeptions(requets));
    dispatch(onSave());
  };

  const handleChange = (value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        id: orderID,
        status: value,
      };
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      sx={{ with: "450px", Height: "450px" }}
    >
      <DialogTitle id="form-dialog-title">Enviar Exception</DialogTitle>
      <DialogContent>
        {" "}
        <FormControl fullWidth key={"index"} margin="dense">
          <InputLabel id="enviarExceptionLabel">
            {"Seleccione Exception"}
          </InputLabel>
          <Select
            labelId="enviarExceptionLabel"
            id="enviarExceptionSelect"
            value={data.status || ""} // Asegúrate de usar 'status' en lugar de 'satus'
            onChange={(e) => handleChange(e.target.value)}
            label="Seleccione Exception"
          >
            {translateStatesSendsFalabbelaSelector.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => handleSave(data)} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReusableFormExceptions;
