import React, { useContext, useEffect, useState } from "react";

import { useLoadScript } from "@react-google-maps/api";
import FromShippingWiew from "../../../Seccions/FromShipping/FromShippingWiew";
import { Grid, LinearProgress } from "@mui/material";
import { UserContext } from "../../../context/CurrentUserProvider";
const libraries = ["places", "drawing", "geometry"];
const Express = () => {
  const { userDataContext } = useContext(UserContext);
  // const { isLoaded, loadError } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS_MATRIX,
  //   libraries,
  // });
  // if (loadError) {
  //   // console.log("Maps Error");
  //   return "Error";
  // }
  // if (!isLoaded) {
  //   // console.log("Loading OK");
  //   return "Loading...";
  // }

  const [prefix, setPrefix] = useState("");
  const [companyData, sertCompanyData] = React.useState(null);

  useEffect(() => {
    if (userDataContext.companyData) {
      setPrefix(userDataContext.companyData.prefix);
      sertCompanyData(userDataContext.companyData);
    }
  }, [userDataContext.companyData]);

  return companyData && prefix ? (
    <Grid>
      <FromShippingWiew
        userDataContext={userDataContext}
        prefix={prefix}
        companyData={companyData}
        dataOrigin={"DATA"}
      ></FromShippingWiew>
    </Grid>
  ) : (
    <>
      <h2>Esperando perfil</h2>
      <LinearProgress></LinearProgress>
    </>
  );
};

export default Express;
