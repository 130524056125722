import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";

function useGetDataRiderByCompany(companyId, userRider) {
  const [ridersDataByCompany, setRiderDataRider] = useState(null);
  const [ridersDataByCompanyActive, setRiderDataRiderActive] = useState(null);
  const [loadRiderData, setLoadDataRider] = useState(false);
  const [errorRiderData, setErrorDataRider] = useState(null);
  const [listRidersDataByCompany, setListridersDataByCompany] = useState(null);
  const [riderCantDataRider, setRiderCantDataRider] = useState(null);
  useEffect(() => {
    if (companyId) {
      fetchData(companyId);
    }
  }, [companyId, userRider]);

  const fetchData = async (companyId) => {
    //console.log(companyId)

    try {
      const docsDriversCompany = await getDocs(
        query(
          collection(
            db,
            `${process.env.REACT_APP_COL_COMPANIES}`,
            companyId,
            "companydrivers"
          )
        )
      );

      var dataRidersCompany = docsDriversCompany.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      let list = [];

      docsDriversCompany.docs.map((doc) => list.push(doc.data().email));

      //let list = Object.values(dataRidersCompanyIds);
      //console.log(list)
      let dataResult = [];
      const querySnapshot = await getDocs(
        query(collection(db, `${process.env.REACT_APP_COL_USERS_DRIVERS}`))
      );
      querySnapshot.forEach((doc) => {
        let field = doc.data().email;
        let id = doc.id;
        if (list.includes(field)) {
          //   console.log( id+' email: =>' + field)
          dataResult.push({ id: doc.id, ...doc.data() });
        }
      });

      // setListridersDataByCompany(dataRidersCompany);

      let riderActive = dataResult.filter((item) => item.active === true);
      let riderConecte = dataResult.filter((item) => item.online === true);
      let riderInvited = list.filter((item) => item.isInvited === true);
      let riderBloq = list.filter((item) => item.isblocked === true);

      setRiderCantDataRider({
        Activos: riderActive.length,
        Conectados: riderConecte.length,
        Invitados: riderInvited.length,
        Inactivos: riderBloq.length,
      });

      // console.log(dataResult) // Resultado
      setRiderDataRiderActive(riderActive);
      setRiderDataRider(dataResult);
      setListridersDataByCompany(docsDriversCompany);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    ridersDataByCompany,
    ridersDataByCompanyActive,
    listRidersDataByCompany,
    riderCantDataRider,
    loadRiderData,
    errorRiderData,
  };
}

export default useGetDataRiderByCompany;
