import React, { useState, useEffect } from "react";
import {
  doc,
  query,
  where,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Container,
  Grid,
  Toolbar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import getOrdesrDispachtByAny from "../../utils/getOrdesrDispachtByAny";
import { TableNoData } from "../Table";
import TabSecondary from "../Dispatchdetails/TabSecondary";
import { Form } from "semantic-ui-react";
import {
  stlyleClassStates,
  styleState,
} from "../../utils/objects/stylesStates";
import {
  translateStatesfalabbela,
  translateStatesfalabbelaSelector,
} from "../../utils/objects/translateStates";

const SearchDoc = (props) => {
  const [dataSearchOrder, setDataSearch] = useState("");
  const [orderDetails, setOrderDetails] = useState("");
  const [orderDetailsCliente, setOrderDetailsClient] = useState(null);
  const [dataOrdesBicciExpress, setDataOrdesBicciExpress] = useState(null);
  const [dispachtHistory, setDispachtHistory] = useState(null);

  const handleSeach = async () => {
    console.log(dataSearchOrder);
    if (dataSearchOrder !== null && dataSearchOrder !== "" && dataSearchOrder) {
      const result = await getOrdesrDispachtByAny(dataSearchOrder.toString());
      console.log(result);

      if (result !== null) {
        setOrderDetails(result);

        findOrdesrDispacht(result.id);
      } else {
        setOrderDetails(false);
      }
    } else {
      console.log("parametro invalido");
    }
  };

  const findOrdesrDispacht = async (id) => {
    try {
      const ordesBicciexpres = await getDocs(
        query(
          collection(db, process.env.REACT_APP_COL_ORDERS),
          where("orderID", "==", id)
        )
      );
      var dataOrdes = ordesBicciexpres.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const dataOrdesBicciExpress = dataOrdes;
      setDataOrdesBicciExpress(dataOrdesBicciExpress);
    } catch (error) {
      setDataOrdesBicciExpress({ error: "no found" });
    }

    try {
      const clearID = id.replace(/F/g, "");
      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );
      const falabellaOrderData = await getDoc(doc(falabellaOrderRef, clearID));

      if (falabellaOrderData.exists()) {
        const orderDataSoc = falabellaOrderData.data();
        setOrderDetailsClient(orderDataSoc);
      } else {
        setOrderDetailsClient({ error: "Pedido no encontrado" });
      }
    } catch (error) {
      setOrderDetailsClient({
        error: "Ocurrió un error al buscar el pedido",
      });
    }

    try {
      const clearID = id.replace(/F/g, "");
      const falabellaOrderRef = collection(
        db,
        process.env.REACT_APP_COL_FALABELLA_ORDERS
      );

      const statusRef = doc(falabellaOrderRef, clearID.toString());

      const statusDocs = collection(statusRef, "status");

      const dataallOrdersfind5 = await getDocs(statusDocs);
      const datadocuments = dataallOrdersfind5.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      datadocuments.sort((a, b) =>
        a.createdAt.seconds < b.createdAt.seconds ? 1 : -1
      );
      setDispachtHistory(datadocuments);
    } catch (error) {
      setDispachtHistory([]);
    }
  };
  console.log(orderDetails);

  return (
    <div id="containerDetails">
      <Grid>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{
              bgcolor: "#f2f2f2",
            }}
          >
            <Toolbar sx={{ width: "100%" }}>
              <Box display="flex" alignItems="center" gap={2}>
                <Button
                  onClick={handleSeach}
                  startIcon={<SearchIcon />}
                  sx={{
                    width: "150px",
                    padding: "10px",
                    background: "#591e8f",
                    borderRadius: "4px",
                    color: "#f8f8f8",
                    fontSize: "12px",
                    fontWeight: "300",
                    margin: "1px",
                    lineHeight: 1,
                    "&:hover": {
                      backgroundColor: "#4a1468",
                    },
                  }}
                >
                  Buscar
                </Button>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="ingresa el código del envío..."
                  value={dataSearchOrder}
                  onChange={(e) => setDataSearch(e.target.value)}
                  sx={{
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "inherit",
                    paddingInlineEnd: "44px",
                    textAlign: "left",
                    "& .MuiOutlinedInput-input": {
                      height: "100%",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#591e8f",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#591e8f",
                      },
                    },
                  }}
                />
              </Box>
            </Toolbar>
          </AppBar>
          <>
            {orderDetails ? (
              <>
                {" "}
                <div id="containerDetails">
                  <div>
                    <h2 className="h2-withe">Resultados de busqueda</h2>
                  </div>
                  <Grid container spacing={2} columns={12}>
                    <Grid item xs={12} md={12} lg={6}>
                      <Card className="card-ultimasOrdenes typeMiniforms">
                        <CardHeader
                          title={
                            <div>
                              <h1>{orderDetails.id}</h1>
                              {orderDetailsCliente ? (
                                <span
                                  className={
                                    styleState[
                                      orderDetailsCliente.falabella_status
                                    ]
                                  }
                                >
                                  {
                                    translateStatesfalabbela[
                                      orderDetailsCliente.falabella_status
                                    ]
                                  }
                                </span>
                              ) : null}

                              {orderDetails.int_falabella_status ? (
                                <div>
                                  <div>
                                    Se encontro un registro de facturacion ,
                                    Validar busqueda por SOC o LPN
                                  </div>
                                  <div>
                                    <span>
                                      order ID : {orderDetails.orderID}
                                    </span>
                                  </div>
                                  <div>
                                    <span>
                                      Tipo de servicio :{" "}
                                      {orderDetails.int_serviceType}
                                    </span>
                                  </div>
                                  <span>LPN : {orderDetails.int_lpn}</span>
                                  <span
                                    className={
                                      stlyleClassStates[
                                        orderDetails.int_falabella_status
                                      ]
                                    }
                                  >
                                    {orderDetails.int_falabella_status !==
                                    "isRequire"
                                      ? orderDetails.int_falabella_status
                                      : "Requiere Actualizacion "}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          }
                        />

                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "350",
                            }}
                          >
                            <div>
                              <h1>Resumen</h1>
                            </div>
                            <Container className="formcontent">
                              <TabSecondary
                                props={props}
                                orderDetails={orderDetails}
                                orderDetailsCliente={orderDetailsCliente}
                                dataOrdesBicciExpress={dataOrdesBicciExpress}
                                bloqueaGoogle={true}
                              />
                            </Container>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={12} lg={5}>
                      <Card className="card-ultimasOrdenes typeMiniforms">
                        <CardHeader title={<h3>Historial</h3>} />
                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Container
                              className="formcontent"
                              id="micuentafromIni"
                            >
                              <div className="alert alert-info">
                                Historial
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Fecha</th>
                                      <th scope="col">Estado</th>
                                    </tr>
                                  </thead>

                                  {dispachtHistory ? (
                                    <tbody>
                                      {dispachtHistory.map((item, index) => (
                                        <tr key={index}>
                                          <th scope="col">
                                            {format(
                                              item.createdAt.seconds * 1000,
                                              "dd/MM/yy HH:mm:ss"
                                            ).toString()}
                                          </th>
                                          <th scope="col">
                                            {
                                              translateStatesfalabbela[
                                                item.status
                                              ]
                                            }
                                          </th>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : null}
                                </table>
                              </div>
                            </Container>
                            <div>
                              {" "}
                              <div>
                                Nota: Si no posee historial, Si la orden fue
                                gestionada en una plataforma externa, puede
                                encontrar el historial de estados allí.
                              </div>
                            </div>
                          </Box>
                        </CardContent>
                      </Card>
                      <Card className="card-ultimasOrdenes typeMiniforms">
                        <CardHeader title={<h3>Comentarios.</h3>} />
                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "150",
                            }}
                          >
                            <Container className="formcontent">
                              <hr></hr>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                Rider:{" "}
                              </Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                {orderDetails.observationReceptor ? (
                                  <div className="color-blue">
                                    {orderDetails.observationReceptor}
                                  </div>
                                ) : (
                                  "Sin Comentarios"
                                )}
                              </Typography>
                              <hr></hr>
                              Admin:
                              {orderDetails.observationAdmin &&
                              orderDetails.observationAdmin.length > 0
                                ? orderDetails.observationAdmin.map(
                                    (item, index) => (
                                      <div key={index} className="color-blue">
                                        <hr></hr>
                                        <div>{item}</div>
                                      </div>
                                    )
                                  )
                                : " Sin Comentarios"}
                              <hr></hr>
                              {/* Agregar comentario a la Orden
                                <div>
                                  <Form onSubmit={saveComentAdmin}>
                                    {" "}
                                    <Form.Field
                                      control={Input}
                                      placeholder="Agregar comentario"
                                      iconPosition="left"
                                      type="text"
                                      onChange={(e) =>
                                        setNewComent(e.target.value)
                                      }
                                      value={newComent}
                                      icon="edit"
                                    />
                                    <Button
                                      sx={{
                                        backgroundColor: "#591E8F",
                                        border: "none",
                                        borderRadius: "3px",
                                        color: "#fff",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        marginTop: "30px",
                                        outline: "none",
                                        padding: "5px",
                                        minWidth: "50px",
                                        fontWeight: 400,
                                        margin: "0em 1em 0em 0em",
                                        ":hover": {
                                          backgroundColor: "#25b2f4 !important",
                                          color: "#591e8f",
                                        },
                                      }}
                                      color="primary"
                                      variant="contained"
                                      type="sutmit"
                                    >
                                      Guardar Nota de Orden
                                    </Button>
                                  </Form>
                                </div>
                                <hr></hr> */}
                            </Container>
                          </Box>
                        </CardContent>
                      </Card>

                      <Card className="card-ultimasOrdenes typeMiniforms">
                        <CardHeader title={<h3>Geo referencia</h3>} />
                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "350",
                            }}
                          >
                            {/* <MapDeliveryPosition
                                markersPending={orderDetails}
                                dispachtHistory={dispachtHistory}
                              ></MapDeliveryPosition> */}
                          </Box>
                        </CardContent>
                      </Card>

                      <Card className="card-ultimasOrdenes typeMiniforms">
                        <CardHeader title={<h3>Facturación</h3>} />
                        <CardContent>
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              maxWidth: "350",
                            }}
                          >
                            <Container className="formcontent">
                              <hr></hr>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                ID:
                              </Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                {orderDetails.id}
                              </Typography>
                              <hr></hr>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                Nombre servicio:
                              </Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                _________
                              </Typography>
                              <hr></hr>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                Costo de servicio:
                              </Typography>
                              <Typography
                                fontFamily="Lato, Arial, Helvetica, sans-serif"
                                color="textPrimary"
                                gutterBottom
                                variant="body"
                              >
                                _________
                              </Typography>
                              <hr></hr>
                            </Container>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <>
                {orderDetails === false ? (
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">
                        <TableNoData
                          notFound={true}
                          bannerActive={false}
                          mensajeBanner={
                            "Código no coincide con un resultado de búsqueda, verifique el código. O consulte si la orden fue creada correctamente."
                          }
                        ></TableNoData>
                        No se encontraron resultados{" "}
                      </p>
                      {/* <button
                      type="button"
                      className="btn btn-ebiex m-1"
                      onClick={handleClick}
                    >
                      Regresar
                    </button> */}
                    </div>
                  </div>
                ) : (
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title"></h5>
                      <p className="card-text">
                        <TableNoData
                          notFound={true}
                          bannerActive={false}
                          mensajeBanner={
                            "Código no coincide con un resultado de búsqueda, verifique el código. O consulte si la orden fue creada correctamente."
                          }
                        ></TableNoData>
                        Ingregrese Busqueda{" "}
                      </p>
                      {/* <button
                      type="button"
                      className="btn btn-ebiex m-1"
                      onClick={handleClick}
                    >
                      Regresar
                    </button> */}
                    </div>
                  </div>
                )}{" "}
              </>
            )}
          </>
        </Box>
      </Grid>
    </div>
  );
};

export default SearchDoc;
